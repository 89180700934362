<template>
  <main class="main--wrapper py-24 lg:py-40">
    <div class="content--wrapper flex flex-wrap">
      <CardPage class="w-full lg:w-6/12 mx-auto order-2 lg:order-none">
        <template #default>
          <button
            type="button"
            class="card--close"
            role="button"
            @click="$router.back()"
          >
            <img
              src="@/assets/images/icn-close.svg"
              :alt="$t('general.cerrar')"
            />
          </button>
          <Form @submit="validar">
            <div class="card--page--header">
              <Title>
                <template #title>
                  <h4>{{ $t('modal-experiencia-profesional.title') }}</h4>
                </template>
              </Title>
            </div>
            <div class="card--page--body">
              <p class="dark:text-primary-text text-secondary-text">
                {{ $t('modal-experiencia-profesional.description-1') }}
              </p>
              <div class="form--group">
                <label class="form--label" for="position">{{
                  $t('modal-experiencia-profesional.lbl-posicion')
                }}</label>
                <Field
                  name="position"
                  type="text"
                  class="form--element w-full"
                  :rules="validateDefault"
                  v-model="experiencies[experiencies.length - 1].position"
                />
                <ErrorMessage name="position" class="form--error" />
              </div>
              <div class="form--group">
                <label class="form--label" for="company">{{
                  $t('modal-experiencia-profesional.lbl-empresa')
                }}</label>
                <Field
                  name="company"
                  type="text"
                  class="form--element w-full"
                  :rules="validateDefault"
                  v-model="experiencies[experiencies.length - 1].company"
                />
                <ErrorMessage name="company" class="form--error" />
              </div>
              <div class="form--group">
                <label class="form--label" for="companyLocation">{{
                  $t('modal-experiencia-profesional.lbl-ubicacion-empresa')
                }}</label>
                <Field
                  name="companyLocation"
                  type="text"
                  class="form--element w-full"
                  :rules="validateDefault"
                  v-model="
                    experiencies[experiencies.length - 1].companyLocation
                  "
                />
                <ErrorMessage name="companyLocation" class="form--error" />
              </div>
              <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-6">
                <div
                  class="form--group"
                  :class="
                    !experiencies[experiencies.length - 1].expires
                      ? ''
                      : 'col-span-full'
                  "
                >
                  <h4
                    class="dark:text-primary-text text-secondary-text font-bold mb-2 lg:col-span-2"
                  >
                    {{
                      $t('modal-experiencia-profesional.title-fecha-de-inicio')
                    }}
                  </h4>
                  <Field
                    name="startDate"
                    as="label"
                    v-slot="{ field }"
                    v-model="experiencies[experiencies.length - 1].startDate"
                    class="form--icon form--icon--left block"
                  >
                    <span
                      class="icon--wrapper bg-secondary text-white rounded-l-lg"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        class="w-4 h-4 fill-current"
                      >
                        <path
                          d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                        ></path>
                      </svg>
                    </span>
                    <input
                      class="form--element w-full"
                      v-bind="field"
                      type="date"
                      onfocus="this.showPicker()"
                      :max="new Date().toISOString().split('T')[0]"
                    />
                  </Field>
                  <ErrorMessage name="startDate" class="form--error" />
                </div>
                <div
                  class="form--group"
                  v-if="!experiencies[experiencies.length - 1].expires"
                >
                  <h4
                    class="dark:text-primary-text text-secondary-text font-bold mb-2 lg:col-span-2"
                  >
                    {{ $t('modal-experiencia-profesional.title-fecha-de-fin') }}
                  </h4>
                  <Field
                    name="endDate"
                    as="label"
                    v-slot="{ field }"
                    v-model="experiencies[experiencies.length - 1].endDate"
                    class="form--icon form--icon--left block"
                  >
                    <span
                      class="icon--wrapper bg-secondary text-white rounded-l-lg"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        class="w-4 h-4 fill-current"
                      >
                        <path
                          d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                        ></path>
                      </svg>
                    </span>
                    <input
                      class="form--element w-full"
                      v-bind="field"
                      type="date"
                      onfocus="this.showPicker()"
                      :min="
                        experiencies[experiencies.length - 1].startDate ||
                        new Date().toISOString().split('T')[0]
                      "
                    />
                  </Field>
                  <ErrorMessage name="endDate" class="form--error" />
                </div>
              </div>
              <div class="form--group lg:col-span-2">
                <label class="form--checkbox"
                  ><input
                    type="checkbox"
                    id="remember"
                    value="first_checkbox"
                    @change="checkExpires"
                    v-model="experiencies[experiencies.length - 1].expires"
                  /><span>{{
                    $t('modal-experiencia-profesional.lbl-sigo-trabajando')
                  }}</span></label
                >
              </div>
              <p class="dark:text-primary-text text-secondary-text">
                {{ $t('modal-experiencia-profesional.description-2') }}
              </p>
              <p class="dark:text-primary-text text-secondary-text">
                {{ $t('modal-experiencia-profesional.description-3')
                }}<b>{{ $t('modal-experiencia-profesional.description-4') }}</b>
              </p>
              <div class="form--group">
                <Field
                  type="div"
                  name="whatBuilt"
                  :rules="validateDefault"
                  v-slot="{ field }"
                >
                  <textarea
                    v-bind="field"
                    class="form--element w-full"
                    rows="4"
                    maxlength="500"
                    v-model="experiencies[experiencies.length - 1].whatBuilt"
                    :placeholder="
                      experiencies[experiencies.length - 1].whatBuilt
                        ? experiencies[experiencies.length - 1].whatBuilt
                        : $t('modal-experiencia-profesional.placeholder-ejempo')
                    "
                  >
                  </textarea>
                  <div class="text-right font-bold text-secondary-text">
                    {{
                      experiencies[experiencies.length - 1].whatBuilt.length
                    }}/500
                  </div>
                </Field>
                <ErrorMessage name="whatBuilt" class="form--error" />
              </div>
              <div class="form--group">
                <label class="form--label" for="technologies">{{
                  $t('modal-experiencia-profesional.lbl-tecnologias-relevantes')
                }}</label>
                <Field
                  name="technologies"
                  as="div"
                  :rules="validateDefault"
                  v-slot="{ field }"
                >
                  <Multiselect
                    :options="tecnologiasLista"
                    v-model="experiencies[experiencies.length - 1].technologies"
                    mode="tags"
                    v-bind="field"
                    :createTag="true"
                    searchable
                    :noOptionsText="$t('contrata-devs-page.lista-vacia')"
                    :noResultsText="$t('contrata-devs-page.sin-resultados')"
                  />
                </Field>
                <ErrorMessage name="technologies" class="form--error" />
              </div>
              <div class="form--group">
                <label class="form--label" for="appOrRepo">{{
                  $t('modal-experiencia-profesional.lbl-link-app')
                }}</label>
                <Field
                  name="appOrRepo"
                  type="text"
                  class="form--element w-full"
                  :rules="validateUrl"
                  v-model="experiencies[experiencies.length - 1].appOrRepo"
                />
                <ErrorMessage name="appOrRepo" class="form--error" />
              </div>
              <!--
              <div class="form--group">
                <label class="form--upload" for="upload">{{
                  $t('modal-experiencia-profesional.lbl-upload')
                }}</label>
                <input
                  type="file"
                  multiple
                  id="upload"
                  name="upload"
                  class="hidden w-full"
                />
              </div>
              -->
              <button
                class="btn btn-rounded-lg btn-secondary btn-lg"
                type="button"
                role="button"
                @click="addRow"
                :aria-label="$t('general.agregar-experiencia')"
              >
                {{ $t('general.agregar-experiencia') }}
              </button>
            </div>
            <div class="card--page--footer flex justify-center space-x-4">
              <button
                type="button"
                class="btn btn-outline btn-outline-primary btn-rounded-lg btn-lg"
                role="button"
                aria-pressed="false"
                @click="$router.back()"
              >
                {{ $t('general.volver') }}
              </button>
              <button
                type="submit"
                class="btn btn-primary mx-auto btn-rounded-lg btn-lg"
                role="button"
                aria-pressed="false"
              >
                {{ $t('general.guardar') }}
              </button>
            </div>
          </Form>
        </template>
      </CardPage>
      <!-- List experiencies -->
      <ul
        class="list--education list--education--small w-full lg:w-6/12 lg:pl-10"
        v-if="experiencies.length > 1"
      >
        <li
          v-for="(experience, index) in sortExperiences"
          :key="index"
          class="pb-8"
        >
          <div class="flex">
            <div class="flex-grow">
              <span
                class="list--education--bullets"
                :class="experience.active ? 'active' : ''"
              ></span>
              <time class="dark:text-primary-text text-secondary-text text-sm">
                {{ formatedDate(experience.startDate) }}
                <span v-if="experience.expires"
                  >| {{ $t('general.presente') }}</span
                >
                <span v-else>| {{ formatedDate(experience.endDate) }}</span>
              </time>
              <h4
                class="text-white font-bold text-lg mb-2 uppercase"
                v-if="experience.position || experience.company"
              >
                {{ experience.position }} |
                <i class="text-secondary">{{ experience.company }}</i>
              </h4>
              <div class="dark:text-primary-text text-secondary-text text-sm">
                {{ experience.companyLocation }}
              </div>
              <p
                class="dark:text-primary-text text-secondary-text text-sm uppercase mb-4"
              >
                {{ experience.whatBuilt }}
              </p>
              <div class="list--badges mt-2">
                <template
                  v-for="(badge, index) in experience.technologies"
                  :key="index"
                >
                  <div class="badge badge-line badge-xs badge-rounded-full">
                    #{{ badge }}
                  </div>
                </template>
              </div>
              <a :href="experience.appOrRepo" class="text-primary text-sm">{{
                experience.appOrRepo
              }}</a>
            </div>
            <div class="flex space-x-3 pl-6">
              <button
                type="button"
                @click="editRow(index)"
                class="w-9 h-9 flex justify-center items-center rounded-md btn-secondary"
              >
                <img src="@/assets/images/icn-edit.svg" />
              </button>
              <button
                class="w-9 h-9 flex justify-center items-center rounded-md btn-primary"
                @click="removeRow(index)"
              >
                <img src="@/assets/images/icn-remove.svg" />
              </button>
            </div>
          </div>
        </li>
      </ul>
      <!-- End List experiencies -->
    </div>
  </main>
</template>

<script>
import CardPage from '@/components/CardPage'
import Title from '@/components/Title'
import tecnologiasLista from '@/components/json/tecnologias.json'
import { Field, Form, ErrorMessage } from 'vee-validate'
import apolloClient from '@/graphql/apolloClient'
import savePastJobsMutation from '@/graphql/mutations/savePastJobs'
import { mapActions, mapGetters } from 'vuex'
import ROUTES from '@/constants/routes'
import Multiselect from '@vueform/multiselect'
import { toastNotification } from '@/services/toastNotification'
import {
  validateDefault,
  validateNumber,
  validateUrl
} from '@/services/validationRules'

const emptyExperience = {
  position: null,
  company: null,
  companyLocation: null,
  startDate: null,
  endDate: null,
  whatBuilt: '',
  technologies: null,
  appOrRepo: null,
  expires: false
}

export default {
  name: 'EditExperienciaProfesional',
  components: {
    Title,
    CardPage,
    Field,
    Form,
    ErrorMessage,
    Multiselect
  },
  data() {
    return {
      tecnologiasLista: [...tecnologiasLista],
      experiencies: [
        {
          ...emptyExperience,
          active: true
        }
      ],
      ROUTES
    }
  },
  mounted() {
    this.initializeData()
  },
  computed: {
    ...mapGetters(['token', 'user']),
    sortExperiences() {
      return [...this.experiencies]?.sort(
        (a, b) => new Date(b.startDate) - new Date(a.startDate)
      )
    }
  },
  methods: {
    customTechnologies(row) {
      this.tecnologiasLista = [...this.tecnologiasLista, ...row.technologies]
    },
    ...mapActions(['fetchUser', 'setLoadingOn', 'setLoadingOff']),
    async validar() {
      try {
        this.setLoadingOn()
        const saveExperiencia = await apolloClient.mutate({
          mutation: savePastJobsMutation,
          variables: {
            pastJobs: this.sortExperiences.map(({ active, ...rest }) => rest)
          },
          context: {
            headers: {
              authorization: `Bearer ${await this.token}`
            }
          }
        })
        if (!saveExperiencia.errors) {
          await this.fetchUser()
          this.setLoadingOff()
          this.$router.push({ name: 'Profile' })
          toastNotification(this.$t('general.info-guardada'), 'success')
        }
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    addRow() {
      this.experiencies.forEach((item) => {
        item.active = false
      })
      this.experiencies.push({
        ...emptyExperience,
        active: true
      })
    },
    removeRow(index) {
      const removePosition = this.experiencies.indexOf(
        this.sortExperiences[index]
      )
      this.experiencies.splice(removePosition, 1)
    },
    editRow(index) {
      const editPosition = this.experiencies.indexOf(
        this.sortExperiences[index]
      )
      this.experiencies.forEach((item) => {
        item.active = false
      })
      this.experiencies[editPosition].active = true
      this.experiencies.push(this.experiencies[editPosition])
      this.experiencies.splice(editPosition, 1)
      const row = this.experiencies[editPosition]
      this.customTechnologies(row)
      if (row.endDate === null) {
        row.expires = true
      }
    },
    formatedDate(date) {
      if (date) {
        const month = `${new Date(date).getMonth() + 1}`.padStart(2, '0')
        const year = new Date(date).getFullYear()
        return `${month}-${year}`
      } else {
        return this.$t('general.presente')
      }
    },
    initializeData() {
      if (this.user.pastJobs) {
        this.user.pastJobs.forEach((val) => {
          this.experiencies.unshift({
            position: val.position,
            company: val.company,
            companyLocation: val.company,
            startDate: val.startDate,
            endDate: val.endDate,
            whatBuilt: val.whatBuilt,
            technologies: val.technologies,
            appOrRepo: val.appOrRepo,
            active: false
          })
        })
      }
    },
    // RULES
    validateDefault,
    validateNumber,
    validateUrl,
    checkExpires() {
      if (this.experiencies[this.experiencies.length - 1].expires === true) {
        this.experiencies[this.experiencies.length - 1].endDate = null
      }
    }
  }
}
</script>
