import gql from 'graphql-tag'

const savePastProjectsMutation = gql`
  mutation savePastProjectsMutation(
    $pastProjects: [UserProfileProjectsInputType]
    $id: Int
  ) {
    updatePastProjects(pastProjects: $pastProjects, id: $id) {
      name
      whatBuilt
      technologies
      appOrRepo
    }
  }
`

export default savePastProjectsMutation
