<template>
  <footer
    class="footer-dark footer--wrapper !p-0 !m-0"
    style="padding: 0 !important"
  >
    <section class="md:flex items-center text-white bg-black">
      <div
        class="h-full flex items-center justify-center md:w-1/2 w-full pb-16"
      >
        <a href="#" class="footer-brand-2 footer-link w-inline-block mt-5"
          ><img
            src="https://assets-global.website-files.com/64680d7294182cbb1eaf97d6/646cf0caab1f45fba9c176f7_Group%20(1).svg"
            loading="lazy"
            alt="Utopicode"
          />
          <div class="text-block-19 mt-10">
            Talento tech de calidad para el mundo
          </div></a
        >
      </div>
      <div class="h-full w-full py-32 px-10" style="background-color: #cab1fc">
        <div class="footer-wrapper-2 md:flex items-end justify-around mt-5">
          <div
            class="footer-content-2 flex items-center justify-center gap-10 tracking-wide text-md"
          >
            <div
              id="w-node-_5e69649b-2cf5-f34e-48d9-52775fc5b089-1eaf97d9"
              class="footer-block-2 block"
            >
              <div class="title-small-2 mb-3 font-extrabold">Explorar</div>
              <div class="mb-3">
                <a href="https://www.utopicode.io/" class="footer-link-2"
                  >Para Empresas</a
                >
              </div>
              <div class="mb-3">
                <a
                  href="https://www.utopicode.io/devs"
                  aria-current="page"
                  class="footer-link-2 w--current"
                  >Para Techies</a
                >
              </div>
              <div class="mb-3">
                <a href="https://blog.utopicode.com/" class="footer-link-2"
                  >Blog</a
                >
              </div>
            </div>
            <div
              id="w-node-_5e69649b-2cf5-f34e-48d9-52775fc5b094-1eaf97d9"
              class="footer-block-2"
            >
              <div class="title-small-2 mb-3 font-extrabold">Soporte</div>
              <!-- <div class="mb-3">
                <a
                  href="https://join.utopicode.io/es/faq"
                  target="_blank"
                  class="footer-link-2"
                  >FAQ</a
                >
              </div> -->
              <div class="mb-3">
                <a
                  href="/es/terminos-y-condiciones"
                  target="_blank"
                  class="footer-link-2"
                  >Términos y condiciones</a
                >
              </div>
              <div class="mb-3">
                <a
                  href="/es/politica-de-privacidad"
                  target="_blank"
                  class="footer-link-2"
                  >Política de privacidad</a
                >
              </div>
              <div class="mb-3">
                <span>&nbsp;</span>
              </div>
            </div>
          </div>
          <div
            class="social-container flex items-center gap-2 text-center justify-center pt-10 md:pb-3 md:pt-0"
          >
            <a
              href="https://www.facebook.com/utopicoders/"
              target="_blank"
              class="w-inline-block"
              ><img
                src="https://assets-global.website-files.com/64680d7294182cbb1eaf97d6/648cbb65941c4fe3610d6059_Facebook.svg"
                loading="lazy"
                alt="Facebook" /></a
            ><a
              href="https://www.instagram.com/utopicode_dev_community"
              target="_blank"
              class="w-inline-block"
              ><img
                src="https://assets-global.website-files.com/64680d7294182cbb1eaf97d6/648cbbdd3fe3bc7ccbae35cd_Facebook.svg"
                loading="lazy"
                alt="Instagram" /></a
            ><a
              href="https://www.youtube.com/channel/UCkSTX4rcQ_KJO6e_OZKF7vA"
              target="_blank"
              class="w-inline-block"
              ><img
                src="https://assets-global.website-files.com/64680d7294182cbb1eaf97d6/648cbc12563ce38de4e9c603_Facebook.svg"
                loading="lazy"
                alt="Youtube" /></a
            ><a
              href="https://twitter.com/utopicode1"
              target="_blank"
              class="w-inline-block"
              ><img
                src="https://assets-global.website-files.com/64680d7294182cbb1eaf97d6/648cbbfbc67400489e507768_%F0%9F%94%B9%20Icon%20Color.svg"
                loading="lazy"
                alt="X" /></a
            ><a
              href="https://mx.linkedin.com/company/utopicode"
              target="_blank"
              class="w-inline-block"
              ><img
                src="https://assets-global.website-files.com/64680d7294182cbb1eaf97d6/64c7d42c26f4c3a01aa60411_Linkedin.svg"
                loading="lazy"
                alt="LinkedIn"
            /></a>
          </div>
        </div>
      </div>
    </section>
    <div class="footer-copyright-center px-20 text-xs py-3 bg-white">
      Copyright © 2024 Utopicode
    </div>
  </footer>
</template>
