<template>
  <main class="main--wrapper pt-32 pb-8 min-h-screen">
    <div class="content--wrapper">
      <Title class="mb-8">
        <template #title>
          <h3>
            {{ $t('admin-dashboard-candidatos.title') }}
          </h3>
        </template>
      </Title>
      <h3 class="text-main">Realiza una búsqueda para cargar los perfiles</h3>
      <!-- Filters -->
      <Form
        @submit="getCandidates"
        class="mt-4 mb-8 grid gap-x-6 gap-y-4 lg:grid-cols-5 md:grid-cols-3 grid-cols-1 items-start"
      >
        <!-- <input
          class="form--filter"
          :placeholder="$t('admin-dashboard-candidatos.form-buscar-email')"
          v-model="filter.email"
          id="email"
        /> -->
        <Field
          name="search"
          type="text"
          class="form--filter w-full"
          :placeholder="$t('admin-dashboard-candidatos.form-buscar-email')"
        />
        <!-- <input
          class="form--filter"
          id="nombre"
          name="search"
          v-model="filter.nombre"
          :placeholder="$t('admin-dashboard-candidatos.form-buscar-nombre')"
        /> -->
        <!-- Especialidad -->
        <!-- <Multiselect
          class="multiselect-filter"
          :options="especialidadLista"
          v-model.lazy="filter.especialidad"
          mode="multiple"
          name="position"
          :placeholder="
            $t('admin-dashboard-candidatos.form-buscar-especialidad')
          "
          searchable
          :noOptionsText="$t('contrata-devs-page.lista-vacia')"
          :noResultsText="$t('contrata-devs-page.sin-resultados')"
        /> -->
        <Field name="position" as="select" class="form--filter form--select">
          <option value="" disabled>
            {{ $t('admin-dashboard-candidatos.form-buscar-especialidad') }}
          </option>
          <option
            :value="val"
            v-for="(val, index) in especialidadLista.map((val) => val.name)"
            :key="index"
          >
            {{ val }}
          </option>
        </Field>
        <!-- End Especialidad -->
        <!-- Tecnologias -->
        <Field name="requiredTechnologies" as="div" v-slot="{ field }">
          <Multiselect
            class="multiselect-filter"
            :options="tecnologiasLista.map((val) => val.name)"
            v-bind="field"
            v-model.lazy="filter.tecnologias"
            mode="multiple"
            name="requiredTechnologies"
            :placeholder="
              $t('admin-dashboard-candidatos.form-buscar-tecnologias')
            "
            searchable
            :noOptionsText="$t('contrata-devs-page.lista-vacia')"
            :noResultsText="$t('contrata-devs-page.sin-resultados')"
          />
        </Field>
        <!-- End Tecnologias -->
        <Field name="jobStatus" as="select" class="form--filter form--select">
          <option value="" selected>
            {{ $t('admin-dashboard-candidatos.form-buscar-status') }}
          </option>
          <option :value="level" v-for="(level, index) in status" :key="index">
            {{ level }}
          </option>
        </Field>
        <Field name="country" as="select" class="form--filter form--select">
          <option value="" selected>
            {{ $t('admin-dashboard-candidatos.form-buscar-pais') }}
          </option>
          <option
            :value="pais"
            v-for="pais in paisesLista[$i18n.locale]"
            :key="pais"
          >
            {{ pais }}
          </option>
        </Field>
        <Field
          name="minYears"
          type="number"
          class="form--filter w-full"
          :placeholder="
            $t('admin-dashboard-candidatos.form-buscar-experiencia-min')
          "
        />
        <!-- <input
            class="form--filter"
            v-model="filter.experiencia"
            :placeholder="
              $t('admin-dashboard-candidatos.form-buscar-experiencia')
            "
            type="number"
            min="0"
          /> -->
        <Field
          name="maxYears"
          type="number"
          class="form--filter w-full"
          :placeholder="
            $t('admin-dashboard-candidatos.form-buscar-experiencia-max')
          "
        />
        <!-- <input
            class="form--filter"
            v-model="filter.experiencia"
            :placeholder="
              $t('admin-dashboard-candidatos.form-buscar-experiencia')
            "
            type="number"
            min="0"
          /> -->
        <!-- <div class="form--filter lg:col-span-2 flex items-center">
          <span class="mr-2 whitespace-nowrap">{{
            $t('admin-dashboard-candidatos.form-buscar-salarial')
          }}</span> -->
        <Field
          name="minSalary"
          type="number"
          class="form--filter w-full"
          :placeholder="
            $t('admin-dashboard-candidatos.form-buscar-salarial-min')
          "
        />
        <Field
          name="maxSalary"
          type="number"
          class="form--filter w-full"
          :placeholder="
            $t('admin-dashboard-candidatos.form-buscar-salarial-max')
          "
        />
        <!-- <input
            v-model="filter.minSalarial"
            :max="filter.maxSalarial"
            class="-mr-1 w-1/2"
            type="range"
            step="100"
          /> -->
        <!-- <input
            v-model="filter.maxSalarial"
            :min="filter.minSalarial"
            max="50000"
            class="mr-2 w-1/2"
            type="range"
            step="100"
          /> -->
        <!-- <span class="text-xs whitespace-nowrap"
            >{{ filter.minSalarial }} USD a {{ filter.maxSalarial }} USD
            {{ $t('admin-dashboard-candidatos.lbl-netos-mensuales') }}</span
          >
        </div> -->
        <button type="submit" class="btn btn-rounded-lg btn-lg btn-primary">
          {{ $t('general.buscar') }}
        </button>
        <!-- <button
          type="button"
          @click="resetFilter()"
          class="btn btn-rounded-lg btn-lg btn-secondary"
        >
          {{ $t('general.resetear') }}
        </button> -->
      </Form>
      <!-- End Filters -->
      <!-- Table -->
      <CardPage class="pt-10">
        <template #default>
          <!-- Body -->
          <div class="card--page--body">
            <div
              class="overflow-x-auto mb-6 border dark:border-line-dark border-line-light whitespace-nowrap"
            >
              <table
                class="table--default table--hover table-fixed table--nowrap dark:text-primary-text text-secondary-text"
              >
                <thead>
                  <tr>
                    <th class="col-fixed first-col">
                      <div class="flex items-center justify-center flex-nowrap">
                        ID
                        <div class="table-sort">
                          <button
                            class="table-sort-up active"
                            type="button"
                            @click="sortByColumn('id', 'asc')"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="fill-current"
                              viewBox="0 0 10 6"
                            >
                              <path
                                d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                              />
                            </svg>
                          </button>
                          <button
                            class="table-sort-down"
                            type="button"
                            @click="sortByColumn('id', 'desc')"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="fill-current transform rotate-180"
                              viewBox="0 0 10 6"
                            >
                              <path
                                d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </th>
                    <th class="col-fixed second-col">
                      <div class="flex items-center justify-center flex-nowrap">
                        {{ $t('admin-dashboard-candidatos.table-nombre') }}
                        <div class="table-sort">
                          <button
                            class="table-sort-up active"
                            type="button"
                            @click="sortByColumn('fullname', 'asc')"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="fill-current"
                              viewBox="0 0 10 6"
                            >
                              <path
                                d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                              />
                            </svg>
                          </button>
                          <button
                            class="table-sort-down"
                            type="button"
                            @click="sortByColumn('fullname', 'desc')"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="fill-current transform rotate-180"
                              viewBox="0 0 10 6"
                            >
                              <path
                                d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </th>
                    <th>
                      <div class="flex items-center justify-center flex-nowrap">
                        Email
                        <div class="table-sort">
                          <button
                            class="table-sort-up active"
                            type="button"
                            @click="sortByColumn('email', 'asc')"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="fill-current"
                              viewBox="0 0 10 6"
                            >
                              <path
                                d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                              />
                            </svg>
                          </button>
                          <button
                            class="table-sort-down"
                            type="button"
                            @click="sortByColumn('email', 'desc')"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="fill-current transform rotate-180"
                              viewBox="0 0 10 6"
                            >
                              <path
                                d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </th>
                    <th>
                      <div class="flex items-center justify-center flex-nowrap">
                        {{
                          $t('admin-dashboard-candidatos.table-especialidad')
                        }}
                        <div class="table-sort">
                          <button
                            class="table-sort-up active"
                            type="button"
                            @click="sortByColumn('speciality', 'asc')"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="fill-current"
                              viewBox="0 0 10 6"
                            >
                              <path
                                d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                              />
                            </svg>
                          </button>
                          <button
                            class="table-sort-down"
                            type="button"
                            @click="sortByColumn('speciality', 'desc')"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="fill-current transform rotate-180"
                              viewBox="0 0 10 6"
                            >
                              <path
                                d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </th>
                    <th>
                      <div class="flex items-center justify-center flex-nowrap">
                        Status
                        <div class="table-sort">
                          <button
                            class="table-sort-up active"
                            type="button"
                            @click="sortByColumn('status', 'asc')"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="fill-current"
                              viewBox="0 0 10 6"
                            >
                              <path
                                d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                              />
                            </svg>
                          </button>
                          <button
                            class="table-sort-down"
                            type="button"
                            @click="sortByColumn('status', 'desc')"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="fill-current transform rotate-180"
                              viewBox="0 0 10 6"
                            >
                              <path
                                d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </th>
                    <th>
                      <div class="flex items-center justify-center flex-nowrap">
                        {{
                          $t(
                            'admin-dashboard-candidatos.table-perfil-completado'
                          )
                        }}
                        <div class="table-sort">
                          <button class="table-sort-up active" type="button">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="fill-current"
                              viewBox="0 0 10 6"
                            >
                              <path
                                d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                              />
                            </svg>
                          </button>
                          <button class="table-sort-down" type="button">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="fill-current transform rotate-180"
                              viewBox="0 0 10 6"
                            >
                              <path
                                d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </th>
                    <th>
                      {{ $t('admin-dashboard-candidatos.table-tecnologias') }}
                    </th>
                    <th>
                      <div class="flex items-center justify-center flex-nowrap">
                        {{ $t('admin-dashboard-candidatos.table-pais') }}
                        <div class="table-sort">
                          <button class="table-sort-up active" type="button">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="fill-current"
                              viewBox="0 0 10 6"
                            >
                              <path
                                d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                              />
                            </svg>
                          </button>
                          <button class="table-sort-down" type="button">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="fill-current transform rotate-180"
                              viewBox="0 0 10 6"
                            >
                              <path
                                d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </th>
                    <th>
                      <div class="flex items-center justify-center flex-nowrap">
                        {{
                          $t(
                            'admin-dashboard-candidatos.table-expectativa-salarial'
                          )
                        }}
                        <div class="table-sort">
                          <button class="table-sort-up active" type="button">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="fill-current"
                              viewBox="0 0 10 6"
                            >
                              <path
                                d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                              />
                            </svg>
                          </button>
                          <button class="table-sort-down" type="button">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="fill-current transform rotate-180"
                              viewBox="0 0 10 6"
                            >
                              <path
                                d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </th>
                    <th>
                      <div class="flex items-center justify-center flex-nowrap">
                        {{ $t('admin-dashboard-candidatos.table-experiencia') }}
                        <div class="table-sort">
                          <button class="table-sort-up active" type="button">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="fill-current"
                              viewBox="0 0 10 6"
                            >
                              <path
                                d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                              />
                            </svg>
                          </button>
                          <button class="table-sort-down" type="button">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="fill-current transform rotate-180"
                              viewBox="0 0 10 6"
                            >
                              <path
                                d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </th>
                    <th>{{ $t('admin-dashboard-candidatos.table-social') }}</th>
                    <th>
                      <div class="flex items-center justify-center flex-nowrap">
                        {{
                          $t(
                            'admin-dashboard-candidatos.table-ultima-actualizacion'
                          )
                        }}
                        <div class="table-sort">
                          <button class="table-sort-up active" type="button">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="fill-current"
                              viewBox="0 0 10 6"
                            >
                              <path
                                d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                              />
                            </svg>
                          </button>
                          <button class="table-sort-down" type="button">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="fill-current transform rotate-180"
                              viewBox="0 0 10 6"
                            >
                              <path
                                d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </th>
                    <th>
                      {{ $t('admin-dashboard-candidatos.table-acciones') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(user, index) in users" :key="index">
                    <td
                      class="col-fixed first-col text-center"
                      :class="
                        user.jobStatus === 'open'
                          ? 'bg-open'
                          : user.jobStatus === 'looking'
                          ? 'bg-looking'
                          : user.jobStatus === 'closed'
                          ? 'bg-no-open'
                          : ''
                      "
                    >
                      {{ user.id }}
                    </td>
                    <td class="col-fixed second-col">{{ user.name }}</td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.speciality }}</td>
                    <td>
                      <div class="flex items-center capitalize">
                        <span
                          class="w-3 h-3 rounded-full mr-2"
                          :class="
                            user.status !== 'pre-vetted' || user.status === null
                              ? 'bg-line-dark'
                              : 'bg-secondary'
                          "
                        >
                        </span>
                        {{
                          user.status !== 'pre-vetted' || user.status === null
                            ? 'Not Pre-Vetted'
                            : 'Pre-Vetted'
                        }}
                      </div>
                    </td>

                    <td>
                      <ProgressBar
                        class="w-full"
                        :porcentaje="completionPercent(user)"
                      />
                    </td>
                    <td>
                      <BadgeCollapse
                        v-if="user.technologies !== null"
                        class="w-72"
                        :size="'h-6'"
                        :badges="user.technologies"
                      ></BadgeCollapse>
                    </td>
                    <td class="text-center">{{ user.country }}</td>
                    <td class="text-center">
                      <span v-if="user.minSalary"
                        >{{ user.minSalary }} USD
                        {{
                          $t('admin-dashboard-candidatos.lbl-netos-mensuales')
                        }}</span
                      >
                    </td>
                    <td class="text-center">
                      <span v-if="user.yearsOfExp"
                        >{{ user?.yearsOfExp }} {{ $t('general.años') }}</span
                      >
                    </td>
                    <td>
                      <ul
                        class="flex gap-2 justify-center"
                        v-if="user.socialNetworks"
                      >
                        <li
                          v-for="(social, key, index) in user.socialNetworks"
                          :key="index"
                        >
                          <a
                            :href="`${
                              key === 'github'
                                ? social.includes('https://github.com/')
                                  ? social
                                  : 'https://github.com/' + social
                                : key === 'twitter'
                                ? 'https://twitter.com/' + social
                                : social
                            }`"
                            v-if="
                              key !== '__typename' &&
                              social !== '' &&
                              social !== null
                            "
                            target="_blank"
                            class="w-9 h-9 flex justify-center items-center rounded-md"
                            :class="`bg-${key}`"
                          >
                            <img
                              :src="
                                require(`@/assets/images/social-media/icn-${key}.svg`)
                              "
                              :alt="key"
                            />
                          </a>
                        </li>
                      </ul>
                    </td>
                    <td>
                      {{ user.updatedAt }}
                    </td>
                    <td>
                      <div class="flex gap-4">
                        <router-link
                          :to="{
                            name: 'AdminCandidate',
                            params: { id: user.id }
                          }"
                          class="btn btn-primary btn-rounded-lg btn-md"
                        >
                          {{
                            $t('admin-dashboard-candidatos.btn-editar-perfil')
                          }}
                        </router-link>
                        <a
                          :href="goToProfile(user.userId)"
                          class="btn btn-secondary btn-rounded-lg btn-md"
                          target="_blank"
                        >
                          {{
                            $t('admin-dashboard-candidatos.btn-perfil-publico')
                          }}
                        </a>
                        <a
                          :href="goToProfileComplete(user.userId)"
                          class="btn btn-secondary btn-rounded-lg btn-md"
                          target="_blank"
                        >
                          {{
                            $t('admin-dashboard-candidatos.btn-perfil-completo')
                          }}
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-if="users">
                <h4 v-if="users.length === 0" class="text-main">
                  No se encontraron resultados
                </h4>
              </div>
            </div>
            <!-- Reference -->
            <div class="flex gap-4">
              <div
                class="flex dark:text-primary-text text-secondary-text items-center"
              >
                <span class="w-8 h-8 bg-tertiary rounded-md mr-2"></span>
                {{ $t('admin-dashboard-candidatos.lbl-busqueda-activa') }}
              </div>
              <div
                class="flex dark:text-primary-text text-secondary-text items-center"
              >
                <span class="w-8 h-8 bg-seventh rounded-md mr-2"></span>
                {{ $t('admin-dashboard-candidatos.lbl-escuchando-ofertas') }}
              </div>
              <div
                class="flex dark:text-primary-text text-secondary-text items-center"
              >
                <span class="w-8 h-8 bg-fourth rounded-md mr-2"></span>
                {{ $t('admin-dashboard-candidatos.lbl-no-escucha-ofertas') }}
              </div>
            </div>
            <!-- End Reference -->
          </div>
          <!-- End Body -->
        </template>
      </CardPage>
      <!-- End Table -->
    </div>
  </main>
</template>

<script>
import CardPage from '@/components/CardPage'
import Title from '@/components/Title'
import paisesLista from '@/components/json/countries.json'
// import especialidadLista from '@/constants/specialities.json'
// import tecnologiasLista from '@/components/json/tecnologias.json'
import getPositionsQuery from '@/graphql/queries/positions'
import getTechnologiesQuery from '@/graphql/queries/technologies'
import ApolloClient from '@/graphql/apolloClient'
import GetUsers from '@/graphql/queries/usersSimplified'
import { mapGetters, mapActions } from 'vuex'
import { Form, Field } from 'vee-validate'
import { toastNotification } from '@/services/toastNotification'
import ProgressBar from '@/components/ProgressBar'
import { hourFormat, dateFormat } from '@/services/dateFormatting'
import BadgeCollapse from '@/components/badgesCollapse'
import Multiselect from '@vueform/multiselect'

export default {
  name: 'AdminWelcome',
  data() {
    return {
      users: null,
      paisesLista,
      especialidadLista: [],
      tecnologiasLista: [],
      filter: {
        email: '',
        nombre: '',
        especialidad: [],
        tecnologias: [],
        status: '',
        pais: '',
        experiencia: 0,
        maxSalarial: 50000,
        minSalarial: 0
      },
      status: ['open', 'looking', 'Closed']
    }
  },
  components: {
    CardPage,
    Title,
    ProgressBar,
    BadgeCollapse,
    Multiselect,
    Form,
    Field
  },
  computed: {
    ...mapGetters(['token']),
    filteredRows() {
      if (this.users) {
        return this.users.filter((user) => {
          const {
            especialidad: specialityFilter,
            email: emailFilter,
            nombre: nameFilter,
            status: statusFilter,
            pais: countryFilter,
            experiencia: experienceFilter,
            minSalarial,
            maxSalarial,
            tecnologias: technologiesFilter
          } = this.filter
          const regexEmail = new RegExp(`.*${emailFilter.toLowerCase()}.*`)
          const regexName = new RegExp(`.*${nameFilter.toLowerCase()}.*`)
          const regexCountry = new RegExp(`.*${countryFilter.toLowerCase()}.*`)
          const checkTechs = (technologies) => {
            return technologiesFilter.every((i) =>
              technologies.map((tech) => tech.techName).includes(i)
            )
          }

          const checkSpec = (spec) => spec === user.speciality
          const resultEmail =
            emailFilter && emailFilter !== ''
              ? user.email.toLowerCase().match(regexEmail)
              : true
          const resultName =
            nameFilter && nameFilter !== ''
              ? user.fullname.toLowerCase().match(regexName)
              : true

          const resultSpeciality =
            specialityFilter && specialityFilter.length > 0
              ? user.speciality && user.speciality !== ''
                ? specialityFilter.find(checkSpec)
                : false
              : true

          const resultTechnologies =
            technologiesFilter && technologiesFilter.length > 0
              ? user.technologies
                ? checkTechs(user.technologies)
                : false
              : true
          const resultCountry =
            countryFilter && countryFilter !== ''
              ? user.country
                ? user.country.toLowerCase().match(regexCountry)
                : false
              : true

          const resultJobStatus =
            statusFilter && statusFilter !== ''
              ? user.jobStatus
                ? user.jobStatus.toLowerCase() === statusFilter.toLowerCase()
                : false
              : true
          const yearsOfExpResult = user?.yearsOfExp
            ? user?.yearsOfExp >= experienceFilter
            : true

          const salaryRangeFilter = user.minSalary
            ? user.minSalary >= parseInt(minSalarial) &&
              user.minSalary <= parseInt(maxSalarial)
            : true
          return (
            !!resultEmail &&
            !!resultName &&
            !!resultSpeciality &&
            !!resultCountry &&
            !!resultJobStatus &&
            !!yearsOfExpResult &&
            !!salaryRangeFilter &&
            !!resultTechnologies
          )
        })
      } else {
        return this.users
      }
    }
  },
  beforeMount() {
    this.getTechnologies()
    this.getPositions()
  },
  methods: {
    hourFormat,
    dateFormat,
    ...mapActions(['setLoadingOn', 'setLoadingOff']),
    // fetchUsers: async function () {
    //   this.setLoadingOn()
    //   try {
    //     const getUsers = await ApolloClient.query({
    //       context: {
    //         headers: {
    //           authorization: `Bearer ${await this.token}`,
    //           lang: this.$root.$i18n.locale
    //         }
    //       },
    //       fetchPolicy: 'network-only',
    //       query: GetUsers
    //     })
    //     this.users = getUsers.data.users
    //     this.setLoadingOff()
    //   } catch (e) {
    //     toastNotification(e, 'error')
    //   }
    // },
    encryptId(id) {
      return id.toString(16)
    },
    decryptId(hexId) {
      return parseInt(hexId, 16)
    },
    goToProfile(userId) {
      if (userId) {
        const encryptedId = this.encryptId(userId)
        return `/es/ver-perfil-empresarial/${encryptedId}`
      } else {
        console.error('El perfil o el ID del perfil no están disponibles')
        return '#'
      }
    },
    goToProfileComplete(userId) {
      if (userId) {
        const encryptedId = this.encryptId(userId)
        return `/es/ver-perfil-completo/${encryptedId}`
      } else {
        console.error('El perfil o el ID del perfil no están disponibles')
        return '#'
      }
    },
    getCandidates: async function (filters) {
      this.setLoadingOn()
      try {
        const candidatesQuery = await ApolloClient.query({
          context: {
            headers: {
              authorization: `Bearer ${await this.token}`
            }
          },
          fetchPolicy: 'no-cache',
          query: GetUsers,
          variables: {
            ...filters,
            suggestedTechnologies: filters.requiredTechnologies,
            maxYears: filters.maxYears ? parseInt(filters.maxYears) : null,
            minYears: filters.minYears ? parseInt(filters.minYears) : null,
            maxSalary: filters.maxSalary ? parseInt(filters.maxSalary) : null,
            minSalary: filters.minSalary ? parseInt(filters.minSalary) : null
          }
        })
        this.users = candidatesQuery.data.candidates
        this.setLoadingOff()
      } catch (e) {
        console.log(e, 'error')
        toastNotification(e, 'error')
        this.setLoadingOff()
      }
    },
    getPositions: async function () {
      this.setLoadingOn()
      try {
        const positionsQuery = await ApolloClient.query({
          fetchPolicy: 'no-cache',
          query: getPositionsQuery
        })
        this.especialidadLista = positionsQuery.data.positionTypes
        this.setLoadingOff()
      } catch (e) {
        console.log(e, 'error')
        this.setLoadingOff()
      }
    },
    getTechnologies: async function () {
      this.setLoadingOn()
      try {
        const technologiesQuery = await ApolloClient.query({
          fetchPolicy: 'no-cache',
          query: getTechnologiesQuery
        })
        this.tecnologiasLista = technologiesQuery.data.technologies
        this.setLoadingOff()
      } catch (e) {
        console.log(e, 'error')
        this.setLoadingOff()
      }
    },
    // Percentage of Complete users
    completionPercent(percent) {
      const userObject = { ...percent, ...percent }
      const value = Object.keys(userObject).reduce((acc, val) => {
        const realValue = userObject[val]
        if (realValue !== null && realValue !== '') {
          if (val === 'takenAssesments') {
            if (realValue.length === 4) {
              return acc + 1
            } else return acc
          }
          if (Array.isArray(realValue)) {
            return realValue.length > 0 ? acc + 1 : acc
          }
          return acc + 1
        } else {
          return acc
        }
      }, 0)
      return Math.round((value * 100) / (Object.values(userObject).length - 2))
    },
    sortByColumn(column, order) {
      const orderFactor = order === 'asc' ? 1 : -1
      const userList = [...this.users]
      this.users = userList.sort(function compare(firstEl, secondEl) {
        if (firstEl[column] > secondEl[column]) {
          return 1 * orderFactor
        } else {
          return -1 * orderFactor
        }
      })
    },

    // Reset Filters
    resetFilter() {
      this.filter.email = ''
      this.filter.nombre = ''
      this.filter.especialidad = ''
      this.filter.tecnologias = ''
      this.filter.status = ''
      this.filter.pais = ''
      this.filter.experiencia = 0
      this.filter.maxSalarial = 50000
      this.filter.minSalarial = 0
    }
  }
}
</script>
