import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/index'
import i18n from '@/plugins/i18n'
import ROUTES from '@/constants/routes'
import { getCurrentUser } from '@/services/firebase'

// layouts
import DefaultLayout from '@/layouts/default.vue'
import ClientsLayout from '@/layouts/layoutClients.vue'
import NoHeaderFooter from '@/layouts/noHeaderFooter.vue'
import ProfileLayout from '@/layouts/ProfileLayout.vue'

// Instalar VueRouter
import Vue from 'vue'
import VueRouter from 'vue-router'

// Dashboard
import Admin from '@/views/Admin.vue'
import AdminWelcome from '@/views/AdminWelcome.vue'
import AdminCandidate from '@/views/AdminCandidate.vue'
import AdminCandidates from '@/views/AdminCandidates.vue'
import AdminEvents from '@/views/AdminEvents.vue'
import AdminEventDetail from '@/views/AdminEventDetail.vue'
import AdminEventCreate from '@/views/AdminEventCreate.vue'
import AdminEventEdit from '@/views/AdminEventEdit.vue'

// Sitio
// import Home from '@/views/Home.vue'
import Comunidad from '@/views/Comunidad.vue'
import Eventos from '@/views/Eventos.vue'
import EventosAll from '@/views/EventosAll.vue'
import EventoDetail from '@/views/EventoDetail.vue'
import Cursos from '@/views/Cursos.vue'
import CursosAll from '@/views/CursosAll.vue'
import Mentorias from '@/views/Mentorias.vue'
import Habilidades from '@/views/Habilidades.vue'
import HabilidadesTest from '@/views/HabilidadesTest.vue'
import HabilidadesTestIncompletos from '@/views/HabilidadesTestIncompletos.vue'
import Trabajos from '@/views/Trabajos.vue'
import TrabajosDetail from '@/views/TrabajosDetail.vue'
import Nosotros from '@/views/Nosotros.vue'
import Contrata from '@/views/ContrataDevs.vue'
import Faq from '@/views/Faq.vue'
import Terminos from '@/views/TerminosCondiciones.vue'
import Politica from '@/views/PoliticaPrivacidad.vue'
import SignUp from '@/views/SignUp.vue'
import SignIn from '@/views/SignIn.vue'
import RecuperarContraseña from '@/views/RecuperarContraseña.vue'
import Profile from '@/views/Profile.vue'
// import ProfileEvents from '@/views/ProfileEvents.vue'
import InstruccionesAssesments from '@/views/InstruccionesAssesments.vue'
// import BadgesDetail from '@/views/BadgesDetail.vue'
// import MisTests from '@/views/MisTests.vue'
import TrabajosTech from '@/views/TrabajosTech.vue'
import PageNotFound from '@/views/PageNotFound.vue'
// Profile
import EditProfile from '@/views/Profile/EditProfile.vue'
import TechnologiesProfile from '@/views/Profile/TechnologiesProfile.vue'
import EducationProfile from '@/views/Profile/EducationProfile.vue'
import ExperienceProfile from '@/views/Profile/ExperienceProfile.vue'
import CoursesCertificadesProfile from '@/views/Profile/CoursesCertificadesProfile.vue'
import SocialMediaProfile from '@/views/Profile/SocialMediaProfile.vue'
import LaboralPreferencesProfile from '@/views/Profile/LaboralPreferencesProfile.vue'
import HighPreferencesProfile from '@/views/Profile/HighPreferencesProfile.vue'

// Profile view
import ProfileView from '@/views/Profile/ProfileView.vue'
import EmpresarialProfileView from '@/views/Profile/EmpresarialProfileView.vue'
import EmpresarialCompleteProfileView from '@/views/Profile/EmpresarialCompleteProfileView.vue'

// Profile components
import EditTecnologias from '@/views/Profile/EditTecnologias.vue'
import EditExperienciaProfesional from '@/views/Profile/EditExperienciaProfesional.vue'
import EditEducacionFormal from '@/views/Profile/EditEducacionFormal.vue'
import EditCertificacionesCursos from '@/views/Profile/EditCertificacionesCursos.vue'
import PublicProfile from '@/views/PublicProfile.vue'
import EditProfileAvatar from '@/views/Profile/EditProfileAvatar.vue'

import DashboardProfile from '@/views/Profile/DashboardProfile.vue'

// Tests
import Tests from '@/views/Tests.vue'
import SoftSkillsTest from '@/views/Test/SoftSkillsTest.vue'
import EmotionalBehaviorTest from '@/views/Test/EmotionalBehaviorTest.vue'
import CulturalFitTest from '@/views/Test/CulturalFitTest.vue'
import HardSkillsTest from '@/views/Test/HardSkillsTest.vue'
import TechnicalSkillsTest from '@/views/Test/TechnicalSkillsTest.vue'

// PreCalifica
import PreCalifica from '@/views/PreCalifica.vue'

// Tests
import Interviews from '@/views/Interviews.vue'

// Dashboard Empresas
import CandidateProfile from '@/views/Empresas/CandidateProfile.vue'
import LandingEmpresas from '@/views/Empresas/Landing.vue'
import Paywall from '@/views/Empresas/Paywall.vue'
import SignInEmpresas from '@/views/Empresas/SignIn.vue'
// import SignUpEmpresas from '@/views/Empresas/SignUp.vue'
import SignUpEmpresas from '@/views/Company/SignUp.vue'
import DashboardPremium from '@/views/Empresas/DashboardPremium.vue'
import DashboardKanban from '@/views/Empresas/DashboardKanban.vue'
import DetalleBusqueda from '@/views/Empresas/DetalleBusqueda.vue'
import CreatePool from '@/views/Empresas/CreateNewPool.vue'
import CreatePosition from '@/views/Empresas/CreateNewPosition.vue'
import ClientsProfile from '@/views/Empresas/Profile.vue'
// Upskilling
import Upskilling from '@/views/Upskilling.vue'
import UpskillingB2C from '@/views/UpskillingB2C.vue'
// Summit
import LandingSummit from '@/views/LandingSummit.vue'

// Swag
import Unboxing from '@/views/Unboxing.vue'

// Generación de PDFs
import ProfileDark from '@/views/ProfileDark.vue'
import ProfileLight from '@/views/ProfileLight.vue'
import ProfileLightComplete from '@/views/ProfileLightComplete.vue'

const routes = [
  {
    path: '',
    redirect: '/es/sign-up'
  },
  {
    path: '/admin',
    component: Admin,
    async beforeEnter(to, from, next) {
      if (await getCurrentUser()) {
        return next()
      } else {
        return next('/es')
      }
    },
    children: [
      {
        path: '',
        component: AdminWelcome,
        name: 'AdminWelcome'
      },
      {
        path: 'candidatos',
        component: AdminCandidates,
        name: 'AdminCandidates'
      },
      {
        path: 'candidatos/:id',
        name: 'AdminCandidate',
        component: AdminCandidate
      },
      {
        path: 'eventos',
        component: AdminEvents,
        name: 'AdminEvents'
      },
      {
        path: 'evento/:id',
        component: AdminEventDetail,
        name: 'AdminEventDetail'
      },
      {
        path: 'evento/edit/:id',
        component: AdminEventEdit,
        name: 'AdminEventEdit'
      },
      {
        path: 'evento/create',
        component: AdminEventCreate,
        name: 'AdminEventCreate'
      }
    ]
  },
  {
    path: '/:locale',
    meta: {
      layout: DefaultLayout
    },
    beforeRouteEnter: (to, from, next) => {
      const locale = to.params.locale
      if (!locale) {
        return next('es')
      }
      const supportedLocales = ['es', 'en']
      if (!supportedLocales.includes(locale)) {
        return next(`/${i18n.global.locale}/${locale}`)
      }
      if (i18n.global.locale !== locale) {
        // 4
        i18n.global.locale = locale
      }
      if (to.meta.unprotected && store.getters.isAuthenticated) {
        return next(ROUTES.home)
      }
      if (to.meta.protected && !store.getters.isAuthenticated) {
        return next({
          path: ROUTES.signin,
          query: { redirectTo: to.fullPath }
        })
      }
      if (to.meta.needsLoading) {
        store.dispatch('setLoadingOn')
      }
      if (to.meta.title) {
        if (locale === 'es') {
          document.title = `UTOPICODE | ${to.meta.title.es}`
        } else {
          document.title = `UTOPICODE | ${to.meta.title.en} `
        }
      } else {
        if (locale === 'es') {
          document.title = `UTOPICODE | ${es['home-page'].title}`
        } else {
          document.title = `UTOPICODE | ${en['home-page'].title}`
        }
      }
      next()
    },
    component: {
      template: '<router-view></router-view>'
    },
    children: [
      {
        path: 'perfil',
        async beforeEnter(to, from, next) {
          if (await getCurrentUser()) {
            return next()
          } else {
            return next('/es')
          }
        },
        component: {
          template: '<router-view></router-view>'
        },
        meta: {
          protected: true
        },
        // Profile Pages
        children: [
          {
            path: 'mi-perfil',
            name: 'Profile',
            component: Profile,
            meta: {
              layout: ProfileLayout
            }
          },
          {
            path: 'technologies',
            name: 'TechnologiesProfile',
            component: TechnologiesProfile,
            meta: {
              layout: ProfileLayout
            }
          },
          {
            path: 'educacion',
            name: 'EducationProfile',
            component: EducationProfile,
            meta: {
              layout: ProfileLayout
            }
          },
          {
            path: 'experience',
            name: 'ExperienceProfile',
            component: ExperienceProfile,
            meta: {
              layout: ProfileLayout
            }
          },
          {
            path: 'preferencias-laborales',
            name: 'LaboralPreferencesProfile',
            component: LaboralPreferencesProfile,
            meta: {
              layout: ProfileLayout
            }
          },
          {
            path: 'courses-certifications',
            name: 'CoursesCertificadesProfile',
            component: CoursesCertificadesProfile,
            meta: {
              layout: ProfileLayout
            }
          },
          {
            path: 'preferencias-altas',
            name: 'HighPreferencesProfile',
            component: HighPreferencesProfile,
            meta: {
              layout: ProfileLayout
            }
          },
          {
            path: 'social-media',
            name: 'SocialMediaProfile',
            component: SocialMediaProfile,
            meta: {
              layout: ProfileLayout
            }
          },
          // {
          //   path: ROUTES.profileEvents,
          //   name: 'ProfileEvents',
          //   component: ProfileEvents,
          //   meta: {
          //     layout: ProfileLayout
          //   }
          // },
          // {
          //   path: ROUTES.profileBadges,
          //   name: 'BadgesDetail',
          //   component: BadgesDetail,
          //   meta: {
          //     layout: ProfileLayout
          //   }
          // },
          // {
          //   path: ROUTES.profileTests,
          //   name: 'MisTests',
          //   component: MisTests,
          //   meta: {
          //     layout: ProfileLayout
          //   }
          // },
          {
            path: ROUTES.dashboardProfile,
            name: 'DashboardProfile',
            component: DashboardProfile
          },
          {
            path: ROUTES.editProfile,
            name: 'EditProfile',
            component: EditProfile
          },
          {
            path: ROUTES.editProfileAvatar,
            name: 'EditProfileAvatar',
            component: EditProfileAvatar
          },
          {
            path: ROUTES.editTecnologias,
            name: 'EditTecnologias',
            component: EditTecnologias,
            meta: {
              layout: ProfileLayout
            }
          },
          {
            path: ROUTES.editExperienciaProfesional,
            name: 'EditExperienciaProfesional',
            component: EditExperienciaProfesional,
            meta: {
              layout: ProfileLayout
            }
          },
          {
            path: ROUTES.editEducacionFormal,
            name: 'EditEducacionFormal',
            component: EditEducacionFormal,
            meta: {
              layout: ProfileLayout
            }
          },
          {
            path: ROUTES.editCertificacionesCursos,
            name: 'EditCertificacionesCursos',
            component: EditCertificacionesCursos,
            meta: {
              layout: ProfileLayout
            }
          }
        ]
      },
      {
        path: 'test',
        async beforeEnter(to, from, next) {
          if (await getCurrentUser()) {
            return next()
          } else {
            return next('/es')
          }
        },
        component: {
          template: '<router-view></router-view>'
        },
        meta: {
          protected: true
        },
        children: [
          {
            path: 'tests',
            name: 'Tests',
            component: Tests,
            meta: {
              layout: ProfileLayout
            }
          },
          {
            path: 'habilidades-blandas',
            name: 'SoftSkillsTest',
            component: SoftSkillsTest,
            meta: {
              layout: ProfileLayout
            }
          },
          {
            path: 'comportamiento-emocional',
            name: 'EmotionalBehaviorTest',
            component: EmotionalBehaviorTest,
            meta: {
              layout: ProfileLayout
            }
          },
          {
            path: 'fit-cultural',
            name: 'CulturalFitTest',
            component: CulturalFitTest,
            meta: {
              layout: ProfileLayout
            }
          },
          {
            path: 'habilidades-duras',
            name: 'HardSkillsTest',
            component: HardSkillsTest,
            meta: {
              layout: ProfileLayout
            }
          },
          {
            path: 'habilidades-tecnicas',
            name: 'TechnicalSkillsTest',
            component: TechnicalSkillsTest,
            meta: {
              layout: ProfileLayout
            }
          }
        ]
      },
      {
        path: 'interviews',
        async beforeEnter(to, from, next) {
          if (await getCurrentUser()) {
            return next()
          } else {
            return next('/es')
          }
        },
        component: {
          template: '<router-view></router-view>'
        },
        meta: {
          protected: true
        },
        children: [
          {
            path: 'recibe-entrevistas',
            name: 'Interviews',
            component: Interviews,
            meta: {
              layout: ProfileLayout
            }
          }
        ]
      },
      {
        path: 'precalifica',
        async beforeEnter(to, from, next) {
          if (await getCurrentUser()) {
            return next()
          } else {
            return next('/es')
          }
        },
        component: {
          template: '<router-view></router-view>'
        },
        meta: {
          protected: true
        },
        children: [
          {
            path: 'pre-califica',
            name: 'PreCalifica',
            component: PreCalifica,
            meta: {
              layout: ProfileLayout
            }
          }
        ]
      },
      {
        path: 'empresas',
        component: {
          template: '<router-view></router-view>'
        },
        meta: {
          layout: DefaultLayout,
          protected: true
        },
        // Empresas Pages
        children: [
          {
            path: ROUTES.landing,
            name: 'Landing',
            component: LandingEmpresas
          },
          {
            path: ROUTES.candidateProfile,
            name: 'CandidateProfile',
            component: CandidateProfile,
            meta: {
              layout: ClientsLayout,
              protected: true
            }
          },
          {
            path: ROUTES.dashboardKanban,
            name: 'DashboardKanban',
            component: DashboardKanban,
            meta: {
              layout: ClientsLayout,
              protected: true
            }
          },
          {
            path: ROUTES.dashboardPremium,
            name: 'DashboardPremium',
            component: DashboardPremium,
            meta: {
              layout: ClientsLayout,
              protected: true
            }
          },
          {
            path: ROUTES.clientsProfile,
            name: 'ClientsProfile',
            component: ClientsProfile,
            meta: {
              layout: ClientsLayout,
              protected: true
            }
          },
          {
            path: ROUTES.paywall,
            name: 'Paywall',
            component: Paywall
          },
          {
            path: ROUTES.signInEmpresas,
            name: 'SignInEmpresas',
            component: SignInEmpresas
          },
          {
            path: ROUTES.signUpEmpresas,
            name: 'SignUpEmpresas',
            component: SignUpEmpresas,
            meta: {
              layout: NoHeaderFooter,
              protected: true
            }
          },
          {
            path: ROUTES.detalleBusqueda,
            name: 'DetalleBusqueda',
            component: DetalleBusqueda,
            meta: {
              layout: ClientsLayout,
              protected: true
            }
          },
          {
            path: ROUTES.detalleBusqueda,
            name: 'CreatePool',
            component: CreatePool,
            meta: {
              layout: ClientsLayout,
              protected: true
            }
          },
          {
            path: ROUTES.detalleBusqueda,
            name: 'CreatePosition',
            component: CreatePosition,
            meta: {
              layout: ClientsLayout,
              protected: true
            }
          }
        ]
      },
      {
        path: ROUTES.upskilling,
        name: 'Upskilling',
        component: Upskilling,
        meta: {
          needsLoading: true
        }
      },
      {
        path: ROUTES.upskillingb2c,
        name: 'UpskillingB2C',
        component: UpskillingB2C,
        meta: {
          needsLoading: true
        }
      },
      {
        path: ROUTES.summit,
        name: 'LandingSummit',
        component: LandingSummit,
        meta: {
          needsLoading: true
        }
      },
      {
        path: ROUTES.home,
        name: 'Home',
        // component: Home,
        meta: {
          title: {
            es: 'Devs calificados para el mundo remoto | Homepage',
            en: 'Skilled remote software developers for the world | Homepage'
          },
          needsLoading: true
        },
        redirect: '/es/sign-in'
      },
      {
        path: ROUTES.comunidad,
        name: 'Comunidad',
        component: Comunidad,
        meta: {
          needsLoading: true
        }
      },
      // Eventos Pages
      {
        path: ROUTES.eventos,
        component: {
          template: '<router-view :key="$route.fullPath"></router-view>'
        },
        meta: {
          needsLoading: true
        },
        children: [
          {
            path: '',
            name: 'Eventos',
            component: Eventos
          },
          {
            path: 'all',
            name: 'EventosAll',
            component: EventosAll
          },
          {
            path: ':id',
            name: 'EventoDetail',
            component: EventoDetail
          }
        ]
      },
      // Cursos Pages
      {
        path: ROUTES.cursos,
        component: {
          template: '<router-view></router-view>'
        },
        meta: {
          needsLoading: true
        },
        children: [
          {
            path: '',
            name: 'Cursos',
            component: Cursos
          },
          {
            path: 'all',
            name: 'CursosAll',
            component: CursosAll
          }
        ]
      },
      {
        path: ROUTES.mentorias,
        name: 'Mentorias',
        component: Mentorias,
        meta: {
          needsLoading: true
        }
      },
      {
        path: ROUTES.tickets,
        name: 'Tickets',
        component: Comunidad,
        meta: {
          needsLoading: true
        }
      },
      // Habilidades pages
      {
        path: ROUTES.habilidades,
        component: {
          template: '<router-view></router-view>'
        },
        meta: {
          needsLoading: true
        },
        children: [
          {
            path: ROUTES.cursos,
            async beforeEnter(to, from, next) {
              if (await getCurrentUser()) {
                return next()
              } else {
                return next('/es')
              }
            },
            component: {
              template: '<router-view></router-view>'
            },
            meta: {
              needsLoading: true
            },
            children: [
              {
                path: `${ROUTES.test}/:id`,
                name: 'HabilidadesTest',
                component: HabilidadesTest,
                meta: {
                  protected: true,
                  layout: ProfileLayout
                }
              },
              {
                path: ROUTES.habilidadesTestIncompletos,
                name: 'HabilidadesTestIncompletos',
                component: HabilidadesTestIncompletos,
                meta: {
                  protected: true,
                  needsLoading: false,
                  layout: ProfileLayout
                }
              }
            ]
          },
          {
            path: '',
            name: 'Habilidades',
            component: Habilidades
          },
          {
            path: `${ROUTES.detallesHabilidad}/:id`,
            name: 'InstruccionesAssesments',
            component: InstruccionesAssesments,
            meta: {
              protected: true,
              layout: ProfileLayout
            }
          }
        ]
      },
      {
        path: ROUTES.trabajos,
        component: {
          template: '<router-view></router-view>'
        },
        meta: {
          needsLoading: true,
          layout: DefaultLayout
        },
        children: [
          {
            path: '',
            name: 'Trabajos',
            component: Trabajos,
            meta: {
              unprotected: true
            }
          },
          {
            path: ROUTES.trabajos,
            name: 'Trabajos Tech',
            component: TrabajosTech,
            meta: { layout: DefaultLayout, unprotected: true }
          },
          {
            path: ':id',
            name: 'TrabajosTechDetail',
            component: TrabajosDetail,
            meta: { unprotected: true }
          }
        ]
      },
      {
        path: 'ver-perfil/:id',
        name: 'ProfileView',
        component: ProfileView,
        meta: {
          layout: NoHeaderFooter,
          unprotected: true
        }
      },
      {
        path: 'ver-perfil-empresarial/:id',
        name: 'EmpresarialProfileView',
        component: EmpresarialProfileView,
        meta: {
          layout: NoHeaderFooter,
          unprotected: true
        }
      },
      {
        path: 'ver-perfil-completo/:id',
        name: 'EmpresarialCompleteProfileView',
        component: EmpresarialCompleteProfileView,
        meta: {
          layout: NoHeaderFooter,
          unprotected: true
        }
      },
      {
        path: 'profile-dark-pdf/:id',
        name: 'ProfileDark',
        component: ProfileDark,
        meta: {
          layout: NoHeaderFooter,
          unprotected: true
        }
      },
      {
        path: 'profile-light-pdf/:id',
        name: 'ProfileLight',
        component: ProfileLight,
        meta: {
          layout: NoHeaderFooter,
          unprotected: true
        }
      },
      {
        path: 'profile-light-complete-pdf/:id',
        name: 'ProfileLightComplete',
        component: ProfileLightComplete,
        meta: {
          layout: NoHeaderFooter,
          unprotected: true
        }
      },
      {
        path: ROUTES.nosotros,
        name: 'Nosotros',
        component: Nosotros
      },
      {
        path: ROUTES.contrata,
        name: 'Contrata',
        component: Contrata
      },
      {
        path: ROUTES.faq,
        name: 'Faq',
        component: Faq
      },
      {
        path: ROUTES.terminos,
        name: 'Terminos',
        component: Terminos
      },
      {
        path: ROUTES.politica,
        name: 'Politica',
        component: Politica
      },
      {
        path: ROUTES.signup,
        name: 'SignUp',
        component: SignUp,
        meta: {
          unprotected: true
        }
      },
      {
        path: ROUTES.signin,
        name: 'SignIn',
        component: SignIn,
        meta: {
          unprotected: true
        }
      },
      {
        path: ROUTES.recuperarContraseña,
        name: 'RecuperarContraseña',
        component: RecuperarContraseña
      },
      {
        path: ROUTES.publicProfile,
        name: 'PublicProfile',
        component: PublicProfile,
        meta: { layout: NoHeaderFooter }
      }
    ]
  },
  {
    path: ROUTES.unboxingEs,
    name: 'UnboxingEs',
    component: Unboxing,
    meta: {
      layout: NoHeaderFooter
    }
  },
  {
    path: ROUTES.unboxingEn,
    name: 'UnboxingEn',
    component: Unboxing,
    meta: {
      layout: NoHeaderFooter
    }
  },
  {
    path: '/:catchAll(.*)',
    name: 'PageNotFound',
    component: PageNotFound,
    meta: {
      layout: NoHeaderFooter
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ el: to.hash })
        }, 500)
      })
    }
    return { top: 0, left: 0 }
  }
})

// Agregar navigation guard global
router.beforeEach((to, from, next) => {
  // Obtener el locale de la ruta
  const locale = to.params.locale || 'es'
  
  // Verificar si es un locale soportado
  const supportedLocales = ['es', 'en']
  if (!supportedLocales.includes(locale)) {
    return next('/es')
  }
  
  // Cambiar el idioma si es diferente al actual
  if (i18n.global.locale !== locale) {
    i18n.global.locale = locale
    store.dispatch('toggleLang', i18n)
  }
  
  return next()
})

export default router
