<template>
  <main class="main--wrapper m-6 flex space-x-6">
    <div class="lg:w-9/12">
      <CardPage class="mb-6">
        <template #default>
          <div>
            <div class="card--page--header">
              <Title>
                <template #title>
                  <h3>
                    {{ $t('profile-candidate.lbl-overview') }}
                  </h3>
                </template>
                <template #options>
                  <span
                    class="bg-tertiary rounded-lg text-white font-bold badge badge-sm transform -translate-y-3"
                    >95% MATCH</span
                  >
                </template>
              </Title>
            </div>
            <div class="card--page--body flex space-x-6 items-center">
              <div class="w-6/12 flex space-x-6 items-center">
                <img
                  :src="require(`@/assets/images/avatars/avatar-1.svg`)"
                  class="w-1/3 rounded-full"
                />
                <div class="w-2/3">
                  <h4 class="dark:text-white font-bold">
                    {{ data.user.fullname }}
                  </h4>
                  <div
                    class="dark:text-primary-text text-secondary-text text-sm mb-2"
                  >
                    {{ data.userProfile.speciality }}
                  </div>
                  <div class="mb-1 flex space-x-2">
                    <span
                      class="badge dark:bg-main-dark bg-line-light dark:text-white badge-xs badge-rounded-full"
                      v-for="stack in data.userProfile.technologies"
                      :key="stack"
                      >{{ stack.techName }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="w-6/12">
                <p class="text-secondary-text dark:text-white last">
                  {{ data.userProfile?.about }}
                </p>
                <p
                  class="text-secondary text-sm uppercase font-bold tracking-widest last"
                >
                  Prepared by Utopicode
                </p>
              </div>
            </div>
            <div class="card--page--footer space-x-4">
              <span
                class="bg-seventh text-white py-2 px-3 rounded-lg font-bold uppercase text-xs"
                >Escuchando ofertas</span
              >
              <span
                class="border dark:border-secondary-text border-primary-text dark:text-secondary-text text-primary-text text-white text-xs uppercase py-2 px-3 rounded-lg"
                >Last Active: <b>22.08.21 | 19:00 CST</b></span
              >
            </div>
          </div>
        </template>
      </CardPage>
      <CardPage>
        <template #default>
          <div>
            <div class="card--page--header">
              <Title>
                <template #title>
                  <h4>
                    {{ $t('profile-candidate.lbl-experience') }}
                  </h4>
                </template>
              </Title>
            </div>
            <div
              class="card--page--body divide-y divide-line-light dark:divide-line-dark"
            >
              <div
                class="flex items-start gap-6"
                :class="
                  index === data.userProfile.pastJobs.length - 1
                    ? 'pt-6 mb-0'
                    : index === 0
                    ? 'pt-0 mb-6'
                    : 'pt-6 mb-6'
                "
                v-for="(experience, index) in data.userProfile.pastJobs"
                :key="experience"
              >
                <div class="w-4/12 flex items-start gap-6">
                  <img
                    :src="require(`@/assets/images/avatars/avatar-1.svg`)"
                    class="w-3/12 rounded-lg mt-1"
                  />
                  <div class="w-2/3">
                    <h4 class="dark:text-white font-bold">
                      {{ experience.position }}
                    </h4>
                    <p class="text-secondary last">{{ experience.company }}</p>
                    <p class="text-secondary-text last text-sm">
                      {{ experience.startDate }}-{{ experience.endDate }}
                    </p>
                    <p class="text-secondary-text last text-sm">
                      {{ experience.companyLocation }}
                    </p>
                  </div>
                </div>
                <div class="w-8/12">
                  <p class="text-secondary-text dark:text-white last text-sm">
                    {{ experience.whatBuilt }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </template>
      </CardPage>
    </div>
    <div class="lg:w-4/12">
      <CardPage class="mb-6">
        <template #default>
          <div>
            <div class="card--page--header">
              <Title>
                <template #title>
                  <h4>
                    {{ $t('profile-candidate.lbl-social-media') }}
                  </h4>
                </template>
              </Title>
            </div>
            <div class="card--page--body">
              <SocialMediaSection
                :socialNetworks="data.userProfile.socialNetworks"
              />
            </div>
          </div>
        </template>
      </CardPage>
      <CardPage class="mb-6">
        <template #default>
          <div>
            <div class="card--page--header">
              <Title>
                <template #title>
                  <h4>
                    {{ $t('profile-candidate.lbl-overview') }}
                  </h4>
                </template>
              </Title>
            </div>
            <div class="card--page--body"></div>
          </div>
        </template>
      </CardPage>
      <BoxNotas v-if="data" :user="data" />
    </div>
  </main>
</template>
<script>
import CardPage from '@/components/CardPage'
import Title from '@/components/Title'
import SocialMediaSection from '@/components/empresas/SocialMediaSection'
import BoxNotas from '@/components/profile/BoxNotas'

export default {
  name: 'DashboardCandidato',
  components: {
    CardPage,
    Title,
    BoxNotas,
    SocialMediaSection
  },
  data() {
    return {
      data: {
        user: {
          email: 'esmemag96@gmail.com',
          country: 'México',
          fullname: 'Esmeralda Magdaleno',
          phonenumber: '+525534460865',
          avatar: '9',
          role: null,
          __typename: 'UserType'
        },
        userProfile: {
          socialNetworks: {
            linkedin: 'https://www.linkedin.com/in/esmeraldamagdaleno/',
            behance: '',
            github: 'esmemag96',
            dribble: '',
            twitter: '',
            bitbucket: '',
            __typename: 'UserProfileSocialNetworkType'
          },
          technologies: [
            {
              techName: 'Git',
              level: '4',
              expYears: '4',
              __typename: 'UserProfileTechnologyType'
            },
            {
              techName: 'Ionic',
              level: '2',
              expYears: '1',
              __typename: 'UserProfileTechnologyType'
            },
            {
              techName: 'Vue.js',
              level: '3',
              expYears: '2',
              __typename: 'UserProfileTechnologyType'
            }
          ],
          takenAssesments: [
            {
              id: 1,
              assesment: {
                id: 1,
                name: 'Soft Skills',
                testLength: 50,
                photo: 'icn-soft-skill.svg',
                __typename: 'AssesmentType'
              },
              totalScore: 46,
              subAssesmentsResult: [
                {
                  id: 43,
                  assesment: {
                    id: 8,
                    name: 'Adaptabilidad',
                    testLength: 10,
                    __typename: 'AssesmentType'
                  },
                  totalScore: 10,
                  description:
                    'Refleja capacidad para adaptarse a los cambios, con flexibilidad y buena actitud, buscando opciones, soluciones y herramientas para enfrentarlos adecuadamente. ',
                  __typename: 'AssesmentResultType'
                },
                {
                  id: 37,
                  assesment: {
                    id: 7,
                    name: 'Trabajo en equipo',
                    testLength: 9,
                    __typename: 'AssesmentType'
                  },
                  totalScore: 9,
                  description:
                    'Significa que hay entendimiento de la relevancia que tiene el trabajo en equipo en los objetivos comunes y en los propios. Hay gran disposición y actitud para ser un buen colaborador, ya que se cumple con las propias responsabilidades y se da un extra para ayudar a los demás con las suyas.',
                  __typename: 'AssesmentResultType'
                },
                {
                  id: 31,
                  assesment: {
                    id: 6,
                    name: 'Comunicación',
                    testLength: 8,
                    __typename: 'AssesmentType'
                  },
                  totalScore: 8,
                  description:
                    'Refleja habilidad para organizar las propias ideas y expresarlas de forma asertiva, para que los demás logren entenderlas con facilidad. ',
                  __typename: 'AssesmentResultType'
                },
                {
                  id: 56,
                  assesment: {
                    id: 10,
                    name: 'Persistencia y compromiso',
                    testLength: 10,
                    __typename: 'AssesmentType'
                  },
                  totalScore: 7,
                  description:
                    'Denota la existencia de metas claras; sin embargo, las distractores o nuevos intereses pueden provocar un cambio de rumbo. Recordar que hay que trabajar en los objetivos de forma constante, aunque esto implique renunciar a otras acciones puede ser útil para fomentar esta habilidad. ',
                  __typename: 'AssesmentResultType'
                },
                {
                  id: 25,
                  assesment: {
                    id: 5,
                    name: 'Manejo al fracaso',
                    testLength: 7,
                    __typename: 'AssesmentType'
                  },
                  totalScore: 6,
                  description:
                    'Existe un adecuado manejo de la frustración y habilidad para seguir adelante con los objetivos personales y laborales, a pesar de las dificultades presentadas, sin rendirse fácilmente. Existe disposición para aprender, crecer y mejorar con base en cada uno de los obstáculos.',
                  __typename: 'AssesmentResultType'
                },
                {
                  id: 49,
                  assesment: {
                    id: 9,
                    name: 'Negociación',
                    testLength: 6,
                    __typename: 'AssesmentType'
                  },
                  totalScore: 6,
                  description:
                    'Significa un alto dominio de las habilidades de negociación, enfocadas en conseguir un ganar-ganar entre las personas; esto tomando en cuenta las propias opiniones y las de los demás. ',
                  __typename: 'AssesmentResultType'
                }
              ],
              keyPercentil: 'high',
              __typename: 'AssesmentResultType'
            },
            {
              id: 7,
              assesment: {
                id: 2,
                name: 'Fit Cultural',
                testLength: 50,
                photo: 'icn-fit-cultural.svg',
                __typename: 'AssesmentType'
              },
              totalScore: 40,
              subAssesmentsResult: [
                {
                  id: 67,
                  assesment: {
                    id: 17,
                    name: 'Aprender a aprender',
                    testLength: 10,
                    __typename: 'AssesmentType'
                  },
                  totalScore: 10,
                  description:
                    'Existen grandes habilidades de aprendizaje que denotan constante búsqueda por la actualización y  reconocimiento de la importancia de investigar cuando algo no se sabe.',
                  __typename: 'AssesmentResultType'
                },
                {
                  id: 61,
                  assesment: {
                    id: 16,
                    name: 'Pasión y goce',
                    testLength: 10,
                    __typename: 'AssesmentType'
                  },
                  totalScore: 8,
                  description:
                    'Significa tener un gusto impresionante por aprender y por encontrar nuevos caminos que sean más eficientes para lograr lo deseado. Ser apasionado y encontrar disfrute en lo que se hace, se refleja en los resultados, ',
                  __typename: 'AssesmentResultType'
                },
                {
                  id: 73,
                  assesment: {
                    id: 18,
                    name: 'Respeto a tiempo de entrega',
                    testLength: 10,
                    __typename: 'AssesmentType'
                  },
                  totalScore: 8,
                  description:
                    'Significa respeto por los tiempos de entrega, como resultado de estrategias de planeación y organización alineadas y efectivas. Existe logro de resultados aún cuando el tiempo de entrega está próximo. ',
                  __typename: 'AssesmentResultType'
                },
                {
                  id: 80,
                  assesment: {
                    id: 19,
                    name: 'Creatividad',
                    testLength: 10,
                    __typename: 'AssesmentType'
                  },
                  totalScore: 7,
                  description:
                    'Refleja un nivel de creatividad adecuado. Aunque en ocasiones puede haber dificultad para crear cosas nuevas, hay tendencia a utilizar herramientas ya existentes para ser más eficiente. Entender las necesidades del cliente puede dar información útil para entender si es momento de utilizar la eficiencia o la creatividad. ',
                  __typename: 'AssesmentResultType'
                },
                {
                  id: 92,
                  assesment: {
                    id: 20,
                    name: 'Proactividad',
                    testLength: 10,
                    __typename: 'AssesmentType'
                  },
                  totalScore: 7,
                  description:
                    'Refleja conductas proactivas en el lugar de trabajo. Ocasionalmente hay participación de manera activa en la búsqueda de soluciones y propuestas de cambios para la empresa. Otras veces, hay preferencia por esperar instrucciones y que sea alguien más quien tome la iniciativa. ',
                  __typename: 'AssesmentResultType'
                }
              ],
              keyPercentil: 'high',
              __typename: 'AssesmentResultType'
            },
            {
              id: 13,
              assesment: {
                id: 3,
                name: 'Comportamiento Emocional',
                testLength: 50,
                photo: 'icn-comportamiento-emocional.svg',
                __typename: 'AssesmentType'
              },
              totalScore: 45,
              subAssesmentsResult: [
                {
                  id: 121,
                  assesment: {
                    id: 23,
                    name: 'Empatía',
                    testLength: 10,
                    __typename: 'AssesmentType'
                  },
                  totalScore: 10,
                  description:
                    'Existe capacidad para ponerse en el lugar de los demás y de imaginarse lo que otra persona siente, piensa y necesita. Hay presencia de buenas habilidades de comunicación y capacidad para detectar las necesidades de los demás.',
                  __typename: 'AssesmentResultType'
                },
                {
                  id: 109,
                  assesment: {
                    id: 22,
                    name: 'Humildad',
                    testLength: 10,
                    __typename: 'AssesmentType'
                  },
                  totalScore: 9,
                  description:
                    'Refleja apertura a ideas nuevas y, por ende, a crecer y mejorar, así como entendimiento de que la retroalimentación es algo positivo y de que los comentarios de los demás son una oportunidad para fomentar el desarrollo personal.  ',
                  __typename: 'AssesmentResultType'
                },
                {
                  id: 127,
                  assesment: {
                    id: 24,
                    name: 'Actitud positiva',
                    testLength: 10,
                    __typename: 'AssesmentType'
                  },
                  totalScore: 9,
                  description:
                    'Significa ver el lado positivo de la vida, dar la vuelta a los problemas, buscar soluciones, contagiar a los demás de buena actitud y tener conciencia del para qué se hace lo que se hace. Tener presente el sentido y propósito del trabajo,  facilita el “resistir” aún en los días malos. \r\n',
                  __typename: 'AssesmentResultType'
                },
                {
                  id: 139,
                  assesment: {
                    id: 25,
                    name: 'Consciencia individual',
                    testLength: 10,
                    __typename: 'AssesmentType'
                  },
                  totalScore: 9,
                  description:
                    'Significa que hay consciencia de las decisiones tomadas y la forma en la que éstas repercuten en los demás, así como el impacto que pueden tener en el futuro. Existe eficiencia y practicidad, así como facilidad para detectar cuando algo requiere más esfuerzo para cumplir con los resultados esperados.',
                  __typename: 'AssesmentResultType'
                },
                {
                  id: 103,
                  assesment: {
                    id: 21,
                    name: 'Curiosidad',
                    testLength: 10,
                    __typename: 'AssesmentType'
                  },
                  totalScore: 8,
                  description:
                    'Existe una constante búsqueda por obtener respuestas a las preguntas, interés por mantenerse actualizado y por investigar sobre las herramientas que ayuden a mejorar, lo que facilitará el desarrollo y el aprendizaje. ',
                  __typename: 'AssesmentResultType'
                }
              ],
              keyPercentil: 'high',
              __typename: 'AssesmentResultType'
            },
            {
              id: 19,
              assesment: {
                id: 4,
                name: 'Hard Skills',
                testLength: 38,
                photo: 'icn-hard-skill.svg',
                __typename: 'AssesmentType'
              },
              totalScore: 32,
              subAssesmentsResult: [
                {
                  id: 169,
                  assesment: {
                    id: 29,
                    name: 'Pensamiento Analítico',
                    testLength: 10,
                    __typename: 'AssesmentType'
                  },
                  totalScore: 8,
                  description:
                    'Denota gran habilidad de planeación y conocimiento de las acciones diarias que se tienen que llevar a cabo para sacar adelante los proyectos. Muestra habilidad para priorizar y organizar las tareas para ser lo más eficiente posible. ',
                  __typename: 'AssesmentResultType'
                },
                {
                  id: 157,
                  assesment: {
                    id: 27,
                    name: 'Pensamiento claro',
                    testLength: 9,
                    __typename: 'AssesmentType'
                  },
                  totalScore: 7,
                  description:
                    'Significa una alta apreciación del medio desde un pensamiento claro, lógico y objetivo, así como habilidad para utilizar este pensamiento en la resolución de problemas. ',
                  __typename: 'AssesmentResultType'
                },
                {
                  id: 151,
                  assesment: {
                    id: 26,
                    name: 'Lectura y comprensión',
                    testLength: 7,
                    __typename: 'AssesmentType'
                  },
                  totalScore: 6,
                  description:
                    'Refleja una notable comprensión lectora, así como gran habilidad para entender el significado de la información escrita y aplicarla en la práctica. ',
                  __typename: 'AssesmentResultType'
                },
                {
                  id: 175,
                  assesment: {
                    id: 30,
                    name: 'Planeación',
                    testLength: 6,
                    __typename: 'AssesmentType'
                  },
                  totalScore: 6,
                  description:
                    'Demuestra gran capacidad de análisis e interés por entender la raíz de los problemas. Existe cuestionamiento constante sobre las propias acciones y prevención de las consecuencias de las decisiones tomadas, lo que lleva a buenos resultados. ',
                  __typename: 'AssesmentResultType'
                },
                {
                  id: 163,
                  assesment: {
                    id: 28,
                    name: 'Resolución de problemas',
                    testLength: 6,
                    __typename: 'AssesmentType'
                  },
                  totalScore: 5,
                  description:
                    'Demuestra gran enfoque en la solución de problemas y en la visualización de los siguientes pasos para que los proyectos salgan lo mejor posible.  Refleja la capacidad para siempre buscar la mejor alternativa para darle salida a  los retos que se puedan presentar.  ',
                  __typename: 'AssesmentResultType'
                }
              ],
              keyPercentil: 'high',
              __typename: 'AssesmentResultType'
            }
          ],
          about:
            'Full Stack Developer with over 2 years experience working in large international companies. He is extremely capable in his technical skills and speaks fluent English. He enjoys teamwork and can easily switch between solo and collaborative work. PREPARED BY UTOPIC0DE',
          jobStatus: 'closed',
          jobTypePreference: ['Full Time'],
          availability: '1to2',
          yearsOfExp: 4,
          minSalary: 3500,
          openToNegotiate: true,
          personsInCharge: 1,
          speciality: 'Frontend Software Engineers',
          englishLevel: '4',
          industries: [
            'educación',
            'comunicación digital',
            'fitness y bienestar personal'
          ],
          idealCompany: [
            'con oportunidades de crecimiento',
            'con una gran cultura laboral',
            'con oportunidades de liderazgo'
          ],
          pastJobs: [
            {
              position: 'Application Developer',
              company: 'IBM ',
              companyLocation: 'Mexico ',
              startDate: '2020-03-17',
              endDate: '2021-06-04',
              whatBuilt:
                'Como Application Developer me dediqué a desarrollar probar varias aplicaciones web utilizando Angular y una aplicación móvil en Ionic, para el primer proyecto de Blockchain en México. De igual formé logré desplegar dichas aplicaciones en la nube y monitorearlas mediante diversas funciones de IBM Cloud.\nAl ser un proyecto de Blockchain, aprendí mucho sobre el tema, desde desarrollar Smart Contracts utilizando Hyperledger Fabric hasta la creación y configuración del ambiente en IBM Cloud.\n',
              technologies: [
                'Angular/Angular.js',
                'Kubernetes',
                'IBM Cloud',
                'Git',
                'Ionic',
                'Cypress',
                'Typescript',
                'Blockchain'
              ],
              appOrRepo: null,
              __typename: 'UserProfilePastJobsType'
            },
            {
              position: 'IBM Student',
              company: 'IBM',
              companyLocation: 'Mexico',
              startDate: '2019-03-26',
              endDate: '2020-03-16',
              whatBuilt:
                'Mientras trabajé como IBM Student logré desarrollar diversos demos que utilizaban varias herramientas de la inteligencia artificial Watson, es decir, chatbots, aplicaciones de reconocimiento visual, algoritmos de recomendaciones, etc. \nPara estas demos utilizamos tecnologías como Vue.js, Angular y React para frontend y Node.js y Java para backend.',
              technologies: [
                'Vue.js',
                'Angular/Angular.js',
                'React.js',
                'Docker',
                'Kubernetes',
                'HTML/CSS',
                'Git'
              ],
              appOrRepo: null,
              __typename: 'UserProfilePastJobsType'
            },
            {
              position: 'Frontend Developer',
              company: 'Kimo Solutions',
              companyLocation: 'Mexico',
              startDate: '2017-11-27',
              endDate: '2018-08-20',
              whatBuilt:
                'Desarrollé una aplicación web para un cine utilizando Vue.js y Foundation como framework de CSS. En este proyecto también aprendí a hacer peticiones utilizando Axios y todo sobre conexiones del frontend a API REST.\nAprendí a utilizar Git desde la CLI y a crear repositorios co Bitbucket',
              technologies: [
                'Vue.js',
                'HTML/CSS',
                'git',
                'bitbucket',
                'Axios',
                'JavaScript'
              ],
              appOrRepo: null,
              __typename: 'UserProfilePastJobsType'
            }
          ],
          formalEducation: [
            {
              grade: 'Exchange semester Information Systems',
              school: 'ECE Paris',
              startDate: '2018-09-07',
              endDate: '2018-12-21',
              __typename: 'UserProfileFormalEducationType'
            },
            {
              grade: 'Ingeniería en Tecnologías Computacionales',
              school: 'Tecnológico de Monterrey',
              startDate: '2015-08-10',
              endDate: '2020-06-19',
              __typename: 'UserProfileFormalEducationType'
            }
          ],
          certsAndCourses: [
            {
              name: 'Professional Scrum Developer',
              startDate: '2020-09-30',
              endDate: null,
              certifiedBy: 'SCRUM.org',
              certId: null,
              certUrl: null,
              __typename: 'UserProfileCertsType'
            }
          ],
          registeredEvents: true,
          __typename: 'UserProfileType'
        }
      }
    }
  }
}
</script>
