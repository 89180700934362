<template>
  <div class="w-full lg:w-8/12 lg:pr-6">
    <!-- Card Page Sign Up -->
    <CardPage class="bg-transparentAux">
      <template #default>
        <!-- Body -->
        <div class="card--page--body lg:pl-1 pt-10">
          <!-- Section Experiencia Profesional -->
          <ExperienciaProfesionalSection
            v-if="userProfile"
            :userPastJobs="userProfile?.pastJobs"
            :userPastProjects="userProfile?.pastProjects"
          />
          <!-- End Section Experiencia Profesional -->
        </div>
        <!-- End Body -->
      </template>
    </CardPage>
    <!-- End Card Page Sign Up -->
  </div>
</template>

<script>
import CardPage from '@/components/CardPage'
import ExperienciaProfesionalSection from '@/components/profile/ExperienciaProfesionalSection'

import ApolloClient from '@/graphql/apolloClient'
import assesmentCategoriesQuery from '@/graphql/queries/assesmentCategories'
import userProfileQuery from '@/graphql/queries/userProfile'
import { mapGetters, mapActions } from 'vuex'
import { toastNotification } from '@/services/toastNotification'

export default {
  name: 'Profile',
  data() {
    return {
      userProfile: null,
      assesmentCategories: []
    }
  },
  components: {
    CardPage,
    ExperienciaProfesionalSection
  },
  updated() {
    this.getAssesmentCategories()
  },
  mounted() {
    this.setLoadingOn()
    this.getUserProfile()
    this.getAssesmentCategories()
  },
  computed: {
    ...mapGetters(['token', 'user'])
  },
  methods: {
    ...mapActions(['setLoadingOn', 'setLoadingOff']),
    getAssesmentCategories: async function () {
      try {
        const habilitiesQuery = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale
            }
          },
          fetchPolicy: 'network-only',
          query: assesmentCategoriesQuery
        })
        this.assesmentCategories = habilitiesQuery.data.assesmentCategories
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    getUserProfile: async function () {
      try {
        const userProfileData = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale,
              authorization: `Bearer ${await this.token}`
            }
          },
          fetchPolicy: 'network-only',
          query: userProfileQuery
        })
        this.userProfile = userProfileData.data.userProfile
        this.setLoadingOff()
      } catch (e) {
        toastNotification(e, 'error')
      }
    }
  }
}
</script>
