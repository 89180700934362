<template>
  <main class="main--wrapper">
    <header>
      <div class="flex justify-between pt-20">
        <img
          src="@/assets/images/summit/logo.svg"
          class="shadow-svg-xl m-auto w-36 lg:w-auto"
          alt="Utopicode"
        />
        <div class="m-auto">
          <img
            src="@/assets/images/summit/evento_virtual.svg"
            class="shadow-svg-xl mx-12"
            alt="Utopicode"
          />
        </div>
      </div>
    </header>
    <Hero
      :hero-title="$t('landing-summit.title')"
      :hero-description="$t('landing-summit.description')"
      class="variation lg:mb-10"
    >
      <div class="lg:w-8/12 mx-auto flex flex-col items-center justify-center">
        <h2
          class="text-center text-white font-bold text-3xl lg:text-6xl mx-auto lg:leading-tight lg:w-10/12 mb-10"
          v-html="$t('landing-summit.title')"
        ></h2>
        <p
          class="text-xl text-secondary-text text-center mb-8"
          v-html="$t('landing-summit.description')"
        ></p>
        <a
          href="#conferences"
          target="_self"
          class="text-white btn btn-lg bg-fifth btn-rounded-lg"
        >
          {{ $t('landing-summit.btn-sign-up') }}
        </a>
      </div>
    </Hero>
    <div class="flex justify-center mb-4">
      <img
        src="@/assets/images/summit/google-developers-logo.svg"
        class="shadow-svg-xl mr-6"
        alt="Utopicode"
      />
      <Logo class="shadow-svg-xl" />
    </div>
    <div class="flex justify-end mb-10">
      <img
        src="@/assets/images/summit/lineas_verdes.svg"
        class="shadow-svg-xl lg:w-auto w-1/2"
        alt="Utopicode"
      />
    </div>
    <!-- Sección Speakers  -->
    <div class="border-t-2 border-opacity-20">
      <section class="my-10 lg:my-20 content--wrapper">
        <img
          src="@/assets/images/summit/puntos_morados.svg"
          class="shadow-svg-xl m-auto"
          alt="Utopicode"
        />
        <h2
          class="title--h3 text-white font-bold text-center lg:my-20 my-10"
          v-html="$t('landing-summit.speakers-title')"
        ></h2>
        <div class="grid lg:grid-cols-3 grid-cols-1 gap-4">
          <template v-for="speaker in speakers" :key="speaker.role">
            <Speaker
              :name="speaker.name"
              :role="speaker.role"
              :event="speaker.event"
              :filename="speaker.filename"
            />
          </template>
        </div>
        <img
          src="@/assets/images/summit/puntos_morados.svg"
          class="shadow-svg-xl m-auto"
          alt="Utopicode"
        />
      </section>
    </div>
    <!-- End Sección Speakers -->
    <!-- Sección Agenda  -->
    <!-- <div class="border-t-2 border-opacity-20 m-4">
      <section class="my-10 lg:my-20 px-10">
        <h2
          class="title--h3 text-white font-bold text-center lg:my-20 my-10"
          v-html="$t('landing-summit.agenda-title')"
        ></h2>
        <div class="grid lg:grid-cols-2 grid-cols-1 flex justify-center">
          <div>
            <h2 class="text-center text-seventh font-bold text-3xl p-8">
              Junio 24
            </h2>
            <template
              v-for="item in agendaItems"
              :key="item.title"
              class="w-1/2"
            >
              <AgendaItem
                :time="item.time"
                :title="item.title"
                :speaker="item.speaker"
                :description="item.description"
              />
            </template>
          </div>
          <div>
            <h2 class="text-center text-seventh font-bold text-3xl p-8">
              Junio 25
            </h2>
            <template v-for="item in agendaItems2" :key="item.title">
              <AgendaItem
                :time="item.time"
                :title="item.title"
                :speaker="item.speaker"
                :description="item.description"
              />
            </template>
          </div>
        </div>
      </section>
    </div> -->
    <!-- End Sección Agenda  -->
    <div class="border-t-2 border-opacity-20 m-4">
      <section class="my-10 lg:my-20 px-10" id="conferences">
        <h2
          class="title--h3 text-white font-bold text-center lg:my-20 my-10"
          v-html="$t('landing-summit.agenda-title')"
        ></h2>
        <div
          class="grid lg:grid-cols-4 grid-cols-1 flex justify-center"
          v-if="isAuthenticated"
        >
          <template v-for="item in speakers" :key="item.name">
            <ConferenceVideo
              :source="item.video"
              :event="item.event"
              :speaker="item.name"
              :description="item.description"
            />
          </template>
        </div>
        <div
          class="lg:w-8/12 mx-auto flex flex-col items-center justify-center"
          v-else
        >
          <h2 class="lg:text-xl text-center text-secondary-text mb-2">
            {{ $t('landing-summit.lbl-sign-in') }}s
          </h2>
          <router-link
            :to="{
              name: 'SignUp',
              params: {
                summit: summit
              }
            }"
            class="btn btn-lg btn-primary btn-rounded-lg mx-auto"
            title=""
            >{{ $t('header.sign-up') }}
          </router-link>
        </div>
      </section>
    </div>
    <!-- <div class="lg:w-8/12 mx-auto flex flex-col items-center justify-center">
      <h2
        class="title--h5 text-white text-center lg:my-10 my-5"
        v-html="$t('landing-summit.lbl-register')"
      ></h2>
      <a
        href="https://utopicode.io/es/comunidad/evento/49"
        target="_blank"
        class="text-white btn btn-lg bg-fifth btn-rounded-lg mb-10"
      >
        {{ $t('landing-summit.btn-sign-up') }}
      </a>
    </div> -->
    <div class="flex justify-start">
      <img
        src="@/assets/images/summit/lineas_amarillas.svg"
        class="shadow-svg-xl lg:w-auto w-1/2"
        alt="Utopicode"
      />
    </div>
    <div
      class="mx-auto flex flex-col items-center justify-center mb-14 p-14 border-t-2 border-opacity-20"
    >
      <h2
        class="title--h5 text-white text-center font-bold lg:my-10 my-5"
        v-html="$t('landing-summit.lbl-networking')"
      ></h2>
      <h2 class="text-primary-text lg:text-xl text-md text-center">
        {{ $t('landing-summit.networking-description') }}
      </h2>
      <video class="w-auto my-10 lg:w-8/12" controls>
        <source
          src="https://webdynamiccontentutopicode.s3.us-west-2.amazonaws.com/6181e2838f1b6a1e97422065_Gather+Events+020-025-transcode.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <a
        href="https://discord.gg/TDfsZjYftF"
        target="_blank"
        class="text-white btn btn-md bg-secondary-text btn-rounded-lg"
      >
        {{ $t('landing-summit.btn-community') }}
      </a>
    </div>
    <div class="border-t-2 border-opacity-20 flex justify-center mb-4 p-20">
      <img
        src="@/assets/images/summit/google-developers-logo.svg"
        class="shadow-svg-xl mr-20"
        alt="Utopicode"
      />
      <Logo class="shadow-svg-xl" />
    </div>
    <div
      class="border-t-2 border-opacity-20 flex flex-col items-center justify-center mb-4 p-20 text-secondary-text"
    >
      <svg
        width="24"
        height="24"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        class="fill-current mb-4"
      >
        <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
        <path
          d="M464 64C490.5 64 512 85.49 512 112C512 127.1 504.9 141.3 492.8 150.4L275.2 313.6C263.8 322.1 248.2 322.1 236.8 313.6L19.2 150.4C7.113 141.3 0 127.1 0 112C0 85.49 21.49 64 48 64H464zM217.6 339.2C240.4 356.3 271.6 356.3 294.4 339.2L512 176V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V176L217.6 339.2z"
        />
      </svg>
      <a
        href="mailto:devcommunitysummit@gmail.com"
        target="_blank"
        class="text-summit underline"
      >
        {{ $t('landing-summit.contact-mail') }}
      </a>
    </div>
    <div
      class="lg:w-8/12 mx-auto border-t-2 border-opacity-20 text-center p-20 text-secondary-text"
    >
      <p>{{ $t('landing-summit.lbl-social') }}</p>
      <ul class="flex flex-wrap justify-center gap-x-6">
        <li>
          <a
            href="https://www.instagram.com/utopicode_dev_community"
            title="Instagram"
            target="_blank"
          >
            <svg viewBox="0 0 22 22" class="fill-current w-6 h-6">
              <path
                d="M10.7139 5.69141C7.71387 5.69141 5.32324 8.12891 5.32324 11.082C5.32324 14.082 7.71387 16.4727 10.7139 16.4727C13.667 16.4727 16.1045 14.082 16.1045 11.082C16.1045 8.12891 13.667 5.69141 10.7139 5.69141ZM10.7139 14.5977C8.79199 14.5977 7.19824 13.0508 7.19824 11.082C7.19824 9.16016 8.74512 7.61328 10.7139 7.61328C12.6357 7.61328 14.1826 9.16016 14.1826 11.082C14.1826 13.0508 12.6357 14.5977 10.7139 14.5977ZM17.5576 5.50391C17.5576 4.80078 16.9951 4.23828 16.292 4.23828C15.5889 4.23828 15.0264 4.80078 15.0264 5.50391C15.0264 6.20703 15.5889 6.76953 16.292 6.76953C16.9951 6.76953 17.5576 6.20703 17.5576 5.50391ZM21.1201 6.76953C21.0264 5.08203 20.6514 3.58203 19.4326 2.36328C18.2139 1.14453 16.7139 0.769531 15.0264 0.675781C13.292 0.582031 8.08887 0.582031 6.35449 0.675781C4.66699 0.769531 3.21387 1.14453 1.94824 2.36328C0.729492 3.58203 0.354492 5.08203 0.260742 6.76953C0.166992 8.50391 0.166992 13.707 0.260742 15.4414C0.354492 17.1289 0.729492 18.582 1.94824 19.8477C3.21387 21.0664 4.66699 21.4414 6.35449 21.5352C8.08887 21.6289 13.292 21.6289 15.0264 21.5352C16.7139 21.4414 18.2139 21.0664 19.4326 19.8477C20.6514 18.582 21.0264 17.1289 21.1201 15.4414C21.2139 13.707 21.2139 8.50391 21.1201 6.76953ZM18.8701 17.2695C18.542 18.207 17.792 18.9102 16.9014 19.2852C15.4951 19.8477 12.2139 19.707 10.7139 19.707C9.16699 19.707 5.88574 19.8477 4.52637 19.2852C3.58887 18.9102 2.88574 18.207 2.51074 17.2695C1.94824 15.9102 2.08887 12.6289 2.08887 11.082C2.08887 9.58203 1.94824 6.30078 2.51074 4.89453C2.88574 4.00391 3.58887 3.30078 4.52637 2.92578C5.88574 2.36328 9.16699 2.50391 10.7139 2.50391C12.2139 2.50391 15.4951 2.36328 16.9014 2.92578C17.792 3.25391 18.4951 4.00391 18.8701 4.89453C19.4326 6.30078 19.292 9.58203 19.292 11.082C19.292 12.6289 19.4326 15.9102 18.8701 17.2695Z"
              />
            </svg>
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/utopicoders/"
            title="Facebook"
            target="_blank"
          >
            <svg viewBox="0 0 12 24" class="fill-current w-6 h-5">
              <path
                d="M3 8H0V12H3V24H8V12H11.642L12 8H8V6.333C8 5.378 8.192 5 9.115 5H12V0H8.192C4.596 0 3 1.583 3 4.615V8Z"
              />
            </svg>
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/utopicode1"
            title="Twitter"
            target="_blank"
          >
            <svg viewBox="0 0 26 21" class="fill-current w-6 h-6">
              <path
                d="M26 2.48611C25.0434 2.90817 24.0153 3.19242 22.9363 3.32055C24.0381 2.66484 24.8842 1.62582 25.2818 0.387613C24.2515 0.994873 23.1097 1.43632 21.8942 1.67427C20.9224 0.643868 19.5347 0 18.0007 0C14.5567 0 12.0261 3.1935 12.8039 6.50866C8.372 6.28794 4.44167 4.1776 1.81025 0.970109C0.41275 3.35285 1.0855 6.4699 3.46017 8.04835C2.587 8.02035 1.76367 7.7824 1.04542 7.3851C0.986917 9.84106 2.75817 12.1387 5.3235 12.6502C4.57275 12.8526 3.7505 12.9 2.91417 12.7406C3.59233 14.8466 5.56183 16.3788 7.8975 16.4219C5.655 18.1693 2.82967 18.95 0 18.6183C2.36058 20.1225 5.16533 21 8.177 21C18.0808 21 23.6763 12.6868 23.3382 5.23062C24.3804 4.48231 25.285 3.54881 26 2.48611Z"
              />
            </svg>
          </a>
        </li>
      </ul>
    </div>
  </main>
</template>
<script>
import Hero from '@/components/FullHero'
import Logo from '@/components/svg/logo'
import Speaker from '@/components/Speaker'
import { mapGetters, mapActions } from 'vuex'
// import AgendaItem from '@/components/AgendaItem'
import ConferenceVideo from '@/components/ConferenceVideo'

export default {
  name: 'Upskilling',
  components: {
    Hero,
    Logo,
    Speaker,
    ConferenceVideo
  },
  computed: {
    ...mapGetters(['user', 'isAuthenticated'])
  },
  created: function () {
    this.setLoadingOff()
  },
  data() {
    return {
      summit: true,
      speakers: [
        {
          name: 'Yslen González',
          role: 'Cloud & DevOps en @Improving',
          event: 'Intro a DevOps: ¿Qué? ¿Cómo? y ¿Por qué?',
          filename: 'ylsen.png',
          video: 'https://www.youtube.com/embed/ug04yvjW_GM',
          description:
            'Mi viaje tecnológico me llevó a la nube y luego a DevOps, lo que junto con mi pasión por la IA me llevó a aprender sobre MLOps y querer ingresar a este mundo completamente nuevo.'
        },
        {
          name: 'María Emilia Torres',
          role: 'Co-Founder & COO en Utopicode',
          event: 'How to Ace a tech interview?',
          filename: 'Maem.png',
          video: 'https://www.youtube.com/embed/dUe__UOafKM',
          description:
            'Muchas veces a la hora de las entrevistas no podemos exponer al 100% todo lo que nuestro perfil representa, ya sea por los nervios, por la inexperiencia con las entrevistas, etc.'
        },
        {
          name: 'Stephanie Saveedra',
          role: 'Lead en Google Developer Student Club',
          event: '¿Rockeando con tecnología en Latinoamérica?',
          filename: 'steph.png',
          video: 'https://www.youtube.com/embed/DBi5aiDHU9I',
          description:
            '¿Cuál es la situación de América Latina en términos de tecnología? Mientras el ecosistema de la tecnología crece aceleradamente se rodea de oportunidades para innovar.'
        },
        {
          name: 'Esmeralda Magadaleno',
          role: 'Frontend Developer en Utopicode',
          event: 'Qué es blockchain y casos de uso',
          filename: 'Esme.png',
          video: 'https://www.youtube.com/embed/ohev26XL2vk',
          description:
            'Acompañamos a esta charla donde hablaremos de blockchain y sus casos de uso más populares en la actualidad. ¿Cómo puedo empezar?, ¿Que necesito?, ¿Dónde lo puedo usar?'
        },
        {
          name: 'Juan O. del Toro',
          role: 'Instructor código facilito, frontend Xteam',
          event: 'Patrones de React Js que debes aprender',
          filename: 'Juan.png',
          video: 'https://www.youtube.com/embed/hBLr7iMi1jU',
          description:
            'Los patrones de diseño son una parte importante en el desarrollo de software, ayudándonos a comunicarnos de manera eficiente con nuestro equipo de trabajo, estableciendo un lenguaje en común. Por eso mismo en esta charla vamos a ver cuales son los patrones de react que deberias de aprender en ReactJS.'
        },
        {
          name: 'Naren Castellón',
          role: 'Machine Learning-Data Sciences',
          event: 'Análisis de Series de Tiempo Multivariados',
          filename: 'Naren.png',
          video: 'https://www.youtube.com/embed/soX_0Ceh1KI',
          description:
            'El análisis de series de tiempo es algo sumamente importante en data science, ya que nos permite ver cómo ciertos fenómenos afectan el comportamiento de determinadas cosas. Es por eso, que es importante analizar cada una de las variables y su dinámica en el tiempo. En este taller vamos a aprender cómo podemos llevar esto acabo.'
        },
        {
          name: 'Mauricio Chavez Olea',
          role: 'Backend Engineer en UBER',
          event: 'Crea tu primer juego de plataformas 2D con Unity',
          filename: 'Mauricio.png',
          video: 'https://www.youtube.com/embed/QjNi3t9NlIU',
          description:
            'Los videojuegos es algo que a casi todos nos encanta, pero muchas veces nos da miedo entrar al mundo del desarrollo de estos, ya sea por la complejidad o porque ya tenemos experiencia en otra área del desarrollo y no sabemos si aventurarnos.'
        },
        {
          name: 'Pablo Esteban',
          role: 'Lead en Google Developer Student Club',
          event:
            'Metaverso: Marketing en la economía creativa con realidad aumentada.',
          filename: 'Pablo.png',
          video: 'https://www.youtube.com/embed/3Ls_2ufZO2Q',
          description:
            'Las tecnologías de realidad aumentada/ virtual son el futuro del e-commerce y la vida como la conocemos pero ¿Cómo podemos posicionar nuestra empresa con estas tecnologías inmersivas? En esta plática observaremos los posibles enfoques de la realidad aumentada para desarrollar experiencias únicas con sparkAR y así iniciarnos en la economía creativa.'
        }
      ],
      agendaItems: [
        { time: '14:45', title: 'Apertura Summit' },
        {
          time: '15:00',
          title: 'How to Ace a tech interview?',
          speaker: 'Daniela Alvarez - Senior IT Recruiter en UTOPICODE',
          description:
            'Muchas veces a la hora de las entrevistas no podemos exponer al 100% todo lo que nuestro perfil representa, ya sea por los nervios, por la inexperiencia con las entrevistas, etc.'
        },
        {
          time: '16:00',
          title:
            'Metaverso: Marketing en la economía creativa con realidad aumentada.',
          speaker: 'Pablo Esteban - GDSC Lead',
          description:
            'Las tecnologías de realidad aumentada/ virtual son el futuro del e-commerce y la vida como la conocemos pero ¿Cómo podemos posicionar nuestra empresa con estas tecnologías inmersivas? En esta plática observaremos los posibles enfoques de la realidad aumentada para desarrollar experiencias únicas con sparkAR y así iniciarnos en la economía creativa.'
        },
        {
          time: '17:15',
          title: 'Coffee Break'
        },
        {
          time: '17:30',
          title: 'Networking Room'
        },
        {
          time: '18:00',
          title: 'Qué es blockchain y casos de uso',
          speaker: 'Esme Magadaleno - Frontend Developer',
          description:
            'Acompañamos a esta charla donde hablaremos de blockchain y sus casos de uso más populares en la actualidad. ¿Cómo puedo empezar?, ¿Que necesito?, ¿Dónde lo puedo usar?'
        },
        {
          time: '19:00',
          title: 'Análisis de Series de Tiempo Multivariados',
          speaker: 'Naren Castellón - Machine Learning-Data Sciences',
          description:
            'El análisis de series de tiempo es algo sumamente importante en data science, ya que nos permite ver cómo ciertos fenómenos afectan el comportamiento de determinadas cosas. Es por eso, que es importante analizar cada una de las variables y su dinámica en el tiempo. En este taller vamos a aprender cómo podemos llevar esto acabo.'
        }
      ],
      agendaItems2: [
        { time: '15:45', title: 'Apertura Summit' },
        {
          time: '16:00',
          title: 'Intro a DevOps: ¿Qué? ¿Cómo? Y ¿Por qué?',
          speaker: 'Yslen González - Cloud & DevOps Engineer',
          description:
            'Mi viaje tecnológico me llevó a la nube y luego a DevOps, lo que junto con mi pasión por la IA me llevó a aprender sobre MLOps y querer ingresar a este mundo completamente nuevo.'
        },
        {
          time: '17:00',
          title: 'Crea tu primer juego de plataformas 2D con Unity',
          speaker: 'Mauricio Chavez Olea - Backend Engineer at Uber',
          description:
            'Los videojuegos es algo que a casi todos nos encanta, pero muchas veces nos da miedo entrar al mundo del desarrollo de estos, ya sea por la complejidad o porque ya tenemos experiencia en otra área del desarrollo y no sabemos si aventurarnos.'
        },
        {
          time: '18:15',
          title: 'Coffee Break'
        },
        {
          time: '18:30',
          title: 'Networking Room'
        },
        {
          time: '19:00',
          title: 'Patrones de React Js que debes aprender',
          speaker: 'Juan O. del Toro - Frontend Developer - Instructor',
          description:
            'Los patrones de diseño son una parte importante en el desarrollo de software, ayudándonos a comunicarnos de manera eficiente con nuestro equipo de trabajo, estableciendo un lenguaje en común. Por eso mismo en esta charla vamos a ver cuales son los patrones de react que deberias de aprender en ReactJS.'
        },
        {
          time: '20:00',
          title: '¿Rockeando con tecnología en Latinoamérica?',
          speaker: 'Stephanie Saveedra - GDSC Lead',
          description:
            '¿Cuál es la situación de América Latina en términos de tecnología? Mientras el ecosistema de la tecnología crece aceleradamente se rodea de oportunidades para innovar.'
        }
      ]
    }
  },
  methods: {
    ...mapActions(['setLoadingOff'])
  }
}
</script>
