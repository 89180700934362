<template>
  <div
    class="tooltip--globe !p-0 m-0 tooltip--xs !top-[110px] !right-[50%] !w-[290px] flex gap-4 items-stretch !bg-[#DB7E6E] lg:transform lg:translate-x-1/2 lg:translate-y-1/3"
  >
    <div class="w-full absolute mt-[-19px] flex justify-center">
      <div
        class="w-0 h-0 border-l-[10px] border-b-[#DB7E6E] border-r-[10px] border-b-[20px] border-l-transparent border-r-transparent"
      ></div>
    </div>

    <div class="w-full h-full m-0 py-4 pl-4 flex-1">
      <p class="text-white font-extrabold mb-0">{{ title }}</p>
      <div class="flex items-center">
        <svg
          width="25"
          height="25"
          class="min-w-[25px] mr-2 !mt-[-5px]"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.79492 12.4478C2.79492 17.698 7.05107 21.9542 12.3013 21.9542C17.549 21.9482 21.8017 17.6955 21.8077 12.4478C21.8077 7.19755 17.5515 2.94141 12.3013 2.94141C7.05107 2.94141 2.79492 7.19755 2.79492 12.4478ZM4.42459 12.4478C4.42459 8.09759 7.95111 4.57107 12.3013 4.57107C16.649 4.57705 20.172 8.10007 20.178 12.4478C20.178 16.798 16.6515 20.3245 12.3013 20.3245C7.95111 20.3245 4.42459 16.798 4.42459 12.4478ZM8.60706 16.947C8.39079 16.9481 8.18323 16.8619 8.03125 16.708C7.71352 16.3899 7.71352 15.8745 8.03125 15.5564L11.1448 12.4475L8.04347 9.34159C7.74431 9.02053 7.75314 8.52022 8.06344 8.20992C8.37374 7.89962 8.87405 7.89079 9.1951 8.18996L12.2972 11.2967L15.4082 8.19031C15.6113 7.97231 15.9173 7.88258 16.206 7.95631C16.4947 8.03004 16.7201 8.25547 16.7938 8.54418C16.8676 8.83288 16.7778 9.13881 16.5598 9.34194L13.448 12.4492L16.5612 15.5669C16.7165 15.7182 16.8041 15.9259 16.8041 16.1427C16.8041 16.3596 16.7165 16.5672 16.5612 16.7185C16.4085 16.8714 16.2014 16.9574 15.9854 16.9575C15.7703 16.9529 15.5647 16.8676 15.4096 16.7185L12.2955 13.5999L9.18288 16.708C9.03312 16.8652 8.82408 16.9519 8.60706 16.947Z"
            fill="white"
          />
        </svg>

        <p class="text-xs dark:text-white font-bold p-0 italic text-white last">
          {{ message }}
        </p>
      </div>
    </div>

    <router-link
      :to="url"
      class="max-w-[50px] flex-grow p-0 m-0 flex-1 border-l border-white flex items-center justify-center"
    >
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.3016 12.2573L6.73633 20.1894V21.393L17.3361 12.603L17.3279 11.8886L6.73633 3.50586V4.68638L16.3016 12.2573Z"
          fill="white"
        />
      </svg>
    </router-link>
  </div>
</template>
<script>
export default {
  name: 'ErrorBadgeTooltip',
  props: {
    title: String,
    message: String,
    url: String
  }
}
</script>
