import gql from 'graphql-tag'

const saveSocialNetworks = gql`
  mutation saveSocialNetworksMutation(
    $github: String
    $linkedin: String
    $behance: String
    $dribble: String
    $twitter: String
    $bitbucket: String
    $portfolio: String
    $id: Int
  ) {
    updateSocialNetworks(
      id: $id
      github: $github
      linkedin: $linkedin
      behance: $behance
      dribble: $dribble
      twitter: $twitter
      bitbucket: $bitbucket
      portfolio: $portfolio
    ) {
      github
      linkedin
      behance
      dribble
      twitter
      bitbucket
      portfolio
    }
  }
`

export default saveSocialNetworks
