<template>
  <div>
    <p
      class="text-lg dark:text-primary-text text-secondary-text text-center mb-10"
    >
      {{ title }}
    </p>
    <div
      class="grid grid-cols-2 lg:grid-cols-6 items-center justify-center gap-10 mb-10 dark:text-white text-secondary-text"
    >
      <a href="https://novopayment.com/" title="novopayment" target="_blank">
        <LogoNovo class="mx-auto w-11/12" alt="Briq" />
      </a>
      <a href="https://yayzy.com/" title="Yayzy" target="_blank">
        <LogoYayzy class="mx-auto w-10/12" alt="Yayzy" />
      </a>
      <a href="https://siclo.com/" title="Siclo" target="_blank">
        <LogoSiclo class="mx-auto w-8/12" alt="Siclo" />
      </a>
      <a href="https://konfio.mx/" title="Konfio" target="_blank">
        <LogoKonfio class="mx-auto w-10/12" alt="Konfio" />
      </a>
      <a href="https://ai360.mx/" title="ai360" target="_blank">
        <LogoAi360 class="mx-auto w-7/12" alt="ai360" />
      </a>
      <a href="https://flat.mx/" title="Flat" target="_blank">
        <LogoFlat class="mx-auto w-7/12" alt="Flat" />
      </a>
    </div>
    <div
      class="grid grid-cols-2 lg:grid-cols-5 items-center justify-center gap-10 dark:text-white text-secondary-text"
    >
      <a href="https://www.acasa.com.co/" title="Acasa" target="_blank">
        <LogoAcasa
          class="mx-auto w-5/12 lg:w-9/12 col-start-1 col-end-3 lg:col-start-auto lg:col-end-auto"
          alt="acasa"
        />
      </a>
      <a href="https://cumplo.mx/" title="Cumplo" target="_blank">
        <LogoCumplo class="mx-auto w-9/12" alt="Cumplo" />
      </a>
      <a href="https://www.mtrade.mx/" title="mTrade" target="_blank">
        <LogoMtrade class="mx-auto w-9/12" alt="Mtrade" />
      </a>
      <a href="https://zebrands.mx/" title="Zebrands" target="_blank">
        <LogoZebrands class="mx-auto w-9/12" alt="Zebrands" />
      </a>
      <a href="https://www.briq.mx/" title="briq.mx" target="_blank">
        <LogoBriq class="mx-auto w-7/12" alt="Briq" />
      </a>
    </div>
  </div>
</template>

<script>
import LogoYayzy from '@/components/svg/clients/logoYayzy.vue'
import LogoSiclo from '@/components/svg/clients/logoSiclo.vue'
import LogoKonfio from '@/components/svg/clients/logoKonfio.vue'
import LogoAi360 from '@/components/svg/clients/logoAi360.vue'
import LogoFlat from '@/components/svg/clients/logoFlat.vue'
import LogoAcasa from '@/components/svg/clients/logoAcasa.vue'
import LogoCumplo from '@/components/svg/clients/logoCumplo.vue'
import LogoMtrade from '@/components/svg/clients/logoMtrade.vue'
import LogoZebrands from '@/components/svg/clients/logoZebrands.vue'
import LogoBriq from '@/components/svg/clients/logoBriq.vue'
import LogoNovo from '@/components/svg/clients/logoNovo.vue'

export default {
  name: 'LogosGrid',
  components: {
    LogoYayzy,
    LogoSiclo,
    LogoKonfio,
    LogoAi360,
    LogoFlat,
    LogoAcasa,
    LogoCumplo,
    LogoMtrade,
    LogoZebrands,
    LogoBriq,
    LogoNovo
  },
  props: { title: String }
}
</script>
