<template>
  <main class="main--wrapper">
    <Hero
      :hero-title="$t('upskilling-b2c.title')"
      :hero-image="'B2C-hero.png'"
      :hero-description="$t('upskilling-b2c.description')"
      class="lg:min-h-screen variation lg:mb-10 hero--image--dark m-0"
    >
      <div class="lg:flex justify-between lg:space-x-10">
        <div class="lg:w-7/12 w-12/12 lg:mb-0 mb-12">
          <img src="@/assets/images/upskilling/cody-b2c.svg" alt="" />
        </div>
        <div class="lg:w-5/12 w-12/12">
          <h2
            class="text-center text-white font-bold text-2xl lg:text-6xl mx-auto lg:leading-tight lg:w-12/12 mb-10"
            v-html="$t('upskilling-b2c.title')"
          ></h2>
          <p
            class="text-2xl text-white text-center mb-16 font-bold"
            v-html="$t('upskilling-b2c.description')"
          ></p>
          <div class="">
            <a
              href="#programs"
              target="_self"
              class="btn btn-lg btn-primary btn-rounded-lg mb-4"
            >
              {{ $t('upskilling-b2c.btn-more') }}
            </a>
          </div>
        </div>
      </div>
    </Hero>
    <!-- Sección Course details -->
    <div class="dark:bg-contrast bg-white mb-10 lg:mb-20">
      <section class="">
        <div
          class="text-center grid grid-cols-1 lg:grid-cols-4 font-bold gap-4 dark:text-white text-contrast text-md py-10 mx-auto w-10/12"
        >
          <div class="cards--skill--body lg:mb-2 mb-6">
            <img
              src="@/assets/images/upskilling/nIcons/web_development.svg"
              class="shadow-svg-xl mx-auto mb-4 w-10"
              alt="Utopicode"
            />
            <h4 v-html="$t('upskilling-b2c.course-detail-1')"></h4>
          </div>
          <div class="cards--skill--body lg:mb-2 mb-6">
            <img
              src="@/assets/images/upskilling/nIcons/cloud_check.svg"
              class="shadow-svg-xl mx-auto mb-4 w-10"
              alt="Utopicode"
            />
            <h4 v-html="$t('upskilling-b2c.course-detail-2')"></h4>
          </div>
          <div class="cards--skill--body lg:mb-2 mb-6">
            <img
              src="@/assets/images/upskilling/nIcons/clipboard.svg"
              class="shadow-svg-xl mx-auto mb-4 w-10"
              alt="Utopicode"
            />
            <h4 v-html="$t('upskilling-b2c.course-detail-3')"></h4>
          </div>
          <div class="cards--skill--body lg:mb-2 mb-6">
            <img
              src="@/assets/images/upskilling/nIcons/group.svg"
              class="shadow-svg-xl mx-auto mb-4 w-10"
              alt="Utopicode"
            />
            <h4 v-html="$t('upskilling-b2c.course-detail-4')"></h4>
          </div>
        </div>
      </section>
    </div>
    <!-- End Sección Overview  -->
    <!-- Sección How  -->
    <div class="content--wrapper">
      <section class="mb-10 lg:mb-20">
        <div class="mb-20">
          <h2
            class="title--h3 dark:text-white text-contrast font-bold text-center mb-4"
            v-html="$t('upskilling-b2c.perks-title')"
          ></h2>
        </div>
        <div class="flex lg:justify-between flex-wrap relative items-center">
          <div class="w-full lg:w-5/12 mb-8">
            <img
              src="@/assets/images/upskilling/improve-skills.png"
              class="mx-auto w-full"
              alt="Utopicode"
            />
          </div>
          <div class="w-full lg:w-6/12 mb-12 lg:mb-0">
            <ul
              class="mb-8 dark:text-primary-text text-secondary-text text-lg list--bullet list--bullet--icon"
            >
              <li v-for="(title, index) in 4" :key="index">
                <h6 class="dark:text-white font-bold text-xl mb-6">
                  {{ $t(`upskilling-b2c.title-perks-section-${index + 1}`) }}
                </h6>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
    <!-- End Sección How  -->
    <!-- Sección Quote  -->
    <div
      class="dark:bg-contrast bg-white flex justify-center text-center items-baseline py-20 mb-10 lg:mb-20"
    >
      <div class="mx-auto w-10/12">
        <h2 class="dark:text-white text-3xl italic">
          {{ $t('upskilling-b2c.quote-data') }}
        </h2>
        <p class="text-secondary-text">
          {{ $t('upskilling-b2c.quote-info') }}
        </p>
      </div>
    </div>
    <!-- End Sección Quote  -->
    <section id="programs" class="content--wrapper">
      <div>
        <h2
          class="text-center text-white font-bold text-2xl lg:text-5xl mx-auto lg:leading-tight lg:w-12/12 mb-20"
          v-html="$t('upskilling-b2c.programs-title')"
        ></h2>
        <div class="lg:flex lg:space-x-6">
          <div
            class="mb-12 lg:mb-0 dark:text-white text-center border-2 dark:border-line-dark border-line-light rounded lg:w-3/12 w-12/12 lg:order-first order-last"
          >
            <div class="flex justify-end">
              <span class="bg-secondary rounded-lg w-4/12 text-center text-sm">
                {{ $t('upskilling-b2c.programs-soon') }}
              </span>
            </div>
            <div class="p-8">
              <div class="flex mb-6">
                <img
                  src="@/assets/images/upskilling/nIcons/message.svg"
                  class="shadow-svg-xl w-10 mr-8"
                  alt="Utopicode"
                />
                <h2 class="title--h5 font-bold">
                  {{ $t('upskilling-b2c.program-title-3') }}
                </h2>
              </div>
              <div
                class="w-full mb-12 lg:mb-0 border-b dark:border-line-dark border-line-light"
              >
                <ul
                  class="mb-8 dark:text-primary-text text-secondary-text text-lg text-left list-disc"
                >
                  <li v-for="(title, index) in 2" :key="index" class="mb-2">
                    <span class="dark:text-white text-md">
                      {{
                        $t(`upskilling-b2c.program-description-3-${index + 1}`)
                      }}
                    </span>
                  </li>
                </ul>
              </div>
              <div class="w-full mb-12 lg:mb-4">
                <ul
                  class="mb-8 dark:text-primary-text text-secondary-text text-lg text-left list--bullet list--bullet--icon"
                >
                  <li>
                    <span class="dark:text-white text-sm">
                      {{ $t(`upskilling-b2c.program-details-duration`) }}
                    </span>
                  </li>
                  <li>
                    <span class="dark:text-white text-sm">
                      {{ $t(`upskilling-b2c.program-details-online`) }}
                    </span>
                  </li>
                  <li>
                    <span class="dark:text-white text-sm">
                      {{ $t(`upskilling-b2c.program-details-certificate`) }}
                    </span>
                  </li>
                </ul>
              </div>
              <div class="flex justify-center">
                <a
                  href="https://utopicode.typeform.com/to/SCzuYqJD?utm_source=xxxxx&utm_medium=xxxxx&utm_campaign=xxxxx&utm_term=xxxxx&utm_content=xxxxx"
                  target="_blank"
                  class="btn btn-md border-2 btn-outline-secondary btn-outline-secondary-glow btn-rounded-lg"
                >
                  {{ $t(`upskilling-b2c.btn-interested`) }}
                </a>
              </div>
            </div>
          </div>
          <div
            class="mb-12 p-4 lg:mb-0 dark:text-white text-center border-2 border-secondary rounded-lg lg:w-5/12 w-12/12 h-full"
          >
            <div class="flex justify-center">
              <img
                src="@/assets/images/upskilling/cody-up.svg"
                class="transform -translate-y-20 lg:block absolute shadow-svg-xl mx-auto"
                alt="Utopicode"
              />
            </div>
            <div class="p-8 top-0 relative">
              <div class="flex mb-6">
                <img
                  src="@/assets/images/upskilling/nIcons/soft-skill-icon-1.svg"
                  class="shadow-svg-xl w-28"
                  alt="Utopicode"
                />
                <h2 class="title--h5 font-bold">
                  {{ $t('upskilling-b2c.program-title-2') }}
                </h2>
              </div>
              <div
                class="w-full my-12 lg:my-4 border-b dark:border-line-dark border-line-light"
              >
                <ul
                  class="mb-8 dark:text-primary-text text-secondary-text text-lg text-left list-disc"
                >
                  <li v-for="(title, index) in 3" :key="index" class="mb-2">
                    <span class="dark:text-white text-md">
                      {{
                        $t(`upskilling-b2c.program-description-2-${index + 1}`)
                      }}
                    </span>
                  </li>
                </ul>
              </div>
              <div
                class="w-full my-12 lg:my-4 flex justify-between border-b dark:border-line-dark border-line-light"
              >
                <ul
                  class="mb-8 dark:text-primary-text text-secondary-text text-lg text-left list--bullet list--bullet--icon"
                >
                  <li>
                    <span class="dark:text-white text-sm">
                      {{ $t(`upskilling-b2c.program-details-duration`) }}
                    </span>
                  </li>
                  <li>
                    <span class="dark:text-white text-sm">
                      {{ $t(`upskilling-b2c.program-details-online`) }}
                    </span>
                  </li>
                  <li>
                    <span class="dark:text-white text-sm">
                      {{ $t(`upskilling-b2c.program-details-certificate`) }}
                    </span>
                  </li>
                  <li>
                    <span class="dark:text-white text-sm">
                      {{ $t(`upskilling-b2c.program-details-verified`) }}
                    </span>
                  </li>
                </ul>
                <h2
                  class="lg:text-md text-sm dark:text-white"
                  v-html="$t(`upskilling-b2c.program-details-price`)"
                ></h2>
              </div>
              <div class="w-12/12 mx-auto mb-4">
                <a
                  href="https://upskilling.utopicode.com/enroll/2091916?price_id=2830787"
                  target="_blank"
                  class="btn btn-lg btn-primary btn-rounded-lg"
                >
                  {{ $t(`upskilling-b2c.btn-buy`) }}
                </a>
              </div>
              <div class="lg:w-6/12 mx-auto">
                <a
                  href="#syllabus"
                  class="btn btn-md border-2 btn-outline-secondary btn-outline-secondary-glow btn-rounded-lg"
                >
                  {{ $t(`upskilling-b2c.btn-download`) }}
                </a>
              </div>
            </div>
          </div>
          <div
            class="mb-12 lg:mb-0 dark:text-white text-center border-2 dark:border-line-dark border-line-light rounded lg:w-3/12 w-12/12"
          >
            <div class="flex justify-end">
              <span class="bg-secondary rounded-lg w-4/12 text-center text-sm">
                {{ $t('upskilling-b2c.programs-soon') }}
              </span>
            </div>
            <div class="p-8">
              <div class="flex mb-6">
                <img
                  src="@/assets/images/upskilling/nIcons/soft-skill-icon-2.svg"
                  class="shadow-svg-xl w-10 mr-8"
                  alt="Utopicode"
                />
                <h2 class="title--h5 font-bold">
                  {{ $t('upskilling-b2c.program-title-1') }}
                </h2>
              </div>
              <div
                class="w-full mb-12 lg:mb-0 border-b dark:border-line-dark border-line-light"
              >
                <ul
                  class="mb-8 dark:text-primary-text text-secondary-text text-lg text-left list-disc"
                >
                  <li v-for="(title, index) in 2" :key="index" class="mb-2">
                    <span class="dark:text-white text-md">
                      {{
                        $t(`upskilling-b2c.program-description-1-${index + 1}`)
                      }}
                    </span>
                  </li>
                </ul>
              </div>
              <div class="w-full mb-12 lg:mb-4">
                <ul
                  class="mb-8 dark:text-primary-text text-secondary-text text-lg text-left list--bullet list--bullet--icon"
                >
                  <li>
                    <span class="dark:text-white text-sm">
                      {{ $t(`upskilling-b2c.program-details-duration`) }}
                    </span>
                  </li>
                  <li>
                    <span class="dark:text-white text-sm">
                      {{ $t(`upskilling-b2c.program-details-online`) }}
                    </span>
                  </li>
                  <li>
                    <span class="dark:text-white text-sm">
                      {{ $t(`upskilling-b2c.program-details-certificate`) }}
                    </span>
                  </li>
                </ul>
              </div>
              <div class="flex justify-center">
                <a
                  href="https://utopicode.typeform.com/to/j1feckTQ?utm_source=xxxxx&utm_medium=xxxxx&utm_campaign=xxxxx&utm_term=xxxxx&utm_content=xxxxx"
                  target="_blank"
                  class="btn btn-md border-2 btn-outline-secondary btn-outline-secondary-glow btn-rounded-lg"
                >
                  {{ $t(`upskilling-b2c.btn-interested`) }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <section class="content--wrapper relative py-20">
          <h4 class="text-2xl dark:text-white font-bold text-center mb-4">
            {{ $t(`upskilling-b2c.upskilling-team`) }}
          </h4>
          <div class="flex items-baseline justify-center space-x-6">
            <router-link
              :to="{ name: 'Upskilling' }"
              class="btn btn-lg border-2 btn-outline-secondary btn-outline-secondary-glow btn-rounded-lg"
            >
              {{ $t('upskilling-b2c.btn-upskilling-team') }}
            </router-link>
          </div>
        </section>
      </div>
    </section>
    <section class="relative w-full h-full overflow-hidden mb-10 lg:mb-20">
      <img
        class="absolute w-full h-full object-cover object-center"
        src="@/assets/images/upskilling/img-blockquote-bg.png"
      />
      <div class="absolute text-center mx-auto w-full py-24">
        <h4
          class="dark:text-white font-bold lg:text-3xl text-xl lg:mb-4 mb-10 text-center mx-auto lg:w-8/12 w-12/12"
          v-html="$t('upskilling-b2c.companies-experience')"
        ></h4>
        <div
          class="text-center grid grid-cols-5 font-bold gap-x-2 dark:text-secondary-text text-contrast text-lg mx-auto lg:w-6/12 w-12/12"
        >
          <div class="cards--skill--body lg:mb-2 mb-6">
            <LogoSiclo class="mx-auto lg:w-24 w-10" alt="Siclo" />
          </div>
          <div class="cards--skill--body lg:mb-2 mb-6">
            <img
              class="mx-auto lg:w-24 w-10 fill-current"
              src="@/assets/images/upskilling/logoUrbvan.svg"
              alt=""
            />
          </div>
          <div class="cards--skill--body lg:mb-2 mb-6">
            <img
              class="mx-auto lg:w-24 w-10 fill-current"
              src="@/assets/images/upskilling/logoJusto.svg"
              alt=""
            />
          </div>
          <div class="cards--skill--body lg:mb-2 mb-6">
            <img
              class="mx-auto lg:w-24 w-10 fill-current"
              src="@/assets/images/upskilling/logoBen&Frank.svg"
              alt=""
            />
          </div>
          <div class="cards--skill--body lg:mb-2 mb-6">
            <img
              class="mx-auto lg:w-24 w-10 fill-current"
              src="@/assets/images/upskilling/logoSilabuz.svg"
              alt=""
            />
          </div>
        </div>
      </div>
      <splide class="splide--blockquote z-20 mt-40" :options="options">
        <splide-slide class="lg:px-40">
          <BlockquoteLarge
            class="lg:mt-14 mt-18"
            :cite="$t(`upskilling-b2b.review-quote-1`)"
            author="Rodrigo Piña"
            position="Developer Siclo"
            :block-image="'B2B-hero.png'"
          >
          </BlockquoteLarge>
        </splide-slide>
        <splide-slide class="lg:px-40">
          <BlockquoteLarge
            class="lg:mt-14 mt-18"
            :cite="$t(`upskilling-b2b.review-quote-2`)"
            author="Karen Barrera"
          >
          </BlockquoteLarge>
        </splide-slide>
        <splide-slide class="lg:px-40">
          <BlockquoteLarge
            class="lg:mt-14 mt-18"
            :cite="$t(`upskilling-b2b.review-quote-3`)"
            author="Learner del Upskilling Program"
          >
          </BlockquoteLarge>
        </splide-slide>
        <splide-slide class="lg:px-40">
          <BlockquoteLarge
            class="lg:mt-14 mt-18"
            :cite="$t(`upskilling-b2b.review-quote-4`)"
            author="Sebastian F"
          >
          </BlockquoteLarge>
        </splide-slide>
      </splide>
    </section>
    <!-- Sección FAQ  -->
    <div class="content--wrapper dark:text-white-text text-secondary-text">
      <section class="mb-10 lg:mb-20">
        <h2
          class="title--h3 dark:text-white text-contrast font-bold text-center my-6"
          v-html="$t('upskilling-b2b.title-faq')"
        ></h2>
        <div class="grid grid-cols-1 gap-x-6 gap-y-4 mb-10 items-start">
          <div class="lg:w-7/12 w-10/12 mx-auto">
            <CollapseSimple
              class="collapse--wrapper--simple shadow-box-light dark:shadow-box-dark mb-6"
              :title="$t('upskilling-b2b.faq-upskilling-title-q1')"
            >
              <template #body>
                {{ $t('upskilling-b2b.faq-upskilling-text-q1') }}
              </template>
            </CollapseSimple>
            <CollapseSimple
              class="shadow-box-light dark:shadow-box-dark mb-6"
              :title="$t('upskilling-b2b.faq-upskilling-title-q6')"
            >
              <template #body>
                <h2 v-html="$t('upskilling-b2b.faq-upskilling-text-q6')"></h2>
              </template>
            </CollapseSimple>
            <CollapseSimple
              class="shadow-box-light dark:shadow-box-dark mb-6"
              :title="$t('upskilling-b2b.faq-upskilling-title-q5')"
            >
              <template #body>
                <h2 v-html="$t('upskilling-b2b.faq-upskilling-text-q5')"></h2>
              </template>
            </CollapseSimple>
          </div>
        </div>
      </section>
    </div>
    <!-- Sección FAQ  -->
    <!-- Sección Comunidad   -->
    <div class="dark:bg-contrast bg-white">
      <section class="mb-10 lg:mb-20 py-10">
        <h2
          class="title--h4 dark:text-white text-contrast font-bold text-center my-4"
          v-html="$t('upskilling-b2c.title-community')"
        ></h2>
        <h2
          class="dark:text-white font-bold text-lg text-center mx-auto mb-6 lg:w-8/12 w-10/12"
        >
          {{ $t('upskilling-b2c.subtitle-community') }}
        </h2>
        <div class="flex justify-center mb-10">
          <a
            href="https://discord.gg/SwctK6By"
            _target="blank"
            class="btn btn-lg border-2 btn-outline-secondary btn-outline-secondary-glow btn-rounded-lg"
            >{{ $t('upskilling-b2c.btn-community') }}</a
          >
        </div>
      </section>
    </div>
    <!-- Sección More Content   -->
    <div class="">
      <section class="content--wrapper mb-10 lg:mb-20 py-10">
        <h2
          class="title--h4 dark:text-white text-contrast font-bold text-center mb-10"
          v-html="$t('upskilling-b2c.title-more-content')"
        ></h2>
        <div class="grid lg:grid-cols-3 gap-x-4 dark:text-white mb-4">
          <div class="dark:text-white p-8">
            <div class="mb-6">
              <img
                src="@/assets/images/upskilling/nIcons/briefcase.svg"
                class="shadow-svg-xl mx-auto w-14 mb-4"
                alt="Utopicode"
              />
              <h4
                class="font-bold text-xl text-center"
                v-html="$t('upskilling-b2c.title-job')"
              ></h4>
            </div>
            <router-link
              :to="{ name: 'SignUp' }"
              class="btn btn-md border-2 btn-outline-secondary btn-outline-secondary-glow btn-rounded-lg"
            >
              {{ $t(`upskilling-b2c.btn-register`) }}
            </router-link>
          </div>
          <div class="dark:text-white p-8">
            <div class="text-center mb-6">
              <img
                src="@/assets/images/upskilling/nIcons/ticket.svg"
                class="shadow-svg-xl mx-auto w-14 mb-4"
                alt="Utopicode"
              />
              <h4
                class="font-bold text-xl"
                v-html="$t('upskilling-b2c.title-events')"
              ></h4>
            </div>
            <router-link
              :to="{ name: 'Eventos' }"
              class="btn btn-md border-2 btn-outline-secondary btn-outline-secondary-glow btn-rounded-lg"
            >
              {{ $t(`upskilling-b2c.btn-register`) }}
            </router-link>
          </div>
          <div class="dark:text-white p-8">
            <div class="mb-6">
              <img
                src="@/assets/images/upskilling/nIcons/book.svg"
                class="shadow-svg-xl mx-auto w-14 mb-4"
                alt="Utopicode"
              />
              <h4
                class="font-bold text-xl text-center"
                v-html="$t('upskilling-b2c.title-blog')"
              ></h4>
            </div>
            <a
              href="https://blog.utopicode.io/"
              class="btn btn-md border-2 btn-outline-secondary btn-outline-secondary-glow btn-rounded-lg"
            >
              {{ $t(`upskilling-b2c.btn-blog`) }}
            </a>
          </div>
        </div>
      </section>
    </div>
    <!-- End Sección More Content   -->
    <!-- Sección Form Temario  -->
    <div id="syllabus" class="content--wrapper">
      <section class="mb-10 lg:mb-20">
        <div class="w-full lg:w-8/12 mx-auto">
          <CardPage>
            <template #default>
              <div v-if="enviado">
                <div class="card--page--header">
                  <Title>
                    <template #title>
                      <h3>
                        {{ $t('form-validation.validation-hire-devs-1') }}
                      </h3>
                    </template>
                  </Title>
                </div>
                <div class="card--page--body">
                  <p class="dark:text-primary-text text-secondary-text">
                    {{ $t('form-validation.validation-hire-devs-2') }}
                  </p>
                  <div
                    class="dark:text-primary-text text-secondary-text"
                    v-html="$t('form-validation.validation-upskilling-devs')"
                  ></div>
                </div>
                <div class="card--page--footer">
                  <button
                    type="button"
                    class="btn btn-primary btn-lg btn-rounded-lg mx-auto"
                    @click="resetForm"
                  >
                    {{ $t('general.volver') }}
                  </button>
                </div>
              </div>
              <Form @submit="downloadSyllabus" v-else>
                <!-- Title -->
                <div class="card--page--header">
                  <h4
                    class="title--h5 dark:text-white text-contrast font-bold mb-6"
                    v-html="$t('upskilling-b2b.title-form')"
                  ></h4>
                  <p
                    class="dark:text-white-text text-secondary-text text-xl mb-8"
                  >
                    {{ $t('upskilling-b2b.form-description') }}
                  </p>
                </div>
                <!-- End Title -->
                <!-- Body -->
                <div
                  class="card--page--body grid grid-cols-1 lg:grid-cols-2 gap-x-6"
                >
                  <div class="form--group">
                    <label class="form--label" for="name">{{
                      $t('contrata-devs-page.lbl-nombre')
                    }}</label>
                    <Field
                      name="name"
                      type="text"
                      class="form--element w-full"
                      :rules="validateDefault"
                    />
                    <ErrorMessage name="name" class="form--error" />
                  </div>
                  <div class="form--group">
                    <label class="form--label" for="email">{{
                      $t('contrata-devs-page.lbl-email')
                    }}</label>
                    <Field
                      name="email"
                      type="email"
                      class="form--element w-full"
                      :rules="validateEmail"
                    />
                    <ErrorMessage name="email" class="form--error" />
                  </div>
                  <div class="form--group">
                    <label class="form--label" for="phone">{{
                      $t('contrata-devs-page.lbl-telefono')
                    }}</label>
                    <div class="form--phone">
                      <Field
                        name="phoneCode"
                        type="select"
                        :placeholder="$t('general.codigo-internacional')"
                        list="phoneCodeNumberList"
                        class="form--element form--select w-10/12"
                        :rules="validateDefaultPhone"
                        v-model.lazy="phoneCodeNumber"
                      />
                      <Field
                        name="phone"
                        type="text"
                        class="form--element w-full w-6/12"
                        :rules="validatePhone"
                        v-model="phonenumber"
                      />
                      <datalist id="phoneCodeNumberList">
                        <option value="" disabled></option>
                        <option
                          :value="code.dialCode"
                          v-for="(code, index) in phoneCodes"
                          :key="index"
                        >
                          {{ code.emoji }} {{ code.name }}
                        </option>
                      </datalist>
                    </div>
                    <ErrorMessage name="phone" class="form--error" />
                    <ErrorMessage name="phoneCode" class="form--error" />
                  </div>
                  <div class="form--group">
                    <label class="form--label" for="tipo-de-rol">{{
                      $t('upskilling-b2b.lbl-speciality')
                    }}</label>
                    <Field
                      name="speciality"
                      as="div"
                      :rules="validateDefault"
                      v-slot="{ field }"
                    >
                      <Multiselect
                        mode="tags"
                        v-bind="field"
                        v-model="speciality"
                        :options="especialidadLista"
                        searchable
                        :max="5"
                        :noOptionsText="$t('contrata-devs-page.lista-vacia')"
                        :noResultsText="$t('contrata-devs-page.sin-resultados')"
                      />
                    </Field>
                    <ErrorMessage name="speciality" class="form--error" />
                  </div>
                  <div class="form--group">
                    <label class="form--label" for="tipo-de-rol">{{
                      $t('upskilling-b2b.lbl-englishLevel')
                    }}</label>
                    <Field
                      name="english"
                      as="Select"
                      class="form--element form--select w-full"
                      :rules="validateDefault"
                    >
                      <option
                        v-for="item in englishLevels"
                        :key="item"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </Field>
                    <ErrorMessage name="english" class="form--error" />
                    <!-- End Other Selected -->
                  </div>
                  <div class="form--group">
                    <label class="form--label" for="tipo-de-rol">{{
                      $t('upskilling-b2b.lbl-seniority')
                    }}</label>
                    <Field
                      name="seniority"
                      as="Select"
                      class="form--element form--select w-full"
                      :rules="validateDefault"
                    >
                      <option
                        v-for="item in seniorityLevels"
                        :key="item"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </Field>
                    <ErrorMessage name="seniority" class="form--error" />
                  </div>
                </div>
                <!-- End Body -->
                <!-- Footer -->
                <div class="card--page--footer">
                  <div class="flex items-baseline justify-center space-x-6">
                    <button
                      type="submit"
                      class="btn btn-lg btn-secondary btn-rounded-lg shadow-lg"
                    >
                      {{ $t('box-course-details.btn-download') }}
                    </button>
                  </div>
                </div>
                <!-- End Footer -->
              </Form>
            </template>
          </CardPage>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import Hero from '@/components/FullHero'
import Title from '@/components/Title'
import CollapseSimple from '@/components/CollapseSimple'
import Multiselect from '@vueform/multiselect'
import BlockquoteLarge from '@/components/BlockquoteLarge'
import CardPage from '@/components/CardPage'
import { Field, Form, ErrorMessage } from 'vee-validate'
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import phoneCodes from '@/components/json/phoneCodes.json'
import especialidadLista from '@/constants/specialities.json'

import ApolloClient from '@/graphql/apolloClient'
import EventsQuery from '../graphql/queries/events'
import UpskillingInterest from '@/graphql/mutations/upskillingInterest'
import { mapActions } from 'vuex'
import { toastNotification } from '@/services/toastNotification'
import ROUTES from '../constants/routes'

import LogoSiclo from '@/components/svg/clients/logoSiclo.vue'

import {
  validateDefault,
  validateEmail,
  validatePhone,
  validateUrlNoRegex,
  validateDefaultPhone
} from '@/services/validationRules'

export default {
  name: 'Upskilling',
  components: {
    Hero,
    Title,
    CollapseSimple,
    Field,
    Form,
    ErrorMessage,
    Multiselect,
    CardPage,
    BlockquoteLarge,
    Splide,
    SplideSlide,
    LogoSiclo
  },
  data() {
    return {
      phoneCodes,
      especialidadLista,
      enviado: false,
      ROUTES,
      phonenumber: '',
      phoneCodeNumber: '',
      courses: [],
      englishLevels: {
        opt1: 'No sé nada de Inglés',
        opt2: 'Básico',
        opt3: 'Conversacional',
        opt4: 'Profesional',
        opt5: 'Lengua Materna'
      },
      seniorityLevels: {
        opt1: 'Student',
        opt2: 'Junior',
        opt3: 'Mid-senior',
        opt4: 'Senior',
        opt5: 'Expert'
      },
      motivations: {
        opt1: 'Me gusta aprender',
        opt2: 'Me gusta mejorar',
        opt3: 'No se'
      },
      options: {
        rewind: false,
        perPage: 1,
        type: 'loop',
        pagination: false,
        autoplay: false,
        pauseOnHover: false,
        breakpoints: {
          1024: {
            pagination: true,
            arrows: false
          }
        }
      }
    }
  },
  created: function () {
    this.getEvents()
  },
  methods: {
    ...mapActions(['setLoadingOn', 'setLoadingOff']),
    getEvents: async function () {
      try {
        const eventsQuery = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale
            }
          },
          fetchPolicy: 'network-only',
          query: EventsQuery
        })
        this.moreEvents = eventsQuery.data.events

        this.moreEvents.forEach((element) => {
          if (this.courses.length <= 2) {
            this.courses.push(element)
          }
        })
        this.setLoadingOff()
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    // Send Form
    downloadSyllabus: async function (values) {
      try {
        this.setLoadingOn()
        if (values) {
          const specialityArray = values.speciality
          const result = await ApolloClient.mutate({
            mutation: UpskillingInterest,
            variables: {
              fullname: values.name,
              email: values.email,
              phone: this.phoneNumberComplete,
              speciality: specialityArray.join(';'),
              english: values.english,
              seniority: values.seniority,
              motivations: values.motivations,
              origin: 'B2C'
            }
          })
          if (result.data.upskillingInterest) {
            this.setLoadingOff()
            this.enviado = !this.enviado
            this.downloadItem()
          } else {
            this.setLoadingOff()
            alert('Something went wrong, try again')
          }
        }
        return false
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    downloadItem: function () {
      const a = document.createElement('a')
      a.href = 'https://webdynamiccontentutopicode.s3.us-west-2.amazonaws.com/Temario_UYP+V2.pdf'
      a.target = '_blank'
      a.download = 'Temario UTOPIC0DE'
      a.click()
      URL.revokeObjectURL(a.href)
    },
    // Reset Form
    resetForm() {
      this.enviado = !this.enviado
    },

    // RULES
    validateDefault,
    validateEmail,
    validatePhone,
    validateUrlNoRegex,
    validateDefaultPhone,
    scrollMeTo(refName) {
      const element = this.$refs[refName]
      const top = element.offsetTop
      window.scrollTo({ top, behavior: 'smooth' })
    }
  },
  computed: {
    phoneNumberComplete() {
      return this.phoneCodeNumber + this.phonenumber
    }
  }
}
</script>
