<template>
  <!-- END SIDEBAR -->
  <div class="w-full lg:w-8/12 lg:pr-6">
    <!-- Card Page Sign Up -->
    <CardPage class="bg-transparentAux">
      <template #default>
        <!-- Body -->
        <div class="card--page--body lg:pl-1 pt-10">
          <section class="mb-12 w-full flex flex-wrap md:pt-7 md:pb-10">
            <div class="w-full lg:w-5/12 lg:pt-4">
              <img
                src="@/assets/images/test-dinamica.svg"
                class="mb-6 w-full"
                alt="Step 1"
              />
            </div>
            <div class="w-full lg:w-7/12 lg:pl-4 lg:mt-16">
              <p class="mb-2 text-xs uppercase" style="color: #28b99a">
                {{ $t('schedule.pre-title') }}
              </p>
              <h2 class="dark:text-white text-secondary-text mb-4 lg:text-4xl">
                <span
                  class="md:inline-block md:p-2 md:bg-[#FFE81A] md:rounded-lg text-black"
                >
                  {{ $t('schedule.title_1') }}
                </span>
                <span class="md:block"> {{ $t('schedule.title_2') }} </span>
                {{ $t('schedule.title_3') }}
              </h2>
              <p class="text-base mb-4" style="color: #b9a3ff">
                {{ $t('schedule.description') }}
              </p>
            </div>
          </section>
        </div>
        <!-- End Body -->
      </template>
    </CardPage>
    <!-- End Card Page Sign Up -->
  </div>
</template>

<script>
import CardPage from '@/components/CardPage'
// import BoxEstadoDeEmpleo from '@/components/profile/BoxEstadoDeEmpleo'
// import BasicInformationSection from '@/components/profile/BasicInformationSection'

// import ApolloClient from '@/graphql/apolloClient'
// import assesmentCategoriesQuery from '@/graphql/queries/assesmentCategories'
// import userProfileQuery from '@/graphql/queries/userProfile'
import { mapGetters, mapActions } from 'vuex'
// import { toastNotification } from '@/services/toastNotification'

export default {
  name: 'PreCalifica',
  data() {
    return {
      userProfile: null,
      modalBienvenidaShow: false,
      assesmentCategories: [],
      email: ''
    }
  },
  components: {
    CardPage
    // BasicInformationSection
  },
  updated() {
    // this.getAssesmentCategories()
  },
  mounted() {
    // this.setLoadingOn()
    // this.getUserProfile()
    // this.getAssesmentCategories()
  },
  watch: {
    modalBienvenidaShow(newName) {
      localStorage.modalBienvenidaShow = newName
    }
  },
  computed: {
    ...mapGetters(['token', 'user'])
  },
  methods: {
    ...mapActions(['setLoadingOn', 'setLoadingOff']),
    // showTutorialModal: function () {
    //   // Comprobar si ya se ha mostrado el modal antes y almacenarlo en localStorage
    //   if (localStorage.getItem('modalBienvenidaShown')) {
    //     // Si el modal ya se mostró anteriormente, asegurar que no se muestre de nuevo
    //     this.modalBienvenidaShow = false
    //   } else {
    //     // Verificar si el perfil del usuario tiene datos que indicarían que no es su primera visita
    //     if (
    //       this.userProfile.englishLevel === null &&
    //       this.userProfile.idealCompany.length === 0 &&
    //       this.userProfile.idealJobType === null &&
    //       this.userProfile.industries.length === 0 &&
    //       this.userProfile.jobStatus === null &&
    //       this.userProfile.minSalary === null &&
    //       this.userProfile.personsInCharge === null
    //     ) {
    //       // Mostrar el modal solo si es evidente que el usuario es nuevo y no ha configurado su perfil
    //       this.modalBienvenidaShow = true
    //       // Almacenar en localStorage que el modal ya se ha mostrado para no repetir en futuras sesiones
    //       localStorage.setItem('modalBienvenidaShown', 'true')
    //     } else {
    //       // No mostrar el modal si el perfil del usuario ya está parcialmente o totalmente lleno
    //       this.modalBienvenidaShow = false
    //     }
    //   }
    // },

    // getAssesmentCategories: async function () {
    //   try {
    //     const habilitiesQuery = await ApolloClient.query({
    //       context: {
    //         headers: {
    //           lang: this.$root.$i18n.locale
    //         }
    //       },
    //       fetchPolicy: 'network-only',
    //       query: assesmentCategoriesQuery
    //     })
    //     this.assesmentCategories = habilitiesQuery.data.assesmentCategories
    //   } catch (e) {
    //     toastNotification(e, 'error')
    //   }
    // },
    // getUserProfile: async function () {
    //   try {
    //     const userProfileData = await ApolloClient.query({
    //       context: {
    //         headers: {
    //           lang: this.$root.$i18n.locale,
    //           authorization: `Bearer ${await this.token}`
    //         }
    //       },
    //       fetchPolicy: 'network-only',
    //       query: userProfileQuery
    //     })
    //     this.userProfile = userProfileData.data.userProfile
    //     this.email = userProfileData.data.user.email
    //     this.showTutorialModal()
    //     this.setLoadingOff()
    //   } catch (e) {
    //     toastNotification(e, 'error')
    //   }
    // },
    onModalClose() {
      this.modalBienvenidaShow = false
    }
  }
}
</script>
