<template>
  <div class="content--wrapper m-6">
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 mb-6">
      <!-- Color Widgets-->
      <CardWidgetColor
        class="bg-secondary w-full"
        :title="titleColor"
        :number="num"
      />
      <CardWidgetColor
        class="bg-primary w-full"
        :title="titleColor"
        :color="primary"
        :number="num"
      />
      <CardWidgetColor
        class="bg-tertiary w-full"
        :title="titleColor"
        :number="num"
      />
      <CardWidgetColor
        class="bg-fifth w-full"
        :title="titleColor"
        :number="num"
      />
    </div>
    <!-- End Color Widgets -->
    <!-- Widgets -->
    <div
      class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 mb-6 items-start"
    >
      <div class="lg:col-span-2 grid md:grid-cols-2 gap-5">
        <CardWidgetTop class="lg:col-span-2" :title="title1" :data="top" />
        <CardWidgetColor
          class="bg-secondary w-full lg:col-span-2"
          :title="titleColor"
          :number="num"
        />
      </div>
      <CardWidgetChart
        :title="title2"
        :data="datachart"
        :options="chartOptions"
        class="h-full"
      />
      <CardWidgetChart :title="title3" :data="chart" class="h-full" />
    </div>
    <!-- End Widget -->
    <div class="flex flex-wrap lg:flex-nowrap gap-5 mb-6">
      <CardWidgetActivity
        class="w-full lg:w-5/12"
        :title="title4"
        :type="activity"
      />
      <CardWidgetChart class="w-full lg:w-7/12" :title="title5" :type="chart" />
    </div>
  </div>
  <!-- End Widgets-->
</template>
<script>
import CardWidgetTop from '@/components/empresas/CardWidgetTop'
import CardWidgetActivity from '@/components/empresas/CardWidgetActivity'
import CardWidgetChart from '@/components/empresas/CardWidgetChart'
import CardWidgetColor from '@/components/empresas/CardWidgetColor'

export default {
  name: 'DashboardPremium',
  components: {
    CardWidgetTop,
    CardWidgetActivity,
    CardWidgetChart,
    CardWidgetColor
  },
  data() {
    return {
      title1: 'Top Cities for Developer',
      title2: 'Titulo 2',
      title3: 'Titulo 3',
      title4: 'Actividad',
      title5: 'Titulo 5',
      titleColor: 'Widget Title',
      num: 50,
      top: {
        name: 'top',
        total: '3500',
        city: 'Mexico',
        stack: {
          Mexico: '1,560',
          Argentina: '1,456',
          Chile: '1,026'
        }
      },
      datachart: {
        labels: ['Paris', 'Nîmes', 'Toulon', 'Perpignan', 'Autre'],
        datasets: [
          {
            data: [30, 40, 60, 70, 5],
            backgroundColor: [
              '#77CEFF',
              '#0079AF',
              '#123E6B',
              '#97B0C4',
              '#A5C8ED'
            ]
          }
        ]
      },
      chartOptions: {
        responsive: true,
        lineTension: 1,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                padding: 25
              }
            }
          ]
        }
      },
      activity: 'soy activity'
    }
  }
}
</script>
