<template>
  <!-- END SIDEBAR -->
  <div class="w-full lg:w-8/12 lg:pr-6">
    <!-- Banner finish test -->
    <div v-if="showModal" class="welcome flex justify-center items-center">
      <div
        class="welcome-container bg-black text-white px-10 py-12 rounded-xl border-2 w-full relative border-[#B9A3FF]"
      >
        <!-- Exit -->
        <div class="welcome-exit absolute top-3 right-3">
          <span class="cursor-pointer" @click="closeModal()">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.39916 18.3069L5.69141 17.5992L11.2914 11.9992L5.69141 6.39916L6.39916 5.69141L11.9992 11.2914L17.5992 5.69141L18.3069 6.39916L12.7069 11.9992L18.3069 17.5992L17.5992 18.3069L11.9992 12.7069L6.39916 18.3069Z"
                fill="#E8EAED"
              />
            </svg>
          </span>
        </div>
        <!-- End Exit -->

        <div class="flex justify-center">
          <Vue3Lottie
            :animationData="AlertAnimation"
            :height="150"
            :width="150"
          />
        </div>

        <h3 class="text-center text-4xl my-4">
          <span class="flex items-center justify-center">
            ¡Mejorar Score!
          </span>
        </h3>
        <p class="text-center text-lg max-w-lg mx-auto break-words">
          ¿Estás
          <span class="relative bottom-[-3px] inline-flex">
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="20.1362"
                height="20.1362"
                transform="translate(0.0800781 0.0585938)"
                fill="black"
              />
              <path
                d="M17.7432 13.2439L11.3214 10.1263L17.7456 7.00803C17.9278 6.90658 18.0767 6.69517 18.1441 6.43455C18.2121 6.17029 18.1848 5.9036 18.0706 5.72075C17.9455 5.49598 17.68 5.3192 17.372 5.24994C17.1326 5.19588 16.9121 5.21835 16.7833 5.31069L10.7934 9.36631L11.3548 2.19365C11.3918 1.86924 11.2849 1.6305 11.0377 1.47559C10.6835 1.25446 10.1179 1.26479 9.74916 1.49989C9.5742 1.61167 9.37555 1.82064 9.42111 2.18393L9.98365 9.36571L3.99803 5.31312C3.56792 5.07498 2.94403 5.29915 2.71379 5.65089C2.46957 6.20492 2.68341 6.83367 3.03393 7.00863L9.45514 10.1263L3.03697 13.2421C2.84683 13.3496 2.69981 13.5592 2.63299 13.818C2.56495 14.0823 2.59229 14.349 2.7065 14.5312C2.83164 14.756 3.09711 14.9328 3.40511 15.002C3.64447 15.0561 3.86499 15.0336 3.99377 14.9413L9.98365 10.8857L9.42233 18.0583C9.37616 18.4313 9.57481 18.6403 9.74977 18.7521C10.1179 18.9872 10.6841 18.9975 11.0383 18.7764C11.2855 18.6215 11.3924 18.3827 11.356 18.0662L10.7934 10.8863L16.7791 14.9388C17.2098 15.177 17.8337 14.9528 18.0633 14.6011C18.3075 14.0477 18.0937 13.4189 17.7432 13.2439Z"
                fill="#DB7E6E"
              />
            </svg>
          </span>
          ur@ de realizar de nuevo el test?
        </p>
        <div class="flex justify-center space-x-4 mt-4">
          <!-- Botón Aceptar -->
          <router-link
            :to="{ name: 'InstruccionesAssesments', params: { id: 3 } }"
            class="btn btn-lg btn-rounded-lg btn-primary"
          >
            <span class="mr-2">Aceptar</span>
          </router-link>

          <!-- Botón Cancelar -->
          <button
            type="button"
            class="btn btn-lg btn-rounded-lg btn-dark border border-primary"
            @click="closeModal()"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
    <!-- End Banner finish test -->

    <!-- Card Page Sign Up -->
    <!-- Card Page Sign Up -->
    <CardPage class="bg-transparentAux">
      <template #default>
        <!-- Body -->
        <div class="card--page--body lg:pl-1 pt-10">
          <section class="mb-2">
            <div class="flex mb-6 lg:mt-7">
              <div
                class="flex justify-between items-center uppercase border-2 bg-black rounded-lg w-full px-3 lg:px-6 py-3 lg:py-5"
                style="border-color: #31a5ff"
              >
                <Title class="">
                  <template #title>
                    <h5 class="mt-0 text-lg flex items-center">
                      <svg
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width="71.2px"
                        height="78.8px"
                        viewBox="0 0 71.2 78.8"
                        style="enable-background: new 0 0 71.2 78.8"
                        xml:space="preserve"
                        sttyle=""
                      >
                        <g>
                          <path
                            class="st0"
                            d="M64.8,17.1L40.3,2.9c-2.9-1.7-6.5-1.7-9.4,0L6.3,17.1c-2.9,1.7-4.7,4.8-4.7,8.1v28.4c0,3.4,1.8,6.5,4.7,8.1
                           l24.6,14.2c2.9,1.7,6.5,1.7,9.4,0l24.5-14.2c2.9-1.7,4.7-4.8,4.7-8.1V25.2C69.5,21.8,67.7,18.7,64.8,17.1z"
                            fill="#31a5ff"
                          />
                        </g>
                        <g>
                          <path
                            class="st1"
                            d="M35.6,78.3c-1.8,0-3.7-0.5-5.3-1.4L5.8,62.7c-3.3-1.9-5.3-5.4-5.3-9.1V25.2c0-3.8,2-7.2,5.3-9.1L30.3,1.9
                           c3.3-1.9,7.3-1.9,10.5,0l24.6,14.2c3.3,1.9,5.3,5.4,5.3,9.1v28.4c0,3.8-2,7.2-5.3,9.1L40.8,76.8C39.2,77.8,37.4,78.3,35.6,78.3z
                            M35.6,2.8c-1.4,0-2.9,0.4-4.1,1.1L7,18c-2.6,1.5-4.1,4.2-4.1,7.2v28.4c0,3,1.6,5.7,4.1,7.2l24.5,14.1c2.6,1.4,5.7,1.4,8.3,0
                           l24.6-14.1c2.5-1.5,4.1-4.2,4.1-7.1V25.2c0-2.9-1.6-5.7-4.1-7.2L39.7,3.9C38.5,3.1,37,2.8,35.6,2.8L35.6,2.8z"
                            fill="#ffffff"
                          />
                          <path
                            class="st0"
                            d="M35.6,78.8c-1.9,0-3.8-0.5-5.5-1.5L5.5,63.1c-3.4-2-5.5-5.6-5.5-9.5V25.2c0-3.9,2.1-7.6,5.5-9.6L30.1,1.5
                           c3.4-2,7.6-2,11,0l24.6,14.2c3.4,2,5.5,5.6,5.5,9.6v28.4c0,3.9-2.1,7.6-5.5,9.5L41.1,77.3C39.4,78.2,37.5,78.8,35.6,78.8z M35.6,1
                           c-1.7,0-3.5,0.4-5,1.3L6,16.5c-3.1,1.8-5,5.1-5,8.7v28.4c0,3.6,1.9,6.9,5,8.7l24.6,14.2c3,1.8,7,1.8,10,0l24.6-14.2
                           c3.1-1.8,5-5.1,5-8.7V25.2c0-3.6-1.9-6.9-5-8.7L40.6,2.3C39,1.4,37.3,1,35.6,1z M35.6,76.4c-1.5,0-3-0.4-4.4-1.1L6.7,61.2
                           c-2.7-1.6-4.4-4.5-4.4-7.6V25.2c0-3.1,1.7-6,4.4-7.6L31.2,3.4c1.2-0.7,2.5-1.1,3.9-1.2v0l0.5,0c1.5,0,3,0.4,4.4,1.2l24.6,14.2
                           c2.7,1.6,4.4,4.5,4.4,7.6v28.4c0,3.1-1.7,6-4.4,7.6L40,75.3C38.6,76.1,37.1,76.4,35.6,76.4z M35.9,3.3l-0.3,0c-1.4,0-2.7,0.4-3.9,1
                           L7.2,18.5c-2.4,1.4-3.9,4-3.9,6.7v28.4c0,2.8,1.5,5.4,3.9,6.8l24.5,14.1c2.4,1.3,5.4,1.3,7.8,0L64,60.3c2.4-1.4,3.9-4,3.9-6.7V25.2
                           c0-2.8-1.5-5.3-3.9-6.7L39.5,4.3C38.4,3.7,37.1,3.3,35.9,3.3z"
                            fill="#31a5ff"
                          />
                        </g>
                        <g>
                          <path
                            class="st1"
                            d="M53.6,44.2l-4.7-7.1c-0.1-1.5-0.4-2.9-0.9-4.3c-0.2-0.5-0.6-0.8-1.1-0.8h-1.2v-3.7c0-0.7-0.5-1.2-1.2-1.2h-1.6
                           c0-0.1-0.1-0.3-0.2-0.4l1.1-1.1c0.5-0.5,0.5-1.2,0-1.7l-3.4-3.4c-0.5-0.5-1.2-0.5-1.7,0c0,0,0,0,0,0l-1.1,1.1l-0.4-0.2v-1.6
                           c0-0.7-0.5-1.2-1.2-1.2h-4.8c-0.7,0-1.2,0.5-1.2,1.2v1.6l-0.4,0.2l-1.1-1.1c-0.5-0.5-1.2-0.5-1.7,0c0,0,0,0,0,0L23.4,24
                           c-0.5,0.5-0.5,1.2,0,1.7l1.1,1.1l-0.2,0.4h-1.6c-0.7,0-1.2,0.5-1.2,1.2l0,0v3.7h-1c-0.5,0-1,0.3-1.1,0.8
                           c-2.8,7.4,0.9,15.8,8.3,18.6c0.1,0,0.2,0.1,0.3,0.1v7c0,0.7,0.5,1.2,1.2,1.2H43c0.7,0,1.2-0.5,1.2-1.2v-6.2h3.5
                           c0.7,0,1.2-0.5,1.3-1.1c0,0,0,0,0-0.1v-5.1h3.7c0.7,0,1.2-0.5,1.2-1.2C53.8,44.7,53.8,44.4,53.6,44.2L53.6,44.2z M24,29.6h1.2
                           c0.5,0,1-0.3,1.1-0.8c0.2-0.6,0.4-1.1,0.7-1.6c0.3-0.5,0.2-1.1-0.2-1.4l-0.9-0.9l1.7-1.7l0.9,0.9c0.4,0.4,1,0.5,1.4,0.2
                           c0.5-0.3,1-0.5,1.6-0.7c0.5-0.1,0.9-0.6,0.9-1.2v-1.2h2.4v1.2c0,0.5,0.4,1,0.9,1.2c0.6,0.2,1.1,0.4,1.6,0.7
                           c0.5,0.3,1.1,0.2,1.4-0.2l0.9-0.9l1.7,1.7l-0.9,0.9c-0.4,0.4-0.5,1-0.2,1.4c0.3,0.5,0.5,1.1,0.7,1.6c0.1,0.5,0.6,0.9,1.2,0.9h1.2
                           v2.5h-5c0.7-2.6-0.8-5.2-3.4-5.9c-2.6-0.7-5.2,0.8-5.9,3.4c-0.2,0.8-0.2,1.7,0,2.5H24V29.6z M31.5,32.1c-0.7-1.1-0.4-2.6,0.7-3.3
                           c1.1-0.7,2.6-0.4,3.3,0.7c0.5,0.8,0.5,1.8,0,2.6H31.5z M47.7,43.7c-0.7,0-1.2,0.5-1.2,1.2V50H43c-0.7,0-1.2,0.5-1.2,1.2v6.2H30.5
                           v-6.7c0-0.5-0.4-1-0.9-1.2c-6.4-1.8-10.1-8.5-8.3-14.9c0-0.1,0-0.1,0.1-0.2H46c0.3,1,0.5,2.1,0.5,3.1c0,0.6,0.1,0.4,3.9,6.1
                           L47.7,43.7z"
                            fill="#ffffff"
                          />
                        </g>
                      </svg>
                      <span class="ml-3">
                        {{
                          $t('habilidades-page.title-comportamiento-emocional')
                        }}
                      </span>
                    </h5>
                  </template>
                </Title>
                <router-link
                  v-if="comportamientoEmocional.length === 0"
                  :to="{ name: 'InstruccionesAssesments', params: { id: 3 } }"
                  class="btn btn-primary btn-rounded-lg btn-md"
                >
                  <span class="mr-2">{{ $t('general.realizar-test') }}</span>
                  <svg
                    width="41"
                    height="19"
                    viewBox="0 0 41 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1072_6965)">
                      <path
                        d="M40.6555 8.09292C38.8929 7.5766 37.2549 6.77541 35.795 5.68935C33.9255 4.30063 32.3588 2.43119 31.3083 0.3125L30.1867 0.864429C31.3439 3.16116 33.0175 5.15523 35.0472 6.66858C35.7416 7.1849 36.4715 7.64781 37.2549 8.05731H0.0976562V9.3036H37.5576C36.7564 9.76651 36.0086 10.2828 35.3143 10.8882C32.608 13.2205 31.3617 16.0158 30.7742 17.9386L31.9671 18.2947C32.5012 16.5143 33.6407 13.9505 36.1155 11.8318C37.4508 10.6923 38.9819 9.81992 40.6733 9.26799L40.6555 8.09292ZM38.5902 8.76947V8.68045C38.6258 8.69826 38.6436 8.69826 38.6793 8.71606C38.6436 8.73387 38.6258 8.75167 38.5902 8.76947Z"
                        fill="#1E1D1D"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1072_6965">
                        <rect
                          width="40.5757"
                          height="18"
                          fill="white"
                          transform="translate(0.0976562 0.3125)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </router-link>
                <button
                  v-if="comportamientoEmocional.length > 0"
                  @click="showModal = true"
                  class="btn btn-primary btn-rounded-lg btn-md"
                >
                  <span class="mr-2">Mejorar Score</span>
                  <svg
                    width="41"
                    height="19"
                    viewBox="0 0 41 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1072_6965)">
                      <path
                        d="M40.6555 8.09292C38.8929 7.5766 37.2549 6.77541 35.795 5.68935C33.9255 4.30063 32.3588 2.43119 31.3083 0.3125L30.1867 0.864429C31.3439 3.16116 33.0175 5.15523 35.0472 6.66858C35.7416 7.1849 36.4715 7.64781 37.2549 8.05731H0.0976562V9.3036H37.5576C36.7564 9.76651 36.0086 10.2828 35.3143 10.8882C32.608 13.2205 31.3617 16.0158 30.7742 17.9386L31.9671 18.2947C32.5012 16.5143 33.6407 13.9505 36.1155 11.8318C37.4508 10.6923 38.9819 9.81992 40.6733 9.26799L40.6555 8.09292ZM38.5902 8.76947V8.68045C38.6258 8.69826 38.6436 8.69826 38.6793 8.71606C38.6436 8.73387 38.6258 8.75167 38.5902 8.76947Z"
                        fill="#1E1D1D"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1072_6965">
                        <rect
                          width="40.5757"
                          height="18"
                          fill="white"
                          transform="translate(0.0976562 0.3125)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
            </div>
            <p class="dark:text-white text-secondary-text mb-0">
              Este test mide la forma en la que una persona se comporta en el
              ámbito laboral. Específicamente con respecto a cuál es la actitud
              que tiene con los demás y ante los problemas, la forma en la que
              escucha y trata a los que lo rodean, la manera en la que toma
              decisiones, su apertura a la retroalimentación y a pedir ayuda y
              su deseo por saber más y mantenerse actualizado.
            </p>
          </section>
        </div>
      </template>
    </CardPage>
    <!-- End Card Page Sign Up -->

    <ResultadoAssesments :id="3" />
  </div>
</template>

<script>
import CardPage from '@/components/CardPage'
// import BoxEstadoDeEmpleo from '@/components/profile/BoxEstadoDeEmpleo'
// import BasicInformationSection from '@/components/profile/BasicInformationSection'
import Title from '@/components/Title'

import ApolloClient from '@/graphql/apolloClient'
import assesmentCategoriesQuery from '@/graphql/queries/assesmentCategories'
import userProfileQuery from '@/graphql/queries/userProfile'
import { mapGetters, mapActions } from 'vuex'
import { toastNotification } from '@/services/toastNotification'
import ResultadoAssesments from '@/views/ResultadoAssesments.vue'
import AlertAnimation from '@/animations/Alert.json'

export default {
  name: 'EmotionalBehaviorTest',
  data() {
    return {
      AlertAnimation,
      userProfile: null,
      modalBienvenidaShow: false,
      assesmentCategories: [],
      showModal: false
    }
  },
  components: {
    CardPage,
    // BasicInformationSection
    Title,
    ResultadoAssesments
  },
  updated() {
    this.getAssesmentCategories()
  },
  mounted() {
    this.setLoadingOn()
    this.getUserProfile()
    this.getAssesmentCategories()
  },
  watch: {
    modalBienvenidaShow(newName) {
      localStorage.modalBienvenidaShow = newName
    }
  },
  computed: {
    ...mapGetters(['token', 'user']),
    comportamientoEmocional() {
      if (
        !this.userProfile ||
        !this.userProfile.takenAssesments ||
        this.userProfile.takenAssesments.length === 0
      ) {
        return []
      }

      return this.userProfile.takenAssesments.filter(
        (assesment) =>
          assesment.assesment.photo === 'icn-comportamiento-emocional.svg'
      )
    }
  },
  methods: {
    ...mapActions(['setLoadingOn', 'setLoadingOff']),
    showTutorialModal: function () {
      // Comprobar si ya se ha mostrado el modal antes y almacenarlo en localStorage
      if (localStorage.getItem('modalBienvenidaShown')) {
        // Si el modal ya se mostró anteriormente, asegurar que no se muestre de nuevo
        this.modalBienvenidaShow = false
      } else {
        // Verificar si el perfil del usuario tiene datos que indicarían que no es su primera visita
        if (
          this.userProfile.englishLevel === null &&
          this.userProfile.idealCompany.length === 0 &&
          this.userProfile.jobTypePreference?.length === 0 &&
          this.userProfile.industries.length === 0 &&
          this.userProfile.jobStatus === null &&
          this.userProfile.minSalary === null &&
          this.userProfile.personsInCharge === null
        ) {
          // Mostrar el modal solo si es evidente que el usuario es nuevo y no ha configurado su perfil
          this.modalBienvenidaShow = true
          // Almacenar en localStorage que el modal ya se ha mostrado para no repetir en futuras sesiones
          localStorage.setItem('modalBienvenidaShown', 'true')
        } else {
          // No mostrar el modal si el perfil del usuario ya está parcialmente o totalmente lleno
          this.modalBienvenidaShow = false
        }
      }
    },

    getAssesmentCategories: async function () {
      try {
        const habilitiesQuery = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale
            }
          },
          fetchPolicy: 'network-only',
          query: assesmentCategoriesQuery
        })
        this.assesmentCategories = habilitiesQuery.data.assesmentCategories
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    getUserProfile: async function () {
      try {
        const userProfileData = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale,
              authorization: `Bearer ${await this.token}`
            }
          },
          fetchPolicy: 'network-only',
          query: userProfileQuery
        })
        this.userProfile = userProfileData.data.userProfile
        this.showTutorialModal()
        this.setLoadingOff()
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    onModalClose() {
      this.modalBienvenidaShow = false
    },
    closeModal() {
      this.showModal = false
    }
  }
}
</script>
