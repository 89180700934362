<template>
  <div class="hero">
    <div class="hero--container">
      <!-- IF SECTION = PAGE TITLE -->
      <div v-if="section == 'pageHero'" class="w-full md:w-9/12 mx-auto">
        <div class="hero--meta">
          <h2 class="text-center hero--title" v-if="heroTitle">
            {{ heroTitle }}
          </h2>
          <div
            class="text-center hero--description"
            v-if="heroDescription"
            v-html="heroDescription"
          ></div>
        </div>
      </div>
      <!-- END IF SECTION = PAGE TITLE -->
      <!-- IF SECTION = PAGE TITLE -->
      <div v-if="section == 'pageHeroEvent'" class="w-full md:w-9/12">
        <div class="hero--meta">
          <!-- Badges -->
          <div class="hero--badges">
            <span
              v-if="category"
              class="badge badge-secondary badge-md badge-rounded-full"
              :class="{ 'bg-secondary': category === 'Webinar' }"
              >{{ category }}</span
            >
            <template v-if="badges">
              <template v-for="badge in badges" :key="badge.id">
                <span class="badge badge-event badge-md badge-rounded-full"
                  >#{{ badge }}</span
                >
              </template>
            </template>
          </div>
          <!-- End Badges-->
          <!-- Title -->
          <h2 v-if="heroTitle" class="hero--title" @click="checkThis">
            {{ heroTitle }}
          </h2>
          <!-- End Title -->
          <!-- Description -->
          <div
            class="mb-10 hero--description"
            v-if="heroDescription"
            v-html="heroDescription"
          ></div>
          <!-- End Description -->
          <!-- INFO -->
          <ul class="hero--info">
            <li>
              <span><img src="@/assets/images/icn-calendar.svg" /></span
              >{{ date }}
            </li>
            <li>
              <span><img src="@/assets/images/icn-clock-time.svg" /> </span
              >{{ time }}
            </li>
            <li>
              <span><img src="@/assets/images/icn-idioma.svg" /></span
              >{{ place }}
            </li>
            <li>
              <span><img src="@/assets/images/icn-idioma.svg" /></span
              >{{ language }}
            </li>
          </ul>
          <!-- End INFO -->
          <div
            class="flex flex-wrap space-y-6 lg:flex-nowrap lg:space-x-6 lg:space-y-0"
          >
            <button
              v-if="isAuthenticated"
              @click="$emit('handleIncription')"
              class="btn btn-xl btn-primary btn-rounded-lg w-full lg:w-auto"
            >
              {{ $t('general.inscribirse') }}
              <svg
                class="ml-2 w-5 text-white fill-current"
                viewBox="0 0 16 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.5 3.375V8.625H12.25V3.375H3.5ZM14.4375 6C14.4375 5.28906 15.0117 4.6875 15.75 4.6875V2.0625C15.75 1.35156 15.1484 0.75 14.4375 0.75H1.3125C0.574219 0.75 0 1.35156 0 2.0625V4.6875C0.710938 4.6875 1.3125 5.28906 1.3125 6C1.3125 6.73828 0.710938 7.3125 0 7.3125V9.9375C0 10.6758 0.574219 11.25 1.3125 11.25H14.4375C15.1484 11.25 15.75 10.6758 15.75 9.9375V7.3125C15.0117 7.3125 14.4375 6.73828 14.4375 6ZM13.125 3.15625V8.84375C13.125 9.22656 12.8242 9.5 12.4688 9.5H3.28125C2.89844 9.5 2.625 9.22656 2.625 8.84375V3.15625C2.625 2.80078 2.89844 2.5 3.28125 2.5H12.4688C12.8242 2.5 13.125 2.80078 13.125 3.15625Z"
                />
              </svg>
            </button>
            <router-link
              v-else
              :to="link"
              title=""
              class="btn btn-xl btn-primary btn-rounded-lg w-full lg:w-auto"
              >{{ $t('general.inscribirse') }}
              <svg
                class="ml-2 w-5 text-white fill-current"
                viewBox="0 0 16 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.5 3.375V8.625H12.25V3.375H3.5ZM14.4375 6C14.4375 5.28906 15.0117 4.6875 15.75 4.6875V2.0625C15.75 1.35156 15.1484 0.75 14.4375 0.75H1.3125C0.574219 0.75 0 1.35156 0 2.0625V4.6875C0.710938 4.6875 1.3125 5.28906 1.3125 6C1.3125 6.73828 0.710938 7.3125 0 7.3125V9.9375C0 10.6758 0.574219 11.25 1.3125 11.25H14.4375C15.1484 11.25 15.75 10.6758 15.75 9.9375V7.3125C15.0117 7.3125 14.4375 6.73828 14.4375 6ZM13.125 3.15625V8.84375C13.125 9.22656 12.8242 9.5 12.4688 9.5H3.28125C2.89844 9.5 2.625 9.22656 2.625 8.84375V3.15625C2.625 2.80078 2.89844 2.5 3.28125 2.5H12.4688C12.8242 2.5 13.125 2.80078 13.125 3.15625Z"
                />
              </svg>
            </router-link>
            <Share
              button-styles="btn btn-xl border-2 btn-outline-secondary btn-outline-secondary-glow btn-rounded-lg w-full lg:w-auto"
              :link="`${$i18n.locale}/comunidad/evento/${link.params.id}`"
              :title="link.params.name"
            >
              <template #button>
                {{ $t('general.compartir') }}
                <svg
                  class="ml-2 w-5 text-white fill-current"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 8.75C9.87109 8.75 9.29688 8.96875 8.85938 9.32422L6.04297 7.57422C6.07031 7.4375 6.125 7.16406 6.125 7C6.125 6.86328 6.07031 6.58984 6.04297 6.45312L8.85938 4.70312C9.29688 5.05859 9.87109 5.25 10.5 5.25C11.9492 5.25 13.125 4.10156 13.125 2.625C13.125 1.17578 11.9492 0 10.5 0C9.02344 0 7.875 1.17578 7.875 2.625C7.875 2.84375 7.875 3.03516 7.92969 3.19922L5.11328 4.94922C4.67578 4.59375 4.10156 4.375 3.5 4.375C2.02344 4.375 0.875 5.55078 0.875 7C0.875 8.47656 2.02344 9.625 3.5 9.625C4.10156 9.625 4.67578 9.43359 5.11328 9.07812L7.92969 10.8281C7.90234 10.9648 7.875 11.2383 7.875 11.375C7.875 12.8516 9.02344 14 10.5 14C11.9492 14 13.125 12.8516 13.125 11.375C13.125 9.92578 11.9492 8.75 10.5 8.75Z"
                  />
                </svg>
              </template>
            </Share>
          </div>
        </div>
      </div>
      <!-- END IF SECTION = PAGE TITLE -->
      <!-- IF SECTION HAVE CTA -->
      <div v-if="section == 'pageHeroCta'" class="w-full md:w-6/12">
        <div class="hero--meta">
          <h2 class="hero--title" v-if="heroTitle">
            {{ heroTitle }}
          </h2>
          <p class="hero--description" v-if="heroDescription">
            {{ heroDescription }}
          </p>
          <slot name="pageHeroCta"> </slot>
        </div>
      </div>
      <!-- END SECTION HAVE CTA -->
    </div>
    <picture class="hero--image" v-if="heroImage">
      <img :src="heroImage" :alt="heroDescription" />
    </picture>
  </div>
</template>

<script>
import Share from '@/components/Share'

export default {
  name: 'Hero',
  props: {
    heroTitle: String,
    heroDescription: String,
    heroImage: String,
    section: String,
    category: String,
    time: String,
    date: String,
    place: String,
    language: String,
    badges: Array,
    link: Object,
    isAuthenticated: Boolean
  },
  emits: ['handleIncription'],
  computed: {
    currentUrl() {
      return `${process.env.VUE_APP_URI}${this.$route.fullPath}`
    }
  },
  components: {
    Share
  },
  data: function () {
    return {
      modalOpen: false
    }
  }
}
</script>
