<template>
  <section class="mb-12">
    <!-- <div class="flex justify-between items-baseline">
      <h4
        class="text-xl font-bold text-contrast dark:text-white w-full mb-4 mt-2"
      >
        {{ $t('profile-page.title-redes-sociales') }}
      </h4>
      <button
        @click="editSocialNetwork = true"
        v-if="!editSocialNetwork"
        class="
          w-9
          h-9
          flex
          justify-center
          items-center
          rounded-md
          btn-secondary
        "
      >
        <img src="@/assets/images/icn-edit.svg" />
      </button>
    </div> -->
    <Form @submit="saveSocialNetworks" v-if="editSocialNetwork">
      <div class="flex mb-6 lg:mt-7">
        <div
          class="flex justify-between bg-black items-center uppercase border-2 border-primary rounded-lg w-full px-3 lg:px-6 py-3 lg:py-5"
        >
          <div class="title text-center">
            <h5 class="mt-0 text-lg">
              {{ $t('profile-page.title-redes-sociales') }}
            </h5>
          </div>
          <button
            type="submit"
            class="btn btn-primary btn-rounded-lg btn-md"
            role="button"
            aria-pressed="false"
          >
            <svg
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.8844 7.56222V17.7186C18.8844 18.1452 18.7416 18.5013 18.4559 18.787C18.1702 19.0726 17.8141 19.2155 17.3876 19.2155H5.55642C5.12989 19.2155 4.77379 19.0726 4.4881 18.787C4.20241 18.5013 4.05957 18.1452 4.05957 17.7186V5.88747C4.05957 5.46095 4.20241 5.10484 4.4881 4.81916C4.77379 4.53347 5.12989 4.39062 5.55642 4.39062H15.7128L18.8844 7.56222ZM17.9579 7.95786L15.3172 5.31718H5.55642C5.3901 5.31718 5.25343 5.37061 5.14642 5.47747C5.03956 5.58449 4.98612 5.72116 4.98612 5.88747V17.7186C4.98612 17.885 5.03956 18.0216 5.14642 18.1286C5.25343 18.2355 5.3901 18.2889 5.55642 18.2889H17.3876C17.5539 18.2889 17.6906 18.2355 17.7976 18.1286C17.9044 18.0216 17.9579 17.885 17.9579 17.7186V7.95786ZM11.472 16.0082C11.9828 16.0082 12.4194 15.8271 12.7817 15.4648C13.144 15.1025 13.3251 14.666 13.3251 14.1551C13.3251 13.6443 13.144 13.2077 12.7817 12.8454C12.4194 12.4831 11.9828 12.302 11.472 12.302C10.9612 12.302 10.5246 12.4831 10.1623 12.8454C9.80003 13.2077 9.61889 13.6443 9.61889 14.1551C9.61889 14.666 9.80003 15.1025 10.1623 15.4648C10.5246 15.8271 10.9612 16.0082 11.472 16.0082ZM6.62543 9.73615H13.5032V6.95648H6.62543V9.73615ZM4.98612 7.95786V18.2889V5.31718V7.95786Z"
                fill="black"
              />
            </svg>
            {{ $t('form.btn-guardar') }}
          </button>
        </div>
      </div>
      <p class="dark:text-white text-secondary-text">
        {{ $t(`profile-page.title-redes-sociales`) }}
      </p>
      <div
        class="grid gap-x-6 gap-y-4 grid-cols-1 lg:grid-cols-3 mb-8 items-start"
      >
        <div class="form--icon form--icon--left">
          <span class="icon--wrapper bg-github rounded-l-lg">
            <img
              src="@/assets/images/social-media/icn-github.svg"
              alt="Github"
            />
          </span>
          <input
            type="url"
            class="form--element element--sm w-full border-2 border-primary"
            :placeholder="$t('profile-page.social-holder-github')"
            v-model="social.github"
            pattern="https?://.*"
            required
          />
        </div>
        <div class="form--icon form--icon--left">
          <span class="icon--wrapper bg-github rounded-l-lg">
            <img
              src="@/assets/images/social-media/x-white.png"
              alt="X"
              width="20px"
            />
          </span>
          <input
            type="url"
            class="form--element element--sm w-full border-2 border-primary"
            :placeholder="$t('profile-page.social-holder-twitter')"
            v-model="social.twitter"
            pattern="https?://.*"
            required
          />
        </div>
        <div>
          <div class="form--icon form--icon--left">
            <span class="icon--wrapper bg-bitbucket rounded-l-lg">
              <img
                src="@/assets/images/social-media/icn-bitbucket.svg"
                alt="Bitbucket"
              />
            </span>
            <Field
              name="bitbucket"
              type="url"
              :rules="validateUrl"
              class="form--element element--sm w-full border-2 border-primary"
              :placeholder="$t('profile-page.social-holder-bitbucket')"
              v-model="social.bitbucket"
              pattern="https?://.*"
              required
            />
          </div>
          <ErrorMessage name="bitbucket" class="text-primary text-sm" />
        </div>
        <div>
          <div class="form--icon form--icon--left">
            <span class="icon--wrapper bg-dribble rounded-l-lg">
              <img
                src="@/assets/images/social-media/icn-dribble.svg"
                alt="Dribbble"
              />
            </span>
            <Field
              name="dribbble"
              type="url"
              :rules="validateUrl"
              class="form--element element--sm w-full border-2 border-primary"
              :placeholder="$t('profile-page.social-holder-dribble')"
              v-model="social.dribble"
              pattern="https?://.*"
              required
            />
          </div>
          <ErrorMessage name="dribbble" class="text-primary text-sm" />
        </div>
        <div>
          <div class="form--icon form--icon--left">
            <span class="icon--wrapper bg-behance rounded-l-lg">
              <img
                src="@/assets/images/social-media/icn-behance.svg"
                alt="Behance"
              />
            </span>
            <Field
              name="behance"
              type="url"
              :rules="validateUrl"
              class="form--element element--sm w-full border-2 border-primary"
              :placeholder="$t('profile-page.social-holder-behance')"
              v-model="social.behance"
              pattern="https?://.*"
              required
            />
          </div>
          <ErrorMessage name="behance" class="text-primary text-sm" />
        </div>
      </div>
    </Form>
    <ul
      class="grid gap-x-6 gap-y-4 grid-cols-1 lg:grid-cols-3 text-base dark:text-primary-text text-secondary-text items-center list--social mb-6"
      v-else
    >
      <li v-if="social.github">
        <span class="bg-github mr-2">
          <img src="@/assets/images/social-media/icn-github.svg" alt="Github" />
        </span>
        {{ social.github }}
      </li>
      <li v-if="social.twitter">
        <span class="bg-twitter mr-2">
          <img src="@/assets/images/social-media/icn-twitter.svg" alt="X" />
        </span>
        {{ social.twitter }}
      </li>
      <li v-if="social.bitbucket">
        <span class="bg-bitbucket mr-2">
          <img
            src="@/assets/images/social-media/icn-bitbucket.svg"
            alt="Bitbucket"
          />
        </span>
        {{ social.bitbucket }}
      </li>
      <li v-if="social.dribble">
        <span class="bg-dribble mr-2">
          <img
            src="@/assets/images/social-media/icn-dribble.svg"
            alt="Dribbble"
          />
        </span>
        {{ social.dribble }}
      </li>
      <li v-if="social.behance">
        <span class="bg-behance mr-2">
          <img
            src="@/assets/images/social-media/icn-behance.svg"
            alt="Behance"
          />
        </span>
        {{ social.behance }}
      </li>
    </ul>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import apolloClient from '../../graphql/apolloClient'
import SaveSocialNetworksMutation from '../../graphql/mutations/saveSocialNetworks'
import { Field, Form, ErrorMessage } from 'vee-validate'
import { validateUrl } from '@/services/validationRules'
import { toastNotification } from '@/services/toastNotification'

export default {
  name: 'SocialMediaSection',
  components: {
    Field,
    Form,
    ErrorMessage
  },
  props: {
    socialNetworks: Object,
    id: { type: Number, required: false },
    initialData: Object
  },
  computed: {
    ...mapGetters(['token', 'profile', 'user'])
  },
  data() {
    return {
      editSocialNetwork: true,
      social: {}
    }
  },
  async beforeMount() {
    await this.fetchProfile()
    await this.getSocialNetworks()
  },
  methods: {
    ...mapActions([
      'updateUserSocialNetworks',
      'setLoadingOn',
      'setLoadingOff',
      'fetchProfile',
      'fetchUser'
    ]),
    async getSocialNetworks() {
      this.social = {
        github: this.initialData
          ? this.initialData?.github
          : this.profile?.socialNetworks?.github,
        twitter: this.initialData
          ? this.initialData?.twitter
          : this.profile?.socialNetworks?.twitter,
        bitbucket: this.initialData
          ? this.initialData?.bitbucket
          : this.profile?.socialNetworks?.bitbucket,
        dribble: this.initialData
          ? this.initialData?.dribble
          : this.profile?.socialNetworks?.dribble,
        behance: this.initialData
          ? this.initialData?.behance
          : this.profile?.socialNetworks?.behance,
        linkedin: this.initialData
          ? this.initialData?.linkedin
          : this.profile?.socialNetworks?.linkedin
      }
      if (
        this.social.linkedin ||
        this.social.github ||
        this.social.twitter ||
        this.social.bitbucket ||
        this.social.dribble ||
        this.social.behance !== null
      ) {
        this.editSocialNetwork = true
      } else {
        this.editSocialNetwork = true
      }
    },
    async saveSocialNetworks() {
      // Validación de URLs
      const urlPattern = /^https?:\/\/.+/ // Expresión regular para validar URL
      const urls = {
        github: this.social.github,
        twitter: this.social.twitter,
        bitbucket: this.social.bitbucket,
        dribble: this.social.dribble,
        behance: this.social.behance
      }

      for (const [key, value] of Object.entries(urls)) {
        if (value && !urlPattern.test(value)) {
          toastNotification(
            `${
              key.charAt(0).toUpperCase() + key.slice(1)
            } no es una URL válida.`,
            'error'
          )
          return // Detener el envío si hay un error
        }
      }

      try {
        this.setLoadingOn()
        const saveSocialNetworks = await apolloClient.mutate({
          mutation: SaveSocialNetworksMutation,
          context: {
            headers: {
              authorization: `Bearer ${await this.token}`
            }
          },
          variables: {
            linkedin: this.social.linkedin,
            github: this.social.github,
            twitter: this.social.twitter,
            behance: this.social.behance,
            dribble: this.social.dribble,
            bitbucket: this.social.bitbucket,
            id: this.id
          }
        })
        if (!saveSocialNetworks.errors) {
          await this.fetchUser()
          await this.fetchProfile()
          this.setLoadingOff()
          toastNotification(this.$t('general.info-guardada'), 'success')
        }
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    // RULES
    validateUrl
  }
}
</script>
