<template>
  <div v-if="showModal" class="welcome flex justify-center items-center">
    <div class="welcome-container bg-black text-white px-10 py-12 rounded-xl border-2 w-full relative"
      :class="'border-[#B9A3FF]'">
      <button class="absolute top-3 right-3 text-white" @click="$emit('cerrarModal')">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="white">
          <path
            d="M6.225 4.811l5.298 5.298 5.298-5.298 1.414 1.414-5.298 5.298 5.298 5.298-1.414 1.414-5.298-5.298-5.298 5.298-1.414-1.414 5.298-5.298-5.298-5.298z" />
        </svg>
      </button>
      <div class="modal-title text-center mb-4">
        <h2 class="text-2xl font-bold flex items-center justify-center">
          {{ $t('profile.referral_title') }}
          <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-1">
            <rect width="20.1362" height="20.1362" transform="translate(0.966797 0.865234)" fill="black" />
            <path
              d="M18.6294 14.0516L12.2076 10.9339L18.6318 7.81564C18.8141 7.71419 18.9629 7.50278 19.0303 7.24217C19.0984 6.97791 19.071 6.71122 18.9568 6.52837C18.8317 6.30359 18.5662 6.12681 18.2582 6.05756C18.0189 6.00349 17.7983 6.02597 17.6695 6.11831L11.6797 10.1739L12.241 3.00126C12.2781 2.67686 12.1711 2.43812 11.9239 2.28321C11.5697 2.06208 11.0041 2.0724 10.6354 2.3075C10.4604 2.41928 10.2618 2.62826 10.3073 2.99154L10.8699 10.1733L4.88426 6.12074C4.45415 5.8826 3.83026 6.10676 3.60002 6.4585C3.3558 7.01254 3.56964 7.64129 3.92016 7.81625L10.3414 10.9339L3.9232 14.0497C3.73306 14.1573 3.58604 14.3668 3.51922 14.6256C3.45118 14.8899 3.47852 15.1566 3.59273 15.3388C3.71787 15.5636 3.98334 15.7404 4.29134 15.8096C4.5307 15.8637 4.75122 15.8412 4.88 15.7489L10.8699 11.6933L10.3086 18.8659C10.2624 19.2389 10.461 19.4479 10.636 19.5597C11.0041 19.7948 11.5703 19.8051 11.9245 19.584C12.1717 19.4291 12.2787 19.1903 12.2422 18.8738L11.6797 11.6939L17.6653 15.7465C18.096 15.9846 18.7199 15.7604 18.9495 15.4087C19.1938 14.8553 18.9799 14.2265 18.6294 14.0516Z"
              fill="#DB7E6E" />
          </svg>
        </h2>
        <p class="text-white text-lg mt-2">
          {{ $t('profile.referral_message') }}
        </p>
      </div>
      <div class="modal-form space-y-4">
        <input type="text" class="form--element w-full px-3 py-2" :placeholder="$t('profile.name')"
          v-model="form.name" />
        <input type="text" class="form--element w-full px-3 py-2" :placeholder="$t('profile.email')"
          v-model="form.email" />
        <select class="form--element form--select w-full px-3 py-2" :placeholder="$t('profile.speciality')"
          v-model="form.speciality">
          <option disabled value="">{{ $t('profile.speciality') }}</option>
          <option v-for="speciality in specialities" :key="speciality" :value="speciality">
            {{ speciality }}
          </option>
        </select>
      </div>
      <div class="modal-footer text-center mb-4 mt-8">
        <p class="text-white text-sm mt-2">
          {{ $t('profile.referral_subtitle') }}
        </p>
        <div class="flex justify-center mt-4">
          <button
            class="flex justify-between items-center w-auto max-w-md px-6 py-3 rounded-[6.22px] bg-[#28b99a] mx-auto"
            :disabled="!isFormValid" style="
              box-shadow: 0px 0.713px 2.14px 0 rgba(0, 0, 0, 0.05),
                0px 1.427px 0.713px 0 rgba(0, 0, 0, 0.03),
                0px 0.713px 0.713px 0 rgba(0, 0, 0, 0.04);
              max-height: 45px !important;
              min-width: 150px !important;
            " @click="handleSubmit">
            <span class="text-md font-medium text-black"> {{ $t('profile.referral_button') }} </span>
            <svg width="32" height="15" viewBox="0 0 32 15" fill="none" xmlns="http://www.w3.org/2000/svg"
              class="w-[31.56px] h-3.5" preserveAspectRatio="xMidYMid meet">
              <g clip-path="url(#clip0_2002_12177)">
                <path
                  d="M31.8713 6.64446C30.5006 6.24293 29.2267 5.61986 28.0913 4.77525C26.6375 3.69526 25.4191 2.24143 24.6022 0.59375L23.7299 1.02298C24.6298 2.80911 25.9314 4.35987 27.5098 5.53678C28.0498 5.93831 28.6175 6.29831 29.2267 6.61677H0.330078V7.58599H29.4621C28.839 7.94599 28.2575 8.34752 27.7175 8.81829C25.6129 10.6321 24.6437 12.8059 24.1868 14.3013L25.1145 14.5782C25.5298 13.1936 26.416 11.1998 28.3406 9.55213C29.379 8.66598 30.5698 7.98753 31.8852 7.5583L31.8713 6.64446ZM30.2652 7.17061V7.10138C30.2929 7.11523 30.3067 7.11523 30.3344 7.12907C30.3067 7.14292 30.2929 7.15676 30.2652 7.17061Z"
                  fill="#1E1D1D" />
              </g>
              <defs>
                <clipPath id="clip0_2002_12177">
                  <rect width="31.5551" height="13.9983" fill="white" transform="translate(0.330078 0.59375)"></rect>
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import specialitiesList from '@/constants/specialities.json'
import apolloClient from '@/graphql/apolloClient'
import referUserMutation from '@/graphql/mutations/referUserMutation'

export default {
  name: 'ModalReferido',
  data() {
    return {
      showModal: true,
      specialities: specialitiesList,
      form: {
        name: '',
        email: '',
        speciality: ''
      }
    }
  },
  computed: {
    isFormValid() {
      return this.form.name && this.form.email && this.form.speciality
    }
  },
  methods: {
    async handleSubmit() {
      try {
        const response = await apolloClient.mutate({
          mutation: referUserMutation,
          variables: {
            name: this.form.name,
            email: this.form.email,
            speciality: this.form.speciality,
            userReferalEmail: this.$store.state.user.email
          }
        })
        console.log('Referencia enviada con éxito:', response.data)

        alert(
          '¡Gracias por tu recomendación!\nHemos recibido tu referencia exitosamente.\n\nTe notificaremos por correo electrónico si tu amigo es conectado con una empresa exitosamente para solicitar tu número de cuenta y transferirte los $500 USD.\n\n¡Gracias por ayudarnos a encontrar a los mejores desarrolladores y a hacer crecer nuestra comunidad!'
        )

        this.$emit('cerrarModal')
      } catch (error) {
        console.error('Error al enviar la referencia:', error)
      }
    }
  }
}
</script>
