import gql from 'graphql-tag'

const candidatesFilterQuery = gql`
  query getCandidatesFilter(
    $position: String
    $country: String
    $city: String
    $jobStatus: String
    $minSalary: Int
    $maxSalary: Int
    $minYears: Int
    $maxYears: Int
    $category: Int
    $status: String
    $search: String
    $requiredTechnologies: [String]
    $suggestedTechnologies: [String]
  ) {
    candidates(
      position: $position
      country: $country
      city: $city
      jobStatus: $jobStatus
      minSalary: $minSalary
      maxSalary: $maxSalary
      requiredTechnologies: $requiredTechnologies
      suggestedTechnologies: $suggestedTechnologies
      minYears: $minYears
      maxYears: $maxYears
      category: $category
      status: $status
      search: $search
    ) {
      id
      userId
      name
      email
      publicUri
      country
      city
      jobStatus
      yearsOfExp
      speciality
      minSalary
      updatedAt
      status
      jobOffers {
        id
      }
      knownTechnologies
      expertTechnologies
      technologies {
        techName
        level
        expYears
      }
      socialNetworks {
        behance
        bitbucket
        dribble
        github
        linkedin
        twitter
        portfolio
      }
    }
  }
`
export default candidatesFilterQuery
