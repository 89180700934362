import gql from 'graphql-tag'

const saveTechAssesmentsMutation = gql`
  mutation saveTechAssesmentsMutation(
    $assesments: [UserProfileTechAssesmentInputType]
    $id: Int
  ) {
    updateTechAssesments(assesments: $assesments, id: $id) {
      name
      startDate
      endDate
      certifiedBy
      certId
      certUrl
      file
    }
  }
`

export default saveTechAssesmentsMutation
