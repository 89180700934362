import gql from 'graphql-tag'

const saveAboutUser = gql`
  mutation saveAboutUser($about: String, $id: Int!) {
    updateAbout(about: $about, id: $id) {
      id
      about
    }
  }
`

export default saveAboutUser
