import gql from 'graphql-tag'

const createUser = gql`
  mutation createUser(
    $email: String!
    $phonenumber: String
    $fullname: String!
    $firstname: String!
    $lastname: String!
    $surname: String
    $country: String
    $speciality: String
    $yearsOfExp: Int
  ) {
    createUser(
      email: $email
      phonenumber: $phonenumber
      fullname: $fullname
      firstname: $firstname
      lastname: $lastname
      surname: $surname
      country: $country
      speciality: $speciality
      yearsOfExp: $yearsOfExp
    ) {
      email
    }
  }
`

export default createUser
