import gql from 'graphql-tag'

const savePersonalInfoMutation = gql`
  mutation savePersonalInfoMutation(
    $fullname: String
    $country: String
    $city: String
    $yearsOfExp: Int
    $englishLevel: Int
    $minSalary: Int
    $realSalary: Int
    $openToNegotiate: Boolean
    $phonenumber: String
    $speciality: String
    $id: Int
    $about: String
  ) {
    updatePersonalInfo(
      fullname: $fullname
      country: $country
      city: $city
      phonenumber: $phonenumber
      speciality: $speciality
      yearsOfExp: $yearsOfExp
      englishLevel: $englishLevel
      minSalary: $minSalary
      realSalary: $realSalary
      openToNegotiate: $openToNegotiate
      id: $id
      about: $about
    ) {
      id
      speciality
      about
    }
  }
`

export default savePersonalInfoMutation
