<template>
  <slot name="default">
    <button :class="buttonStyles" @click="modalOpen = true">
      <slot name="button"></slot>
    </button>
  </slot>
  <teleport to="#modals">
    <transition name="fade">
      <ModalShare
        size="xs"
        v-if="modalOpen"
        @cerrarModal="
          ;(modalOpen = false), (error = false), (successful = false)
        "
      >
        <template #default>
          <div class="modal--header">
            <Title>
              <template #title>
                <h3>{{ $t('modal-share.title') }}</h3>
              </template>
            </Title>
          </div>
          <div class="modal--body">
            <p class="dark:text-primary-text text-secondary-text mb-2">
              {{ $t('modal-share.description') }}
            </p>
            <div class="flex space-x-4 mb-8">
              <a
                :href="`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.utopicode.io/${link}&amp;t=${title}`"
                target="_blank"
                rel="noopener"
                aria-label="Share on Facebook"
                class="btn btn-facebook btn-rounded-lg btn-md btn-full focus:outline-none"
                ><img
                  src="@/assets/images/social-media/icn-facebook.svg"
                  alt="Utopicode Facebook"
              /></a>
              <a
                :href="`https://twitter.com/intent/tweet?url=https%3A%2F%2Fwww.utopicode.io/${link}&via=Utopicode&text=${title}`"
                target="_blank"
                rel="noopener"
                aria-label="Share on X"
                class="btn btn-twitter btn-rounded-lg btn-md btn-full focus:outline-none"
                ><img
                  src="@/assets/images/social-media/icn-twitter.svg"
                  alt="Utopicode X"
              /></a>
              <!--<a
                :href="`${link}`"
                target="_blank"
                rel="noopener"
                aria-label="Share on Instagram"
                class="btn btn-instagram btn-rounded-lg btn-md btn-full focus:outline-none"
                ><img
                  src="@/assets/images/social-media/icn-instagram.svg"
                  alt="Utopicode Instagram"
              /></a>-->
              <a
                :href="`https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fwww.utopicode.io/${link}&title=${title}`"
                target="_blank"
                rel="noopener"
                aria-label="Share on LinkedIn"
                class="btn btn-linkedin btn-rounded-lg btn-md btn-full focus:outline-none"
                ><img
                  src="@/assets/images/social-media/icn-linkedin.svg"
                  alt="Utopicode LinkedIn"
              /></a>
              <a
                :href="`https://api.whatsapp.com/send?text=${title} http%3A%2F%2Fwww.utopicode.io/${link}`"
                target="_blank"
                rel="noopener"
                aria-label="Share on Whatsapp"
                class="btn btn-whatapp btn-rounded-lg btn-md btn-full focus:outline-none"
                ><img
                  src="@/assets/images/social-media/icn-whatsapp.svg"
                  alt="Utopicode Whatsapp"
              /></a>
            </div>

            <div class="modal--divider -ml-8 -mr-14">
              <h4>{{ $t('modal-share.divider') }}</h4>
            </div>
            <form>
              <div class="relative mb-0">
                <input
                  type="text"
                  :value="`https://utopicode.io/${link}`"
                  id="copy-url"
                  name="mail-newsletter"
                  class="form--element w-full"
                  disabled
                />
                <button
                  type="button"
                  @click.stop.prevent="copyUrl"
                  class="btn btn-secondary btn-lg absolute top-0 right-0 rounded-r-lg focus:outline-none"
                >
                  {{ $t('modal-share.btn-copy') }}
                </button>
              </div>
              <div
                class="dark:text-primary-text text-secondary-text text-base mt-2"
              >
                <transition name="fade">
                  <template v-if="successful">{{
                    $t('modal-share.share-copiado')
                  }}</template>
                </transition>
                <transition name="fade">
                  <template v-if="error">{{
                    $t('modal-share.share-error')
                  }}</template>
                </transition>
              </div>
            </form>
          </div>
        </template>
      </ModalShare>
    </transition>
  </teleport>
</template>

<script>
import ModalShare from '@/components/Modal'
import Title from '@/components/Title'

export default {
  name: 'Share',
  props: {
    link: String,
    title: String,
    buttonStyles: String
  },
  components: {
    ModalShare,
    Title
  },
  data() {
    return {
      modalOpen: false,
      successful: false,
      error: false
    }
  },
  methods: {
    copyUrl() {
      const urlCopyClipboard = document.querySelector('#copy-url')

      try {
        navigator.clipboard.writeText(urlCopyClipboard.value)
        this.successful = true
        this.error = false
      } catch (err) {
        this.successful = false
        this.error = true
      }
      window.getSelection().removeAllRanges()
    }
  }
}
</script>
