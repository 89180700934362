<template>

  <body class="a4-page" v-if="userProfile">
    <header style="position: relative; z-index: 1">
      <div style="
          position: absolute;
          top: 0;
          width: 100%;
          height: 167px;
          overflow: hidden;
        ">
        <svg width="1447" height="176" viewBox="0 0 1447 176" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1630_48634)">
            <path
              d="M28.7351 29.8861C21.8576 29.8861 16.2617 24.2902 16.2617 17.4128C16.2617 10.5345 21.8576 4.93945 28.7351 4.93945C35.6125 4.93945 41.2085 10.5345 41.2085 17.4128C41.2085 24.2911 35.6125 29.8861 28.7351 29.8861ZM28.7351 6.11264C22.5044 6.11264 17.4349 11.1821 17.4349 17.4128C17.4349 23.6434 22.5044 28.7129 28.7351 28.7129C34.9657 28.7129 40.0352 23.6434 40.0352 17.4128C40.0352 11.1821 34.9666 6.11264 28.7351 6.11264Z"
              fill="#5956D9" />
            <path
              d="M60.0984 29.8861C53.2209 29.8861 47.625 24.2902 47.625 17.4128C47.625 10.5345 53.2209 4.93945 60.0984 4.93945C66.9758 4.93945 72.5717 10.5345 72.5717 17.4128C72.5717 24.2911 66.9758 29.8861 60.0984 29.8861ZM60.0984 6.11264C53.8677 6.11264 48.7982 11.1821 48.7982 17.4128C48.7982 23.6434 53.8677 28.7129 60.0984 28.7129C66.329 28.7129 71.3985 23.6434 71.3985 17.4128C71.3985 11.1821 66.3299 6.11264 60.0984 6.11264Z"
              fill="#5956D9" />
            <path
              d="M91.4577 29.8861C84.5803 29.8861 78.9844 24.2902 78.9844 17.4128C78.9844 10.5345 84.5803 4.93945 91.4577 4.93945C98.3352 4.93945 103.931 10.5345 103.931 17.4128C103.931 24.2911 98.3352 29.8861 91.4577 29.8861ZM91.4577 6.11264C85.2271 6.11264 80.1576 11.1821 80.1576 17.4128C80.1576 23.6434 85.2271 28.7129 91.4577 28.7129C97.6884 28.7129 102.758 23.6434 102.758 17.4128C102.758 11.1821 97.6893 6.11264 91.4577 6.11264Z"
              fill="#5956D9" />
            <path
              d="M122.821 29.8861C115.944 29.8861 110.348 24.2902 110.348 17.4128C110.348 10.5345 115.944 4.93945 122.821 4.93945C129.698 4.93945 135.294 10.5345 135.294 17.4128C135.294 24.2911 129.698 29.8861 122.821 29.8861ZM122.821 6.11264C116.59 6.11264 111.521 11.1821 111.521 17.4128C111.521 23.6434 116.59 28.7129 122.821 28.7129C129.052 28.7129 134.121 23.6434 134.121 17.4128C134.121 11.1821 129.053 6.11264 122.821 6.11264Z"
              fill="#5956D9" />
            <path
              d="M154.18 29.8861C147.303 29.8861 141.707 24.2902 141.707 17.4128C141.707 10.5345 147.303 4.93945 154.18 4.93945C161.058 4.93945 166.654 10.5345 166.654 17.4128C166.654 24.2911 161.058 29.8861 154.18 29.8861ZM154.18 6.11264C147.95 6.11264 142.88 11.1821 142.88 17.4128C142.88 23.6434 147.95 28.7129 154.18 28.7129C160.411 28.7129 165.481 23.6434 165.481 17.4128C165.481 11.1821 160.412 6.11264 154.18 6.11264Z"
              fill="#5956D9" />
            <path
              d="M185.544 29.8861C178.666 29.8861 173.07 24.2902 173.07 17.4128C173.07 10.5345 178.666 4.93945 185.544 4.93945C192.421 4.93945 198.017 10.5345 198.017 17.4128C198.017 24.2911 192.421 29.8861 185.544 29.8861ZM185.544 6.11264C179.313 6.11264 174.244 11.1821 174.244 17.4128C174.244 23.6434 179.313 28.7129 185.544 28.7129C191.774 28.7129 196.844 23.6434 196.844 17.4128C196.844 11.1821 191.775 6.11264 185.544 6.11264Z"
              fill="#5956D9" />
            <path
              d="M216.907 29.8861C210.029 29.8861 204.434 24.2902 204.434 17.4128C204.434 10.5345 210.029 4.93945 216.907 4.93945C223.784 4.93945 229.38 10.5345 229.38 17.4128C229.38 24.2911 223.784 29.8861 216.907 29.8861ZM216.907 6.11264C210.676 6.11264 205.607 11.1821 205.607 17.4128C205.607 23.6434 210.676 28.7129 216.907 28.7129C223.138 28.7129 228.207 23.6434 228.207 17.4128C228.207 11.1821 223.138 6.11264 216.907 6.11264Z"
              fill="#5956D9" />
            <path
              d="M248.266 29.8861C241.389 29.8861 235.793 24.2902 235.793 17.4128C235.793 10.5345 241.389 4.93945 248.266 4.93945C255.144 4.93945 260.74 10.5345 260.74 17.4128C260.74 24.2911 255.144 29.8861 248.266 29.8861ZM248.266 6.11264C242.036 6.11264 236.966 11.1821 236.966 17.4128C236.966 23.6434 242.036 28.7129 248.266 28.7129C254.497 28.7129 259.566 23.6434 259.566 17.4128C259.566 11.1821 254.498 6.11264 248.266 6.11264Z"
              fill="#5956D9" />
            <path
              d="M279.63 29.8861C272.752 29.8861 267.156 24.2902 267.156 17.4128C267.156 10.5345 272.752 4.93945 279.63 4.93945C286.507 4.93945 292.103 10.5345 292.103 17.4128C292.103 24.2911 286.507 29.8861 279.63 29.8861ZM279.63 6.11264C273.399 6.11264 268.329 11.1821 268.329 17.4128C268.329 23.6434 273.399 28.7129 279.63 28.7129C285.86 28.7129 290.93 23.6434 290.93 17.4128C290.93 11.1821 285.861 6.11264 279.63 6.11264Z"
              fill="#5956D9" />
            <path
              d="M310.989 29.8861C304.112 29.8861 298.516 24.2902 298.516 17.4128C298.516 10.5345 304.112 4.93945 310.989 4.93945C317.866 4.93945 323.462 10.5345 323.462 17.4128C323.462 24.2911 317.866 29.8861 310.989 29.8861ZM310.989 6.11264C304.758 6.11264 299.689 11.1821 299.689 17.4128C299.689 23.6434 304.758 28.7129 310.989 28.7129C317.22 28.7129 322.289 23.6434 322.289 17.4128C322.289 11.1821 317.221 6.11264 310.989 6.11264Z"
              fill="#5956D9" />
            <path
              d="M342.352 29.8861C335.475 29.8861 329.879 24.2902 329.879 17.4128C329.879 10.5345 335.475 4.93945 342.352 4.93945C349.23 4.93945 354.826 10.5345 354.826 17.4128C354.826 24.2911 349.23 29.8861 342.352 29.8861ZM342.352 6.11264C336.122 6.11264 331.052 11.1821 331.052 17.4128C331.052 23.6434 336.122 28.7129 342.352 28.7129C348.583 28.7129 353.652 23.6434 353.652 17.4128C353.652 11.1821 348.584 6.11264 342.352 6.11264Z"
              fill="#5956D9" />
            <path
              d="M373.712 29.8861C366.834 29.8861 361.238 24.2902 361.238 17.4128C361.238 10.5345 366.834 4.93945 373.712 4.93945C380.589 4.93945 386.185 10.5345 386.185 17.4128C386.185 24.2911 380.589 29.8861 373.712 29.8861ZM373.712 6.11264C367.481 6.11264 362.411 11.1821 362.411 17.4128C362.411 23.6434 367.481 28.7129 373.712 28.7129C379.942 28.7129 385.012 23.6434 385.012 17.4128C385.012 11.1821 379.943 6.11264 373.712 6.11264Z"
              fill="#5956D9" />
            <path
              d="M405.075 29.8861C398.197 29.8861 392.602 24.2902 392.602 17.4128C392.602 10.5345 398.197 4.93945 405.075 4.93945C411.952 4.93945 417.548 10.5345 417.548 17.4128C417.548 24.2911 411.952 29.8861 405.075 29.8861ZM405.075 6.11264C398.844 6.11264 393.775 11.1821 393.775 17.4128C393.775 23.6434 398.844 28.7129 405.075 28.7129C411.306 28.7129 416.375 23.6434 416.375 17.4128C416.375 11.1821 411.306 6.11264 405.075 6.11264Z"
              fill="#5956D9" />
            <path
              d="M436.434 29.8861C429.557 29.8861 423.961 24.2902 423.961 17.4128C423.961 10.5345 429.557 4.93945 436.434 4.93945C443.312 4.93945 448.908 10.5345 448.908 17.4128C448.908 24.2911 443.312 29.8861 436.434 29.8861ZM436.434 6.11264C430.204 6.11264 425.134 11.1821 425.134 17.4128C425.134 23.6434 430.204 28.7129 436.434 28.7129C442.665 28.7129 447.734 23.6434 447.734 17.4128C447.734 11.1821 442.666 6.11264 436.434 6.11264Z"
              fill="#5956D9" />
            <path
              d="M467.794 29.8861C460.916 29.8861 455.32 24.2902 455.32 17.4128C455.32 10.5345 460.916 4.93945 467.794 4.93945C474.671 4.93945 480.267 10.5345 480.267 17.4128C480.267 24.2911 474.671 29.8861 467.794 29.8861ZM467.794 6.11264C461.563 6.11264 456.494 11.1821 456.494 17.4128C456.494 23.6434 461.563 28.7129 467.794 28.7129C474.024 28.7129 479.094 23.6434 479.094 17.4128C479.094 11.1821 474.025 6.11264 467.794 6.11264Z"
              fill="#5956D9" />
            <path
              d="M499.153 29.8861C492.276 29.8861 486.68 24.2902 486.68 17.4128C486.68 10.5345 492.276 4.93945 499.153 4.93945C506.031 4.93945 511.626 10.5345 511.626 17.4128C511.626 24.2911 506.031 29.8861 499.153 29.8861ZM499.153 6.11264C492.922 6.11264 487.853 11.1821 487.853 17.4128C487.853 23.6434 492.922 28.7129 499.153 28.7129C505.384 28.7129 510.453 23.6434 510.453 17.4128C510.453 11.1821 505.385 6.11264 499.153 6.11264Z"
              fill="#5956D9" />
            <path
              d="M530.512 29.8861C523.635 29.8861 518.039 24.2902 518.039 17.4128C518.039 10.5345 523.635 4.93945 530.512 4.93945C537.39 4.93945 542.986 10.5345 542.986 17.4128C542.986 24.2911 537.39 29.8861 530.512 29.8861ZM530.512 6.11264C524.282 6.11264 519.212 11.1821 519.212 17.4128C519.212 23.6434 524.282 28.7129 530.512 28.7129C536.743 28.7129 541.813 23.6434 541.813 17.4128C541.813 11.1821 536.744 6.11264 530.512 6.11264Z"
              fill="#5956D9" />
            <path
              d="M561.872 29.8861C554.994 29.8861 549.398 24.2902 549.398 17.4128C549.398 10.5345 554.994 4.93945 561.872 4.93945C568.749 4.93945 574.345 10.5345 574.345 17.4128C574.345 24.2911 568.749 29.8861 561.872 29.8861ZM561.872 6.11264C555.641 6.11264 550.572 11.1821 550.572 17.4128C550.572 23.6434 555.641 28.7129 561.872 28.7129C568.102 28.7129 573.172 23.6434 573.172 17.4128C573.172 11.1821 568.103 6.11264 561.872 6.11264Z"
              fill="#5956D9" />
            <path
              d="M593.231 29.8861C586.354 29.8861 580.758 24.2902 580.758 17.4128C580.758 10.5345 586.354 4.93945 593.231 4.93945C600.109 4.93945 605.705 10.5345 605.705 17.4128C605.705 24.2911 600.109 29.8861 593.231 29.8861ZM593.231 6.11264C587.001 6.11264 581.931 11.1821 581.931 17.4128C581.931 23.6434 587.001 28.7129 593.231 28.7129C599.462 28.7129 604.531 23.6434 604.531 17.4128C604.531 11.1821 599.463 6.11264 593.231 6.11264Z"
              fill="#5956D9" />
            <path
              d="M624.591 29.8861C617.713 29.8861 612.117 24.2902 612.117 17.4128C612.117 10.5345 617.713 4.93945 624.591 4.93945C631.468 4.93945 637.064 10.5345 637.064 17.4128C637.064 24.2911 631.468 29.8861 624.591 29.8861ZM624.591 6.11264C618.36 6.11264 613.29 11.1821 613.29 17.4128C613.29 23.6434 618.36 28.7129 624.591 28.7129C630.821 28.7129 635.891 23.6434 635.891 17.4128C635.891 11.1821 630.822 6.11264 624.591 6.11264Z"
              fill="#5956D9" />
            <path
              d="M655.95 29.8861C649.072 29.8861 643.477 24.2902 643.477 17.4128C643.477 10.5345 649.072 4.93945 655.95 4.93945C662.827 4.93945 668.423 10.5345 668.423 17.4128C668.423 24.2911 662.827 29.8861 655.95 29.8861ZM655.95 6.11264C649.719 6.11264 644.65 11.1821 644.65 17.4128C644.65 23.6434 649.719 28.7129 655.95 28.7129C662.181 28.7129 667.25 23.6434 667.25 17.4128C667.25 11.1821 662.181 6.11264 655.95 6.11264Z"
              fill="#5956D9" />
            <path
              d="M687.309 29.8861C680.432 29.8861 674.836 24.2902 674.836 17.4128C674.836 10.5345 680.432 4.93945 687.309 4.93945C694.187 4.93945 699.783 10.5345 699.783 17.4128C699.783 24.2911 694.187 29.8861 687.309 29.8861ZM687.309 6.11264C681.079 6.11264 676.009 11.1821 676.009 17.4128C676.009 23.6434 681.079 28.7129 687.309 28.7129C693.54 28.7129 698.609 23.6434 698.609 17.4128C698.609 11.1821 693.541 6.11264 687.309 6.11264Z"
              fill="#5956D9" />
            <path
              d="M718.669 29.8861C711.791 29.8861 706.195 24.2902 706.195 17.4128C706.195 10.5345 711.791 4.93945 718.669 4.93945C725.546 4.93945 731.142 10.5345 731.142 17.4128C731.142 24.2911 725.546 29.8861 718.669 29.8861ZM718.669 6.11264C712.438 6.11264 707.369 11.1821 707.369 17.4128C707.369 23.6434 712.438 28.7129 718.669 28.7129C724.899 28.7129 729.969 23.6434 729.969 17.4128C729.969 11.1821 724.9 6.11264 718.669 6.11264Z"
              fill="#5956D9" />
            <path
              d="M750.028 29.8861C743.151 29.8861 737.555 24.2902 737.555 17.4128C737.555 10.5345 743.151 4.93945 750.028 4.93945C756.906 4.93945 762.501 10.5345 762.501 17.4128C762.501 24.2911 756.906 29.8861 750.028 29.8861ZM750.028 6.11264C743.797 6.11264 738.728 11.1821 738.728 17.4128C738.728 23.6434 743.797 28.7129 750.028 28.7129C756.259 28.7129 761.328 23.6434 761.328 17.4128C761.328 11.1821 756.26 6.11264 750.028 6.11264Z"
              fill="#5956D9" />
            <path
              d="M781.387 29.8861C774.51 29.8861 768.914 24.2902 768.914 17.4128C768.914 10.5345 774.51 4.93945 781.387 4.93945C788.265 4.93945 793.861 10.5345 793.861 17.4128C793.861 24.2911 788.265 29.8861 781.387 29.8861ZM781.387 6.11264C775.157 6.11264 770.087 11.1821 770.087 17.4128C770.087 23.6434 775.157 28.7129 781.387 28.7129C787.618 28.7129 792.688 23.6434 792.688 17.4128C792.688 11.1821 787.619 6.11264 781.387 6.11264Z"
              fill="#5956D9" />
            <path
              d="M812.747 29.8861C805.869 29.8861 800.273 24.2902 800.273 17.4128C800.273 10.5345 805.869 4.93945 812.747 4.93945C819.624 4.93945 825.22 10.5345 825.22 17.4128C825.22 24.2911 819.624 29.8861 812.747 29.8861ZM812.747 6.11264C806.516 6.11264 801.447 11.1821 801.447 17.4128C801.447 23.6434 806.516 28.7129 812.747 28.7129C818.977 28.7129 824.047 23.6434 824.047 17.4128C824.047 11.1821 818.978 6.11264 812.747 6.11264Z"
              fill="#5956D9" />
            <path
              d="M844.106 29.8861C837.229 29.8861 831.633 24.2902 831.633 17.4128C831.633 10.5345 837.229 4.93945 844.106 4.93945C850.984 4.93945 856.58 10.5345 856.58 17.4128C856.58 24.2911 850.984 29.8861 844.106 29.8861ZM844.106 6.11264C837.876 6.11264 832.806 11.1821 832.806 17.4128C832.806 23.6434 837.876 28.7129 844.106 28.7129C850.337 28.7129 855.406 23.6434 855.406 17.4128C855.406 11.1821 850.338 6.11264 844.106 6.11264Z"
              fill="#5956D9" />
            <path
              d="M875.466 29.8861C868.588 29.8861 862.992 24.2902 862.992 17.4128C862.992 10.5345 868.588 4.93945 875.466 4.93945C882.343 4.93945 887.939 10.5345 887.939 17.4128C887.939 24.2911 882.343 29.8861 875.466 29.8861ZM875.466 6.11264C869.235 6.11264 864.165 11.1821 864.165 17.4128C864.165 23.6434 869.235 28.7129 875.466 28.7129C881.696 28.7129 886.766 23.6434 886.766 17.4128C886.766 11.1821 881.697 6.11264 875.466 6.11264Z"
              fill="#5956D9" />
            <path
              d="M906.825 29.8861C899.947 29.8861 894.352 24.2902 894.352 17.4128C894.352 10.5345 899.947 4.93945 906.825 4.93945C913.702 4.93945 919.298 10.5345 919.298 17.4128C919.298 24.2911 913.702 29.8861 906.825 29.8861ZM906.825 6.11264C900.594 6.11264 895.525 11.1821 895.525 17.4128C895.525 23.6434 900.594 28.7129 906.825 28.7129C913.056 28.7129 918.125 23.6434 918.125 17.4128C918.125 11.1821 913.056 6.11264 906.825 6.11264Z"
              fill="#5956D9" />
            <path
              d="M28.7351 64.099C21.8576 64.099 16.2617 58.5031 16.2617 51.6257C16.2617 44.7474 21.8576 39.1523 28.7351 39.1523C35.6125 39.1523 41.2085 44.7474 41.2085 51.6257C41.2085 58.504 35.6125 64.099 28.7351 64.099ZM28.7351 40.3255C22.5044 40.3255 17.4349 45.395 17.4349 51.6257C17.4349 57.8563 22.5044 62.9258 28.7351 62.9258C34.9657 62.9258 40.0352 57.8563 40.0352 51.6257C40.0352 45.395 34.9666 40.3255 28.7351 40.3255Z"
              fill="#5956D9" />
            <path
              d="M60.0984 64.099C53.2209 64.099 47.625 58.5031 47.625 51.6257C47.625 44.7474 53.2209 39.1523 60.0984 39.1523C66.9758 39.1523 72.5717 44.7474 72.5717 51.6257C72.5717 58.504 66.9758 64.099 60.0984 64.099ZM60.0984 40.3255C53.8677 40.3255 48.7982 45.395 48.7982 51.6257C48.7982 57.8563 53.8677 62.9258 60.0984 62.9258C66.329 62.9258 71.3985 57.8563 71.3985 51.6257C71.3985 45.395 66.3299 40.3255 60.0984 40.3255Z"
              fill="#5956D9" />
            <path
              d="M91.4577 64.099C84.5803 64.099 78.9844 58.5031 78.9844 51.6257C78.9844 44.7474 84.5803 39.1523 91.4577 39.1523C98.3352 39.1523 103.931 44.7474 103.931 51.6257C103.931 58.504 98.3352 64.099 91.4577 64.099ZM91.4577 40.3255C85.2271 40.3255 80.1576 45.395 80.1576 51.6257C80.1576 57.8563 85.2271 62.9258 91.4577 62.9258C97.6884 62.9258 102.758 57.8563 102.758 51.6257C102.758 45.395 97.6893 40.3255 91.4577 40.3255Z"
              fill="#5956D9" />
            <path
              d="M122.821 64.099C115.944 64.099 110.348 58.5031 110.348 51.6257C110.348 44.7474 115.944 39.1523 122.821 39.1523C129.698 39.1523 135.294 44.7474 135.294 51.6257C135.294 58.504 129.698 64.099 122.821 64.099ZM122.821 40.3255C116.59 40.3255 111.521 45.395 111.521 51.6257C111.521 57.8563 116.59 62.9258 122.821 62.9258C129.052 62.9258 134.121 57.8563 134.121 51.6257C134.121 45.395 129.053 40.3255 122.821 40.3255Z"
              fill="#5956D9" />
            <path
              d="M154.18 64.099C147.303 64.099 141.707 58.5031 141.707 51.6257C141.707 44.7474 147.303 39.1523 154.18 39.1523C161.058 39.1523 166.654 44.7474 166.654 51.6257C166.654 58.504 161.058 64.099 154.18 64.099ZM154.18 40.3255C147.95 40.3255 142.88 45.395 142.88 51.6257C142.88 57.8563 147.95 62.9258 154.18 62.9258C160.411 62.9258 165.481 57.8563 165.481 51.6257C165.481 45.395 160.412 40.3255 154.18 40.3255Z"
              fill="#5956D9" />
            <path
              d="M185.544 64.099C178.666 64.099 173.07 58.5031 173.07 51.6257C173.07 44.7474 178.666 39.1523 185.544 39.1523C192.421 39.1523 198.017 44.7474 198.017 51.6257C198.017 58.504 192.421 64.099 185.544 64.099ZM185.544 40.3255C179.313 40.3255 174.244 45.395 174.244 51.6257C174.244 57.8563 179.313 62.9258 185.544 62.9258C191.774 62.9258 196.844 57.8563 196.844 51.6257C196.844 45.395 191.775 40.3255 185.544 40.3255Z"
              fill="#5956D9" />
            <path
              d="M216.907 64.099C210.029 64.099 204.434 58.5031 204.434 51.6257C204.434 44.7474 210.029 39.1523 216.907 39.1523C223.784 39.1523 229.38 44.7474 229.38 51.6257C229.38 58.504 223.784 64.099 216.907 64.099ZM216.907 40.3255C210.676 40.3255 205.607 45.395 205.607 51.6257C205.607 57.8563 210.676 62.9258 216.907 62.9258C223.138 62.9258 228.207 57.8563 228.207 51.6257C228.207 45.395 223.138 40.3255 216.907 40.3255Z"
              fill="#5956D9" />
            <path
              d="M248.266 64.099C241.389 64.099 235.793 58.5031 235.793 51.6257C235.793 44.7474 241.389 39.1523 248.266 39.1523C255.144 39.1523 260.74 44.7474 260.74 51.6257C260.74 58.504 255.144 64.099 248.266 64.099ZM248.266 40.3255C242.036 40.3255 236.966 45.395 236.966 51.6257C236.966 57.8563 242.036 62.9258 248.266 62.9258C254.497 62.9258 259.566 57.8563 259.566 51.6257C259.566 45.395 254.498 40.3255 248.266 40.3255Z"
              fill="#5956D9" />
            <path
              d="M279.63 64.099C272.752 64.099 267.156 58.5031 267.156 51.6257C267.156 44.7474 272.752 39.1523 279.63 39.1523C286.507 39.1523 292.103 44.7474 292.103 51.6257C292.103 58.504 286.507 64.099 279.63 64.099ZM279.63 40.3255C273.399 40.3255 268.329 45.395 268.329 51.6257C268.329 57.8563 273.399 62.9258 279.63 62.9258C285.86 62.9258 290.93 57.8563 290.93 51.6257C290.93 45.395 285.861 40.3255 279.63 40.3255Z"
              fill="#5956D9" />
            <path
              d="M310.989 64.099C304.112 64.099 298.516 58.5031 298.516 51.6257C298.516 44.7474 304.112 39.1523 310.989 39.1523C317.866 39.1523 323.462 44.7474 323.462 51.6257C323.462 58.504 317.866 64.099 310.989 64.099ZM310.989 40.3255C304.758 40.3255 299.689 45.395 299.689 51.6257C299.689 57.8563 304.758 62.9258 310.989 62.9258C317.22 62.9258 322.289 57.8563 322.289 51.6257C322.289 45.395 317.221 40.3255 310.989 40.3255Z"
              fill="#5956D9" />
            <path
              d="M342.352 64.099C335.475 64.099 329.879 58.5031 329.879 51.6257C329.879 44.7474 335.475 39.1523 342.352 39.1523C349.23 39.1523 354.826 44.7474 354.826 51.6257C354.826 58.504 349.23 64.099 342.352 64.099ZM342.352 40.3255C336.122 40.3255 331.052 45.395 331.052 51.6257C331.052 57.8563 336.122 62.9258 342.352 62.9258C348.583 62.9258 353.652 57.8563 353.652 51.6257C353.652 45.395 348.584 40.3255 342.352 40.3255Z"
              fill="#5956D9" />
            <path
              d="M373.712 64.099C366.834 64.099 361.238 58.5031 361.238 51.6257C361.238 44.7474 366.834 39.1523 373.712 39.1523C380.589 39.1523 386.185 44.7474 386.185 51.6257C386.185 58.504 380.589 64.099 373.712 64.099ZM373.712 40.3255C367.481 40.3255 362.411 45.395 362.411 51.6257C362.411 57.8563 367.481 62.9258 373.712 62.9258C379.942 62.9258 385.012 57.8563 385.012 51.6257C385.012 45.395 379.943 40.3255 373.712 40.3255Z"
              fill="#5956D9" />
            <path
              d="M405.075 64.099C398.197 64.099 392.602 58.5031 392.602 51.6257C392.602 44.7474 398.197 39.1523 405.075 39.1523C411.952 39.1523 417.548 44.7474 417.548 51.6257C417.548 58.504 411.952 64.099 405.075 64.099ZM405.075 40.3255C398.844 40.3255 393.775 45.395 393.775 51.6257C393.775 57.8563 398.844 62.9258 405.075 62.9258C411.306 62.9258 416.375 57.8563 416.375 51.6257C416.375 45.395 411.306 40.3255 405.075 40.3255Z"
              fill="#5956D9" />
            <path
              d="M436.434 64.099C429.557 64.099 423.961 58.5031 423.961 51.6257C423.961 44.7474 429.557 39.1523 436.434 39.1523C443.312 39.1523 448.908 44.7474 448.908 51.6257C448.908 58.504 443.312 64.099 436.434 64.099ZM436.434 40.3255C430.204 40.3255 425.134 45.395 425.134 51.6257C425.134 57.8563 430.204 62.9258 436.434 62.9258C442.665 62.9258 447.734 57.8563 447.734 51.6257C447.734 45.395 442.666 40.3255 436.434 40.3255Z"
              fill="#5956D9" />
            <path
              d="M467.794 64.099C460.916 64.099 455.32 58.5031 455.32 51.6257C455.32 44.7474 460.916 39.1523 467.794 39.1523C474.671 39.1523 480.267 44.7474 480.267 51.6257C480.267 58.504 474.671 64.099 467.794 64.099ZM467.794 40.3255C461.563 40.3255 456.494 45.395 456.494 51.6257C456.494 57.8563 461.563 62.9258 467.794 62.9258C474.024 62.9258 479.094 57.8563 479.094 51.6257C479.094 45.395 474.025 40.3255 467.794 40.3255Z"
              fill="#5956D9" />
            <path
              d="M499.153 64.099C492.276 64.099 486.68 58.5031 486.68 51.6257C486.68 44.7474 492.276 39.1523 499.153 39.1523C506.031 39.1523 511.626 44.7474 511.626 51.6257C511.626 58.504 506.031 64.099 499.153 64.099ZM499.153 40.3255C492.922 40.3255 487.853 45.395 487.853 51.6257C487.853 57.8563 492.922 62.9258 499.153 62.9258C505.384 62.9258 510.453 57.8563 510.453 51.6257C510.453 45.395 505.385 40.3255 499.153 40.3255Z"
              fill="#5956D9" />
            <path
              d="M530.512 64.099C523.635 64.099 518.039 58.5031 518.039 51.6257C518.039 44.7474 523.635 39.1523 530.512 39.1523C537.39 39.1523 542.986 44.7474 542.986 51.6257C542.986 58.504 537.39 64.099 530.512 64.099ZM530.512 40.3255C524.282 40.3255 519.212 45.395 519.212 51.6257C519.212 57.8563 524.282 62.9258 530.512 62.9258C536.743 62.9258 541.813 57.8563 541.813 51.6257C541.813 45.395 536.744 40.3255 530.512 40.3255Z"
              fill="#5956D9" />
            <path
              d="M561.872 64.099C554.994 64.099 549.398 58.5031 549.398 51.6257C549.398 44.7474 554.994 39.1523 561.872 39.1523C568.749 39.1523 574.345 44.7474 574.345 51.6257C574.345 58.504 568.749 64.099 561.872 64.099ZM561.872 40.3255C555.641 40.3255 550.572 45.395 550.572 51.6257C550.572 57.8563 555.641 62.9258 561.872 62.9258C568.102 62.9258 573.172 57.8563 573.172 51.6257C573.172 45.395 568.103 40.3255 561.872 40.3255Z"
              fill="#5956D9" />
            <path
              d="M593.231 64.099C586.354 64.099 580.758 58.5031 580.758 51.6257C580.758 44.7474 586.354 39.1523 593.231 39.1523C600.109 39.1523 605.705 44.7474 605.705 51.6257C605.705 58.504 600.109 64.099 593.231 64.099ZM593.231 40.3255C587.001 40.3255 581.931 45.395 581.931 51.6257C581.931 57.8563 587.001 62.9258 593.231 62.9258C599.462 62.9258 604.531 57.8563 604.531 51.6257C604.531 45.395 599.463 40.3255 593.231 40.3255Z"
              fill="#5956D9" />
            <path
              d="M624.591 64.099C617.713 64.099 612.117 58.5031 612.117 51.6257C612.117 44.7474 617.713 39.1523 624.591 39.1523C631.468 39.1523 637.064 44.7474 637.064 51.6257C637.064 58.504 631.468 64.099 624.591 64.099ZM624.591 40.3255C618.36 40.3255 613.29 45.395 613.29 51.6257C613.29 57.8563 618.36 62.9258 624.591 62.9258C630.821 62.9258 635.891 57.8563 635.891 51.6257C635.891 45.395 630.822 40.3255 624.591 40.3255Z"
              fill="#5956D9" />
            <path
              d="M655.95 64.099C649.072 64.099 643.477 58.5031 643.477 51.6257C643.477 44.7474 649.072 39.1523 655.95 39.1523C662.827 39.1523 668.423 44.7474 668.423 51.6257C668.423 58.504 662.827 64.099 655.95 64.099ZM655.95 40.3255C649.719 40.3255 644.65 45.395 644.65 51.6257C644.65 57.8563 649.719 62.9258 655.95 62.9258C662.181 62.9258 667.25 57.8563 667.25 51.6257C667.25 45.395 662.181 40.3255 655.95 40.3255Z"
              fill="#5956D9" />
            <path
              d="M687.309 64.099C680.432 64.099 674.836 58.5031 674.836 51.6257C674.836 44.7474 680.432 39.1523 687.309 39.1523C694.187 39.1523 699.783 44.7474 699.783 51.6257C699.783 58.504 694.187 64.099 687.309 64.099ZM687.309 40.3255C681.079 40.3255 676.009 45.395 676.009 51.6257C676.009 57.8563 681.079 62.9258 687.309 62.9258C693.54 62.9258 698.609 57.8563 698.609 51.6257C698.609 45.395 693.541 40.3255 687.309 40.3255Z"
              fill="#5956D9" />
            <path
              d="M718.669 64.099C711.791 64.099 706.195 58.5031 706.195 51.6257C706.195 44.7474 711.791 39.1523 718.669 39.1523C725.546 39.1523 731.142 44.7474 731.142 51.6257C731.142 58.504 725.546 64.099 718.669 64.099ZM718.669 40.3255C712.438 40.3255 707.369 45.395 707.369 51.6257C707.369 57.8563 712.438 62.9258 718.669 62.9258C724.899 62.9258 729.969 57.8563 729.969 51.6257C729.969 45.395 724.9 40.3255 718.669 40.3255Z"
              fill="#5956D9" />
            <path
              d="M750.028 64.099C743.151 64.099 737.555 58.5031 737.555 51.6257C737.555 44.7474 743.151 39.1523 750.028 39.1523C756.906 39.1523 762.501 44.7474 762.501 51.6257C762.501 58.504 756.906 64.099 750.028 64.099ZM750.028 40.3255C743.797 40.3255 738.728 45.395 738.728 51.6257C738.728 57.8563 743.797 62.9258 750.028 62.9258C756.259 62.9258 761.328 57.8563 761.328 51.6257C761.328 45.395 756.26 40.3255 750.028 40.3255Z"
              fill="#5956D9" />
            <path
              d="M781.387 64.099C774.51 64.099 768.914 58.5031 768.914 51.6257C768.914 44.7474 774.51 39.1523 781.387 39.1523C788.265 39.1523 793.861 44.7474 793.861 51.6257C793.861 58.504 788.265 64.099 781.387 64.099ZM781.387 40.3255C775.157 40.3255 770.087 45.395 770.087 51.6257C770.087 57.8563 775.157 62.9258 781.387 62.9258C787.618 62.9258 792.688 57.8563 792.688 51.6257C792.688 45.395 787.619 40.3255 781.387 40.3255Z"
              fill="#5956D9" />
            <path
              d="M812.747 64.099C805.869 64.099 800.273 58.5031 800.273 51.6257C800.273 44.7474 805.869 39.1523 812.747 39.1523C819.624 39.1523 825.22 44.7474 825.22 51.6257C825.22 58.504 819.624 64.099 812.747 64.099ZM812.747 40.3255C806.516 40.3255 801.447 45.395 801.447 51.6257C801.447 57.8563 806.516 62.9258 812.747 62.9258C818.977 62.9258 824.047 57.8563 824.047 51.6257C824.047 45.395 818.978 40.3255 812.747 40.3255Z"
              fill="#5956D9" />
            <path
              d="M844.106 64.099C837.229 64.099 831.633 58.5031 831.633 51.6257C831.633 44.7474 837.229 39.1523 844.106 39.1523C850.984 39.1523 856.58 44.7474 856.58 51.6257C856.58 58.504 850.984 64.099 844.106 64.099ZM844.106 40.3255C837.876 40.3255 832.806 45.395 832.806 51.6257C832.806 57.8563 837.876 62.9258 844.106 62.9258C850.337 62.9258 855.406 57.8563 855.406 51.6257C855.406 45.395 850.338 40.3255 844.106 40.3255Z"
              fill="#5956D9" />
            <path
              d="M875.466 64.099C868.588 64.099 862.992 58.5031 862.992 51.6257C862.992 44.7474 868.588 39.1523 875.466 39.1523C882.343 39.1523 887.939 44.7474 887.939 51.6257C887.939 58.504 882.343 64.099 875.466 64.099ZM875.466 40.3255C869.235 40.3255 864.165 45.395 864.165 51.6257C864.165 57.8563 869.235 62.9258 875.466 62.9258C881.696 62.9258 886.766 57.8563 886.766 51.6257C886.766 45.395 881.697 40.3255 875.466 40.3255Z"
              fill="#5956D9" />
            <path
              d="M906.825 64.099C899.947 64.099 894.352 58.5031 894.352 51.6257C894.352 44.7474 899.947 39.1523 906.825 39.1523C913.702 39.1523 919.298 44.7474 919.298 51.6257C919.298 58.504 913.702 64.099 906.825 64.099ZM906.825 40.3255C900.594 40.3255 895.525 45.395 895.525 51.6257C895.525 57.8563 900.594 62.9258 906.825 62.9258C913.056 62.9258 918.125 57.8563 918.125 51.6257C918.125 45.395 913.056 40.3255 906.825 40.3255Z"
              fill="#5956D9" />
            <path
              d="M28.7351 98.3119C21.8576 98.3119 16.2617 92.716 16.2617 85.8386C16.2617 78.9603 21.8576 73.3652 28.7351 73.3652C35.6125 73.3652 41.2085 78.9603 41.2085 85.8386C41.2085 92.7169 35.6125 98.3119 28.7351 98.3119ZM28.7351 74.5384C22.5044 74.5384 17.4349 79.6079 17.4349 85.8386C17.4349 92.0692 22.5044 97.1387 28.7351 97.1387C34.9657 97.1387 40.0352 92.0692 40.0352 85.8386C40.0352 79.6079 34.9666 74.5384 28.7351 74.5384Z"
              fill="#5956D9" />
            <path
              d="M60.0984 98.3119C53.2209 98.3119 47.625 92.716 47.625 85.8386C47.625 78.9603 53.2209 73.3652 60.0984 73.3652C66.9758 73.3652 72.5717 78.9603 72.5717 85.8386C72.5717 92.7169 66.9758 98.3119 60.0984 98.3119ZM60.0984 74.5384C53.8677 74.5384 48.7982 79.6079 48.7982 85.8386C48.7982 92.0692 53.8677 97.1387 60.0984 97.1387C66.329 97.1387 71.3985 92.0692 71.3985 85.8386C71.3985 79.6079 66.3299 74.5384 60.0984 74.5384Z"
              fill="#5956D9" />
            <path
              d="M91.4577 98.3119C84.5803 98.3119 78.9844 92.716 78.9844 85.8386C78.9844 78.9603 84.5803 73.3652 91.4577 73.3652C98.3352 73.3652 103.931 78.9603 103.931 85.8386C103.931 92.7169 98.3352 98.3119 91.4577 98.3119ZM91.4577 74.5384C85.2271 74.5384 80.1576 79.6079 80.1576 85.8386C80.1576 92.0692 85.2271 97.1387 91.4577 97.1387C97.6884 97.1387 102.758 92.0692 102.758 85.8386C102.758 79.6079 97.6893 74.5384 91.4577 74.5384Z"
              fill="#5956D9" />
            <path
              d="M122.821 98.3119C115.944 98.3119 110.348 92.716 110.348 85.8386C110.348 78.9603 115.944 73.3652 122.821 73.3652C129.698 73.3652 135.294 78.9603 135.294 85.8386C135.294 92.7169 129.698 98.3119 122.821 98.3119ZM122.821 74.5384C116.59 74.5384 111.521 79.6079 111.521 85.8386C111.521 92.0692 116.59 97.1387 122.821 97.1387C129.052 97.1387 134.121 92.0692 134.121 85.8386C134.121 79.6079 129.053 74.5384 122.821 74.5384Z"
              fill="#5956D9" />
            <path
              d="M154.18 98.3119C147.303 98.3119 141.707 92.716 141.707 85.8386C141.707 78.9603 147.303 73.3652 154.18 73.3652C161.058 73.3652 166.654 78.9603 166.654 85.8386C166.654 92.7169 161.058 98.3119 154.18 98.3119ZM154.18 74.5384C147.95 74.5384 142.88 79.6079 142.88 85.8386C142.88 92.0692 147.95 97.1387 154.18 97.1387C160.411 97.1387 165.481 92.0692 165.481 85.8386C165.481 79.6079 160.412 74.5384 154.18 74.5384Z"
              fill="#5956D9" />
            <path
              d="M185.544 98.3119C178.666 98.3119 173.07 92.716 173.07 85.8386C173.07 78.9603 178.666 73.3652 185.544 73.3652C192.421 73.3652 198.017 78.9603 198.017 85.8386C198.017 92.7169 192.421 98.3119 185.544 98.3119ZM185.544 74.5384C179.313 74.5384 174.244 79.6079 174.244 85.8386C174.244 92.0692 179.313 97.1387 185.544 97.1387C191.774 97.1387 196.844 92.0692 196.844 85.8386C196.844 79.6079 191.775 74.5384 185.544 74.5384Z"
              fill="#5956D9" />
            <path
              d="M216.907 98.3119C210.029 98.3119 204.434 92.716 204.434 85.8386C204.434 78.9603 210.029 73.3652 216.907 73.3652C223.784 73.3652 229.38 78.9603 229.38 85.8386C229.38 92.7169 223.784 98.3119 216.907 98.3119ZM216.907 74.5384C210.676 74.5384 205.607 79.6079 205.607 85.8386C205.607 92.0692 210.676 97.1387 216.907 97.1387C223.138 97.1387 228.207 92.0692 228.207 85.8386C228.207 79.6079 223.138 74.5384 216.907 74.5384Z"
              fill="#5956D9" />
            <path
              d="M248.266 98.3119C241.389 98.3119 235.793 92.716 235.793 85.8386C235.793 78.9603 241.389 73.3652 248.266 73.3652C255.144 73.3652 260.74 78.9603 260.74 85.8386C260.74 92.7169 255.144 98.3119 248.266 98.3119ZM248.266 74.5384C242.036 74.5384 236.966 79.6079 236.966 85.8386C236.966 92.0692 242.036 97.1387 248.266 97.1387C254.497 97.1387 259.566 92.0692 259.566 85.8386C259.566 79.6079 254.498 74.5384 248.266 74.5384Z"
              fill="#5956D9" />
            <path
              d="M279.63 98.3119C272.752 98.3119 267.156 92.716 267.156 85.8386C267.156 78.9603 272.752 73.3652 279.63 73.3652C286.507 73.3652 292.103 78.9603 292.103 85.8386C292.103 92.7169 286.507 98.3119 279.63 98.3119ZM279.63 74.5384C273.399 74.5384 268.329 79.6079 268.329 85.8386C268.329 92.0692 273.399 97.1387 279.63 97.1387C285.86 97.1387 290.93 92.0692 290.93 85.8386C290.93 79.6079 285.861 74.5384 279.63 74.5384Z"
              fill="#5956D9" />
            <path
              d="M310.989 98.3119C304.112 98.3119 298.516 92.716 298.516 85.8386C298.516 78.9603 304.112 73.3652 310.989 73.3652C317.866 73.3652 323.462 78.9603 323.462 85.8386C323.462 92.7169 317.866 98.3119 310.989 98.3119ZM310.989 74.5384C304.758 74.5384 299.689 79.6079 299.689 85.8386C299.689 92.0692 304.758 97.1387 310.989 97.1387C317.22 97.1387 322.289 92.0692 322.289 85.8386C322.289 79.6079 317.221 74.5384 310.989 74.5384Z"
              fill="#5956D9" />
            <path
              d="M342.352 98.3119C335.475 98.3119 329.879 92.716 329.879 85.8386C329.879 78.9603 335.475 73.3652 342.352 73.3652C349.23 73.3652 354.826 78.9603 354.826 85.8386C354.826 92.7169 349.23 98.3119 342.352 98.3119ZM342.352 74.5384C336.122 74.5384 331.052 79.6079 331.052 85.8386C331.052 92.0692 336.122 97.1387 342.352 97.1387C348.583 97.1387 353.652 92.0692 353.652 85.8386C353.652 79.6079 348.584 74.5384 342.352 74.5384Z"
              fill="#5956D9" />
            <path
              d="M373.712 98.3119C366.834 98.3119 361.238 92.716 361.238 85.8386C361.238 78.9603 366.834 73.3652 373.712 73.3652C380.589 73.3652 386.185 78.9603 386.185 85.8386C386.185 92.7169 380.589 98.3119 373.712 98.3119ZM373.712 74.5384C367.481 74.5384 362.411 79.6079 362.411 85.8386C362.411 92.0692 367.481 97.1387 373.712 97.1387C379.942 97.1387 385.012 92.0692 385.012 85.8386C385.012 79.6079 379.943 74.5384 373.712 74.5384Z"
              fill="#5956D9" />
            <path
              d="M405.075 98.3119C398.197 98.3119 392.602 92.716 392.602 85.8386C392.602 78.9603 398.197 73.3652 405.075 73.3652C411.952 73.3652 417.548 78.9603 417.548 85.8386C417.548 92.7169 411.952 98.3119 405.075 98.3119ZM405.075 74.5384C398.844 74.5384 393.775 79.6079 393.775 85.8386C393.775 92.0692 398.844 97.1387 405.075 97.1387C411.306 97.1387 416.375 92.0692 416.375 85.8386C416.375 79.6079 411.306 74.5384 405.075 74.5384Z"
              fill="#5956D9" />
            <path
              d="M436.434 98.3119C429.557 98.3119 423.961 92.716 423.961 85.8386C423.961 78.9603 429.557 73.3652 436.434 73.3652C443.312 73.3652 448.908 78.9603 448.908 85.8386C448.908 92.7169 443.312 98.3119 436.434 98.3119ZM436.434 74.5384C430.204 74.5384 425.134 79.6079 425.134 85.8386C425.134 92.0692 430.204 97.1387 436.434 97.1387C442.665 97.1387 447.734 92.0692 447.734 85.8386C447.734 79.6079 442.666 74.5384 436.434 74.5384Z"
              fill="#5956D9" />
            <path
              d="M467.794 98.3119C460.916 98.3119 455.32 92.716 455.32 85.8386C455.32 78.9603 460.916 73.3652 467.794 73.3652C474.671 73.3652 480.267 78.9603 480.267 85.8386C480.267 92.7169 474.671 98.3119 467.794 98.3119ZM467.794 74.5384C461.563 74.5384 456.494 79.6079 456.494 85.8386C456.494 92.0692 461.563 97.1387 467.794 97.1387C474.024 97.1387 479.094 92.0692 479.094 85.8386C479.094 79.6079 474.025 74.5384 467.794 74.5384Z"
              fill="#5956D9" />
            <path
              d="M499.153 98.3119C492.276 98.3119 486.68 92.716 486.68 85.8386C486.68 78.9603 492.276 73.3652 499.153 73.3652C506.031 73.3652 511.626 78.9603 511.626 85.8386C511.626 92.7169 506.031 98.3119 499.153 98.3119ZM499.153 74.5384C492.922 74.5384 487.853 79.6079 487.853 85.8386C487.853 92.0692 492.922 97.1387 499.153 97.1387C505.384 97.1387 510.453 92.0692 510.453 85.8386C510.453 79.6079 505.385 74.5384 499.153 74.5384Z"
              fill="#5956D9" />
            <path
              d="M530.512 98.3119C523.635 98.3119 518.039 92.716 518.039 85.8386C518.039 78.9603 523.635 73.3652 530.512 73.3652C537.39 73.3652 542.986 78.9603 542.986 85.8386C542.986 92.7169 537.39 98.3119 530.512 98.3119ZM530.512 74.5384C524.282 74.5384 519.212 79.6079 519.212 85.8386C519.212 92.0692 524.282 97.1387 530.512 97.1387C536.743 97.1387 541.813 92.0692 541.813 85.8386C541.813 79.6079 536.744 74.5384 530.512 74.5384Z"
              fill="#5956D9" />
            <path
              d="M561.872 98.3119C554.994 98.3119 549.398 92.716 549.398 85.8386C549.398 78.9603 554.994 73.3652 561.872 73.3652C568.749 73.3652 574.345 78.9603 574.345 85.8386C574.345 92.7169 568.749 98.3119 561.872 98.3119ZM561.872 74.5384C555.641 74.5384 550.572 79.6079 550.572 85.8386C550.572 92.0692 555.641 97.1387 561.872 97.1387C568.102 97.1387 573.172 92.0692 573.172 85.8386C573.172 79.6079 568.103 74.5384 561.872 74.5384Z"
              fill="#5956D9" />
            <path
              d="M593.231 98.3119C586.354 98.3119 580.758 92.716 580.758 85.8386C580.758 78.9603 586.354 73.3652 593.231 73.3652C600.109 73.3652 605.705 78.9603 605.705 85.8386C605.705 92.7169 600.109 98.3119 593.231 98.3119ZM593.231 74.5384C587.001 74.5384 581.931 79.6079 581.931 85.8386C581.931 92.0692 587.001 97.1387 593.231 97.1387C599.462 97.1387 604.531 92.0692 604.531 85.8386C604.531 79.6079 599.463 74.5384 593.231 74.5384Z"
              fill="#5956D9" />
            <path
              d="M624.591 98.3119C617.713 98.3119 612.117 92.716 612.117 85.8386C612.117 78.9603 617.713 73.3652 624.591 73.3652C631.468 73.3652 637.064 78.9603 637.064 85.8386C637.064 92.7169 631.468 98.3119 624.591 98.3119ZM624.591 74.5384C618.36 74.5384 613.29 79.6079 613.29 85.8386C613.29 92.0692 618.36 97.1387 624.591 97.1387C630.821 97.1387 635.891 92.0692 635.891 85.8386C635.891 79.6079 630.822 74.5384 624.591 74.5384Z"
              fill="#5956D9" />
            <path
              d="M655.95 98.3119C649.072 98.3119 643.477 92.716 643.477 85.8386C643.477 78.9603 649.072 73.3652 655.95 73.3652C662.827 73.3652 668.423 78.9603 668.423 85.8386C668.423 92.7169 662.827 98.3119 655.95 98.3119ZM655.95 74.5384C649.719 74.5384 644.65 79.6079 644.65 85.8386C644.65 92.0692 649.719 97.1387 655.95 97.1387C662.181 97.1387 667.25 92.0692 667.25 85.8386C667.25 79.6079 662.181 74.5384 655.95 74.5384Z"
              fill="#5956D9" />
            <path
              d="M687.309 98.3119C680.432 98.3119 674.836 92.716 674.836 85.8386C674.836 78.9603 680.432 73.3652 687.309 73.3652C694.187 73.3652 699.783 78.9603 699.783 85.8386C699.783 92.7169 694.187 98.3119 687.309 98.3119ZM687.309 74.5384C681.079 74.5384 676.009 79.6079 676.009 85.8386C676.009 92.0692 681.079 97.1387 687.309 97.1387C693.54 97.1387 698.609 92.0692 698.609 85.8386C698.609 79.6079 693.541 74.5384 687.309 74.5384Z"
              fill="#5956D9" />
            <path
              d="M718.669 98.3119C711.791 98.3119 706.195 92.716 706.195 85.8386C706.195 78.9603 711.791 73.3652 718.669 73.3652C725.546 73.3652 731.142 78.9603 731.142 85.8386C731.142 92.7169 725.546 98.3119 718.669 98.3119ZM718.669 74.5384C712.438 74.5384 707.369 79.6079 707.369 85.8386C707.369 92.0692 712.438 97.1387 718.669 97.1387C724.899 97.1387 729.969 92.0692 729.969 85.8386C729.969 79.6079 724.9 74.5384 718.669 74.5384Z"
              fill="#5956D9" />
            <path
              d="M750.028 98.3119C743.151 98.3119 737.555 92.716 737.555 85.8386C737.555 78.9603 743.151 73.3652 750.028 73.3652C756.906 73.3652 762.501 78.9603 762.501 85.8386C762.501 92.7169 756.906 98.3119 750.028 98.3119ZM750.028 74.5384C743.797 74.5384 738.728 79.6079 738.728 85.8386C738.728 92.0692 743.797 97.1387 750.028 97.1387C756.259 97.1387 761.328 92.0692 761.328 85.8386C761.328 79.6079 756.26 74.5384 750.028 74.5384Z"
              fill="#5956D9" />
            <path
              d="M781.387 98.3119C774.51 98.3119 768.914 92.716 768.914 85.8386C768.914 78.9603 774.51 73.3652 781.387 73.3652C788.265 73.3652 793.861 78.9603 793.861 85.8386C793.861 92.7169 788.265 98.3119 781.387 98.3119ZM781.387 74.5384C775.157 74.5384 770.087 79.6079 770.087 85.8386C770.087 92.0692 775.157 97.1387 781.387 97.1387C787.618 97.1387 792.688 92.0692 792.688 85.8386C792.688 79.6079 787.619 74.5384 781.387 74.5384Z"
              fill="#5956D9" />
            <path
              d="M812.747 98.3119C805.869 98.3119 800.273 92.716 800.273 85.8386C800.273 78.9603 805.869 73.3652 812.747 73.3652C819.624 73.3652 825.22 78.9603 825.22 85.8386C825.22 92.7169 819.624 98.3119 812.747 98.3119ZM812.747 74.5384C806.516 74.5384 801.447 79.6079 801.447 85.8386C801.447 92.0692 806.516 97.1387 812.747 97.1387C818.977 97.1387 824.047 92.0692 824.047 85.8386C824.047 79.6079 818.978 74.5384 812.747 74.5384Z"
              fill="#5956D9" />
            <path
              d="M844.106 98.3119C837.229 98.3119 831.633 92.716 831.633 85.8386C831.633 78.9603 837.229 73.3652 844.106 73.3652C850.984 73.3652 856.58 78.9603 856.58 85.8386C856.58 92.7169 850.984 98.3119 844.106 98.3119ZM844.106 74.5384C837.876 74.5384 832.806 79.6079 832.806 85.8386C832.806 92.0692 837.876 97.1387 844.106 97.1387C850.337 97.1387 855.406 92.0692 855.406 85.8386C855.406 79.6079 850.338 74.5384 844.106 74.5384Z"
              fill="#5956D9" />
            <path
              d="M875.466 98.3119C868.588 98.3119 862.992 92.716 862.992 85.8386C862.992 78.9603 868.588 73.3652 875.466 73.3652C882.343 73.3652 887.939 78.9603 887.939 85.8386C887.939 92.7169 882.343 98.3119 875.466 98.3119ZM875.466 74.5384C869.235 74.5384 864.165 79.6079 864.165 85.8386C864.165 92.0692 869.235 97.1387 875.466 97.1387C881.696 97.1387 886.766 92.0692 886.766 85.8386C886.766 79.6079 881.697 74.5384 875.466 74.5384Z"
              fill="#5956D9" />
            <path
              d="M906.825 98.3119C899.947 98.3119 894.352 92.716 894.352 85.8386C894.352 78.9603 899.947 73.3652 906.825 73.3652C913.702 73.3652 919.298 78.9603 919.298 85.8386C919.298 92.7169 913.702 98.3119 906.825 98.3119ZM906.825 74.5384C900.594 74.5384 895.525 79.6079 895.525 85.8386C895.525 92.0692 900.594 97.1387 906.825 97.1387C913.056 97.1387 918.125 92.0692 918.125 85.8386C918.125 79.6079 913.056 74.5384 906.825 74.5384Z"
              fill="#5956D9" />
            <path
              d="M28.7351 132.523C21.8576 132.523 16.2617 126.927 16.2617 120.05C16.2617 113.171 21.8576 107.576 28.7351 107.576C35.6125 107.576 41.2085 113.171 41.2085 120.05C41.2085 126.928 35.6125 132.523 28.7351 132.523ZM28.7351 108.749C22.5044 108.749 17.4349 113.819 17.4349 120.05C17.4349 126.28 22.5044 131.35 28.7351 131.35C34.9657 131.35 40.0352 126.28 40.0352 120.05C40.0352 113.819 34.9666 108.749 28.7351 108.749Z"
              fill="#5956D9" />
            <path
              d="M60.0984 132.523C53.2209 132.523 47.625 126.927 47.625 120.05C47.625 113.171 53.2209 107.576 60.0984 107.576C66.9758 107.576 72.5717 113.171 72.5717 120.05C72.5717 126.928 66.9758 132.523 60.0984 132.523ZM60.0984 108.749C53.8677 108.749 48.7982 113.819 48.7982 120.05C48.7982 126.28 53.8677 131.35 60.0984 131.35C66.329 131.35 71.3985 126.28 71.3985 120.05C71.3985 113.819 66.3299 108.749 60.0984 108.749Z"
              fill="#5956D9" />
            <path
              d="M91.4577 132.523C84.5803 132.523 78.9844 126.927 78.9844 120.05C78.9844 113.171 84.5803 107.576 91.4577 107.576C98.3352 107.576 103.931 113.171 103.931 120.05C103.931 126.928 98.3352 132.523 91.4577 132.523ZM91.4577 108.749C85.2271 108.749 80.1576 113.819 80.1576 120.05C80.1576 126.28 85.2271 131.35 91.4577 131.35C97.6884 131.35 102.758 126.28 102.758 120.05C102.758 113.819 97.6893 108.749 91.4577 108.749Z"
              fill="#5956D9" />
            <path
              d="M122.821 132.523C115.944 132.523 110.348 126.927 110.348 120.05C110.348 113.171 115.944 107.576 122.821 107.576C129.698 107.576 135.294 113.171 135.294 120.05C135.294 126.928 129.698 132.523 122.821 132.523ZM122.821 108.749C116.59 108.749 111.521 113.819 111.521 120.05C111.521 126.28 116.59 131.35 122.821 131.35C129.052 131.35 134.121 126.28 134.121 120.05C134.121 113.819 129.053 108.749 122.821 108.749Z"
              fill="#5956D9" />
            <path
              d="M154.18 132.523C147.303 132.523 141.707 126.927 141.707 120.05C141.707 113.171 147.303 107.576 154.18 107.576C161.058 107.576 166.654 113.171 166.654 120.05C166.654 126.928 161.058 132.523 154.18 132.523ZM154.18 108.749C147.95 108.749 142.88 113.819 142.88 120.05C142.88 126.28 147.95 131.35 154.18 131.35C160.411 131.35 165.481 126.28 165.481 120.05C165.481 113.819 160.412 108.749 154.18 108.749Z"
              fill="#5956D9" />
            <path
              d="M185.544 132.523C178.666 132.523 173.07 126.927 173.07 120.05C173.07 113.171 178.666 107.576 185.544 107.576C192.421 107.576 198.017 113.171 198.017 120.05C198.017 126.928 192.421 132.523 185.544 132.523ZM185.544 108.749C179.313 108.749 174.244 113.819 174.244 120.05C174.244 126.28 179.313 131.35 185.544 131.35C191.774 131.35 196.844 126.28 196.844 120.05C196.844 113.819 191.775 108.749 185.544 108.749Z"
              fill="#5956D9" />
            <path
              d="M216.907 132.523C210.029 132.523 204.434 126.927 204.434 120.05C204.434 113.171 210.029 107.576 216.907 107.576C223.784 107.576 229.38 113.171 229.38 120.05C229.38 126.928 223.784 132.523 216.907 132.523ZM216.907 108.749C210.676 108.749 205.607 113.819 205.607 120.05C205.607 126.28 210.676 131.35 216.907 131.35C223.138 131.35 228.207 126.28 228.207 120.05C228.207 113.819 223.138 108.749 216.907 108.749Z"
              fill="#5956D9" />
            <path
              d="M248.266 132.523C241.389 132.523 235.793 126.927 235.793 120.05C235.793 113.171 241.389 107.576 248.266 107.576C255.144 107.576 260.74 113.171 260.74 120.05C260.74 126.928 255.144 132.523 248.266 132.523ZM248.266 108.749C242.036 108.749 236.966 113.819 236.966 120.05C236.966 126.28 242.036 131.35 248.266 131.35C254.497 131.35 259.566 126.28 259.566 120.05C259.566 113.819 254.498 108.749 248.266 108.749Z"
              fill="#5956D9" />
            <path
              d="M279.63 132.523C272.752 132.523 267.156 126.927 267.156 120.05C267.156 113.171 272.752 107.576 279.63 107.576C286.507 107.576 292.103 113.171 292.103 120.05C292.103 126.928 286.507 132.523 279.63 132.523ZM279.63 108.749C273.399 108.749 268.329 113.819 268.329 120.05C268.329 126.28 273.399 131.35 279.63 131.35C285.86 131.35 290.93 126.28 290.93 120.05C290.93 113.819 285.861 108.749 279.63 108.749Z"
              fill="#5956D9" />
            <path
              d="M310.989 132.523C304.112 132.523 298.516 126.927 298.516 120.05C298.516 113.171 304.112 107.576 310.989 107.576C317.866 107.576 323.462 113.171 323.462 120.05C323.462 126.928 317.866 132.523 310.989 132.523ZM310.989 108.749C304.758 108.749 299.689 113.819 299.689 120.05C299.689 126.28 304.758 131.35 310.989 131.35C317.22 131.35 322.289 126.28 322.289 120.05C322.289 113.819 317.221 108.749 310.989 108.749Z"
              fill="#5956D9" />
            <path
              d="M342.352 132.523C335.475 132.523 329.879 126.927 329.879 120.05C329.879 113.171 335.475 107.576 342.352 107.576C349.23 107.576 354.826 113.171 354.826 120.05C354.826 126.928 349.23 132.523 342.352 132.523ZM342.352 108.749C336.122 108.749 331.052 113.819 331.052 120.05C331.052 126.28 336.122 131.35 342.352 131.35C348.583 131.35 353.652 126.28 353.652 120.05C353.652 113.819 348.584 108.749 342.352 108.749Z"
              fill="#5956D9" />
            <path
              d="M373.712 132.523C366.834 132.523 361.238 126.927 361.238 120.05C361.238 113.171 366.834 107.576 373.712 107.576C380.589 107.576 386.185 113.171 386.185 120.05C386.185 126.928 380.589 132.523 373.712 132.523ZM373.712 108.749C367.481 108.749 362.411 113.819 362.411 120.05C362.411 126.28 367.481 131.35 373.712 131.35C379.942 131.35 385.012 126.28 385.012 120.05C385.012 113.819 379.943 108.749 373.712 108.749Z"
              fill="#5956D9" />
            <path
              d="M405.075 132.523C398.197 132.523 392.602 126.927 392.602 120.05C392.602 113.171 398.197 107.576 405.075 107.576C411.952 107.576 417.548 113.171 417.548 120.05C417.548 126.928 411.952 132.523 405.075 132.523ZM405.075 108.749C398.844 108.749 393.775 113.819 393.775 120.05C393.775 126.28 398.844 131.35 405.075 131.35C411.306 131.35 416.375 126.28 416.375 120.05C416.375 113.819 411.306 108.749 405.075 108.749Z"
              fill="#5956D9" />
            <path
              d="M436.434 132.523C429.557 132.523 423.961 126.927 423.961 120.05C423.961 113.171 429.557 107.576 436.434 107.576C443.312 107.576 448.908 113.171 448.908 120.05C448.908 126.928 443.312 132.523 436.434 132.523ZM436.434 108.749C430.204 108.749 425.134 113.819 425.134 120.05C425.134 126.28 430.204 131.35 436.434 131.35C442.665 131.35 447.734 126.28 447.734 120.05C447.734 113.819 442.666 108.749 436.434 108.749Z"
              fill="#5956D9" />
            <path
              d="M467.794 132.523C460.916 132.523 455.32 126.927 455.32 120.05C455.32 113.171 460.916 107.576 467.794 107.576C474.671 107.576 480.267 113.171 480.267 120.05C480.267 126.928 474.671 132.523 467.794 132.523ZM467.794 108.749C461.563 108.749 456.494 113.819 456.494 120.05C456.494 126.28 461.563 131.35 467.794 131.35C474.024 131.35 479.094 126.28 479.094 120.05C479.094 113.819 474.025 108.749 467.794 108.749Z"
              fill="#5956D9" />
            <path
              d="M499.153 132.523C492.276 132.523 486.68 126.927 486.68 120.05C486.68 113.171 492.276 107.576 499.153 107.576C506.031 107.576 511.626 113.171 511.626 120.05C511.626 126.928 506.031 132.523 499.153 132.523ZM499.153 108.749C492.922 108.749 487.853 113.819 487.853 120.05C487.853 126.28 492.922 131.35 499.153 131.35C505.384 131.35 510.453 126.28 510.453 120.05C510.453 113.819 505.385 108.749 499.153 108.749Z"
              fill="#5956D9" />
            <path
              d="M530.512 132.523C523.635 132.523 518.039 126.927 518.039 120.05C518.039 113.171 523.635 107.576 530.512 107.576C537.39 107.576 542.986 113.171 542.986 120.05C542.986 126.928 537.39 132.523 530.512 132.523ZM530.512 108.749C524.282 108.749 519.212 113.819 519.212 120.05C519.212 126.28 524.282 131.35 530.512 131.35C536.743 131.35 541.813 126.28 541.813 120.05C541.813 113.819 536.744 108.749 530.512 108.749Z"
              fill="#5956D9" />
            <path
              d="M561.872 132.523C554.994 132.523 549.398 126.927 549.398 120.05C549.398 113.171 554.994 107.576 561.872 107.576C568.749 107.576 574.345 113.171 574.345 120.05C574.345 126.928 568.749 132.523 561.872 132.523ZM561.872 108.749C555.641 108.749 550.572 113.819 550.572 120.05C550.572 126.28 555.641 131.35 561.872 131.35C568.102 131.35 573.172 126.28 573.172 120.05C573.172 113.819 568.103 108.749 561.872 108.749Z"
              fill="#5956D9" />
            <path
              d="M593.231 132.523C586.354 132.523 580.758 126.927 580.758 120.05C580.758 113.171 586.354 107.576 593.231 107.576C600.109 107.576 605.705 113.171 605.705 120.05C605.705 126.928 600.109 132.523 593.231 132.523ZM593.231 108.749C587.001 108.749 581.931 113.819 581.931 120.05C581.931 126.28 587.001 131.35 593.231 131.35C599.462 131.35 604.531 126.28 604.531 120.05C604.531 113.819 599.463 108.749 593.231 108.749Z"
              fill="#5956D9" />
            <path
              d="M624.591 132.523C617.713 132.523 612.117 126.927 612.117 120.05C612.117 113.171 617.713 107.576 624.591 107.576C631.468 107.576 637.064 113.171 637.064 120.05C637.064 126.928 631.468 132.523 624.591 132.523ZM624.591 108.749C618.36 108.749 613.29 113.819 613.29 120.05C613.29 126.28 618.36 131.35 624.591 131.35C630.821 131.35 635.891 126.28 635.891 120.05C635.891 113.819 630.822 108.749 624.591 108.749Z"
              fill="#5956D9" />
            <path
              d="M655.95 132.523C649.072 132.523 643.477 126.927 643.477 120.05C643.477 113.171 649.072 107.576 655.95 107.576C662.827 107.576 668.423 113.171 668.423 120.05C668.423 126.928 662.827 132.523 655.95 132.523ZM655.95 108.749C649.719 108.749 644.65 113.819 644.65 120.05C644.65 126.28 649.719 131.35 655.95 131.35C662.181 131.35 667.25 126.28 667.25 120.05C667.25 113.819 662.181 108.749 655.95 108.749Z"
              fill="#5956D9" />
            <path
              d="M687.309 132.523C680.432 132.523 674.836 126.927 674.836 120.05C674.836 113.171 680.432 107.576 687.309 107.576C694.187 107.576 699.783 113.171 699.783 120.05C699.783 126.928 694.187 132.523 687.309 132.523ZM687.309 108.749C681.079 108.749 676.009 113.819 676.009 120.05C676.009 126.28 681.079 131.35 687.309 131.35C693.54 131.35 698.609 126.28 698.609 120.05C698.609 113.819 693.541 108.749 687.309 108.749Z"
              fill="#5956D9" />
            <path
              d="M718.669 132.523C711.791 132.523 706.195 126.927 706.195 120.05C706.195 113.171 711.791 107.576 718.669 107.576C725.546 107.576 731.142 113.171 731.142 120.05C731.142 126.928 725.546 132.523 718.669 132.523ZM718.669 108.749C712.438 108.749 707.369 113.819 707.369 120.05C707.369 126.28 712.438 131.35 718.669 131.35C724.899 131.35 729.969 126.28 729.969 120.05C729.969 113.819 724.9 108.749 718.669 108.749Z"
              fill="#5956D9" />
            <path
              d="M750.028 132.523C743.151 132.523 737.555 126.927 737.555 120.05C737.555 113.171 743.151 107.576 750.028 107.576C756.906 107.576 762.501 113.171 762.501 120.05C762.501 126.928 756.906 132.523 750.028 132.523ZM750.028 108.749C743.797 108.749 738.728 113.819 738.728 120.05C738.728 126.28 743.797 131.35 750.028 131.35C756.259 131.35 761.328 126.28 761.328 120.05C761.328 113.819 756.26 108.749 750.028 108.749Z"
              fill="#5956D9" />
            <path
              d="M781.387 132.523C774.51 132.523 768.914 126.927 768.914 120.05C768.914 113.171 774.51 107.576 781.387 107.576C788.265 107.576 793.861 113.171 793.861 120.05C793.861 126.928 788.265 132.523 781.387 132.523ZM781.387 108.749C775.157 108.749 770.087 113.819 770.087 120.05C770.087 126.28 775.157 131.35 781.387 131.35C787.618 131.35 792.688 126.28 792.688 120.05C792.688 113.819 787.619 108.749 781.387 108.749Z"
              fill="#5956D9" />
            <path
              d="M812.747 132.523C805.869 132.523 800.273 126.927 800.273 120.05C800.273 113.171 805.869 107.576 812.747 107.576C819.624 107.576 825.22 113.171 825.22 120.05C825.22 126.928 819.624 132.523 812.747 132.523ZM812.747 108.749C806.516 108.749 801.447 113.819 801.447 120.05C801.447 126.28 806.516 131.35 812.747 131.35C818.977 131.35 824.047 126.28 824.047 120.05C824.047 113.819 818.978 108.749 812.747 108.749Z"
              fill="#5956D9" />
            <path
              d="M844.106 132.523C837.229 132.523 831.633 126.927 831.633 120.05C831.633 113.171 837.229 107.576 844.106 107.576C850.984 107.576 856.58 113.171 856.58 120.05C856.58 126.928 850.984 132.523 844.106 132.523ZM844.106 108.749C837.876 108.749 832.806 113.819 832.806 120.05C832.806 126.28 837.876 131.35 844.106 131.35C850.337 131.35 855.406 126.28 855.406 120.05C855.406 113.819 850.338 108.749 844.106 108.749Z"
              fill="#5956D9" />
            <path
              d="M875.466 132.523C868.588 132.523 862.992 126.927 862.992 120.05C862.992 113.171 868.588 107.576 875.466 107.576C882.343 107.576 887.939 113.171 887.939 120.05C887.939 126.928 882.343 132.523 875.466 132.523ZM875.466 108.749C869.235 108.749 864.165 113.819 864.165 120.05C864.165 126.28 869.235 131.35 875.466 131.35C881.696 131.35 886.766 126.28 886.766 120.05C886.766 113.819 881.697 108.749 875.466 108.749Z"
              fill="#5956D9" />
            <path
              d="M906.825 132.523C899.947 132.523 894.352 126.927 894.352 120.05C894.352 113.171 899.947 107.576 906.825 107.576C913.702 107.576 919.298 113.171 919.298 120.05C919.298 126.928 913.702 132.523 906.825 132.523ZM906.825 108.749C900.594 108.749 895.525 113.819 895.525 120.05C895.525 126.28 900.594 131.35 906.825 131.35C913.056 131.35 918.125 126.28 918.125 120.05C918.125 113.819 913.056 108.749 906.825 108.749Z"
              fill="#5956D9" />
            <path
              d="M28.7351 166.738C21.8576 166.738 16.2617 161.142 16.2617 154.264C16.2617 147.386 21.8576 141.791 28.7351 141.791C35.6125 141.791 41.2085 147.386 41.2085 154.264C41.2085 161.143 35.6125 166.738 28.7351 166.738ZM28.7351 142.964C22.5044 142.964 17.4349 148.034 17.4349 154.264C17.4349 160.495 22.5044 165.564 28.7351 165.564C34.9657 165.564 40.0352 160.495 40.0352 154.264C40.0352 148.034 34.9666 142.964 28.7351 142.964Z"
              fill="#5956D9" />
            <path
              d="M60.0984 166.738C53.2209 166.738 47.625 161.142 47.625 154.264C47.625 147.386 53.2209 141.791 60.0984 141.791C66.9758 141.791 72.5717 147.386 72.5717 154.264C72.5717 161.143 66.9758 166.738 60.0984 166.738ZM60.0984 142.964C53.8677 142.964 48.7982 148.034 48.7982 154.264C48.7982 160.495 53.8677 165.564 60.0984 165.564C66.329 165.564 71.3985 160.495 71.3985 154.264C71.3985 148.034 66.3299 142.964 60.0984 142.964Z"
              fill="#5956D9" />
            <path
              d="M91.4577 166.738C84.5803 166.738 78.9844 161.142 78.9844 154.264C78.9844 147.386 84.5803 141.791 91.4577 141.791C98.3352 141.791 103.931 147.386 103.931 154.264C103.931 161.143 98.3352 166.738 91.4577 166.738ZM91.4577 142.964C85.2271 142.964 80.1576 148.034 80.1576 154.264C80.1576 160.495 85.2271 165.564 91.4577 165.564C97.6884 165.564 102.758 160.495 102.758 154.264C102.758 148.034 97.6893 142.964 91.4577 142.964Z"
              fill="#5956D9" />
            <path
              d="M122.821 166.738C115.944 166.738 110.348 161.142 110.348 154.264C110.348 147.386 115.944 141.791 122.821 141.791C129.698 141.791 135.294 147.386 135.294 154.264C135.294 161.143 129.698 166.738 122.821 166.738ZM122.821 142.964C116.59 142.964 111.521 148.034 111.521 154.264C111.521 160.495 116.59 165.564 122.821 165.564C129.052 165.564 134.121 160.495 134.121 154.264C134.121 148.034 129.053 142.964 122.821 142.964Z"
              fill="#5956D9" />
            <path
              d="M154.18 166.738C147.303 166.738 141.707 161.142 141.707 154.264C141.707 147.386 147.303 141.791 154.18 141.791C161.058 141.791 166.654 147.386 166.654 154.264C166.654 161.143 161.058 166.738 154.18 166.738ZM154.18 142.964C147.95 142.964 142.88 148.034 142.88 154.264C142.88 160.495 147.95 165.564 154.18 165.564C160.411 165.564 165.481 160.495 165.481 154.264C165.481 148.034 160.412 142.964 154.18 142.964Z"
              fill="#5956D9" />
            <path
              d="M185.544 166.738C178.666 166.738 173.07 161.142 173.07 154.264C173.07 147.386 178.666 141.791 185.544 141.791C192.421 141.791 198.017 147.386 198.017 154.264C198.017 161.143 192.421 166.738 185.544 166.738ZM185.544 142.964C179.313 142.964 174.244 148.034 174.244 154.264C174.244 160.495 179.313 165.564 185.544 165.564C191.774 165.564 196.844 160.495 196.844 154.264C196.844 148.034 191.775 142.964 185.544 142.964Z"
              fill="#5956D9" />
            <path
              d="M216.907 166.738C210.029 166.738 204.434 161.142 204.434 154.264C204.434 147.386 210.029 141.791 216.907 141.791C223.784 141.791 229.38 147.386 229.38 154.264C229.38 161.143 223.784 166.738 216.907 166.738ZM216.907 142.964C210.676 142.964 205.607 148.034 205.607 154.264C205.607 160.495 210.676 165.564 216.907 165.564C223.138 165.564 228.207 160.495 228.207 154.264C228.207 148.034 223.138 142.964 216.907 142.964Z"
              fill="#5956D9" />
            <path
              d="M248.266 166.738C241.389 166.738 235.793 161.142 235.793 154.264C235.793 147.386 241.389 141.791 248.266 141.791C255.144 141.791 260.74 147.386 260.74 154.264C260.74 161.143 255.144 166.738 248.266 166.738ZM248.266 142.964C242.036 142.964 236.966 148.034 236.966 154.264C236.966 160.495 242.036 165.564 248.266 165.564C254.497 165.564 259.566 160.495 259.566 154.264C259.566 148.034 254.498 142.964 248.266 142.964Z"
              fill="#5956D9" />
            <path
              d="M279.63 166.738C272.752 166.738 267.156 161.142 267.156 154.264C267.156 147.386 272.752 141.791 279.63 141.791C286.507 141.791 292.103 147.386 292.103 154.264C292.103 161.143 286.507 166.738 279.63 166.738ZM279.63 142.964C273.399 142.964 268.329 148.034 268.329 154.264C268.329 160.495 273.399 165.564 279.63 165.564C285.86 165.564 290.93 160.495 290.93 154.264C290.93 148.034 285.861 142.964 279.63 142.964Z"
              fill="#5956D9" />
            <path
              d="M310.989 166.738C304.112 166.738 298.516 161.142 298.516 154.264C298.516 147.386 304.112 141.791 310.989 141.791C317.866 141.791 323.462 147.386 323.462 154.264C323.462 161.143 317.866 166.738 310.989 166.738ZM310.989 142.964C304.758 142.964 299.689 148.034 299.689 154.264C299.689 160.495 304.758 165.564 310.989 165.564C317.22 165.564 322.289 160.495 322.289 154.264C322.289 148.034 317.221 142.964 310.989 142.964Z"
              fill="#5956D9" />
            <path
              d="M342.352 166.738C335.475 166.738 329.879 161.142 329.879 154.264C329.879 147.386 335.475 141.791 342.352 141.791C349.23 141.791 354.826 147.386 354.826 154.264C354.826 161.143 349.23 166.738 342.352 166.738ZM342.352 142.964C336.122 142.964 331.052 148.034 331.052 154.264C331.052 160.495 336.122 165.564 342.352 165.564C348.583 165.564 353.652 160.495 353.652 154.264C353.652 148.034 348.584 142.964 342.352 142.964Z"
              fill="#5956D9" />
            <path
              d="M373.712 166.738C366.834 166.738 361.238 161.142 361.238 154.264C361.238 147.386 366.834 141.791 373.712 141.791C380.589 141.791 386.185 147.386 386.185 154.264C386.185 161.143 380.589 166.738 373.712 166.738ZM373.712 142.964C367.481 142.964 362.411 148.034 362.411 154.264C362.411 160.495 367.481 165.564 373.712 165.564C379.942 165.564 385.012 160.495 385.012 154.264C385.012 148.034 379.943 142.964 373.712 142.964Z"
              fill="#5956D9" />
            <path
              d="M405.075 166.738C398.197 166.738 392.602 161.142 392.602 154.264C392.602 147.386 398.197 141.791 405.075 141.791C411.952 141.791 417.548 147.386 417.548 154.264C417.548 161.143 411.952 166.738 405.075 166.738ZM405.075 142.964C398.844 142.964 393.775 148.034 393.775 154.264C393.775 160.495 398.844 165.564 405.075 165.564C411.306 165.564 416.375 160.495 416.375 154.264C416.375 148.034 411.306 142.964 405.075 142.964Z"
              fill="#5956D9" />
            <path
              d="M436.434 166.738C429.557 166.738 423.961 161.142 423.961 154.264C423.961 147.386 429.557 141.791 436.434 141.791C443.312 141.791 448.908 147.386 448.908 154.264C448.908 161.143 443.312 166.738 436.434 166.738ZM436.434 142.964C430.204 142.964 425.134 148.034 425.134 154.264C425.134 160.495 430.204 165.564 436.434 165.564C442.665 165.564 447.734 160.495 447.734 154.264C447.734 148.034 442.666 142.964 436.434 142.964Z"
              fill="#5956D9" />
            <path
              d="M467.794 166.738C460.916 166.738 455.32 161.142 455.32 154.264C455.32 147.386 460.916 141.791 467.794 141.791C474.671 141.791 480.267 147.386 480.267 154.264C480.267 161.143 474.671 166.738 467.794 166.738ZM467.794 142.964C461.563 142.964 456.494 148.034 456.494 154.264C456.494 160.495 461.563 165.564 467.794 165.564C474.024 165.564 479.094 160.495 479.094 154.264C479.094 148.034 474.025 142.964 467.794 142.964Z"
              fill="#5956D9" />
            <path
              d="M499.153 166.738C492.276 166.738 486.68 161.142 486.68 154.264C486.68 147.386 492.276 141.791 499.153 141.791C506.031 141.791 511.626 147.386 511.626 154.264C511.626 161.143 506.031 166.738 499.153 166.738ZM499.153 142.964C492.922 142.964 487.853 148.034 487.853 154.264C487.853 160.495 492.922 165.564 499.153 165.564C505.384 165.564 510.453 160.495 510.453 154.264C510.453 148.034 505.385 142.964 499.153 142.964Z"
              fill="#5956D9" />
            <path
              d="M530.512 166.738C523.635 166.738 518.039 161.142 518.039 154.264C518.039 147.386 523.635 141.791 530.512 141.791C537.39 141.791 542.986 147.386 542.986 154.264C542.986 161.143 537.39 166.738 530.512 166.738ZM530.512 142.964C524.282 142.964 519.212 148.034 519.212 154.264C519.212 160.495 524.282 165.564 530.512 165.564C536.743 165.564 541.813 160.495 541.813 154.264C541.813 148.034 536.744 142.964 530.512 142.964Z"
              fill="#5956D9" />
            <path
              d="M561.872 166.738C554.994 166.738 549.398 161.142 549.398 154.264C549.398 147.386 554.994 141.791 561.872 141.791C568.749 141.791 574.345 147.386 574.345 154.264C574.345 161.143 568.749 166.738 561.872 166.738ZM561.872 142.964C555.641 142.964 550.572 148.034 550.572 154.264C550.572 160.495 555.641 165.564 561.872 165.564C568.102 165.564 573.172 160.495 573.172 154.264C573.172 148.034 568.103 142.964 561.872 142.964Z"
              fill="#5956D9" />
            <path
              d="M593.231 166.738C586.354 166.738 580.758 161.142 580.758 154.264C580.758 147.386 586.354 141.791 593.231 141.791C600.109 141.791 605.705 147.386 605.705 154.264C605.705 161.143 600.109 166.738 593.231 166.738ZM593.231 142.964C587.001 142.964 581.931 148.034 581.931 154.264C581.931 160.495 587.001 165.564 593.231 165.564C599.462 165.564 604.531 160.495 604.531 154.264C604.531 148.034 599.463 142.964 593.231 142.964Z"
              fill="#5956D9" />
            <path
              d="M624.591 166.738C617.713 166.738 612.117 161.142 612.117 154.264C612.117 147.386 617.713 141.791 624.591 141.791C631.468 141.791 637.064 147.386 637.064 154.264C637.064 161.143 631.468 166.738 624.591 166.738ZM624.591 142.964C618.36 142.964 613.29 148.034 613.29 154.264C613.29 160.495 618.36 165.564 624.591 165.564C630.821 165.564 635.891 160.495 635.891 154.264C635.891 148.034 630.822 142.964 624.591 142.964Z"
              fill="#5956D9" />
            <path
              d="M655.95 166.738C649.072 166.738 643.477 161.142 643.477 154.264C643.477 147.386 649.072 141.791 655.95 141.791C662.827 141.791 668.423 147.386 668.423 154.264C668.423 161.143 662.827 166.738 655.95 166.738ZM655.95 142.964C649.719 142.964 644.65 148.034 644.65 154.264C644.65 160.495 649.719 165.564 655.95 165.564C662.181 165.564 667.25 160.495 667.25 154.264C667.25 148.034 662.181 142.964 655.95 142.964Z"
              fill="#5956D9" />
            <path
              d="M687.309 166.738C680.432 166.738 674.836 161.142 674.836 154.264C674.836 147.386 680.432 141.791 687.309 141.791C694.187 141.791 699.783 147.386 699.783 154.264C699.783 161.143 694.187 166.738 687.309 166.738ZM687.309 142.964C681.079 142.964 676.009 148.034 676.009 154.264C676.009 160.495 681.079 165.564 687.309 165.564C693.54 165.564 698.609 160.495 698.609 154.264C698.609 148.034 693.541 142.964 687.309 142.964Z"
              fill="#5956D9" />
            <path
              d="M718.669 166.738C711.791 166.738 706.195 161.142 706.195 154.264C706.195 147.386 711.791 141.791 718.669 141.791C725.546 141.791 731.142 147.386 731.142 154.264C731.142 161.143 725.546 166.738 718.669 166.738ZM718.669 142.964C712.438 142.964 707.369 148.034 707.369 154.264C707.369 160.495 712.438 165.564 718.669 165.564C724.899 165.564 729.969 160.495 729.969 154.264C729.969 148.034 724.9 142.964 718.669 142.964Z"
              fill="#5956D9" />
            <path
              d="M750.028 166.738C743.151 166.738 737.555 161.142 737.555 154.264C737.555 147.386 743.151 141.791 750.028 141.791C756.906 141.791 762.501 147.386 762.501 154.264C762.501 161.143 756.906 166.738 750.028 166.738ZM750.028 142.964C743.797 142.964 738.728 148.034 738.728 154.264C738.728 160.495 743.797 165.564 750.028 165.564C756.259 165.564 761.328 160.495 761.328 154.264C761.328 148.034 756.26 142.964 750.028 142.964Z"
              fill="#5956D9" />
            <path
              d="M781.387 166.738C774.51 166.738 768.914 161.142 768.914 154.264C768.914 147.386 774.51 141.791 781.387 141.791C788.265 141.791 793.861 147.386 793.861 154.264C793.861 161.143 788.265 166.738 781.387 166.738ZM781.387 142.964C775.157 142.964 770.087 148.034 770.087 154.264C770.087 160.495 775.157 165.564 781.387 165.564C787.618 165.564 792.688 160.495 792.688 154.264C792.688 148.034 787.619 142.964 781.387 142.964Z"
              fill="#5956D9" />
            <path
              d="M812.747 166.738C805.869 166.738 800.273 161.142 800.273 154.264C800.273 147.386 805.869 141.791 812.747 141.791C819.624 141.791 825.22 147.386 825.22 154.264C825.22 161.143 819.624 166.738 812.747 166.738ZM812.747 142.964C806.516 142.964 801.447 148.034 801.447 154.264C801.447 160.495 806.516 165.564 812.747 165.564C818.977 165.564 824.047 160.495 824.047 154.264C824.047 148.034 818.978 142.964 812.747 142.964Z"
              fill="#5956D9" />
            <path
              d="M844.106 166.738C837.229 166.738 831.633 161.142 831.633 154.264C831.633 147.386 837.229 141.791 844.106 141.791C850.984 141.791 856.58 147.386 856.58 154.264C856.58 161.143 850.984 166.738 844.106 166.738ZM844.106 142.964C837.876 142.964 832.806 148.034 832.806 154.264C832.806 160.495 837.876 165.564 844.106 165.564C850.337 165.564 855.406 160.495 855.406 154.264C855.406 148.034 850.338 142.964 844.106 142.964Z"
              fill="#5956D9" />
            <path
              d="M875.466 166.738C868.588 166.738 862.992 161.142 862.992 154.264C862.992 147.386 868.588 141.791 875.466 141.791C882.343 141.791 887.939 147.386 887.939 154.264C887.939 161.143 882.343 166.738 875.466 166.738ZM875.466 142.964C869.235 142.964 864.165 148.034 864.165 154.264C864.165 160.495 869.235 165.564 875.466 165.564C881.696 165.564 886.766 160.495 886.766 154.264C886.766 148.034 881.697 142.964 875.466 142.964Z"
              fill="#5956D9" />
            <path
              d="M906.825 166.738C899.947 166.738 894.352 161.142 894.352 154.264C894.352 147.386 899.947 141.791 906.825 141.791C913.702 141.791 919.298 147.386 919.298 154.264C919.298 161.143 913.702 166.738 906.825 166.738ZM906.825 142.964C900.594 142.964 895.525 148.034 895.525 154.264C895.525 160.495 900.594 165.564 906.825 165.564C913.056 165.564 918.125 160.495 918.125 154.264C918.125 148.034 913.056 142.964 906.825 142.964Z"
              fill="#5956D9" />
            <path
              d="M939.255 29.8861C932.377 29.8861 926.781 24.2902 926.781 17.4128C926.781 10.5345 932.377 4.93945 939.255 4.93945C946.132 4.93945 951.728 10.5345 951.728 17.4128C951.728 24.2911 946.132 29.8861 939.255 29.8861ZM939.255 6.11264C933.024 6.11264 927.954 11.1821 927.954 17.4128C927.954 23.6434 933.024 28.7129 939.255 28.7129C945.485 28.7129 950.555 23.6434 950.555 17.4128C950.555 11.1821 945.486 6.11264 939.255 6.11264Z"
              fill="#5956D9" />
            <path
              d="M970.618 29.8861C963.74 29.8861 958.145 24.2902 958.145 17.4128C958.145 10.5345 963.74 4.93945 970.618 4.93945C977.495 4.93945 983.091 10.5345 983.091 17.4128C983.091 24.2911 977.495 29.8861 970.618 29.8861ZM970.618 6.11264C964.387 6.11264 959.318 11.1821 959.318 17.4128C959.318 23.6434 964.387 28.7129 970.618 28.7129C976.849 28.7129 981.918 23.6434 981.918 17.4128C981.918 11.1821 976.849 6.11264 970.618 6.11264Z"
              fill="#5956D9" />
            <path
              d="M1001.98 29.8861C995.1 29.8861 989.504 24.2902 989.504 17.4128C989.504 10.5345 995.1 4.93945 1001.98 4.93945C1008.85 4.93945 1014.45 10.5345 1014.45 17.4128C1014.45 24.2911 1008.85 29.8861 1001.98 29.8861ZM1001.98 6.11264C995.747 6.11264 990.677 11.1821 990.677 17.4128C990.677 23.6434 995.747 28.7129 1001.98 28.7129C1008.21 28.7129 1013.28 23.6434 1013.28 17.4128C1013.28 11.1821 1008.21 6.11264 1001.98 6.11264Z"
              fill="#5956D9" />
            <path
              d="M1033.34 29.8861C1026.46 29.8861 1020.87 24.2902 1020.87 17.4128C1020.87 10.5345 1026.46 4.93945 1033.34 4.93945C1040.22 4.93945 1045.81 10.5345 1045.81 17.4128C1045.81 24.2911 1040.22 29.8861 1033.34 29.8861ZM1033.34 6.11264C1027.11 6.11264 1022.04 11.1821 1022.04 17.4128C1022.04 23.6434 1027.11 28.7129 1033.34 28.7129C1039.57 28.7129 1044.64 23.6434 1044.64 17.4128C1044.64 11.1821 1039.57 6.11264 1033.34 6.11264Z"
              fill="#5956D9" />
            <path
              d="M1064.7 29.8861C1057.82 29.8861 1052.23 24.2902 1052.23 17.4128C1052.23 10.5345 1057.82 4.93945 1064.7 4.93945C1071.58 4.93945 1077.17 10.5345 1077.17 17.4128C1077.17 24.2911 1071.58 29.8861 1064.7 29.8861ZM1064.7 6.11264C1058.47 6.11264 1053.4 11.1821 1053.4 17.4128C1053.4 23.6434 1058.47 28.7129 1064.7 28.7129C1070.93 28.7129 1076 23.6434 1076 17.4128C1076 11.1821 1070.93 6.11264 1064.7 6.11264Z"
              fill="#5956D9" />
            <path
              d="M1096.06 29.8861C1089.19 29.8861 1083.59 24.2902 1083.59 17.4128C1083.59 10.5345 1089.19 4.93945 1096.06 4.93945C1102.94 4.93945 1108.54 10.5345 1108.54 17.4128C1108.54 24.2911 1102.94 29.8861 1096.06 29.8861ZM1096.06 6.11264C1089.83 6.11264 1084.76 11.1821 1084.76 17.4128C1084.76 23.6434 1089.83 28.7129 1096.06 28.7129C1102.29 28.7129 1107.36 23.6434 1107.36 17.4128C1107.36 11.1821 1102.29 6.11264 1096.06 6.11264Z"
              fill="#5956D9" />
            <path
              d="M1127.43 29.8861C1120.55 29.8861 1114.95 24.2902 1114.95 17.4128C1114.95 10.5345 1120.55 4.93945 1127.43 4.93945C1134.3 4.93945 1139.9 10.5345 1139.9 17.4128C1139.9 24.2911 1134.3 29.8861 1127.43 29.8861ZM1127.43 6.11264C1121.2 6.11264 1116.13 11.1821 1116.13 17.4128C1116.13 23.6434 1121.2 28.7129 1127.43 28.7129C1133.66 28.7129 1138.73 23.6434 1138.73 17.4128C1138.73 11.1821 1133.66 6.11264 1127.43 6.11264Z"
              fill="#5956D9" />
            <path
              d="M1158.79 29.8861C1151.91 29.8861 1146.31 24.2902 1146.31 17.4128C1146.31 10.5345 1151.91 4.93945 1158.79 4.93945C1165.66 4.93945 1171.26 10.5345 1171.26 17.4128C1171.26 24.2911 1165.66 29.8861 1158.79 29.8861ZM1158.79 6.11264C1152.56 6.11264 1147.49 11.1821 1147.49 17.4128C1147.49 23.6434 1152.56 28.7129 1158.79 28.7129C1165.02 28.7129 1170.09 23.6434 1170.09 17.4128C1170.09 11.1821 1165.02 6.11264 1158.79 6.11264Z"
              fill="#5956D9" />
            <path
              d="M1190.15 29.8861C1183.27 29.8861 1177.68 24.2902 1177.68 17.4128C1177.68 10.5345 1183.27 4.93945 1190.15 4.93945C1197.03 4.93945 1202.62 10.5345 1202.62 17.4128C1202.62 24.2911 1197.03 29.8861 1190.15 29.8861ZM1190.15 6.11264C1183.92 6.11264 1178.85 11.1821 1178.85 17.4128C1178.85 23.6434 1183.92 28.7129 1190.15 28.7129C1196.38 28.7129 1201.45 23.6434 1201.45 17.4128C1201.45 11.1821 1196.38 6.11264 1190.15 6.11264Z"
              fill="#5956D9" />
            <path
              d="M1221.51 29.8861C1214.63 29.8861 1209.04 24.2902 1209.04 17.4128C1209.04 10.5345 1214.63 4.93945 1221.51 4.93945C1228.39 4.93945 1233.98 10.5345 1233.98 17.4128C1233.98 24.2911 1228.39 29.8861 1221.51 29.8861ZM1221.51 6.11264C1215.28 6.11264 1210.21 11.1821 1210.21 17.4128C1210.21 23.6434 1215.28 28.7129 1221.51 28.7129C1227.74 28.7129 1232.81 23.6434 1232.81 17.4128C1232.81 11.1821 1227.74 6.11264 1221.51 6.11264Z"
              fill="#5956D9" />
            <path
              d="M1252.87 29.8861C1245.99 29.8861 1240.4 24.2902 1240.4 17.4128C1240.4 10.5345 1245.99 4.93945 1252.87 4.93945C1259.75 4.93945 1265.35 10.5345 1265.35 17.4128C1265.35 24.2911 1259.75 29.8861 1252.87 29.8861ZM1252.87 6.11264C1246.64 6.11264 1241.57 11.1821 1241.57 17.4128C1241.57 23.6434 1246.64 28.7129 1252.87 28.7129C1259.1 28.7129 1264.17 23.6434 1264.17 17.4128C1264.17 11.1821 1259.1 6.11264 1252.87 6.11264Z"
              fill="#5956D9" />
            <path
              d="M1284.23 29.8861C1277.35 29.8861 1271.76 24.2902 1271.76 17.4128C1271.76 10.5345 1277.35 4.93945 1284.23 4.93945C1291.11 4.93945 1296.7 10.5345 1296.7 17.4128C1296.7 24.2911 1291.11 29.8861 1284.23 29.8861ZM1284.23 6.11264C1278 6.11264 1272.93 11.1821 1272.93 17.4128C1272.93 23.6434 1278 28.7129 1284.23 28.7129C1290.46 28.7129 1295.53 23.6434 1295.53 17.4128C1295.53 11.1821 1290.46 6.11264 1284.23 6.11264Z"
              fill="#5956D9" />
            <path
              d="M1315.59 29.8861C1308.72 29.8861 1303.12 24.2902 1303.12 17.4128C1303.12 10.5345 1308.72 4.93945 1315.59 4.93945C1322.47 4.93945 1328.07 10.5345 1328.07 17.4128C1328.07 24.2911 1322.47 29.8861 1315.59 29.8861ZM1315.59 6.11264C1309.36 6.11264 1304.29 11.1821 1304.29 17.4128C1304.29 23.6434 1309.36 28.7129 1315.59 28.7129C1321.83 28.7129 1326.89 23.6434 1326.89 17.4128C1326.89 11.1821 1321.83 6.11264 1315.59 6.11264Z"
              fill="#5956D9" />
            <path
              d="M1346.95 29.8861C1340.08 29.8861 1334.48 24.2902 1334.48 17.4128C1334.48 10.5345 1340.08 4.93945 1346.95 4.93945C1353.83 4.93945 1359.43 10.5345 1359.43 17.4128C1359.43 24.2911 1353.83 29.8861 1346.95 29.8861ZM1346.95 6.11264C1340.72 6.11264 1335.65 11.1821 1335.65 17.4128C1335.65 23.6434 1340.72 28.7129 1346.95 28.7129C1353.18 28.7129 1358.25 23.6434 1358.25 17.4128C1358.25 11.1821 1353.19 6.11264 1346.95 6.11264Z"
              fill="#5956D9" />
            <path
              d="M1378.31 29.8861C1371.44 29.8861 1365.84 24.2902 1365.84 17.4128C1365.84 10.5345 1371.44 4.93945 1378.31 4.93945C1385.19 4.93945 1390.79 10.5345 1390.79 17.4128C1390.79 24.2911 1385.19 29.8861 1378.31 29.8861ZM1378.31 6.11264C1372.08 6.11264 1367.01 11.1821 1367.01 17.4128C1367.01 23.6434 1372.08 28.7129 1378.31 28.7129C1384.54 28.7129 1389.61 23.6434 1389.61 17.4128C1389.61 11.1821 1384.54 6.11264 1378.31 6.11264Z"
              fill="#5956D9" />
            <path
              d="M1409.67 29.8861C1402.8 29.8861 1397.2 24.2902 1397.2 17.4128C1397.2 10.5345 1402.8 4.93945 1409.67 4.93945C1416.55 4.93945 1422.15 10.5345 1422.15 17.4128C1422.15 24.2911 1416.55 29.8861 1409.67 29.8861ZM1409.67 6.11264C1403.44 6.11264 1398.37 11.1821 1398.37 17.4128C1398.37 23.6434 1403.44 28.7129 1409.67 28.7129C1415.9 28.7129 1420.97 23.6434 1420.97 17.4128C1420.97 11.1821 1415.9 6.11264 1409.67 6.11264Z"
              fill="#5956D9" />
            <path
              d="M1441.03 29.8861C1434.15 29.8861 1428.56 24.2902 1428.56 17.4128C1428.56 10.5345 1434.15 4.93945 1441.03 4.93945C1447.91 4.93945 1453.51 10.5345 1453.51 17.4128C1453.51 24.2911 1447.91 29.8861 1441.03 29.8861ZM1441.03 6.11264C1434.8 6.11264 1429.73 11.1821 1429.73 17.4128C1429.73 23.6434 1434.8 28.7129 1441.03 28.7129C1447.26 28.7129 1452.33 23.6434 1452.33 17.4128C1452.33 11.1821 1447.26 6.11264 1441.03 6.11264Z"
              fill="#5956D9" />
            <path
              d="M939.255 64.099C932.377 64.099 926.781 58.5031 926.781 51.6257C926.781 44.7474 932.377 39.1523 939.255 39.1523C946.132 39.1523 951.728 44.7474 951.728 51.6257C951.728 58.504 946.132 64.099 939.255 64.099ZM939.255 40.3255C933.024 40.3255 927.954 45.395 927.954 51.6257C927.954 57.8563 933.024 62.9258 939.255 62.9258C945.485 62.9258 950.555 57.8563 950.555 51.6257C950.555 45.395 945.486 40.3255 939.255 40.3255Z"
              fill="#5956D9" />
            <path
              d="M970.618 64.099C963.74 64.099 958.145 58.5031 958.145 51.6257C958.145 44.7474 963.74 39.1523 970.618 39.1523C977.495 39.1523 983.091 44.7474 983.091 51.6257C983.091 58.504 977.495 64.099 970.618 64.099ZM970.618 40.3255C964.387 40.3255 959.318 45.395 959.318 51.6257C959.318 57.8563 964.387 62.9258 970.618 62.9258C976.849 62.9258 981.918 57.8563 981.918 51.6257C981.918 45.395 976.849 40.3255 970.618 40.3255Z"
              fill="#5956D9" />
            <path
              d="M1001.98 64.099C995.1 64.099 989.504 58.5031 989.504 51.6257C989.504 44.7474 995.1 39.1523 1001.98 39.1523C1008.85 39.1523 1014.45 44.7474 1014.45 51.6257C1014.45 58.504 1008.85 64.099 1001.98 64.099ZM1001.98 40.3255C995.747 40.3255 990.677 45.395 990.677 51.6257C990.677 57.8563 995.747 62.9258 1001.98 62.9258C1008.21 62.9258 1013.28 57.8563 1013.28 51.6257C1013.28 45.395 1008.21 40.3255 1001.98 40.3255Z"
              fill="#5956D9" />
            <path
              d="M1033.34 64.099C1026.46 64.099 1020.87 58.5031 1020.87 51.6257C1020.87 44.7474 1026.46 39.1523 1033.34 39.1523C1040.22 39.1523 1045.81 44.7474 1045.81 51.6257C1045.81 58.504 1040.22 64.099 1033.34 64.099ZM1033.34 40.3255C1027.11 40.3255 1022.04 45.395 1022.04 51.6257C1022.04 57.8563 1027.11 62.9258 1033.34 62.9258C1039.57 62.9258 1044.64 57.8563 1044.64 51.6257C1044.64 45.395 1039.57 40.3255 1033.34 40.3255Z"
              fill="#5956D9" />
            <path
              d="M1064.7 64.099C1057.82 64.099 1052.23 58.5031 1052.23 51.6257C1052.23 44.7474 1057.82 39.1523 1064.7 39.1523C1071.58 39.1523 1077.17 44.7474 1077.17 51.6257C1077.17 58.504 1071.58 64.099 1064.7 64.099ZM1064.7 40.3255C1058.47 40.3255 1053.4 45.395 1053.4 51.6257C1053.4 57.8563 1058.47 62.9258 1064.7 62.9258C1070.93 62.9258 1076 57.8563 1076 51.6257C1076 45.395 1070.93 40.3255 1064.7 40.3255Z"
              fill="#5956D9" />
            <path
              d="M1096.06 64.099C1089.19 64.099 1083.59 58.5031 1083.59 51.6257C1083.59 44.7474 1089.19 39.1523 1096.06 39.1523C1102.94 39.1523 1108.54 44.7474 1108.54 51.6257C1108.54 58.504 1102.94 64.099 1096.06 64.099ZM1096.06 40.3255C1089.83 40.3255 1084.76 45.395 1084.76 51.6257C1084.76 57.8563 1089.83 62.9258 1096.06 62.9258C1102.29 62.9258 1107.36 57.8563 1107.36 51.6257C1107.36 45.395 1102.29 40.3255 1096.06 40.3255Z"
              fill="#5956D9" />
            <path
              d="M1127.43 64.099C1120.55 64.099 1114.95 58.5031 1114.95 51.6257C1114.95 44.7474 1120.55 39.1523 1127.43 39.1523C1134.3 39.1523 1139.9 44.7474 1139.9 51.6257C1139.9 58.504 1134.3 64.099 1127.43 64.099ZM1127.43 40.3255C1121.2 40.3255 1116.13 45.395 1116.13 51.6257C1116.13 57.8563 1121.2 62.9258 1127.43 62.9258C1133.66 62.9258 1138.73 57.8563 1138.73 51.6257C1138.73 45.395 1133.66 40.3255 1127.43 40.3255Z"
              fill="#5956D9" />
            <path
              d="M1158.79 64.099C1151.91 64.099 1146.31 58.5031 1146.31 51.6257C1146.31 44.7474 1151.91 39.1523 1158.79 39.1523C1165.66 39.1523 1171.26 44.7474 1171.26 51.6257C1171.26 58.504 1165.66 64.099 1158.79 64.099ZM1158.79 40.3255C1152.56 40.3255 1147.49 45.395 1147.49 51.6257C1147.49 57.8563 1152.56 62.9258 1158.79 62.9258C1165.02 62.9258 1170.09 57.8563 1170.09 51.6257C1170.09 45.395 1165.02 40.3255 1158.79 40.3255Z"
              fill="#5956D9" />
            <path
              d="M1190.15 64.099C1183.27 64.099 1177.68 58.5031 1177.68 51.6257C1177.68 44.7474 1183.27 39.1523 1190.15 39.1523C1197.03 39.1523 1202.62 44.7474 1202.62 51.6257C1202.62 58.504 1197.03 64.099 1190.15 64.099ZM1190.15 40.3255C1183.92 40.3255 1178.85 45.395 1178.85 51.6257C1178.85 57.8563 1183.92 62.9258 1190.15 62.9258C1196.38 62.9258 1201.45 57.8563 1201.45 51.6257C1201.45 45.395 1196.38 40.3255 1190.15 40.3255Z"
              fill="#5956D9" />
            <path
              d="M1221.51 64.099C1214.63 64.099 1209.04 58.5031 1209.04 51.6257C1209.04 44.7474 1214.63 39.1523 1221.51 39.1523C1228.39 39.1523 1233.98 44.7474 1233.98 51.6257C1233.98 58.504 1228.39 64.099 1221.51 64.099ZM1221.51 40.3255C1215.28 40.3255 1210.21 45.395 1210.21 51.6257C1210.21 57.8563 1215.28 62.9258 1221.51 62.9258C1227.74 62.9258 1232.81 57.8563 1232.81 51.6257C1232.81 45.395 1227.74 40.3255 1221.51 40.3255Z"
              fill="#5956D9" />
            <path
              d="M1252.87 64.099C1245.99 64.099 1240.4 58.5031 1240.4 51.6257C1240.4 44.7474 1245.99 39.1523 1252.87 39.1523C1259.75 39.1523 1265.35 44.7474 1265.35 51.6257C1265.35 58.504 1259.75 64.099 1252.87 64.099ZM1252.87 40.3255C1246.64 40.3255 1241.57 45.395 1241.57 51.6257C1241.57 57.8563 1246.64 62.9258 1252.87 62.9258C1259.1 62.9258 1264.17 57.8563 1264.17 51.6257C1264.17 45.395 1259.1 40.3255 1252.87 40.3255Z"
              fill="#5956D9" />
            <path
              d="M1284.23 64.099C1277.35 64.099 1271.76 58.5031 1271.76 51.6257C1271.76 44.7474 1277.35 39.1523 1284.23 39.1523C1291.11 39.1523 1296.7 44.7474 1296.7 51.6257C1296.7 58.504 1291.11 64.099 1284.23 64.099ZM1284.23 40.3255C1278 40.3255 1272.93 45.395 1272.93 51.6257C1272.93 57.8563 1278 62.9258 1284.23 62.9258C1290.46 62.9258 1295.53 57.8563 1295.53 51.6257C1295.53 45.395 1290.46 40.3255 1284.23 40.3255Z"
              fill="#5956D9" />
            <path
              d="M1315.59 64.099C1308.72 64.099 1303.12 58.5031 1303.12 51.6257C1303.12 44.7474 1308.72 39.1523 1315.59 39.1523C1322.47 39.1523 1328.07 44.7474 1328.07 51.6257C1328.07 58.504 1322.47 64.099 1315.59 64.099ZM1315.59 40.3255C1309.36 40.3255 1304.29 45.395 1304.29 51.6257C1304.29 57.8563 1309.36 62.9258 1315.59 62.9258C1321.83 62.9258 1326.89 57.8563 1326.89 51.6257C1326.89 45.395 1321.83 40.3255 1315.59 40.3255Z"
              fill="#5956D9" />
            <path
              d="M1346.95 64.099C1340.08 64.099 1334.48 58.5031 1334.48 51.6257C1334.48 44.7474 1340.08 39.1523 1346.95 39.1523C1353.83 39.1523 1359.43 44.7474 1359.43 51.6257C1359.43 58.504 1353.83 64.099 1346.95 64.099ZM1346.95 40.3255C1340.72 40.3255 1335.65 45.395 1335.65 51.6257C1335.65 57.8563 1340.72 62.9258 1346.95 62.9258C1353.18 62.9258 1358.25 57.8563 1358.25 51.6257C1358.25 45.395 1353.19 40.3255 1346.95 40.3255Z"
              fill="#5956D9" />
            <path
              d="M1378.31 64.099C1371.44 64.099 1365.84 58.5031 1365.84 51.6257C1365.84 44.7474 1371.44 39.1523 1378.31 39.1523C1385.19 39.1523 1390.79 44.7474 1390.79 51.6257C1390.79 58.504 1385.19 64.099 1378.31 64.099ZM1378.31 40.3255C1372.08 40.3255 1367.01 45.395 1367.01 51.6257C1367.01 57.8563 1372.08 62.9258 1378.31 62.9258C1384.54 62.9258 1389.61 57.8563 1389.61 51.6257C1389.61 45.395 1384.54 40.3255 1378.31 40.3255Z"
              fill="#5956D9" />
            <path
              d="M1409.67 64.099C1402.8 64.099 1397.2 58.5031 1397.2 51.6257C1397.2 44.7474 1402.8 39.1523 1409.67 39.1523C1416.55 39.1523 1422.15 44.7474 1422.15 51.6257C1422.15 58.504 1416.55 64.099 1409.67 64.099ZM1409.67 40.3255C1403.44 40.3255 1398.37 45.395 1398.37 51.6257C1398.37 57.8563 1403.44 62.9258 1409.67 62.9258C1415.9 62.9258 1420.97 57.8563 1420.97 51.6257C1420.97 45.395 1415.9 40.3255 1409.67 40.3255Z"
              fill="#5956D9" />
            <path
              d="M1441.03 64.099C1434.15 64.099 1428.56 58.5031 1428.56 51.6257C1428.56 44.7474 1434.15 39.1523 1441.03 39.1523C1447.91 39.1523 1453.51 44.7474 1453.51 51.6257C1453.51 58.504 1447.91 64.099 1441.03 64.099ZM1441.03 40.3255C1434.8 40.3255 1429.73 45.395 1429.73 51.6257C1429.73 57.8563 1434.8 62.9258 1441.03 62.9258C1447.26 62.9258 1452.33 57.8563 1452.33 51.6257C1452.33 45.395 1447.26 40.3255 1441.03 40.3255Z"
              fill="#5956D9" />
            <path
              d="M939.255 98.3119C932.377 98.3119 926.781 92.716 926.781 85.8386C926.781 78.9603 932.377 73.3652 939.255 73.3652C946.132 73.3652 951.728 78.9603 951.728 85.8386C951.728 92.7169 946.132 98.3119 939.255 98.3119ZM939.255 74.5384C933.024 74.5384 927.954 79.6079 927.954 85.8386C927.954 92.0692 933.024 97.1387 939.255 97.1387C945.485 97.1387 950.555 92.0692 950.555 85.8386C950.555 79.6079 945.486 74.5384 939.255 74.5384Z"
              fill="#5956D9" />
            <path
              d="M970.618 98.3119C963.74 98.3119 958.145 92.716 958.145 85.8386C958.145 78.9603 963.74 73.3652 970.618 73.3652C977.495 73.3652 983.091 78.9603 983.091 85.8386C983.091 92.7169 977.495 98.3119 970.618 98.3119ZM970.618 74.5384C964.387 74.5384 959.318 79.6079 959.318 85.8386C959.318 92.0692 964.387 97.1387 970.618 97.1387C976.849 97.1387 981.918 92.0692 981.918 85.8386C981.918 79.6079 976.849 74.5384 970.618 74.5384Z"
              fill="#5956D9" />
            <path
              d="M1001.98 98.3119C995.1 98.3119 989.504 92.716 989.504 85.8386C989.504 78.9603 995.1 73.3652 1001.98 73.3652C1008.85 73.3652 1014.45 78.9603 1014.45 85.8386C1014.45 92.7169 1008.85 98.3119 1001.98 98.3119ZM1001.98 74.5384C995.747 74.5384 990.677 79.6079 990.677 85.8386C990.677 92.0692 995.747 97.1387 1001.98 97.1387C1008.21 97.1387 1013.28 92.0692 1013.28 85.8386C1013.28 79.6079 1008.21 74.5384 1001.98 74.5384Z"
              fill="#5956D9" />
            <path
              d="M1033.34 98.3119C1026.46 98.3119 1020.87 92.716 1020.87 85.8386C1020.87 78.9603 1026.46 73.3652 1033.34 73.3652C1040.22 73.3652 1045.81 78.9603 1045.81 85.8386C1045.81 92.7169 1040.22 98.3119 1033.34 98.3119ZM1033.34 74.5384C1027.11 74.5384 1022.04 79.6079 1022.04 85.8386C1022.04 92.0692 1027.11 97.1387 1033.34 97.1387C1039.57 97.1387 1044.64 92.0692 1044.64 85.8386C1044.64 79.6079 1039.57 74.5384 1033.34 74.5384Z"
              fill="#5956D9" />
            <path
              d="M1064.7 98.3119C1057.82 98.3119 1052.23 92.716 1052.23 85.8386C1052.23 78.9603 1057.82 73.3652 1064.7 73.3652C1071.58 73.3652 1077.17 78.9603 1077.17 85.8386C1077.17 92.7169 1071.58 98.3119 1064.7 98.3119ZM1064.7 74.5384C1058.47 74.5384 1053.4 79.6079 1053.4 85.8386C1053.4 92.0692 1058.47 97.1387 1064.7 97.1387C1070.93 97.1387 1076 92.0692 1076 85.8386C1076 79.6079 1070.93 74.5384 1064.7 74.5384Z"
              fill="#5956D9" />
            <path
              d="M1096.06 98.3119C1089.19 98.3119 1083.59 92.716 1083.59 85.8386C1083.59 78.9603 1089.19 73.3652 1096.06 73.3652C1102.94 73.3652 1108.54 78.9603 1108.54 85.8386C1108.54 92.7169 1102.94 98.3119 1096.06 98.3119ZM1096.06 74.5384C1089.83 74.5384 1084.76 79.6079 1084.76 85.8386C1084.76 92.0692 1089.83 97.1387 1096.06 97.1387C1102.29 97.1387 1107.36 92.0692 1107.36 85.8386C1107.36 79.6079 1102.29 74.5384 1096.06 74.5384Z"
              fill="#5956D9" />
            <path
              d="M1127.43 98.3119C1120.55 98.3119 1114.95 92.716 1114.95 85.8386C1114.95 78.9603 1120.55 73.3652 1127.43 73.3652C1134.3 73.3652 1139.9 78.9603 1139.9 85.8386C1139.9 92.7169 1134.3 98.3119 1127.43 98.3119ZM1127.43 74.5384C1121.2 74.5384 1116.13 79.6079 1116.13 85.8386C1116.13 92.0692 1121.2 97.1387 1127.43 97.1387C1133.66 97.1387 1138.73 92.0692 1138.73 85.8386C1138.73 79.6079 1133.66 74.5384 1127.43 74.5384Z"
              fill="#5956D9" />
            <path
              d="M1158.79 98.3119C1151.91 98.3119 1146.31 92.716 1146.31 85.8386C1146.31 78.9603 1151.91 73.3652 1158.79 73.3652C1165.66 73.3652 1171.26 78.9603 1171.26 85.8386C1171.26 92.7169 1165.66 98.3119 1158.79 98.3119ZM1158.79 74.5384C1152.56 74.5384 1147.49 79.6079 1147.49 85.8386C1147.49 92.0692 1152.56 97.1387 1158.79 97.1387C1165.02 97.1387 1170.09 92.0692 1170.09 85.8386C1170.09 79.6079 1165.02 74.5384 1158.79 74.5384Z"
              fill="#5956D9" />
            <path
              d="M1190.15 98.3119C1183.27 98.3119 1177.68 92.716 1177.68 85.8386C1177.68 78.9603 1183.27 73.3652 1190.15 73.3652C1197.03 73.3652 1202.62 78.9603 1202.62 85.8386C1202.62 92.7169 1197.03 98.3119 1190.15 98.3119ZM1190.15 74.5384C1183.92 74.5384 1178.85 79.6079 1178.85 85.8386C1178.85 92.0692 1183.92 97.1387 1190.15 97.1387C1196.38 97.1387 1201.45 92.0692 1201.45 85.8386C1201.45 79.6079 1196.38 74.5384 1190.15 74.5384Z"
              fill="#5956D9" />
            <path
              d="M1221.51 98.3119C1214.63 98.3119 1209.04 92.716 1209.04 85.8386C1209.04 78.9603 1214.63 73.3652 1221.51 73.3652C1228.39 73.3652 1233.98 78.9603 1233.98 85.8386C1233.98 92.7169 1228.39 98.3119 1221.51 98.3119ZM1221.51 74.5384C1215.28 74.5384 1210.21 79.6079 1210.21 85.8386C1210.21 92.0692 1215.28 97.1387 1221.51 97.1387C1227.74 97.1387 1232.81 92.0692 1232.81 85.8386C1232.81 79.6079 1227.74 74.5384 1221.51 74.5384Z"
              fill="#5956D9" />
            <path
              d="M1252.87 98.3119C1245.99 98.3119 1240.4 92.716 1240.4 85.8386C1240.4 78.9603 1245.99 73.3652 1252.87 73.3652C1259.75 73.3652 1265.35 78.9603 1265.35 85.8386C1265.35 92.7169 1259.75 98.3119 1252.87 98.3119ZM1252.87 74.5384C1246.64 74.5384 1241.57 79.6079 1241.57 85.8386C1241.57 92.0692 1246.64 97.1387 1252.87 97.1387C1259.1 97.1387 1264.17 92.0692 1264.17 85.8386C1264.17 79.6079 1259.1 74.5384 1252.87 74.5384Z"
              fill="#5956D9" />
            <path
              d="M1284.23 98.3119C1277.35 98.3119 1271.76 92.716 1271.76 85.8386C1271.76 78.9603 1277.35 73.3652 1284.23 73.3652C1291.11 73.3652 1296.7 78.9603 1296.7 85.8386C1296.7 92.7169 1291.11 98.3119 1284.23 98.3119ZM1284.23 74.5384C1278 74.5384 1272.93 79.6079 1272.93 85.8386C1272.93 92.0692 1278 97.1387 1284.23 97.1387C1290.46 97.1387 1295.53 92.0692 1295.53 85.8386C1295.53 79.6079 1290.46 74.5384 1284.23 74.5384Z"
              fill="#5956D9" />
            <path
              d="M1315.59 98.3119C1308.72 98.3119 1303.12 92.716 1303.12 85.8386C1303.12 78.9603 1308.72 73.3652 1315.59 73.3652C1322.47 73.3652 1328.07 78.9603 1328.07 85.8386C1328.07 92.7169 1322.47 98.3119 1315.59 98.3119ZM1315.59 74.5384C1309.36 74.5384 1304.29 79.6079 1304.29 85.8386C1304.29 92.0692 1309.36 97.1387 1315.59 97.1387C1321.83 97.1387 1326.89 92.0692 1326.89 85.8386C1326.89 79.6079 1321.83 74.5384 1315.59 74.5384Z"
              fill="#5956D9" />
            <path
              d="M1346.95 98.3119C1340.08 98.3119 1334.48 92.716 1334.48 85.8386C1334.48 78.9603 1340.08 73.3652 1346.95 73.3652C1353.83 73.3652 1359.43 78.9603 1359.43 85.8386C1359.43 92.7169 1353.83 98.3119 1346.95 98.3119ZM1346.95 74.5384C1340.72 74.5384 1335.65 79.6079 1335.65 85.8386C1335.65 92.0692 1340.72 97.1387 1346.95 97.1387C1353.18 97.1387 1358.25 92.0692 1358.25 85.8386C1358.25 79.6079 1353.19 74.5384 1346.95 74.5384Z"
              fill="#5956D9" />
            <path
              d="M1378.31 98.3119C1371.44 98.3119 1365.84 92.716 1365.84 85.8386C1365.84 78.9603 1371.44 73.3652 1378.31 73.3652C1385.19 73.3652 1390.79 78.9603 1390.79 85.8386C1390.79 92.7169 1385.19 98.3119 1378.31 98.3119ZM1378.31 74.5384C1372.08 74.5384 1367.01 79.6079 1367.01 85.8386C1367.01 92.0692 1372.08 97.1387 1378.31 97.1387C1384.54 97.1387 1389.61 92.0692 1389.61 85.8386C1389.61 79.6079 1384.54 74.5384 1378.31 74.5384Z"
              fill="#5956D9" />
            <path
              d="M1409.67 98.3119C1402.8 98.3119 1397.2 92.716 1397.2 85.8386C1397.2 78.9603 1402.8 73.3652 1409.67 73.3652C1416.55 73.3652 1422.15 78.9603 1422.15 85.8386C1422.15 92.7169 1416.55 98.3119 1409.67 98.3119ZM1409.67 74.5384C1403.44 74.5384 1398.37 79.6079 1398.37 85.8386C1398.37 92.0692 1403.44 97.1387 1409.67 97.1387C1415.9 97.1387 1420.97 92.0692 1420.97 85.8386C1420.97 79.6079 1415.9 74.5384 1409.67 74.5384Z"
              fill="#5956D9" />
            <path
              d="M1441.03 98.3119C1434.15 98.3119 1428.56 92.716 1428.56 85.8386C1428.56 78.9603 1434.15 73.3652 1441.03 73.3652C1447.91 73.3652 1453.51 78.9603 1453.51 85.8386C1453.51 92.7169 1447.91 98.3119 1441.03 98.3119ZM1441.03 74.5384C1434.8 74.5384 1429.73 79.6079 1429.73 85.8386C1429.73 92.0692 1434.8 97.1387 1441.03 97.1387C1447.26 97.1387 1452.33 92.0692 1452.33 85.8386C1452.33 79.6079 1447.26 74.5384 1441.03 74.5384Z"
              fill="#5956D9" />
            <path
              d="M939.255 132.523C932.377 132.523 926.781 126.927 926.781 120.05C926.781 113.171 932.377 107.576 939.255 107.576C946.132 107.576 951.728 113.171 951.728 120.05C951.728 126.928 946.132 132.523 939.255 132.523ZM939.255 108.749C933.024 108.749 927.954 113.819 927.954 120.05C927.954 126.28 933.024 131.35 939.255 131.35C945.485 131.35 950.555 126.28 950.555 120.05C950.555 113.819 945.486 108.749 939.255 108.749Z"
              fill="#5956D9" />
            <path
              d="M970.618 132.523C963.74 132.523 958.145 126.927 958.145 120.05C958.145 113.171 963.74 107.576 970.618 107.576C977.495 107.576 983.091 113.171 983.091 120.05C983.091 126.928 977.495 132.523 970.618 132.523ZM970.618 108.749C964.387 108.749 959.318 113.819 959.318 120.05C959.318 126.28 964.387 131.35 970.618 131.35C976.849 131.35 981.918 126.28 981.918 120.05C981.918 113.819 976.849 108.749 970.618 108.749Z"
              fill="#5956D9" />
            <path
              d="M1001.98 132.523C995.1 132.523 989.504 126.927 989.504 120.05C989.504 113.171 995.1 107.576 1001.98 107.576C1008.85 107.576 1014.45 113.171 1014.45 120.05C1014.45 126.928 1008.85 132.523 1001.98 132.523ZM1001.98 108.749C995.747 108.749 990.677 113.819 990.677 120.05C990.677 126.28 995.747 131.35 1001.98 131.35C1008.21 131.35 1013.28 126.28 1013.28 120.05C1013.28 113.819 1008.21 108.749 1001.98 108.749Z"
              fill="#5956D9" />
            <path
              d="M1033.34 132.523C1026.46 132.523 1020.87 126.927 1020.87 120.05C1020.87 113.171 1026.46 107.576 1033.34 107.576C1040.22 107.576 1045.81 113.171 1045.81 120.05C1045.81 126.928 1040.22 132.523 1033.34 132.523ZM1033.34 108.749C1027.11 108.749 1022.04 113.819 1022.04 120.05C1022.04 126.28 1027.11 131.35 1033.34 131.35C1039.57 131.35 1044.64 126.28 1044.64 120.05C1044.64 113.819 1039.57 108.749 1033.34 108.749Z"
              fill="#5956D9" />
            <path
              d="M1064.7 132.523C1057.82 132.523 1052.23 126.927 1052.23 120.05C1052.23 113.171 1057.82 107.576 1064.7 107.576C1071.58 107.576 1077.17 113.171 1077.17 120.05C1077.17 126.928 1071.58 132.523 1064.7 132.523ZM1064.7 108.749C1058.47 108.749 1053.4 113.819 1053.4 120.05C1053.4 126.28 1058.47 131.35 1064.7 131.35C1070.93 131.35 1076 126.28 1076 120.05C1076 113.819 1070.93 108.749 1064.7 108.749Z"
              fill="#5956D9" />
            <path
              d="M1096.06 132.523C1089.19 132.523 1083.59 126.927 1083.59 120.05C1083.59 113.171 1089.19 107.576 1096.06 107.576C1102.94 107.576 1108.54 113.171 1108.54 120.05C1108.54 126.928 1102.94 132.523 1096.06 132.523ZM1096.06 108.749C1089.83 108.749 1084.76 113.819 1084.76 120.05C1084.76 126.28 1089.83 131.35 1096.06 131.35C1102.29 131.35 1107.36 126.28 1107.36 120.05C1107.36 113.819 1102.29 108.749 1096.06 108.749Z"
              fill="#5956D9" />
            <path
              d="M1127.43 132.523C1120.55 132.523 1114.95 126.927 1114.95 120.05C1114.95 113.171 1120.55 107.576 1127.43 107.576C1134.3 107.576 1139.9 113.171 1139.9 120.05C1139.9 126.928 1134.3 132.523 1127.43 132.523ZM1127.43 108.749C1121.2 108.749 1116.13 113.819 1116.13 120.05C1116.13 126.28 1121.2 131.35 1127.43 131.35C1133.66 131.35 1138.73 126.28 1138.73 120.05C1138.73 113.819 1133.66 108.749 1127.43 108.749Z"
              fill="#5956D9" />
            <path
              d="M1158.79 132.523C1151.91 132.523 1146.31 126.927 1146.31 120.05C1146.31 113.171 1151.91 107.576 1158.79 107.576C1165.66 107.576 1171.26 113.171 1171.26 120.05C1171.26 126.928 1165.66 132.523 1158.79 132.523ZM1158.79 108.749C1152.56 108.749 1147.49 113.819 1147.49 120.05C1147.49 126.28 1152.56 131.35 1158.79 131.35C1165.02 131.35 1170.09 126.28 1170.09 120.05C1170.09 113.819 1165.02 108.749 1158.79 108.749Z"
              fill="#5956D9" />
            <path
              d="M1190.15 132.523C1183.27 132.523 1177.68 126.927 1177.68 120.05C1177.68 113.171 1183.27 107.576 1190.15 107.576C1197.03 107.576 1202.62 113.171 1202.62 120.05C1202.62 126.928 1197.03 132.523 1190.15 132.523ZM1190.15 108.749C1183.92 108.749 1178.85 113.819 1178.85 120.05C1178.85 126.28 1183.92 131.35 1190.15 131.35C1196.38 131.35 1201.45 126.28 1201.45 120.05C1201.45 113.819 1196.38 108.749 1190.15 108.749Z"
              fill="#5956D9" />
            <path
              d="M1221.51 132.523C1214.63 132.523 1209.04 126.927 1209.04 120.05C1209.04 113.171 1214.63 107.576 1221.51 107.576C1228.39 107.576 1233.98 113.171 1233.98 120.05C1233.98 126.928 1228.39 132.523 1221.51 132.523ZM1221.51 108.749C1215.28 108.749 1210.21 113.819 1210.21 120.05C1210.21 126.28 1215.28 131.35 1221.51 131.35C1227.74 131.35 1232.81 126.28 1232.81 120.05C1232.81 113.819 1227.74 108.749 1221.51 108.749Z"
              fill="#5956D9" />
            <path
              d="M1252.87 132.523C1245.99 132.523 1240.4 126.927 1240.4 120.05C1240.4 113.171 1245.99 107.576 1252.87 107.576C1259.75 107.576 1265.35 113.171 1265.35 120.05C1265.35 126.928 1259.75 132.523 1252.87 132.523ZM1252.87 108.749C1246.64 108.749 1241.57 113.819 1241.57 120.05C1241.57 126.28 1246.64 131.35 1252.87 131.35C1259.1 131.35 1264.17 126.28 1264.17 120.05C1264.17 113.819 1259.1 108.749 1252.87 108.749Z"
              fill="#5956D9" />
            <path
              d="M1284.23 132.523C1277.35 132.523 1271.76 126.927 1271.76 120.05C1271.76 113.171 1277.35 107.576 1284.23 107.576C1291.11 107.576 1296.7 113.171 1296.7 120.05C1296.7 126.928 1291.11 132.523 1284.23 132.523ZM1284.23 108.749C1278 108.749 1272.93 113.819 1272.93 120.05C1272.93 126.28 1278 131.35 1284.23 131.35C1290.46 131.35 1295.53 126.28 1295.53 120.05C1295.53 113.819 1290.46 108.749 1284.23 108.749Z"
              fill="#5956D9" />
            <path
              d="M1315.59 132.523C1308.72 132.523 1303.12 126.927 1303.12 120.05C1303.12 113.171 1308.72 107.576 1315.59 107.576C1322.47 107.576 1328.07 113.171 1328.07 120.05C1328.07 126.928 1322.47 132.523 1315.59 132.523ZM1315.59 108.749C1309.36 108.749 1304.29 113.819 1304.29 120.05C1304.29 126.28 1309.36 131.35 1315.59 131.35C1321.83 131.35 1326.89 126.28 1326.89 120.05C1326.89 113.819 1321.83 108.749 1315.59 108.749Z"
              fill="#5956D9" />
            <path
              d="M1346.95 132.523C1340.08 132.523 1334.48 126.927 1334.48 120.05C1334.48 113.171 1340.08 107.576 1346.95 107.576C1353.83 107.576 1359.43 113.171 1359.43 120.05C1359.43 126.928 1353.83 132.523 1346.95 132.523ZM1346.95 108.749C1340.72 108.749 1335.65 113.819 1335.65 120.05C1335.65 126.28 1340.72 131.35 1346.95 131.35C1353.18 131.35 1358.25 126.28 1358.25 120.05C1358.25 113.819 1353.19 108.749 1346.95 108.749Z"
              fill="#5956D9" />
            <path
              d="M1378.31 132.523C1371.44 132.523 1365.84 126.927 1365.84 120.05C1365.84 113.171 1371.44 107.576 1378.31 107.576C1385.19 107.576 1390.79 113.171 1390.79 120.05C1390.79 126.928 1385.19 132.523 1378.31 132.523ZM1378.31 108.749C1372.08 108.749 1367.01 113.819 1367.01 120.05C1367.01 126.28 1372.08 131.35 1378.31 131.35C1384.54 131.35 1389.61 126.28 1389.61 120.05C1389.61 113.819 1384.54 108.749 1378.31 108.749Z"
              fill="#5956D9" />
            <path
              d="M1409.67 132.523C1402.8 132.523 1397.2 126.927 1397.2 120.05C1397.2 113.171 1402.8 107.576 1409.67 107.576C1416.55 107.576 1422.15 113.171 1422.15 120.05C1422.15 126.928 1416.55 132.523 1409.67 132.523ZM1409.67 108.749C1403.44 108.749 1398.37 113.819 1398.37 120.05C1398.37 126.28 1403.44 131.35 1409.67 131.35C1415.9 131.35 1420.97 126.28 1420.97 120.05C1420.97 113.819 1415.9 108.749 1409.67 108.749Z"
              fill="#5956D9" />
            <path
              d="M1441.03 132.523C1434.15 132.523 1428.56 126.927 1428.56 120.05C1428.56 113.171 1434.15 107.576 1441.03 107.576C1447.91 107.576 1453.51 113.171 1453.51 120.05C1453.51 126.928 1447.91 132.523 1441.03 132.523ZM1441.03 108.749C1434.8 108.749 1429.73 113.819 1429.73 120.05C1429.73 126.28 1434.8 131.35 1441.03 131.35C1447.26 131.35 1452.33 126.28 1452.33 120.05C1452.33 113.819 1447.26 108.749 1441.03 108.749Z"
              fill="#5956D9" />
            <path
              d="M939.255 166.738C932.377 166.738 926.781 161.142 926.781 154.264C926.781 147.386 932.377 141.791 939.255 141.791C946.132 141.791 951.728 147.386 951.728 154.264C951.728 161.143 946.132 166.738 939.255 166.738ZM939.255 142.964C933.024 142.964 927.954 148.034 927.954 154.264C927.954 160.495 933.024 165.564 939.255 165.564C945.485 165.564 950.555 160.495 950.555 154.264C950.555 148.034 945.486 142.964 939.255 142.964Z"
              fill="#5956D9" />
            <path
              d="M970.618 166.738C963.74 166.738 958.145 161.142 958.145 154.264C958.145 147.386 963.74 141.791 970.618 141.791C977.495 141.791 983.091 147.386 983.091 154.264C983.091 161.143 977.495 166.738 970.618 166.738ZM970.618 142.964C964.387 142.964 959.318 148.034 959.318 154.264C959.318 160.495 964.387 165.564 970.618 165.564C976.849 165.564 981.918 160.495 981.918 154.264C981.918 148.034 976.849 142.964 970.618 142.964Z"
              fill="#5956D9" />
            <path
              d="M1001.98 166.738C995.1 166.738 989.504 161.142 989.504 154.264C989.504 147.386 995.1 141.791 1001.98 141.791C1008.85 141.791 1014.45 147.386 1014.45 154.264C1014.45 161.143 1008.85 166.738 1001.98 166.738ZM1001.98 142.964C995.747 142.964 990.677 148.034 990.677 154.264C990.677 160.495 995.747 165.564 1001.98 165.564C1008.21 165.564 1013.28 160.495 1013.28 154.264C1013.28 148.034 1008.21 142.964 1001.98 142.964Z"
              fill="#5956D9" />
            <path
              d="M1033.34 166.738C1026.46 166.738 1020.87 161.142 1020.87 154.264C1020.87 147.386 1026.46 141.791 1033.34 141.791C1040.22 141.791 1045.81 147.386 1045.81 154.264C1045.81 161.143 1040.22 166.738 1033.34 166.738ZM1033.34 142.964C1027.11 142.964 1022.04 148.034 1022.04 154.264C1022.04 160.495 1027.11 165.564 1033.34 165.564C1039.57 165.564 1044.64 160.495 1044.64 154.264C1044.64 148.034 1039.57 142.964 1033.34 142.964Z"
              fill="#5956D9" />
            <path
              d="M1064.7 166.738C1057.82 166.738 1052.23 161.142 1052.23 154.264C1052.23 147.386 1057.82 141.791 1064.7 141.791C1071.58 141.791 1077.17 147.386 1077.17 154.264C1077.17 161.143 1071.58 166.738 1064.7 166.738ZM1064.7 142.964C1058.47 142.964 1053.4 148.034 1053.4 154.264C1053.4 160.495 1058.47 165.564 1064.7 165.564C1070.93 165.564 1076 160.495 1076 154.264C1076 148.034 1070.93 142.964 1064.7 142.964Z"
              fill="#5956D9" />
            <path
              d="M1096.06 166.738C1089.19 166.738 1083.59 161.142 1083.59 154.264C1083.59 147.386 1089.19 141.791 1096.06 141.791C1102.94 141.791 1108.54 147.386 1108.54 154.264C1108.54 161.143 1102.94 166.738 1096.06 166.738ZM1096.06 142.964C1089.83 142.964 1084.76 148.034 1084.76 154.264C1084.76 160.495 1089.83 165.564 1096.06 165.564C1102.29 165.564 1107.36 160.495 1107.36 154.264C1107.36 148.034 1102.29 142.964 1096.06 142.964Z"
              fill="#5956D9" />
            <path
              d="M1127.43 166.738C1120.55 166.738 1114.95 161.142 1114.95 154.264C1114.95 147.386 1120.55 141.791 1127.43 141.791C1134.3 141.791 1139.9 147.386 1139.9 154.264C1139.9 161.143 1134.3 166.738 1127.43 166.738ZM1127.43 142.964C1121.2 142.964 1116.13 148.034 1116.13 154.264C1116.13 160.495 1121.2 165.564 1127.43 165.564C1133.66 165.564 1138.73 160.495 1138.73 154.264C1138.73 148.034 1133.66 142.964 1127.43 142.964Z"
              fill="#5956D9" />
            <path
              d="M1158.79 166.738C1151.91 166.738 1146.31 161.142 1146.31 154.264C1146.31 147.386 1151.91 141.791 1158.79 141.791C1165.66 141.791 1171.26 147.386 1171.26 154.264C1171.26 161.143 1165.66 166.738 1158.79 166.738ZM1158.79 142.964C1152.56 142.964 1147.49 148.034 1147.49 154.264C1147.49 160.495 1152.56 165.564 1158.79 165.564C1165.02 165.564 1170.09 160.495 1170.09 154.264C1170.09 148.034 1165.02 142.964 1158.79 142.964Z"
              fill="#5956D9" />
            <path
              d="M1190.15 166.738C1183.27 166.738 1177.68 161.142 1177.68 154.264C1177.68 147.386 1183.27 141.791 1190.15 141.791C1197.03 141.791 1202.62 147.386 1202.62 154.264C1202.62 161.143 1197.03 166.738 1190.15 166.738ZM1190.15 142.964C1183.92 142.964 1178.85 148.034 1178.85 154.264C1178.85 160.495 1183.92 165.564 1190.15 165.564C1196.38 165.564 1201.45 160.495 1201.45 154.264C1201.45 148.034 1196.38 142.964 1190.15 142.964Z"
              fill="#5956D9" />
            <path
              d="M1221.51 166.738C1214.63 166.738 1209.04 161.142 1209.04 154.264C1209.04 147.386 1214.63 141.791 1221.51 141.791C1228.39 141.791 1233.98 147.386 1233.98 154.264C1233.98 161.143 1228.39 166.738 1221.51 166.738ZM1221.51 142.964C1215.28 142.964 1210.21 148.034 1210.21 154.264C1210.21 160.495 1215.28 165.564 1221.51 165.564C1227.74 165.564 1232.81 160.495 1232.81 154.264C1232.81 148.034 1227.74 142.964 1221.51 142.964Z"
              fill="#5956D9" />
            <path
              d="M1252.87 166.738C1245.99 166.738 1240.4 161.142 1240.4 154.264C1240.4 147.386 1245.99 141.791 1252.87 141.791C1259.75 141.791 1265.35 147.386 1265.35 154.264C1265.35 161.143 1259.75 166.738 1252.87 166.738ZM1252.87 142.964C1246.64 142.964 1241.57 148.034 1241.57 154.264C1241.57 160.495 1246.64 165.564 1252.87 165.564C1259.1 165.564 1264.17 160.495 1264.17 154.264C1264.17 148.034 1259.1 142.964 1252.87 142.964Z"
              fill="#5956D9" />
            <path
              d="M1284.23 166.738C1277.35 166.738 1271.76 161.142 1271.76 154.264C1271.76 147.386 1277.35 141.791 1284.23 141.791C1291.11 141.791 1296.7 147.386 1296.7 154.264C1296.7 161.143 1291.11 166.738 1284.23 166.738ZM1284.23 142.964C1278 142.964 1272.93 148.034 1272.93 154.264C1272.93 160.495 1278 165.564 1284.23 165.564C1290.46 165.564 1295.53 160.495 1295.53 154.264C1295.53 148.034 1290.46 142.964 1284.23 142.964Z"
              fill="#5956D9" />
            <path
              d="M1315.59 166.738C1308.72 166.738 1303.12 161.142 1303.12 154.264C1303.12 147.386 1308.72 141.791 1315.59 141.791C1322.47 141.791 1328.07 147.386 1328.07 154.264C1328.07 161.143 1322.47 166.738 1315.59 166.738ZM1315.59 142.964C1309.36 142.964 1304.29 148.034 1304.29 154.264C1304.29 160.495 1309.36 165.564 1315.59 165.564C1321.83 165.564 1326.89 160.495 1326.89 154.264C1326.89 148.034 1321.83 142.964 1315.59 142.964Z"
              fill="#5956D9" />
            <path
              d="M1346.95 166.738C1340.08 166.738 1334.48 161.142 1334.48 154.264C1334.48 147.386 1340.08 141.791 1346.95 141.791C1353.83 141.791 1359.43 147.386 1359.43 154.264C1359.43 161.143 1353.83 166.738 1346.95 166.738ZM1346.95 142.964C1340.72 142.964 1335.65 148.034 1335.65 154.264C1335.65 160.495 1340.72 165.564 1346.95 165.564C1353.18 165.564 1358.25 160.495 1358.25 154.264C1358.25 148.034 1353.19 142.964 1346.95 142.964Z"
              fill="#5956D9" />
            <path
              d="M1378.31 166.738C1371.44 166.738 1365.84 161.142 1365.84 154.264C1365.84 147.386 1371.44 141.791 1378.31 141.791C1385.19 141.791 1390.79 147.386 1390.79 154.264C1390.79 161.143 1385.19 166.738 1378.31 166.738ZM1378.31 142.964C1372.08 142.964 1367.01 148.034 1367.01 154.264C1367.01 160.495 1372.08 165.564 1378.31 165.564C1384.54 165.564 1389.61 160.495 1389.61 154.264C1389.61 148.034 1384.54 142.964 1378.31 142.964Z"
              fill="#5956D9" />
            <path
              d="M1409.67 166.738C1402.8 166.738 1397.2 161.142 1397.2 154.264C1397.2 147.386 1402.8 141.791 1409.67 141.791C1416.55 141.791 1422.15 147.386 1422.15 154.264C1422.15 161.143 1416.55 166.738 1409.67 166.738ZM1409.67 142.964C1403.44 142.964 1398.37 148.034 1398.37 154.264C1398.37 160.495 1403.44 165.564 1409.67 165.564C1415.9 165.564 1420.97 160.495 1420.97 154.264C1420.97 148.034 1415.9 142.964 1409.67 142.964Z"
              fill="#5956D9" />
            <path
              d="M1441.03 166.738C1434.15 166.738 1428.56 161.142 1428.56 154.264C1428.56 147.386 1434.15 141.791 1441.03 141.791C1447.91 141.791 1453.51 147.386 1453.51 154.264C1453.51 161.143 1447.91 166.738 1441.03 166.738ZM1441.03 142.964C1434.8 142.964 1429.73 148.034 1429.73 154.264C1429.73 160.495 1434.8 165.564 1441.03 165.564C1447.26 165.564 1452.33 160.495 1452.33 154.264C1452.33 148.034 1447.26 142.964 1441.03 142.964Z"
              fill="#5956D9" />
            <rect y="1.5" width="1447" height="175" fill="url(#paint0_linear_1630_48634)" />
          </g>
          <defs>
            <linearGradient id="paint0_linear_1630_48634" x1="683.835" y1="1.49999" x2="683.835" y2="166.206"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="white" stop-opacity="0" />
              <stop offset="1" stop-color="white" />
            </linearGradient>
            <clipPath id="clip0_1630_48634">
              <rect width="175" height="1447" fill="white" transform="translate(0 175.5) rotate(-90)" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </header>
    <main>
      <div class="left-section">
        <div class="profile-card" style="min-width: 300px; max-width: 100%">
          <div class="profile-image">
          </div>
          <div class="profile-content" style="width: 100%">
            <h1 style="
                font-size: 18px !important;
                width: 100%;
                line-height: 1.4rem;
              ">
              {{ userProfile.fullname }}
            </h1>
            <div style="gap: 15px">
              <div style="display: flex; flex-direction: column">
                <p style="font-size: 8px !important; margin-bottom: 2px">
                  <strong>Especialidad</strong>
                </p>
                <p style="
                    margin-top: 0;
                    font-size: 11px !important;
                    word-break: break-word;
                    overflow-wrap: break-word;
                  ">
                  {{ userProfile.speciality ? userProfile.speciality : null }}
                </p>
              </div>
              <div style="display: flex; flex-direction: column">
                <p style="font-size: 8px !important; margin-bottom: 2px">
                  <strong>Experiencia</strong>
                </p>
                <p style="font-size: 13px !important; margin-top: 0">
                  {{ userProfile?.yearsOfExp ? userProfile?.yearsOfExp : null }}
                  Años
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="contact-info !block !space-y-3 mb-6">
          <div class="contact-item">
            <svg width="15" height="12" viewbox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.56617 11.5285C1.20174 11.5285 0.897474 11.4065 0.653377 11.1624C0.40928 10.9183 0.287231 10.614 0.287231 10.2496V1.72413C0.287231 1.3597 0.40928 1.05543 0.653377 0.811336C0.897474 0.567239 1.20174 0.44519 1.56617 0.44519H13.2583C13.6227 0.44519 13.927 0.567239 14.1711 0.811336C14.4152 1.05543 14.5372 1.3597 14.5372 1.72413V10.2496C14.5372 10.614 14.4152 10.9183 14.1711 11.1624C13.927 11.4065 13.6227 11.5285 13.2583 11.5285H1.56617ZM7.41223 6.07829L1.0789 1.93709V10.2496C1.0789 10.3917 1.12455 10.5085 1.21586 10.5999C1.30729 10.6912 1.42406 10.7369 1.56617 10.7369H13.2583C13.4004 10.7369 13.5172 10.6912 13.6086 10.5999C13.6999 10.5085 13.7456 10.3917 13.7456 10.2496V1.93709L7.41223 6.07829ZM7.41223 5.19519L13.5019 1.23686H1.32253L7.41223 5.19519ZM1.0789 1.93709V1.23686V10.2496C1.0789 10.3917 1.12455 10.5085 1.21586 10.5999C1.30729 10.6912 1.42406 10.7369 1.56617 10.7369H1.0789V1.93709Z"
                fill="#5956d9" />
            </svg>
            <span style="font-size: 10px !important; min-width: 120px">{{
              userProfile.email ? userProfile.email : null
              }}</span>
          </div>
          <div class="contact-item" v-if="userProfile.country || userProfile.city">
            <svg width="11" height="16" viewbox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.41223 12.5318C6.85042 11.3951 7.93567 10.3056 8.66796 9.2632C9.40025 8.22084 9.76639 7.20995 9.76639 6.23053C9.76639 5.42012 9.61849 4.72906 9.32267 4.15735C9.02671 3.58563 8.658 3.12297 8.21651 2.76936C7.77502 2.41562 7.30187 2.15852 6.79705 1.99808C6.2921 1.83777 5.83049 1.75761 5.41223 1.75761C4.99396 1.75761 4.53236 1.83777 4.02741 1.99808C3.52259 2.15852 3.04943 2.41562 2.60795 2.76936C2.16646 3.12297 1.79774 3.58563 1.50179 4.15735C1.20597 4.72906 1.05806 5.42012 1.05806 6.23053C1.05806 7.20995 1.42421 8.22084 2.1565 9.2632C2.88879 10.3056 3.97403 11.3951 5.41223 12.5318ZM5.41223 13.5412C3.68376 12.2401 2.394 10.9836 1.54296 9.77165C0.691916 8.55987 0.266396 7.37943 0.266396 6.23033C0.266396 5.3624 0.421958 4.60141 0.733083 3.94736C1.04408 3.29344 1.44486 2.74416 1.93543 2.29951C2.426 1.85498 2.97785 1.52163 3.591 1.29943C4.20428 1.07711 4.81135 0.965942 5.41223 0.965942C6.0131 0.965942 6.62018 1.07711 7.23346 1.29943C7.8466 1.52163 8.39846 1.85498 8.88903 2.29951C9.3796 2.74416 9.78038 3.29344 10.0914 3.94736C10.4025 4.60141 10.5581 5.3624 10.5581 6.23033C10.5581 7.37943 10.1325 8.55987 9.2815 9.77165C8.43046 10.9836 7.1407 12.2401 5.41223 13.5412ZM5.41223 7.29928C5.74618 7.29928 6.02755 7.18481 6.25634 6.95589C6.48527 6.7271 6.59973 6.44573 6.59973 6.11178C6.59973 5.77782 6.48527 5.49645 6.25634 5.26766C6.02755 5.03874 5.74618 4.92428 5.41223 4.92428C5.07828 4.92428 4.79691 5.03874 4.56811 5.26766C4.33919 5.49645 4.22473 5.77782 4.22473 6.11178C4.22473 6.44573 4.33919 6.7271 4.56811 6.95589C4.79691 7.18481 5.07828 7.29928 5.41223 7.29928ZM0.266396 16.0076V15.2159H10.5581V16.0076H0.266396Z"
                fill="#5956d9" />
            </svg>
            <span style="
                font-size: 8px !important;
                word-wrap: break-word;
                min-width: 100px;
              ">{{ userProfile.city ? userProfile.city + "," : null }} {{ userProfile.country ? userProfile.country :
              null }}
            </span>
          </div>
          <div class="contact-item">
            <svg width="13" height="13" viewbox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.8991 12.8201C10.5645 12.8201 9.19633 12.486 7.79468 11.8177C6.39303 11.1492 5.09081 10.2111 3.888 9.00329C2.68533 7.7956 1.74978 6.49344 1.08135 5.09681C0.413048 3.70018 0.0788984 2.33455 0.0788984 0.999932C0.0788984 0.758078 0.158065 0.556532 0.316398 0.395296C0.474732 0.23406 0.672648 0.153442 0.910148 0.153442H2.86794C3.08314 0.153442 3.2709 0.221196 3.43121 0.356703C3.59166 0.49221 3.70031 0.666046 3.75718 0.878213L4.14985 2.76594C4.1864 2.98721 4.1798 3.18058 4.13005 3.34604C4.08031 3.51149 3.9925 3.647 3.86663 3.75256L2.12971 5.37231C2.45443 5.96197 2.81345 6.5098 3.20677 7.01581C3.6001 7.52181 4.01955 8.0011 4.46513 8.45367C4.92482 8.9135 5.41961 9.34106 5.9495 9.73637C6.47926 10.1317 7.0608 10.5049 7.69414 10.8562L9.38712 9.13272C9.51603 8.99365 9.6609 8.90208 9.82174 8.85802C9.98258 8.81381 10.1604 8.80491 10.3553 8.8313L12.0208 9.17231C12.236 9.22508 12.4106 9.33348 12.5447 9.49748C12.6786 9.66136 12.7456 9.84938 12.7456 10.0615V11.9889C12.7456 12.2264 12.6649 12.4243 12.5037 12.5826C12.3425 12.7409 12.1409 12.8201 11.8991 12.8201ZM1.75822 4.62933L3.27763 3.23184C3.32829 3.1912 3.36128 3.13539 3.37659 3.0644C3.39176 2.99328 3.38919 2.92731 3.36887 2.86648L3.01717 1.18874C2.99685 1.10747 2.96136 1.04657 2.91069 1.00607C2.85989 0.965429 2.79392 0.945109 2.71277 0.945109H1.08827C1.02732 0.945109 0.976582 0.965429 0.936075 1.00607C0.895437 1.04657 0.875117 1.09731 0.875117 1.15827C0.890291 1.69924 0.975065 2.26403 1.12944 2.85263C1.28368 3.44137 1.49328 4.0336 1.75822 4.62933ZM8.4478 11.2277C8.98257 11.4926 9.55271 11.6885 10.1582 11.8153C10.7636 11.9422 11.2911 12.0102 11.7407 12.0193C11.8017 12.0193 11.8524 11.999 11.8929 11.9584C11.9336 11.9177 11.9539 11.867 11.9539 11.8062V10.2167C11.9539 10.1356 11.9336 10.0696 11.8929 10.0188C11.8524 9.96813 11.7915 9.93264 11.7103 9.91232L10.2457 9.61228C10.1849 9.59196 10.1316 9.58945 10.086 9.60476C10.0402 9.61993 9.99195 9.65292 9.94128 9.70371L8.4478 11.2277Z"
                fill="#5956d9" />
            </svg>
            <span style="font-size: 10px !important">{{
              userProfile.phonenumber ? userProfile.phonenumber : null
              }}</span>
          </div>
        </div>
        <div class="dark-box">
          <p style="
              font-size: 9px !important;
              padding: 5px 15px;
              margin-top: 0.7rem;
              margin-bottom: 0.7rem;
            ">
            {{ userProfile?.about ? userProfile.about : null }}
          </p>
          <p style="
              font-size: 10px !important;
              padding-left: 15px;
              margin-top: 0.7rem;
              margin-bottom: 0.7rem;
            " v-if="userProfile?.minSalary">
            <strong>{{ $t('profile.desired_salary') }}</strong>
          </p>
          <h3 v-if="userProfile?.minSalary" style="
              padding-left: 12px;
              padding-bottom: 15px;
              font-weight: bold !important;
              font-size: 14px !important;
            ">
            ${{ userProfile.minSalary ? userProfile.minSalary : null }} USD {{ userProfile?.openToNegotiate ?
            'negociable' : 'no negociable' }}
          </h3>
          <p style="
              font-size: 10px !important;
              padding-left: 15px;
              margin-top: 0.7rem;
              margin-bottom: 0.7rem;
            " v-if="userProfile?.realSalary">
            <strong>{{ $t('profile.current_salary') }}</strong>
          </p>
          <h3 v-if="userProfile?.realSalary" style="
              padding-left: 12px;
              padding-bottom: 15px;
              font-weight: bold !important;
              font-size: 14px !important;
            ">
            ${{ userProfile.realSalary ? userProfile.realSalary : null }} USD
          </h3>
        </div>
        <h2 style="
            padding-left: 13px;
            font-size: 20px !important;
            font-weight: bold;
          ">
          {{ $t('profile.languages') }}
        </h2>
        <div class="dark-box" style="padding-left: 20px; padding-right: 20px">
          <div class="languages" style="margin-top: 20px; margin-bottom: 20px !important">
            <div class="language-item" style="margin-left: 0px">
              <p style="
                  font-size: 12px;
                  padding-left: 0px;
                  margin-top: 20px !important;
                  font-weight: bold !important;
                  margin-top: 0rem !important;
                  margin-bottom: 0rem;
                ">
                {{ $t('profile.english') }}
              </p>
              <div class="flex">
                <span v-for="star in 5" :key="star" class="mr-1">
                  <svg v-if="star <= this.englishLevel" width="18" height="16" viewBox="0 0 22 20" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.3206 0.369775C10.4702 -0.09088 11.1219 -0.09088 11.2716 0.369775L13.3813 6.8628C13.4483 7.06882 13.6402 7.20829 13.8569 7.20829H20.684C21.1684 7.20829 21.3698 7.8281 20.9779 8.1128L15.4546 12.1257C15.2794 12.253 15.206 12.4787 15.273 12.6847L17.3827 19.1778C17.5324 19.6384 17.0051 20.0215 16.6133 19.7368L11.09 15.7239C10.9147 15.5965 10.6774 15.5965 10.5022 15.7239L4.97889 19.7368C4.58703 20.0215 4.05979 19.6384 4.20947 19.1778L6.31918 12.6847C6.38612 12.4787 6.31279 12.253 6.13755 12.1257L0.614247 8.1128C0.22239 7.8281 0.423778 7.20829 0.90814 7.20829H7.73531C7.95193 7.20829 8.1439 7.06881 8.21084 6.8628L10.3206 0.369775Z"
                      fill="#5956d9" />
                  </svg>
                  <svg v-else width="18" height="16" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.5862 0.369775C10.7359 -0.09088 11.3876 -0.09088 11.5372 0.369775L13.6469 6.8628C13.7139 7.06882 13.9059 7.20829 14.1225 7.20829H20.9496C21.434 7.20829 21.6354 7.8281 21.2435 8.1128L15.7202 12.1257C15.545 12.253 15.4717 12.4787 15.5386 12.6847L17.6483 19.1778C17.798 19.6384 17.2708 20.0215 16.8789 19.7368L11.3556 15.7239C11.1804 15.5965 10.9431 15.5965 10.7678 15.7239L5.24452 19.7368C4.85266 20.0215 4.32542 19.6384 4.47509 19.1778L6.58481 12.6847C6.65174 12.4787 6.57841 12.253 6.40317 12.1257L0.879872 8.1128C0.488015 7.8281 0.689403 7.20829 1.17376 7.20829H8.00094C8.21755 7.20829 8.40953 7.06881 8.47647 6.8628L10.5862 0.369775Z"
                      fill="#333333" />
                  </svg>
                </span>
              </div>
              <span class="level">{{
                englishLevelDescription(this.englishLevel)
                }}</span>
            </div>
          </div>
        </div>
        <h2 style="
            padding-left: 13px;
            font-size: 20px !important;
            font-weight: bold;
          ">
          {{ $t('profile.preferences') }}
        </h2>
        <div class="dark-box">
          <p style="
              padding-left: 14px;
              font-size: 10px !important;
              margin-top: 13px !important;
              margin-bottom: 8px;
            ">
            {{ $t('profile.people_in_charge') }}
          </p>
          <div class="pill !px-2 !py-1">{{ this.personsInCharge }}</div>
          <template v-if="this.jobTypePreference?.length > 0">
            <p style="
                padding-left: 14px;
                margin-top: 13px !important;
                margin-bottom: 8px;
                font-size: 10px !important;
              ">
              {{ $t('profile.work_modality') }}
            </p>
            <div class="pill !px-2 !py-1" v-for="preference in this.jobTypePreference" :key="preference">
              {{ preference }}
            </div>
          </template>
          <div class="pill-section" v-if="industries?.length > 0">
            <p style="
                padding-left: 14px;
                margin-top: 13px !important;
                margin-bottom: 8px;
                font-size: 10px !important;
              ">
              {{ $t('profile.companies_looking') }}
            </p>
            <div v-for="industry in industries" :key="industry" class="pill !px-2 !py-1">
              {{ industry }}
            </div>
          </div>
        </div>
        <section>
          <h2 style="
              padding-left: 15px;
              font-size: 18px !important;
              font-weight: bold;
            ">
            {{ $t('profile.social_networks') }}
          </h2>
          <div class="dark-box">
            <!-- LinkedIn -->
            <div class="link-with-icon" style="margin-top: 15px" v-if="userProfile?.socialNetworks?.linkedin">
              <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20.9886 15.657C22.3369 13.8298 23.9158 12.6589 25.9382 12.2686C29.2379 11.6477 32.3957 11.8783 34.8793 14.4862C36.4937 16.1715 36.955 18.3713 37.1146 20.6066C37.4872 25.7335 37.2033 30.8783 37.292 36.023C37.3098 36.8213 36.9727 37.0519 36.2276 37.0519C34.3294 37.0342 32.4489 37.0164 30.5507 37.0519C29.6814 37.0696 29.4331 36.7326 29.4331 35.8988C29.4508 32.0846 29.4508 28.2704 29.4331 24.4562C29.4331 23.516 29.4331 22.5758 29.2202 21.6355C28.8299 19.9324 27.8542 18.9567 26.3462 18.8148C24.0932 18.6019 22.5675 19.347 21.8757 21.0501C21.5031 21.9903 21.3257 22.9838 21.3257 23.995C21.3257 27.8979 21.3257 31.8007 21.308 35.7036C21.308 37.0519 21.308 37.0519 19.9065 37.0519C18.097 37.0519 16.3052 37.0342 14.4957 37.0519C13.8393 37.0519 13.5377 36.839 13.5554 36.1294C13.5732 28.572 13.5732 20.9969 13.5554 13.4395C13.5554 12.7299 13.8215 12.4992 14.4957 12.4992C16.3584 12.517 18.2211 12.517 20.0839 12.4992C20.7048 12.4992 21.0064 12.7299 20.9709 13.3863C20.9709 14.0959 20.9886 14.77 20.9886 15.657Z"
                  fill="#5956d9" />
                <path
                  d="M1.3468 24.7174C1.3468 21.0451 1.36454 17.3906 1.32906 13.7184C1.31132 12.8491 1.54194 12.4943 2.46444 12.5298C4.36266 12.583 6.24314 12.5652 8.14136 12.5475C8.81549 12.5475 9.09934 12.7781 9.08159 13.4877C9.06385 21.0451 9.06385 28.6203 9.08159 36.1777C9.08159 36.8873 8.78001 37.1002 8.12362 37.1002C6.20766 37.0824 4.27396 37.0647 2.358 37.1002C1.59516 37.1179 1.32906 36.8518 1.32906 36.0712C1.36454 32.2925 1.3468 28.4961 1.3468 24.7174Z"
                  fill="#5956d9" />
                <path
                  d="M5.18408 9.44337C2.68269 9.44337 0.660292 7.38549 0.695772 4.90184C0.731253 2.56011 2.78914 0.50223 5.1486 0.48449C7.61452 0.466749 9.65466 2.50689 9.63692 5.00828C9.63692 7.45645 7.63226 9.44337 5.18408 9.44337Z"
                  fill="#5956d9" />
              </svg>
              <a :href="userProfile?.socialNetworks?.linkedin" target="_blank">
                {{ userProfile?.socialNetworks?.linkedin }}
              </a>
            </div>

            <!-- GitHub -->
            <div class="link-with-icon" v-if="userProfile?.socialNetworks?.github">
              <svg width="38" height="38" viewBox="0 0 41 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20.1366 0.121094C9.31929 0.121094 0.46875 8.97164 0.46875 19.789C0.46875 28.3937 6.12326 35.7691 13.9904 38.4734C14.9738 38.7193 15.2197 37.9817 15.2197 37.49C15.2197 36.9984 15.2197 35.7691 15.2197 34.0482C9.81099 35.2774 8.58175 31.5897 8.58175 31.5897C7.59835 29.3771 6.36911 28.6395 6.36911 28.6395C4.64817 27.4103 6.61496 27.4103 6.61496 27.4103C8.58175 27.6561 9.56514 29.3771 9.56514 29.3771C11.2861 32.5731 14.2363 31.5897 15.2197 31.098C15.4655 29.8687 15.9572 28.8854 16.4489 28.3937C12.0236 27.902 7.59835 26.181 7.59835 18.5597C7.59835 16.3471 8.3359 14.6262 9.56514 13.3969C9.31929 12.9052 8.58175 10.9384 9.81099 8.23409C9.81099 8.23409 11.5319 7.74239 15.2197 10.2009C16.6947 9.70918 18.4157 9.46333 20.1366 9.46333C21.8576 9.46333 23.5785 9.70918 25.0536 10.2009C28.7413 7.74239 30.4623 8.23409 30.4623 8.23409C31.4456 10.9384 30.9539 12.9052 30.7081 13.3969C31.9373 14.872 32.6749 16.5929 32.6749 18.5597C32.6749 26.181 28.0038 27.6561 23.5785 28.1478C24.316 29.1312 25.0536 30.3604 25.0536 32.0814C25.0536 34.7857 25.0536 36.7525 25.0536 37.49C25.0536 37.9817 25.2994 38.7193 26.5287 38.4734C34.3958 35.7691 40.0503 28.3937 40.0503 19.789C39.8045 8.97164 30.954 0.121094 20.1366 0.121094Z"
                  fill="#5956d9" />
              </svg>
              <a :href="userProfile?.socialNetworks?.github" target="_blank">
                {{ userProfile?.socialNetworks?.github }}
              </a>
            </div>

            <!-- Bitbucket -->
            <div class="link-with-icon" v-if="userProfile?.socialNetworks?.bitbucket">
              <svg width="38" height="38" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3.33398 0C1.50065 0 0.0506504 1.45 0.167317 3.26667L5.41732 36.0667C5.61732 38.3 7.51732 40 9.76732 40H31.0007C32.7007 40 34.1673 38.7667 34.3673 37.0667L39.6173 3.26667C39.734 1.45 38.284 0 36.4507 0H3.33398ZM23.9173 26.6667H15.8673L13.6173 13.3333H26.0507L23.9173 26.6667Z"
                  fill="#5956d9" />
              </svg>
              <a :href="userProfile?.socialNetworks?.bitbucket" target="_blank">
                {{ userProfile?.socialNetworks?.bitbucket }}
              </a>
            </div>

            <!-- Twitter/X -->
            <div class="link-with-icon" v-if="userProfile?.socialNetworks?.twitter">
              <svg width="38" height="38" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M23.6867 3.33398H28.1725L18.6867 14.209L29.8867 29.334H21.32L14.3867 20.2507L6.45337 29.334H1.96671L12.0867 17.7507L1.33337 3.33398H10.1325L16.4192 11.6673L23.6867 3.33398ZM22.4534 26.6673H24.92L8.89254 5.87732H6.24671L22.4534 26.6673Z"
                  fill="#5956d9" />
              </svg>
              <a :href="userProfile?.socialNetworks?.twitter" target="_blank">
                {{ userProfile?.socialNetworks?.twitter }}
              </a>
            </div>

            <!-- Behance -->
            <div class="link-with-icon" style="margin-bottom: 15px" v-if="userProfile?.socialNetworks?.behance">
              <svg width="38" height="26" viewBox="0 0 40 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.5895 11.3173C21.5065 11.3173 21.0148 17.4635 21.0148 17.4635C21.0148 24.839 12.9018 25.0848 12.9018 25.0848H0.609375V0.5H12.9018C16.8354 0.5 20.2772 2.22094 20.2772 6.64621C20.2772 11.0715 16.5895 11.3173 16.5895 11.3173ZM6.26389 4.43358V9.84224H12.9018C14.131 9.84224 15.1144 9.35054 15.1144 6.89206C15.1144 4.43358 13.3935 4.43358 13.3935 4.43358H12.4101H6.26389ZM12.9018 20.1679C13.8852 20.1679 15.852 19.922 15.852 17.2177C15.852 13.7758 13.3935 13.7758 13.3935 13.7758H6.26389V20.1679H12.9018ZM31.3404 6.40036C41.1744 6.40036 39.9451 16.9718 39.9451 17.2177H27.4068C27.4068 21.643 31.5863 21.3971 31.5863 21.3971C35.7657 21.3971 35.5198 18.6928 35.5198 18.6928H39.6993C39.6993 25.5765 31.3404 25.0848 31.3404 25.0848C21.2606 25.0848 21.9982 15.7426 21.9982 15.7426C21.9982 15.7426 21.9982 6.40036 31.3404 6.40036ZM35.274 13.7758C35.274 13.7758 35.274 9.84224 31.3404 10.0881C27.6527 10.0881 27.161 13.7758 27.161 13.7758H35.274ZM25.1942 0.5H37.4866V2.95848H25.1942V0.5Z"
                  fill="#5956d9" />
              </svg>
              <a :href="userProfile?.socialNetworks?.behance" target="_blank">
                {{ userProfile?.socialNetworks?.behance }}
              </a>
            </div>
          </div>
        </section>
        <section v-if="certsAndCourses?.length">
          <h2 style="
              padding-left: 15px;
              font-size: 18px !important;
              font-weight: bold;
            ">
            {{ $t('profile.certifications') }}
          </h2>
          <div class="dark-box" style="padding-top: 20px; padding-bottom: 20px !important">
            <div v-for="certs in certsAndCourses" :key="certs.name" class="cert" style="padding-left: 15px">
              <p style="
                  font-size: 12px !important;
                  text-transform: uppercase;
                  margin-top: 0rem !important;
                  margin-bottom: 0rem;
                ">
                <strong>{{ certs.name }}</strong>
              </p>
              <p style="
                  font-size: 12px !important;
                  margin-top: 0rem !important;
                  margin-bottom: 0rem;
                ">
                {{ certs.certifiedBy }}
              </p>
              <p style="
                  font-size: 12px !important;
                  margin-top: 0rem !important;
                  margin-bottom: 0rem;
                ">
                {{ formatedDate(certs.startDate) }} -
                {{ formatedDate(certs.endDate) }}
              </p>
            </div>
          </div>
        </section>
        <section v-if="techAssesments?.length">
          <h2 style="
              padding-left: 15px;
              font-size: 18px !important;
              font-weight: bold;
            ">
            {{ $t('profile.technical_skills_test') }}
          </h2>
          <div class="dark-box" style="padding: 2rem">
            <div v-for="certs in techAssesments" :key="certs.name" class="singular-test">
              <img src="@/assets/images/tests/squaregorilla.png" alt="" class="img-test" />
              <div>
                <h3 style="font-weight: bold !important">{{ certs.name }}</h3>
                <p class="test-name" style="
                    font-size: 12px !important;
                    margin-top: 0rem !important;
                    margin-bottom: 0rem;
                  ">
                  {{ certs.certifiedBy }}
                </p>
                <p style="
                    font-size: 12px !important;
                    margin-top: 0rem !important;
                    margin-bottom: 0rem;
                  ">
                  {{ $t('profile.issued') }}: <span> {{ formatedDate(certs.startDate) }}</span>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="right-section">
        <section v-if="technologies?.length">
          <h2 style="
              padding-left: 15px;
              font-size: 18px !important;
              font-weight: bold;
            ">
            {{ $t('profile.technologies') }}
          </h2>
          <div class="dark-box" style="padding: 1.5rem 2rem">
            <div class="singular-technology" v-for="technology in technologies" :key="technology.techName">
              <div class="technology-mastery">
                <h3 style="font-size: 16px !important">
                  {{ technology.techName }}
                </h3>
                <span v-if="technology?.level?.length">{{
                  $t(
                  `modal-tecnologias.opcion-nivel-experiencia-${technology.level}`
                  )
                  }}</span>
              </div>
              <div class="progress-bar">
                <div class="bar" style="width: 100%"></div>
              </div>
              <p class="years-experience">
                {{ technology.expYears }} {{ $t('profile.years_experience') }}
              </p>
            </div>
          </div>
        </section>
        <section v-if="experience?.length">
          <h2 style="
              padding-left: 15px;
              font-size: 18px !important;
              font-weight: bold;
            ">
            {{ $t('profile.experience') }}
          </h2>
          <div class="dark-box" style="padding: 2rem">
            <div v-for="job in experience" :key="job.company" class="singular-experience relative">
              <div class="border-2 rounded-full h-4 w-4 absolute z-[99]" style="
                  border-color: #5956d9;
                  background-color: #e3e9ff;
                  left: -6.5px;
                "></div>
              <h3 style="
                  font-weight: bold !important;
                  font-size: 14px !important;
                  margin: 0;
                  margin-top: 0.2rem;
                ">
                {{ job.position }}
              </h3>
              <p class="company-name" style="
                  margin: 0;
                  margin-top: 0.2rem !important;
                  font-size: 14px !important;
                ">
                {{ job.company }}
              </p>
              <p class="period" style="
                  margin: 0;
                  margin-top: 0.2rem !important;
                  font-size: 13px !important;
                ">
                {{ job.startDate }}|{{ job.endDate }}({{ job.companyLocation }})
              </p>
              <div class="grid-skills-badge" style="margin-top: 1rem">
                <div v-for="tech in job.technologies" :key="tech" class="badge">
                  {{ tech }}
                </div>
              </div>
              <p class="description" style="
                  margin: 0;
                  margin-top: 0.6rem;
                  font-size: 11px;
                  line-height: 16px;
                ">
                {{ job.whatBuilt }}
              </p>
            </div>
          </div>
        </section>
        <section v-if="formalEducation?.length">
          <h2 style="
              padding-left: 15px;
              font-size: 18px !important;
              font-weight: bold;
            ">
            {{ $t('profile.education') }}
          </h2>
          <div class="dark-box" style="padding: 2rem 2rem 0.5rem">
            <div v-for="education in formalEducation" :key="education.name" class="singular-experience">
              <div class="border-2 rounded-full h-4 w-4 absolute z-[99]" style="
                  border-color: #5956d9;
                  background-color: #e3e9ff;
                  left: -6.5px;
                "></div>
              <h3 style="font-size: 14px !important">{{ education.school }}</h3>
              <span style="font-size: 14px !important">{{
                education.grade
                }}</span><br />
              <span class="spaneriod text-xs text-primary" style="margin: 0; margin-top: 0.2rem !important">
                {{ education.startDate }} | {{ education.endDate }}
              </span>
            </div>
          </div>
        </section>
        <section v-if="takenAssesments?.length">
          <h2 style="
              padding-left: 15px;
              font-size: 18px !important;
              font-weight: bold;
            ">
            {{ $t('profile.skills') }}
          </h2>
          <div class="dark-box" style="padding: 2rem">
            <div class="grid-skills">
              <div v-for="result in takenAssesments" :key="result.id" class="w-full mt-2 flex items-start">
                <div>
                  <img :src="
                      require(`@/assets/images/badges/${result.assesment.photo}`)
                    " alt="Assessment Icon" class="max-w-none" style="width: 50px; height: auto" />
                </div>
                <div class="ml-3">
                  <h3 style="font-size: 16px !important" class="text-lg mb-1 font-semibold">
                    {{ result.assesment.name }}
                  </h3>
                  <span class="text-xs mt-2">
                    {{ $t('profile.assessment_description', { name: result.assesment.name, length:
                    result.assesment.testLength }) }}
                  </span>
                  <div class="flex flex-wrap mt-2 text-xs">
                    <div v-for="subAssesment in result.subAssesmentsResult" :key="subAssesment.id"
                      class="rounded-full uppercase px-2 py-1 mr-2 mt-2 text-white"
                      style="background: #5956d9; font-size: 0.55rem">
                      {{ subAssesment.assesment.name }}
                    </div>
                  </div>
                  <h3 class="text-lg font-semibold mt-3">
                    {{ $t('profile.score') }}:
                    <span style="color: rgb(40, 185, 154)">
                      {{ getTotalScore(result) }}/{{
                      getTotalTestLength(result)
                      }}
                    </span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
    <div class="text-center pb-10">
      <span>powered by
        <img src="@/assets/images/logo-purple.png" alt="Share" class="inline mx-2 max-h-6" /></span>
    </div>
  </body>
</template>

<script>
import CryptoJS from 'crypto-js'
import ApolloClient from '@/graphql/apolloClient'
import publicProfileQuery from '@/graphql/queries/publicProfile'
import { toastNotification } from '@/services/toastNotification'
import { mapActions } from 'vuex'

export default {
  name: 'Profile',
  data() {
    return {
      userProfile: null,
      modalBienvenidaShow: false,
      assesmentCategories: [],
      // section: 'Education',
      section: 'Skills',
      technologies: [],
      formalEducation: [],
      certsAndCourses: [],
      experience: [],
      personalProjects: [],
      takenAssesments: [],
      englishLevel: null,
      about: null,
      id: null,
      decryptedId: null,
      jobTypePreference: []
    }
  },
  async mounted() {
    await this.setLoadingOn()
    await this.getUserProfile()
  },
  methods: {
    ...mapActions(['setLoadingOn', 'setLoadingOff']),
    getUserProfile: async function () {
      try {
        const userId = this.$route.params.id
        this.decryptedId = this.decryptId(userId)

        const userProfileData = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale
            }
          },
          fetchPolicy: 'network-only',
          query: publicProfileQuery,
          variables: {
            id: this.decryptedId
          }
        })

        this.userProfile = userProfileData.data.userPublicProfile
        console.log('perfil dark', this.userProfile)
        this.technologies = this.userProfile?.technologies?.slice()?.reverse()
        this.formalEducation = this.userProfile?.formalEducation
          ?.slice()
          ?.reverse()
        this.certsAndCourses = this.userProfile.certsAndCourses
          ?.slice()
          ?.reverse()
        this.techAssesments = this.userProfile.techAssesments
          ?.slice()
          ?.reverse()
        this.experience = this.userProfile.pastJobs?.slice()?.sort((a, b) => {
          const endDateA = a.endDate ? new Date(a.endDate) : new Date()
          const endDateB = b.endDate ? new Date(b.endDate) : new Date()
          return endDateB - endDateA
        })
        this.personalProjects = this.userProfile.pastProjects
          ?.slice()
          ?.reverse()
        this.takenAssesments = this.userProfile.takenAssesments
          ?.slice()
          ?.reverse()
        this.englishLevel = this.userProfile?.englishLevel
        this.about = this.userProfile?.about
        this.id = this.userProfile?.id
        this.yearsOfExp = this.userProfile?.yearsOfExp
        this.minSalary = this.userProfile?.minSalary
        this.personsInCharge = this.userProfile?.personsInCharge
        this.industries = this.userProfile?.industries?.slice()?.reverse()
        this.jobTypePreference = this.userProfile?.jobTypePreference
        this.setLoadingOff()
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    formatedDate(date) {
      if (date) {
        const parsedDate = new Date(date)
        const year = parsedDate.getUTCFullYear()
        return `${year}`
      }
      return ''
    },
    getTotalScore(result) {
      return result.subAssesmentsResult.reduce(
        (total, subAssesment) => total + subAssesment.totalScore,
        0
      )
    },
    getTotalTestLength(result) {
      return result.subAssesmentsResult.reduce(
        (total, subAssesment) => total + subAssesment.assesment.testLength,
        0
      )
    },
    englishLevelDescription(level) {
      const numericLevel = parseInt(level, 10)
      if (!numericLevel) return ''
      switch (numericLevel) {
        case 1:
        case 2:
          return 'Básico'
        case 3:
          return 'Intermedio'
        case 4:
          return 'Avanzado'
        case 5:
          return 'Profesional'
        default:
          return 'Desconocido'
      }
    },
    truncateText(text, length) {
      return text?.length > length ? text.substring(0, length) + '...' : text
    },
    encryptId(id) {
      const secretKey = '123secret'
      const ciphertext = CryptoJS.AES.encrypt(
        id.toString(),
        secretKey
      ).toString()
      const hexCiphertext = CryptoJS.enc.Base64.parse(ciphertext).toString(
        CryptoJS.enc.Hex
      )
      return hexCiphertext
    },
    decryptId(encryptedHexId) {
      return parseInt(encryptedHexId, 16).toString()
    }
  }
}
</script>

<style scoped>
.a4-page {
  width: 210mm;
  background-color: #fff;
  background-image: url('@/assets/images/background-forms.svg');
  background-repeat: repeat;
  background-size: 210mm;
  color: #000;
}
header {
  position: relative;
  z-index: -1;
}

header svg {
  width: 100%;
  height: auto;
}

main {
  display: flex;
  flex-direction: row;
  gap: 45px;
  margin-left: 15px;
  margin-right: 15px;
  flex: 1;
  z-index: 2;
}

.left-section {
  padding: 10px;
  box-sizing: border-box;
  margin-top: 35px;
  width: 40%;
  z-index: 3;
}
.right-section {
  padding: 10px;
  box-sizing: border-box;
  margin-top: 35px;
  width: 60%;
  z-index: 3;
}
.left-section {
  width: 36%;
}
.right-section {
  width: 53%;
}

.contact-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 30px;
  gap: 3px;
  min-width: 300px;
  margin-left: 5px !important;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.contact-item svg {
  width: 16px;
  height: 16px;
}

.profile-card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: left;
  gap: 20px;
  width: 100%;
}

.profile-image img {
  width: 120px;
  height: auto;
  border-radius: 50%;
}

.profile-content {
  max-width: 500px;
}

.profile-content h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.profile-content p {
  margin: 5px 0;
}

.profile-content p strong {
  color: #5956d9;
}

.dark-box {
  background-color: #e3e9ff;
  border: 2px solid #5956d9;
  border-radius: 10px;
  color: #000;
  margin-top: 10px;
  margin-bottom: 30px;
  min-width: 300px;
  max-width: 100%;
}

.right-section .dark-box {
  min-width: 420px;
}

.dark-box p strong {
  color: #5956d9;
}

.dark-box p {
  margin-top: 0.7rem !important;
}

.dark-box span strong {
  color: #5956d9;
}

.languages {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.language-item {
  display: flex;
  align-items: center;
  gap: 25px;
  margin-left: 15px;
  padding: 0;
}

.stars {
  display: flex;
  gap: 5px;
}

.stars svg {
  width: 18px;
  height: 17px;
}

.level {
  font-size: 12px;
  color: #a5a5a5;
}

.pill {
  border-radius: 9999px;
  padding: 10px 16px;
  margin-right: 8px;
  margin-left: 15px;
  background-color: rgba(185, 163, 255, 0.4);
  color: #000;
  font-size: 0.7rem;
  margin-bottom: 10px;
  display: inline-block;
  text-transform: uppercase;
}

.pill-section {
  margin-bottom: 5px;
}

.link-with-icon {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.link-with-icon a {
  color: #5956d9;
  text-decoration: none;
  font-size: 1rem;
  margin-top: 8px;
}

.link-with-icon a:hover {
  text-decoration: underline;
}

.link-with-icon svg {
  padding-left: 15px;
}
.progress-bar {
  width: 100%;
  height: 5px;
  background-color: #333333;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
}
.progress-bar .bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #5956d9;
  border-radius: 50px;
}
.technology-mastery {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.technology-mastery h3 {
  margin-top: 0;
  margin-bottom: 0;
}
.technology-mastery span {
  font-size: 12px;
  letter-spacing: 2px;
  color: #5956d9;
}
.singular-technology {
  margin-bottom: 0.7rem;
}
.singular-technology h3 {
  font-weight: normal;
}
.singular-technology .years-experience {
  margin-top: 2px !important;
  margin-bottom: 0;
  font-size: 10px;
  font-weight: normal;
}
.badge {
  display: block;
  width: max-content;
  background-color: #5956d9;
  color: #fff;
  font-size: 11px;
  padding: 5px 1rem;
  border-radius: 50px;
}
.grid-skills-badge {
  width: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
}
.singular-experience {
  font-weight: normal;
  position: relative;
  padding-bottom: 1rem;
  padding-left: 2rem;
}
/* .singular-experience::after {
  position: absolute;
  top: 0;
  left: -8px;
  content: '';
  width: 18px;
  height: 18px;
  background-color: #e3e9ff;
  border: 3px solid #5956d9;
  border-radius: 50px;
  z-index: 2;
} */
.singular-experience::before {
  position: absolute;
  left: 0;
  content: '';
  width: 3px;
  height: 100%;
  background-color: #5956d9;
  z-index: 1;
}
.singular-experience:last-child:before {
  display: none;
}
.singular-experience h3 {
  color: #5956d9;
  margin-top: 0;
}
.singular-experience .company-name {
  font-weight: bold;
}
.singular-experience .period {
  color: #5956d9;
}
.singular-experience .description {
  line-height: 1;
  margin-bottom: 0;
}
.grid-skills {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}
.singular-skill {
  width: calc(50% - 2rem);
  display: flex;
  gap: 1rem;
  align-items: start;
}
.singular-skill .insignia {
  width: 64px;
  height: 64px;
  object-fit: contain;
}
.singular-skill h3 {
  margin-top: 0;
}
.singular-skill .description {
  font-size: 13px;
  line-height: 1;
}
.singular-skill .score span {
  font-size: 25px;
  color: #28b99a;
}
.singular-test {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}
.singular-test img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}
.singular-test .test-name {
  color: #5956d9;
  font-size: 18px;
}
.singular-test h3 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.singular-test p {
  margin-top: 5px;
  margin-bottom: 5px;
}
footer {
  padding: 1rem;
  text-align: center;
}
footer .img-copy {
  width: 100%;
}
</style>
