<template>
  <div class="rounded-2xl p-5 mr-2">
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-6">
      <span
        class="flex justify-center w-16 h-16 bg-opacity-30 bg-black rounded-2xl items-center text-white"
        >Icon</span
      >
      <div class="text-right">
        <h2 class="text-4xl font-bold text-white">{{ number }}</h2>
        <span class="text-white">{{ title }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CardWidgetColor',
  props: {
    title: String,
    number: Number
  },
  data() {
    return {}
  }
}
</script>
