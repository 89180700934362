<template>
  <svg
    width="680"
    height="582"
    viewBox="0 0 680 582"
    alt="Utopicode"
    class="fill-current bg-gradient-to-br from-tertiary to-linkedin text-main-light opacity-30 dark:from-gradient-primary dark:to-gradient-secondary dark:opacity-100 dark:text-main-dark"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M224.286 0C78.4916 0.00819769 0 121.432 0 211.386V0H224.286ZM224.316 0C246.995 0.00127517 271.303 2.94047 297.197 9.39297C390.656 32.682 413.191 113.244 433.582 186.142C455.164 263.297 474.345 331.866 572.669 314.863C763.892 281.795 681.796 553.356 466.545 544.338C402.938 541.673 336.184 553.533 273.697 564.635C124.722 591.103 0 613.263 0 424.01V582H680V0H224.316Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Shape02'
}
</script>
