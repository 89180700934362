<template>
  <main class="main--wrapper main--wrapper--internal pt-32">
    <div class="content--wrapper">
      <Title class="mb-8">
        <template #title>
          <h3>
            {{ $t('admin-event-detail.lbl-inscriptos') }}
            {{ this.$route.params.title }}
          </h3>
        </template>
      </Title>
      <!-- Filters -->
      <section
        class="mt-4 mb-8 grid gap-x-6 gap-y-4 lg:grid-cols-4 md:grid-cols-2 grid-cols-1"
      >
        <input
          class="form--filter element--sm"
          :placeholder="$t('admin-dashboard-candidatos.form-buscar-email')"
          v-model="filter.email"
          id="email"
        />
        <input
          class="form--filter element--sm"
          id="nombre"
          v-model="filter.nombre"
          :placeholder="$t('admin-dashboard-candidatos.form-buscar-nombre')"
        />
        <select
          class="form--filter element--sm form--select"
          v-model.lazy="filter.especialidad"
        >
          <option value="" selected>
            {{ $t('admin-dashboard-candidatos.form-buscar-especialidad') }}
          </option>
          <option
            :value="especialidad"
            v-for="especialidad in especialidadLista"
            :key="especialidad"
          >
            {{ especialidad }}
          </option>
        </select>
        <button
          type="button"
          @click="resetFilter()"
          class="btn btn-rounded-lg btn-md btn-secondary"
        >
          {{ $t('general.resetear') }}
        </button>
      </section>
      <!-- End Filters -->
      <!-- Inscriptos -->
      <CardPage class="pt-10">
        <template #default>
          <!-- Body -->
          <div class="card--page--body">
            <div
              class="overflow-x-auto mb-6 border dark:border-line-dark border-line-light whitespace-nowrap"
            >
              <h2 class="text-main">Total inscritos: {{ numInscritos }}</h2>
              <table
                v-if="inscriptos"
                class="table--default table--hover table-fixed table--nowrap dark:text-primary-text text-secondary-text"
              >
                <thead>
                  <tr>
                    <th class="col-fixed first-col">Id Insc</th>
                    <th class="col-fixed second-col">
                      {{ $t('admin-dashboard-candidatos.table-nombre') }}
                    </th>
                    <th class="w-full">Email</th>
                    <th>
                      {{
                        $t(
                          'admin-dashboard-candidatos.form-buscar-especialidad'
                        )
                      }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="inscripto in filteredRows" :key="inscripto.id">
                    <td class="col-fixed first-col text-center">
                      {{ inscripto.id }}
                    </td>
                    <td class="col-fixed second-col">
                      {{ inscripto.fullname }}
                    </td>
                    <td>{{ inscripto.email }}</td>
                    <td>{{ inscripto.speciality }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- End Body -->
        </template>
      </CardPage>
      <!-- End Inscriptos -->
    </div>
  </main>
</template>

<script>
import CardPage from '@/components/CardPage'
import Title from '@/components/Title'
import ApolloClient from '@/graphql/apolloClient'
import EventQuery from '@/graphql/queries/eventDetail'
import { mapActions } from 'vuex'
import { toastNotification } from '@/services/toastNotification'
import especialidadLista from '@/constants/specialities.json'

export default {
  name: 'AdminEventDetail',
  data: function () {
    return {
      inscriptos: null,
      especialidadLista,
      filter: {
        email: '',
        nombre: '',
        especialidad: '',
        numInscritos: ''
      }
    }
  },
  components: {
    CardPage,
    Title
  },
  beforeMount() {
    this.getEvents()
  },
  updated() {
    this.getEvents()
  },
  computed: {
    filteredRows() {
      if (this.inscriptos) {
        return this.inscriptos
          .filter((inscripto) => {
            if (inscripto.email) {
              return inscripto.email
                .toLowerCase()
                .includes(this.filter.email.toLowerCase())
            }
            return inscripto.email
          })
          .filter((inscripto) => {
            if (inscripto.fullname) {
              return inscripto.fullname
                .toLowerCase()
                .includes(this.filter.nombre.toLowerCase())
            }
            return inscripto.fullname
          })
          .filter((inscripto) => {
            if (inscripto.speciality) {
              return inscripto.speciality
                .toLowerCase()
                .includes(this.filter.especialidad.toLowerCase())
            }
            return inscripto.speciality
          })
      } else {
        return this.inscriptos
      }
    }
  },
  methods: {
    ...mapActions(['setLoadingOff', 'setLoadingOn']),
    getEvents: async function () {
      this.setLoadingOn()
      try {
        const eventQuery = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale
            }
          },
          fetchPolicy: 'network-only',
          query: EventQuery,
          variables: {
            eventId: parseInt(this.$route.params.id)
          }
        })
        this.inscriptos = eventQuery.data.event.inscriptions
        this.numInscritos = this.inscriptos.length
        this.setLoadingOff()
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    // Reset Filters
    resetFilter() {
      this.filter.email = ''
      this.filter.nombre = ''
      this.filter.especialidad = ''
    }
  }
}
</script>
