import { app } from '@/main'
import 'vue-toast-notification/dist/theme-sugar.css'

const toastNotification = (toastMessage, toastType) => {
  app.config.globalProperties.$toast.open({
    message: toastMessage,
    type: toastType,
    position: 'top-right',
    duration: 5000
  })
}

export { toastNotification }
