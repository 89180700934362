import gql from 'graphql-tag'

const basicInfoQuery = gql`
  query userQuery {
    user {
      id
      email
      country
      city
      fullname
      phonenumber
      avatar
      role
    }
    userProfile {
      speciality
    }
  }
`
export default basicInfoQuery
