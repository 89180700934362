<template>
  <div
    class="tooltip--globe !p-0 m-0 tooltip--xs !top-[100%] !right-[50%] !w-[290px] flex gap-4 items-stretch lg:transform lg:translate-x-1/2 lg:translate-y-1/3"
    :class="success ? `!bg-[#31BB9A]` : `!bg-[#B9A3FF]`"
  >
    <div class="w-full absolute mt-[-15px] flex justify-center">
      <div
        class="w-0 h-0 border-l-[10px] border-r-[10px] border-b-[20px] border-l-transparent border-r-transparent"
        :class="success ? `!border-b-[#31BB9A]` : '!border-b-[#B9A3FF]'"
      ></div>
    </div>

    <div class="w-full h-full m-0 p-4 flex-1">
      <p class="text-black text-xl font-bold mb-0">{{ title }}</p>
      <div class="flex items-center mt-2">
        <p class="text-sm dark:text-black font-normal p-0 text-black last">
          {{ message }}
        </p>
      </div>
      <div class="flex items-center mt-4">
        <svg
          width="25"
          height="25"
          class="min-w-[25px] mr-2 !mt-[-2px]"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          v-if="completed"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.4732 22.253C7.22294 22.253 2.9668 17.9968 2.9668 12.7466C2.9668 7.49638 7.22294 3.24023 12.4732 3.24023C17.7234 3.24023 21.9795 7.49638 21.9795 12.7466C21.9676 17.9919 17.7184 22.241 12.4732 22.253ZM12.4732 4.86771C8.12298 4.86771 4.59646 8.39423 4.59646 12.7444C4.59646 17.0946 8.12298 20.6211 12.4732 20.6211C16.8234 20.6211 20.3499 17.0946 20.3499 12.7444C20.3439 8.39671 16.8209 4.87369 12.4732 4.86771ZM10.2999 16.2667C10.4622 16.4318 10.6879 16.5189 10.9191 16.5057C11.1362 16.5106 11.3452 16.4238 11.495 16.2667L17.3509 10.3347C17.6686 10.0166 17.6686 9.5012 17.3509 9.18307C17.1996 9.02776 16.9919 8.94017 16.7751 8.94017C16.5582 8.94017 16.3506 9.02776 16.1993 9.18307L10.8757 14.4958L8.7028 12.3229C8.38175 12.0237 7.88144 12.0326 7.57114 12.3429C7.26084 12.6532 7.25201 13.1535 7.55117 13.4745L10.2999 16.2667Z"
            fill="black"
          />
        </svg>
        <svg
          width="25"
          height="25"
          class="min-w-[25px] mr-2 !mt-[-2px]"
          viewBox="0 0 25 25"
          fill="black"
          xmlns="http://www.w3.org/2000/svg"
          v-else
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.79492 12.4478C2.79492 17.698 7.05107 21.9542 12.3013 21.9542C17.549 21.9482 21.8017 17.6955 21.8077 12.4478C21.8077 7.19755 17.5515 2.94141 12.3013 2.94141C7.05107 2.94141 2.79492 7.19755 2.79492 12.4478ZM4.42459 12.4478C4.42459 8.09759 7.95111 4.57107 12.3013 4.57107C16.649 4.57705 20.172 8.10007 20.178 12.4478C20.178 16.798 16.6515 20.3245 12.3013 20.3245C7.95111 20.3245 4.42459 16.798 4.42459 12.4478ZM8.60706 16.947C8.39079 16.9481 8.18323 16.8619 8.03125 16.708C7.71352 16.3899 7.71352 15.8745 8.03125 15.5564L11.1448 12.4475L8.04347 9.34159C7.74431 9.02053 7.75314 8.52022 8.06344 8.20992C8.37374 7.89962 8.87405 7.89079 9.1951 8.18996L12.2972 11.2967L15.4082 8.19031C15.6113 7.97231 15.9173 7.88258 16.206 7.95631C16.4947 8.03004 16.7201 8.25547 16.7938 8.54418C16.8676 8.83288 16.7778 9.13881 16.5598 9.34194L13.448 12.4492L16.5612 15.5669C16.7165 15.7182 16.8041 15.9259 16.8041 16.1427C16.8041 16.3596 16.7165 16.5672 16.5612 16.7185C16.4085 16.8714 16.2014 16.9574 15.9854 16.9575C15.7703 16.9529 15.5647 16.8676 15.4096 16.7185L12.2955 13.5999L9.18288 16.708C9.03312 16.8652 8.82408 16.9519 8.60706 16.947Z"
            fill="black"
          />
        </svg>

        <p
          class="text-sm dark:text-black font-semibold p-0 italic text-black last"
        >
          {{ subtitle }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SuccessBadgeTooltip',
  props: {
    title: String,
    message: String,
    subtitle: String,
    completed: Boolean,
    url: String,
    success: Boolean
  },
  mounted() {
    console.log('COLOR', this.color)
  },
  computed: {
    tooltipStyle() {
      return this.color ?? '#B9A3FF'
    }
  }
}
</script>
