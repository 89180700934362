<template>
  <div class="flex flex-col justify-start mb-10 items-start flex-grow-0 flex-shrink-0 relative gap-6">
    <div class="flex justify-between items-center flex-grow-0 flex-shrink-0 w-full">
      <div class="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5">
        <div class="flex justify-start items-center flex-grow-0 flex-shrink-0 relative"
          style="min-height: -webkit-fill-available">
          <div class="flex-grow-0 flex-shrink-0 flex items-center">
            <p class="left-0 mb-0 top-0 text-[25.979999542236328px] text-left dark:text-white">
              {{ $t('steps.title_1') }} <span>&nbsp;</span>
              {{ $t('steps.title_2') }}
            </p>
            <svg width="18" height="15" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg"
              class="absolute left-[19.2px] top-[17px]" preserveAspectRatio="xMidYMid meet">
              <path
                d="M13.6727 11.2785L7.96204 8.50613L13.6748 5.73319C13.8369 5.64298 13.9693 5.45498 14.0292 5.22323C14.0897 4.98823 14.0654 4.75108 13.9639 4.58847C13.8526 4.38859 13.6165 4.23139 13.3426 4.1698C13.1298 4.12172 12.9337 4.14171 12.8191 4.22382L7.49259 7.83032L7.99175 1.45197C8.02471 1.16349 7.92963 0.951184 7.70976 0.813429C7.39481 0.616789 6.89187 0.625973 6.56396 0.835037C6.40838 0.934437 6.23172 1.12027 6.27224 1.44332L6.77248 7.82978L1.44972 4.22598C1.06724 4.01422 0.512439 4.21356 0.307697 4.52634C0.0905293 5.01902 0.280685 5.57815 0.592391 5.73373L6.3025 8.50613L0.595093 11.2769C0.426005 11.3725 0.295273 11.5589 0.235849 11.789C0.175344 12.024 0.199654 12.2612 0.301214 12.4232C0.412499 12.6231 0.648575 12.7803 0.922465 12.8419C1.13531 12.89 1.33141 12.87 1.44594 12.7879L6.77248 9.1814L6.27332 15.5598C6.23226 15.8914 6.40892 16.0773 6.5645 16.1767C6.89187 16.3857 7.39536 16.3949 7.7103 16.1983C7.93017 16.0605 8.02525 15.8482 7.99284 15.5668L7.49259 9.18194L12.8154 12.7857C13.1984 12.9975 13.7532 12.7982 13.9574 12.4854C14.1745 11.9932 13.9844 11.4341 13.6727 11.2785Z"
                fill="#FF9980"></path>
            </svg>
          </div>
          <p
            class="flex-grow-0 mt-1 flex-shrink-0 mb-0 ml-2 text-[17.5314998626709px] font-light text-left dark:text-white">
            {{ $t('steps.subtitle') }}
          </p>
        </div>
        <span class="cursor-pointer" @click="goToNextStep(1)">
          <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"
            class="flex-grow-0 flex-shrink-0 w-6 h-6 relative" preserveAspectRatio="none">
            <path
              d="M12.241 18.209C12.5265 18.209 12.7676 18.1105 12.9645 17.9135C13.1613 17.7163 13.2597 17.4749 13.2597 17.1892C13.2597 16.9038 13.1611 16.6626 12.964 16.4658C12.7668 16.2691 12.5255 16.1707 12.24 16.1707C11.9545 16.1707 11.7133 16.2693 11.5165 16.4665C11.3196 16.6637 11.2212 16.905 11.2212 17.1905C11.2212 17.476 11.3198 17.7172 11.517 17.914C11.7141 18.1107 11.9555 18.209 12.241 18.209ZM11.5327 14.6283H12.9405C12.9533 14.1361 13.0254 13.7428 13.1567 13.4485C13.2882 13.1543 13.6071 12.7643 14.1135 12.2785C14.5533 11.8387 14.8901 11.4325 15.124 11.06C15.358 10.6877 15.475 10.2479 15.475 9.74075C15.475 8.87992 15.1657 8.2075 14.5472 7.7235C13.9285 7.23967 13.1968 6.99775 12.352 6.99775C11.5173 6.99775 10.8266 7.2205 10.28 7.666C9.73312 8.1115 9.34304 8.63617 9.1097 9.24L10.3942 9.75525C10.516 9.42325 10.7244 9.09983 11.0192 8.785C11.314 8.47033 11.7519 8.313 12.3327 8.313C12.9237 8.313 13.3605 8.47483 13.6432 8.7985C13.926 9.12233 14.0675 9.47842 14.0675 9.86675C14.0675 10.2066 13.9706 10.5175 13.777 10.7995C13.5835 11.0815 13.3367 11.3539 13.0367 11.6167C12.3802 12.2091 11.9655 12.6816 11.7925 13.0343C11.6193 13.3868 11.5327 13.9181 11.5327 14.6283ZM12.2537 22.0938C10.9397 22.0938 9.70462 21.8444 8.54845 21.3458C7.39229 20.8471 6.38662 20.1703 5.53145 19.3155C4.67629 18.4607 3.9992 17.4554 3.5002 16.2998C3.00137 15.1441 2.75195 13.9093 2.75195 12.5955C2.75195 11.2815 3.00129 10.0464 3.49995 8.89025C3.99862 7.73408 4.67537 6.72842 5.5302 5.87325C6.38504 5.01808 7.39029 4.341 8.54595 3.842C9.70162 3.34317 10.9364 3.09375 12.2502 3.09375C13.5642 3.09375 14.7993 3.34308 15.9555 3.84175C17.1116 4.34042 18.1173 5.01717 18.9725 5.872C19.8276 6.72683 20.5047 7.73208 21.0037 8.88775C21.5025 10.0434 21.752 11.2782 21.752 12.592C21.752 13.906 21.5026 15.1411 21.004 16.2973C20.5053 17.4534 19.8285 18.4591 18.9737 19.3143C18.1189 20.1694 17.1136 20.8465 15.958 21.3455C14.8023 21.8443 13.5675 22.0938 12.2537 22.0938Z"
              class="dark:fill-white fill-black"></path>
          </svg>
        </span>
      </div>
      <div class="w-full px-4 mt-1 flex justify-end">
        <button
          class="flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-[7.133814811706543px] px-[12.442957878112793px] py-[11.414103507995605px] rounded-[6.22px] bg-[#28b99a]"
          style="
            box-shadow: 0px 0.7133814692497253px 2.1401443481445312px 0
                rgba(0, 0, 0, 0.05),
              0px 1.4267629384994507px 0.7133814692497253px 0
                rgba(0, 0, 0, 0.03),
              0px 0.7133814692497253px 0.7133814692497253px 0
                rgba(0, 0, 0, 0.04);
          " @click="abrirModal">
          <span class="flex-grow-0 flex-shrink-0 text-md font-medium text-center text-black">{{ $t('general.referidos')
            }}
            <b>$500<small class="text-xs">USD</small></b>
          </span>
          <svg width="32" height="15" viewBox="0 0 32 15" fill="none" xmlns="http://www.w3.org/2000/svg"
            class="flex-grow-0 flex-shrink-0 w-[31.56px] h-3.5 relative" preserveAspectRatio="xMidYMid meet">
            <g clip-path="url(#clip0_2002_12177)">
              <path
                d="M31.8713 6.64446C30.5006 6.24293 29.2267 5.61986 28.0913 4.77525C26.6375 3.69526 25.4191 2.24143 24.6022 0.59375L23.7299 1.02298C24.6298 2.80911 25.9314 4.35987 27.5098 5.53678C28.0498 5.93831 28.6175 6.29831 29.2267 6.61677H0.330078V7.58599H29.4621C28.839 7.94599 28.2575 8.34752 27.7175 8.81829C25.6129 10.6321 24.6437 12.8059 24.1868 14.3013L25.1145 14.5782C25.5298 13.1936 26.416 11.1998 28.3406 9.55213C29.379 8.66598 30.5698 7.98753 31.8852 7.5583L31.8713 6.64446ZM30.2652 7.17061V7.10138C30.2929 7.11523 30.3067 7.11523 30.3344 7.12907C30.3067 7.14292 30.2929 7.15676 30.2652 7.17061Z"
                fill="#1E1D1D"></path>
            </g>
            <defs>
              <clipPath id="clip0_2002_12177">
                <rect width="31.5551" height="13.9983" fill="white" transform="translate(0.330078 0.59375)"></rect>
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
      <Modal v-if="showModalReferer" @cerrarModal="cerrarModal" />
      <ModalBienvenida v-if="showModalBienvenida" @cerrarModalBienvenida="cerrarModalBienvenida" />
    </div>
    <div class="flex-grow-0 flex-shrink-0 w-full h-[100px]" :class="[getCurrentStep == 2 ? 'tutorial-step-2' : '']">
      <div class="tutorial-step-2-container absolute" v-if="getCurrentStep == 2"></div>
      <div class="flex justify-start items-center absolute left-[220px]">
        <div class="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 h-16 w-[58px] relative gap-4">
          <!-- <svg
            width="65"
            height="65"
            viewBox="0 0 65 65"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="flex-grow-0 flex-shrink-0 w-16 h-16 scale-125 shadow-2xl shadow-[#B9A3FF]"
            preserveAspectRatio="none"
          >
            <rect
              x="32.5527"
              y="2.22021"
              width="43.2181"
              height="43.2181"
              rx="5.80999"
              transform="rotate(45 32.5527 2.22021)"
              fill="#B9A3FF"
              stroke="#B9A3FF"
              stroke-width="2.035"
            ></rect>
          </svg> -->
          <router-link :to="{ name: 'Profile' }">
            <div class="flex-grow-0 flex-shrink-0 w-16 h-16 mb-2">
              <div @mouseenter="tooltipProfileShow = true" @mouseleave="tooltipProfileShow = false">
                <div class="absolute rotate-45 rounded-[6.83px] border-[2.03px] border-[#B9A3FF] bg-[#B9A3FF]" style=""
                  :class="[
                    isActiveRoute('/perfil/')
                      ? 'shadow-[0_0_2px_#B9A3FF,inset_0_0_3px_#B9A3FF,0_0_2px_#B9A3FF,0_0_4px_#B9A3FF,0_0_45px_#B9A3FF] left-[-4px] w-[65px] h-[65px]'
                      : 'w-[45px] h-[45px] top-[9px] left-[7px]'
                  ]"></div>
                <p class="absolute text-4xl font-extrabold text-left text-black" :class="[
                  isActiveRoute('/perfil/')
                    ? 'scale-150 left-[15px] top-[50px]'
                    : 'scale-100 left-[16px] top-[18px]'
                ]">
                  <svg class="w-[25px]">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M13.9187 22.0639C9.30585 22.0639 5.56641 18.3245 5.56641 13.7116C5.56641 9.09882 9.30585 5.35938 13.9187 5.35938C18.5315 5.35938 22.271 9.09882 22.271 13.7116C22.2605 18.3201 18.5272 22.0534 13.9187 22.0639ZM13.9187 6.78925C10.0966 6.78925 6.99824 9.88765 6.99824 13.7097C6.99824 17.5318 10.0966 20.6302 13.9187 20.6302C17.7408 20.6302 20.8391 17.5318 20.8391 13.7097C20.8339 9.88983 17.7386 6.79451 13.9187 6.78925ZM12.0096 16.8044C12.1522 16.9495 12.3505 17.026 12.5536 17.0144C12.7443 17.0187 12.928 16.9424 13.0596 16.8044L18.2046 11.5925C18.4837 11.313 18.4837 10.8602 18.2046 10.5807C18.0716 10.4443 17.8892 10.3673 17.6986 10.3673C17.5081 10.3673 17.3257 10.4443 17.1927 10.5807L12.5155 15.2484L10.6064 13.3394C10.3243 13.0765 9.88473 13.0843 9.6121 13.3569C9.33947 13.6295 9.33171 14.0691 9.59455 14.3512L12.0096 16.8044Z"
                      fill="#1E1D1D"></path>
                  </svg>
                </p>
                <transition name="fade" v-if="tooltipProfileShow == true">
                  <DefaultStepTooltip :title="$t('steps.step_1_title')" :message="$t('steps.step_1_description')"
                    :subtitle="$t('steps.step_1_subtitle')" :completed="true"></DefaultStepTooltip>
                </transition>
              </div>
            </div>
            <p class="flex-grow-0 flex-shrink-0 text-[11.912351608276367px] text-nowrap font-semibold text-center uppercase dark:text-white"
              :class="[isActiveRoute('/perfil/') ? 'mt-7' : '']">
              {{ $t('steps.step_1') }}
            </p>
          </router-link>
        </div>
        <div class="flex-grow-0 flex-shrink-0 w-[106.93px] h-[2.3px] bg-[#b9a3ff]"></div>
        <div class="hidden lg:flex justify-start items-center flex-grow-0 flex-shrink-0 relative">
          <div
            class="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 h-[27px] w-[27px] relative gap-4">
            <div @mouseenter="tooltipBasicShow = true" @mouseleave="tooltipBasicShow = false">
              <div v-if="profile?.technologies?.length > 0">
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"
                  class="flex-grow-0 flex-shrink-0 w-[26.85px] h-[26.85px]" preserveAspectRatio="none">
                  <circle cx="13.9856" cy="13.7082" r="13.427" fill="#B9A3FF"></circle>
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M13.987 22.0639C9.37421 22.0639 5.63477 18.3245 5.63477 13.7116C5.63477 9.09882 9.37421 5.35938 13.987 5.35938C18.5999 5.35938 22.3393 9.09882 22.3393 13.7116C22.3288 18.3201 18.5955 22.0534 13.987 22.0639ZM13.9871 6.78925C10.165 6.78925 7.0666 9.88765 7.0666 13.7097C7.0666 17.5318 10.165 20.6302 13.9871 20.6302C17.8091 20.6302 20.9075 17.5318 20.9075 13.7097C20.9023 9.88983 17.8069 6.79451 13.9871 6.78925ZM12.0779 16.8044C12.2206 16.9495 12.4189 17.026 12.622 17.0144C12.8127 17.0187 12.9963 16.9424 13.1279 16.8044L18.2729 11.5925C18.5521 11.313 18.5521 10.8602 18.2729 10.5807C18.14 10.4443 17.9575 10.3673 17.767 10.3673C17.5765 10.3673 17.3941 10.4443 17.2611 10.5807L12.5838 15.2484L10.6747 13.3394C10.3927 13.0765 9.95309 13.0843 9.68046 13.3569C9.40783 13.6295 9.40007 14.0691 9.66291 14.3512L12.0779 16.8044Z"
                    fill="#1E1D1D"></path>
                </svg>
              </div>
              <div v-else class="rounded-full min-h-[27px] w-[27px] border-2 border-[#666]"></div>
            </div>
            <transition name="fade" v-if="tooltipBasicShow == true">
              <DefaultStepTooltip :title="$t('steps.basic_title')" :message="$t('steps.basic_description')"
                :subtitle="$t('steps.basic_subtitle')" :completed="profile?.technologies?.length > 0
                  // ToDo: && profile?.idealCompany?.length > 0
                  "></DefaultStepTooltip>
            </transition>
            <p
              class="flex-grow-0 flex-shrink-0 text-[11.912351608276367px] text-nowrap font-semibold text-center uppercase dark:text-white">
              {{ $t('steps.basic') }}
            </p>
          </div>
          <div class="flex-grow-0 flex-shrink-0 w-[106.93px] h-[2.3px]" :class="[
            // ToDo: && profile?.idealCompany?.length > 0
            profile?.technologies?.length > 0 &&
              profile?.formalEducation?.length > 0 &&
              profile?.pastJobs?.length > 0
              ? 'bg-[#B9A3FF]'
              : 'bg-[#666]',
            getCurrentStep == 0 ? 'tutorial-profileStep-badge-aux' : ''
          ]"></div>

          <div
            class="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 h-[27px] w-[27px] relative gap-4"
            :class="[
              getCurrentStep == 0
                ? 'tutorial-profileStep-badge tutorial-profileStep-badge-container'
                : ''
            ]">
            <div @mouseenter="tooltipMediumShow = true" @mouseleave="tooltipMediumShow = false">
              <div v-if="
                profile?.technologies?.length > 0 &&
                profile?.formalEducation?.length > 0 &&
                profile?.pastJobs?.length > 0
                // ToDo: && profile?.idealCompany?.length > 0
              ">
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"
                  class="flex-grow-0 flex-shrink-0 w-[26px] h-[26.85px]" preserveAspectRatio="none">
                  <circle cx="13.9856" cy="13.7082" r="13.427" fill="#B9A3FF"></circle>
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M13.987 22.0639C9.37421 22.0639 5.63477 18.3245 5.63477 13.7116C5.63477 9.09882 9.37421 5.35938 13.987 5.35938C18.5999 5.35938 22.3393 9.09882 22.3393 13.7116C22.3288 18.3201 18.5955 22.0534 13.987 22.0639ZM13.9871 6.78925C10.165 6.78925 7.0666 9.88765 7.0666 13.7097C7.0666 17.5318 10.165 20.6302 13.9871 20.6302C17.8091 20.6302 20.9075 17.5318 20.9075 13.7097C20.9023 9.88983 17.8069 6.79451 13.9871 6.78925ZM12.0779 16.8044C12.2206 16.9495 12.4189 17.026 12.622 17.0144C12.8127 17.0187 12.9963 16.9424 13.1279 16.8044L18.2729 11.5925C18.5521 11.313 18.5521 10.8602 18.2729 10.5807C18.14 10.4443 17.9575 10.3673 17.767 10.3673C17.5765 10.3673 17.3941 10.4443 17.2611 10.5807L12.5838 15.2484L10.6747 13.3394C10.3927 13.0765 9.95309 13.0843 9.68046 13.3569C9.40783 13.6295 9.40007 14.0691 9.66291 14.3512L12.0779 16.8044Z"
                    fill="#1E1D1D"></path>
                </svg>
              </div>
              <div class="rounded-full min-h-[27px] w-[27px] border-2 border-[#666]" :class="[
                getCurrentStep == 0 ? 'tutorial-profileStep-badge-aux' : ''
              ]" v-else></div>
            </div>
            <transition name="fade" v-if="tooltipMediumShow == true">
              <DefaultStepTooltip :title="$t('steps.medium_title')" :message="$t('steps.medium_description')"
                :subtitle="$t('steps.medium_subtitle')" :completed="profile?.technologies?.length > 0 &&
                  profile?.formalEducation?.length > 0 &&
                  profile?.pastJobs?.length > 0
                  // ToDo: && profile?.idealCompany?.length > 0
                  "></DefaultStepTooltip>
            </transition>
            <p
              class="flex-grow-0 flex-shrink-0 text-[11.912351608276367px] text-nowrap font-semibold text-center uppercase dark:text-white">
              {{ $t('steps.medium') }}
            </p>
          </div>
          <div class="flex-grow-0 flex-shrink-0 w-[106.93px] h-[2.3px]" :class="[
            // ToDo: && profile?.idealCompany?.length > 0
            profile?.technologies?.length > 0 &&
              profile?.formalEducation?.length > 0 &&
              profile?.pastJobs?.length > 0 &&
              (profile?.socialNetworks?.linkedin != null ||
                profile?.socialNetworks?.github != null ||
                profile?.socialNetworks?.twitter != null ||
                profile?.socialNetworks?.bitbucket != null ||
                profile?.socialNetworks?.dribble != null ||
                profile?.socialNetworks?.behance != null)
              ? 'bg-[#B9A3FF]'
              : 'bg-[#666]',
            getCurrentStep == 0 ? 'tutorial-profileStep-badge-aux' : ''
          ]"></div>
          <div
            class="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 h-[27px] w-[27px] relative gap-4">
            <div @mouseenter="tooltipHighShow = true" @mouseleave="tooltipHighShow = false">
              <div v-if="
                profile?.technologies?.length > 0 &&
                profile?.formalEducation?.length > 0 &&
                profile?.pastJobs?.length > 0 &&
                // ToDo: && profile?.idealCompany?.length > 0
                (profile?.socialNetworks?.linkedin != null ||
                  profile?.socialNetworks?.github != null ||
                  profile?.socialNetworks?.twitter != null ||
                  profile?.socialNetworks?.bitbucket != null ||
                  profile?.socialNetworks?.dribble != null ||
                  profile?.socialNetworks?.behance != null)
              ">
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"
                  class="flex-grow-0 flex-shrink-0 w-[26.85px] h-[26.85px]" preserveAspectRatio="none">
                  <circle cx="13.9856" cy="13.7082" r="13.427" fill="#B9A3FF"></circle>
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M13.987 22.0639C9.37421 22.0639 5.63477 18.3245 5.63477 13.7116C5.63477 9.09882 9.37421 5.35938 13.987 5.35938C18.5999 5.35938 22.3393 9.09882 22.3393 13.7116C22.3288 18.3201 18.5955 22.0534 13.987 22.0639ZM13.9871 6.78925C10.165 6.78925 7.0666 9.88765 7.0666 13.7097C7.0666 17.5318 10.165 20.6302 13.9871 20.6302C17.8091 20.6302 20.9075 17.5318 20.9075 13.7097C20.9023 9.88983 17.8069 6.79451 13.9871 6.78925ZM12.0779 16.8044C12.2206 16.9495 12.4189 17.026 12.622 17.0144C12.8127 17.0187 12.9963 16.9424 13.1279 16.8044L18.2729 11.5925C18.5521 11.313 18.5521 10.8602 18.2729 10.5807C18.14 10.4443 17.9575 10.3673 17.767 10.3673C17.5765 10.3673 17.3941 10.4443 17.2611 10.5807L12.5838 15.2484L10.6747 13.3394C10.3927 13.0765 9.95309 13.0843 9.68046 13.3569C9.40783 13.6295 9.40007 14.0691 9.66291 14.3512L12.0779 16.8044Z"
                    fill="#1E1D1D"></path>
                </svg>
              </div>
              <div class="rounded-full min-h-[27px] w-[27px] border-2 border-[#666]" v-else></div>
            </div>
            <transition name="fade" v-if="tooltipHighShow == true">
              <DefaultStepTooltip :title="$t('steps.advanced_title')" :message="$t('steps.advanced_description')"
                :subtitle="$t('steps.advanced_subtitle')" :completed="profile?.technologies?.length > 0 &&
                  profile?.formalEducation?.length > 0 &&
                  profile?.pastJobs?.length > 0 &&
                  // ToDo: && profile?.idealCompany?.length > 0
                  (profile?.socialNetworks?.linkedin != null ||
                    profile?.socialNetworks?.github != null ||
                    profile?.socialNetworks?.twitter != null ||
                    profile?.socialNetworks?.bitbucket != null ||
                    profile?.socialNetworks?.dribble != null ||
                    profile?.socialNetworks?.behance != null)
                  "></DefaultStepTooltip>
            </transition>

            <p
              class="flex-grow-0 flex-shrink-0 text-nowrap text-[11.912351608276367px] font-semibold text-center uppercase dark:text-white">
              {{ $t('steps.advanced') }}
            </p>
          </div>

          <div class="flex-grow-0 flex-shrink-0 w-[106.93px] h-[2.3px]" :class="[
            // ToDo: && profile?.idealCompany?.length > 0
            // ToDo: && profile?.jobTypePreference?.length > 0
            profile?.technologies?.length > 0 &&
              profile?.formalEducation?.length > 0 &&
              profile?.pastJobs?.length > 0 &&
              (profile?.socialNetworks?.linkedin != null ||
                profile?.socialNetworks?.github != null ||
                profile?.socialNetworks?.twitter != null ||
                profile?.socialNetworks?.bitbucket != null ||
                profile?.socialNetworks?.dribble != null ||
                profile?.socialNetworks?.behance != null)
              ? 'bg-[#B9A3FF]'
              : 'bg-[#666]'
          ]"></div>
        </div>
        <div class="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 h-16 w-[58px] relative gap-4">
          <router-link :to="
            // ToDo: && profile?.idealCompany?.length > 0
            // ToDo: && profile?.jobTypePreference?.length > 0
            profile?.technologies?.length > 0 &&
              profile?.formalEducation?.length > 0 &&
              profile?.pastJobs?.length > 0 &&
              (profile?.socialNetworks?.linkedin != null ||
                profile?.socialNetworks?.github != null ||
                profile?.socialNetworks?.twitter != null ||
                profile?.socialNetworks?.bitbucket != null ||
                profile?.socialNetworks?.dribble != null ||
                profile?.socialNetworks?.behance != null)
              ? { name: 'Tests' }
              : $route.path
            ">
            <div @mouseenter="tooltipTestsShow = true" @mouseleave="tooltipTestsShow = false">
              <div class="flex-grow-0 flex-shrink-0 w-16 h-16">
                <div class="absolute rotate-45 rounded-[6.83px] border-[2.03px]" :class="[
                  isActiveRoute('/test/')
                    ? 'shadow-[0_0_2px_#B9A3FF,inset_0_0_3px_#B9A3FF,0_0_2px_#B9A3FF,0_0_4px_#B9A3FF,0_0_45px_#B9A3FF] left-[-4px] w-[65px] h-[65px] border-[#B9A3FF] bg-[#B9A3FF]'
                    : // ToDo: && profile?.idealCompany?.length > 0
                    // ToDo: && profile?.jobTypePreference?.length > 0
                    profile?.technologies?.length > 0 &&
                      profile?.formalEducation?.length > 0 &&
                      profile?.pastJobs?.length > 0 &&
                      (profile?.socialNetworks?.linkedin != null ||
                        profile?.socialNetworks?.github != null ||
                        profile?.socialNetworks?.twitter != null ||
                        profile?.socialNetworks?.bitbucket != null ||
                        profile?.socialNetworks?.dribble != null ||
                        profile?.socialNetworks?.behance != null)
                      ? 'w-[45px] h-[45px] left-[6px] top-[10px] rotate-45 rounded-[6.83px] border-[2.03px] border-[#B9A3FF] bg-[#B9A3FF]'
                      : 'w-[45px] h-[45px] left-[6px] top-[10px] border-[#666]'
                ]"></div>

                <p class="absolute text-[21.367490768432617px] text-left text-black" :class="[
                  isActiveRoute('/test/')
                    ? 'scale-150 left-[15px] top-[50px]'
                    : 'scale-100 left-[14px] top-[20px]'
                ]" v-if="
                    // ToDo: && profile?.idealCompany?.length > 0
                    // ToDo: && profile?.jobTypePreference?.length > 0
                    profile?.technologies?.length > 0 &&
                    profile?.formalEducation?.length > 0 &&
                    profile?.pastJobs?.length > 0 &&
                    (profile?.socialNetworks?.linkedin != null ||
                      profile?.socialNetworks?.github != null ||
                      profile?.socialNetworks?.twitter != null ||
                      profile?.socialNetworks?.bitbucket != null ||
                      profile?.socialNetworks?.dribble != null ||
                      profile?.socialNetworks?.behance != null)
                  ">
                  <svg class="w-[25px]">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M13.9187 22.0639C9.30585 22.0639 5.56641 18.3245 5.56641 13.7116C5.56641 9.09882 9.30585 5.35938 13.9187 5.35938C18.5315 5.35938 22.271 9.09882 22.271 13.7116C22.2605 18.3201 18.5272 22.0534 13.9187 22.0639ZM13.9187 6.78925C10.0966 6.78925 6.99824 9.88765 6.99824 13.7097C6.99824 17.5318 10.0966 20.6302 13.9187 20.6302C17.7408 20.6302 20.8391 17.5318 20.8391 13.7097C20.8339 9.88983 17.7386 6.79451 13.9187 6.78925ZM12.0096 16.8044C12.1522 16.9495 12.3505 17.026 12.5536 17.0144C12.7443 17.0187 12.928 16.9424 13.0596 16.8044L18.2046 11.5925C18.4837 11.313 18.4837 10.8602 18.2046 10.5807C18.0716 10.4443 17.8892 10.3673 17.6986 10.3673C17.5081 10.3673 17.3257 10.4443 17.1927 10.5807L12.5155 15.2484L10.6064 13.3394C10.3243 13.0765 9.88473 13.0843 9.6121 13.3569C9.33947 13.6295 9.33171 14.0691 9.59455 14.3512L12.0096 16.8044Z"
                      fill="#1E1D1D"></path>
                  </svg>
                </p>
                <p class="absolute left-[22.89px] top-[18px] text-[21.367490768432617px] text-left text-[#666]" v-else>
                  2
                </p>
              </div>
            </div>
          </router-link>
          <transition name="fade" v-if="tooltipTestsShow == true">
            <DefaultStepTooltip :title="$t('steps.step_2_title')" :message="$t('steps.step_2_description')"
              :subtitle="$t('steps.step_2_subtitle')" :completed="
                // ToDo: && profile?.idealCompany?.length > 0
                // ToDo: && profile?.jobTypePreference?.length > 0
                profile?.technologies?.length > 0 &&
                profile?.formalEducation?.length > 0 &&
                profile?.pastJobs?.length > 0 &&
                (profile?.socialNetworks?.linkedin != null ||
                  profile?.socialNetworks?.github != null ||
                  profile?.socialNetworks?.twitter != null ||
                  profile?.socialNetworks?.bitbucket != null ||
                  profile?.socialNetworks?.dribble != null ||
                  profile?.socialNetworks?.behance != null)
                "></DefaultStepTooltip>
          </transition>
          <p class="flex-grow-0 flex-shrink-0 text-[11.912351608276367px] text-nowrap font-semibold text-center uppercase text-[#666]"
            :class="[isActiveRoute('/test/') ? 'mt-3 text-white' : '']">
            {{ $t('steps.step_2') }}
          </p>
        </div>
        <div class="flex-grow-0 flex-shrink-0 w-[106.93px] h-[2.3px]" :class="[
          // ToDo: && profile?.idealCompany?.length > 0
          // ToDo: && profile?.jobTypePreference?.length > 0
          profile?.technologies?.length > 0 &&
            profile?.formalEducation?.length > 0 &&
            profile?.pastJobs?.length > 0 &&
            profile?.takenAssestments?.length > 0 &&
            (profile?.socialNetworks?.linkedin != null ||
              profile?.socialNetworks?.github != null ||
              profile?.socialNetworks?.twitter != null ||
              profile?.socialNetworks?.bitbucket != null ||
              profile?.socialNetworks?.dribble != null ||
              profile?.socialNetworks?.behance != null)
            ? 'bg-[#31BB9A]'
            : 'bg-[#666]'
        ]"></div>
        <div class="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 h-16 w-[58px] relative gap-4">
          <div @mouseenter="tooltipPreQualifyShow = true" @mouseleave="tooltipPreQualifyShow = false">
            <div class="flex-grow-0 flex-shrink-0 w-16 h-16">
              <div class="absolute rotate-45 rounded-[6.83px] border-[2.03px]" :class="[
                // ToDo: && profile?.idealCompany?.length > 0
                // ToDo: && profile?.jobTypePreference?.length > 0
                profile?.technologies?.length > 0 &&
                  profile?.formalEducation?.length > 0 &&
                  profile?.pastJobs?.length > 0 &&
                  profile?.takenAssestments?.length > 0 &&
                  (profile?.socialNetworks?.linkedin != null ||
                    profile?.socialNetworks?.github != null ||
                    profile?.socialNetworks?.twitter != null ||
                    profile?.socialNetworks?.bitbucket != null ||
                    profile?.socialNetworks?.dribble != null ||
                    profile?.socialNetworks?.behance != null)
                  ? 'w-[65px] h-[65px] left-[-4px] mb-4 top-0 rotate-45 rounded-[6.83px] border-[2.03px] border-[#31B99B] shadow-[0_0_2px_#31B99B,inset_0_0_3px_#31B99B,0_0_2px_#31B99B,0_0_4px_#31B99B,0_0_45px_#31B99B] bg-[#31B99B]'
                  : 'w-[45px] h-[45px] left-[6px] top-[10px] border-[#666]'
              ]"></div>

              <p v-if="
                // ToDo: && profile?.idealCompany?.length > 0
                // ToDo: && profile?.jobTypePreference?.length > 0
                profile?.technologies?.length > 0 &&
                  profile?.formalEducation?.length > 0 &&
                  profile?.pastJobs?.length > 0 &&
                  profile?.takenAssestments?.length > 0 &&
                  (profile?.socialNetworks?.linkedin != null ||
                    profile?.socialNetworks?.github != null ||
                    profile?.socialNetworks?.twitter != null ||
                    profile?.socialNetworks?.bitbucket != null ||
                    profile?.socialNetworks?.dribble != null ||
                    profile?.socialNetworks?.behance != null)
                  ? '!text-3xl !left-[20px] !top-[17px] !text-black font-normal'
                  : ''
              ">
                <svg class="w-[25px]">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M13.9187 22.0639C9.30585 22.0639 5.56641 18.3245 5.56641 13.7116C5.56641 9.09882 9.30585 5.35938 13.9187 5.35938C18.5315 5.35938 22.271 9.09882 22.271 13.7116C22.2605 18.3201 18.5272 22.0534 13.9187 22.0639ZM13.9187 6.78925C10.0966 6.78925 6.99824 9.88765 6.99824 13.7097C6.99824 17.5318 10.0966 20.6302 13.9187 20.6302C17.7408 20.6302 20.8391 17.5318 20.8391 13.7097C20.8339 9.88983 17.7386 6.79451 13.9187 6.78925ZM12.0096 16.8044C12.1522 16.9495 12.3505 17.026 12.5536 17.0144C12.7443 17.0187 12.928 16.9424 13.0596 16.8044L18.2046 11.5925C18.4837 11.313 18.4837 10.8602 18.2046 10.5807C18.0716 10.4443 17.8892 10.3673 17.6986 10.3673C17.5081 10.3673 17.3257 10.4443 17.1927 10.5807L12.5155 15.2484L10.6064 13.3394C10.3243 13.0765 9.88473 13.0843 9.6121 13.3569C9.33947 13.6295 9.33171 14.0691 9.59455 14.3512L12.0096 16.8044Z"
                    fill="#1E1D1D"></path>
                </svg>
              </p>
              <p v-else class="absolute left-[22.89px] top-[18px] text-[21.367490768432617px] text-left text-[#666]">
                3
              </p>
            </div>
          </div>
          <transition name="fade" v-if="tooltipPreQualifyShow == true">
            <DefaultStepTooltip :success="true" :title="$t('profile.interview_title')"
              :message="$t('profile.interview_message')" :subtitle="$t('profile.interview_subtitle')" :completed="
                // ToDo: && profile?.idealCompany?.length > 0
                // ToDo: && profile?.jobTypePreference?.length > 0
                profile?.technologies?.length > 0 &&
                profile?.formalEducation?.length > 0 &&
                profile?.pastJobs?.length > 0 &&
                profile?.takenAssestments?.length > 0 &&
                (profile?.socialNetworks?.linkedin != null ||
                  profile?.socialNetworks?.github != null ||
                  profile?.socialNetworks?.twitter != null ||
                  profile?.socialNetworks?.bitbucket != null ||
                  profile?.socialNetworks?.dribble != null ||
                  profile?.socialNetworks?.behance != null)
                "></DefaultStepTooltip>
          </transition>
          <p
            class="flex-grow-0 flex-shrink-0 text-[11.912351608276367px] text-nowrap font-semibold text-center uppercase text-[#666]">
            {{ $t('steps.step_3') }}
          </p>
        </div>
        <div class="flex-grow-0 flex-shrink-0 w-[106.93px] h-[2.3px]" :class="[
          // ToDo: Status pre-califica
          // ToDo: && profile?.idealCompany?.length > 0
          // ToDo: && profile?.jobTypePreference?.length > 0
          profile?.technologies?.length > 0 &&
            profile?.formalEducation?.length > 0 &&
            profile?.pastJobs?.length > 0 &&
            profile?.takenAssestments?.length > 0 &&
            (profile?.socialNetworks?.linkedin != null ||
              profile?.socialNetworks?.github != null ||
              profile?.socialNetworks?.twitter != null ||
              profile?.socialNetworks?.bitbucket != null ||
              profile?.socialNetworks?.dribble != null ||
              profile?.socialNetworks?.behance != null)
            ? 'bg-[#31BB9A]'
            : 'bg-[#666]'
        ]"></div>
        <div class="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 h-16 w-[58px] relative gap-4">
          <div class="flex-grow-0 flex-shrink-0 w-16 h-16">
            <div @mouseenter="tooltipReceiveInterviewsShow = true" @mouseleave="tooltipReceiveInterviewsShow = false">
              <div class="absolute rotate-45 rounded-[6.83px] border-[2.03px]" :class="[
                // ToDo: Status pre-califica
                // ToDo: && profile?.idealCompany?.length > 0
                // ToDo: && profile?.jobTypePreference?.length > 0
                profile?.technologies?.length > 0 &&
                  profile?.formalEducation?.length > 0 &&
                  profile?.pastJobs?.length > 0 &&
                  profile?.takenAssestments?.length > 0 &&
                  (profile?.socialNetworks?.linkedin != null ||
                    profile?.socialNetworks?.github != null ||
                    profile?.socialNetworks?.twitter != null ||
                    profile?.socialNetworks?.bitbucket != null ||
                    profile?.socialNetworks?.dribble != null ||
                    profile?.socialNetworks?.behance != null)
                  ? 'w-[65px] h-[65px] left-[-4px] mb-4 top-0 rotate-45 rounded-[6.83px] border-[2.03px] border-[#31B99B] shadow-[0_0_2px_#31B99B,inset_0_0_3px_#31B99B,0_0_2px_#31B99B,0_0_4px_#31B99B,0_0_45px_#31B99B] bg-[#31B99B]'
                  : 'w-[45px] h-[45px] left-[6px] top-[10px] border-[#666]'
              ]"></div>
              <p class="absolute left-[22.89px] top-[18px] text-[21.367490768432617px] text-left text-[#666]" :class="[
                // ToDo: Status pre-califica
                // ToDo: && profile?.idealCompany?.length > 0
                // ToDo: && profile?.jobTypePreference?.length > 0
                profile?.technologies?.length > 0 &&
                  profile?.formalEducation?.length > 0 &&
                  profile?.pastJobs?.length > 0 &&
                  profile?.takenAssestments?.length > 0 &&
                  (profile?.socialNetworks?.linkedin != null ||
                    profile?.socialNetworks?.github != null ||
                    profile?.socialNetworks?.twitter != null ||
                    profile?.socialNetworks?.bitbucket != null ||
                    profile?.socialNetworks?.dribble != null ||
                    profile?.socialNetworks?.behance != null)
                  ? '!text-3xl !left-[20px] !top-[17px] !text-black font-normal'
                  : ''
              ]">
                4
              </p>
            </div>
          </div>
          <transition name="fade" v-if="tooltipReceiveInterviewsShow == true">
            <DefaultStepTooltip :success="true" :title="$t('job-oportunities.title')"
              :message="$t('profile.notification_message')" :subtitle="$t('profile.notification_subtitle')" :completed="
                // ToDo: Status pre-califica
                // ToDo: && profile?.idealCompany?.length > 0
                // ToDo: && profile?.jobTypePreference?.length > 0
                profile?.technologies?.length > 0 &&
                profile?.formalEducation?.length > 0 &&
                profile?.pastJobs?.length > 0 &&
                profile?.takenAssestments?.length > 0 &&
                (profile?.socialNetworks?.linkedin != null ||
                  profile?.socialNetworks?.github != null ||
                  profile?.socialNetworks?.twitter != null ||
                  profile?.socialNetworks?.bitbucket != null ||
                  profile?.socialNetworks?.dribble != null ||
                  profile?.socialNetworks?.behance != null)
                "></DefaultStepTooltip>
          </transition>
          <p
            class="flex-grow-0 flex-shrink-0 text-[11.912351608276367px] text-nowrap font-semibold text-center uppercase text-[#666]">
            <span
              class="flex-grow-0 flex-shrink-0 text-[11.912351608276367px] font-semibold text-center uppercase text-[#666]">{{
                $t('steps.step_4_1') }}</span><br /><span
              class="flex-grow-0 flex-shrink-0 text-[11.912351608276367px] font-semibold text-center uppercase text-[#666]">{{
                $t('steps.step_4_2') }}</span>
          </p>
        </div>
      </div>
      <div :class="[getCurrentStep == 1 ? 'tutorial-step-1-logo' : '']">
        <svg width="126" height="100" viewBox="0 0 126 100" fill="none" xmlns="http://www.w3.org/2000/svg"
          class="w-[125px] h-[100px] scale-110" preserveAspectRatio="none">
          <path
            d="M35.8334 30.471C34.5655 27.7075 32.6722 22.4643 33.4488 15.9798C33.7311 13.6264 34.5686 6.62096 39.8804 2.41488C41.0033 1.52594 43.1679 -0.136534 46.092 0.017861C46.5116 0.0396946 49.7195 0.251792 50.9968 2.37589C52.4316 4.76043 50.7067 8.24132 49.5433 10.3592C45.7349 17.2898 42.3367 23.5279 43.026 29.4261L35.8349 30.4741L35.8334 30.471Z"
            fill="#5A57DE"></path>
          <path
            d="M33.4096 34.9833C32.7297 34.1224 31.7362 32.814 30.6368 31.1546C23.9245 21.0207 24.2364 14.3864 19.5796 12.9407C16.7911 12.0752 14.1898 13.6815 13.7204 13.9856C9.48781 16.732 8.98408 23.2914 10.6887 27.6316C12.4681 32.1605 16.7787 35.4652 20.952 37.6501C22.1497 38.277 23.3849 38.8322 24.6466 39.3204C25.0583 39.4795 28.3084 40.8737 28.5376 40.6086C30.1626 38.7324 31.7877 36.8579 33.4112 34.9833H33.4096Z"
            fill="#5A57DE"></path>
          <path
            d="M31.9338 40.0773C30.4975 39.5003 28.5434 38.5552 26.5144 36.9972C21.3227 33.0063 17.4769 26.4017 18.6434 25.2164C18.6668 25.193 18.737 25.126 18.8695 25.0776C20.4743 24.4834 22.784 29.7235 27.3051 33.6645C28.5886 34.7842 30.5676 36.2471 33.4434 37.49C33.1986 37.6413 32.8025 37.9282 32.4734 38.4179C32.0133 39.0979 31.9463 39.7763 31.9338 40.0789V40.0773Z"
            fill="#E1E7FF"></path>
          <path
            d="M28.1413 42.7133C21.7909 40.5923 14.0353 39.0686 7.58812 41.6668C3.60973 43.27 -0.384255 47.9767 0.730818 52.5508C0.799437 52.8347 1.53242 55.6559 3.92008 56.6649C5.8274 57.4712 7.82673 56.7413 8.90749 56.2672C16.2483 53.0561 19.8882 48.2153 26.9374 48.4695L28.1398 42.7133H28.1413Z"
            fill="#5A57DE"></path>
          <path
            d="M28.5154 44.7198C25.3511 43.9946 22.7794 44.0211 21.0515 44.1724C15.7974 44.6294 9.74324 46.9843 9.93818 48.3083C9.94754 48.3707 9.96781 48.4128 9.98185 48.4409C10.523 49.5123 14.6995 48.1368 18.0743 47.5535C20.2639 47.1745 23.4812 46.8938 27.6218 47.513C27.9197 46.5819 28.2176 45.6524 28.5154 44.7214V44.7198Z"
            fill="#E1E7FF"></path>
          <path
            d="M38.2069 30.4732C37.8279 29.1725 37.3382 27.025 37.3975 24.3691C37.5519 17.412 41.3899 10.9259 43.3658 10.8604C43.4579 10.8572 43.6497 10.8541 43.7994 10.9758C44.548 11.5824 43.0196 14.2742 42.1806 16.4903C39.8039 22.7721 41.2402 29.8103 41.2402 29.8103L38.2053 30.4716L38.2069 30.4732Z"
            fill="#E1E7FF"></path>
          <path
            d="M97.6211 45.0968C100.342 43.7384 105.102 40.8392 108.563 35.3013C109.82 33.291 113.556 27.3071 112.055 20.6993C111.736 19.3019 111.094 16.6507 108.719 14.9383C108.377 14.6935 105.746 12.847 103.419 13.7001C100.807 14.6592 99.9682 18.452 99.5487 20.8303C98.1716 28.6171 96.9084 35.607 92.6758 39.7709L97.6211 45.0968Z"
            fill="#5A57DE"></path>
          <path
            d="M96.6793 50.1291C97.7491 49.8843 99.343 49.4882 101.239 48.8831C112.821 45.1948 116.738 39.8299 121.272 41.6218C123.987 42.6948 125.007 45.5784 125.181 46.1086C126.758 50.9011 123.039 56.3283 118.99 58.6411C114.764 61.0537 109.335 60.929 104.714 60.0135C103.387 59.7515 102.078 59.4084 100.789 58.9998C100.368 58.8657 96.9616 57.9144 96.9491 57.565C96.8586 55.0869 96.7682 52.6088 96.6777 50.1291H96.6793Z"
            fill="#5A57DE"></path>
          <path
            d="M94.6367 55.0207C96.1167 55.4714 98.2315 55.9596 100.789 56.0173C107.336 56.1623 114.471 53.4269 114.307 51.7706C114.304 51.7379 114.292 51.6412 114.218 51.5211C113.342 50.052 108.256 52.6877 102.263 52.9231C100.56 52.9902 98.102 52.8904 95.0828 52.056C95.1794 52.3274 95.3073 52.7984 95.2574 53.3863C95.1888 54.2051 94.8161 54.7774 94.6367 55.0207Z"
            fill="#E1E7FF"></path>
          <path
            d="M95.9371 59.4531C102.214 61.7815 109.212 65.4558 112.605 71.5209C114.7 75.2638 114.861 81.4364 111.125 84.2997C110.895 84.4775 108.555 86.2164 106.063 85.5068C104.072 84.9392 102.972 83.1176 102.425 82.0712C98.7178 74.9674 98.9143 68.9149 93.2656 64.6932L95.9371 59.4547V59.4531Z"
            fill="#5A57DE"></path>
          <path
            d="M94.3882 60.7812C97.3077 62.1989 99.2946 63.8333 100.547 65.0326C104.354 68.6834 107.596 74.3118 106.613 75.221C106.567 75.2647 106.525 75.2834 106.497 75.2975C105.403 75.7934 103.011 72.1035 100.748 69.5334C99.2805 67.8662 96.9474 65.6283 93.334 63.5167C93.6849 62.6059 94.0373 61.6951 94.3882 60.7828V60.7812Z"
            fill="#E1E7FF"></path>
          <path
            d="M95.7693 43.6114C96.8797 42.8348 98.6077 41.4686 100.225 39.3632C104.465 33.8456 105.541 26.3863 104.041 25.0965C103.973 25.0373 103.824 24.9141 103.631 24.9141C102.667 24.9172 102.171 27.9739 101.435 30.2259C99.3501 36.6091 93.8184 41.1926 93.8184 41.1941L95.7678 43.6114H95.7693Z"
            fill="#E1E7FF"></path>
          <path
            d="M26.9457 82.4759C40.2673 85.5732 49.6074 78.0219 50.0098 77.5961C50.0191 77.5852 50.0316 77.5727 50.0316 77.5727C50.2297 77.3606 51.3276 76.1504 50.9985 75.5781C50.5275 74.7609 47.3461 75.5796 47.226 75.0946C47.1948 74.9683 47.4053 74.8591 47.3866 74.5675C47.3726 74.3523 47.2541 74.1495 47.0435 73.9437C46.3651 73.2809 45.4668 73.1171 44.6325 72.8411C40.194 71.3767 35.8772 69.6035 31.5464 67.8552C26.3422 65.7561 17.2875 61.3457 16.4656 60.8731C16.4422 60.8591 16.4017 60.8357 16.3424 60.803C16.1163 60.6798 15.7092 60.4755 15.1556 60.3273C15.1556 60.3273 14.9856 60.2821 14.8484 60.254C14.29 60.1417 13.15 60.5082 12.1691 61.4283C10.806 62.7072 9.49913 65.2866 10.5503 66.9117C10.8653 67.3998 11.3238 67.693 11.7277 67.8739C11.4829 68.8674 11.2942 70.2429 11.6108 71.779C13.1485 79.2679 24.8965 81.9987 26.9473 82.4744L26.9457 82.4759Z"
            fill="#C3CDEF"></path>
          <path
            d="M82.9129 99.1946C82.1144 98.0046 81.0742 96.8366 79.6347 95.7168C79.0234 95.2411 78.4963 94.8934 78.1703 94.6875L75.7031 99.193H82.9129V99.1946Z"
            fill="#5A57DE"></path>
          <path
            d="M80.7595 80.8022C80.6862 80.9036 80.4757 80.8193 80.2761 81.0158C80.1295 81.1609 80.0546 81.3745 80.0359 81.6568C79.9782 82.566 80.4227 83.324 80.7455 84.1037C82.461 88.2537 83.8755 92.5081 85.3181 96.7579C85.5692 97.4955 85.8343 98.3221 86.1056 99.1985H100.41C100.258 98.7681 100.095 98.3221 99.9123 97.8558C94.811 84.7853 85.0623 77.393 80.8328 77.209C80.6753 77.2028 78.9645 77.1279 78.7446 77.7548C78.4467 78.611 81.0371 80.4139 80.7595 80.8053V80.8022Z"
            fill="#C3CDEF"></path>
          <path
            d="M86.676 99.1956C83.178 85.8241 79.524 77.298 79.524 77.298C78.6553 75.2738 77.5652 72.7925 76.2583 69.9744C73.7022 71.4544 67.6325 74.508 59.345 74.4098C51.6752 74.3193 45.8285 71.5075 42.7094 69.6797C42.1043 71.1831 41.2263 73.3774 40.1861 76.0317C40.1861 76.0317 36.49 85.4576 32.8516 99.1956H86.676Z"
            fill="#E1E7FF"></path>
          <path
            d="M99.831 58.6359C101.217 50.8289 98.5849 44.4862 97.5369 42.2701C91.3565 29.1965 76.834 25.4239 71.7359 24.0999C68.6698 23.303 47.6223 17.8337 34.3007 30.4051C32.6818 31.9319 27.6009 36.8632 26.2472 44.848C24.2182 56.8191 32.253 65.7459 33.0998 66.6567C37.1905 71.0561 42.599 72.6999 53.4128 75.9905C59.7056 77.9041 67.4081 79.5073 76.257 79.5166C79.2607 79.5198 80.7594 79.5088 82.1848 79.2001C91.1241 77.2615 98.1748 67.9667 99.8295 58.6359H99.831Z"
            fill="#E1E7FF"></path>
          <path
            d="M69.5165 61.9734C66.4036 61.9734 63.3048 61.6116 60.2871 60.8895C57.3474 60.1877 54.4841 59.1475 51.7751 57.7954C50.9236 57.3696 50.579 56.3356 51.0047 55.4841C51.4305 54.6326 52.4645 54.288 53.316 54.7137C55.7878 55.9489 58.4016 56.8986 61.0872 57.5396C64.2359 58.2897 67.4813 58.6157 70.7314 58.5081C71.6842 58.4753 72.4781 59.2223 72.5092 60.1721C72.5404 61.1234 71.795 61.9188 70.8452 61.95C70.4023 61.964 69.9594 61.9718 69.5165 61.9718V61.9734Z"
            fill="#242424"></path>
          <path
            d="M45.0798 44.2591C44.6119 46.2959 42.5814 47.5685 40.5446 47.099C38.5094 46.6312 37.2384 44.5991 37.7094 42.5623C38.1773 40.5256 40.2078 39.253 42.2445 39.7224C44.2797 40.1903 45.5492 42.2224 45.0798 44.2591Z"
            fill="white"></path>
          <path
            d="M90.6735 54.7591C90.2057 56.7959 88.1752 58.0685 86.1384 57.599C84.1032 57.1312 82.8322 55.0991 83.3031 53.0623C83.771 51.0256 85.8015 49.753 87.8383 50.2224C89.8735 50.6903 91.143 52.7224 90.6735 54.7591Z"
            fill="white"></path>
          <path
            d="M44.1513 44.0445C43.8004 45.5682 42.2814 46.5195 40.7593 46.1686C39.2372 45.8177 38.2874 44.2987 38.6368 42.775C38.9877 41.2514 40.5067 40.3 42.0288 40.6509C43.5509 41.0018 44.5007 42.5208 44.1513 44.0445Z"
            fill="black"></path>
          <path
            d="M89.7431 54.5445C89.3922 56.0682 87.8732 57.0195 86.3511 56.6686C84.829 56.3177 83.8792 54.7987 84.2286 53.275C84.5779 51.7514 86.0985 50.8 87.6206 51.1509C89.1427 51.5018 90.094 53.0224 89.7431 54.5445Z"
            fill="black"></path>
          <path
            d="M40.5357 42.5948C40.8337 42.5948 41.0753 42.3532 41.0753 42.0552C41.0753 41.7572 40.8337 41.5156 40.5357 41.5156C40.2377 41.5156 39.9961 41.7572 39.9961 42.0552C39.9961 42.3532 40.2377 42.5948 40.5357 42.5948Z"
            fill="white"></path>
          <path
            d="M86.2427 53.1183C86.5407 53.1183 86.7823 52.8767 86.7823 52.5787C86.7823 52.2806 86.5407 52.0391 86.2427 52.0391C85.9447 52.0391 85.7031 52.2806 85.7031 52.5787C85.7031 52.8767 85.9447 53.1183 86.2427 53.1183Z"
            fill="white"></path>
        </svg>
      </div>
      <div class="tutorial-step-1-text bg-[#B9A3FF] text-black w-[280px] rounded-xl p-4 shadow-xl absolute"
        v-if="getCurrentStep == 1">
        <div class="tutorial-step-1-text-polygon bg-[#B9A3FF] absolute"></div>
        <div class="tutorial-step-1-text-exit absolute cursor-pointer" @click="goToNextStep(-1)">
          <span>
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.63238 14.0905L5.10156 13.5597L9.30156 9.35972L5.10156 5.15972L5.63238 4.62891L9.83238 8.82891L14.0324 4.62891L14.5632 5.15972L10.3632 9.35972L14.5632 13.5597L14.0324 14.0905L9.83238 9.89053L5.63238 14.0905Z"
                fill="#1E1D1D" />
            </svg>
          </span>
        </div>
        <h3 class="font-bold text-3xl m-0">
          H<span class="inline-block"><svg width="17" height="17" viewBox="0 0 17 17" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_325_11872)">
                <rect width="16.14" height="16.14" transform="translate(0.142578 -0.00390625)" fill="#B9A3FF" />
                <path
                  d="M15.8052 11.1814L9.38336 8.06379L15.8076 4.94553C15.9898 4.84408 16.1387 4.63267 16.2061 4.37205C16.2742 4.10779 16.2468 3.8411 16.1326 3.65825C16.0075 3.43348 15.742 3.2567 15.434 3.18744C15.1946 3.13338 14.9741 3.15585 14.8453 3.24819L8.85545 7.30381L9.41678 0.131145C9.45383 -0.193256 9.34692 -0.432001 9.09967 -0.586912C8.7455 -0.808039 8.17992 -0.797712 7.81117 -0.562613C7.63621 -0.450834 7.43756 -0.241856 7.48313 0.121425L8.04566 7.30321L2.06004 3.25062C1.62993 3.01248 1.00604 3.23665 0.775798 3.58839C0.531585 4.14242 0.745422 4.77117 1.09595 4.94613L7.51715 8.06379L1.09898 11.1796C0.908839 11.2871 0.761826 11.4967 0.695002 11.7555C0.626963 12.0198 0.654299 12.2865 0.768508 12.4687C0.893652 12.6935 1.15913 12.8703 1.46712 12.9395C1.70648 12.9936 1.927 12.9711 2.05579 12.8788L8.04566 8.82315L7.48434 15.9958C7.43817 16.3688 7.63682 16.5778 7.81178 16.6896C8.17992 16.9247 8.74611 16.935 9.10027 16.7139C9.34752 16.559 9.45444 16.3202 9.41799 16.0037L8.85545 8.82376L14.8411 12.8763C15.2718 13.1145 15.8957 12.8903 16.1253 12.5386C16.3695 11.9852 16.1557 11.3564 15.8052 11.1814Z"
                  fill="#5956D9" />
              </g>
              <defs>
                <clipPath id="clip0_325_11872">
                  <rect width="16.14" height="16.14" fill="white" transform="translate(0.142578 -0.00390625)" />
                </clipPath>
              </defs>
            </svg> </span>
          {{ $t('profile_steps.la_soy_wilson_bienvenid') }}
        </h3>
        <p class="my-2">
          {{ $t('profile_steps.te_contare_como_funciona_tu_perfil') }}
        </p>
        <div class="flex justify-start">
          <button type="button" class="btn btn-md btn-rounded-lg btn-primary" @click="goToNextStep(2)">
            <span class="mr-4 text-sm"> {{ $t('profile_steps.empezar') }} </span>
            <svg width="36" height="16" viewBox="0 0 36 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M35.0108 7.28319C33.5064 6.84251 32.1084 6.15869 30.8623 5.23174C29.2668 4.04646 27.9295 2.45089 27.033 0.642578L26.0756 1.11365C27.0633 3.07392 28.4918 4.77586 30.2241 6.06752C30.8167 6.5082 31.4398 6.90329 32.1084 7.2528H0.394531V8.31651H32.3667C31.6829 8.7116 31.0447 9.15229 30.452 9.66895C28.1423 11.6596 27.0785 14.0454 26.5771 15.6865L27.5952 15.9904C28.0511 14.4709 29.0236 12.2826 31.1358 10.4743C32.2755 9.50179 33.5824 8.75719 35.026 8.28612L35.0108 7.28319ZM33.2481 7.86063V7.78465C33.2785 7.79985 33.2937 7.79985 33.3241 7.81505C33.2937 7.83024 33.2785 7.84544 33.2481 7.86063Z"
                fill="#1E1D1D" />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="tutorial-step-2-text bg-[#B9A3FF] text-black w-[290px] rounded-xl p-4 shadow-xl absolute"
      v-if="getCurrentStep == 2">
      <div class="tutorial-step-2-text-polygon bg-[#B9A3FF] absolute"></div>
      <div class="tutorial-step-2-text-exit absolute cursor-pointer" @click="goToNextStep(-1)">
        <span>
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.63238 14.0905L5.10156 13.5597L9.30156 9.35972L5.10156 5.15972L5.63238 4.62891L9.83238 8.82891L14.0324 4.62891L14.5632 5.15972L10.3632 9.35972L14.5632 13.5597L14.0324 14.0905L9.83238 9.89053L5.63238 14.0905Z"
              fill="#1E1D1D" />
          </svg>
        </span>
      </div>
      <h3 class="font-bold m-0" style="font-size: 1.55rem">
        {{ $t('profile_steps.profile_progress_1') }}<span class="inline-block"><svg width="17" height="18"
            viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.1182 12.1268L9.69635 9.0091L16.1206 5.89084C16.3028 5.78939 16.4517 5.57798 16.5191 5.31737C16.5871 5.05311 16.5598 4.78642 16.4456 4.60356C16.3205 4.37879 16.055 4.20201 15.747 4.13275C15.5076 4.07869 15.2871 4.10116 15.1583 4.1935L9.16844 8.24913L9.72976 1.07646C9.76682 0.752056 9.6599 0.513311 9.41265 0.358401C9.05849 0.137273 8.49291 0.1476 8.12416 0.3827C7.9492 0.494479 7.75055 0.703457 7.79611 1.06674L8.35865 8.24852L2.37303 4.19593C1.94292 3.9578 1.31903 4.18196 1.08879 4.5337C0.844574 5.08773 1.05841 5.71649 1.40893 5.89145L7.83014 9.0091L1.41197 12.1249C1.22183 12.2325 1.07481 12.442 1.00799 12.7008C0.939951 12.9651 0.967288 13.2318 1.0815 13.414C1.20664 13.6388 1.47211 13.8156 1.78011 13.8848C2.01947 13.9389 2.23999 13.9164 2.36877 13.8241L8.35865 9.76847L7.79733 16.9411C7.75116 17.3141 7.94981 17.5231 8.12477 17.6349C8.49291 17.87 9.05909 17.8803 9.41326 17.6592C9.66051 17.5043 9.76743 17.2655 9.73098 16.949L9.16844 9.76907L15.1541 13.8217C15.5848 14.0598 16.2087 13.8356 16.4383 13.4839C16.6825 12.9305 16.4687 12.3017 16.1182 12.1268Z"
              fill="#5956D9" />
          </svg> </span>
        {{ $t('profile_steps.profile_progress_2') }}
      </h3>
      <p class="my-2">
        {{ $t('profile_steps.profile_progress_3') }}
      </p>
      <div class="flex justify-start">
        <button type="button" class="btn btn-md btn-rounded-lg btn-primary" @click="goToNextStep(3)">
          <span class="mr-4 text-sm"> {{ $t('profile_steps.siguiente') }} </span>
          <svg width="36" height="16" viewBox="0 0 36 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M35.0108 7.28319C33.5064 6.84251 32.1084 6.15869 30.8623 5.23174C29.2668 4.04646 27.9295 2.45089 27.033 0.642578L26.0756 1.11365C27.0633 3.07392 28.4918 4.77586 30.2241 6.06752C30.8167 6.5082 31.4398 6.90329 32.1084 7.2528H0.394531V8.31651H32.3667C31.6829 8.7116 31.0447 9.15229 30.452 9.66895C28.1423 11.6596 27.0785 14.0454 26.5771 15.6865L27.5952 15.9904C28.0511 14.4709 29.0236 12.2826 31.1358 10.4743C32.2755 9.50179 33.5824 8.75719 35.026 8.28612L35.0108 7.28319ZM33.2481 7.86063V7.78465C33.2785 7.79985 33.2937 7.79985 33.3241 7.81505C33.2937 7.83024 33.2785 7.84544 33.2481 7.86063Z"
              fill="#1E1D1D" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ROUTES from '@/constants/routes'
import { mapActions, mapGetters } from 'vuex'
import DefaultStepTooltip from '../DefaultStepTooltip.vue'
import Modal from '@/components/ModalReferido'
import ModalBienvenida from '@/components/ModalBienvenida'

export default {
  name: 'ProfileSteps',
  components: {
    DefaultStepTooltip,
    Modal,
    ModalBienvenida
  },
  data() {
    return {
      ROUTES,
      tooltipProfileShow: false,
      tooltipBasicShow: false,
      tooltipMediumShow: false,
      tooltipHighShow: false,
      tooltipTestsShow: false,
      tooltipPreQualifyShow: false,
      tooltipReceiveInterviewsShow: false,
      showModalReferer: false,
      showModalBienvenida: false
    }
  },
  async mounted() {
    await this.fetchProfile()
    this.showModal()
  },
  computed: {
    ...mapGetters(['profile', 'getCurrentStep']),
    softSkill() {
      return (
        this.profile?.takenAssesments?.filter(
          (item) => item.assesment.photo === 'icn-soft-skill.svg'
        ) || []
      )
    }
  },
  methods: {
    abrirModal() {
      this.showModalReferer = true
    },
    cerrarModal() {
      this.showModalReferer = false
    },
    cerrarModalBienvenida() {
      localStorage.setItem('modalBienvenidaShown', 'true')
      this.$forceUpdate() // Fuerza la actualización del DOM
    },
    ...mapActions([
      'fetchProfile',
      'fetchUser',
      'getCurrentStep',
      'updateStep'
    ]),
    isActiveRoute(route) {
      console.log(this.$route.path, route, this.$route.path.includes(route))
      return this.$route.path.includes(route)
    },
    goToNextStep(nextStep) {
      this.updateStep(nextStep)
    },
    showModal() {
      const isModalShowed = localStorage.getItem('isWelcomeShowed')
      console.log('Ejecutando showModal')
      console.log('show:', isModalShowed)
      if (isModalShowed) {
        this.showModalBienvenida = false
      } else {
        this.showModalBienvenida = true
      }
    }
  }
}
</script>
