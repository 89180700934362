<template>
  <main class="main--wrapper" v-if="jobExample">
    {{ jobExample.jobTitle }}
    <Hero
      :hero-title="jobExample.jobTitle"
      :hero-description="jobExample.company.about"
      section="pageHero"
      :hero-image="require('@/assets/images/hero/bg-trabajos-detail.svg')"
      class="hero--md mb-8 lg:mb-16"
    />
    <div class="content--wrapper">
      <section class="mb-16 lg:mb-24">
        <Title class="mb-8">
          <template #title>
            <h2>{{ $t('trabajos-detail-page.title-acerca-trabajo') }}</h2>
          </template>
        </Title>
        <div class="flex justify-between space-x-6 mb-12">
          <time
            class="text-secondary-text text-sm flex whitespace-nowrap w-full"
          >
            <img
              src="@/assets/images/icn-posted.svg"
              class="w-4 mr-2"
              alt="Posted Date"
            />
            Publicado ayer
          </time>
          <Share
            button-styles="text-base font-bold text-tertiary flex whitespace-nowrap items-center"
            :link="`${$i18n.locale}/trabajos-tech/${jobExample.id}`"
            :title="jobExample.name"
          >
            <template #button>
              {{ $t('general.compartir') }}
              <svg
                class="ml-2 w-4 fill-current"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 8.75C9.87109 8.75 9.29688 8.96875 8.85938 9.32422L6.04297 7.57422C6.07031 7.4375 6.125 7.16406 6.125 7C6.125 6.86328 6.07031 6.58984 6.04297 6.45312L8.85938 4.70312C9.29688 5.05859 9.87109 5.25 10.5 5.25C11.9492 5.25 13.125 4.10156 13.125 2.625C13.125 1.17578 11.9492 0 10.5 0C9.02344 0 7.875 1.17578 7.875 2.625C7.875 2.84375 7.875 3.03516 7.92969 3.19922L5.11328 4.94922C4.67578 4.59375 4.10156 4.375 3.5 4.375C2.02344 4.375 0.875 5.55078 0.875 7C0.875 8.47656 2.02344 9.625 3.5 9.625C4.10156 9.625 4.67578 9.43359 5.11328 9.07812L7.92969 10.8281C7.90234 10.9648 7.875 11.2383 7.875 11.375C7.875 12.8516 9.02344 14 10.5 14C11.9492 14 13.125 12.8516 13.125 11.375C13.125 9.92578 11.9492 8.75 10.5 8.75Z"
                />
              </svg>
            </template>
          </Share>
        </div>
        <div
          class="flex flex-wrap lg:flex-nowrap lg:space-x-6 mb-12 items-center"
        >
          <div
            class="w-full lg:w-4/12 mb-8 lg:mb-0 bg-contrast rounded-xl p-10 items-center justify-center flex"
          >
            <img
              class="w-32"
              :src="
                jobExample.company.logo
                  ? jobExample.company.logo
                  : '@/assets/images/clients/logo-siclo.svg'
              "
              :alt="jobExample.company.name"
            />
          </div>
          <div class="w-full lg:w-8/12">
            <ul class="list--jobs">
              <li>
                <span><img src="@/assets/images/icn-modalidad.svg" /></span
                >{{ $t('trabajos-detail-page.lbl-modalidad') }}:&nbsp;<b>
                  {{ jobExample.jobType }}</b
                >
              </li>
              <li>
                <span><img src="@/assets/images/icn-industry.svg" /></span
                >{{ $t('trabajos-detail-page.lbl-industry') }}:&nbsp;<b>
                  {{ jobExample.company.industry }}</b
                >
              </li>
              <li>
                <span><img src="@/assets/images/icn-type-position.svg" /></span
                >{{ $t('trabajos-detail-page.lbl-type-position') }}:&nbsp;<b>
                  {{ jobExample.jobTime }}</b
                >
              </li>
              <li>
                <span><img src="@/assets/images/icn-company-size.svg" /></span
                >{{ $t('trabajos-detail-page.lbl-tamaño-compania') }}:&nbsp;<b>
                  {{ jobExample.company.size }}
                  {{ $t('trabajos-detail-page.lbl-empleados') }}</b
                >
              </li>
              <li>
                <span><img src="@/assets/images/icn-level.svg" /></span
                >{{ $t('trabajos-detail-page.lbl-level') }}:&nbsp;<b> Senior</b>
              </li>
              <li>
                <span><img src="@/assets/images/icn-money.svg" /></span
                >{{ $t('trabajos-detail-page.lbl-rango-salarial') }}:&nbsp;
                <b>{{ jobExample.salaryRange }}</b>
              </li>
            </ul>
          </div>
        </div>
        <div class="w-full mb-10">
          <h4 class="font-lg mb-3 text-white font-bold flex items-center">
            {{ $t('trabajos-detail-page.title-techstack-empresa') }}
            <!-- {{ jobExample.techStack.toString() }}
            <span class="flex flex-wrap text-sm font-normal dark:text-primary-text text-secondary-text ml-6"
              ><img src="@/assets/images/icn-idioma.svg" class="mr-2" />{{
                $t('trabajos-detail-page.lbl-modalidad')
              }}:&nbsp;<b> {{ jobExample.jobType }}</b></span
            >-->
          </h4>
          <div class="list--badges">
            <template v-for="badge in jobExample.techStack" :key="badge.id">
              <span class="badge badge-line badge-sm badge-rounded-full"
                >#{{ badge }}</span
              >
            </template>
          </div>
        </div>
        <div
          class="flex flex-wrap space-y-6 lg:flex-nowrap lg:space-x-8 lg:space-y-0"
        >
          <div class="w-full lg:w-7/12">
            <h4 class="font-lg mb-3 text-white font-bold">
              {{ $t('trabajos-detail-page.title-sobre-compañia') }}
            </h4>
            <p
              class="text-base dark:text-primary-text text-secondary-text mb-8"
            >
              {{ jobExample.company.about }}
            </p>
            <h4 class="font-lg mb-3 text-white font-bold">
              {{ $t('trabajos-detail-page.title-responsabilidades') }}
            </h4>
            <ul
              class="list--bullet list--bullet--secondary mb-8 dark:text-primary-text text-secondary-text"
            >
              <li v-for="item in jobExample.responsabilities" :key="item">
                {{ `${item}.` }}
              </li>
            </ul>
            <h4 class="font-lg mb-3 text-white font-bold">
              {{ $t('trabajos-detail-page.title-requerimientos') }}
            </h4>
            <ul
              class="list--bullet list--bullet--secondary mb-8 dark:text-primary-text text-secondary-text"
            >
              <li v-for="item in jobExample.requirements" :key="item">
                {{ `${item}.` }}
              </li>
            </ul>
          </div>
          <div class="w-full lg:w-5/12">
            <!-- Beneficios -->
            <div class="p-8 bg-contrast rounded-xl mb-10">
              <Title class="mb-6">
                <template #title>
                  <h4>{{ $t('trabajos-detail-page.title-beneficios') }}</h4>
                </template>
              </Title>
              <ul>
                <li
                  class="flex mb-4"
                  v-for="benefit in jobExample.benefits"
                  :key="benefit"
                >
                  <img
                    src="@/assets/images/icn-industry.svg"
                    class="rounded-full w-4 h-4 mt-1 mr-3"
                    alt=""
                  />
                  <p
                    class="dark:text-primary-text text-secondary-text text-sm leading-6 mb-0"
                  >
                    {{ `${benefit}.` }}
                  </p>
                </li>
              </ul>
            </div>
            <!-- End Beneficios -->
            <div class="flex">
              <router-link
                to=""
                class="btn btn-xl btn-primary btn-rounded-lg mx-auto uppercase"
                title=""
                >{{ $t('general.aplicar') }}
              </router-link>
            </div>
          </div>
        </div>
      </section>
      <!-- End Sección Contratar Devs -->
      <!-- Sección Comunidad -->
      <section class="content--comunidad">
        <Title class="mb-6">
          <template #title>
            <h3>{{ $t('general.trabajos-similares') }}</h3>
          </template>
        </Title>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <CardTrabajo
            v-for="job in jobsExamples"
            :key="job.id"
            :jobData="job"
          />
        </div>
      </section>
      <!-- End Sección Comunidad -->
    </div>
  </main>
</template>

<script>
import Hero from '@/components/Hero'
import Title from '@/components/Title'
import CardTrabajo from '@/components/CardTrabajo'
import Share from '@/components/Share'
import ApolloClient from '../graphql/apolloClient'
import JobQuery from '../graphql/queries/job'
import ROUTES from '../constants/routes'
import { toastNotification } from '@/services/toastNotification'

export default {
  data: function () {
    return {
      job: null,
      ROUTES,
      jobExample: {
        id: 1,
        jobTitle: 'Title of the Job',
        jobType: 'Remoto',
        jobTime: 'Full Time',
        salaryRange: '5000',
        techStack: ['Stack 01', 'Stack 02'],
        responsabilities: ['Responsabilidad 01', 'Responsabilidad 02'],
        benefits: ['Beneficio 01', 'Beneficio 02'],
        requirements: ['Requerimiento 01', 'Requerimiento 02'],
        company: {
          logo: 'https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg',
          name: 'Nombre de la compania',
          about:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit sagittis, vestibulum amet odio consequat libero ultricies orci, tempor. Vestibulum pulvinar tincidunt euismod suspendisse nunc interdum velit egestas eget. Fringilla nullam facilisis volutpat molestie facilisi.',
          industry: 'Industria',
          size: '50-100'
        }
      },
      jobsExamples: [
        {
          id: 1,
          image:
            'https://upload.wikimedia.org/wikipedia/commons/4/4a/Amazon_icon.svg',
          jobTitle: 'Senior QA',
          company: {
            id: 1,
            name: 'amazon',
            about: 'Tech company that aaims to toganize the world information.'
          }
        },
        {
          id: 2,
          jobTitle: 'Senior Developer',
          company: {
            id: 1,
            name: 'Vue.js',
            about: 'Tech company that aaims to toganize the world information.'
          }
        },
        {
          id: 3,
          image:
            'https://upload.wikimedia.org/wikipedia/commons/0/0c/Netflix_2015_N_logo.svg',
          jobTitle: 'Senior Arquitec',
          company: {
            id: 1,
            name: 'Netflix',
            about: 'Tech company that aaims to toganize the world information.'
          }
        }
      ]
    }
  },
  components: {
    Hero,
    Title,
    CardTrabajo,
    Share
  },
  beforeMount: async function () {
    await this.getJobs()
  },
  methods: {
    getJobs: async function () {
      try {
        const recentJobs = await ApolloClient.query({
          variables: {
            jobId: parseInt(this.$route.params.id)
          },
          query: JobQuery
        })
        this.job = recentJobs.data.job
      } catch (e) {
        toastNotification(e, 'error')
      }
    }
  }
}
</script>
