<template>
  <main class="main--wrapper">
    <Hero
      :hero-title="$t('upskilling-b2b.title')"
      :hero-image="'B2B-hero.png'"
      :hero-description="$t('upskilling-b2b.description')"
      class="lg:min-h-screen variation lg:mb-10 hero--image--dark"
    >
      <div
        class="lg:w-8/12 mx-auto flex flex-col items-center justify-center mb-4 lg:mb-10"
      >
        <h2
          class="text-center text-white font-bold text-3xl lg:text-6xl mx-auto lg:leading-tight lg:w-10/12 mb-10"
          v-html="$t('upskilling-b2b.title')"
        ></h2>
        <p
          class="text-xl text-white text-center mb-8"
          v-html="$t('upskilling-b2b.description')"
        ></p>
        <div class="">
          <a
            href="https://utopicode.typeform.com/to/zLVQcGOk"
            target="_blank"
            class="btn btn-lg btn-primary btn-rounded-lg mb-4"
          >
            {{ $t('upskilling-b2b.buy') }}
          </a>
          <a
            href="#syllabus"
            target="_self"
            class="btn btn-xl border-2 btn-outline-secondary btn-outline-secondary-glow btn-rounded-lg"
          >
            {{ $t('box-course-details.btn-download') }}
          </a>
        </div>
      </div>
    </Hero>
    <!-- Sección Course details -->
    <div class="dark:bg-contrast bg-white">
      <section class="">
        <div
          class="text-center grid grid-cols-1 lg:grid-cols-4 font-bold gap-x-6 dark:text-white text-contrast text-lg py-10"
        >
          <div class="cards--skill--body lg:mb-2 mb-6">
            <img
              src="@/assets/images/upskilling/nIcons/schedule.svg"
              class="shadow-svg-xl mx-auto w-10 mb-4"
              alt="Utopicode"
            />
            <h4 v-html="$t('box-course-details.duration')"></h4>
          </div>
          <div class="cards--skill--body lg:mb-2 mb-6">
            <img
              src="@/assets/images/upskilling/nIcons/cloud_check.svg"
              class="shadow-svg-xl mx-auto w-10 mb-4"
              alt="Utopicode"
            />
            <h4 v-html="$t('box-course-details.mode')"></h4>
          </div>
          <div class="cards--skill--body lg:mb-2 mb-6">
            <img
              src="@/assets/images/upskilling/nIcons/dollar.svg"
              class="shadow-svg-xl mx-auto w-10 mb-4"
              alt="Utopicode"
            />
            <h4 v-html="$t('box-course-details.price')"></h4>
            <p v-html="$t('box-course-details.disclaimer')"></p>
          </div>
          <div class="cards--skill--body lg:mb-2 mb-6">
            <img
              src="@/assets/images/upskilling/nIcons/group.svg"
              class="shadow-svg-xl mx-auto w-10 mb-4"
              alt="Utopicode"
            />
            <h4 v-html="$t('box-course-details.benefit')"></h4>
          </div>
        </div>
      </section>
    </div>
    <!-- End Sección Overview  -->
    <section id="whatlearn">
      <WhatWillLearnVue />
    </section>
    <!-- Sección Empresas -->
    <div class="dark:bg-contrast bg-white lg:mb-20 mb-10">
      <section class="p-18">
        <div class="lg:flex justify-center items-center">
          <h4
            class="dark:text-white font-bold text-2xl lg:w-6/12 w-12/12 lg:mb-2 mb-10 lg:text-left text-center"
            v-html="$t('upskilling-b2b.companies')"
          ></h4>
          <div
            class="text-center grid grid-cols-1 lg:grid-cols-5 font-bold gap-x-6 dark:text-secondary-text text-contrast text-lg lg:w-7/12 w-12/12"
          >
            <div class="cards--skill--body lg:mb-2 mb-6">
              <LogoSiclo class="mx-auto w-24" alt="Siclo" />
            </div>
            <div class="cards--skill--body lg:mb-2 mb-6">
              <img
                class="mx-auto w-24 fill-current"
                src="@/assets/images/upskilling/logoUrbvan.svg"
                alt=""
              />
            </div>
            <div class="cards--skill--body lg:mb-2 mb-6">
              <img
                class="mx-auto w-24 fill-current"
                src="@/assets/images/upskilling/logoJusto.svg"
                alt=""
              />
            </div>
            <div class="cards--skill--body lg:mb-2 mb-6">
              <img
                class="mx-auto w-24 fill-current"
                src="@/assets/images/upskilling/logoBen&Frank.svg"
                alt=""
              />
            </div>
            <div class="cards--skill--body lg:mb-2 mb-6">
              <img
                class="mx-auto w-24 fill-current"
                src="@/assets/images/upskilling/logoSilabuz.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- Sección How  -->
    <div class="content--wrapper">
      <section class="mb-10 lg:mb-20">
        <div class="mx-auto lg:w-5/12 w-10/12 mb-20">
          <h2
            class="title--h3 dark:text-white text-contrast font-bold text-center mb-4"
            v-html="$t('upskilling-b2b.perks-title')"
          ></h2>
        </div>
        <div class="flex lg:justify-between flex-wrap relative items-center">
          <div class="w-full lg:w-6/12 mb-8">
            <img
              src="@/assets/images/upskilling/how-img.png"
              class="mx-auto w-full"
              alt="Utopicode"
            />
          </div>
          <div class="w-full lg:w-5/12 mb-12 lg:mb-0">
            <ul class="mb-8 dark:text-primary-text text-secondary-text text-lg">
              <li v-for="(title, index) in 4" :key="index">
                <h6 class="dark:text-white font-bold text-2xl mb-2">
                  {{ $t(`upskilling-b2b.title-perks-section-${index + 1}`) }}
                </h6>
                <p class="dark:text-white mb-10">
                  {{
                    $t(`upskilling-b2b.description-perks-section-${index + 1}`)
                  }}
                </p>
              </li>
            </ul>
          </div>
        </div>
        <section class="content--wrapper relative py-10">
          <h6 class="dark:text-white font-bold text-2xl text-center mb-4">
            {{ $t('upskilling-b2b.how-description') }}
          </h6>
          <div class="flex items-baseline justify-center space-x-6">
            <a
              href="https://utopicode.typeform.com/to/zLVQcGOk"
              target="_blank"
              class="btn btn-lg btn-primary btn-rounded-lg"
            >
              {{ $t('upskilling-b2b.buy') }}
            </a>
          </div>
        </section>
      </section>
    </div>
    <!-- End Sección How  -->
    <!-- Sección Quote  -->
    <div
      class="dark:bg-contrast bg-white flex justify-center text-center items-baseline py-20 mb-10 lg:mb-20"
    >
      <div class="mx-auto w-6/12">
        <h2 class="dark:text-white font-bold text-xl italic">
          {{ $t('upskilling-b2b.quote-data') }}
        </h2>
        <p class="text-secondary-text">
          {{ $t('upskilling-b2b.quote-info') }}
        </p>
      </div>
    </div>
    <!-- End Sección Quote  -->
    <!-- Sección Price  -->
    <div class="content--wrapper">
      <section class="mb-10 lg:mb-20">
        <div class="mb-20">
          <h2
            class="title--h3 dark:text-white text-contrast font-bold text-center mb-2"
            v-html="$t('upskilling-b2b.price-title')"
          ></h2>
        </div>
        <div
          class="flex lg:justify-center flex-wrap relative items-center mx-auto mb-10"
        >
          <div class="w-full lg:w-4/12 mb-8">
            <h4 class="dark:text-white font-bold text-xl mb-2">
              {{ $t('upskilling-b2b.price-includes') }}
            </h4>
            <ul
              class="mb-8 dark:text-primary-text text-secondary-text text-lg list--bullet list--bullet--icon"
            >
              <li v-for="(title, index) in 4" :key="index">
                <h6 class="dark:text-white text-xl">
                  {{ $t(`upskilling-b2b.price-includes-${index + 1}`) }}
                </h6>
              </li>
            </ul>
          </div>
          <div
            class="w-full lg:w-4/12 mb-12 p-4 lg:mb-0 dark:text-white text-center border-2 border-secondary rounded-lg"
          >
            <div class="flex justify-center">
              <img
                src="@/assets/images/upskilling/cody-up.svg"
                class="transform -translate-y-20 lg:block absolute shadow-svg-xl mx-auto"
                alt="Utopicode"
              />
            </div>
            <h4
              class="text-2xl"
              v-html="$t('upskilling-b2b.price-number')"
            ></h4>
            <h2 class="dark:text-white font-bold text-4xl">
              {{ $t('upskilling-b2b.price-discount') }}
            </h2>
            <p>{{ $t('upskilling-b2b.price-person') }}</p>
            <p v-html="$t('box-course-details.disclaimer')"></p>
            <h4
              class="text-xl"
              v-html="$t('box-course-details.price-monthly')"
            ></h4>
          </div>
        </div>
        <div class="lg:flex justify-between">
          <div class="lg:flex justify-between space-x-6 relative pt-1 mb-10">
            <input
              id="minmax-range"
              type="range"
              min="0"
              max="30"
              v-model="value"
              class="bg-main-dark"
            />
            <h2 class="text-secondary-text">
              {{ value }} {{ $t('upskilling-b2b.lbl-person') }}
            </h2>
            <span class="dark:text-white font-bold text-4xl">
              $ {{ total }} USD
            </span>
          </div>
          <div class="lg:flex justify-center lg:space-x-6">
            <a
              href="https://utopicode.typeform.com/to/zLVQcGOk"
              target="_blank"
              class="btn btn-lg btn-primary btn-rounded-lg mb-4"
            >
              {{ $t('upskilling-b2b.buy') }}
            </a>
            <a
              href="#syllabus"
              type="submit"
              class="btn btn-lg border-2 btn-outline-secondary btn-outline-secondary-glow btn-rounded-lg"
            >
              {{ $t('box-course-details.btn-download') }}
            </a>
          </div>
        </div>
      </section>
    </div>
    <!-- End Sección Price  -->
    <div>
      <section class="relative w-full h-full overflow-hidden mb-10 lg:mb-20">
        <img
          class="absolute w-full h-full object-cover object-center"
          src="@/assets/images/upskilling/img-blockquote-bg.png"
        />
        <splide class="splide--blockquote z-20" :options="options">
          <splide-slide class="lg:px-40">
            <BlockquoteLarge
              class="lg:mt-14 mt-9"
              :cite="$t(`upskilling-b2b.review-quote-1`)"
              author="Rodrigo Piña"
              position="Developer Siclo"
              :block-image="'B2B-hero.png'"
            >
            </BlockquoteLarge>
          </splide-slide>
          <splide-slide class="lg:px-40">
            <BlockquoteLarge
              class="lg:mt-14 mt-9"
              :cite="$t(`upskilling-b2b.review-quote-2`)"
              author="Karen Barrera"
            >
            </BlockquoteLarge>
          </splide-slide>
          <splide-slide class="lg:px-40">
            <BlockquoteLarge
              class="lg:mt-14 mt-9"
              :cite="$t(`upskilling-b2b.review-quote-3`)"
              author="Learner del Upskilling Program"
            >
            </BlockquoteLarge>
          </splide-slide>
          <splide-slide class="lg:px-40">
            <BlockquoteLarge
              class="lg:mt-14 mt-9"
              :cite="$t(`upskilling-b2b.review-quote-4`)"
              author="Sebastian F"
            >
            </BlockquoteLarge>
          </splide-slide>
        </splide>
      </section>
    </div>
    <!-- Sección FAQ  -->
    <div class="content--wrapper dark:text-white-text text-secondary-text">
      <section class="mb-10 lg:mb-20">
        <h2
          class="title--h3 dark:text-white text-contrast font-bold text-center my-6"
          v-html="$t('upskilling-b2b.title-faq')"
        ></h2>
        <div class="grid grid-cols-1 gap-x-6 gap-y-4 mb-10 items-start">
          <div class="lg:w-7/12 w-10/12 mx-auto">
            <CollapseSimple
              class="collapse--wrapper--simple shadow-box-light dark:shadow-box-dark mb-6"
              :title="$t('upskilling-b2b.faq-upskilling-title-q1')"
            >
              <template #body>
                {{ $t('upskilling-b2b.faq-upskilling-text-q1') }}
              </template>
            </CollapseSimple>
            <CollapseSimple
              class="shadow-box-light dark:shadow-box-dark mb-6"
              :title="$t('upskilling-b2b.faq-upskilling-title-q2')"
            >
              <template #body>
                <h2 v-html="$t('upskilling-b2b.faq-upskilling-text-q2')"></h2>
              </template>
            </CollapseSimple>
            <CollapseSimple
              class="shadow-box-light dark:shadow-box-dark mb-6"
              :title="$t('upskilling-b2b.faq-upskilling-title-q10')"
            >
              <template #body>
                <h2 v-html="$t('upskilling-b2b.faq-upskilling-text-q10')"></h2>
              </template>
            </CollapseSimple>
            <CollapseSimple
              class="shadow-box-light dark:shadow-box-dark mb-6"
              :title="$t('upskilling-b2b.faq-upskilling-title-q3')"
            >
              <template #body>
                <h2 v-html="$t('upskilling-b2b.faq-upskilling-text-q3')"></h2>
              </template>
            </CollapseSimple>
            <CollapseSimple
              class="shadow-box-light dark:shadow-box-dark mb-6"
              :title="$t('upskilling-b2b.faq-upskilling-title-q4')"
            >
              <template #body>
                {{ $t('upskilling-b2b.faq-upskilling-text-q4') }}
              </template>
            </CollapseSimple>
            <CollapseSimple
              class="shadow-box-light dark:shadow-box-dark mb-6"
              :title="$t('upskilling-b2b.faq-upskilling-title-q11')"
            >
              <template #body>
                {{ $t('upskilling-b2b.faq-upskilling-text-q11') }}
              </template>
            </CollapseSimple>
            <CollapseSimple
              class="shadow-box-light dark:shadow-box-dark mb-6"
              :title="$t('upskilling-b2b.faq-upskilling-title-q9')"
            >
              <template #body>
                <h2 v-html="$t('upskilling-b2b.faq-upskilling-text-q9')"></h2>
              </template>
            </CollapseSimple>
          </div>
        </div>
      </section>
    </div>
    <!-- Sección FAQ  -->
    <!-- Sección Hire   -->
    <div class="dark:bg-contrast bg-white">
      <section class="mb-10 lg:mb-20 py-10">
        <h2
          class="title--h4 dark:text-white text-contrast font-bold text-center my-4"
          v-html="$t('upskilling-b2b.title-hire')"
        ></h2>
        <h2
          class="text-secondary-text text-lg text-center mx-auto mb-6 lg:w-8/12 w-10/12"
        >
          {{ $t('upskilling-b2b.subtitle-hire') }}
        </h2>
        <div
          class="grid lg:grid-cols-3 gap-x-6 text-center dark:text-white font-bold mb-4"
        >
          <div class="cards--skill--body lg:mb-2 mb-6">
            <img
              src="@/assets/images/upskilling/nIcons/briefcase.svg"
              class="shadow-svg-xl mx-auto mb-4 w-10"
              alt="Utopicode"
            />
            <h4 v-html="$t('upskilling-b2b.benefit-hire-1')"></h4>
          </div>
          <div class="cards--skill--body lg:mb-2 mb-6">
            <img
              src="@/assets/images/upskilling/nIcons/badge.svg"
              class="shadow-svg-xl mx-auto mb-4 w-10"
              alt="Utopicode"
            />
            <h4 v-html="$t('upskilling-b2b.benefit-hire-2')"></h4>
          </div>
          <div class="cards--skill--body lg:mb-2 mb-6">
            <img
              src="@/assets/images/upskilling/nIcons/timer.svg"
              class="shadow-svg-xl mx-auto mb-4 w-10"
              alt="Utopicode"
            />
            <h4 v-html="$t('upskilling-b2b.benefit-hire-3')"></h4>
          </div>
        </div>
        <div class="flex justify-center mb-10">
          <router-link
            :to="{ name: 'Contrata' }"
            class="btn btn-lg btn-primary btn-rounded-lg"
            >{{ $t('upskilling-b2b.btn-hire') }}</router-link
          >
        </div>
      </section>
    </div>
    <!-- End Sección Hire   -->
    <!-- Sección Form Temario  -->
    <div id="syllabus" class="content--wrapper">
      <section class="mb-10 lg:mb-20">
        <div class="w-full lg:w-8/12 mx-auto">
          <CardPage>
            <template #default>
              <div v-if="enviado">
                <div class="card--page--header">
                  <Title>
                    <template #title>
                      <h3>
                        {{ $t('form-validation.validation-hire-devs-1') }}
                      </h3>
                    </template>
                  </Title>
                </div>
                <div class="card--page--body">
                  <p class="dark:text-primary-text text-secondary-text">
                    {{ $t('form-validation.validation-hire-devs-2') }}
                  </p>
                  <div
                    class="dark:text-primary-text text-secondary-text"
                    v-html="$t('form-validation.validation-upskilling-devs')"
                  ></div>
                </div>
                <div class="card--page--footer">
                  <button
                    type="button"
                    class="btn btn-primary btn-lg btn-rounded-lg mx-auto"
                    @click="resetForm"
                  >
                    {{ $t('general.volver') }}
                  </button>
                </div>
              </div>
              <Form @submit="downloadSyllabus" v-else>
                <!-- Title -->
                <div class="card--page--header">
                  <h4
                    class="title--h5 dark:text-white text-contrast font-bold mb-6"
                    v-html="$t('upskilling-b2b.title-form')"
                  ></h4>
                  <p
                    class="dark:text-white-text text-secondary-text text-xl mb-8"
                  >
                    {{ $t('upskilling-b2b.form-description') }}
                  </p>
                </div>
                <!-- End Title -->
                <!-- Body -->
                <div
                  class="card--page--body grid grid-cols-1 lg:grid-cols-2 gap-x-6"
                >
                  <div class="form--group">
                    <label class="form--label" for="name">{{
                      $t('contrata-devs-page.lbl-nombre')
                    }}</label>
                    <Field
                      name="name"
                      type="text"
                      class="form--element w-full"
                      :rules="validateDefault"
                    />
                    <ErrorMessage name="name" class="form--error" />
                  </div>
                  <div class="form--group">
                    <label class="form--label" for="name">{{
                      $t('contrata-devs-page.lbl-nombre-empresa')
                    }}</label>
                    <Field
                      name="companyName"
                      type="text"
                      class="form--element w-full"
                      :rules="validateDefault"
                    />
                    <ErrorMessage name="companyName" class="form--error" />
                  </div>
                  <div class="form--group">
                    <label class="form--label" for="email">{{
                      $t('contrata-devs-page.lbl-email')
                    }}</label>
                    <Field
                      name="email"
                      type="email"
                      class="form--element w-full"
                      :rules="validateEmail"
                    />
                    <ErrorMessage name="email" class="form--error" />
                  </div>
                  <div class="form--group">
                    <label class="form--label" for="phone">{{
                      $t('contrata-devs-page.lbl-telefono')
                    }}</label>
                    <div class="form--phone">
                      <Field
                        name="phoneCode"
                        type="select"
                        :placeholder="$t('general.codigo-internacional')"
                        list="phoneCodeNumberList"
                        class="form--element form--select w-10/12"
                        :rules="validateDefaultPhone"
                        v-model.lazy="phoneCodeNumber"
                      />
                      <Field
                        name="phone"
                        type="text"
                        class="form--element w-full w-6/12"
                        :rules="validatePhone"
                        v-model="phonenumber"
                      />
                      <datalist id="phoneCodeNumberList">
                        <option value="" disabled></option>
                        <option
                          :value="code.dialCode"
                          v-for="(code, index) in phoneCodes"
                          :key="index"
                        >
                          {{ code.emoji }} {{ code.name }}
                        </option>
                      </datalist>
                    </div>
                    <ErrorMessage name="phone" class="form--error" />
                    <ErrorMessage name="phoneCode" class="form--error" />
                  </div>
                </div>
                <!-- End Body -->
                <!-- Footer -->
                <div class="card--page--footer">
                  <div class="flex items-baseline justify-center space-x-6">
                    <button
                      type="submit"
                      class="btn btn-lg btn-secondary btn-rounded-lg shadow-lg"
                    >
                      {{ $t('box-course-details.btn-download') }}
                    </button>
                  </div>
                </div>
                <!-- End Footer -->
              </Form>
            </template>
          </CardPage>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import Hero from '@/components/FullHero'
import Title from '@/components/Title'
import CollapseSimple from '@/components/CollapseSimple'
import BlockquoteLarge from '@/components/BlockquoteLarge'
import CardPage from '@/components/CardPage'
import { Field, Form, ErrorMessage } from 'vee-validate'
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import WhatWillLearnVue from '../components/WhatWillLearn.vue'
import phoneCodes from '@/components/json/phoneCodes.json'
import especialidadLista from '@/constants/specialities.json'
// import getPositionsQuery from '@/graphql/queries/positions'
import ApolloClient from '@/graphql/apolloClient'
import UpskillingInterest from '@/graphql/mutations/upskillingInterest'
import { mapActions } from 'vuex'
import { toastNotification } from '@/services/toastNotification'
import ROUTES from '../constants/routes'

import LogoSiclo from '@/components/svg/clients/logoSiclo.vue'

import {
  validateDefault,
  validateEmail,
  validatePhone,
  validateUrlNoRegex,
  validateDefaultPhone
} from '@/services/validationRules'

export default {
  name: 'Upskilling',
  components: {
    Hero,
    Title,
    WhatWillLearnVue,
    CollapseSimple,
    Field,
    Form,
    ErrorMessage,
    CardPage,
    BlockquoteLarge,
    Splide,
    SplideSlide,
    LogoSiclo
  },
  data() {
    return {
      phoneCodes,
      especialidadLista,
      enviado: false,
      ROUTES,
      phonenumber: '',
      value: 1,
      phoneCodeNumber: '',
      courses: [],
      englishLevels: {
        opt1: 'No sé nada de Inglés',
        opt2: 'Básico',
        opt3: 'Conversacional',
        opt4: 'Profesional',
        opt5: 'Lengua Materna'
      },
      seniorityLevels: {
        opt1: 'Student',
        opt2: 'Junior',
        opt3: 'Mid-senior',
        opt4: 'Senior',
        opt5: 'Expert'
      },
      motivations: {
        opt1: 'Me gusta aprender',
        opt2: 'Me gusta mejorar',
        opt3: 'No se'
      },
      options: {
        rewind: false,
        perPage: 1,
        type: 'loop',
        pagination: false,
        autoplay: false,
        pauseOnHover: false,
        breakpoints: {
          1024: {
            pagination: true,
            arrows: false
          }
        }
      }
    }
  },
  methods: {
    ...mapActions(['setLoadingOn', 'setLoadingOff']),
    // Send Form
    downloadSyllabus: async function (values) {
      try {
        this.setLoadingOn()
        if (values) {
          const result = await ApolloClient.mutate({
            mutation: UpskillingInterest,
            variables: {
              fullname: values.name,
              companyname: values.companyName,
              email: values.email,
              phone: this.phoneNumberComplete,
              origin: 'B2B'
            }
          })
          if (result.data.upskillingInterest) {
            this.setLoadingOff()
            this.enviado = !this.enviado
            this.downloadItem()
          } else {
            this.setLoadingOff()
            alert('Something went wrong, try again')
          }
        }
        return false
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    downloadItem: function () {
      const a = document.createElement('a')
      a.href = `https://webdynamiccontentutopicode.s3.us-west-2.amazonaws.com/Temario_TechTeamsTraining+ESP.pdf`
      a.target = '_blank'
      a.download = 'Temario UTOPIC0DE'
      a.click()
      URL.revokeObjectURL(a.href)
    },
    // Reset Form
    resetForm() {
      this.enviado = !this.enviado
    },

    // RULES
    validateDefault,
    validateEmail,
    validatePhone,
    validateUrlNoRegex,
    validateDefaultPhone,
    scrollMeTo(refName) {
      const element = this.$refs[refName]
      const top = element.offsetTop
      window.scrollTo({ top, behavior: 'smooth' })
    }
  },
  computed: {
    phoneNumberComplete() {
      return this.phoneCodeNumber + this.phonenumber
    },
    total: function () {
      return this.value * 150
    }
  }
}
</script>
