<template>
  <div class="w-full">
    <!-- Card Page Sign Up -->
    <CardPage class="bg-transparentAux">
      <template v-if="score && user" #default>
        <!-- Body -->
        <div class="card--page--body lg:pl-1 pt-0">
          <div
            class="flex flex-wrap mb-7 lg:flex-nowrap items-center space-x-8 space-y-6 lg:space-y-0 border-b-2 border-[#B9A3FF] pb-7"
          >
            <div class="w-full lg:w-3/12">
              <span class="text-green-50 tracking-widest"> SCORE </span>
              <div class="flex items-center">
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="13.3652"
                    y="0.373047"
                    width="17.8352"
                    height="17.8352"
                    transform="rotate(45 13.3652 0.373047)"
                    fill="#D2D2D2"
                  />
                  <g style="mix-blend-mode: multiply" opacity="0.2">
                    <path
                      d="M25.9766 12.9844L13.3652 0.373047V25.5958L25.9766 12.9844Z"
                      fill="#2B2B2B"
                    />
                  </g>
                </svg>
                <h3 class="dark:text-white ml-2 text-4xl">
                  {{
                    $t(`resultados-assessment-page.lvl-${score.keyPercentil}`)
                  }}
                </h3>
              </div>
            </div>
            <div class="w-full lg:w-5/12">
              <p class="dark:text-white text-sm">
                {{
                  $t('resultados-assessment-page.text-resultados-assessment-01')
                }}
                <span class="text-sm">
                  {{
                    $t(
                      'resultados-assessment-page.text-resultados-assessment-04'
                    )
                  }}
                  {{
                    $t(`resultados-assessment-page.lvl-${score.keyPercentil}`)
                  }}
                  {{
                    $t(
                      'resultados-assessment-page.text-resultados-assessment-02'
                    )
                  }}
                  {{ score.assesment.name }}
                </span>
                <br />
                {{
                  $t('resultados-assessment-page.text-resultados-assessment-03')
                }}
              </p>
            </div>
            <div class="w-full lg:w-4/12 text-center">
              <PerceptileChart :nivel="score.keyPercentil"></PerceptileChart>
            </div>
          </div>
          <div>
            <Title class="mb-2">
              <template #title>
                <h4 class="text-left">
                  {{
                    $t('resultados-assessment-page.title-resultado-generales')
                  }}
                </h4>
              </template>
            </Title>
            <h4 class="dark:text-[#B9A3FF] text-lg font-bold mb-2">
              {{ $t('resultados-assessment-page.text-resultado-general-1') }}
              {{ score.subAssesmentsResult[0].assesment.name }}
              {{ $t('resultados-assessment-page.text-resultado-general-2') }}
              {{
                score.subAssesmentsResult[score.subAssesmentsResult.length - 1]
                  .assesment.name
              }}
            </h4>
            <p
              class="dark:text-primary-text text-secondary-text leading-relaxed"
            >
              {{ score.description }}
            </p>
          </div>
          <!-- <div
            class="flex flex-wrap mb-10 lg:flex-nowrap lg:space-x-8 items-center space-y-6 lg:space-y-0"
          >
            <div class="w-full lg:w-12/12">
              <CircleChart :respuestas="score"></CircleChart>
            </div>
          </div> -->
          <!-- <Title class="mb-6">
            <template #title>
              <h4>
                {{
                  $t('resultados-assessment-page.title-resultado-detallados')
                }}
              </h4>
            </template>
          </Title> -->
          <div class="flex dark:text-white flex-wrap items-stretch">
            <div
              v-for="(subAssesment, index) in score.subAssesmentsResult"
              :key="subAssesment.id"
              class="w-full lg:w-3/12 p-3 rounded-t-lg cursor-pointer"
              :class="
                subAssesmentActive === index ? 'bg-[#312C3E]' : 'bg-customBlack'
              "
              @click="subAssesmentActive = index"
            >
              <div
                class="text-center bg-black h-full flex flex-wrap items-center pl-2 pr-2 pt-4 pb-4 rounded-lg border-2"
                :style="`border-color: ${colorClass(index)};`"
              >
                <div class="text-xs uppercase tracking-wider w-full mb-2">
                  {{ subAssesment.assesment.name }}
                </div>
                <div class="text-4xl w-full">
                  {{ subAssesment.totalScore }} /
                  {{ subAssesment.assesment.testLength }}
                </div>
                <div
                  v-if="index === 0"
                  class="text-xs w-full mt-2 flex justify-center items-center"
                >
                  <div
                    class="flex justify-center items-center bg-[#CA3472] pt-1 pb-1 pl-2 pr-2 rounded-full"
                  >
                    <span class="mr-1">
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.64923 10.6229L3.83601 9.25137L2.28838 8.91778L2.44014 7.3213L1.39648 6.1259L2.44014 4.93049L2.28838 3.33402L3.83601 3.00043L4.64923 1.62891L6.09955 2.24165L7.54987 1.62891L8.36309 3.00043L9.91072 3.33402L9.75897 4.93049L10.8026 6.1259L9.75897 7.3213L9.91072 8.91778L8.36309 9.25137L7.54987 10.6229L6.09955 10.0101L4.64923 10.6229ZM4.85725 9.99894L6.09955 9.4818L7.34745 9.99894L8.04825 8.82972L9.38798 8.53181L9.26619 7.14897L10.1675 6.1259L9.26619 5.09723L9.38798 3.71438L8.04825 3.42208L7.34185 2.25286L6.09955 2.76999L4.85165 2.25286L4.15085 3.42208L2.81112 3.71438L2.93291 5.09723L2.03164 6.1259L2.93291 7.14897L2.81112 8.53742L4.15085 8.82972L4.85725 9.99894ZM5.58802 7.51812L8.00331 5.10283L7.65851 4.75243L5.58802 6.82292L4.54059 5.7811L4.19579 6.1259L5.58802 7.51812Z"
                          fill="#E8EAED"
                        />
                      </svg>
                    </span>
                    Mejor resultado
                  </div>
                </div>
                <div
                  v-if="index === score.subAssesmentsResult.length - 1"
                  class="text-xs w-full mt-2 flex justify-center items-center"
                >
                  <div
                    class="flex justify-center items-center pt-1 pb-1 pl-2 pr-2 rounded-full"
                    :style="`background-color: ${colorClass(index)};`"
                  >
                    <span class="mr-1">
                      <svg
                        width="13"
                        height="12"
                        viewBox="0 0 13 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.88867 10.0233L6.40442 2.22852L10.9202 10.0233H1.88867ZM2.72625 9.53614H10.0826L6.40442 3.20287L2.72625 9.53614ZM6.40442 8.86165C6.48935 8.86165 6.56056 8.83291 6.61805 8.77542C6.67553 8.71793 6.70428 8.64672 6.70428 8.56179C6.70428 8.47686 6.67553 8.40565 6.61805 8.34817C6.56056 8.29068 6.48935 8.26194 6.40442 8.26194C6.31949 8.26194 6.24828 8.29068 6.19079 8.34817C6.13331 8.40565 6.10456 8.47686 6.10456 8.56179C6.10456 8.64672 6.13331 8.71793 6.19079 8.77542C6.24828 8.83291 6.31949 8.86165 6.40442 8.86165ZM6.16083 7.77476H6.64801V5.33889H6.16083V7.77476Z"
                          fill="#E8EAED"
                        />
                      </svg>
                    </span>
                    Peor resultado
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-for="(subAssesment, index) in score.subAssesmentsResult"
            :key="subAssesment.id"
          >
            <div
              v-if="subAssesmentActive === index"
              class="w-full p-5 rounded-r-lg dark:text-white bg-[#312C3E]"
            >
              <div class="text-lg font-bold mb-2">
                {{ subAssesment.assesment.name }}
              </div>
              <div>
                {{ subAssesment.description }}
              </div>
            </div>
          </div>
          <!-- <Collapse
            class="mb-4"
            v-for="subAssesment in score.subAssesmentsResult"
            icon="icn-money.svg"
            :key="subAssesment.id"
            :title="subAssesment.assesment.name"
          >
            <template #body>
              <div
                class="flex flex-wrap lg:flex-nowrap lg:space-x-8 space-y-6 lg:space-y-0 items-center"
              >
                <div class="w-full lg:w-3/12">
                  <DonutChart
                    icon="icn-money.svg"
                    :title="subAssesment.assesment.name"
                    :respuestas-correctas="subAssesment.totalScore"
                    :respuestas-totales="subAssesment.assesment.testLength"
                  ></DonutChart>
                </div>
                <div class="w-full lg:w-9/12">
                  <p
                    class="dark:text-primary-text text-secondary-text leading-relaxed"
                  >
                    {{ subAssesment.assesment.instructions[0] }}
                  </p>
                  <h4 class="dark:text-white text-lg font-bold mb-3">
                    {{ $t('resultados-assessment-page.title-tu-resultado') }}
                  </h4>
                  <p
                    class="dark:text-primary-text text-secondary-text leading-relaxed last"
                  >
                    {{ subAssesment.description }}
                  </p>
                </div>
              </div>
            </template>
          </Collapse> -->
        </div>
        <!-- End Body -->
      </template>
    </CardPage>
    <!-- End Card Page Sign Up -->
    <!-- <section class="content--comunidad">
      <Title class="mb-6">
        <template #title>
          <h3>{{ $t('general.otros-badges') }}</h3>
        </template>
      </Title>
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        <CardSkill
          v-for="(item, key) in otherAssesments"
          :key="key"
          :link="{ name: 'InstruccionesAssesments', params: { id: item.id } }"
          :image="item.photo"
          :title="item.name"
          :description="item.instructions[0]"
          locked
          footer
          :cta="$t('general.realizar-test')"
        />
      </div>
    </section> -->
  </div>
</template>

<script>
import CardPage from '@/components/CardPage'
import Title from '@/components/Title'
// import Collapse from '@/components/Collapse'
// import DonutChart from '@/components/DonutChart'
import PerceptileChart from '@/components/PerceptileChart'
// import CircleChart from '@/components/CircleChart'
// import CardSkill from '@/components/CardSkill'
import apolloClient from '@/graphql/apolloClient'
import AssesmentResultQuery from '@/graphql/queries/assesmentResult'
import userAssesmentsQuery from '@/graphql/queries/userAssesments'
import assesmentsQuery from '@/graphql/queries/assesments'
import { mapActions, mapGetters } from 'vuex'
import { toastNotification } from '@/services/toastNotification'

export default {
  components: {
    CardPage,
    Title,
    // Collapse,
    // DonutChart,
    // CircleChart,
    PerceptileChart
    // CardSkill
  },
  props: {
    id: { type: Number, required: false }
  },
  data() {
    return {
      user: null,
      score: null,
      otherAssesments: [],
      subAssesmentActive: 0
    }
  },
  computed: {
    ...mapGetters(['token']),
    getAssesmentFromCategories() {
      return this.user.takenAssesments.filter((item) => item.assesment.filter())
    }
  },
  mounted: async function () {
    this.setLoadingOn()
    await this.getUserAssesments()
    await this.getAssesmentResult()
    await this.getAssesmentCategories()
    this.setLoadingOff()
  },
  methods: {
    ...mapActions(['setLoadingOff', 'setLoadingOn']),
    getAssesmentResult: async function () {
      try {
        const assesmentResult = await apolloClient.query({
          query: AssesmentResultQuery,
          variables: {
            assesmentId: parseInt(this.id)
          },
          fetchPolicy: 'network-only',
          context: {
            headers: {
              authorization: `Bearer ${await this.token}`
            }
          }
        })
        this.score = assesmentResult.data.assesmentResult
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    getAssesmentCategories: async function () {
      try {
        const fetchAssesmentsQuery = await apolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale
            }
          },
          fetchPolicy: 'network-only',
          query: assesmentsQuery
        })
        this.otherAssesments = fetchAssesmentsQuery.data.assesments.filter(
          (el) => {
            return el.id !== parseInt(this.id)
          }
        )
        this.setLoadingOff()
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    getUserAssesments: async function () {
      try {
        const userAssesmentsData = await apolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale,
              authorization: `Bearer ${await this.token}`
            }
          },
          fetchPolicy: 'network-only',
          query: userAssesmentsQuery
        })
        this.user = userAssesmentsData.data.userProfile
        // this.getAssesmentFromCategories()
        this.setLoadingOff()
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    colorClass(index) {
      if (index === 1 || index === 6) {
        return '#3A33B0'
      }
      if (index === 2 || index === 7) {
        return '#48C175'
      }
      if (index === 3 || index === 8) {
        return '#E47F21'
      }
      if (index === 4 || index === 9) {
        return '#6643C4'
      }
      return '#CA3472'
    }
    // getAssesmentFromCategories: function () {
    //   const newAssesments = this.user.takenAssesments.filter((item) =>
    //     item.assesment.filter()
    //   )
    //   return newAssesments
    // }
  }
}
</script>
