<template>
  <CardPage class="bg-transparentAux">
    <template #default>
      <!-- Title -->
      <div class="card--page--header">
        <Title>
          <template #title>
            <h5 class="mt-2">
              {{
                $t('box-preferencias-personales.title-preferencias-personales')
              }}
            </h5>
          </template>
          <template #options v-if="!isPublic">
            <button
              @click="edit = true"
              v-if="edit === false"
              type="button"
              role="button"
              aria-label="Edit"
              class="w-9 h-9 flex justify-center items-center rounded-md btn-secondary transform -translate-y-2"
            >
              <img src="@/assets/images/icn-edit.svg" />
            </button>
          </template>
        </Title>
      </div>
      <!-- End Title -->
      <!-- Body -->
      <div class="card--page--body">
        <ul class="list--box" v-if="edit === false">
          <li>
            <span class="icon"
              ><img src="@/assets/images/icn-industry.svg" /></span
            ><b>{{ $t('box-preferencias-personales.text-industrias') }}: </b>
            <span
              v-for="(industry, index) in preferedIndustries"
              :key="industry"
            >
              <span v-if="industry !== 'Otro' && industry !== 'Other'">
                {{ industry
                }}<span v-if="preferedIndustries.length != index + 1">, </span>
              </span>
            </span>
            <span v-if="otherIndustry">{{ otherIndustry }}</span>
          </li>
          <li>
            <span class="icon"
              ><img src="@/assets/images/icn-empleados.svg" /></span
            ><b
              >{{ $t('box-preferencias-personales.text-tipos-empresas') }}:
            </b>
            <span v-for="(ideal, key) in userIdealCompany" :key="ideal">
              {{ ideal
              }}<span v-if="userIdealCompany.length != key + 1">,</span>
            </span>
          </li>
        </ul>
        <form @submit.prevent="onSubmit" v-else>
          <div class="form--group">
            <label class="form--label" for="industrias-favoritas">{{
              $t('box-preferencias-personales.lbl-industrias-favoritas')
            }}</label>
            <Multiselect
              mode="tags"
              v-model="preferedIndustries"
              :options="IndustriesList[$i18n.locale ? $i18n.locale : 'es']"
              searchable
              :max="3"
              :noOptionsText="$t('contrata-devs-page.lista-vacia')"
              :noResultsText="$t('contrata-devs-page.sin-resultados')"
            />
            <!-- If Other Selected -->
            <div
              class="mt-6"
              v-if="
                preferedIndustries &&
                preferedIndustries.some((el) => ['Other', 'Otro'].includes(el))
              "
            >
              <label class="form--label" for="otro">
                {{ $t('box-preferencias-personales.text-otra-industria') }}
              </label>
              <input
                name="otro"
                type="text"
                class="form--element w-full"
                v-model="otherIndustry"
              />
            </div>
            <!-- End Other Selected -->
          </div>

          <div class="form--group">
            <label class="form--label" for="empresa-ideal">{{
              $t('box-preferencias-personales.lbl-empresa-ideal')
            }}</label>
            <Multiselect
              mode="tags"
              v-model="userIdealCompany"
              :options="IdealCompanyList[$i18n.locale]"
              searchable
              :max="3"
              :noOptionsText="$t('contrata-devs-page.lista-vacia')"
              :noResultsText="$t('contrata-devs-page.sin-resultados')"
            />
          </div>
          <div class="flex justify-center space-x-4">
            <button
              type="button"
              @click="edit = false"
              class="btn btn-md btn-outline btn-outline-primary btn-rounded-lg"
              role="button"
            >
              {{ $t('general.cancelar') }}
            </button>
            <button
              type="submit"
              @click="submitForm"
              class="btn btn-md btn-primary btn-rounded-lg mx-auto"
              role="button"
            >
              {{ $t('general.guardar') }}
            </button>
          </div>
        </form>
      </div>
      <!-- End Body -->
    </template>
  </CardPage>
</template>

<script>
import CardPage from '@/components/CardPage'
import Title from '@/components/Title'
import Multiselect from '@vueform/multiselect'
import IndustriesList from '@/components/json/industries.json'
import IdealCompanyList from '@/components/json/idealCompany.json'
import { mapActions, mapGetters } from 'vuex'
import apolloClient from '@/graphql/apolloClient'
import savePersonalPreferencesMutation from '@/graphql/mutations/savePersonalPreferences'
import { toastNotification } from '@/services/toastNotification'

export default {
  name: 'PreferenciasPersonalesBox',
  props: {
    industries: Array,
    idealCompany: Array,
    isPublic: Boolean,
    id: { type: Number, required: false }
  },
  components: {
    CardPage,
    Title,
    Multiselect
  },
  data() {
    return {
      preferedIndustries: this.industries,
      userIdealCompany: this.idealCompany,
      edit: true,
      IndustriesList,
      IdealCompanyList,
      otherIndustry: null
    }
  },
  mounted() {
    if (
      (this.preferedIndustries.length > 0 &&
        this.userIdealCompany.length > 0) ||
      this.isPublic
    ) {
      this.edit = false
    } else {
      this.edit = true
    }
  },
  computed: {
    ...mapGetters(['token'])
  },
  methods: {
    ...mapActions(['setLoadingOn', 'setLoadingOff', 'fetchUser']),
    async submitForm() {
      try {
        this.setLoadingOn()
        const savePersonalPreferences = await apolloClient.mutate({
          mutation: savePersonalPreferencesMutation,
          variables: {
            industries: this.preferedIndustries,
            idealCompany: this.userIdealCompany,
            id: this.id
          },
          context: {
            headers: {
              authorization: `Bearer ${await this.token}`
            }
          }
        })
        if (!savePersonalPreferences.errors) {
          await this.fetchUser()
          this.setLoadingOff()
          this.edit = false
          toastNotification(this.$t('general.info-guardada'), 'success')
        }
      } catch (e) {
        toastNotification(e, 'error')
      }
    }
  }
}
</script>
