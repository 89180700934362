<template>
  <div class="perceptile--wrapper">
    <svg
      class="w-full"
      viewBox="0 0 327 163"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <pattern
        id="perceptile-01"
        x="0"
        y="0"
        width="100%"
        height="200%"
        patternUnits="userSpaceOnUse"
      >
        <rect
          id="fillshape"
          :x="`${
            nivel === 'low'
              ? '0'
              : nivel === 'medium'
              ? '37.5'
              : nivel === 'high'
              ? '75'
              : '0'
          }%`"
          y="0"
          width="25%"
          height="100%"
          stroke="none"
          fill="#4D47FF"
          class="opacity-30"
        />
      </pattern>
      <pattern
        id="perceptile-02"
        x="0"
        y="0"
        width="100%"
        height="200%"
        patternUnits="userSpaceOnUse"
      >
        <rect
          id="fillshape"
          :x="`${
            nivel === 'low'
              ? '0'
              : nivel === 'medium'
              ? '37.5'
              : nivel === 'high'
              ? '75'
              : '0'
          }%`"
          y="0"
          width="25%"
          height="100%"
          stroke="none"
          fill="#4D47FF"
        />
      </pattern>
      <path
        fill="url(#perceptile-01)"
        d="M0 159C126 159 103 3 164.5 3C226 3 197.5 159 327 159"
        stroke="#D7D7D7"
        stroke-width="7"
        stroke-dashoffset="25"
      />
      <path
        fill="url(#perceptile-02)"
        d="M72.8621 137.62C55.5714 152.924 32.4278 163 0 163V155C30.5722 155 51.8036 145.576 67.5598 131.63C83.44 117.574 94.0178 98.7003 102.795 79.3479C105.906 72.488 108.752 65.6601 111.536 58.9816C112.684 56.2262 113.822 53.4962 114.964 50.7998C118.841 41.6432 122.744 32.9151 127.108 25.3719C135.816 10.3206 146.96 -1 164.5 -1C182.086 -1 192.886 10.3773 201.134 25.4554C205.264 33.0047 208.903 41.7383 212.517 50.8903C213.484 53.339 214.449 55.8162 215.422 58.315C218.115 65.2305 220.872 72.3126 223.925 79.4215C232.229 98.7561 242.447 117.599 258.278 131.632C273.988 145.556 295.522 155 327 155V163C293.728 163 270.262 152.944 252.972 137.618C235.803 122.401 225.021 102.244 216.575 82.5785C213.462 75.3322 210.63 68.0588 207.923 61.106C206.961 58.6345 206.014 56.2034 205.076 53.8285C201.472 44.6992 197.986 36.3703 194.116 29.2946C186.364 15.1227 177.664 7 164.5 7C151.29 7 142.247 15.1794 134.033 29.3781C129.936 36.4599 126.194 44.7943 122.331 53.9189C121.225 56.5308 120.108 59.2114 118.971 61.9392C116.168 68.6627 113.245 75.6736 110.08 82.6521C101.17 102.3 90.0287 122.426 72.8621 137.62Z"
      />
    </svg>
    <div class="perceptile--label candidatos text-sm">
      {{ $t('resultados-assessment-page.lbl-candidatos') }}
    </div>
    <div class="perceptile--label performance text-sm">
      {{ $t('resultados-assessment-page.lbl-performance') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PerceptileChart',
  props: {
    nivel: String
  }
}
</script>
