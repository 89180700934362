import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import createPlugin from 'logrocket-vuex'
import LogRocket from 'logrocket'
import { getUser } from '../services/authentication'
import router from '../router/index'
import apolloClient from '@/graphql/apolloClient'
import firebase, { analytics, firebaseInstance } from '@/services/firebase'
import createUserMutation from '@/graphql/mutations/signUp'
import ApolloClient from '../graphql/apolloClient'
import userProfileQuery from '@/graphql/queries/userProfile'
import { toastNotification } from '@/services/toastNotification'

if (process.env.NODE_ENV !== 'development') {
  LogRocket.init('acp4rz/utopicode')
}
const logrocketPlugin = createPlugin(LogRocket)

export default new Vuex.Store({
  state: {
    isLoggedIn: null,
    user: null,
    profile: null,
    lang: 'es',
    loading: false,
    themeColor: 'dark',
    currentStep: -1
  },
  mutations: {
    setLang(state, vuei18n) {
      const toggled = state.lang === 'es' ? 'en' : 'es'
      vuei18n.locale = toggled
      state.lang = toggled
    },
    setLoggedIn(state, user) {
      console.log('user', user)
      state.isLoggedIn = user !== null
      if (user) {
        state.user = user
      } else {
        state.user = null
      }
      console.log('state', state)
    },
    setLoadingOn(state) {
      state.loading = true
    },
    setLoadingOff(state) {
      state.loading = false
    },
    setProfile(state, profile) {
      if (profile === null) {
        state.profile = null
        return
      }
      console.log('profile', profile)
      state.profile = {
        ...state.profile,
        ...profile
      }
      console.log('state.profile', state.profile)
    },
    setUser(state, user) {
      if (user === null) {
        state.user = null
        return
      }
      console.log('user', user)
      state.user = {
        ...user.user,
        ...user.userData,
        token: user.token
      }
      console.log('state.user', state.user)
    },
    setUserSocialNetworks(state, socialNetworks) {
      state.user.socialNetworks = socialNetworks
    },
    setUserAbout(state, about) {
      state.user.about = about
    },
    clearUser(state) {
      state.user = null
    },
    setThemeColor(state, theme) {
      state.themeColor = theme
    },
    setStep(state, step) {
      state.currentStep = step
    }
  },
  actions: {
    toggleLang({ commit }, vuei18n) {
      commit('setLang', vuei18n)
    },
    setLoadingOn({ commit }) {
      commit('setLoadingOn')
    },
    setLoadingOff({ commit }) {
      commit('setLoadingOff')
    },
    updateStep({ commit }, step) {
      commit('setStep', step)
    },
    async socialLogin({ dispatch, commit }, { type, router }) {
      console.log('started social login')
      const provider = (type) => {
        console.log('provider type', type)
        if (type === 'github')
          return new firebaseInstance.auth.GithubAuthProvider()
        if (type === 'facebook')
          return new firebaseInstance.auth.FacebookAuthProvider()
        if (type === 'gmail')
          return new firebaseInstance.auth.GoogleAuthProvider()
      }

      try {
        console.log('setting loading on')
        dispatch('setLoadingOn')
        console.log('signing in with popup')
        const result = await firebase.signInWithPopup(provider(type))
        console.log('result', result)
        
        if (result.additionalUserInfo.isNewUser) {
          console.log('creating user')
          toastNotification(
            "Regístrate para continuar",
            'success'
          )
          dispatch('setLoadingOff')
          let routeUrl = router.resolve({ 
            path: '/es/sign-up',
            query: {
              email: result.user.email,
              fullname: result.user.displayName,
              photo: result.user.photoURL,
              fromSocial: true,
              provider: type,
              step: 2
            }
          })
          window.location = routeUrl.href
          return
        }
        
        // Esperamos a que Firebase actualice el estado de autenticación
        await new Promise(resolve => setTimeout(resolve, 1000))
        
        try {
          const token = await firebase.currentUser.getIdToken(true)
          const userData = await getUser(token)
          
          if (!userData || !userData.user) {
            toastNotification(
              "Regístrate para continuar",
              'success'
            )
            dispatch('setLoadingOff')
            let routeUrl = router.resolve({ 
              path: '/es/sign-up',
              query: {
                email: result.user.email,
                fullname: result.user.displayName,
                photo: result.user.photoURL,
                fromSocial: true,
                provider: type,
                step: 2
              }
            })
            window.location = routeUrl.href
            return
          }

          // Usuario encontrado, actualizamos el estado
          commit('setLoggedIn', {
            ...userData.user,
            ...userData.userProfile
          })

          // toastNotification(
          //   "Has iniciado sesión correctamente",
          //   'success'
          // )
          
          // Redirigimos al usuario
          const currentRoute = router.currentRoute._value.name
          if (currentRoute === 'SignIn' || currentRoute === 'SignUp') {
            dispatch('setLoadingOff')
            if (router.currentRoute._value.query.redirectTo) {
              let routeUrl = router.resolve({ path: router.currentRoute._value.query.redirectTo })
              window.location = routeUrl.href
            } else {
              if (userData.user.role === 'ADMIN') {
                let routeUrl = router.resolve({ path: '/admin' })
                window.location = routeUrl.href
              } else {
                let routeUrl = router.resolve({ name: 'Profile' })
                window.location = routeUrl.href
              }
            }
          }
        } catch (error) {
          console.log('Error fetching user after login:', error)
          if (error.message === 'No token') {
            toastNotification(
              "Error de autenticación. Por favor, intenta de nuevo",
              'error'
            )
          } else {
            const query = router.currentRoute._value.query
            if (router && ['SignIn', 'SignUp'].includes(router.currentRoute._value.name)) {
              commit('setLoggedIn', null)
              dispatch('setLoadingOff')
              toastNotification(
                "Debes completar el registro para continuar",
                'success'
              )
              // await firebase.signOut()
              setTimeout(() => {
                let routeUrl = router.resolve({ 
                  path: '/es/sign-up',
                  query: {
                    email: result.user.email,
                    fullname: result.user.displayName,
                    photo: result.user.photoURL,
                    fromSocial: true,
                    provider: type,
                    step: 2
                  }
                })
                window.location = routeUrl.href
              }, 1000)
              return 

            }

            toastNotification(
              error.message || "Ha ocurrido un error inesperado",
              'error'
            )
          }
          await firebase.signOut()
        }
        
        dispatch('setLoadingOff')
        
      } catch (e) {
        console.log('error', e)
        toastNotification(
          e?.message || "Ha ocurrido un error al intentar iniciar sesión",
          'error'
        )
        console.log('setting loading off')
        dispatch('setLoadingOff')
      }
    },
    async fetchProfile({ commit, getters, rootState, dispatch }) {
      console.log('fetching profile')
      const token = await getters.token
      // const locale = rootState.$i18n.locale // Accediendo al i18n desde el root
      try {
        console.log('fetching profile')
        const userProfileData = await ApolloClient.query({
          context: {
            headers: {
              // lang: locale,
              authorization: `Bearer ${token}`
            }
          },
          fetchPolicy: 'network-only',
          query: userProfileQuery
        })

        console.log('PROFILE Fetch', userProfileData.data)
        commit('setProfile', userProfileData.data.userProfile)
      } catch (e) {
        console.log(e)
        // if (router?.currentRoute?._value?.name !== 'SignIn') {
        //   toastNotification(
        //     "Regístrate para continuar",
        //     'error'
        //   )
        // }
      }
    },
     async fetchUserNoRedirect({ commit, getters, dispatch }, router) {
      console.log('fetching user no redirect')
      dispatch('setLoadingOn')
      try {
        const token = await getters.token
        if (!token) {
          let isLoggedIn = getters.isLoggedIn
          if (isLoggedIn && firebase.currentUser) {
            toastNotification(
              "Tu cuenta existe pero necesitas completar el registro",
              'error'
            )
            commit('setLoggedIn', null)
            // setTimeout(() => {
            //   let routeUrl = router.resolve({ 
            //     path: '/es/sign-up',
            //     query: {
            //       email: firebase.currentUser.email,
            //       fullname: firebase.currentUser.displayName,
            //       photo: firebase.currentUser.photoURL,
            //       fromSocial: true,
            //       provider: firebase.currentUser.providerData[0]?.providerId,
            //       step: 2
            //     }
            //   })
            //   window.location = routeUrl.href
            // }, 1000)
          }
          dispatch('setLoadingOff')
          throw new Error('No token')
        }

        const userData = await getUser(token)
        console.log('NOREDIR userData', userData)
        
        if (!userData || !userData.user) {
          throw new Error('Usuario no encontrado en el sistema')
        }

        commit('setLoggedIn', {
          ...userData.user,
          ...userData.userProfile
        })

        if (router) {
          const currentRoute = router.currentRoute._value.name
          if (currentRoute === 'SignIn' || currentRoute === 'SignUp') {
            dispatch('setLoadingOff')
            if (router.currentRoute._value.query.redirectTo) {
              let routeUrl = router.resolve({ path: router.currentRoute._value.query.redirectTo })
              window.location = routeUrl.href
            } else {
              if (userData.user.role === 'ADMIN') {
                let routeUrl = router.resolve({ path: '/admin' })
                window.location = routeUrl.href
              } else if (router.currentRoute._value.params.eventLang) {
                let routeUrl = router.resolve({
                  name: 'EventoDetail',
                  params: router.currentRoute._value.params
                })
                window.location = routeUrl.href
              } else if (router.currentRoute._value.params.summit) {
                let routeUrl = router.resolve({
                  name: 'LandingSummit'
                })
                window.location = routeUrl.href 
              } else {
                let routeUrl = router.resolve({ 
                  name: 'Profile'
                })
                window.location = routeUrl.href 
              }
            }
          }
        }
      } catch (error) {
        console.log('Error en fetchUserNoRedirect:', error)
        commit('setLoggedIn', null)
        dispatch('setLoadingOff')
        throw error
      }
    },
    async fetchUser({ commit, getters, dispatch }, router) {
      console.log('fetching user')
      const token = await getters.token
      dispatch('setLoadingOn')
      try {
        if (!token) {
          console.log('No token available')
          let isLoggedIn = getters.isLoggedIn
          
          // if (router && ['SignIn', 'SignUp'].includes(router.currentRoute._value.name) && isLoggedIn) {
          //   toastNotification(
          //       "Debes completar el registro para continuar",
          //       'success'
          //     )
          //   commit('setLoggedIn', null)
          //   dispatch('setLoadingOff')
          //   let routeUrl = router.resolve({ path: '/es/sign-up' })
          //   window.location = routeUrl.href
          // }

          commit('setLoggedIn', null)
          dispatch('setLoadingOff')
          return
        }

        const userData = await getUser(token)
        if (!userData || !userData.user) {
          console.log('Usuario no encontrado en el sistema')
          if(!['SignIn', 'SignUp'].includes(router.currentRoute._value.name)){
            await firebase.signOut()
          
            if (router) {
              dispatch('setLoadingOff')
              let routeUrl = router.resolve({ path: '/es/sign-up' })
              window.location = routeUrl.href
            }
          }
          dispatch('setLoadingOff')
          return
        }

        commit('setLoggedIn', {
          ...userData.user,
          ...userData.userProfile
        })

        dispatch('setLoadingOff')

        if (router) {
          const currentRoute = router.currentRoute._value.name
          console.log('Current route:', currentRoute)
          
          if (currentRoute === 'SignIn' || currentRoute === 'SignUp') {
            console.log('User is on login/signup page, redirecting...')
            if (router.currentRoute._value.query.redirectTo) {
              console.log('Redirecting to:', router.currentRoute._value.query.redirectTo)
              let routeUrl = router.resolve({ path: router.currentRoute._value.query.redirectTo })
              window.location = routeUrl.href
              return
            } else {
              let targetRoute = { name: 'Profile' }
              
              if (userData.user.role === 'ADMIN') {
                targetRoute = { path: '/admin' }
              } else if (router.currentRoute._value.params.eventLang) {
                targetRoute = {
                  name: 'EventoDetail',
                  params: router.currentRoute._value.params
                }
              } else if (router.currentRoute._value.params.summit) {
                targetRoute = {
                  name: 'LandingSummit'
                }
              }
              
              console.log('Redirecting to:', targetRoute)
              
              let routeUrl = router.resolve(targetRoute)
              window.location = routeUrl.href
              return
            }
          }
          // toastNotification(
          //   "Has iniciado sesión correctamente",
          //   'success'
          // )
        }

        
      } catch (e) {
        console.log('Error en fetchUser:', e)
        const query = router.currentRoute._value.query
        dispatch('setLoadingOff')
        if(!['SignIn', 'SignUp'].includes(router.currentRoute._value.name) && !query.fromSocial){
          toastNotification(
            "Tu cuenta existe pero necesitas completar el registro",
            'error'
          )
          await this.commit('setUser', null)
          await firebase.signOut()
          if (router) {
            let routeUrl = router.resolve({ path: '/es/sign-up' })
            window.location = routeUrl.href
          }
        }
      }
    },
    async signOut({dispatch}) {
      // await router.push({ name: 'Home' })
      await this.commit('setUser', null)
      dispatch('setLoadingOff')
      await firebase.signOut()
      let routeUrl = router.resolve({ path: '/es/sign-in' })
      window.location = routeUrl.href
    },
    setThemeColor({ commit }, theme) {
      analytics.logEvent('ThemeChange!', { theme })
      commit('setThemeColor', theme)
    }
  },
  getters: {
    user(state) {
      return state.user
    },
    profile(state) {
      return state.profile
    },
    isAuthenticated(state) {
      return state.isLoggedIn
    },
    lang(state) {
      return state.lang
    },
    loading(state) {
      return state.loading
    },
    getCurrentStep(state) {
      return state.currentStep
    },
    isLoggedIn(state) {
      return state.isLoggedIn
    },
    async token(state) {
      if (firebase.currentUser) {
        return await firebase.currentUser.getIdToken(true)
      } else {
        return null
      }
    },
    themeColor(state) {
      return state.themeColor
    }
  },
  plugins: [createPersistedState(), logrocketPlugin]
})
