<template>
  <main class="main--wrapper py-8" v-if="user">
    <div
      class="content--wrapper flex flex-wrap lg:space-x-8 space-y-8 lg:flex-nowrap lg:space-y-0"
    >
      <div class="w-full lg:w-8/12">
        <!-- Card Page Sign Up -->
        <CardPage class="h-full">
          <template #default>
            <!-- Body -->
            <div class="card--page--body pt-8" v-if="user">
              <!-- Section Basic Information -->
              <BasicInformationSection :user="user" :isPublic="true" />
              <!-- End Section Basic Information -->
              <!-- Section Un poco sobre Mi -->
              <AboutMeSection
                v-if="user.about"
                :userAbout="user.about"
                :isPublic="true"
              />
              <!-- End Section Un poco sobre Mi -->
              <!-- Section Tecnologias -->
              <TecnologiasSection
                :userTechnologies="user.technologies"
                v-if="user.technologies?.length > 0"
                :isPublic="true"
              />
              <!-- End Section Tecnologias -->
              <!-- Section Experiencia Profesional -->
              <ExperienciaProfesionalSection
                :userPastJobs="user.pastJobs"
                :userPastProjects="user.pastProjects"
                v-if="user.pastJobs || user.pastProjects"
                :isPublic="true"
              />
              <!-- End Section Experiencia Profesional -->
              <!-- Section Educación Formal -->
              <EducacionFormalSection
                :userFormalEducation="user.formalEducation"
                v-if="user.formalEducation"
                :isPublic="true"
              />
              <!-- Section Educación Formal -->
              <!-- Section Certificaciones y Cursos -->
              <CertificacionesCursosSection
                :userCertsAndCourses="user.certsAndCourses"
                v-if="user.certsAndCourses"
                :isPublic="true"
              />
              <!-- Section Certificaciones y Cursos -->
              <!-- Section Soft Skill -->
              <SoftSkillSection
                v-if="user.takenAssesments?.length > 0"
                :userSoftSkill="user.takenAssesments"
                :isPublic="true"
              />
              <!-- End Section Soft Skill -->
              <!-- Section Hard Skill -->
              <HardSkillSection
                v-if="user.takenAssesments?.length > 0"
                :userHardSkill="user.takenAssesments"
                :isPublic="true"
              />
              <!-- End Section Hard Skill -->
            </div>
            <!-- End Body -->
          </template>
        </CardPage>
        <!-- End Card Page Sign Up -->
      </div>
      <!-- SIDEBAR -->
      <aside class="w-full lg:w-4/12 space-y-8">
        <!-- Estado de Empleo -->
        <BoxExperiencia :user="user" :isPublic="true" />
        <BoxEstadoDeEmpleo :user="user" :isPublic="true" />
        <BoxPreferenciasPersonales
          :industries="user.industries"
          :idealCompany="user.idealCompany"
          :isPublic="true"
        />
        <div v-for="(category, index) in user.takenAssesments" :key="index">
          <BoxChartReference :assesment="category" />
        </div>
        <!-- End Estado de Empleo -->
      </aside>
      <!-- END SIDEBAR -->
    </div>
  </main>
</template>

<script>
import CardPage from '@/components/CardPage'
import BoxEstadoDeEmpleo from '@/components/profile/BoxEstadoDeEmpleo'
import BoxExperiencia from '@/components/profile/BoxExperiencia'
import BoxPreferenciasPersonales from '@/components/profile/BoxPreferenciasPersonales'
import BoxChartReference from '@/components/profile/BoxChartReference'
import BasicInformationSection from '@/components/profile/BasicInformationSection'
import AboutMeSection from '@/components/profile/AboutMeSection'
import TecnologiasSection from '@/components/profile/TecnologiasSection'
import SoftSkillSection from '@/components/profile/SoftSkillSection'
import HardSkillSection from '@/components/profile/HardSkillSection'
import ExperienciaProfesionalSection from '@/components/profile/ExperienciaProfesionalSection'
import EducacionFormalSection from '@/components/profile/EducacionFormalSection'
import CertificacionesCursosSection from '@/components/profile/CertificacionesCursosSection'
import { mapActions } from 'vuex'
import publicProfileQuery from '@/graphql/queries/publicProfile'
import apolloClient from '../graphql/apolloClient'
import { toastNotification } from '@/services/toastNotification'

export default {
  name: 'Profile',
  components: {
    CardPage,
    BoxEstadoDeEmpleo,
    BoxExperiencia,
    BoxPreferenciasPersonales,
    BoxChartReference,
    BasicInformationSection,
    AboutMeSection,
    TecnologiasSection,
    SoftSkillSection,
    HardSkillSection,
    ExperienciaProfesionalSection,
    EducacionFormalSection,
    CertificacionesCursosSection
  },
  data: function () {
    return {
      user: null
    }
  },
  mounted() {
    this.fetchUserData()
  },
  methods: {
    ...mapActions(['setLoadingOn', 'setLoadingOff']),
    fetchUserData: async function () {
      this.setLoadingOn()
      try {
        const publicProfile = await apolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale
            }
          },
          variables: {
            id: this.$route.params.id
          },
          fetchPolicy: 'network-only',
          query: publicProfileQuery
        })
        if (!publicProfile.errors) {
          this.user = publicProfile.data.userPublicProfile
          this.setLoadingOff()
          toastNotification(this.$t('general.info-guardada'), 'success')
        }
      } catch (e) {
        toastNotification(e, 'error')
      }
    }
  }
}
</script>
