<template>
  <main class="main--wrapper py-24 lg:py-40">
    <div class="content--wrapper">
      <!-- Card Page Sign Up -->
      <CardPage class="w-full lg:w-6/12 xl:w-5/12 mx-auto relative">
        <template #default>
          <CodyAnimations :animado="completeFields" class="cody--card" />
          <Form>
            <!-- Title -->
            <div class="card--page--header">
              <Title>
                <template #title>
                  <h3>{{ $t('loguearse-page.title') }}</h3>
                </template>
              </Title>
            </div>
            <!-- End Title -->
            <!-- Body -->
            <div class="card--page--body">
              <p class="dark:text-white text-secondary-text text-lg">
                {{ $t('loguearse-page.texto-bienvenida') }}
              </p>
              <div class="form--group">
                <label class="form--label" for="email">{{
                  $t('loguearse-page.lbl-email')
                }}</label>
                <Field
                  name="email"
                  type="email"
                  class="form--element w-full"
                  :rules="validateEmail"
                  v-model="email"
                />
                <ErrorMessage name="email" class="form--error" />
              </div>
              <div class="form--group">
                <label class="form--label" for="contraseña">{{
                  $t('loguearse-page.lbl-contraseña')
                }}</label>
                <div class="form--icon form--icon--right">
                  <button
                    type="button"
                    @click="showPassword = !showPassword"
                    class="icon--wrapper bg-secondary text-white rounded-r-lg"
                  >
                    <img
                      src="@/assets/images/icn-eye.svg"
                      v-if="showPassword"
                    />
                    <img src="@/assets/images/icn-eye-close.svg" v-else />
                  </button>
                  <Field
                    name="contraseña"
                    :type="[showPassword ? 'text' : 'password']"
                    class="form--element w-full"
                    :rules="validatePassword"
                    v-model="password"
                  />
                </div>
                <ErrorMessage name="contraseña" class="form--error" />
              </div>
              <div class="form--group">
                <label class="form--checkbox"
                  ><input
                    type="checkbox"
                    id="remember"
                    value="first_checkbox" /><span
                    v-html="$t('loguearse-page.lbl-recuerdame')"
                  ></span
                ></label>
              </div>
              <div class="form--group" v-if="error !== null">
                <p class="text-lg text-center text-fourth font-bold">
                  {{ error }}
                </p>
              </div>
              <div class="flex justify-end items-center space-x-6">
                <router-link
                  :to="{ name: 'RecuperarContraseña' }"
                  class="dark:text-primary-text text-secondary-text underline"
                  >{{
                    $t('loguearse-page.lbl-olvidaste-contraseña')
                  }}</router-link
                >
                <button
                  type="button"
                  class="btn btn-lg btn-primary btn-rounded-lg"
                  role="button"
                  :disabled="!completeFields"
                  @click="login"
                >
                  {{ $t('loguearse-page.btn-loguearse') }}
                </button>
              </div>
            </div>
            <!-- End Body -->
            <!-- End Options -->
            <div class="card--page--option">
              <div class="card--page--option--divider">
                <h4>{{ $t('loguearse-page.texto-ingresa-con') }}</h4>
              </div>
              <div class="card--page--body">
                <ul class="flex space-x-4 w-full">
                  <li class="w-full">
                    <button
                      type="button"
                      class="btn btn-lg btn-full btn-rounded-lg btn-github"
                      @click="loginWithGithub"
                    >
                      <img
                        src="@/assets/images/social-media/icn-github.svg"
                        alt="Github"
                      />
                    </button>
                  </li>
                  <!-- <li class="w-full" v-if="false">
                    <button
                      type="button"
                      class="btn btn-lg btn-full btn-rounded-lg btn-linkedin"
                    >
                      <img
                        src="@/assets/images/social-media/icn-linkedin.svg"
                        alt="LinkedIn"
                      />
                    </button>
                  </li> -->
                  <li class="w-full">
                    <button
                      type="button"
                      class="btn btn-lg btn-full btn-rounded-lg btn-gmail"
                      @click="loginWithGoogle"
                    >
                      <img
                        src="@/assets/images/social-media/icn-gmail.svg"
                        alt="Gmail"
                      />
                    </button>
                  </li>
                  <li class="w-full">
                    <button
                      type="button"
                      class="btn btn-lg btn-full btn-rounded-lg btn-facebook"
                      @click="loginWithFacebook"
                    >
                      <img
                        src="@/assets/images/social-media/icn-facebook.svg"
                        alt="Facebook"
                      />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <!-- End Options -->
            <!-- Footer -->
            <div class="card--page--footer">
              <div class="flex items-baseline justify-end space-x-6">
                <p class="dark:text-primary-text text-secondary-text mb-0">
                  {{ $t('loguearse-page.texto-no-tengo-cuenta') }}
                </p>
                <router-link
                  :to="{
                    path: ROUTES.signUpEmpresas,
                    query: isQuery ? { redirectTo: isQuery } : null
                  }"
                  class="btn btn-lg btn-primary btn-rounded-lg"
                  >{{ $t('loguearse-page.btn-crear-cuenta') }}</router-link
                >
              </div>
            </div>
            <!-- End Footer -->
          </Form>
        </template>
      </CardPage>
      <!-- End Card Page Sign Up -->
    </div>
  </main>
</template>
<script>
import CardPage from '@/components/CardPage'
import Title from '@/components/Title'
import CodyAnimations from '@/components/animation/CodyHangingToogle'
import { Field, Form, ErrorMessage } from 'vee-validate'
import ROUTES from '@/constants/routes'

export default {
  name: 'SignInEmpresa',
  components: {
    CardPage,
    Title,
    Field,
    Form,
    ErrorMessage,
    CodyAnimations
  },
  data: function () {
    return {
      email: '',
      password: '',
      error: null,
      showPassword: false,
      ROUTES
    }
  }
}
</script>
