import gql from 'graphql-tag'

const referUserMutation = gql`
  mutation referUser(
    $name: String!
    $email: String!
    $speciality: String!
    $userReferalEmail: String!
  ) {
    referUser(
      name: $name
      email: $email
      speciality: $speciality
      userReferalEmail: $userReferalEmail
    )
  }
`

export default referUserMutation
