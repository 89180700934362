import gql from 'graphql-tag'

const saveLaboralPreferences = gql`
  mutation saveLaboralPreferences(
    $idealJobMode: String
    $idealJobType: String
    $jobTypePreference: [String]
    $jobModePreference: [String]
    $id: Int
  ) {
    updateLaboralPreferences(
      idealJobMode: $idealJobMode
      idealJobType: $idealJobType
      jobTypePreference: $jobTypePreference
      jobModePreference: $jobModePreference
      id: $id
    ) {
      id
      idealJobMode
      idealJobType
      jobTypePreference
      jobModePreference
    }
  }
`

export default saveLaboralPreferences
