<template>
  <main class="main--wrapper">
    <Hero
      :hero-title="$t('mentorias-page.title')"
      :hero-description="$t('mentorias-page.description')"
      section="pageHeroCta"
      :hero-image="require('@/assets/images/hero/bg-comunidad.svg')"
      class="hero--lg"
    >
      <template v-slot:pageHeroCta>
        <div class="flex">
          <button
            type="button"
            @click="scrollMeTo('findMentor')"
            class="btn btn-lg btn-primary btn-rounded-lg"
          >
            {{ $t('mentorias-page.cta') }}
          </button>
        </div>
      </template>
    </Hero>
    <div class="content--wrapper">
      <!-- Section Work in my -->
      <section class="py-20 lg:pt-40">
        <h2 class="title--h3 text-white font-bold text-center mb-4">
          {{ $t('mentorias-page.section-01-title') }}
        </h2>
        <p class="dark:text-primary-text text-secondary-text text-center mb-12">
          {{ $t('mentorias-page.section-01-description') }}
        </p>
        <div class="flex flex-wrap gap-6 justify-center">
          <div v-for="(mentor, index) in mentorOptions" :key="index">
            <label
              :for="'mentorOptions' + index"
              class="btn btn-lg border-2 btn-outline-secondary btn-outline-secondary-glow btn-rounded-lg w-full lg:w-auto"
            >
              {{ mentor }}
            </label>
            <input
              type="checkbox"
              v-model="mentorOption"
              :id="'mentorOptions' + index"
              :value="mentor"
              class="hidden"
            />
          </div>
        </div>
      </section>
      <!-- End Section Work in my -->
      <!-- Mentorship Programme -->
      <section
        class="py-20 lg:py-40 flex flex-wrap lg:justify-between items-center"
      >
        <div class="w-full lg:w-5/12">
          <h2 class="title--h3 text-white font-bold mb-4">
            {{ $t('mentorias-page.section-02-title') }}
          </h2>
          <p class="dark:text-primary-text text-secondary-text">
            {{ $t('mentorias-page.section-02-description-a') }}
          </p>
          <p class="dark:text-primary-text text-secondary-text mb-8">
            {{ $t('mentorias-page.section-02-description-b') }}
          </p>
        </div>

        <div class="w-full lg:w-6/12">
          <img
            src="@/assets/images/mentorias/img-mentoring-01.jpg"
            class="w-full mx-auto"
            alt="Utopicode"
          />
        </div>
      </section>
    </div>
    <!-- End Mentorship Programme -->
    <!-- Mentors -->
    <section class="bg-footer py-20 lg:py-40">
      <div class="content--wrapper">
        <h3 class="title--h3 text-white font-bold text-center mb-6">
          {{ $t('mentorias-page.mentor-title') }}
        </h3>
        <p
          class="dark:text-primary-text text-secondary-text lg:w-8/12 mx-auto text-center mb-16"
        >
          {{ $t('mentorias-page.mentor-description') }}
        </p>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <div
            v-for="(mentor, index) in mentors"
            :key="index"
            class="text-center"
          >
            <div
              class="h-64 w-64 rounded-5xl relative mx-auto overflow-hidden mb-6"
            >
              <img
                :src="mentor.image"
                :alt="mentor.name"
                class="object-center object-cover absolute top-0 left-0 w-full h-full z-0"
              />
            </div>
            <h4 class="uppercase text-white text-2xl font-bold mb-2">
              {{ mentor.name }}
            </h4>
            <h5 class="dark:text-primary-text text-secondary-text">
              {{ mentor.position }}
            </h5>
            <h6 class="dark:text-primary-text text-secondary-text mb-8">
              {{ mentor.role }}
            </h6>
            <div class="flex flex-wrap gap-4 justify-center items-center mb-3">
              <img
                src="@/assets/images/icn-star.svg"
                v-for="(star, index) in mentor.star"
                :key="index"
              />
            </div>
            <blockquote
              class="dark:text-primary-text text-secondary-text text-sm italic mb-4"
            >
              {{ mentor.blockquote }}
              <p class="mt-4">- {{ mentor.cite }}</p>
            </blockquote>
          </div>
        </div>
      </div>
    </section>
    <!-- End Mentors -->
    <div class="content--wrapper">
      <!-- Mentorship Programme -->
      <section
        class="py-20 lg:py-40 flex flex-wrap lg:justify-between items-center"
      >
        <div class="w-full lg:w-6/12 lg:order-2">
          <h2 class="title--h3 text-white font-bold mb-10">
            {{ $t('mentorias-page.section-03-title') }}
          </h2>
          <ol class="list--education list--education--secondary mb-8">
            <li
              v-for="(number, index) in 3"
              :key="index"
              :class="index + 1 === 3 ? 'last' : ''"
            >
              <span class="list--education--bullets">0{{ index + 1 }}</span>
              <h4 class="text-white font-bold title--h6 mb-2">
                {{ $t(`mentorias-page.how-it-work-list-title-0${index + 1}`) }}
              </h4>
              <p class="dark:text-primary-text text-secondary-text text-lg">
                {{
                  $t(
                    `mentorias-page.how-it-work-list-description-0${index + 1}`
                  )
                }}
              </p>
            </li>
          </ol>
        </div>
        <div class="w-full lg:w-5/12 lg:order-1">
          <img
            src="@/assets/images/mentorias/img-mentoring-02.jpg"
            class="w-full mx-auto"
            alt="Utopicode"
          />
        </div>
      </section>
      <!-- End Mentorship Programme -->
      <!-- Section Form -->
      <section id="find-mentor" ref="findMentor" class="py-20 pt-32">
        <h2 class="title--h3 text-white font-bold text-center mb-12">
          {{ $t('mentorias-page.get-matched-title') }}
        </h2>
        <div class="w-full lg:w-6/12 mx-auto">
          <CardPage>
            <template #default>
              <div v-if="enviado">
                <div class="card--page--header">
                  <Title>
                    <template #title>
                      <h3>
                        {{ $t('form-validation.validation-hire-devs-1') }}
                      </h3>
                    </template>
                  </Title>
                </div>
                <div class="card--page--body">
                  <p class="dark:text-primary-text text-secondary-text">
                    {{ $t('form-validation.validation-hire-devs-2') }}
                  </p>
                  <div
                    class="dark:text-primary-text text-secondary-text"
                    v-html="$t('form-validation.validation-hire-devs-4')"
                  ></div>
                </div>
                <div class="card--page--footer">
                  <button
                    type="button"
                    class="btn btn-primary btn-lg btn-rounded-lg mx-auto"
                    @click="resetForm"
                  >
                    {{ $t('general.volver') }}
                  </button>
                </div>
              </div>
              <Form @submit="sendMatch" v-else>
                <!-- Title -->
                <div class="card--page--header">
                  <Title>
                    <template #title>
                      <h3>{{ $t('mentorias-page.card-form-title') }}</h3>
                    </template>
                  </Title>
                </div>
                <!-- End Title -->
                <!-- Body -->
                <div
                  class="card--page--body grid grid-cols-1 lg:grid-cols-2 gap-x-6"
                >
                  <div class="form--group">
                    <label class="form--label" for="name">{{
                      $t('mentorias-page.lbl-nombre')
                    }}</label>
                    <Field
                      name="name"
                      type="text"
                      class="form--element w-full"
                      :rules="validateDefault"
                    />
                    <ErrorMessage name="name" class="form--error" />
                  </div>
                  <div class="form--group">
                    <label class="form--label" for="email">{{
                      $t('mentorias-page.lbl-email')
                    }}</label>
                    <Field
                      name="email"
                      type="email"
                      class="form--element w-full"
                      :rules="validateEmail"
                    />
                    <ErrorMessage name="email" class="form--error" />
                  </div>
                  <div class="form--group">
                    <label class="form--label" for="dateOfBirth">{{
                      $t('mentorias-page.lbl-date')
                    }}</label>
                    <Field
                      name="dateOfBirth"
                      type="date"
                      class="form--element w-full"
                      :rules="validateDefault"
                    />
                    <ErrorMessage name="dateOfBirth" class="form--error" />
                  </div>

                  <div class="form--group">
                    <label class="form--label" for="level">{{
                      $t('mentorias-page.lbl-level')
                    }}</label>
                    <Field
                      name="level"
                      as="select"
                      class="form--element form--select w-full"
                      :rules="validateDefault"
                    >
                      <option value="" disabled></option>
                      <option
                        v-for="level in levels"
                        :key="level"
                        :value="level"
                      >
                        {{ level }}
                      </option>
                    </Field>
                    <ErrorMessage name="level" class="form--error" />
                  </div>
                  <div class="form--group col-span-full mt-6">
                    <label
                      class="text-white text-xl font-bold flex mb-3"
                      for="mentorship"
                      >{{ $t('mentorias-page.lbl-mentorship') }}</label
                    >
                    <Field
                      name="mentorship"
                      type="div"
                      :rules="validateDefault"
                      v-slot="{ field }"
                    >
                      <textarea
                        v-bind="field"
                        class="form--element w-full"
                        rows="6"
                        :placeholder="
                          $t('mentorias-page.placeholder-mentorship')
                        "
                      />
                    </Field>
                    <ErrorMessage name="mentorship" class="form--error" />
                  </div>
                </div>
                <!-- End Body -->
                <!-- Footer -->
                <div class="card--page--footer">
                  <div class="flex items-baseline justify-center space-x-6">
                    <button
                      type="submit"
                      class="btn btn-xl btn-secondary btn-rounded-lg"
                    >
                      {{ $t('general.enviar') }}
                    </button>
                  </div>
                </div>
                <!-- End Footer -->
              </Form>
            </template>
          </CardPage>
        </div>
      </section>
      <!-- Section Form -->
    </div>
  </main>
</template>

<script>
import Hero from '@/components/Hero'
import Title from '@/components/Title'
import CardPage from '@/components/CardPage'
import { Field, Form, ErrorMessage } from 'vee-validate'
import { validateDefault, validateEmail } from '@/services/validationRules'

export default {
  components: {
    Hero,
    Title,
    CardPage,
    Form,
    Field,
    ErrorMessage
  },
  data() {
    return {
      enviado: false,
      levels: ['Trainee', 'Junior', 'Mid Level', 'Senior', 'Arquitect'],
      mentorOption: [],
      mentorOptions: [
        'Leadership Skills',
        'English Language Skills',
        'Javascript Knowledge',
        'Ruby on Rails Knowledge',
        'C++ Knowledge',
        'General Hard Skills',
        'Communication Skills',
        'Career Goals',
        'Career Goals',
        'General Soft Skills',
        'Market Awareness',
        'Teamwork Skills',
        'Emotional Intelligence',
        'Backend Knowledge',
        'Industry Awareness',
        'Frontend Knowledge'
      ],
      mentors: [
        {
          name: 'Nuria Torres',
          position: 'Principal Software',
          role: 'Developer @ Google',
          image:
            'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8cGVyc29ufGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80',
          blockquote:
            'A mentor with an undying thirst for knowledge. I recommend Nuria to any junior developer out there looking to take control of their career.',
          cite: 'Paula, Junior Developer @ Konfio',
          star: 5
        },
        {
          name: 'Maria Emilia Torres',
          position: 'FrontEnd Software',
          role: 'Developer @ Amazon',
          image:
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcOx9yBh3lymVio2oWSVBaF0qfM507bAJKt6DvMCubYrqY4S1kvbkeFKyAWZtwe7TsniM&usqp=CAU',
          blockquote:
            'A mentor with an undying thirst for knowledge. I recommend Nuria to any junior developer out there looking to take control of their career.',
          cite: 'Paula, Junior Developer @ Konfio',
          star: 4
        },
        {
          name: 'Francisco Torres',
          position: 'Arquichet Software',
          role: 'Developer @ Microsoft',
          image:
            'https://images.unsplash.com/photo-1547425260-76bcadfb4f2c?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8cGVyc29ufGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80',
          blockquote:
            'A mentor with an undying thirst for knowledge. I recommend Nuria to any junior developer out there looking to take control of their career.',
          cite: 'Paula, Junior Developer @ Konfio',
          star: 3
        }
      ]
    }
  },
  methods: {
    // Reset Form
    resetForm() {
      this.enviado = !this.enviado
    },
    sendMatch: async function (values) {
      console.log(values)
      this.enviado = true
    },
    // RULES
    validateDefault,
    validateEmail,
    scrollMeTo(refName) {
      const element = this.$refs[refName]
      const top = element.offsetTop
      window.scrollTo({ top, behavior: 'smooth' })
    }
  }
}
</script>
