<template>
  <div
    class="tooltip--globe !p-0 m-0 tooltip--xs !top-[105px] !right-[50%] !w-[290px] flex gap-4 items-stretch !bg-[#31B99B] lg:transform lg:translate-x-1/2 lg:translate-y-1/3"
  >
    <div class="w-full absolute mt-[-19px] flex justify-center">
      <div
        class="w-0 h-0 border-l-[10px] border-b-[#31B99B] border-r-[10px] border-b-[20px] border-l-transparent border-r-transparent"
      ></div>
    </div>

    <div class="w-full h-full m-0 p-4 flex-1">
      <p class="text-black text-xl font-bold mb-0">{{ title }}</p>
      <div class="flex items-center mt-2">
        <p class="text-sm dark:text-black font-normal p-0 text-black last">
          {{ message }}
        </p>
      </div>
      <div class="flex items-center mt-4">
        <svg
          width="25"
          height="25"
          class="min-w-[25px] mr-2 !mt-[-2px]"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.4732 22.253C7.22294 22.253 2.9668 17.9968 2.9668 12.7466C2.9668 7.49638 7.22294 3.24023 12.4732 3.24023C17.7234 3.24023 21.9795 7.49638 21.9795 12.7466C21.9676 17.9919 17.7184 22.241 12.4732 22.253ZM12.4732 4.86771C8.12298 4.86771 4.59646 8.39423 4.59646 12.7444C4.59646 17.0946 8.12298 20.6211 12.4732 20.6211C16.8234 20.6211 20.3499 17.0946 20.3499 12.7444C20.3439 8.39671 16.8209 4.87369 12.4732 4.86771ZM10.2999 16.2667C10.4622 16.4318 10.6879 16.5189 10.9191 16.5057C11.1362 16.5106 11.3452 16.4238 11.495 16.2667L17.3509 10.3347C17.6686 10.0166 17.6686 9.5012 17.3509 9.18307C17.1996 9.02776 16.9919 8.94017 16.7751 8.94017C16.5582 8.94017 16.3506 9.02776 16.1993 9.18307L10.8757 14.4958L8.7028 12.3229C8.38175 12.0237 7.88144 12.0326 7.57114 12.3429C7.26084 12.6532 7.25201 13.1535 7.55117 13.4745L10.2999 16.2667Z"
            fill="black"
          />
        </svg>

        <p
          class="text-sm dark:text-black font-semibold p-0 italic text-black last"
        >
          {{ subtitle }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SuccessBadgeTooltip',
  props: {
    title: String,
    message: String,
    subtitle: String,
    url: String
  }
}
</script>
