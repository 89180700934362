<template>
  <div v-if="showModal" class="welcome flex justify-center items-center">
    <div
      class="welcome-container text-white px-10 py-12 rounded-xl border-2 w-full relative"
      :class="'border-[#B9A3FF]'"
      style="min-width: 600px !important; background-color: #ffffff !important"
    >
      <button
        class="absolute top-3 right-3 text-white"
        @click="$emit('cerrarModal')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          fill="black"
        >
          <path
            d="M6.225 4.811l5.298 5.298 5.298-5.298 1.414 1.414-5.298 5.298 5.298 5.298-1.414 1.414-5.298-5.298-5.298 5.298-1.414-1.414 5.298-5.298-5.298-5.298z"
          />
        </svg>
      </button>
      <div class="modal-title text-center mb-4">
        <figure
          class="flex justify-center absolute -top-24 left-1/2 transform -translate-x-1/2"
        >
          <img
            src="@/assets/images/utopicode-icon-laptop.svg"
            alt="img"
            class="w-50 h-auto pt-10"
          />
        </figure>
        <div
          class="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-10"
          style="
            min-height: -webkit-fill-available;
            margin-top: 110px !important;
          "
        >
          <div class="flex-grow-0 flex-shrink-0 flex items-center relative">
            <p class="mb-0 text-[25.979999542236328px] text-center text-black">
              C <span>&nbsp;</span>
              mparte tu perfil
            </p>
            <svg
              width="18"
              height="18"
              viewBox="0 0 15 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="absolute left-[19.2px] top-[10px]"
              preserveAspectRatio="xMidYMid meet"
            >
              <path
                d="M13.6727 11.2785L7.96204 8.50613L13.6748 5.73319C13.8369 5.64298 13.9693 5.45498 14.0292 5.22323C14.0897 4.98823 14.0654 4.75108 13.9639 4.58847C13.8526 4.38859 13.6165 4.23139 13.3426 4.1698C13.1298 4.12172 12.9337 4.14171 12.8191 4.22382L7.49259 7.83032L7.99175 1.45197C8.02471 1.16349 7.92963 0.951184 7.70976 0.813429C7.39481 0.616789 6.89187 0.625973 6.56396 0.835037C6.40838 0.934437 6.23172 1.12027 6.27224 1.44332L6.77248 7.82978L1.44972 4.22598C1.06724 4.01422 0.512439 4.21356 0.307697 4.52634C0.0905293 5.01902 0.280685 5.57815 0.592391 5.73373L6.3025 8.50613L0.595093 11.2769C0.426005 11.3725 0.295273 11.5589 0.235849 11.789C0.175344 12.024 0.199654 12.2612 0.301214 12.4232C0.412499 12.6231 0.648575 12.7803 0.922465 12.8419C1.13531 12.89 1.33141 12.87 1.44594 12.7879L6.77248 9.1814L6.27332 15.5598C6.23226 15.8914 6.40892 16.0773 6.5645 16.1767C6.89187 16.3857 7.39536 16.3949 7.7103 16.1983C7.93017 16.0605 8.02525 15.8482 7.99284 15.5668L7.49259 9.18194L12.8154 12.7857C13.1984 12.9975 13.7532 12.7982 13.9574 12.4854C14.1745 11.9932 13.9844 11.4341 13.6727 11.2785Z"
                fill="#FF9980"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div class="modal-form space-y-4 pt-5">
        <div class="flex items-center gap-2">
          <input
            type="text"
            class="form--element w-full px-3 py-2"
            :value="currentUrl"
            style="
              background-color: #e3e9ff !important;
              color: #000000 !important;
            "
          />
          <button
            type="submit"
            aria-pressed="false"
            @click="copyToClipboard"
            class="flex items-center gap-2 px-4 py-2 focus:outline-none"
            style="
              background-color: #e3e9ff;
              color: #5956d9;
              border-radius: 8px;
              border: 1px solid #5956d9;
              min-width: 170px !important;
            "
          >
            <svg
              width="19"
              height="22"
              viewBox="0 0 19 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.20703 4.41602H15.207C16.867 4.41602 18.207 5.75602 18.207 7.41602V18.416C18.207 20.076 16.867 21.416 15.207 21.416H7.20703C5.54703 21.416 4.20703 20.076 4.20703 18.416V17.416H5.20703V18.416C5.20703 19.516 6.10703 20.416 7.20703 20.416H15.207C16.307 20.416 17.207 19.516 17.207 18.416V7.41602C17.207 6.31602 16.307 5.41602 15.207 5.41602H7.20703C6.10703 5.41602 5.20703 6.31602 5.20703 7.41602V12.416H4.20703V7.41602C4.20703 5.75602 5.54703 4.41602 7.20703 4.41602ZM3.20703 0.416016H13.207V1.41602H3.20703C2.10703 1.41602 1.20703 2.31602 1.20703 3.41602V12.416C1.20703 13.516 2.10703 14.416 3.20703 14.416H10.457L8.20703 12.166L8.86703 11.416L12.367 14.916L8.86703 18.416L8.20703 17.666L10.457 15.416H3.20703C1.54703 15.416 0.207031 14.076 0.207031 12.416V3.41602C0.207031 1.75602 1.54703 0.416016 3.20703 0.416016Z"
                fill="#5956d9"
              />
            </svg>

            Copiar enlace
          </button>
        </div>
        <div class="flex justify-center items-center text-center gap-2">
          <button
            type="button"
            aria-pressed="false"
            class="flex items-center gap-2 px-4 py-2 focus:outline-none"
            @click="
              downloadPDF(
                `Curriculum - ${userProfile.fullname}-${userProfile.yearsOfExp}expYears-${userProfile.id}`,
                'light'
              )
            "
            style="
              background-color: #e3e9ff;
              color: #5956d9;
              border-radius: 8px;
              border: 1px solid #5956d9;
              min-width: 170px !important;
            "
          >
            <svg
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.20703 0.714844V12.9648L14.457 7.71484L15.207 8.37484L8.70703 14.8748L2.20703 8.37484L2.95703 7.71484L8.20703 12.9648V0.714844H9.20703ZM0.207031 15.7148H1.20703V17.7148H16.207V15.7148H17.207V18.7148H0.207031V15.7148Z"
                fill="#5956d9"
              />
            </svg>
            Descargar tu perfil en pdf
          </button>
        </div>
      </div>
      <div class="modal-footer text-center mb-4 mt-8">
        <div class="card--page--option">
          <div class="flex items-baseline justify-center space-x-6 mb-5">
            <p class="text-black text-black-text mb-0">O compartir en:</p>
          </div>
          <div class="card--page--body">
            <ul class="flex space-x-4 w-full">
              <li class="w-full">
                <a
                  :href="`https://www.linkedin.com/shareArticle?mini=true&url=${currentUrl}&title=${encodeURIComponent(
                    'Visita mi perfil en Utopicode'
                  )}&summary=${encodeURIComponent(
                    'Visita mi perfil en Utopicode y conoce mi trayectoria profesional'
                  )}`"
                  target="_blank"
                  class="w-full"
                >
                  <button
                    type="button"
                    class="btn btn-lg btn-full btn-rounded-lg btn-linkedin"
                  >
                    <img
                      src="@/assets/images/social-media/icn-linkedin.svg"
                      alt="LinkedIn"
                    />
                  </button>
                </a>
              </li>
              <li class="w-full">
                <a
                  :href="`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=${encodeURIComponent(
                    'Visita mi perfil en Utopicode'
                  )}&body=${encodeURIComponent(
                    'Visita mi perfil en Utopicode y conoce mi trayectoria profesional: ' +
                      currentUrl
                  )}`"
                  target="_blank"
                  class="w-full"
                >
                  <button
                    type="button"
                    class="btn btn-lg btn-full btn-rounded-lg btn-gmail"
                  >
                    <img
                      src="@/assets/images/social-media/icn-gmail.svg"
                      alt="Gmail"
                    />
                  </button>
                </a>
              </li>
              <li class="w-full">
                <a
                  :href="`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}&quote=${encodeURIComponent(
                    'Visita mi perfil en Utopicode y conoce mi trayectoria profesional'
                  )}`"
                  target="_blank"
                  class="w-full"
                >
                  <button
                    type="button"
                    class="btn btn-lg btn-full btn-rounded-lg btn-facebook"
                  >
                    <img
                      src="@/assets/images/social-media/icn-facebook.svg"
                      alt="Facebook"
                    />
                  </button>
                </a>
              </li>
              <li class="w-full">
                <a
                  :href="`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                    'Visita mi perfil en Utopicode y conoce mi trayectoria profesional: ' +
                      currentUrl
                  )}`"
                  target="_blank"
                  class="w-full"
                >
                  <button
                    type="button"
                    class="btn btn-lg btn-full btn-rounded-lg btn-github"
                  >
                    <img
                      src="@/assets/images/social-media/x-white.png"
                      alt="Twitter"
                      width="22px"
                    />
                  </button>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ModalProfileShareEmpresarial',
  props: {
    userProfile: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showModal: true,
      currentUrl: window.location.href
    }
  },
  computed: {
    ...mapGetters(['loading', 'user', 'profile', 'token'])
  },
  methods: {
    ...mapActions(['setLoadingOn', 'setLoadingOff']),
    async downloadPDF(pdfName, background, wait = false) {
      console.log('pdfName', pdfName)
      this.showModal = false
      try {
        this.setLoadingOn()
        const userId = this.$route.params.id
        const domain = location.hostname
        const protocol = location.protocol
        const profileUrl = `${protocol}//${domain}/es/profile-light-pdf/${userId}`
        const generatorUrl = process.env.VUE_APP_CV_PDF_GENERATOR_URL
        const apiUrl = `${generatorUrl}?background=${background}&url=${profileUrl}`
        const response = await fetch(apiUrl)
        if (!response.ok) throw new Error('Error al obtener el PDF')

        // Obtener el contenido en formato JSON
        const data = await response.json()

        // Decodificar el contenido del PDF desde base64
        const pdfContent = atob(data.data) // Suponiendo que la propiedad es 'data'

        // Convertir el contenido decodificado a un array de bytes
        const byteArray = new Uint8Array(pdfContent.length)
        for (let i = 0; i < pdfContent.length; i++) {
          byteArray[i] = pdfContent.charCodeAt(i)
        }

        // Crear un Blob desde el array de bytes
        const blob = new Blob([byteArray], { type: 'application/pdf' })

        // Crear una URL temporal para el archivo PDF
        const url = window.URL.createObjectURL(blob)

        // Crear un enlace invisible y hacer clic en él para iniciar la descarga
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = pdfName.trim() // Usar el nombre proporcionado para la descarga
        document.body.appendChild(a)
        a.click()

        // Limpiar después de la descarga
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)

        // Esperar si se requiere
        if (wait) await new Promise((resolve) => setTimeout(resolve, 1000)) // Espera de 1 segundo por defecto
      } catch (error) {
        console.error('Error durante la descarga del PDF:', error)
      } finally {
        // Indicar que la descarga ha terminado
        this.setLoadingOff()
      }
    },
    copyToClipboard() {
      // Crear un elemento `textarea` temporal
      const tempTextArea = document.createElement('textarea')

      // Asigna el valor de `currentUrl` al `textarea`
      tempTextArea.value = this.currentUrl

      // Asegura que el textarea esté fuera de la vista
      tempTextArea.style.position = 'absolute'
      tempTextArea.style.left = '-9999px'

      // Agrega el textarea temporal al DOM
      document.body.appendChild(tempTextArea)

      // Selecciona y copia el texto dentro del textarea
      tempTextArea.focus()
      tempTextArea.select()

      try {
        // Ejecuta el comando de copia
        const successful = document.execCommand('copy')
        if (successful) {
          alert('¡Enlace copiado al portapapeles!')
        } else {
          console.error('Error al copiar el enlace')
        }
      } catch (err) {
        console.error('Error al copiar el enlace: ', err)
      }

      // Remueve el textarea temporal
      document.body.removeChild(tempTextArea)
    }
  }
}
</script>
