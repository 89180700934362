<template>
  <main class="main--wrapper">
    <Hero
      :hero-title="$t('cursos-page.title')"
      :hero-description="$t('cursos-page.description')"
      section="pageHeroCta"
      :hero-image="require('@/assets/images/hero/bg-comunidad.svg')"
      class="hero--md"
    >
    </Hero>
    <div class="content--wrapper">
      <!-- Filter -->
      <section
        class="mt-4 mb-16 grid gap-x-6 gap-y-4 lg:grid-cols-4 md:grid-cols-3 grid-cols-1"
      >
        <select class="form--filter form--select">
          <option value="test">test</option>
        </select>
        <select class="form--filter form--select">
          <option value="test">test</option>
        </select>
        <select class="form--filter form--select">
          <option value="test">test</option>
        </select>
        <select class="form--filter form--select">
          <option value="test">test</option>
        </select>
        <select class="form--filter form--select">
          <option value="test">test</option>
        </select>
        <select class="form--filter form--select">
          <option value="test">test</option>
        </select>
        <select class="form--filter form--select">
          <option value="test">test</option>
        </select>
        <button type="button" class="btn btn-rounded-lg btn-lg btn-secondary">
          {{ $t('general.resetear') }}
        </button>
      </section>
      <!-- End Filter -->
      <!-- Cards Events -->
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-8">
        <CardComunidad
          v-for="conference in courses"
          v-bind:key="conference.id"
          v-bind:conferenceData="conference"
        />
      </div>
      <Pagination class="justify-center" :totalPages="3" :currentPage="1" />
      <!-- End Cards Events -->
    </div>
  </main>
</template>

<script>
import Hero from '@/components/Hero'
import CardComunidad from '@/components/CardComunidad'
import ApolloClient from '@/graphql/apolloClient'
import EventsQuery from '@/graphql/queries/events'
import { mapActions } from 'vuex'
import { toastNotification } from '@/services/toastNotification'
import Pagination from '@/components/Pagination'

export default {
  components: {
    Hero,
    CardComunidad,
    Pagination
  },
  data: function () {
    return {
      conferences: [],
      pastConferences: [],
      courses: [
        {
          id: 1,
          category: 'Javascript',
          name: 'New Emotional Intelligence and How to Develop It',
          url: 'http://www.google.com.ar',
          photo:
            'https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1170&q=80',
          subCategories: ['react', 'vue'],
          duration: '2 Weeks',
          description:
            'Elementum pulvinar rutrum amet est consectetur libero ut nisi. Ultrices in elit eu odio. Nisi rutrum vulputate et vestibulum ornare cras ut.'
        },
        {
          id: 2,
          category: 'Python',
          name: 'What’s Happening with AI Today?',
          url: 'http://www.google.com.ar',
          photo:
            'https://www.sciencenewsforstudents.org/wp-content/uploads/2021/08/LL_AI_feat-1030x580.jpg',
          subCategories: ['react', 'vue'],
          duration: '5 Weeks',
          description:
            'Elementum pulvinar rutrum amet est consectetur libero ut nisi. Ultrices in elit eu odio. Nisi rutrum vulputate et vestibulum ornare cras ut.'
        }
      ]
    }
  },
  mounted() {
    this.getEvents()
  },
  updated() {
    this.getEvents()
  },
  methods: {
    ...mapActions(['setLoadingOff']),
    getEvents: async function () {
      try {
        const eventsQuery = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale
            }
          },
          fetchPolicy: 'network-only',
          query: EventsQuery
        })
        this.pastConferences = eventsQuery.data.pastEvents
        this.conferences = eventsQuery.data.events
        this.setLoadingOff()
      } catch (e) {
        toastNotification(e, 'error')
      }
    }
  }
}
</script>
