<template>
  <!-- END SIDEBAR -->
  <div class="w-full lg:w-8/12 lg:pr-6" :class="[getCurrentStep == 5 ? 'tutorial-step-5 relative' : '']">
    <div class="tutorial-step-5-text bg-[#B9A3FF] text-black w-[290px] rounded-xl p-4 shadow-xl absolute"
      v-if="getCurrentStep == 5">
      <div class="tutorial-step-5-text-polygon bg-[#B9A3FF] absolute"></div>
      <div class="tutorial-step-5-text-exit absolute cursor-pointer" @click="goToNextStep(-1)">
        <span>
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.63238 14.0905L5.10156 13.5597L9.30156 9.35972L5.10156 5.15972L5.63238 4.62891L9.83238 8.82891L14.0324 4.62891L14.5632 5.15972L10.3632 9.35972L14.5632 13.5597L14.0324 14.0905L9.83238 9.89053L5.63238 14.0905Z"
              fill="#1E1D1D" />
          </svg>
        </span>
      </div>
      <h3 class="font-bold m-0" style="font-size: 1.55rem">
        {{ $t('profile_steps.seccion_de_edicion') }}
      </h3>
      <p class="my-2">
        {{ $t('profile_steps.seccion_de_edicion_description') }}
      </p>
      <div class="flex justify-start">
        <button type="button" class="btn btn-md btn-rounded-lg btn-primary" @click="goToNextStep(-1)">
          <span class="mr-4 text-sm"> Finalizar </span>
          <svg width="36" height="16" viewBox="0 0 36 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M35.0108 7.28319C33.5064 6.84251 32.1084 6.15869 30.8623 5.23174C29.2668 4.04646 27.9295 2.45089 27.033 0.642578L26.0756 1.11365C27.0633 3.07392 28.4918 4.77586 30.2241 6.06752C30.8167 6.5082 31.4398 6.90329 32.1084 7.2528H0.394531V8.31651H32.3667C31.6829 8.7116 31.0447 9.15229 30.452 9.66895C28.1423 11.6596 27.0785 14.0454 26.5771 15.6865L27.5952 15.9904C28.0511 14.4709 29.0236 12.2826 31.1358 10.4743C32.2755 9.50179 33.5824 8.75719 35.026 8.28612L35.0108 7.28319ZM33.2481 7.86063V7.78465C33.2785 7.79985 33.2937 7.79985 33.3241 7.81505C33.2937 7.83024 33.2785 7.84544 33.2481 7.86063Z"
              fill="#1E1D1D" />
          </svg>
        </button>
      </div>
    </div>

    <div class="tutorial-step-2-container absolute" v-if="getCurrentStep == 5"></div>

    <div class="tutorial-step-5-container-black absolute" v-if="getCurrentStep == 5"></div>

    <!-- Card Page Sign Up -->
    <CardPage class="bg-transparentAux">
      <template #default>
        <!-- Body -->
        <div class="card--page--body pt-10" v-if="user">
          <!-- Section Basic Information -->
          <BasicInformationSection :user="user" :userAbout="userProfile?.about" />
          <!-- End Section Basic Information -->
          <!-- Section Un poco sobre Mi -->
          <!-- <AboutMeSection :userAbout="userProfile.about" /> -->
          <!-- End Section Un poco sobre Mi -->
          <!-- Estado de Empleo -->
          <!-- <BoxExperiencia :user="userProfile" />
                <BoxEstadoDeEmpleo :user="userProfile" />
                <BoxPreferenciasPersonales
                  :industries="userProfile.industries"
                  :idealCompany="userProfile.idealCompany"
                /> -->
          <!-- End Estado de Empleo -->
          <!-- Section Social Media -->
          <!-- <SocialMediaSection
                  :socialNetworks="userProfile.socialNetworks"
                /> -->
          <!-- End Section Social Media -->
          <!-- Section Tecnologias -->
          <!-- <TecnologiasSection
                  :userTechnologies="userProfile.technologies"
                /> -->
          <!-- End Section Tecnologias -->
          <!-- Section Experiencia Profesional -->
          <!-- <ExperienciaProfesionalSection
                  :userPastJobs="userProfile.pastJobs"
                /> -->
          <!-- End Section Experiencia Profesional -->
          <!-- Section Certificaciones y Cursos -->
          <!-- <CertificacionesCursosSection
                  :userCertsAndCourses="userProfile.certsAndCourses"
                /> -->
          <!-- Section Certificaciones y Cursos -->
          <!-- Section Soft Skill -->
          <!-- <SoftSkillSection
                  :userSoftSkill="userProfile.takenAssesments"
                /> -->
          <!-- End Section Soft Skill -->
          <!-- Section Hard Skill -->
          <!-- <HardSkillSection
                  :userHardSkill="userProfile.takenAssesments"
                /> -->
          <!-- End Section Hard Skill -->
        </div>
        <!-- End Body -->
      </template>
    </CardPage>
    <!-- End Card Page Sign Up -->
  </div>
</template>

<script>
import CardPage from '@/components/CardPage'
// import BoxEstadoDeEmpleo from '@/components/profile/BoxEstadoDeEmpleo'
import BasicInformationSection from '@/components/profile/BasicInformationSection'

import ApolloClient from '@/graphql/apolloClient'
import assesmentCategoriesQuery from '@/graphql/queries/assesmentCategories'
import userProfileQuery from '@/graphql/queries/userProfile'
import { mapGetters, mapActions } from 'vuex'
import { toastNotification } from '@/services/toastNotification'

export default {
  name: 'Profile',
  data() {
    return {
      userProfile: null,
      modalBienvenidaShow: false,
      assesmentCategories: []
    }
  },
  components: {
    CardPage,
    BasicInformationSection
  },
  updated() {
    this.getAssesmentCategories()
  },
  mounted() {
    this.setLoadingOn()
    this.getUserProfile()
    this.getAssesmentCategories()
  },
  watch: {
    modalBienvenidaShow(newName) {
      localStorage.modalBienvenidaShow = newName
    }
  },
  computed: {
    ...mapGetters(['token', 'user', 'getCurrentStep'])
    // completionPercent: function () {
    //   const userObject = this.userProfile
    //   console.log(this.userProfile)
    //   const value = Object.keys(this.userProfile).reduce((acc, val) => {
    //     const realValue = userObject[val]
    //     if (realValue !== null && realValue !== '') {
    //       if (val === 'takenAssesments') {
    //         if (realValue.length === 4) {
    //           return acc + 1
    //         } else return acc
    //       }
    //       if (Array.isArray(realValue)) {
    //         return realValue.length > 0 ? acc + 1 : acc
    //       }
    //       return acc + 1
    //     } else {
    //       return acc
    //     }
    //   }, 0)
    //   return Math.round((value * 100) / Object.values(this.userProfile).length)
    // }
  },
  methods: {
    ...mapActions(['setLoadingOn', 'setLoadingOff', 'updateStep']),
    showTutorialModal: function () {
      console.log('FirstLogin', this.$route.query.firstLogin === 'true')
      // Comprobar si ya se ha mostrado el modal antes y almacenarlo en localStorage
      if (localStorage.getItem('modalBienvenidaShown')) {
        // Si el modal ya se mostró anteriormente, asegurar que no se muestre de nuevo
        this.modalBienvenidaShow = false
      } else {
        // Verificar si el perfil del usuario tiene datos que indicarían que no es su primera visita
        if (
          (this.userProfile.englishLevel === null &&
            this.userProfile.idealCompany?.length === 0 &&
            this.userProfile.jobTypePreference?.length === 0 &&
            this.userProfile.industries?.length === 0 &&
            this.userProfile.jobStatus === null &&
            this.userProfile.minSalary === null &&
            this.userProfile.personsInCharge === null) ||
          this.$route.query.firstLogin === 'true' ||
          this.$route.query.firstLogin === true
        ) {
          // Mostrar el modal solo si es evidente que el usuario es nuevo y no ha configurado su perfil
          this.modalBienvenidaShow = true
          // Almacenar en localStorage que el modal ya se ha mostrado para no repetir en futuras sesiones
          localStorage.setItem('modalBienvenidaShown', 'true')
          console.log('modalBienvenidaShow', this.modalBienvenidaShow)
          this.updateStep(1)
        } else {
          // No mostrar el modal si el perfil del usuario ya está parcialmente o totalmente lleno
          this.modalBienvenidaShow = false
        }
      }
    },

    getAssesmentCategories: async function () {
      try {
        const habilitiesQuery = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale
            }
          },
          fetchPolicy: 'network-only',
          query: assesmentCategoriesQuery
        })
        this.assesmentCategories = habilitiesQuery.data.assesmentCategories
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    getUserProfile: async function () {
      try {
        const userProfileData = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale,
              authorization: `Bearer ${await this.token}`
            }
          },
          fetchPolicy: 'network-only',
          query: userProfileQuery
        })
        this.userProfile = userProfileData.data.userProfile
        this.showTutorialModal()
        this.setLoadingOff()
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    onModalClose() {
      this.modalBienvenidaShow = false
    },
    goToNextStep(nextStep) {
      this.updateStep(nextStep)
    }
  }
}
</script>
