<template>
  <div class="loading">
    <img
      src="@/assets/images/cody-spinner.svg"
      alt="Cody Loading..."
      class="loading--image"
      :style="[invert ? { transform: 'rotateY(180deg)' } : {}]"
    />
    <div class="loading--text">{{ message }}</div>
  </div>
</template>
<script>
export default {
  name: 'Loading',
  props: {
    invert: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: 'Loading...'
    }
  }
}
</script>
