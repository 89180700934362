<template>
  <CardPage>
    <template #default>
      <!-- Title -->
      <div class="card--page--header">
        <Title>
          <template #title>
            <h5 class="mt-2">
              {{ $t('box-notas.title') }}
            </h5>
          </template>
          <template #options v-if="!isPublic">
            <button
              @click="edit = true"
              v-if="edit === false"
              type="button"
              role="button"
              aria-label="Edit"
              class="w-9 h-9 flex justify-center items-center rounded-md btn-secondary transform -translate-y-2"
            >
              <img src="@/assets/images/icn-edit.svg" />
            </button>
          </template>
        </Title>
      </div>
      <!-- End Title -->
      <!-- Body -->
      <div class="card--page--body">
        <div v-if="edit === false">
          <div
            class="flex dark:text-primary-text text-secondary-text items-center mb-4"
          >
            <span
              class="w-3 h-3 rounded-full mr-2"
              :class="userStatus !== 'pre-vetted' ? 'bg-line' : 'bg-secondary'"
            >
            </span
            >{{ userStatus !== 'pre-vetted' ? 'Not Pre-Vetted' : 'Pre-Vetted' }}
          </div>
          <div class="dark:text-primary-text text-secondary-text">
            {{ notes }}
          </div>
        </div>
        <form @submit.prevent="onSubmit" v-else>
          <div class="form--group">
            <label class="form--label" for="notes">{{
              $t('box-notas.lbl-notas')
            }}</label>
            <textarea
              id="notes"
              name="notes"
              class="form--element form--number w-full"
              v-model="notes"
              rows="6"
            ></textarea>
          </div>
          <div class="form--group">
            <label class="form--label" for="userStatus">{{
              $t('box-notas.lbl-userStatus')
            }}</label>
            <select
              id="userStatus"
              name="userStatus"
              class="form--element form--select w-full"
              v-model="userStatus"
            >
              <option value="not-pre-vetted">Not Pre-Vetted</option>
              <option value="pre-vetted">Pre-Vetted</option>
            </select>
          </div>
          <div class="flex justify-center space-x-4">
            <button
              type="button"
              @click="edit = false"
              class="btn btn-md btn-outline btn-outline-primary btn-rounded-lg"
              role="button"
            >
              {{ $t('general.cancelar') }}
            </button>
            <button
              type="submit"
              @click="submitForm"
              class="btn btn-md btn-primary btn-rounded-lg"
              role="button"
            >
              {{ $t('general.guardar') }}
            </button>
          </div>
        </form>
      </div>
      <!-- End Body -->
    </template>
  </CardPage>
</template>

<script>
import CardPage from '@/components/CardPage'
import Title from '@/components/Title'
import { mapActions, mapGetters } from 'vuex'
import apolloClient from '@/graphql/apolloClient'
import SaveRecruitmentNotes from '@/graphql/mutations/saveRecruitmentInfo'
import { toastNotification } from '@/services/toastNotification'

export default {
  name: 'ExperienciaBox',
  components: {
    CardPage,
    Title
  },
  props: ['user', 'isPublic'],
  data() {
    return {
      notes: this.user.notes,
      userStatus: this.user.status,
      edit: true
    }
  },
  computed: {
    ...mapGetters(['token'])
  },
  mounted() {
    if ((this.notes !== null && this.userStatus !== null) || this.isPublic) {
      this.edit = false
    } else {
      this.edit = true
    }
  },
  methods: {
    ...mapActions(['setLoadingOn', 'setLoadingOff', 'fetchUser']),
    async submitForm() {
      try {
        this.setLoadingOn()
        const saveNotes = await apolloClient.mutate({
          mutation: SaveRecruitmentNotes,
          variables: {
            id: parseInt(this.user.userId),
            notes: this.notes,
            status: this.userStatus
          },
          context: {
            headers: {
              authorization: `Bearer ${await this.token}`
            }
          }
        })
        if (!saveNotes.errors) {
          await this.fetchUser()
          this.setLoadingOff()
          this.edit = false
          toastNotification(this.$t('general.info-guardada'), 'success')
        }
      } catch (e) {
        toastNotification(e, 'error')
      }
    }
  }
}
</script>
