<template>
  <section class="py-20 lg:py-32">
    <div class="content--wrapper">
      <h2
        class="title--h3 dark:text-white text-contrast font-bold text-center mb-12"
        v-html="$t('upskilling-b2b.what-will-learn-title')"
      ></h2>
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-10 text-center mb-4">
        <div v-for="(item, index) in 3" :key="index">
          <img
            :src="
              require(`@/assets/images/upskilling/nIcons/soft-skill-icon-${
                index + 1
              }.svg`)
            "
            class="w-32 h-32 mx-auto"
          />
          <h4 class="dark:text-white font-bold text-2xl">
            {{ $t(`upskilling-b2b.title-unit-section-${index + 1}`) }}
          </h4>
          <p class="dark:text-primary-text text-secondary-text text-lg">
            {{ $t(`upskilling-b2b.description-unit-section-${index + 1}`) }}
          </p>
        </div>
      </div>
      <div
        class="grid grid-cols-1 lg:grid-cols-2 lg:w-8/12 w-12/12 text-center mx-auto"
      >
        <div>
          <img
            :src="
              require(`@/assets/images/upskilling/nIcons/soft-skill-icon-4.svg`)
            "
            class="w-32 h-32 mx-auto"
          />
          <h4 class="dark:text-white font-bold text-2xl">
            {{ $t(`upskilling-b2b.title-unit-section-4`) }}
          </h4>
          <p class="dark:text-primary-text text-secondary-text text-lg">
            {{ $t(`upskilling-b2b.description-unit-section-4`) }}
          </p>
        </div>
        <div class="">
          <img
            :src="
              require(`@/assets/images/upskilling/nIcons/soft-skill-icon-5.svg`)
            "
            class="w-32 h-32 mx-auto"
          />
          <h4 class="dark:text-white font-bold text-2xl">
            {{ $t(`upskilling-b2b.title-unit-section-5`) }}
          </h4>
          <p class="dark:text-primary-text text-secondary-text text-lg">
            {{ $t(`upskilling-b2b.description-unit-section-5`) }}
          </p>
        </div>
      </div>
      <section class="content--wrapper relative py-10">
        <div class="flex items-baseline justify-center space-x-6">
          <button
            type="submit"
            class="btn btn-xl border-2 btn-outline-secondary btn-outline-secondary-glow btn-rounded-lg"
          >
            {{ $t('box-course-details.btn-download') }}
          </button>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WhatWillLearn'
}
</script>
