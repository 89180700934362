<template>
  <main class="main--wrapper py-24 lg:py-32" v-if="candidate">
    <div class="content--wrapper mb-4">
      <div
        class="w-full lg:w-8/12 flex flex-wrap lg:space-x-8 space-y-4 lg:flex-nowrap lg:space-y-0 lg:pr-6 items-center"
      >
        <div class="w-full lg:w-6/12 flex items-center flex-wrap md:space-x-4">
          <h5 class="dark:text-primary-text text-secondary-text font-xl">
            {{ $t('profile-page.title-complete-profile') }}
          </h5>
          <ProgressBar
            class="w-full md:w-auto flex-grow"
            :porcentaje="completionPercent"
          />
        </div>
        <div
          class="w-full lg:w-6/12 dark:text-primary-text text-secondary-text lg:text-right"
        >
          {{ $t('admin-candidato.lbl-actualizacion') }}:
          {{ this.candidate.updatedAt }}
        </div>
      </div>
    </div>
    <div
      class="content--wrapper flex flex-wrap lg:space-x-8 space-y-8 lg:flex-nowrap lg:space-y-0"
    >
      <div class="w-full lg:w-8/12">
        <!-- Card Page Sign Up -->
        <CardPage>
          <template #default>
            <!-- Body -->
            <div class="card--page--body pt-8">
              <!-- Section Basic Information  -->
              <BasicInformationSection
                :initial-data="candidate"
                :id="candidate.userId"
              />
              <!-- End Section Basic Information -->
              <!-- Section Social Media -->
              <SocialMediaSection
                :initial-data="candidate.socialNetworks"
                :id="candidate.userId"
              />
              <!-- End Section Social Media -->
              <!-- Section Un poco sobre Mi -->
              <!-- <AboutMeSection
                :userAbout="candidate.about"
                :id="candidate.userId"
              /> -->
              <!-- End Section Un poco sobre Mi -->
              <!-- Section Tecnologias -->
              <TecnologiasSection
                :userTechnologies="candidate.technologies"
                :id="candidate.userId"
              />
              <!-- End Section Tecnologias -->
              <!-- Section Experiencia Profesional -->
              <ExperienciaProfesionalSection
                :userPastJobs="candidate.pastJobs"
                :userPastProjects="candidate.pastProjects"
                :id="candidate.userId"
              />
              <!-- End Section Experiencia Profesional -->
              <!-- Section Educación Formal -->
              <EducacionFormalSection
                :userFormalEducation="candidate.formalEducation"
                :id="candidate.userId"
              />
              <!-- Section Educación Formal -->
              <!-- Section Certificaciones y Cursos -->
              <CertificacionesCursosSection
                :userCertsAndCourses="candidate.certsAndCourses"
                :id="candidate.userId"
              />
              <!-- Section Certificaciones y Cursos -->
              <!-- Section Soft Skill -->
              <SoftSkillSection :userSoftSkill="candidate.takenAssesments" />
              <!-- End Section Soft Skill -->
              <!-- Section Hard Skill -->
              <HardSkillSection :userHardSkill="candidate.takenAssesments" />
              <!-- End Section Hard Skill -->
            </div>
            <!-- End Body -->
          </template>
        </CardPage>
        <!-- End Card Page Sign Up -->
      </div>
      <!-- SIDEBAR -->
      <aside class="w-full lg:w-4/12 space-y-8" v-if="candidate">
        <!-- Notas -->
        <BoxNotas :user="candidate" />
        <!-- Estado de Empleo -->
        <BoxExperiencia :user="candidate" :id="candidate.userId" />
        <BoxEstadoDeEmpleo :user="candidate" :id="candidate.userId" />
        <BoxPreferenciasPersonales
          :industries="candidate.industries"
          :idealCompany="candidate.idealCompany"
          :id="candidate.userId"
        />
        <!-- End Estado de Empleo -->
      </aside>
      <!-- END SIDEBAR -->
    </div>
  </main>
</template>

<script>
import CardPage from '@/components/CardPage'
import BoxNotas from '@/components/profile/BoxNotas'
import BoxEstadoDeEmpleo from '@/components/profile/BoxEstadoDeEmpleo'
import BoxExperiencia from '@/components/profile/BoxExperiencia'
import BoxPreferenciasPersonales from '@/components/profile/BoxPreferenciasPersonales'
import ProgressBar from '@/components/ProgressBar'
import BasicInformationSection from '@/components/profile/BasicInformationSection'
import SocialMediaSection from '@/components/profile/SocialMediaSection'
import TecnologiasSection from '@/components/profile/TecnologiasSection'
import SoftSkillSection from '@/components/profile/SoftSkillSection'
import HardSkillSection from '@/components/profile/HardSkillSection'
import ExperienciaProfesionalSection from '@/components/profile/ExperienciaProfesionalSection'
import EducacionFormalSection from '@/components/profile/EducacionFormalSection'
import CertificacionesCursosSection from '@/components/profile/CertificacionesCursosSection'

import ApolloClient from '@/graphql/apolloClient'
import assesmentCategoriesQuery from '@/graphql/queries/assesmentCategories'
import GetCandidate from '@/graphql/queries/userDetails'
import { mapActions, mapGetters } from 'vuex'
import { toastNotification } from '@/services/toastNotification'

export default {
  name: 'AdminCandidate',
  data() {
    return {
      candidate: null,
      assesmentCategories: []
    }
  },
  components: {
    CardPage,
    BoxNotas,
    BoxEstadoDeEmpleo,
    BoxExperiencia,
    BoxPreferenciasPersonales,
    ProgressBar,
    BasicInformationSection,
    SocialMediaSection,
    TecnologiasSection,
    SoftSkillSection,
    HardSkillSection,
    ExperienciaProfesionalSection,
    EducacionFormalSection,
    CertificacionesCursosSection
  },
  mounted() {
    this.setLoadingOff()
    this.getCandidate()
    this.getAssesmentCategories()
  },
  computed: {
    ...mapGetters(['token']),
    completionPercent: function () {
      const userObject = this.candidate
      const value = Object.keys(this.candidate).reduce((acc, val) => {
        const realValue = userObject[val]
        if (realValue !== null && realValue !== '') {
          if (val === 'takenAssesments') {
            if (realValue.length === 4) {
              return acc + 1
            } else return acc
          }
          if (Array.isArray(realValue)) {
            return realValue.length > 0 ? acc + 1 : acc
          }
          return acc + 1
        } else {
          return acc
        }
      }, 0)
      return Math.round((value * 100) / Object.values(this.candidate).length)
    }
  },
  methods: {
    ...mapActions(['setLoadingOn', 'setLoadingOff']),
    getCandidate: async function () {
      this.setLoadingOn()
      try {
        const candidate = await ApolloClient.query({
          context: {
            headers: {
              authorization: `Bearer ${await this.token}`,
              lang: this.$root.$i18n.locale
            }
          },
          fetchPolicy: 'network-only',
          query: GetCandidate,
          variables: {
            id: parseInt(this.$route.params.id)
          }
        })
        this.candidate = candidate.data.candidate
        console.log('CANDIDATE', this.candidate)
        this.setLoadingOff()
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    getAssesmentCategories: async function () {
      try {
        const habilitiesQuery = await ApolloClient.query({
          context: {
            headers: {
              lang: 'es'
            }
          },
          fetchPolicy: 'network-only',
          query: assesmentCategoriesQuery
        })
        this.assesmentCategories = habilitiesQuery.data.assesmentCategories
        this.setLoadingOff()
      } catch (e) {
        toastNotification(e, 'error')
      }
    }
  }
}
</script>
