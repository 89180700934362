<template>
  <main class="main--wrapper main--wrapper--internal pt-32 pb-8 mb-0">
    <div class="content--wrapper">
      <Title class="mb-8">
        <template #title>
          <h3>
            {{ $t('admin-dashboard-eventos.title-eventos-actuales') }}
          </h3>
        </template>
        <template #options>
          <router-link
            :to="{ name: 'AdminEventCreate' }"
            class="btn btn-sm btn-rounded-md btn-secondary transform -translate-y-2"
          >
            {{ $t('general.crear-nuevo-evento') }}
          </router-link>
        </template>
      </Title>
      <!-- Filters 
      <section
        class="
          mt-4
          mb-12
          grid
          gap-x-6 gap-y-4
          lg:grid-cols-5
          md:grid-cols-3
          grid-cols-1
        "
      >
        <input
          class="form--filter element--sm"
          placeholder="Buscar por Correo"
          v-model="filter.email"
          id="email"
        />
        <input
          class="form--filter element--sm"
          id="nombre"
          v-model="filter.nombre"
          placeholder="Buscar por Nombre"
        />
        <select
          class="form--filter element--sm form--select"
          v-model.lazy="filter.especialidad"
        >
          <option value="" disabled selected>Buscar por Especialidad</option>
          <option
            :value="especialidad"
            v-for="especialidad in especialidadLista"
            :key="especialidad"
          >
            {{ especialidad }}
          </option>
        </select>
        <select
          class="form--filter element--sm form--select"
          v-model="filter.tecnologias"
        >
          <option value="" disabled selected>Buscar por Tecnologías</option>
        </select>
        <select
          class="form--filter element--sm form--select"
          v-model="filter.status"
        >
          <option value="" disabled selected>Buscar por Status</option>
        </select>
        <select
          class="form--filter element--sm form--select"
          v-model="filter.pais"
        >
          <option value="" disabled selected>Buscar por País</option>
          <option
            :value="pais"
            v-for="pais in paisesLista[$i18n.locale]"
            :key="pais"
          >
            {{ pais }}
          </option>
        </select>
        <select
          class="form--filter element--sm form--select"
          v-model="filter.experiencia"
        >
          <option value="" disabled selected>Buscar Años de Exp.</option>
        </select>
        <div class="form--filter lg:col-span-2 element--sm flex items-center">
          <span class="mr-2 whitespace-nowrap">Rango Salarial</span>
          <input
            v-model="filter.minSalarial"
            :max="filter.maxSalarial"
            class="-mr-1 w-1/2"
            type="range"
            step="100"
          />
          <input
            v-model="filter.maxSalarial"
            :min="filter.minSalarial"
            max="20000"
            class="mr-2 w-1/2"
            type="range"
            step="100"
          />
          <span class="text-xs whitespace-nowrap"
            >{{ filter.minSalarial }} USD a {{ filter.maxSalarial }} USD Brutos
            Mensuales</span
          >
        </div>
        <button type="submit" class="btn btn-rounded-lg btn-md btn-secondary">
          {{ $t('general.buscar') }}
        </button>
      </section>
      End Filters -->
      <!-- Table -->
      <CardPage class="pt-10 mb-12">
        <template #default>
          <!-- Body -->
          <div class="card--page--body">
            <div
              v-if="conferences.length > 0"
              class="overflow-x-auto mb-6 border dark:border-line-dark border-line-light whitespace-nowrap"
            >
              <table
                class="table--default table--hover table-fixed table--nowrap dark:text-primary-text text-secondary-text"
              >
                <thead>
                  <tr>
                    <th class="lg:w-1/12">ID</th>
                    <th class="lg:w-3/12">
                      {{ $t('admin-evento.lbl-nombre-evento') }}
                    </th>
                    <th>{{ $t('admin-evento.lbl-categoria') }}</th>
                    <th>{{ $t('admin-evento.lbl-lugar') }}</th>
                    <th>{{ $t('admin-evento.lbl-fecha-hora') }}</th>
                    <th>{{ $t('admin-evento.lbl-lenguaje') }}</th>
                    <th>{{ $t('admin-evento.lbl-detalles') }}</th>
                    <th>{{ $t('admin-evento.lbl-acciones') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="conference in conferences" :key="conference.id">
                    <td class="text-center">{{ conference.id }}</td>
                    <td class="text-left">{{ conference.name }}</td>
                    <td class="text-center">{{ conference.category }}</td>
                    <td class="text-center">{{ conference.place }}</td>
                    <td class="text-center">
                      {{ dateFormat(conference.date) }} |
                      {{ hourFormat(conference.date) }}
                    </td>
                    <td class="text-center">{{ conference.eventLang }}</td>
                    <td class="text-center">
                      <router-link
                        class="text-primary"
                        :to="{
                          name: 'AdminEventDetail',
                          params: {
                            id: conference.id,
                            title: conference.name
                          }
                        }"
                        >{{ $t('admin-evento.lbl-detalles') }}</router-link
                      >
                    </td>
                    <td>
                      <router-link
                        :to="{
                          name: 'AdminEventEdit',
                          params: {
                            id: conference.id,
                            title: conference.name
                          }
                        }"
                        class="w-9 h-9 flex justify-center items-center rounded-md btn-secondary mx-auto"
                      >
                        <img src="@/assets/images/icn-edit.svg" />
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="dark:text-primary-text text-secondary-text" v-else>
              {{ $t('admin-dashboard-eventos.sin-eventos') }}
              {{ $t('admin-dashboard-eventos.title-eventos-actuales') }}
            </div>
          </div>
          <!-- End Body -->
        </template>
      </CardPage>
      <!-- End Table -->
      <!-- Eventos Pasados -->
      <Title class="mb-8">
        <template #title>
          <h3>
            {{ $t('admin-dashboard-eventos.title-eventos-pasados') }}
          </h3>
        </template>
      </Title>
      <CardPage class="pt-10">
        <template #default>
          <!-- Body -->
          <div class="card--page--body">
            <div
              v-if="pastConferences.length > 0"
              class="overflow-x-auto mb-6 border dark:border-line-dark border-line-light whitespace-nowrap"
            >
              <table
                class="table--default table--hover table-fixed table--nowrap dark:text-primary-text text-secondary-text"
              >
                <thead>
                  <tr>
                    <th class="lg:w-1/12">ID</th>
                    <th class="lg:w-3/12">
                      {{ $t('admin-evento.lbl-nombre-evento') }}
                    </th>
                    <th>{{ $t('admin-evento.lbl-categoria') }}</th>
                    <th>{{ $t('admin-evento.lbl-lugar') }}</th>
                    <th>{{ $t('admin-evento.lbl-fecha-hora') }}</th>
                    <th>{{ $t('admin-evento.lbl-lenguaje') }}</th>
                    <th>{{ $t('admin-evento.lbl-detalles') }}</th>
                    <th>{{ $t('admin-evento.lbl-acciones') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="conference in pastConferences"
                    :key="conference.id"
                  >
                    <td class="text-center">{{ conference.id }}</td>
                    <td class="text-left">{{ conference.name }}</td>
                    <td class="text-center">{{ conference.category }}</td>
                    <td class="text-center">{{ conference.place }}</td>
                    <td class="text-center">
                      {{ dateFormat(conference.date) }} |
                      {{ hourFormat(conference.date) }}
                    </td>
                    <td class="text-center">{{ conference.eventLang }}</td>
                    <td class="text-center">
                      <router-link
                        class="text-primary"
                        :to="{
                          name: 'AdminEventDetail',
                          params: { id: conference.id }
                        }"
                        >{{ $t('admin-evento.lbl-detalles') }}</router-link
                      >
                    </td>
                    <td>
                      <router-link
                        :to="{
                          name: 'AdminEventEdit',
                          params: { id: conference.id }
                        }"
                        class="w-9 h-9 flex justify-center items-center rounded-md btn-secondary mx-auto"
                      >
                        <img src="@/assets/images/icn-edit.svg" />
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="dark:text-primary-text text-secondary-text" v-else>
              {{ $t('admin-dashboard-eventos.sin-eventos') }}
              {{ $t('admin-dashboard-eventos.title-eventos-pasados') }}
            </div>
          </div>
          <!-- End Body -->
        </template>
      </CardPage>
      <!-- End Eventos Pasados -->
    </div>
  </main>
</template>

<script>
import CardPage from '@/components/CardPage'
import Title from '@/components/Title'
import ApolloClient from '@/graphql/apolloClient'
import EventsQuery from '@/graphql/queries/events'
import { mapActions } from 'vuex'
import { toastNotification } from '@/services/toastNotification'
import { hourFormat, dateFormat } from '@/services/dateFormatting'

export default {
  name: 'AdminEvents',
  data: function () {
    return {
      conferences: [],
      pastConferences: []
    }
  },
  components: {
    CardPage,
    Title
  },
  mounted() {
    this.getEvents()
  },
  updated() {
    this.getEvents()
  },
  methods: {
    hourFormat,
    dateFormat,
    ...mapActions(['setLoadingOff', 'setLoadingOn']),
    getEvents: async function () {
      this.setLoadingOn()
      try {
        const eventsQuery = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale
            }
          },
          fetchPolicy: 'network-only',
          query: EventsQuery
        })
        this.pastConferences = eventsQuery.data.pastEvents.filter(
          (val) => val !== null
        )
        this.conferences = eventsQuery.data.events.filter((val) => val !== null)
        this.setLoadingOff()
      } catch (e) {
        toastNotification(e, 'error')
      }
    }
  }
}
</script>
