<template>
  <section class="mb-12">
    <!-- If not complete the section -->
    <div class="text-center" v-if="!isPublic && edit === false && formalEducation.length <= 0">
      <div class="flex justify-center space-x-6">
        <BadgeAward class="inline-block" imagen="icn-experiencia.svg" :bloqueado="true"
          :titulo="$t('habilidades-page.title-experiencia')"></BadgeAward>
      </div>
      <p class="text-secondary-text text-center">
        {{ $t('profile-page.description-educacion-formal-badge') }}
      </p>
      <div class="flex justify-center">
        <button type="button" class="btn btn-md btn-rounded-lg btn-primary mx-auto" @click="editSection()">
          {{ $t('general.completar') }}
        </button>
      </div>
    </div>
    <!-- If not complete and have to add info -->
    <Form @submit="validate" v-else-if="!isPublic && edit === true">
      <div class="flex mb-6 lg:mt-7">
        <div
          class="flex justify-between items-center bg-black uppercase border-2 border-primary rounded-lg w-full px-3 lg:px-6 py-3 lg:py-5">
          <Title class="">
            <template #title>
              <h5 class="mt-0 text-lg">
                {{ $t('profile-page.title-educacion-formal') }}
              </h5>
            </template>
          </Title>
          <!-- <button
            type="button"
            class="btn btn-outline btn-outline-primary btn-rounded-lg btn-md"
            role="button"
            aria-pressed="false"
            @click="cancelEdition"
          >
            {{ $t('general.cancelar') }}
          </button> -->
          <button type="submit" class="btn btn-primary btn-rounded-lg btn-md" role="button" aria-pressed="false">
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M18.8844 7.56222V17.7186C18.8844 18.1452 18.7416 18.5013 18.4559 18.787C18.1702 19.0726 17.8141 19.2155 17.3876 19.2155H5.55642C5.12989 19.2155 4.77379 19.0726 4.4881 18.787C4.20241 18.5013 4.05957 18.1452 4.05957 17.7186V5.88747C4.05957 5.46095 4.20241 5.10484 4.4881 4.81916C4.77379 4.53347 5.12989 4.39062 5.55642 4.39062H15.7128L18.8844 7.56222ZM17.9579 7.95786L15.3172 5.31718H5.55642C5.3901 5.31718 5.25343 5.37061 5.14642 5.47747C5.03956 5.58449 4.98612 5.72116 4.98612 5.88747V17.7186C4.98612 17.885 5.03956 18.0216 5.14642 18.1286C5.25343 18.2355 5.3901 18.2889 5.55642 18.2889H17.3876C17.5539 18.2889 17.6906 18.2355 17.7976 18.1286C17.9044 18.0216 17.9579 17.885 17.9579 17.7186V7.95786ZM11.472 16.0082C11.9828 16.0082 12.4194 15.8271 12.7817 15.4648C13.144 15.1025 13.3251 14.666 13.3251 14.1551C13.3251 13.6443 13.144 13.2077 12.7817 12.8454C12.4194 12.4831 11.9828 12.302 11.472 12.302C10.9612 12.302 10.5246 12.4831 10.1623 12.8454C9.80003 13.2077 9.61889 13.6443 9.61889 14.1551C9.61889 14.666 9.80003 15.1025 10.1623 15.4648C10.5246 15.8271 10.9612 16.0082 11.472 16.0082ZM6.62543 9.73615H13.5032V6.95648H6.62543V9.73615ZM4.98612 7.95786V18.2889V5.31718V7.95786Z"
                fill="black" />
            </svg>
            {{ $t('form.btn-guardar') }}
          </button>
        </div>
      </div>
      <p class="dark:text-white text-secondary-text">
        {{ $t('modal-educacion-formal.description') }}
      </p>
      <div v-for="(education, index) in formalEducation" :key="index">
        <!-- First Row -->
        <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4 items-end">
          <div class="form--group">
            <label class="form--label" :for="`grade-${index}`">{{
              $t('modal-educacion-formal.lbl-grado')
              }}</label>
            <Field :name="`grade-${index}`" type="text" class="form--element w-full border-2 border-primary"
              :rules="validateDefault" v-model="education.grade" />
            <ErrorMessage :name="`grade-${index}`" class="form--error" />
          </div>
          <div class="form--group">
            <label class="form--label" :for="`school-${index}`">{{
              $t('modal-educacion-formal.lbl-entidad-educativa')
              }}</label>
            <Field :name="`school-${index}`" type="text" class="form--element w-full border-2 border-primary"
              :rules="validateDefault" v-model="education.school" />
            <ErrorMessage :name="`school-${index}`" class="form--error" />
          </div>
        </div>
        <!-- End First Row -->
        <!-- Second Row -->
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-x-6">
          <div class="form--group" :class="!education.expires ? '' : 'col-span-2'">
            <h4 class="dark:text-white text-secondary-text mb-2 lg:col-span-2">
              {{ $t('modal-experiencia-profesional.title-fecha-de-inicio') }}
            </h4>
            <Field :name="`startDate-${index}`" as="label" v-slot="{ field }" v-model="education.startDate"
              class="form--icon form--icon--left block rounded-lg border-2 border-primary">
              <span class="icon--wrapper text-white rounded-l-lg opacity-70">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="w-4 h-4 fill-current">
                  <path
                    d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z">
                  </path>
                </svg>
              </span>
              <input class="form--element w-full" v-bind="field" type="date" onfocus="this.showPicker()"
                :max="new Date().toISOString().split('T')[0]" />
            </Field>
            <ErrorMessage :name="`startDate-${index}`" class="form--error" />
          </div>
          <div class="form--group" v-if="!education.expires">
            <h4 class="dark:text-white text-secondary-text mb-2 lg:col-span-2">
              {{ $t('modal-experiencia-profesional.title-fecha-de-fin') }}
            </h4>
            <Field :name="`endDate-${index}`" as="label" v-slot="{ field }" v-model="education.endDate"
              class="form--icon form--icon--left block rounded-lg border-2 border-primary">
              <span class="icon--wrapper text-white rounded-l-lg opacity-70">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="w-4 h-4 fill-current">
                  <path
                    d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z">
                  </path>
                </svg>
              </span>
              <input class="form--element w-full" v-bind="field" type="date" onfocus="this.showPicker()" :min="
                  education.startDate || new Date().toISOString().split('T')[0]
                " />
            </Field>
            <ErrorMessage :name="`endDate-${index}`" class="form--error" />
          </div>
          <div class="form--group self-center lg:pt-5">
            <label class="form--checkbox"><input type="checkbox" :id="`remember-${index}`" value="first_checkbox"
                @change="checkExpires(index)" v-model="education.expires" /><span>{{
                $t('modal-educacion-formal.lbl-sigo-estudiando')
                }}</span></label>
          </div>
        </div>
        <!-- End Second Row -->
        <div class="flex justify-end mb-6">
          <button class="w-9 h-9 flex justify-center items-center rounded-md btn-primary" @click="removeRow(index)">
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.15666 23.1474C8.64431 23.1474 8.2079 22.9672 7.84744 22.607C7.48717 22.2465 7.30703 21.8101 7.30703 21.2978V7.11843H6.16211V5.97351H10.7418V5.09277H17.6114V5.97351H22.1911V7.11843H21.0461V21.2978C21.0461 21.8248 20.8696 22.2648 20.5166 22.6178C20.1636 22.9709 19.7236 23.1474 19.1965 23.1474H9.15666ZM19.9012 7.11843H8.45196V21.2978C8.45196 21.5033 8.51798 21.6721 8.65003 21.8044C8.78227 21.9364 8.95115 22.0025 9.15666 22.0025H19.1965C19.3728 22.0025 19.5344 21.9291 19.6811 21.7823C19.8278 21.6356 19.9012 21.4741 19.9012 21.2978V7.11843ZM11.6666 19.7126H12.8115V9.40828H11.6666V19.7126ZM15.5416 19.7126H16.6865V9.40828H15.5416V19.7126Z"
                fill="#1E1D1D" />
            </svg>
          </button>
        </div>
      </div>
      <button class="btn btn-rounded-lg btn-primary btn-md mb-8 relative" type="button" role="button" :class="{
          'lg:-mt-12': formalEducation.length != 0
        }" @click="addRow" :aria-label="$t('modal-educacion-formal.btn-agregar-otra-educación')">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.57122 13.9112H10.3563V10.7709H13.4967V9.98578H10.3563V6.84541H9.57122V9.98578H6.43086V10.7709H9.57122V13.9112ZM9.96632 17.4441C8.98928 17.4441 8.07065 17.2587 7.21046 16.8879C6.35039 16.5171 5.6022 16.0138 4.96588 15.3782C4.32956 14.7425 3.82586 13.995 3.45478 13.1356C3.08356 12.2763 2.89795 11.358 2.89795 10.3809C2.89795 9.40383 3.08336 8.4852 3.45419 7.62501C3.82501 6.76494 4.32825 6.01675 4.96392 5.38043C5.59958 4.74411 6.34712 4.24041 7.20653 3.86933C8.06581 3.49811 8.98404 3.3125 9.96122 3.3125C10.9383 3.3125 11.8569 3.49791 12.7171 3.86874C13.5772 4.23956 14.3253 4.74281 14.9617 5.37847C15.598 6.01413 16.1017 6.76167 16.4728 7.62108C16.844 8.48036 17.0296 9.39859 17.0296 10.3758C17.0296 11.3528 16.8442 12.2714 16.4734 13.1316C16.1025 13.9917 15.5993 14.7399 14.9636 15.3762C14.328 16.0125 13.5804 16.5162 12.721 16.8873C11.8617 17.2585 10.9435 17.4441 9.96632 17.4441ZM9.96377 16.6591C11.7171 16.6591 13.2023 16.0506 14.4192 14.8337C15.6361 13.6168 16.2445 12.1317 16.2445 10.3783C16.2445 8.62495 15.6361 7.13982 14.4192 5.92293C13.2023 4.70604 11.7171 4.09759 9.96377 4.09759C8.2104 4.09759 6.72527 4.70604 5.50838 5.92293C4.29149 7.13982 3.68304 8.62495 3.68304 10.3783C3.68304 12.1317 4.29149 13.6168 5.50838 14.8337C6.72527 16.0506 8.2104 16.6591 9.96377 16.6591Z"
            fill="#1E1D1D" />
        </svg>
        <span class="ml-1">
          {{ $t('modal-educacion-formal.btn-agregar-otra-educación') }}
        </span>
      </button>
    </Form>
    <!-- Else if the section is complete -->
    <div v-else>
      <ul class="list--education list--education--small">
        <li v-for="(education, index) in formalEducation" :key="index" class="pb-8">
          <span class="list--education--bullets"></span>
          <time class="dark:text-primary-text text-secondary-text text-sm mr-2">{{ formatedDate(education.startDate) }}
            |
            <span v-if="education.endDate">{{
              formatedDate(education.endDate)
              }}</span>
            <span v-else>
              {{ $t('general.presente') }}
            </span>
          </time>
          <h4 class="text-white font-bold text-lg mb-2 uppercase">
            {{ education.school }} |
            <i class="text-secondary">{{ education.grade }}</i>
          </h4>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import BadgeAward from '@/components/BadgeAward'
import Title from '@/components/Title'
import ROUTES from '@/constants/routes'
import { dateFormat } from '@/services/dateFormatting'
import { Field, Form, ErrorMessage } from 'vee-validate'
import apolloClient from '@/graphql/apolloClient'
import saveFormalEducationMutation from '@/graphql/mutations/saveFormalEducation'
import { mapActions, mapGetters } from 'vuex'
import { validateDefault, validateNumber } from '@/services/validationRules'
import { toastNotification } from '@/services/toastNotification'

const emptyEducation = {
  grade: null,
  startDate: null,
  endDate: null,
  school: null,
  expires: false
}

export default {
  name: 'EducaciónFormalSection',
  props: {
    userFormalEducation: Array,
    isPublic: Boolean,
    id: { type: Number, required: false }
  },
  components: {
    Title,
    BadgeAward,
    Field,
    Form,
    ErrorMessage
  },
  data() {
    return {
      ROUTES,
      formalEducation: [],
      edit: true
    }
  },
  mounted() {
    this.initializeData()
  },
  computed: {
    ...mapGetters(['token']),
    sortFormalEducation() {
      return [...this.formalEducation].sort(
        (a, b) => new Date(b.startDate) - new Date(a.startDate)
      )
    }
  },
  methods: {
    confDate: function (e) {
      return dateFormat(e)
    },
    formatedDate(date) {
      if (date) {
        const month = `${new Date(date).getMonth() + 1}`.padStart(2, '0')
        const year = new Date(date).getFullYear()
        return `${month}-${year}`
      } else {
        return 'Actual'
      }
    },
    ...mapActions([
      'fetchUser',
      'fetchProfile',
      'setLoadingOn',
      'setLoadingOff'
    ]),
    async validate() {
      try {
        this.setLoadingOn()
        const saveFormalEducation = await apolloClient.mutate({
          mutation: saveFormalEducationMutation,
          variables: {
            formalEducation: this.sortFormalEducation,
            id: this.id
          },
          context: {
            headers: {
              authorization: `Bearer ${await this.token}`
            }
          }
        })
        if (!saveFormalEducation.errors) {
          await this.fetchUser()
          await this.fetchProfile()
          if (this.formalEducation.length <= 0) {
            this.formalEducation = []
            this.formalEducation.push({
              ...emptyEducation
            })
          }
          this.setLoadingOff()
          toastNotification(this.$t('general.info-guardada'), 'success')
        }
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    addRow() {
      this.formalEducation.push({
        ...emptyEducation
      })
    },
    removeRow(index) {
      this.formalEducation.splice(index, 1)
    },
    editSection() {
      this.edit = true
      if (this.formalEducation.length <= 0) {
        this.formalEducation.push({
          ...emptyEducation
        })
      }
    },
    cancelEdition() {
      this.formalEducation = []
      if (this.userFormalEducation.length > 0) {
        this.initializeData()
      }
      this.edit = false
    },
    initializeData() {
      if (this.userFormalEducation) {
        this.userFormalEducation.forEach((val) => {
          this.formalEducation.unshift({
            grade: val.grade,
            startDate: val.startDate,
            endDate: val.endDate,
            school: val.school
          })
        })
        if (this.formalEducation.length <= 0) {
          this.formalEducation.push({
            ...emptyEducation
          })
        }
      }
    },
    // RULES
    validateDefault,
    validateNumber,
    checkExpires(index) {
      if (this.formalEducation[index].expires === true) {
        this.formalEducation[index].endDate = null
      }
    }
  }
}
</script>
