<template>
  <div
    class="border rounded-lg dark:border-line-dark border-line-light dark:text-white p-4"
  >
    <div class="flex lg:justify-between flex-wrap items-center mb-8">
      <div class="w-full lg:w-8/12">
        <h2 class="flex text-contrast dark:text-white font-bold lg:text-xl">
          {{ name }}
        </h2>
        <p class="text-secondary dark:text-primary last">{{ role }}</p>
      </div>
      <img
        src="@/assets/images/icn-example-founder.png"
        class="w-12 h-12 rounded-full"
        alt="Utopicode"
      />
    </div>
    <p class="dark:text-primary-text text-secondary-text text-sm last">
      {{ description }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'CardTeamMember',
  props: {
    name: String,
    role: String,
    description: String
  },
  data() {
    return {}
  }
}
</script>
