import gql from 'graphql-tag'

const saveJobStatus = gql`
  mutation saveJobStatus(
    $jobStatus: String
    $idealJobType: String
    $jobTypePreference: [String]
    $availability: String
    $minSalary: Int
    $openToNegotiate: Boolean
    $id: Int
  ) {
    updateJobStatus(
      jobStatus: $jobStatus
      idealJobType: $idealJobType
      jobTypePreference: $jobTypePreference
      availability: $availability
      minSalary: $minSalary
      openToNegotiate: $openToNegotiate
      id: $id
    ) {
      id
      jobStatus
      idealJobType
      jobTypePreference
      availability
      minSalary
      openToNegotiate
    }
  }
`

export default saveJobStatus
