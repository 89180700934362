<template>
  <main class="main--wrapper pt-32 main--wrapper--internal">
    <div class="content--wrapper">
      <CardPage class="pt-10">
        <template #default>
          <Form @submit="validate">
            <!-- Body -->
            <div class="card--page--body">
              <Title class="mb-8">
                <template #title>
                  <h3>
                    {{ $t('general.crear-evento') }}
                  </h3>
                </template>
              </Title>
              <!-- Form Elemens Group -->
              <div class="grid grid-cols-1 lg:grid-cols-3 lg:gap-x-4">
                <div class="form--group">
                  <label class="form--label" for="nameEs">
                    {{ $t('admin-evento-create.lbl-nombre-evento-es') }}
                  </label>
                  <Field
                    name="nameEs"
                    type="text"
                    class="form--element w-full"
                    :rules="validateDefault"
                    v-model="event.nameEs"
                  />
                  <ErrorMessage name="nameEs" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="nameEn">
                    {{ $t('admin-evento-create.lbl-nombre-evento-en') }}
                  </label>
                  <Field
                    name="nameEn"
                    type="text"
                    class="form--element w-full"
                    :rules="validateDefault"
                    v-model="event.nameEn"
                  />
                  <ErrorMessage name="nameEn" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="photo">
                    {{ $t('admin-evento-create.lbl-url-foto') }}
                  </label>
                  <Field
                    name="photo"
                    type="text"
                    class="form--element w-full"
                    :rules="validateUrlComplete"
                    v-model="event.photo"
                  />
                  <ErrorMessage name="photo" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="date">
                    {{ $t('admin-evento-create.lbl-fecha-hora') }}
                  </label>
                  <Field
                    name="date"
                    as="label"
                    v-slot="{ field }"
                    v-model="event.date"
                    :rules="validateDefault"
                    class="form--icon form--icon--left block"
                  >
                    <span
                      class="icon--wrapper bg-secondary text-white rounded-l-lg"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        class="w-4 h-4 fill-current"
                      >
                        <path
                          d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                        ></path>
                      </svg>
                    </span>
                    <input
                      class="form--element w-full"
                      type="datetime-local"
                      v-bind="field"
                    />
                  </Field>
                  <ErrorMessage name="date" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="speaker">
                    {{ $t('admin-evento-create.lbl-nombre-speaker') }}
                  </label>
                  <Field
                    name="speaker"
                    type="text"
                    class="form--element w-full"
                    :rules="validateDefault"
                    v-model="event.speaker"
                  />
                  <ErrorMessage name="speaker" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="speakerJobEs">
                    {{ $t('admin-evento-create.lbl-trabajo-speaker-es') }}
                  </label>
                  <Field
                    name="speakerJobEs"
                    type="text"
                    class="form--element w-full"
                    v-model="event.speakerJobEs"
                  />
                  <ErrorMessage name="speakerJobEs" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="speakerJobEn">
                    {{ $t('admin-evento-create.lbl-trabajo-speaker-en') }}
                  </label>
                  <Field
                    name="speakerJobEn"
                    type="text"
                    class="form--element w-full"
                    v-model="event.speakerJobEn"
                  />
                  <ErrorMessage name="speakerJobEn" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="speakerPhoto">
                    {{ $t('admin-evento-create.lbl-foto-speaker') }}
                  </label>
                  <Field
                    name="speakerPhoto"
                    type="text"
                    class="form--element w-full"
                    :rules="validateUrl"
                    v-model="event.speakerPhoto"
                  />
                  <ErrorMessage name="speakerPhoto" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="positionSpeaker">
                    Posición del Speaker
                  </label>
                  <Field
                    name="positionSpeaker"
                    type="text"
                    class="form--element w-full"
                    v-model="event.positionSpeaker"
                  />
                  <ErrorMessage name="positionSpeaker" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="videoLink">
                    Link a video de Youtube
                  </label>
                  <Field
                    name="videoLink"
                    type="text"
                    class="form--element w-full"
                    :rules="validateUrl"
                    v-model="event.videoLink"
                  />
                  <ErrorMessage name="videoLink" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="calendarLink">
                    {{ $t('admin-evento-create.lbl-link-calendario') }}
                  </label>
                  <Field
                    name="calendarLink"
                    type="text"
                    class="form--element w-full"
                    :rules="validateUrl"
                    v-model="event.calendarLink"
                  />
                  <ErrorMessage name="calendarLink" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="liveLink">
                    {{ $t('admin-evento-create.lbl-link-evento-vivo') }}
                  </label>
                  <Field
                    name="liveLink"
                    type="text"
                    class="form--element w-full"
                    :rules="validateUrl"
                    v-model="event.liveLink"
                  />
                  <ErrorMessage name="liveLink" class="form--error" />
                </div>
              </div>
              <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-4">
                <div class="form--group">
                  <label class="form--label" for="subCategories">{{
                    $t('admin-evento-create.lbl-subcategorias')
                  }}</label>
                  <Field
                    name="subCategories"
                    as="div"
                    :rules="validateDefault"
                    v-slot="{ field }"
                  >
                    <Multiselect
                      mode="tags"
                      v-bind="field"
                      v-model="event.subCategories"
                      searchable
                      :createTag="true"
                      :noOptionsText="$t('contrata-devs-page.lista-vacia')"
                      :noResultsText="$t('contrata-devs-page.sin-resultados')"
                    />
                  </Field>
                  <ErrorMessage name="subCategories" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="category">
                    {{ $t('admin-evento-create.lbl-categoria') }}
                  </label>
                  <Field
                    name="category"
                    type="text"
                    class="form--element w-full"
                    :rules="validateDefault"
                    v-model="event.category"
                  />
                  <ErrorMessage name="category" class="form--error" />
                </div>
              </div>
              <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-4">
                <div class="form--group">
                  <label class="form--label" for="speakerAboutEs">
                    {{ $t('admin-evento-create.lbl-bio-es') }}
                  </label>
                  <Field
                    name="speakerAboutEs"
                    type="text"
                    class="form--element w-full"
                    v-model="event.speakerAboutEs"
                    v-slot="{ field }"
                  >
                    <textarea
                      v-bind="field"
                      class="form--element w-full"
                      rows="4"
                    >
                    </textarea>
                    <div
                      v-if="event.speakerAboutEs"
                      class="text-right font-bold text-secondary-text"
                    >
                      {{ event.speakerAboutEs.length }}
                    </div>
                  </Field>
                  <ErrorMessage name="speakerAboutEs" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="speakerAboutEn">
                    {{ $t('admin-evento-create.lbl-bio-en') }}
                  </label>
                  <Field
                    name="speakerAboutEn"
                    type="text"
                    class="form--element w-full"
                    v-model="event.speakerAboutEn"
                    v-slot="{ field }"
                  >
                    <textarea
                      v-bind="field"
                      class="form--element w-full"
                      rows="4"
                    >
                    </textarea>
                    <div
                      v-if="event.speakerAboutEn"
                      class="text-right font-bold text-secondary-text"
                    >
                      {{ event.speakerAboutEn.length }}
                    </div>
                  </Field>
                  <ErrorMessage name="speakerAboutEn" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="descriptionEs">
                    {{ $t('admin-evento-create.lbl-descripcion-es') }}
                  </label>
                  <Field
                    name="descriptionEs"
                    type="text"
                    class="form--element w-full"
                    :rules="validateDefault"
                    v-model="event.descriptionEs"
                    v-slot="{ field }"
                  >
                    <textarea
                      v-bind="field"
                      class="form--element w-full"
                      rows="4"
                    >
                    </textarea>
                    <div
                      v-if="event.descriptionEs"
                      class="text-right font-bold text-secondary-text"
                    >
                      {{ event.descriptionEs.length }}
                    </div>
                  </Field>
                  <ErrorMessage name="descriptionEs" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="descriptionEn">
                    {{ $t('admin-evento-create.lbl-descripcion-en') }}
                  </label>
                  <Field
                    name="descriptionEn"
                    type="text"
                    class="form--element w-full"
                    :rules="validateDefault"
                    v-model="event.descriptionEn"
                    v-slot="{ field }"
                  >
                    <textarea
                      v-bind="field"
                      class="form--element w-full"
                      rows="4"
                    >
                    </textarea>
                    <div
                      v-if="event.descriptionEn"
                      class="text-right font-bold text-secondary-text"
                    >
                      {{ event.descriptionEn.length }}
                    </div>
                  </Field>
                  <ErrorMessage name="descriptionEn" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="whatWillLearnEs">
                    {{ $t('admin-evento-create.lbl-whatWillLearn-es') }}
                  </label>
                  <Field
                    name="whatWillLearnEs"
                    type="text"
                    class="form--element w-full"
                    :rules="validateDefault"
                    v-model="event.whatWillLearnEs"
                    v-slot="{ field }"
                  >
                    <textarea
                      v-bind="field"
                      class="form--element w-full"
                      rows="4"
                    >
                    </textarea>
                    <div
                      v-if="event.whatWillLearnEs"
                      class="text-right font-bold text-secondary-text"
                    >
                      {{ event.whatWillLearnEs.length }}
                    </div>
                  </Field>
                  <ErrorMessage name="whatWillLearnEs" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="whatWillLearnEn">
                    {{ $t('admin-evento-create.lbl-whatWillLearn-en') }}
                  </label>
                  <Field
                    name="whatWillLearnEn"
                    type="text"
                    class="form--element w-full"
                    :rules="validateDefault"
                    v-model="event.whatWillLearnEn"
                    v-slot="{ field }"
                  >
                    <textarea
                      v-bind="field"
                      class="form--element w-full"
                      rows="4"
                    >
                    </textarea>
                    <div
                      v-if="event.whatWillLearnEn"
                      class="text-right font-bold text-secondary-text"
                    >
                      {{ event.whatWillLearnEn.length }}
                    </div>
                  </Field>
                  <ErrorMessage name="whatWillLearnEn" class="form--error" />
                </div>
              </div>
              <!-- End Form Elemens Group -->
            </div>
            <div class="card--page--footer flex justify-center space-x-4">
              <button
                type="button"
                class="btn btn-outline btn-outline-primary btn-rounded-lg btn-lg"
                role="button"
                aria-pressed="false"
                @click="$router.back()"
              >
                {{ $t('general.cancelar') }}
              </button>
              <button
                type="submit"
                class="btn btn-primary btn-rounded-lg btn-lg"
                role="button"
                aria-pressed="false"
              >
                {{ $t('general.guardar') }}
              </button>
            </div>
          </Form>
        </template>
      </CardPage>
    </div>
  </main>
</template>

<script>
import CardPage from '@/components/CardPage'
import Title from '@/components/Title'
import { Field, Form, ErrorMessage } from 'vee-validate'
import apolloClient from '@/graphql/apolloClient'
import createEventMutation from '@/graphql/mutations/createEvent'
import { toastNotification } from '@/services/toastNotification'
import { mapActions } from 'vuex'
import {
  validateDefault,
  validateEmail,
  validateUrlComplete,
  validateUrl
} from '@/services/validationRules'
import Multiselect from '@vueform/multiselect'

export default {
  name: 'AdminEditCreate',
  data() {
    return {
      event: {
        nameEs: null,
        nameEn: null,
        photo: null,
        date: null,
        speaker: null,
        speakerJobEs: null,
        speakerJobEn: null,
        speakerPhoto: null,
        positionSpeaker: null,
        speakerAboutEs: null,
        speakerAboutEn: null,
        videoLink: null,
        calendarLink: null,
        liveLink: null,
        descriptionEs: null,
        descriptionEn: null,
        whatWillLearnEs: null,
        whatWillLearnEn: null,
        category: null,
        subCategories: null
      }
    }
  },
  components: {
    CardPage,
    Title,
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  methods: {
    ...mapActions(['setLoadingOn', 'setLoadingOff']),
    async validate(values) {
      try {
        this.setLoadingOn()
        const createEvent = await apolloClient.mutate({
          mutation: createEventMutation,
          variables: {
            ...values,
            subCategories: values.subCategories.join(';')
          },
          context: {
            headers: {
              authorization: `Bearer ${await this.token}`
            }
          }
        })
        if (!createEvent.errors) {
          this.setLoadingOff()
          toastNotification(this.$t('general.info-guardada'), 'success')
          this.$router.push({ name: 'AdminEvents' })
        }
      } catch (e) {
        toastNotification(e, 'error')
      }
    },

    // Rules
    validateDefault,
    validateEmail,
    validateUrlComplete,
    validateUrl
  }
}
</script>
