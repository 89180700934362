<template>
  <div>
    <div class="text-center" v-if="!isPublic && edit === false">
      <div class="flex justify-center space-x-6">
        <BadgeAward class="inline-block" imagen="icn-experiencia.svg" :bloqueado="true"
          :titulo="$t('habilidades-page.title-experiencia')"></BadgeAward>
      </div>
      <p class="text-secondary-text text-center">
        {{ $t('profile-page.description-educacion-formal-badge') }}
      </p>
      <div class="flex justify-center">
        <button type="button" class="btn btn-md btn-rounded-lg btn-primary mx-auto" @click="editSection()">
          {{ $t('general.completar') }}
        </button>
      </div>
    </div>
    <Form @submit="() => null" v-else-if="!isPublic && edit === true">
      <div class="flex mb-6 lg:mt-7">
        <div
          class="flex justify-between items-center bg-black uppercase border-2 border-primary rounded-lg w-full px-3 lg:px-6 py-3 lg:py-5">
          <Title class="">
            <template #title>
              <h5 class="mt-0 text-lg">
                {{ $t('profile.high_preferences') }}
              </h5>
            </template>
          </Title>
          <!-- <button
            type="button"
            class="btn btn-outline btn-outline-primary btn-rounded-lg btn-md"
            role="button"
            aria-pressed="false"
            @click="cancelEdition"
          >
            {{ $t('general.cancelar') }}
          </button> -->
          <button type="submit" class="btn btn-primary btn-rounded-lg btn-md" role="button" aria-pressed="false"
            @click="submitForm">
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M18.8844 7.56222V17.7186C18.8844 18.1452 18.7416 18.5013 18.4559 18.787C18.1702 19.0726 17.8141 19.2155 17.3876 19.2155H5.55642C5.12989 19.2155 4.77379 19.0726 4.4881 18.787C4.20241 18.5013 4.05957 18.1452 4.05957 17.7186V5.88747C4.05957 5.46095 4.20241 5.10484 4.4881 4.81916C4.77379 4.53347 5.12989 4.39062 5.55642 4.39062H15.7128L18.8844 7.56222ZM17.9579 7.95786L15.3172 5.31718H5.55642C5.3901 5.31718 5.25343 5.37061 5.14642 5.47747C5.03956 5.58449 4.98612 5.72116 4.98612 5.88747V17.7186C4.98612 17.885 5.03956 18.0216 5.14642 18.1286C5.25343 18.2355 5.3901 18.2889 5.55642 18.2889H17.3876C17.5539 18.2889 17.6906 18.2355 17.7976 18.1286C17.9044 18.0216 17.9579 17.885 17.9579 17.7186V7.95786ZM11.472 16.0082C11.9828 16.0082 12.4194 15.8271 12.7817 15.4648C13.144 15.1025 13.3251 14.666 13.3251 14.1551C13.3251 13.6443 13.144 13.2077 12.7817 12.8454C12.4194 12.4831 11.9828 12.302 11.472 12.302C10.9612 12.302 10.5246 12.4831 10.1623 12.8454C9.80003 13.2077 9.61889 13.6443 9.61889 14.1551C9.61889 14.666 9.80003 15.1025 10.1623 15.4648C10.5246 15.8271 10.9612 16.0082 11.472 16.0082ZM6.62543 9.73615H13.5032V6.95648H6.62543V9.73615ZM4.98612 7.95786V18.2889V5.31718V7.95786Z"
                fill="black" />
            </svg>
            {{ $t('form.btn-guardar') }}
          </button>
        </div>
      </div>
      <div class="section-title flex mb-5 justify-between py-3 items-center gap-4">
        <h3 class="w-[280px] block text-[#B9A3FF] text-2xl">
          {{ $t('profile.people_in_charge') }}
        </h3>
        <div class="w-full border-b-2 mt-1 border-primary"></div>
      </div>
      <p class="dark:text-white text-secondary-text">
        {{ $t('profile.select_one_option') }}
      </p>

      <div class="flex flex-wrap gap-4 mb-4">
        <span>
          <input type="radio" class="peer hidden" id="managed-0to5" name="managed-0to5" value="5"
            v-model="managedPeople" />
          <label for="managed-0to5"
            class="bg-[#534A6F] peer-checked:text-black peer-checked:bg-primary text-white inline-block px-10 py-4 rounded-full cursor-pointer transition-colors duration-300">
            0 - 5
          </label>
        </span>
        <span>
          <input type="radio" class="peer hidden" id="managed-10to15" name="managed-10to15" value="10"
            v-model="managedPeople" />
          <label for="managed-10to15"
            class="bg-[#534A6F] peer-checked:text-black peer-checked:bg-primary text-white inline-block px-10 py-4 rounded-full cursor-pointer transition-colors duration-300">
            10 - 15
          </label>
        </span>
        <span>
          <input type="radio" class="peer hidden" id="managed-15to50" name="managed-15to50" value="50"
            v-model="managedPeople" />
          <label for="managed-15to50"
            class="bg-[#534A6F] peer-checked:text-black peer-checked:bg-primary text-white inline-block px-10 py-4 rounded-full cursor-pointer transition-colors duration-300">
            15 - 50
          </label>
        </span>
        <span>
          <input type="radio" class="peer hidden" id="managed-50to100" name="managed-50to100" value="100"
            v-model="managedPeople" />
          <label for="managed-50to100"
            class="bg-[#534A6F] peer-checked:text-black peer-checked:bg-primary text-white inline-block px-10 py-4 rounded-full cursor-pointer transition-colors duration-300">
            50 - 100
          </label>
        </span>
        <span>
          <input type="radio" class="peer hidden" id="managed-more100" name="managed-more100" value="101"
            v-model="managedPeople" />
          <label for="managed-more100"
            class="bg-[#534A6F] peer-checked:text-black peer-checked:bg-primary text-white inline-block px-10 py-4 rounded-full cursor-pointer transition-colors duration-300">
            {{ $t('profile.more') }}
          </label>
        </span>
      </div>

      <div class="section-title flex mb-5 justify-between py-3 items-center gap-4">
        <h3 class="w-[350px] block text-[#B9A3FF] text-2xl">
          {{ $t('profile.companies_looking') }}
        </h3>
        <div class="w-full border-b-2 mt-1 border-primary"></div>
      </div>
      <p class="dark:text-white text-secondary-text">
        {{ $t('profile.select_multiple_options') }}
      </p>

      <div class="flex flex-wrap gap-4 mb-4">
        <span>
          <input type="checkbox" class="peer hidden" id="contract-new-startup" name="contract-new-startup"
            value="Startup nueva" v-model="contractType" />
          <label for="contract-new-startup"
            class="bg-[#534A6F] peer-checked:text-black peer-checked:bg-primary text-white inline-block px-8 py-4 rounded-full cursor-pointer transition-colors duration-300">
            {{ $t('profile.new_startup') }}
          </label>
        </span>
        <span>
          <input type="checkbox" class="peer hidden" id="contract-grow-startup" name="contract-grow-startup"
            value="Startup en crecimiento" v-model="contractType" />
          <label for="contract-grow-startup"
            class="bg-[#534A6F] peer-checked:text-black peer-checked:bg-primary text-white inline-block px-8 py-4 rounded-full cursor-pointer transition-colors duration-300">
            {{ $t('profile.growing_startup') }}
          </label>
        </span>
        <span>
          <input type="checkbox" class="peer hidden" id="contract-software-factory" name="contract-software-factory"
            value="Fabrica de software" v-model="contractType" />
          <label for="contract-software-factory"
            class="bg-[#534A6F] peer-checked:text-black peer-checked:bg-primary text-white inline-block px-8 py-4 rounded-full cursor-pointer transition-colors duration-300">
            {{ $t('profile.software_factory') }}
          </label>
        </span>
        <span>
          <input type="checkbox" class="peer hidden" id="contract-staffing-company" name="contract-staffing-company"
            value="Compañia de staffing" v-model="contractType" />
          <label for="contract-staffing-company"
            class="bg-[#534A6F] peer-checked:text-black peer-checked:bg-primary text-white inline-block px-8 py-4 rounded-full cursor-pointer transition-colors duration-300">
            {{ $t('profile.staffing_company') }}
          </label>
        </span>
        <span>
          <input type="checkbox" class="peer hidden" id="contract-corporative" name="contract-corporative"
            value="Corporativo" v-model="contractType" />
          <label for="contract-corporative"
            class="bg-[#534A6F] peer-checked:text-black peer-checked:bg-primary text-white inline-block px-8 py-4 rounded-full cursor-pointer transition-colors duration-300">
            {{ $t('profile.corporative') }}
          </label>
        </span>
        <span>
          <input type="checkbox" class="peer hidden" id="contract-agency" name="contract-agency" value="Agencia"
            v-model="contractType" />
          <label for="contract-agency"
            class="bg-[#534A6F] peer-checked:text-black peer-checked:bg-primary text-white inline-block px-8 py-4 rounded-full cursor-pointer transition-colors duration-300">
            {{ $t('profile.agency') }}
          </label>
        </span>
      </div>
    </Form>
  </div>
</template>
<script>
import BadgeAward from '@/components/BadgeAward'
import Title from '@/components/Title'
import {
  // Field,
  Form
  // ErrorMessage
} from 'vee-validate'
import { toastNotification } from '@/services/toastNotification'
import apolloClient from '@/graphql/apolloClient'
import { mapActions, mapGetters } from 'vuex'
import saveHighPreferences from '@/graphql/mutations/saveHighPreferences'
export default {
  name: 'EducaciónFormalSection',
  props: {
    isPublic: Boolean
  },
  components: {
    Title,
    BadgeAward,
    // Field,
    Form
    // ErrorMessage
  },
  data() {
    return {
      edit: true,
      managedPeople: '',
      contractType: []
    }
  },
  computed: {
    ...mapGetters(['token', 'user', 'profile'])
  },
  mounted() {
    if (this.profile.personsInCharge) {
      this.managedPeople = this.profile.personsInCharge
    }
    if (this.profile.industries) {
      this.contractType = this.profile.industries
    }
  },
  methods: {
    ...mapActions([
      'setLoadingOn',
      'setLoadingOff',
      'fetchUser',
      'fetchProfile'
    ]),
    async submitForm() {
      try {
        this.setLoadingOn()
        const saveStatus = await apolloClient.mutate({
          mutation: saveHighPreferences,
          variables: {
            industries: this.contractType,
            personsInCharge: this.managedPeople + ''
          },
          context: {
            headers: {
              authorization: `Bearer ${await this.token}`
            }
          }
        })
        if (!saveStatus.errors) {
          await this.fetchUser()
          await this.fetchProfile()
          this.setLoadingOff()

          toastNotification(this.$t('general.info-guardada'), 'success')
        }
      } catch (e) {
        toastNotification(e, 'error')
      }
    }
  }
}
</script>
