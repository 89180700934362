<template>
  <div class="flex flex-wrap text-center space-4">
    <div class="w-full lg:w-4/12">
      <h3 class="text-contrast dark:text-white title--h2 font-black">
        {{ $t('section-general-info.info-title-01') }}
      </h3>
      <h4
        class="text-transparent bg-clip-text bg-gradient-to-r font-bold text-xl from-tertiary to-linkedin"
      >
        {{ $t('section-general-info.info-description-01') }}
      </h4>
    </div>
    <div class="w-full lg:w-4/12">
      <h3 class="text-contrast dark:text-white title--h2 font-black">
        {{ $t('section-general-info.info-title-02') }}
      </h3>
      <h4
        class="text-transparent bg-clip-text bg-gradient-to-r font-bold text-xl from-tertiary to-linkedin"
      >
        {{ $t('section-general-info.info-description-02') }}
      </h4>
    </div>
    <div class="w-full lg:w-4/12">
      <h3 class="text-contrast dark:text-white title--h2 font-black">
        {{ $t('section-general-info.info-title-03') }}
      </h3>
      <h4
        class="text-transparent bg-clip-text bg-gradient-to-r font-bold text-xl from-tertiary to-linkedin"
      >
        {{ $t('section-general-info.info-description-03') }}
      </h4>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GeneralInfo'
}
</script>
