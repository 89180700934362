<template>
  <section class="mb-12">
    <!-- Banner test -->
    <div v-if="showModal" class="welcome flex justify-center items-center">
      <div
        class="welcome-container bg-black text-white px-10 py-12 rounded-xl border-2 w-full relative border-[#B9A3FF]"
      >
        <!-- Exit -->
        <div class="welcome-exit absolute top-3 right-3">
          <span class="cursor-pointer" @click="handleModalClose()">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.39916 18.3069L5.69141 17.5992L11.2914 11.9992L5.69141 6.39916L6.39916 5.69141L11.9992 11.2914L17.5992 5.69141L18.3069 6.39916L12.7069 11.9992L18.3069 17.5992L17.5992 18.3069L11.9992 12.7069L6.39916 18.3069Z"
                fill="#E8EAED"
              />
            </svg>
          </span>
        </div>
        <!-- End Exit -->
        <h1
          class="text-center text-xl font-bold max-w-lg mx-auto break-words"
          style="font-size: 30px !important"
        >
          ¡Felicitaci
          <span class="relative bottom-[-3px] inline-flex">
            <svg
              width="23"
              height="23"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="20.1362"
                height="20.1362"
                transform="translate(0.0800781 0.0585938)"
                fill="black"
              />
              <path
                d="M17.7432 13.2439L11.3214 10.1263L17.7456 7.00803C17.9278 6.90658 18.0767 6.69517 18.1441 6.43455C18.2121 6.17029 18.1848 5.9036 18.0706 5.72075C17.9455 5.49598 17.68 5.3192 17.372 5.24994C17.1326 5.19588 16.9121 5.21835 16.7833 5.31069L10.7934 9.36631L11.3548 2.19365C11.3918 1.86924 11.2849 1.6305 11.0377 1.47559C10.6835 1.25446 10.1179 1.26479 9.74916 1.49989C9.5742 1.61167 9.37555 1.82064 9.42111 2.18393L9.98365 9.36571L3.99803 5.31312C3.56792 5.07498 2.94403 5.29915 2.71379 5.65089C2.46957 6.20492 2.68341 6.83367 3.03393 7.00863L9.45514 10.1263L3.03697 13.2421C2.84683 13.3496 2.69981 13.5592 2.63299 13.818C2.56495 14.0823 2.59229 14.349 2.7065 14.5312C2.83164 14.756 3.09711 14.9328 3.40511 15.002C3.64447 15.0561 3.86499 15.0336 3.99377 14.9413L9.98365 10.8857L9.42233 18.0583C9.37616 18.4313 9.57481 18.6403 9.74977 18.7521C10.1179 18.9872 10.6841 18.9975 11.0383 18.7764C11.2855 18.6215 11.3924 18.3827 11.356 18.0662L10.7934 10.8863L16.7791 14.9388C17.2098 15.177 17.8337 14.9528 18.0633 14.6011C18.3075 14.0477 18.0937 13.4189 17.7432 13.2439Z"
                fill="#DB7E6E"
              />
            </svg>
          </span>
          nes!
        </h1>

        <p class="flex text-lg justify-center mt-2">ganaste el logro de:</p>
        <div class="relative flex items-center justify-center mt-4">
          <div class="absolute top-0 z-10 flex flex-col items-center">
            <BadgeAward
              class="inline-block"
              imagen="icn-aprendizaje.svg"
              titulo="Aprendizaje"
            ></BadgeAward>
            <p class="text-lg font-semibold">Aprendizaje</p>
          </div>
          <div class="z-0">
            <Vue3Lottie
              :animationData="CongratulationsAnimation"
              :height="200"
              :width="200"
            />
          </div>
        </div>
        <p class="text-lg text-center" style="margin-top: -55px !important">
          Continúa así y muy pronto obtendrás <br />
          tu trabajo soñado
        </p>
      </div>
    </div>
    <!-- Banner finish test -->

    <!-- If the sections is not complete -->
    <div
      class="text-center"
      v-if="certifications.length <= 0 && !isPublic && edit === false"
    >
      <div class="flex justify-center space-x-6">
        <BadgeAward
          class="inline-block"
          imagen="icn-aprendizaje.svg"
          :bloqueado="true"
          :titulo="$t('habilidades-page.title-experiencia')"
        ></BadgeAward>
      </div>
      <p class="text-secondary-text text-center">
        {{ $t('profile-page.description-certificaciones-y-cursos-badge') }}
      </p>
      <div class="flex justify-center">
        <button
          type="button"
          class="btn btn-md btn-rounded-lg btn-primary mx-auto"
          role="button"
          :aria-label="$t('general.completar')"
          @click="editSection()"
        >
          {{ $t('general.completar') }}
        </button>
      </div>
    </div>
    <!-- If not complete and edit is true -->
    <Form @submit="validar" v-else-if="!isPublic && edit === true">
      <div class="flex mb-6 lg:mt-7">
        <div
          class="flex justify-between items-center uppercase border-2 bg-black border-primary rounded-lg w-full px-3 lg:px-6 py-3 lg:py-5"
        >
          <Title class="">
            <template #title>
              <h5 class="mt-0 text-lg">
                {{ $t('profile-page.title-certificaciones-y-cursos') }}
              </h5>
            </template>
          </Title>
          <!-- <button
            type="button"
            class="btn btn-outline btn-outline-primary btn-rounded-lg btn-md"
            role="button"
            aria-pressed="false"
            @click="cancelEdition"
          >
            {{ $t('general.cancelar') }}
          </button> -->
          <button
            type="submit"
            class="btn btn-primary btn-rounded-lg btn-md"
            role="button"
            aria-pressed="false"
          >
            <svg
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.8844 7.56222V17.7186C18.8844 18.1452 18.7416 18.5013 18.4559 18.787C18.1702 19.0726 17.8141 19.2155 17.3876 19.2155H5.55642C5.12989 19.2155 4.77379 19.0726 4.4881 18.787C4.20241 18.5013 4.05957 18.1452 4.05957 17.7186V5.88747C4.05957 5.46095 4.20241 5.10484 4.4881 4.81916C4.77379 4.53347 5.12989 4.39062 5.55642 4.39062H15.7128L18.8844 7.56222ZM17.9579 7.95786L15.3172 5.31718H5.55642C5.3901 5.31718 5.25343 5.37061 5.14642 5.47747C5.03956 5.58449 4.98612 5.72116 4.98612 5.88747V17.7186C4.98612 17.885 5.03956 18.0216 5.14642 18.1286C5.25343 18.2355 5.3901 18.2889 5.55642 18.2889H17.3876C17.5539 18.2889 17.6906 18.2355 17.7976 18.1286C17.9044 18.0216 17.9579 17.885 17.9579 17.7186V7.95786ZM11.472 16.0082C11.9828 16.0082 12.4194 15.8271 12.7817 15.4648C13.144 15.1025 13.3251 14.666 13.3251 14.1551C13.3251 13.6443 13.144 13.2077 12.7817 12.8454C12.4194 12.4831 11.9828 12.302 11.472 12.302C10.9612 12.302 10.5246 12.4831 10.1623 12.8454C9.80003 13.2077 9.61889 13.6443 9.61889 14.1551C9.61889 14.666 9.80003 15.1025 10.1623 15.4648C10.5246 15.8271 10.9612 16.0082 11.472 16.0082ZM6.62543 9.73615H13.5032V6.95648H6.62543V9.73615ZM4.98612 7.95786V18.2889V5.31718V7.95786Z"
                fill="black"
              />
            </svg>
            {{ $t('form.btn-guardar') }}
          </button>
        </div>
      </div>
      <p class="dark:text-white text-secondary-text">
        {{ $t('modal-certificaciones-cursos.description') }}
      </p>
      <div v-for="(certification, index) in certifications" :key="index">
        <!-- First Row -->
        <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4 items-end">
          <div class="form--group">
            <label class="form--label" :for="`nombreDelCurso-${index}`">{{
              $t('modal-certificaciones-cursos.lbl-nombre-del-curso')
            }}</label>
            <Field
              :name="`nombreDelCurso-${index}`"
              type="text"
              class="form--element w-full border-2 border-primary"
              :rules="validateDefault"
              v-model="certification.name"
            />
            <ErrorMessage
              :name="`nombreDelCurso-${index}`"
              class="form--error"
            />
          </div>
          <div class="form--group">
            <label class="form--label" :for="`empresaEmisora-${index}`">{{
              $t('modal-certificaciones-cursos.lbl-empresa-emisora')
            }}</label>
            <Field
              :name="`empresaEmisora-${index}`"
              type="text"
              class="form--element w-full border-2 border-primary"
              :rules="validateDefault"
              v-model="certification.certifiedBy"
            />
            <ErrorMessage
              :name="`empresaEmisora-${index}`"
              class="form--error"
            />
          </div>
        </div>
        <!-- End First Row -->
        <!-- Second Row -->
        <div class="grid grid-cols-1 lg:grid-cols-3 lg:gap-x-4 items-end">
          <div
            class="form--group"
            :class="!certification.expires ? '' : 'col-span-2'"
          >
            <h4 class="dark:text-white text-secondary-text mb-2 lg:col-span-2">
              {{ $t('modal-experiencia-profesional.title-fecha-de-inicio') }}
            </h4>
            <Field
              :name="`startDate-${index}`"
              as="label"
              v-slot="{ field }"
              v-model="certification.startDate"
              class="form--icon form--icon--left block rounded-lg border-2 border-primary"
            >
              <span class="icon--wrapper text-white rounded-l-lg opacity-70">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  class="w-4 h-4 fill-current"
                >
                  <path
                    d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                  ></path>
                </svg>
              </span>
              <input
                class="form--element w-full"
                v-bind="field"
                type="date"
                onfocus="this.showPicker()"
                :max="new Date().toISOString().split('T')[0]"
              />
            </Field>
            <ErrorMessage :name="`startDate-${index}`" class="form--error" />
          </div>
          <div class="form--group" v-if="!certification.expires">
            <h4 class="dark:text-white text-secondary-text mb-2 lg:col-span-2">
              {{ $t('modal-experiencia-profesional.title-fecha-de-fin') }}
            </h4>
            <Field
              :name="`endDate-${index}`"
              as="label"
              v-slot="{ field }"
              v-model="certification.endDate"
              class="form--icon form--icon--left block rounded-lg border-2 border-primary"
            >
              <span class="icon--wrapper text-white rounded-l-lg opacity-70">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  class="w-4 h-4 fill-current"
                >
                  <path
                    d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                  ></path>
                </svg>
              </span>
              <input
                class="form--element w-full"
                v-bind="field"
                type="date"
                onfocus="this.showPicker()"
                :min="
                  certification.startDate ||
                  new Date().toISOString().split('T')[0]
                "
              />
            </Field>
            <ErrorMessage :name="`endDate-${index}`" class="form--error" />
          </div>
          <div class="form--group">
            <label class="form--checkbox lg:mb-3"
              ><input
                type="checkbox"
                :id="`remember-${index}`"
                value="first_checkbox"
                @change="checkExpires(index)"
                v-model="certification.expires"
              /><span>{{
                $t('modal-certificaciones-cursos.lbl-credencial-no-caduca')
              }}</span></label
            >
          </div>
        </div>
        <!-- End Second Row -->
        <!-- Third Row -->
        <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4">
          <div class="form--group">
            <label class="form--label" :for="`idCertificacion-${index}`">{{
              $t('modal-certificaciones-cursos.lbl-id-certificacion')
            }}</label>
            <Field
              :name="`idCertificacion-${index}`"
              type="text"
              class="form--element w-full border-2 border-primary rounded-lg"
              v-model="certification.certId"
            />
            <ErrorMessage
              :name="`idCertificacion-${index}`"
              class="form--error"
            />
          </div>
          <div class="form--group">
            <label class="form--label" :for="`urlCertificacion-${index}`">{{
              $t('modal-certificaciones-cursos.lbl-url-certificacion')
            }}</label>
            <Field
              :name="`urlCertificacion-${index}`"
              type="text"
              class="form--element w-full border-2 border-primary rounded-lg"
              :rules="validateUrl"
              v-model="certification.certUrl"
            />
            <ErrorMessage
              :name="`urlCertificacion-${index}`"
              class="form--error"
            />
          </div>
        </div>
        <!-- End Third Row -->
        <div class="flex justify-end mb-6">
          <button
            class="w-9 h-9 flex justify-center items-center rounded-md btn-primary"
            @click="removeRow(index)"
          >
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.15666 23.1474C8.64431 23.1474 8.2079 22.9672 7.84744 22.607C7.48717 22.2465 7.30703 21.8101 7.30703 21.2978V7.11843H6.16211V5.97351H10.7418V5.09277H17.6114V5.97351H22.1911V7.11843H21.0461V21.2978C21.0461 21.8248 20.8696 22.2648 20.5166 22.6178C20.1636 22.9709 19.7236 23.1474 19.1965 23.1474H9.15666ZM19.9012 7.11843H8.45196V21.2978C8.45196 21.5033 8.51798 21.6721 8.65003 21.8044C8.78227 21.9364 8.95115 22.0025 9.15666 22.0025H19.1965C19.3728 22.0025 19.5344 21.9291 19.6811 21.7823C19.8278 21.6356 19.9012 21.4741 19.9012 21.2978V7.11843ZM11.6666 19.7126H12.8115V9.40828H11.6666V19.7126ZM15.5416 19.7126H16.6865V9.40828H15.5416V19.7126Z"
                fill="#1E1D1D"
              />
            </svg>
          </button>
        </div>
      </div>
      <button
        class="btn btn-rounded-lg btn-primary btn-md mb-8 relative"
        @click="addRow"
        type="button"
        role="button"
        :class="{
          'lg:-mt-12': certifications.length != 0
        }"
        :aria-label="
          $t('modal-certificaciones-cursos.btn-agregar-otra-certificacion')
        "
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.57122 13.9112H10.3563V10.7709H13.4967V9.98578H10.3563V6.84541H9.57122V9.98578H6.43086V10.7709H9.57122V13.9112ZM9.96632 17.4441C8.98928 17.4441 8.07065 17.2587 7.21046 16.8879C6.35039 16.5171 5.6022 16.0138 4.96588 15.3782C4.32956 14.7425 3.82586 13.995 3.45478 13.1356C3.08356 12.2763 2.89795 11.358 2.89795 10.3809C2.89795 9.40383 3.08336 8.4852 3.45419 7.62501C3.82501 6.76494 4.32825 6.01675 4.96392 5.38043C5.59958 4.74411 6.34712 4.24041 7.20653 3.86933C8.06581 3.49811 8.98404 3.3125 9.96122 3.3125C10.9383 3.3125 11.8569 3.49791 12.7171 3.86874C13.5772 4.23956 14.3253 4.74281 14.9617 5.37847C15.598 6.01413 16.1017 6.76167 16.4728 7.62108C16.844 8.48036 17.0296 9.39859 17.0296 10.3758C17.0296 11.3528 16.8442 12.2714 16.4734 13.1316C16.1025 13.9917 15.5993 14.7399 14.9636 15.3762C14.328 16.0125 13.5804 16.5162 12.721 16.8873C11.8617 17.2585 10.9435 17.4441 9.96632 17.4441ZM9.96377 16.6591C11.7171 16.6591 13.2023 16.0506 14.4192 14.8337C15.6361 13.6168 16.2445 12.1317 16.2445 10.3783C16.2445 8.62495 15.6361 7.13982 14.4192 5.92293C13.2023 4.70604 11.7171 4.09759 9.96377 4.09759C8.2104 4.09759 6.72527 4.70604 5.50838 5.92293C4.29149 7.13982 3.68304 8.62495 3.68304 10.3783C3.68304 12.1317 4.29149 13.6168 5.50838 14.8337C6.72527 16.0506 8.2104 16.6591 9.96377 16.6591Z"
            fill="#1E1D1D"
          />
        </svg>
        <span class="ml-1">
          {{
            $t('modal-certificaciones-cursos.btn-agregar-otra-certificacion')
          }}
        </span>
      </button>
      <!-- <div class="flex justify-center space-x-4">
        <button
          type="submit"
          class="btn btn-outline btn-outline-primary btn-rounded-lg btn-md"
          role="button"
          aria-pressed="false"
          @click="cancelEdition"
        >
          {{ $t('general.cancelar') }}
        </button>
        <button
          type="submit"
          class="btn btn-primary mx-auto btn-rounded-lg btn-md"
          role="button"
          aria-pressed="false"
        >
          {{ $t('general.guardar') }}
        </button>
      </div> -->
    </Form>
    <!-- Else if the section is complete -->
    <div v-else>
      <ul class="list--education list--education--small">
        <li v-for="(cert, index) in certifications" :key="index" class="pb-8">
          <span class="list--education--bullets"></span>
          <time class="dark:text-primary-text text-secondary-text text-sm">
            {{ formatedDate(cert.startDate) }} |
            {{ formatedDate(cert.endDate) }}
          </time>
          <h4 class="text-white font-bold text-lg mb-2 uppercase">
            {{ cert.name }} |
            <i class="text-secondary">{{ cert.certifiedBy }} </i>
          </h4>
          <div
            v-if="cert.certId"
            class="dark:text-primary-text text-secondary-text text-sm uppercase mb-4"
          >
            Id: {{ cert.certId }}
          </div>
          <a :href="cert.certUrls" class="text-primary text-sm">
            {{ cert.certUrl }}
          </a>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import Title from '@/components/Title'
import BadgeAward from '@/components/BadgeAward'
import ROUTES from '@/constants/routes'
import { dateFormat } from '@/services/dateFormatting'
import { Field, Form, ErrorMessage } from 'vee-validate'
import apolloClient from '@/graphql/apolloClient'
import SaveCertsMutation from '@/graphql/mutations/saveCerts'
import { mapActions, mapGetters } from 'vuex'
import { validateDefault, validateUrl } from '@/services/validationRules'
import { toastNotification } from '@/services/toastNotification'
import CongratulationsAnimation from '@/animations/Congratulations.json'

const emptyCert = {
  name: null,
  certifiedBy: null,
  startDate: null,
  expires: false,
  endDate: null,
  certId: null,
  certUrl: null
}

export default {
  name: 'CertificacionesCursosSection',
  props: {
    userCertsAndCourses: Array,
    isPublic: Boolean,
    id: { type: Number, required: false }
  },
  components: {
    Title,
    BadgeAward,
    Field,
    Form,
    ErrorMessage
  },
  data() {
    return {
      ROUTES,
      CongratulationsAnimation,
      certifications: [],
      edit: true,
      showModal: false,
      exitModal: false
    }
  },
  mounted() {
    this.initializeData()
    // if (this.certifications !== []) {
    //   this.edit = false
    // } else {
    //   this.edit = true
    // }
  },
  computed: {
    ...mapGetters(['token']),
    sortCertificaciones() {
      return [...this.certifications].sort(
        (a, b) => new Date(b.startDate) - new Date(a.startDate)
      )
    }
  },
  methods: {
    confDate: function (e) {
      return dateFormat(e)
    },
    formatedDate(date) {
      if (date) {
        const month = `${new Date(date).getMonth() + 1}`.padStart(2, '0')
        const year = new Date(date).getFullYear()
        return `${month}-${year}`
      } else {
        return this.$t('general.no-caduca')
      }
    },
    ...mapActions([
      'fetchUser',
      'fetchProfile',
      'setLoadingOn',
      'setLoadingOff'
    ]),
    async validar() {
      const wasEmptyBefore =
        !this.userCertsAndCourses || this.userCertsAndCourses.length === 0

      try {
        this.setLoadingOn()
        const saveCertificaciones = await apolloClient.mutate({
          mutation: SaveCertsMutation,
          variables: {
            certs: this.sortCertificaciones,
            id: this.id
          },
          context: {
            headers: {
              authorization: `Bearer ${await this.token}`
            }
          }
        })
        if (!saveCertificaciones.errors) {
          await this.fetchUser()
          await this.fetchProfile()

          this.$nextTick(() => {
            if (wasEmptyBefore && this.certifications.length > 0) {
              this.showModal = true
              setTimeout(() => {
                this.exitModal = true
              }, 1000)
            }
          })

          this.setLoadingOff()
          toastNotification(this.$t('general.info-guardada'), 'success')
          // this.edit = false
        }
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    addRow() {
      this.certifications.push({
        ...emptyCert
      })
    },
    removeRow(index) {
      this.certifications.splice(index, 1)
    },
    editSection() {
      this.edit = true
      if (this.certifications.length <= 0) {
        this.certifications.push({
          ...emptyCert
        })
      }
    },
    cancelEdition() {
      this.certifications = []
      if (this.userCertsAndCourses.length > 0) {
        this.initializeData()
      }
      this.edit = false
    },
    handleModalClose() {
      this.showModal = false
    },
    initializeData() {
      if (this.userCertsAndCourses) {
        this.userCertsAndCourses.forEach((val) => {
          this.certifications.unshift({
            name: val.name,
            certifiedBy: val.certifiedBy,
            startDate: val.startDate,
            endDate: val.endDate,
            certId: val.certId,
            certUrl: val.certUrl
          })
        })
      }
      if (this.certifications.length <= 0) {
        this.certifications.push({
          ...emptyCert
        })
      }
    },
    // RULES
    validateDefault,
    validateUrl,
    checkExpires(index) {
      if (this.certifications[index].expires === true) {
        this.certifications[index].endDate = null
      }
    }
  }
}
</script>
