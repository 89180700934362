<template>

  <body class="a4-page" v-if="userProfile">
    <header style="position: relative; z-index: 1">
      <div style="
          position: absolute;
          top: 0;
          width: 100%;
          height: 167px;
          overflow: hidden;
        ">
        <svg width="1455" height="146" viewBox="0 0 1455 146" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1630_46709)">
            <path
              d="M19.9305 13.691C13.0056 13.691 7.37109 8.05651 7.37109 1.13164C7.37109 -5.79412 13.0056 -11.4277 19.9305 -11.4277C26.8554 -11.4277 32.4899 -5.79412 32.4899 1.13164C32.4899 8.05741 26.8554 13.691 19.9305 13.691ZM19.9305 -10.2465C13.6569 -10.2465 8.55238 -5.14198 8.55238 1.13164C8.55238 7.40522 13.6569 12.5097 19.9305 12.5097C26.2041 12.5097 31.3086 7.40522 31.3086 1.13164C31.3086 -5.14198 26.205 -10.2465 19.9305 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M51.5086 13.691C44.5837 13.691 38.9492 8.05651 38.9492 1.13164C38.9492 -5.79412 44.5837 -11.4277 51.5086 -11.4277C58.4335 -11.4277 64.068 -5.79412 64.068 1.13164C64.068 8.05741 58.4335 13.691 51.5086 13.691ZM51.5086 -10.2465C45.235 -10.2465 40.1305 -5.14198 40.1305 1.13164C40.1305 7.40522 45.235 12.5097 51.5086 12.5097C57.7822 12.5097 62.8867 7.40522 62.8867 1.13164C62.8867 -5.14198 57.7831 -10.2465 51.5086 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M83.0867 13.691C76.1618 13.691 70.5273 8.05651 70.5273 1.13164C70.5273 -5.79412 76.1618 -11.4277 83.0867 -11.4277C90.0116 -11.4277 95.6461 -5.79412 95.6461 1.13164C95.6461 8.05741 90.0116 13.691 83.0867 13.691ZM83.0867 -10.2465C76.8131 -10.2465 71.7086 -5.14198 71.7086 1.13164C71.7086 7.40522 76.8131 12.5097 83.0867 12.5097C89.3603 12.5097 94.4648 7.40522 94.4648 1.13164C94.4648 -5.14198 89.3612 -10.2465 83.0867 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M114.665 13.691C107.74 13.691 102.105 8.05651 102.105 1.13164C102.105 -5.79412 107.74 -11.4277 114.665 -11.4277C121.59 -11.4277 127.224 -5.79412 127.224 1.13164C127.224 8.05741 121.59 13.691 114.665 13.691ZM114.665 -10.2465C108.391 -10.2465 103.287 -5.14198 103.287 1.13164C103.287 7.40522 108.391 12.5097 114.665 12.5097C120.938 12.5097 126.043 7.40522 126.043 1.13164C126.043 -5.14198 120.939 -10.2465 114.665 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M146.239 13.691C139.314 13.691 133.68 8.05651 133.68 1.13164C133.68 -5.79412 139.314 -11.4277 146.239 -11.4277C153.164 -11.4277 158.798 -5.79412 158.798 1.13164C158.798 8.05741 153.164 13.691 146.239 13.691ZM146.239 -10.2465C139.965 -10.2465 134.861 -5.14198 134.861 1.13164C134.861 7.40522 139.965 12.5097 146.239 12.5097C152.513 12.5097 157.617 7.40522 157.617 1.13164C157.617 -5.14198 152.514 -10.2465 146.239 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M177.825 13.691C170.9 13.691 165.266 8.05651 165.266 1.13164C165.266 -5.79412 170.9 -11.4277 177.825 -11.4277C184.75 -11.4277 190.384 -5.79412 190.384 1.13164C190.384 8.05741 184.75 13.691 177.825 13.691ZM177.825 -10.2465C171.551 -10.2465 166.447 -5.14198 166.447 1.13164C166.447 7.40522 171.551 12.5097 177.825 12.5097C184.099 12.5097 189.203 7.40522 189.203 1.13164C189.203 -5.14198 184.1 -10.2465 177.825 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M209.399 13.691C202.474 13.691 196.84 8.05651 196.84 1.13164C196.84 -5.79412 202.474 -11.4277 209.399 -11.4277C216.324 -11.4277 221.959 -5.79412 221.959 1.13164C221.959 8.05741 216.324 13.691 209.399 13.691ZM209.399 -10.2465C203.126 -10.2465 198.021 -5.14198 198.021 1.13164C198.021 7.40522 203.126 12.5097 209.399 12.5097C215.673 12.5097 220.777 7.40522 220.777 1.13164C220.777 -5.14198 215.674 -10.2465 209.399 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M240.977 13.691C234.052 13.691 228.418 8.05651 228.418 1.13164C228.418 -5.79412 234.052 -11.4277 240.977 -11.4277C247.902 -11.4277 253.537 -5.79412 253.537 1.13164C253.537 8.05741 247.902 13.691 240.977 13.691ZM240.977 -10.2465C234.704 -10.2465 229.599 -5.14198 229.599 1.13164C229.599 7.40522 234.704 12.5097 240.977 12.5097C247.251 12.5097 252.355 7.40522 252.355 1.13164C252.355 -5.14198 247.252 -10.2465 240.977 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M272.555 13.691C265.631 13.691 259.996 8.05651 259.996 1.13164C259.996 -5.79412 265.631 -11.4277 272.555 -11.4277C279.48 -11.4277 285.115 -5.79412 285.115 1.13164C285.115 8.05741 279.48 13.691 272.555 13.691ZM272.555 -10.2465C266.282 -10.2465 261.177 -5.14198 261.177 1.13164C261.177 7.40522 266.282 12.5097 272.555 12.5097C278.829 12.5097 283.934 7.40522 283.934 1.13164C283.934 -5.14198 278.83 -10.2465 272.555 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M304.13 13.691C297.205 13.691 291.57 8.05651 291.57 1.13164C291.57 -5.79412 297.205 -11.4277 304.13 -11.4277C311.055 -11.4277 316.689 -5.79412 316.689 1.13164C316.689 8.05741 311.055 13.691 304.13 13.691ZM304.13 -10.2465C297.856 -10.2465 292.752 -5.14198 292.752 1.13164C292.752 7.40522 297.856 12.5097 304.13 12.5097C310.403 12.5097 315.508 7.40522 315.508 1.13164C315.508 -5.14198 310.404 -10.2465 304.13 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M335.712 13.691C328.787 13.691 323.152 8.05651 323.152 1.13164C323.152 -5.79412 328.787 -11.4277 335.712 -11.4277C342.637 -11.4277 348.271 -5.79412 348.271 1.13164C348.271 8.05741 342.637 13.691 335.712 13.691ZM335.712 -10.2465C329.438 -10.2465 324.334 -5.14198 324.334 1.13164C324.334 7.40522 329.438 12.5097 335.712 12.5097C341.985 12.5097 347.09 7.40522 347.09 1.13164C347.09 -5.14198 341.986 -10.2465 335.712 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M367.286 13.691C360.361 13.691 354.727 8.05651 354.727 1.13164C354.727 -5.79412 360.361 -11.4277 367.286 -11.4277C374.211 -11.4277 379.845 -5.79412 379.845 1.13164C379.845 8.05741 374.211 13.691 367.286 13.691ZM367.286 -10.2465C361.012 -10.2465 355.908 -5.14198 355.908 1.13164C355.908 7.40522 361.012 12.5097 367.286 12.5097C373.56 12.5097 378.664 7.40522 378.664 1.13164C378.664 -5.14198 373.56 -10.2465 367.286 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M398.864 13.691C391.939 13.691 386.305 8.05651 386.305 1.13164C386.305 -5.79412 391.939 -11.4277 398.864 -11.4277C405.789 -11.4277 411.423 -5.79412 411.423 1.13164C411.423 8.05741 405.789 13.691 398.864 13.691ZM398.864 -10.2465C392.59 -10.2465 387.486 -5.14198 387.486 1.13164C387.486 7.40522 392.59 12.5097 398.864 12.5097C405.138 12.5097 410.242 7.40522 410.242 1.13164C410.242 -5.14198 405.139 -10.2465 398.864 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M430.446 13.691C423.521 13.691 417.887 8.05651 417.887 1.13164C417.887 -5.79412 423.521 -11.4277 430.446 -11.4277C437.371 -11.4277 443.006 -5.79412 443.006 1.13164C443.006 8.05741 437.371 13.691 430.446 13.691ZM430.446 -10.2465C424.172 -10.2465 419.068 -5.14198 419.068 1.13164C419.068 7.40522 424.172 12.5097 430.446 12.5097C436.72 12.5097 441.824 7.40522 441.824 1.13164C441.824 -5.14198 436.721 -10.2465 430.446 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M462.016 13.691C455.092 13.691 449.457 8.05651 449.457 1.13164C449.457 -5.79412 455.092 -11.4277 462.016 -11.4277C468.941 -11.4277 474.576 -5.79412 474.576 1.13164C474.576 8.05741 468.941 13.691 462.016 13.691ZM462.016 -10.2465C455.743 -10.2465 450.638 -5.14198 450.638 1.13164C450.638 7.40522 455.743 12.5097 462.016 12.5097C468.29 12.5097 473.395 7.40522 473.395 1.13164C473.395 -5.14198 468.291 -10.2465 462.016 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M493.595 13.691C486.67 13.691 481.035 8.05651 481.035 1.13164C481.035 -5.79412 486.67 -11.4277 493.595 -11.4277C500.519 -11.4277 506.154 -5.79412 506.154 1.13164C506.154 8.05741 500.519 13.691 493.595 13.691ZM493.595 -10.2465C487.321 -10.2465 482.216 -5.14198 482.216 1.13164C482.216 7.40522 487.321 12.5097 493.595 12.5097C499.868 12.5097 504.973 7.40522 504.973 1.13164C504.973 -5.14198 499.869 -10.2465 493.595 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M525.169 13.691C518.244 13.691 512.609 8.05651 512.609 1.13164C512.609 -5.79412 518.244 -11.4277 525.169 -11.4277C532.094 -11.4277 537.728 -5.79412 537.728 1.13164C537.728 8.05741 532.094 13.691 525.169 13.691ZM525.169 -10.2465C518.895 -10.2465 513.791 -5.14198 513.791 1.13164C513.791 7.40522 518.895 12.5097 525.169 12.5097C531.442 12.5097 536.547 7.40522 536.547 1.13164C536.547 -5.14198 531.443 -10.2465 525.169 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M556.743 13.691C549.818 13.691 544.184 8.05651 544.184 1.13164C544.184 -5.79412 549.818 -11.4277 556.743 -11.4277C563.668 -11.4277 569.302 -5.79412 569.302 1.13164C569.302 8.05741 563.668 13.691 556.743 13.691ZM556.743 -10.2465C550.469 -10.2465 545.365 -5.14198 545.365 1.13164C545.365 7.40522 550.469 12.5097 556.743 12.5097C563.017 12.5097 568.121 7.40522 568.121 1.13164C568.121 -5.14198 563.017 -10.2465 556.743 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M588.321 13.691C581.396 13.691 575.762 8.05651 575.762 1.13164C575.762 -5.79412 581.396 -11.4277 588.321 -11.4277C595.246 -11.4277 600.881 -5.79412 600.881 1.13164C600.881 8.05741 595.246 13.691 588.321 13.691ZM588.321 -10.2465C582.047 -10.2465 576.943 -5.14198 576.943 1.13164C576.943 7.40522 582.047 12.5097 588.321 12.5097C594.595 12.5097 599.699 7.40522 599.699 1.13164C599.699 -5.14198 594.596 -10.2465 588.321 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M619.895 13.691C612.97 13.691 607.336 8.05651 607.336 1.13164C607.336 -5.79412 612.97 -11.4277 619.895 -11.4277C626.82 -11.4277 632.455 -5.79412 632.455 1.13164C632.455 8.05741 626.82 13.691 619.895 13.691ZM619.895 -10.2465C613.622 -10.2465 608.517 -5.14198 608.517 1.13164C608.517 7.40522 613.622 12.5097 619.895 12.5097C626.169 12.5097 631.273 7.40522 631.273 1.13164C631.273 -5.14198 626.17 -10.2465 619.895 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M651.47 13.691C644.545 13.691 638.91 8.05651 638.91 1.13164C638.91 -5.79412 644.545 -11.4277 651.47 -11.4277C658.394 -11.4277 664.029 -5.79412 664.029 1.13164C664.029 8.05741 658.394 13.691 651.47 13.691ZM651.47 -10.2465C645.196 -10.2465 640.091 -5.14198 640.091 1.13164C640.091 7.40522 645.196 12.5097 651.47 12.5097C657.743 12.5097 662.848 7.40522 662.848 1.13164C662.848 -5.14198 657.744 -10.2465 651.47 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M683.052 13.691C676.127 13.691 670.492 8.05651 670.492 1.13164C670.492 -5.79412 676.127 -11.4277 683.052 -11.4277C689.976 -11.4277 695.611 -5.79412 695.611 1.13164C695.611 8.05741 689.976 13.691 683.052 13.691ZM683.052 -10.2465C676.778 -10.2465 671.673 -5.14198 671.673 1.13164C671.673 7.40522 676.778 12.5097 683.052 12.5097C689.325 12.5097 694.43 7.40522 694.43 1.13164C694.43 -5.14198 689.326 -10.2465 683.052 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M714.622 13.691C707.697 13.691 702.062 8.05651 702.062 1.13164C702.062 -5.79412 707.697 -11.4277 714.622 -11.4277C721.547 -11.4277 727.181 -5.79412 727.181 1.13164C727.181 8.05741 721.547 13.691 714.622 13.691ZM714.622 -10.2465C708.348 -10.2465 703.244 -5.14198 703.244 1.13164C703.244 7.40522 708.348 12.5097 714.622 12.5097C720.895 12.5097 726 7.40522 726 1.13164C726 -5.14198 720.896 -10.2465 714.622 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M746.2 13.691C739.275 13.691 733.641 8.05651 733.641 1.13164C733.641 -5.79412 739.275 -11.4277 746.2 -11.4277C753.125 -11.4277 758.759 -5.79412 758.759 1.13164C758.759 8.05741 753.125 13.691 746.2 13.691ZM746.2 -10.2465C739.926 -10.2465 734.822 -5.14198 734.822 1.13164C734.822 7.40522 739.926 12.5097 746.2 12.5097C752.474 12.5097 757.578 7.40522 757.578 1.13164C757.578 -5.14198 752.475 -10.2465 746.2 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M777.778 13.691C770.853 13.691 765.219 8.05651 765.219 1.13164C765.219 -5.79412 770.853 -11.4277 777.778 -11.4277C784.703 -11.4277 790.338 -5.79412 790.338 1.13164C790.338 8.05741 784.703 13.691 777.778 13.691ZM777.778 -10.2465C771.505 -10.2465 766.4 -5.14198 766.4 1.13164C766.4 7.40522 771.505 12.5097 777.778 12.5097C784.052 12.5097 789.156 7.40522 789.156 1.13164C789.156 -5.14198 784.053 -10.2465 777.778 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M809.348 13.691C802.424 13.691 796.789 8.05651 796.789 1.13164C796.789 -5.79412 802.424 -11.4277 809.348 -11.4277C816.273 -11.4277 821.908 -5.79412 821.908 1.13164C821.908 8.05741 816.273 13.691 809.348 13.691ZM809.348 -10.2465C803.075 -10.2465 797.97 -5.14198 797.97 1.13164C797.97 7.40522 803.075 12.5097 809.348 12.5097C815.622 12.5097 820.727 7.40522 820.727 1.13164C820.727 -5.14198 815.623 -10.2465 809.348 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M840.927 13.691C834.002 13.691 828.367 8.05651 828.367 1.13164C828.367 -5.79412 834.002 -11.4277 840.927 -11.4277C847.851 -11.4277 853.486 -5.79412 853.486 1.13164C853.486 8.05741 847.851 13.691 840.927 13.691ZM840.927 -10.2465C834.653 -10.2465 829.548 -5.14198 829.548 1.13164C829.548 7.40522 834.653 12.5097 840.927 12.5097C847.2 12.5097 852.305 7.40522 852.305 1.13164C852.305 -5.14198 847.201 -10.2465 840.927 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M872.501 13.691C865.576 13.691 859.941 8.05651 859.941 1.13164C859.941 -5.79412 865.576 -11.4277 872.501 -11.4277C879.426 -11.4277 885.06 -5.79412 885.06 1.13164C885.06 8.05741 879.426 13.691 872.501 13.691ZM872.501 -10.2465C866.227 -10.2465 861.123 -5.14198 861.123 1.13164C861.123 7.40522 866.227 12.5097 872.501 12.5097C878.774 12.5097 883.879 7.40522 883.879 1.13164C883.879 -5.14198 878.775 -10.2465 872.501 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M904.075 13.691C897.15 13.691 891.516 8.05651 891.516 1.13164C891.516 -5.79412 897.15 -11.4277 904.075 -11.4277C911 -11.4277 916.634 -5.79412 916.634 1.13164C916.634 8.05741 911 13.691 904.075 13.691ZM904.075 -10.2465C897.801 -10.2465 892.697 -5.14198 892.697 1.13164C892.697 7.40522 897.801 12.5097 904.075 12.5097C910.349 12.5097 915.453 7.40522 915.453 1.13164C915.453 -5.14198 910.35 -10.2465 904.075 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M19.9305 48.1422C13.0056 48.1422 7.37109 42.5077 7.37109 35.5828C7.37109 28.6571 13.0056 23.0234 19.9305 23.0234C26.8554 23.0234 32.4899 28.6571 32.4899 35.5828C32.4899 42.5086 26.8554 48.1422 19.9305 48.1422ZM19.9305 24.2047C13.6569 24.2047 8.55238 29.3092 8.55238 35.5828C8.55238 41.8564 13.6569 46.9609 19.9305 46.9609C26.2041 46.9609 31.3086 41.8564 31.3086 35.5828C31.3086 29.3092 26.205 24.2047 19.9305 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M51.5086 48.1422C44.5837 48.1422 38.9492 42.5077 38.9492 35.5828C38.9492 28.6571 44.5837 23.0234 51.5086 23.0234C58.4335 23.0234 64.068 28.6571 64.068 35.5828C64.068 42.5086 58.4335 48.1422 51.5086 48.1422ZM51.5086 24.2047C45.235 24.2047 40.1305 29.3092 40.1305 35.5828C40.1305 41.8564 45.235 46.9609 51.5086 46.9609C57.7822 46.9609 62.8867 41.8564 62.8867 35.5828C62.8867 29.3092 57.7831 24.2047 51.5086 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M83.0867 48.1422C76.1618 48.1422 70.5273 42.5077 70.5273 35.5828C70.5273 28.6571 76.1618 23.0234 83.0867 23.0234C90.0116 23.0234 95.6461 28.6571 95.6461 35.5828C95.6461 42.5086 90.0116 48.1422 83.0867 48.1422ZM83.0867 24.2047C76.8131 24.2047 71.7086 29.3092 71.7086 35.5828C71.7086 41.8564 76.8131 46.9609 83.0867 46.9609C89.3603 46.9609 94.4648 41.8564 94.4648 35.5828C94.4648 29.3092 89.3612 24.2047 83.0867 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M114.665 48.1422C107.74 48.1422 102.105 42.5077 102.105 35.5828C102.105 28.6571 107.74 23.0234 114.665 23.0234C121.59 23.0234 127.224 28.6571 127.224 35.5828C127.224 42.5086 121.59 48.1422 114.665 48.1422ZM114.665 24.2047C108.391 24.2047 103.287 29.3092 103.287 35.5828C103.287 41.8564 108.391 46.9609 114.665 46.9609C120.938 46.9609 126.043 41.8564 126.043 35.5828C126.043 29.3092 120.939 24.2047 114.665 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M146.239 48.1422C139.314 48.1422 133.68 42.5077 133.68 35.5828C133.68 28.6571 139.314 23.0234 146.239 23.0234C153.164 23.0234 158.798 28.6571 158.798 35.5828C158.798 42.5086 153.164 48.1422 146.239 48.1422ZM146.239 24.2047C139.965 24.2047 134.861 29.3092 134.861 35.5828C134.861 41.8564 139.965 46.9609 146.239 46.9609C152.513 46.9609 157.617 41.8564 157.617 35.5828C157.617 29.3092 152.514 24.2047 146.239 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M177.825 48.1422C170.9 48.1422 165.266 42.5077 165.266 35.5828C165.266 28.6571 170.9 23.0234 177.825 23.0234C184.75 23.0234 190.384 28.6571 190.384 35.5828C190.384 42.5086 184.75 48.1422 177.825 48.1422ZM177.825 24.2047C171.551 24.2047 166.447 29.3092 166.447 35.5828C166.447 41.8564 171.551 46.9609 177.825 46.9609C184.099 46.9609 189.203 41.8564 189.203 35.5828C189.203 29.3092 184.1 24.2047 177.825 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M209.399 48.1422C202.474 48.1422 196.84 42.5077 196.84 35.5828C196.84 28.6571 202.474 23.0234 209.399 23.0234C216.324 23.0234 221.959 28.6571 221.959 35.5828C221.959 42.5086 216.324 48.1422 209.399 48.1422ZM209.399 24.2047C203.126 24.2047 198.021 29.3092 198.021 35.5828C198.021 41.8564 203.126 46.9609 209.399 46.9609C215.673 46.9609 220.777 41.8564 220.777 35.5828C220.777 29.3092 215.674 24.2047 209.399 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M240.977 48.1422C234.052 48.1422 228.418 42.5077 228.418 35.5828C228.418 28.6571 234.052 23.0234 240.977 23.0234C247.902 23.0234 253.537 28.6571 253.537 35.5828C253.537 42.5086 247.902 48.1422 240.977 48.1422ZM240.977 24.2047C234.704 24.2047 229.599 29.3092 229.599 35.5828C229.599 41.8564 234.704 46.9609 240.977 46.9609C247.251 46.9609 252.355 41.8564 252.355 35.5828C252.355 29.3092 247.252 24.2047 240.977 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M272.555 48.1422C265.631 48.1422 259.996 42.5077 259.996 35.5828C259.996 28.6571 265.631 23.0234 272.555 23.0234C279.48 23.0234 285.115 28.6571 285.115 35.5828C285.115 42.5086 279.48 48.1422 272.555 48.1422ZM272.555 24.2047C266.282 24.2047 261.177 29.3092 261.177 35.5828C261.177 41.8564 266.282 46.9609 272.555 46.9609C278.829 46.9609 283.934 41.8564 283.934 35.5828C283.934 29.3092 278.83 24.2047 272.555 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M304.13 48.1422C297.205 48.1422 291.57 42.5077 291.57 35.5828C291.57 28.6571 297.205 23.0234 304.13 23.0234C311.055 23.0234 316.689 28.6571 316.689 35.5828C316.689 42.5086 311.055 48.1422 304.13 48.1422ZM304.13 24.2047C297.856 24.2047 292.752 29.3092 292.752 35.5828C292.752 41.8564 297.856 46.9609 304.13 46.9609C310.403 46.9609 315.508 41.8564 315.508 35.5828C315.508 29.3092 310.404 24.2047 304.13 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M335.712 48.1422C328.787 48.1422 323.152 42.5077 323.152 35.5828C323.152 28.6571 328.787 23.0234 335.712 23.0234C342.637 23.0234 348.271 28.6571 348.271 35.5828C348.271 42.5086 342.637 48.1422 335.712 48.1422ZM335.712 24.2047C329.438 24.2047 324.334 29.3092 324.334 35.5828C324.334 41.8564 329.438 46.9609 335.712 46.9609C341.985 46.9609 347.09 41.8564 347.09 35.5828C347.09 29.3092 341.986 24.2047 335.712 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M367.286 48.1422C360.361 48.1422 354.727 42.5077 354.727 35.5828C354.727 28.6571 360.361 23.0234 367.286 23.0234C374.211 23.0234 379.845 28.6571 379.845 35.5828C379.845 42.5086 374.211 48.1422 367.286 48.1422ZM367.286 24.2047C361.012 24.2047 355.908 29.3092 355.908 35.5828C355.908 41.8564 361.012 46.9609 367.286 46.9609C373.56 46.9609 378.664 41.8564 378.664 35.5828C378.664 29.3092 373.56 24.2047 367.286 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M398.864 48.1422C391.939 48.1422 386.305 42.5077 386.305 35.5828C386.305 28.6571 391.939 23.0234 398.864 23.0234C405.789 23.0234 411.423 28.6571 411.423 35.5828C411.423 42.5086 405.789 48.1422 398.864 48.1422ZM398.864 24.2047C392.59 24.2047 387.486 29.3092 387.486 35.5828C387.486 41.8564 392.59 46.9609 398.864 46.9609C405.138 46.9609 410.242 41.8564 410.242 35.5828C410.242 29.3092 405.139 24.2047 398.864 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M430.446 48.1422C423.521 48.1422 417.887 42.5077 417.887 35.5828C417.887 28.6571 423.521 23.0234 430.446 23.0234C437.371 23.0234 443.006 28.6571 443.006 35.5828C443.006 42.5086 437.371 48.1422 430.446 48.1422ZM430.446 24.2047C424.172 24.2047 419.068 29.3092 419.068 35.5828C419.068 41.8564 424.172 46.9609 430.446 46.9609C436.72 46.9609 441.824 41.8564 441.824 35.5828C441.824 29.3092 436.721 24.2047 430.446 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M462.016 48.1422C455.092 48.1422 449.457 42.5077 449.457 35.5828C449.457 28.6571 455.092 23.0234 462.016 23.0234C468.941 23.0234 474.576 28.6571 474.576 35.5828C474.576 42.5086 468.941 48.1422 462.016 48.1422ZM462.016 24.2047C455.743 24.2047 450.638 29.3092 450.638 35.5828C450.638 41.8564 455.743 46.9609 462.016 46.9609C468.29 46.9609 473.395 41.8564 473.395 35.5828C473.395 29.3092 468.291 24.2047 462.016 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M493.595 48.1422C486.67 48.1422 481.035 42.5077 481.035 35.5828C481.035 28.6571 486.67 23.0234 493.595 23.0234C500.519 23.0234 506.154 28.6571 506.154 35.5828C506.154 42.5086 500.519 48.1422 493.595 48.1422ZM493.595 24.2047C487.321 24.2047 482.216 29.3092 482.216 35.5828C482.216 41.8564 487.321 46.9609 493.595 46.9609C499.868 46.9609 504.973 41.8564 504.973 35.5828C504.973 29.3092 499.869 24.2047 493.595 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M525.169 48.1422C518.244 48.1422 512.609 42.5077 512.609 35.5828C512.609 28.6571 518.244 23.0234 525.169 23.0234C532.094 23.0234 537.728 28.6571 537.728 35.5828C537.728 42.5086 532.094 48.1422 525.169 48.1422ZM525.169 24.2047C518.895 24.2047 513.791 29.3092 513.791 35.5828C513.791 41.8564 518.895 46.9609 525.169 46.9609C531.442 46.9609 536.547 41.8564 536.547 35.5828C536.547 29.3092 531.443 24.2047 525.169 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M556.743 48.1422C549.818 48.1422 544.184 42.5077 544.184 35.5828C544.184 28.6571 549.818 23.0234 556.743 23.0234C563.668 23.0234 569.302 28.6571 569.302 35.5828C569.302 42.5086 563.668 48.1422 556.743 48.1422ZM556.743 24.2047C550.469 24.2047 545.365 29.3092 545.365 35.5828C545.365 41.8564 550.469 46.9609 556.743 46.9609C563.017 46.9609 568.121 41.8564 568.121 35.5828C568.121 29.3092 563.017 24.2047 556.743 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M588.321 48.1422C581.396 48.1422 575.762 42.5077 575.762 35.5828C575.762 28.6571 581.396 23.0234 588.321 23.0234C595.246 23.0234 600.881 28.6571 600.881 35.5828C600.881 42.5086 595.246 48.1422 588.321 48.1422ZM588.321 24.2047C582.047 24.2047 576.943 29.3092 576.943 35.5828C576.943 41.8564 582.047 46.9609 588.321 46.9609C594.595 46.9609 599.699 41.8564 599.699 35.5828C599.699 29.3092 594.596 24.2047 588.321 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M619.895 48.1422C612.97 48.1422 607.336 42.5077 607.336 35.5828C607.336 28.6571 612.97 23.0234 619.895 23.0234C626.82 23.0234 632.455 28.6571 632.455 35.5828C632.455 42.5086 626.82 48.1422 619.895 48.1422ZM619.895 24.2047C613.622 24.2047 608.517 29.3092 608.517 35.5828C608.517 41.8564 613.622 46.9609 619.895 46.9609C626.169 46.9609 631.273 41.8564 631.273 35.5828C631.273 29.3092 626.17 24.2047 619.895 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M651.47 48.1422C644.545 48.1422 638.91 42.5077 638.91 35.5828C638.91 28.6571 644.545 23.0234 651.47 23.0234C658.394 23.0234 664.029 28.6571 664.029 35.5828C664.029 42.5086 658.394 48.1422 651.47 48.1422ZM651.47 24.2047C645.196 24.2047 640.091 29.3092 640.091 35.5828C640.091 41.8564 645.196 46.9609 651.47 46.9609C657.743 46.9609 662.848 41.8564 662.848 35.5828C662.848 29.3092 657.744 24.2047 651.47 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M683.052 48.1422C676.127 48.1422 670.492 42.5077 670.492 35.5828C670.492 28.6571 676.127 23.0234 683.052 23.0234C689.976 23.0234 695.611 28.6571 695.611 35.5828C695.611 42.5086 689.976 48.1422 683.052 48.1422ZM683.052 24.2047C676.778 24.2047 671.673 29.3092 671.673 35.5828C671.673 41.8564 676.778 46.9609 683.052 46.9609C689.325 46.9609 694.43 41.8564 694.43 35.5828C694.43 29.3092 689.326 24.2047 683.052 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M714.622 48.1422C707.697 48.1422 702.062 42.5077 702.062 35.5828C702.062 28.6571 707.697 23.0234 714.622 23.0234C721.547 23.0234 727.181 28.6571 727.181 35.5828C727.181 42.5086 721.547 48.1422 714.622 48.1422ZM714.622 24.2047C708.348 24.2047 703.244 29.3092 703.244 35.5828C703.244 41.8564 708.348 46.9609 714.622 46.9609C720.895 46.9609 726 41.8564 726 35.5828C726 29.3092 720.896 24.2047 714.622 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M746.2 48.1422C739.275 48.1422 733.641 42.5077 733.641 35.5828C733.641 28.6571 739.275 23.0234 746.2 23.0234C753.125 23.0234 758.759 28.6571 758.759 35.5828C758.759 42.5086 753.125 48.1422 746.2 48.1422ZM746.2 24.2047C739.926 24.2047 734.822 29.3092 734.822 35.5828C734.822 41.8564 739.926 46.9609 746.2 46.9609C752.474 46.9609 757.578 41.8564 757.578 35.5828C757.578 29.3092 752.475 24.2047 746.2 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M777.778 48.1422C770.853 48.1422 765.219 42.5077 765.219 35.5828C765.219 28.6571 770.853 23.0234 777.778 23.0234C784.703 23.0234 790.338 28.6571 790.338 35.5828C790.338 42.5086 784.703 48.1422 777.778 48.1422ZM777.778 24.2047C771.505 24.2047 766.4 29.3092 766.4 35.5828C766.4 41.8564 771.505 46.9609 777.778 46.9609C784.052 46.9609 789.156 41.8564 789.156 35.5828C789.156 29.3092 784.053 24.2047 777.778 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M809.348 48.1422C802.424 48.1422 796.789 42.5077 796.789 35.5828C796.789 28.6571 802.424 23.0234 809.348 23.0234C816.273 23.0234 821.908 28.6571 821.908 35.5828C821.908 42.5086 816.273 48.1422 809.348 48.1422ZM809.348 24.2047C803.075 24.2047 797.97 29.3092 797.97 35.5828C797.97 41.8564 803.075 46.9609 809.348 46.9609C815.622 46.9609 820.727 41.8564 820.727 35.5828C820.727 29.3092 815.623 24.2047 809.348 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M840.927 48.1422C834.002 48.1422 828.367 42.5077 828.367 35.5828C828.367 28.6571 834.002 23.0234 840.927 23.0234C847.851 23.0234 853.486 28.6571 853.486 35.5828C853.486 42.5086 847.851 48.1422 840.927 48.1422ZM840.927 24.2047C834.653 24.2047 829.548 29.3092 829.548 35.5828C829.548 41.8564 834.653 46.9609 840.927 46.9609C847.2 46.9609 852.305 41.8564 852.305 35.5828C852.305 29.3092 847.201 24.2047 840.927 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M872.501 48.1422C865.576 48.1422 859.941 42.5077 859.941 35.5828C859.941 28.6571 865.576 23.0234 872.501 23.0234C879.426 23.0234 885.06 28.6571 885.06 35.5828C885.06 42.5086 879.426 48.1422 872.501 48.1422ZM872.501 24.2047C866.227 24.2047 861.123 29.3092 861.123 35.5828C861.123 41.8564 866.227 46.9609 872.501 46.9609C878.774 46.9609 883.879 41.8564 883.879 35.5828C883.879 29.3092 878.775 24.2047 872.501 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M904.075 48.1422C897.15 48.1422 891.516 42.5077 891.516 35.5828C891.516 28.6571 897.15 23.0234 904.075 23.0234C911 23.0234 916.634 28.6571 916.634 35.5828C916.634 42.5086 911 48.1422 904.075 48.1422ZM904.075 24.2047C897.801 24.2047 892.697 29.3092 892.697 35.5828C892.697 41.8564 897.801 46.9609 904.075 46.9609C910.349 46.9609 915.453 41.8564 915.453 35.5828C915.453 29.3092 910.35 24.2047 904.075 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M19.9305 82.5914C13.0056 82.5914 7.37109 76.9569 7.37109 70.032C7.37109 63.1063 13.0056 57.4727 19.9305 57.4727C26.8554 57.4727 32.4899 63.1063 32.4899 70.032C32.4899 76.9578 26.8554 82.5914 19.9305 82.5914ZM19.9305 58.6539C13.6569 58.6539 8.55238 63.7584 8.55238 70.032C8.55238 76.3056 13.6569 81.4101 19.9305 81.4101C26.2041 81.4101 31.3086 76.3056 31.3086 70.032C31.3086 63.7584 26.205 58.6539 19.9305 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M51.5086 82.5914C44.5837 82.5914 38.9492 76.9569 38.9492 70.032C38.9492 63.1063 44.5837 57.4727 51.5086 57.4727C58.4335 57.4727 64.068 63.1063 64.068 70.032C64.068 76.9578 58.4335 82.5914 51.5086 82.5914ZM51.5086 58.6539C45.235 58.6539 40.1305 63.7584 40.1305 70.032C40.1305 76.3056 45.235 81.4101 51.5086 81.4101C57.7822 81.4101 62.8867 76.3056 62.8867 70.032C62.8867 63.7584 57.7831 58.6539 51.5086 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M83.0867 82.5914C76.1618 82.5914 70.5273 76.9569 70.5273 70.032C70.5273 63.1063 76.1618 57.4727 83.0867 57.4727C90.0116 57.4727 95.6461 63.1063 95.6461 70.032C95.6461 76.9578 90.0116 82.5914 83.0867 82.5914ZM83.0867 58.6539C76.8131 58.6539 71.7086 63.7584 71.7086 70.032C71.7086 76.3056 76.8131 81.4101 83.0867 81.4101C89.3603 81.4101 94.4648 76.3056 94.4648 70.032C94.4648 63.7584 89.3612 58.6539 83.0867 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M114.665 82.5914C107.74 82.5914 102.105 76.9569 102.105 70.032C102.105 63.1063 107.74 57.4727 114.665 57.4727C121.59 57.4727 127.224 63.1063 127.224 70.032C127.224 76.9578 121.59 82.5914 114.665 82.5914ZM114.665 58.6539C108.391 58.6539 103.287 63.7584 103.287 70.032C103.287 76.3056 108.391 81.4101 114.665 81.4101C120.938 81.4101 126.043 76.3056 126.043 70.032C126.043 63.7584 120.939 58.6539 114.665 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M146.239 82.5914C139.314 82.5914 133.68 76.9569 133.68 70.032C133.68 63.1063 139.314 57.4727 146.239 57.4727C153.164 57.4727 158.798 63.1063 158.798 70.032C158.798 76.9578 153.164 82.5914 146.239 82.5914ZM146.239 58.6539C139.965 58.6539 134.861 63.7584 134.861 70.032C134.861 76.3056 139.965 81.4101 146.239 81.4101C152.513 81.4101 157.617 76.3056 157.617 70.032C157.617 63.7584 152.514 58.6539 146.239 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M177.825 82.5914C170.9 82.5914 165.266 76.9569 165.266 70.032C165.266 63.1063 170.9 57.4727 177.825 57.4727C184.75 57.4727 190.384 63.1063 190.384 70.032C190.384 76.9578 184.75 82.5914 177.825 82.5914ZM177.825 58.6539C171.551 58.6539 166.447 63.7584 166.447 70.032C166.447 76.3056 171.551 81.4101 177.825 81.4101C184.099 81.4101 189.203 76.3056 189.203 70.032C189.203 63.7584 184.1 58.6539 177.825 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M209.399 82.5914C202.474 82.5914 196.84 76.9569 196.84 70.032C196.84 63.1063 202.474 57.4727 209.399 57.4727C216.324 57.4727 221.959 63.1063 221.959 70.032C221.959 76.9578 216.324 82.5914 209.399 82.5914ZM209.399 58.6539C203.126 58.6539 198.021 63.7584 198.021 70.032C198.021 76.3056 203.126 81.4101 209.399 81.4101C215.673 81.4101 220.777 76.3056 220.777 70.032C220.777 63.7584 215.674 58.6539 209.399 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M240.977 82.5914C234.052 82.5914 228.418 76.9569 228.418 70.032C228.418 63.1063 234.052 57.4727 240.977 57.4727C247.902 57.4727 253.537 63.1063 253.537 70.032C253.537 76.9578 247.902 82.5914 240.977 82.5914ZM240.977 58.6539C234.704 58.6539 229.599 63.7584 229.599 70.032C229.599 76.3056 234.704 81.4101 240.977 81.4101C247.251 81.4101 252.355 76.3056 252.355 70.032C252.355 63.7584 247.252 58.6539 240.977 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M272.555 82.5914C265.631 82.5914 259.996 76.9569 259.996 70.032C259.996 63.1063 265.631 57.4727 272.555 57.4727C279.48 57.4727 285.115 63.1063 285.115 70.032C285.115 76.9578 279.48 82.5914 272.555 82.5914ZM272.555 58.6539C266.282 58.6539 261.177 63.7584 261.177 70.032C261.177 76.3056 266.282 81.4101 272.555 81.4101C278.829 81.4101 283.934 76.3056 283.934 70.032C283.934 63.7584 278.83 58.6539 272.555 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M304.13 82.5914C297.205 82.5914 291.57 76.9569 291.57 70.032C291.57 63.1063 297.205 57.4727 304.13 57.4727C311.055 57.4727 316.689 63.1063 316.689 70.032C316.689 76.9578 311.055 82.5914 304.13 82.5914ZM304.13 58.6539C297.856 58.6539 292.752 63.7584 292.752 70.032C292.752 76.3056 297.856 81.4101 304.13 81.4101C310.403 81.4101 315.508 76.3056 315.508 70.032C315.508 63.7584 310.404 58.6539 304.13 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M335.712 82.5914C328.787 82.5914 323.152 76.9569 323.152 70.032C323.152 63.1063 328.787 57.4727 335.712 57.4727C342.637 57.4727 348.271 63.1063 348.271 70.032C348.271 76.9578 342.637 82.5914 335.712 82.5914ZM335.712 58.6539C329.438 58.6539 324.334 63.7584 324.334 70.032C324.334 76.3056 329.438 81.4101 335.712 81.4101C341.985 81.4101 347.09 76.3056 347.09 70.032C347.09 63.7584 341.986 58.6539 335.712 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M367.286 82.5914C360.361 82.5914 354.727 76.9569 354.727 70.032C354.727 63.1063 360.361 57.4727 367.286 57.4727C374.211 57.4727 379.845 63.1063 379.845 70.032C379.845 76.9578 374.211 82.5914 367.286 82.5914ZM367.286 58.6539C361.012 58.6539 355.908 63.7584 355.908 70.032C355.908 76.3056 361.012 81.4101 367.286 81.4101C373.56 81.4101 378.664 76.3056 378.664 70.032C378.664 63.7584 373.56 58.6539 367.286 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M398.864 82.5914C391.939 82.5914 386.305 76.9569 386.305 70.032C386.305 63.1063 391.939 57.4727 398.864 57.4727C405.789 57.4727 411.423 63.1063 411.423 70.032C411.423 76.9578 405.789 82.5914 398.864 82.5914ZM398.864 58.6539C392.59 58.6539 387.486 63.7584 387.486 70.032C387.486 76.3056 392.59 81.4101 398.864 81.4101C405.138 81.4101 410.242 76.3056 410.242 70.032C410.242 63.7584 405.139 58.6539 398.864 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M430.446 82.5914C423.521 82.5914 417.887 76.9569 417.887 70.032C417.887 63.1063 423.521 57.4727 430.446 57.4727C437.371 57.4727 443.006 63.1063 443.006 70.032C443.006 76.9578 437.371 82.5914 430.446 82.5914ZM430.446 58.6539C424.172 58.6539 419.068 63.7584 419.068 70.032C419.068 76.3056 424.172 81.4101 430.446 81.4101C436.72 81.4101 441.824 76.3056 441.824 70.032C441.824 63.7584 436.721 58.6539 430.446 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M462.016 82.5914C455.092 82.5914 449.457 76.9569 449.457 70.032C449.457 63.1063 455.092 57.4727 462.016 57.4727C468.941 57.4727 474.576 63.1063 474.576 70.032C474.576 76.9578 468.941 82.5914 462.016 82.5914ZM462.016 58.6539C455.743 58.6539 450.638 63.7584 450.638 70.032C450.638 76.3056 455.743 81.4101 462.016 81.4101C468.29 81.4101 473.395 76.3056 473.395 70.032C473.395 63.7584 468.291 58.6539 462.016 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M493.595 82.5914C486.67 82.5914 481.035 76.9569 481.035 70.032C481.035 63.1063 486.67 57.4727 493.595 57.4727C500.519 57.4727 506.154 63.1063 506.154 70.032C506.154 76.9578 500.519 82.5914 493.595 82.5914ZM493.595 58.6539C487.321 58.6539 482.216 63.7584 482.216 70.032C482.216 76.3056 487.321 81.4101 493.595 81.4101C499.868 81.4101 504.973 76.3056 504.973 70.032C504.973 63.7584 499.869 58.6539 493.595 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M525.169 82.5914C518.244 82.5914 512.609 76.9569 512.609 70.032C512.609 63.1063 518.244 57.4727 525.169 57.4727C532.094 57.4727 537.728 63.1063 537.728 70.032C537.728 76.9578 532.094 82.5914 525.169 82.5914ZM525.169 58.6539C518.895 58.6539 513.791 63.7584 513.791 70.032C513.791 76.3056 518.895 81.4101 525.169 81.4101C531.442 81.4101 536.547 76.3056 536.547 70.032C536.547 63.7584 531.443 58.6539 525.169 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M556.743 82.5914C549.818 82.5914 544.184 76.9569 544.184 70.032C544.184 63.1063 549.818 57.4727 556.743 57.4727C563.668 57.4727 569.302 63.1063 569.302 70.032C569.302 76.9578 563.668 82.5914 556.743 82.5914ZM556.743 58.6539C550.469 58.6539 545.365 63.7584 545.365 70.032C545.365 76.3056 550.469 81.4101 556.743 81.4101C563.017 81.4101 568.121 76.3056 568.121 70.032C568.121 63.7584 563.017 58.6539 556.743 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M588.321 82.5914C581.396 82.5914 575.762 76.9569 575.762 70.032C575.762 63.1063 581.396 57.4727 588.321 57.4727C595.246 57.4727 600.881 63.1063 600.881 70.032C600.881 76.9578 595.246 82.5914 588.321 82.5914ZM588.321 58.6539C582.047 58.6539 576.943 63.7584 576.943 70.032C576.943 76.3056 582.047 81.4101 588.321 81.4101C594.595 81.4101 599.699 76.3056 599.699 70.032C599.699 63.7584 594.596 58.6539 588.321 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M619.895 82.5914C612.97 82.5914 607.336 76.9569 607.336 70.032C607.336 63.1063 612.97 57.4727 619.895 57.4727C626.82 57.4727 632.455 63.1063 632.455 70.032C632.455 76.9578 626.82 82.5914 619.895 82.5914ZM619.895 58.6539C613.622 58.6539 608.517 63.7584 608.517 70.032C608.517 76.3056 613.622 81.4101 619.895 81.4101C626.169 81.4101 631.273 76.3056 631.273 70.032C631.273 63.7584 626.17 58.6539 619.895 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M651.47 82.5914C644.545 82.5914 638.91 76.9569 638.91 70.032C638.91 63.1063 644.545 57.4727 651.47 57.4727C658.394 57.4727 664.029 63.1063 664.029 70.032C664.029 76.9578 658.394 82.5914 651.47 82.5914ZM651.47 58.6539C645.196 58.6539 640.091 63.7584 640.091 70.032C640.091 76.3056 645.196 81.4101 651.47 81.4101C657.743 81.4101 662.848 76.3056 662.848 70.032C662.848 63.7584 657.744 58.6539 651.47 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M683.052 82.5914C676.127 82.5914 670.492 76.9569 670.492 70.032C670.492 63.1063 676.127 57.4727 683.052 57.4727C689.976 57.4727 695.611 63.1063 695.611 70.032C695.611 76.9578 689.976 82.5914 683.052 82.5914ZM683.052 58.6539C676.778 58.6539 671.673 63.7584 671.673 70.032C671.673 76.3056 676.778 81.4101 683.052 81.4101C689.325 81.4101 694.43 76.3056 694.43 70.032C694.43 63.7584 689.326 58.6539 683.052 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M714.622 82.5914C707.697 82.5914 702.062 76.9569 702.062 70.032C702.062 63.1063 707.697 57.4727 714.622 57.4727C721.547 57.4727 727.181 63.1063 727.181 70.032C727.181 76.9578 721.547 82.5914 714.622 82.5914ZM714.622 58.6539C708.348 58.6539 703.244 63.7584 703.244 70.032C703.244 76.3056 708.348 81.4101 714.622 81.4101C720.895 81.4101 726 76.3056 726 70.032C726 63.7584 720.896 58.6539 714.622 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M746.2 82.5914C739.275 82.5914 733.641 76.9569 733.641 70.032C733.641 63.1063 739.275 57.4727 746.2 57.4727C753.125 57.4727 758.759 63.1063 758.759 70.032C758.759 76.9578 753.125 82.5914 746.2 82.5914ZM746.2 58.6539C739.926 58.6539 734.822 63.7584 734.822 70.032C734.822 76.3056 739.926 81.4101 746.2 81.4101C752.474 81.4101 757.578 76.3056 757.578 70.032C757.578 63.7584 752.475 58.6539 746.2 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M777.778 82.5914C770.853 82.5914 765.219 76.9569 765.219 70.032C765.219 63.1063 770.853 57.4727 777.778 57.4727C784.703 57.4727 790.338 63.1063 790.338 70.032C790.338 76.9578 784.703 82.5914 777.778 82.5914ZM777.778 58.6539C771.505 58.6539 766.4 63.7584 766.4 70.032C766.4 76.3056 771.505 81.4101 777.778 81.4101C784.052 81.4101 789.156 76.3056 789.156 70.032C789.156 63.7584 784.053 58.6539 777.778 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M809.348 82.5914C802.424 82.5914 796.789 76.9569 796.789 70.032C796.789 63.1063 802.424 57.4727 809.348 57.4727C816.273 57.4727 821.908 63.1063 821.908 70.032C821.908 76.9578 816.273 82.5914 809.348 82.5914ZM809.348 58.6539C803.075 58.6539 797.97 63.7584 797.97 70.032C797.97 76.3056 803.075 81.4101 809.348 81.4101C815.622 81.4101 820.727 76.3056 820.727 70.032C820.727 63.7584 815.623 58.6539 809.348 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M840.927 82.5914C834.002 82.5914 828.367 76.9569 828.367 70.032C828.367 63.1063 834.002 57.4727 840.927 57.4727C847.851 57.4727 853.486 63.1063 853.486 70.032C853.486 76.9578 847.851 82.5914 840.927 82.5914ZM840.927 58.6539C834.653 58.6539 829.548 63.7584 829.548 70.032C829.548 76.3056 834.653 81.4101 840.927 81.4101C847.2 81.4101 852.305 76.3056 852.305 70.032C852.305 63.7584 847.201 58.6539 840.927 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M872.501 82.5914C865.576 82.5914 859.941 76.9569 859.941 70.032C859.941 63.1063 865.576 57.4727 872.501 57.4727C879.426 57.4727 885.06 63.1063 885.06 70.032C885.06 76.9578 879.426 82.5914 872.501 82.5914ZM872.501 58.6539C866.227 58.6539 861.123 63.7584 861.123 70.032C861.123 76.3056 866.227 81.4101 872.501 81.4101C878.774 81.4101 883.879 76.3056 883.879 70.032C883.879 63.7584 878.775 58.6539 872.501 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M904.075 82.5914C897.15 82.5914 891.516 76.9569 891.516 70.032C891.516 63.1063 897.15 57.4727 904.075 57.4727C911 57.4727 916.634 63.1063 916.634 70.032C916.634 76.9578 911 82.5914 904.075 82.5914ZM904.075 58.6539C897.801 58.6539 892.697 63.7584 892.697 70.032C892.697 76.3056 897.801 81.4101 904.075 81.4101C910.349 81.4101 915.453 76.3056 915.453 70.032C915.453 63.7584 910.35 58.6539 904.075 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M19.9305 117.035C13.0056 117.035 7.37109 111.4 7.37109 104.475C7.37109 97.5496 13.0056 91.916 19.9305 91.916C26.8554 91.916 32.4899 97.5496 32.4899 104.475C32.4899 111.401 26.8554 117.035 19.9305 117.035ZM19.9305 93.0973C13.6569 93.0973 8.55238 98.2018 8.55238 104.475C8.55238 110.749 13.6569 115.853 19.9305 115.853C26.2041 115.853 31.3086 110.749 31.3086 104.475C31.3086 98.2018 26.205 93.0973 19.9305 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M51.5086 117.035C44.5837 117.035 38.9492 111.4 38.9492 104.475C38.9492 97.5496 44.5837 91.916 51.5086 91.916C58.4335 91.916 64.068 97.5496 64.068 104.475C64.068 111.401 58.4335 117.035 51.5086 117.035ZM51.5086 93.0973C45.235 93.0973 40.1305 98.2018 40.1305 104.475C40.1305 110.749 45.235 115.853 51.5086 115.853C57.7822 115.853 62.8867 110.749 62.8867 104.475C62.8867 98.2018 57.7831 93.0973 51.5086 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M83.0867 117.035C76.1618 117.035 70.5273 111.4 70.5273 104.475C70.5273 97.5496 76.1618 91.916 83.0867 91.916C90.0116 91.916 95.6461 97.5496 95.6461 104.475C95.6461 111.401 90.0116 117.035 83.0867 117.035ZM83.0867 93.0973C76.8131 93.0973 71.7086 98.2018 71.7086 104.475C71.7086 110.749 76.8131 115.853 83.0867 115.853C89.3603 115.853 94.4648 110.749 94.4648 104.475C94.4648 98.2018 89.3612 93.0973 83.0867 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M114.665 117.035C107.74 117.035 102.105 111.4 102.105 104.475C102.105 97.5496 107.74 91.916 114.665 91.916C121.59 91.916 127.224 97.5496 127.224 104.475C127.224 111.401 121.59 117.035 114.665 117.035ZM114.665 93.0973C108.391 93.0973 103.287 98.2018 103.287 104.475C103.287 110.749 108.391 115.853 114.665 115.853C120.938 115.853 126.043 110.749 126.043 104.475C126.043 98.2018 120.939 93.0973 114.665 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M146.239 117.035C139.314 117.035 133.68 111.4 133.68 104.475C133.68 97.5496 139.314 91.916 146.239 91.916C153.164 91.916 158.798 97.5496 158.798 104.475C158.798 111.401 153.164 117.035 146.239 117.035ZM146.239 93.0973C139.965 93.0973 134.861 98.2018 134.861 104.475C134.861 110.749 139.965 115.853 146.239 115.853C152.513 115.853 157.617 110.749 157.617 104.475C157.617 98.2018 152.514 93.0973 146.239 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M177.825 117.035C170.9 117.035 165.266 111.4 165.266 104.475C165.266 97.5496 170.9 91.916 177.825 91.916C184.75 91.916 190.384 97.5496 190.384 104.475C190.384 111.401 184.75 117.035 177.825 117.035ZM177.825 93.0973C171.551 93.0973 166.447 98.2018 166.447 104.475C166.447 110.749 171.551 115.853 177.825 115.853C184.099 115.853 189.203 110.749 189.203 104.475C189.203 98.2018 184.1 93.0973 177.825 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M209.399 117.035C202.474 117.035 196.84 111.4 196.84 104.475C196.84 97.5496 202.474 91.916 209.399 91.916C216.324 91.916 221.959 97.5496 221.959 104.475C221.959 111.401 216.324 117.035 209.399 117.035ZM209.399 93.0973C203.126 93.0973 198.021 98.2018 198.021 104.475C198.021 110.749 203.126 115.853 209.399 115.853C215.673 115.853 220.777 110.749 220.777 104.475C220.777 98.2018 215.674 93.0973 209.399 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M240.977 117.035C234.052 117.035 228.418 111.4 228.418 104.475C228.418 97.5496 234.052 91.916 240.977 91.916C247.902 91.916 253.537 97.5496 253.537 104.475C253.537 111.401 247.902 117.035 240.977 117.035ZM240.977 93.0973C234.704 93.0973 229.599 98.2018 229.599 104.475C229.599 110.749 234.704 115.853 240.977 115.853C247.251 115.853 252.355 110.749 252.355 104.475C252.355 98.2018 247.252 93.0973 240.977 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M272.555 117.035C265.631 117.035 259.996 111.4 259.996 104.475C259.996 97.5496 265.631 91.916 272.555 91.916C279.48 91.916 285.115 97.5496 285.115 104.475C285.115 111.401 279.48 117.035 272.555 117.035ZM272.555 93.0973C266.282 93.0973 261.177 98.2018 261.177 104.475C261.177 110.749 266.282 115.853 272.555 115.853C278.829 115.853 283.934 110.749 283.934 104.475C283.934 98.2018 278.83 93.0973 272.555 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M304.13 117.035C297.205 117.035 291.57 111.4 291.57 104.475C291.57 97.5496 297.205 91.916 304.13 91.916C311.055 91.916 316.689 97.5496 316.689 104.475C316.689 111.401 311.055 117.035 304.13 117.035ZM304.13 93.0973C297.856 93.0973 292.752 98.2018 292.752 104.475C292.752 110.749 297.856 115.853 304.13 115.853C310.403 115.853 315.508 110.749 315.508 104.475C315.508 98.2018 310.404 93.0973 304.13 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M335.712 117.035C328.787 117.035 323.152 111.4 323.152 104.475C323.152 97.5496 328.787 91.916 335.712 91.916C342.637 91.916 348.271 97.5496 348.271 104.475C348.271 111.401 342.637 117.035 335.712 117.035ZM335.712 93.0973C329.438 93.0973 324.334 98.2018 324.334 104.475C324.334 110.749 329.438 115.853 335.712 115.853C341.985 115.853 347.09 110.749 347.09 104.475C347.09 98.2018 341.986 93.0973 335.712 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M367.286 117.035C360.361 117.035 354.727 111.4 354.727 104.475C354.727 97.5496 360.361 91.916 367.286 91.916C374.211 91.916 379.845 97.5496 379.845 104.475C379.845 111.401 374.211 117.035 367.286 117.035ZM367.286 93.0973C361.012 93.0973 355.908 98.2018 355.908 104.475C355.908 110.749 361.012 115.853 367.286 115.853C373.56 115.853 378.664 110.749 378.664 104.475C378.664 98.2018 373.56 93.0973 367.286 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M398.864 117.035C391.939 117.035 386.305 111.4 386.305 104.475C386.305 97.5496 391.939 91.916 398.864 91.916C405.789 91.916 411.423 97.5496 411.423 104.475C411.423 111.401 405.789 117.035 398.864 117.035ZM398.864 93.0973C392.59 93.0973 387.486 98.2018 387.486 104.475C387.486 110.749 392.59 115.853 398.864 115.853C405.138 115.853 410.242 110.749 410.242 104.475C410.242 98.2018 405.139 93.0973 398.864 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M430.446 117.035C423.521 117.035 417.887 111.4 417.887 104.475C417.887 97.5496 423.521 91.916 430.446 91.916C437.371 91.916 443.006 97.5496 443.006 104.475C443.006 111.401 437.371 117.035 430.446 117.035ZM430.446 93.0973C424.172 93.0973 419.068 98.2018 419.068 104.475C419.068 110.749 424.172 115.853 430.446 115.853C436.72 115.853 441.824 110.749 441.824 104.475C441.824 98.2018 436.721 93.0973 430.446 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M462.016 117.035C455.092 117.035 449.457 111.4 449.457 104.475C449.457 97.5496 455.092 91.916 462.016 91.916C468.941 91.916 474.576 97.5496 474.576 104.475C474.576 111.401 468.941 117.035 462.016 117.035ZM462.016 93.0973C455.743 93.0973 450.638 98.2018 450.638 104.475C450.638 110.749 455.743 115.853 462.016 115.853C468.29 115.853 473.395 110.749 473.395 104.475C473.395 98.2018 468.291 93.0973 462.016 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M493.595 117.035C486.67 117.035 481.035 111.4 481.035 104.475C481.035 97.5496 486.67 91.916 493.595 91.916C500.519 91.916 506.154 97.5496 506.154 104.475C506.154 111.401 500.519 117.035 493.595 117.035ZM493.595 93.0973C487.321 93.0973 482.216 98.2018 482.216 104.475C482.216 110.749 487.321 115.853 493.595 115.853C499.868 115.853 504.973 110.749 504.973 104.475C504.973 98.2018 499.869 93.0973 493.595 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M525.169 117.035C518.244 117.035 512.609 111.4 512.609 104.475C512.609 97.5496 518.244 91.916 525.169 91.916C532.094 91.916 537.728 97.5496 537.728 104.475C537.728 111.401 532.094 117.035 525.169 117.035ZM525.169 93.0973C518.895 93.0973 513.791 98.2018 513.791 104.475C513.791 110.749 518.895 115.853 525.169 115.853C531.442 115.853 536.547 110.749 536.547 104.475C536.547 98.2018 531.443 93.0973 525.169 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M556.743 117.035C549.818 117.035 544.184 111.4 544.184 104.475C544.184 97.5496 549.818 91.916 556.743 91.916C563.668 91.916 569.302 97.5496 569.302 104.475C569.302 111.401 563.668 117.035 556.743 117.035ZM556.743 93.0973C550.469 93.0973 545.365 98.2018 545.365 104.475C545.365 110.749 550.469 115.853 556.743 115.853C563.017 115.853 568.121 110.749 568.121 104.475C568.121 98.2018 563.017 93.0973 556.743 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M588.321 117.035C581.396 117.035 575.762 111.4 575.762 104.475C575.762 97.5496 581.396 91.916 588.321 91.916C595.246 91.916 600.881 97.5496 600.881 104.475C600.881 111.401 595.246 117.035 588.321 117.035ZM588.321 93.0973C582.047 93.0973 576.943 98.2018 576.943 104.475C576.943 110.749 582.047 115.853 588.321 115.853C594.595 115.853 599.699 110.749 599.699 104.475C599.699 98.2018 594.596 93.0973 588.321 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M619.895 117.035C612.97 117.035 607.336 111.4 607.336 104.475C607.336 97.5496 612.97 91.916 619.895 91.916C626.82 91.916 632.455 97.5496 632.455 104.475C632.455 111.401 626.82 117.035 619.895 117.035ZM619.895 93.0973C613.622 93.0973 608.517 98.2018 608.517 104.475C608.517 110.749 613.622 115.853 619.895 115.853C626.169 115.853 631.273 110.749 631.273 104.475C631.273 98.2018 626.17 93.0973 619.895 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M651.47 117.035C644.545 117.035 638.91 111.4 638.91 104.475C638.91 97.5496 644.545 91.916 651.47 91.916C658.394 91.916 664.029 97.5496 664.029 104.475C664.029 111.401 658.394 117.035 651.47 117.035ZM651.47 93.0973C645.196 93.0973 640.091 98.2018 640.091 104.475C640.091 110.749 645.196 115.853 651.47 115.853C657.743 115.853 662.848 110.749 662.848 104.475C662.848 98.2018 657.744 93.0973 651.47 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M683.052 117.035C676.127 117.035 670.492 111.4 670.492 104.475C670.492 97.5496 676.127 91.916 683.052 91.916C689.976 91.916 695.611 97.5496 695.611 104.475C695.611 111.401 689.976 117.035 683.052 117.035ZM683.052 93.0973C676.778 93.0973 671.673 98.2018 671.673 104.475C671.673 110.749 676.778 115.853 683.052 115.853C689.325 115.853 694.43 110.749 694.43 104.475C694.43 98.2018 689.326 93.0973 683.052 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M714.622 117.035C707.697 117.035 702.062 111.4 702.062 104.475C702.062 97.5496 707.697 91.916 714.622 91.916C721.547 91.916 727.181 97.5496 727.181 104.475C727.181 111.401 721.547 117.035 714.622 117.035ZM714.622 93.0973C708.348 93.0973 703.244 98.2018 703.244 104.475C703.244 110.749 708.348 115.853 714.622 115.853C720.895 115.853 726 110.749 726 104.475C726 98.2018 720.896 93.0973 714.622 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M746.2 117.035C739.275 117.035 733.641 111.4 733.641 104.475C733.641 97.5496 739.275 91.916 746.2 91.916C753.125 91.916 758.759 97.5496 758.759 104.475C758.759 111.401 753.125 117.035 746.2 117.035ZM746.2 93.0973C739.926 93.0973 734.822 98.2018 734.822 104.475C734.822 110.749 739.926 115.853 746.2 115.853C752.474 115.853 757.578 110.749 757.578 104.475C757.578 98.2018 752.475 93.0973 746.2 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M777.778 117.035C770.853 117.035 765.219 111.4 765.219 104.475C765.219 97.5496 770.853 91.916 777.778 91.916C784.703 91.916 790.338 97.5496 790.338 104.475C790.338 111.401 784.703 117.035 777.778 117.035ZM777.778 93.0973C771.505 93.0973 766.4 98.2018 766.4 104.475C766.4 110.749 771.505 115.853 777.778 115.853C784.052 115.853 789.156 110.749 789.156 104.475C789.156 98.2018 784.053 93.0973 777.778 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M809.348 117.035C802.424 117.035 796.789 111.4 796.789 104.475C796.789 97.5496 802.424 91.916 809.348 91.916C816.273 91.916 821.908 97.5496 821.908 104.475C821.908 111.401 816.273 117.035 809.348 117.035ZM809.348 93.0973C803.075 93.0973 797.97 98.2018 797.97 104.475C797.97 110.749 803.075 115.853 809.348 115.853C815.622 115.853 820.727 110.749 820.727 104.475C820.727 98.2018 815.623 93.0973 809.348 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M840.927 117.035C834.002 117.035 828.367 111.4 828.367 104.475C828.367 97.5496 834.002 91.916 840.927 91.916C847.851 91.916 853.486 97.5496 853.486 104.475C853.486 111.401 847.851 117.035 840.927 117.035ZM840.927 93.0973C834.653 93.0973 829.548 98.2018 829.548 104.475C829.548 110.749 834.653 115.853 840.927 115.853C847.2 115.853 852.305 110.749 852.305 104.475C852.305 98.2018 847.201 93.0973 840.927 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M872.501 117.035C865.576 117.035 859.941 111.4 859.941 104.475C859.941 97.5496 865.576 91.916 872.501 91.916C879.426 91.916 885.06 97.5496 885.06 104.475C885.06 111.401 879.426 117.035 872.501 117.035ZM872.501 93.0973C866.227 93.0973 861.123 98.2018 861.123 104.475C861.123 110.749 866.227 115.853 872.501 115.853C878.774 115.853 883.879 110.749 883.879 104.475C883.879 98.2018 878.775 93.0973 872.501 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M904.075 117.035C897.15 117.035 891.516 111.4 891.516 104.475C891.516 97.5496 897.15 91.916 904.075 91.916C911 91.916 916.634 97.5496 916.634 104.475C916.634 111.401 911 117.035 904.075 117.035ZM904.075 93.0973C897.801 93.0973 892.697 98.2018 892.697 104.475C892.697 110.749 897.801 115.853 904.075 115.853C910.349 115.853 915.453 110.749 915.453 104.475C915.453 98.2018 910.35 93.0973 904.075 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M19.9305 151.486C13.0056 151.486 7.37109 145.851 7.37109 138.927C7.37109 132.001 13.0056 126.367 19.9305 126.367C26.8554 126.367 32.4899 132.001 32.4899 138.927C32.4899 145.852 26.8554 151.486 19.9305 151.486ZM19.9305 127.548C13.6569 127.548 8.55238 132.653 8.55238 138.927C8.55238 145.2 13.6569 150.305 19.9305 150.305C26.2041 150.305 31.3086 145.2 31.3086 138.927C31.3086 132.653 26.205 127.548 19.9305 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M51.5086 151.486C44.5837 151.486 38.9492 145.851 38.9492 138.927C38.9492 132.001 44.5837 126.367 51.5086 126.367C58.4335 126.367 64.068 132.001 64.068 138.927C64.068 145.852 58.4335 151.486 51.5086 151.486ZM51.5086 127.548C45.235 127.548 40.1305 132.653 40.1305 138.927C40.1305 145.2 45.235 150.305 51.5086 150.305C57.7822 150.305 62.8867 145.2 62.8867 138.927C62.8867 132.653 57.7831 127.548 51.5086 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M83.0867 151.486C76.1618 151.486 70.5273 145.851 70.5273 138.927C70.5273 132.001 76.1618 126.367 83.0867 126.367C90.0116 126.367 95.6461 132.001 95.6461 138.927C95.6461 145.852 90.0116 151.486 83.0867 151.486ZM83.0867 127.548C76.8131 127.548 71.7086 132.653 71.7086 138.927C71.7086 145.2 76.8131 150.305 83.0867 150.305C89.3603 150.305 94.4648 145.2 94.4648 138.927C94.4648 132.653 89.3612 127.548 83.0867 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M114.665 151.486C107.74 151.486 102.105 145.851 102.105 138.927C102.105 132.001 107.74 126.367 114.665 126.367C121.59 126.367 127.224 132.001 127.224 138.927C127.224 145.852 121.59 151.486 114.665 151.486ZM114.665 127.548C108.391 127.548 103.287 132.653 103.287 138.927C103.287 145.2 108.391 150.305 114.665 150.305C120.938 150.305 126.043 145.2 126.043 138.927C126.043 132.653 120.939 127.548 114.665 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M146.239 151.486C139.314 151.486 133.68 145.851 133.68 138.927C133.68 132.001 139.314 126.367 146.239 126.367C153.164 126.367 158.798 132.001 158.798 138.927C158.798 145.852 153.164 151.486 146.239 151.486ZM146.239 127.548C139.965 127.548 134.861 132.653 134.861 138.927C134.861 145.2 139.965 150.305 146.239 150.305C152.513 150.305 157.617 145.2 157.617 138.927C157.617 132.653 152.514 127.548 146.239 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M177.825 151.486C170.9 151.486 165.266 145.851 165.266 138.927C165.266 132.001 170.9 126.367 177.825 126.367C184.75 126.367 190.384 132.001 190.384 138.927C190.384 145.852 184.75 151.486 177.825 151.486ZM177.825 127.548C171.551 127.548 166.447 132.653 166.447 138.927C166.447 145.2 171.551 150.305 177.825 150.305C184.099 150.305 189.203 145.2 189.203 138.927C189.203 132.653 184.1 127.548 177.825 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M209.399 151.486C202.474 151.486 196.84 145.851 196.84 138.927C196.84 132.001 202.474 126.367 209.399 126.367C216.324 126.367 221.959 132.001 221.959 138.927C221.959 145.852 216.324 151.486 209.399 151.486ZM209.399 127.548C203.126 127.548 198.021 132.653 198.021 138.927C198.021 145.2 203.126 150.305 209.399 150.305C215.673 150.305 220.777 145.2 220.777 138.927C220.777 132.653 215.674 127.548 209.399 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M240.977 151.486C234.052 151.486 228.418 145.851 228.418 138.927C228.418 132.001 234.052 126.367 240.977 126.367C247.902 126.367 253.537 132.001 253.537 138.927C253.537 145.852 247.902 151.486 240.977 151.486ZM240.977 127.548C234.704 127.548 229.599 132.653 229.599 138.927C229.599 145.2 234.704 150.305 240.977 150.305C247.251 150.305 252.355 145.2 252.355 138.927C252.355 132.653 247.252 127.548 240.977 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M272.555 151.486C265.631 151.486 259.996 145.851 259.996 138.927C259.996 132.001 265.631 126.367 272.555 126.367C279.48 126.367 285.115 132.001 285.115 138.927C285.115 145.852 279.48 151.486 272.555 151.486ZM272.555 127.548C266.282 127.548 261.177 132.653 261.177 138.927C261.177 145.2 266.282 150.305 272.555 150.305C278.829 150.305 283.934 145.2 283.934 138.927C283.934 132.653 278.83 127.548 272.555 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M304.13 151.486C297.205 151.486 291.57 145.851 291.57 138.927C291.57 132.001 297.205 126.367 304.13 126.367C311.055 126.367 316.689 132.001 316.689 138.927C316.689 145.852 311.055 151.486 304.13 151.486ZM304.13 127.548C297.856 127.548 292.752 132.653 292.752 138.927C292.752 145.2 297.856 150.305 304.13 150.305C310.403 150.305 315.508 145.2 315.508 138.927C315.508 132.653 310.404 127.548 304.13 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M335.712 151.486C328.787 151.486 323.152 145.851 323.152 138.927C323.152 132.001 328.787 126.367 335.712 126.367C342.637 126.367 348.271 132.001 348.271 138.927C348.271 145.852 342.637 151.486 335.712 151.486ZM335.712 127.548C329.438 127.548 324.334 132.653 324.334 138.927C324.334 145.2 329.438 150.305 335.712 150.305C341.985 150.305 347.09 145.2 347.09 138.927C347.09 132.653 341.986 127.548 335.712 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M367.286 151.486C360.361 151.486 354.727 145.851 354.727 138.927C354.727 132.001 360.361 126.367 367.286 126.367C374.211 126.367 379.845 132.001 379.845 138.927C379.845 145.852 374.211 151.486 367.286 151.486ZM367.286 127.548C361.012 127.548 355.908 132.653 355.908 138.927C355.908 145.2 361.012 150.305 367.286 150.305C373.56 150.305 378.664 145.2 378.664 138.927C378.664 132.653 373.56 127.548 367.286 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M398.864 151.486C391.939 151.486 386.305 145.851 386.305 138.927C386.305 132.001 391.939 126.367 398.864 126.367C405.789 126.367 411.423 132.001 411.423 138.927C411.423 145.852 405.789 151.486 398.864 151.486ZM398.864 127.548C392.59 127.548 387.486 132.653 387.486 138.927C387.486 145.2 392.59 150.305 398.864 150.305C405.138 150.305 410.242 145.2 410.242 138.927C410.242 132.653 405.139 127.548 398.864 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M430.446 151.486C423.521 151.486 417.887 145.851 417.887 138.927C417.887 132.001 423.521 126.367 430.446 126.367C437.371 126.367 443.006 132.001 443.006 138.927C443.006 145.852 437.371 151.486 430.446 151.486ZM430.446 127.548C424.172 127.548 419.068 132.653 419.068 138.927C419.068 145.2 424.172 150.305 430.446 150.305C436.72 150.305 441.824 145.2 441.824 138.927C441.824 132.653 436.721 127.548 430.446 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M462.016 151.486C455.092 151.486 449.457 145.851 449.457 138.927C449.457 132.001 455.092 126.367 462.016 126.367C468.941 126.367 474.576 132.001 474.576 138.927C474.576 145.852 468.941 151.486 462.016 151.486ZM462.016 127.548C455.743 127.548 450.638 132.653 450.638 138.927C450.638 145.2 455.743 150.305 462.016 150.305C468.29 150.305 473.395 145.2 473.395 138.927C473.395 132.653 468.291 127.548 462.016 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M493.595 151.486C486.67 151.486 481.035 145.851 481.035 138.927C481.035 132.001 486.67 126.367 493.595 126.367C500.519 126.367 506.154 132.001 506.154 138.927C506.154 145.852 500.519 151.486 493.595 151.486ZM493.595 127.548C487.321 127.548 482.216 132.653 482.216 138.927C482.216 145.2 487.321 150.305 493.595 150.305C499.868 150.305 504.973 145.2 504.973 138.927C504.973 132.653 499.869 127.548 493.595 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M525.169 151.486C518.244 151.486 512.609 145.851 512.609 138.927C512.609 132.001 518.244 126.367 525.169 126.367C532.094 126.367 537.728 132.001 537.728 138.927C537.728 145.852 532.094 151.486 525.169 151.486ZM525.169 127.548C518.895 127.548 513.791 132.653 513.791 138.927C513.791 145.2 518.895 150.305 525.169 150.305C531.442 150.305 536.547 145.2 536.547 138.927C536.547 132.653 531.443 127.548 525.169 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M556.743 151.486C549.818 151.486 544.184 145.851 544.184 138.927C544.184 132.001 549.818 126.367 556.743 126.367C563.668 126.367 569.302 132.001 569.302 138.927C569.302 145.852 563.668 151.486 556.743 151.486ZM556.743 127.548C550.469 127.548 545.365 132.653 545.365 138.927C545.365 145.2 550.469 150.305 556.743 150.305C563.017 150.305 568.121 145.2 568.121 138.927C568.121 132.653 563.017 127.548 556.743 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M588.321 151.486C581.396 151.486 575.762 145.851 575.762 138.927C575.762 132.001 581.396 126.367 588.321 126.367C595.246 126.367 600.881 132.001 600.881 138.927C600.881 145.852 595.246 151.486 588.321 151.486ZM588.321 127.548C582.047 127.548 576.943 132.653 576.943 138.927C576.943 145.2 582.047 150.305 588.321 150.305C594.595 150.305 599.699 145.2 599.699 138.927C599.699 132.653 594.596 127.548 588.321 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M619.895 151.486C612.97 151.486 607.336 145.851 607.336 138.927C607.336 132.001 612.97 126.367 619.895 126.367C626.82 126.367 632.455 132.001 632.455 138.927C632.455 145.852 626.82 151.486 619.895 151.486ZM619.895 127.548C613.622 127.548 608.517 132.653 608.517 138.927C608.517 145.2 613.622 150.305 619.895 150.305C626.169 150.305 631.273 145.2 631.273 138.927C631.273 132.653 626.17 127.548 619.895 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M651.47 151.486C644.545 151.486 638.91 145.851 638.91 138.927C638.91 132.001 644.545 126.367 651.47 126.367C658.394 126.367 664.029 132.001 664.029 138.927C664.029 145.852 658.394 151.486 651.47 151.486ZM651.47 127.548C645.196 127.548 640.091 132.653 640.091 138.927C640.091 145.2 645.196 150.305 651.47 150.305C657.743 150.305 662.848 145.2 662.848 138.927C662.848 132.653 657.744 127.548 651.47 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M683.052 151.486C676.127 151.486 670.492 145.851 670.492 138.927C670.492 132.001 676.127 126.367 683.052 126.367C689.976 126.367 695.611 132.001 695.611 138.927C695.611 145.852 689.976 151.486 683.052 151.486ZM683.052 127.548C676.778 127.548 671.673 132.653 671.673 138.927C671.673 145.2 676.778 150.305 683.052 150.305C689.325 150.305 694.43 145.2 694.43 138.927C694.43 132.653 689.326 127.548 683.052 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M714.622 151.486C707.697 151.486 702.062 145.851 702.062 138.927C702.062 132.001 707.697 126.367 714.622 126.367C721.547 126.367 727.181 132.001 727.181 138.927C727.181 145.852 721.547 151.486 714.622 151.486ZM714.622 127.548C708.348 127.548 703.244 132.653 703.244 138.927C703.244 145.2 708.348 150.305 714.622 150.305C720.895 150.305 726 145.2 726 138.927C726 132.653 720.896 127.548 714.622 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M746.2 151.486C739.275 151.486 733.641 145.851 733.641 138.927C733.641 132.001 739.275 126.367 746.2 126.367C753.125 126.367 758.759 132.001 758.759 138.927C758.759 145.852 753.125 151.486 746.2 151.486ZM746.2 127.548C739.926 127.548 734.822 132.653 734.822 138.927C734.822 145.2 739.926 150.305 746.2 150.305C752.474 150.305 757.578 145.2 757.578 138.927C757.578 132.653 752.475 127.548 746.2 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M777.778 151.486C770.853 151.486 765.219 145.851 765.219 138.927C765.219 132.001 770.853 126.367 777.778 126.367C784.703 126.367 790.338 132.001 790.338 138.927C790.338 145.852 784.703 151.486 777.778 151.486ZM777.778 127.548C771.505 127.548 766.4 132.653 766.4 138.927C766.4 145.2 771.505 150.305 777.778 150.305C784.052 150.305 789.156 145.2 789.156 138.927C789.156 132.653 784.053 127.548 777.778 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M809.348 151.486C802.424 151.486 796.789 145.851 796.789 138.927C796.789 132.001 802.424 126.367 809.348 126.367C816.273 126.367 821.908 132.001 821.908 138.927C821.908 145.852 816.273 151.486 809.348 151.486ZM809.348 127.548C803.075 127.548 797.97 132.653 797.97 138.927C797.97 145.2 803.075 150.305 809.348 150.305C815.622 150.305 820.727 145.2 820.727 138.927C820.727 132.653 815.623 127.548 809.348 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M840.927 151.486C834.002 151.486 828.367 145.851 828.367 138.927C828.367 132.001 834.002 126.367 840.927 126.367C847.851 126.367 853.486 132.001 853.486 138.927C853.486 145.852 847.851 151.486 840.927 151.486ZM840.927 127.548C834.653 127.548 829.548 132.653 829.548 138.927C829.548 145.2 834.653 150.305 840.927 150.305C847.2 150.305 852.305 145.2 852.305 138.927C852.305 132.653 847.201 127.548 840.927 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M872.501 151.486C865.576 151.486 859.941 145.851 859.941 138.927C859.941 132.001 865.576 126.367 872.501 126.367C879.426 126.367 885.06 132.001 885.06 138.927C885.06 145.852 879.426 151.486 872.501 151.486ZM872.501 127.548C866.227 127.548 861.123 132.653 861.123 138.927C861.123 145.2 866.227 150.305 872.501 150.305C878.774 150.305 883.879 145.2 883.879 138.927C883.879 132.653 878.775 127.548 872.501 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M904.075 151.486C897.15 151.486 891.516 145.851 891.516 138.927C891.516 132.001 897.15 126.367 904.075 126.367C911 126.367 916.634 132.001 916.634 138.927C916.634 145.852 911 151.486 904.075 151.486ZM904.075 127.548C897.801 127.548 892.697 132.653 892.697 138.927C892.697 145.2 897.801 150.305 904.075 150.305C910.349 150.305 915.453 145.2 915.453 138.927C915.453 132.653 910.35 127.548 904.075 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M936.352 13.691C929.427 13.691 923.793 8.05651 923.793 1.13164C923.793 -5.79412 929.427 -11.4277 936.352 -11.4277C943.277 -11.4277 948.912 -5.79412 948.912 1.13164C948.912 8.05741 943.277 13.691 936.352 13.691ZM936.352 -10.2465C930.079 -10.2465 924.974 -5.14198 924.974 1.13164C924.974 7.40522 930.079 12.5097 936.352 12.5097C942.626 12.5097 947.73 7.40522 947.73 1.13164C947.73 -5.14198 942.627 -10.2465 936.352 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M967.93 13.691C961.006 13.691 955.371 8.05651 955.371 1.13164C955.371 -5.79412 961.006 -11.4277 967.93 -11.4277C974.855 -11.4277 980.49 -5.79412 980.49 1.13164C980.49 8.05741 974.855 13.691 967.93 13.691ZM967.93 -10.2465C961.657 -10.2465 956.552 -5.14198 956.552 1.13164C956.552 7.40522 961.657 12.5097 967.93 12.5097C974.204 12.5097 979.309 7.40522 979.309 1.13164C979.309 -5.14198 974.205 -10.2465 967.93 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M999.509 13.691C992.584 13.691 986.949 8.05651 986.949 1.13164C986.949 -5.79412 992.584 -11.4277 999.509 -11.4277C1006.43 -11.4277 1012.07 -5.79412 1012.07 1.13164C1012.07 8.05741 1006.43 13.691 999.509 13.691ZM999.509 -10.2465C993.235 -10.2465 988.131 -5.14198 988.131 1.13164C988.131 7.40522 993.235 12.5097 999.509 12.5097C1005.78 12.5097 1010.89 7.40522 1010.89 1.13164C1010.89 -5.14198 1005.78 -10.2465 999.509 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1031.09 13.691C1024.16 13.691 1018.53 8.05651 1018.53 1.13164C1018.53 -5.79412 1024.16 -11.4277 1031.09 -11.4277C1038.01 -11.4277 1043.65 -5.79412 1043.65 1.13164C1043.65 8.05741 1038.01 13.691 1031.09 13.691ZM1031.09 -10.2465C1024.81 -10.2465 1019.71 -5.14198 1019.71 1.13164C1019.71 7.40522 1024.81 12.5097 1031.09 12.5097C1037.36 12.5097 1042.46 7.40522 1042.46 1.13164C1042.46 -5.14198 1037.36 -10.2465 1031.09 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1062.66 13.691C1055.74 13.691 1050.1 8.05651 1050.1 1.13164C1050.1 -5.79412 1055.74 -11.4277 1062.66 -11.4277C1069.59 -11.4277 1075.22 -5.79412 1075.22 1.13164C1075.22 8.05741 1069.59 13.691 1062.66 13.691ZM1062.66 -10.2465C1056.39 -10.2465 1051.28 -5.14198 1051.28 1.13164C1051.28 7.40522 1056.39 12.5097 1062.66 12.5097C1068.93 12.5097 1074.04 7.40522 1074.04 1.13164C1074.04 -5.14198 1068.94 -10.2465 1062.66 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1094.25 13.691C1087.32 13.691 1081.69 8.05651 1081.69 1.13164C1081.69 -5.79412 1087.32 -11.4277 1094.25 -11.4277C1101.17 -11.4277 1106.81 -5.79412 1106.81 1.13164C1106.81 8.05741 1101.17 13.691 1094.25 13.691ZM1094.25 -10.2465C1087.97 -10.2465 1082.87 -5.14198 1082.87 1.13164C1082.87 7.40522 1087.97 12.5097 1094.25 12.5097C1100.52 12.5097 1105.62 7.40522 1105.62 1.13164C1105.62 -5.14198 1100.52 -10.2465 1094.25 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1125.82 13.691C1118.9 13.691 1113.26 8.05651 1113.26 1.13164C1113.26 -5.79412 1118.9 -11.4277 1125.82 -11.4277C1132.75 -11.4277 1138.38 -5.79412 1138.38 1.13164C1138.38 8.05741 1132.75 13.691 1125.82 13.691ZM1125.82 -10.2465C1119.55 -10.2465 1114.44 -5.14198 1114.44 1.13164C1114.44 7.40522 1119.55 12.5097 1125.82 12.5097C1132.09 12.5097 1137.2 7.40522 1137.2 1.13164C1137.2 -5.14198 1132.1 -10.2465 1125.82 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1157.4 13.691C1150.47 13.691 1144.84 8.05651 1144.84 1.13164C1144.84 -5.79412 1150.47 -11.4277 1157.4 -11.4277C1164.32 -11.4277 1169.96 -5.79412 1169.96 1.13164C1169.96 8.05741 1164.32 13.691 1157.4 13.691ZM1157.4 -10.2465C1151.13 -10.2465 1146.02 -5.14198 1146.02 1.13164C1146.02 7.40522 1151.13 12.5097 1157.4 12.5097C1163.67 12.5097 1168.78 7.40522 1168.78 1.13164C1168.78 -5.14198 1163.67 -10.2465 1157.4 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1188.98 13.691C1182.05 13.691 1176.42 8.05651 1176.42 1.13164C1176.42 -5.79412 1182.05 -11.4277 1188.98 -11.4277C1195.9 -11.4277 1201.54 -5.79412 1201.54 1.13164C1201.54 8.05741 1195.9 13.691 1188.98 13.691ZM1188.98 -10.2465C1182.7 -10.2465 1177.6 -5.14198 1177.6 1.13164C1177.6 7.40522 1182.7 12.5097 1188.98 12.5097C1195.25 12.5097 1200.36 7.40522 1200.36 1.13164C1200.36 -5.14198 1195.25 -10.2465 1188.98 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1220.55 13.691C1213.63 13.691 1207.99 8.05651 1207.99 1.13164C1207.99 -5.79412 1213.63 -11.4277 1220.55 -11.4277C1227.48 -11.4277 1233.11 -5.79412 1233.11 1.13164C1233.11 8.05741 1227.48 13.691 1220.55 13.691ZM1220.55 -10.2465C1214.28 -10.2465 1209.17 -5.14198 1209.17 1.13164C1209.17 7.40522 1214.28 12.5097 1220.55 12.5097C1226.83 12.5097 1231.93 7.40522 1231.93 1.13164C1231.93 -5.14198 1226.83 -10.2465 1220.55 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1252.13 13.691C1245.21 13.691 1239.57 8.05651 1239.57 1.13164C1239.57 -5.79412 1245.21 -11.4277 1252.13 -11.4277C1259.06 -11.4277 1264.69 -5.79412 1264.69 1.13164C1264.69 8.05741 1259.06 13.691 1252.13 13.691ZM1252.13 -10.2465C1245.86 -10.2465 1240.76 -5.14198 1240.76 1.13164C1240.76 7.40522 1245.86 12.5097 1252.13 12.5097C1258.41 12.5097 1263.51 7.40522 1263.51 1.13164C1263.51 -5.14198 1258.41 -10.2465 1252.13 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1283.71 13.691C1276.78 13.691 1271.15 8.05651 1271.15 1.13164C1271.15 -5.79412 1276.78 -11.4277 1283.71 -11.4277C1290.63 -11.4277 1296.27 -5.79412 1296.27 1.13164C1296.27 8.05741 1290.63 13.691 1283.71 13.691ZM1283.71 -10.2465C1277.43 -10.2465 1272.33 -5.14198 1272.33 1.13164C1272.33 7.40522 1277.43 12.5097 1283.71 12.5097C1289.98 12.5097 1295.09 7.40522 1295.09 1.13164C1295.09 -5.14198 1289.98 -10.2465 1283.71 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1315.29 13.691C1308.36 13.691 1302.73 8.05651 1302.73 1.13164C1302.73 -5.79412 1308.36 -11.4277 1315.29 -11.4277C1322.21 -11.4277 1327.85 -5.79412 1327.85 1.13164C1327.85 8.05741 1322.21 13.691 1315.29 13.691ZM1315.29 -10.2465C1309.01 -10.2465 1303.91 -5.14198 1303.91 1.13164C1303.91 7.40522 1309.01 12.5097 1315.29 12.5097C1321.56 12.5097 1326.66 7.40522 1326.66 1.13164C1326.66 -5.14198 1321.56 -10.2465 1315.29 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1346.87 13.691C1339.94 13.691 1334.31 8.05651 1334.31 1.13164C1334.31 -5.79412 1339.94 -11.4277 1346.87 -11.4277C1353.79 -11.4277 1359.43 -5.79412 1359.43 1.13164C1359.43 8.05741 1353.79 13.691 1346.87 13.691ZM1346.87 -10.2465C1340.59 -10.2465 1335.49 -5.14198 1335.49 1.13164C1335.49 7.40522 1340.59 12.5097 1346.87 12.5097C1353.14 12.5097 1358.25 7.40522 1358.25 1.13164C1358.25 -5.14198 1353.14 -10.2465 1346.87 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1378.44 13.691C1371.51 13.691 1365.88 8.05651 1365.88 1.13164C1365.88 -5.79412 1371.51 -11.4277 1378.44 -11.4277C1385.36 -11.4277 1391 -5.79412 1391 1.13164C1391 8.05741 1385.36 13.691 1378.44 13.691ZM1378.44 -10.2465C1372.16 -10.2465 1367.06 -5.14198 1367.06 1.13164C1367.06 7.40522 1372.16 12.5097 1378.44 12.5097C1384.71 12.5097 1389.82 7.40522 1389.82 1.13164C1389.82 -5.14198 1384.71 -10.2465 1378.44 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1410.02 13.691C1403.09 13.691 1397.46 8.05651 1397.46 1.13164C1397.46 -5.79412 1403.09 -11.4277 1410.02 -11.4277C1416.94 -11.4277 1422.58 -5.79412 1422.58 1.13164C1422.58 8.05741 1416.94 13.691 1410.02 13.691ZM1410.02 -10.2465C1403.74 -10.2465 1398.64 -5.14198 1398.64 1.13164C1398.64 7.40522 1403.74 12.5097 1410.02 12.5097C1416.29 12.5097 1421.39 7.40522 1421.39 1.13164C1421.39 -5.14198 1416.29 -10.2465 1410.02 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1441.59 13.691C1434.67 13.691 1429.03 8.05651 1429.03 1.13164C1429.03 -5.79412 1434.67 -11.4277 1441.59 -11.4277C1448.52 -11.4277 1454.15 -5.79412 1454.15 1.13164C1454.15 8.05741 1448.52 13.691 1441.59 13.691ZM1441.59 -10.2465C1435.32 -10.2465 1430.21 -5.14198 1430.21 1.13164C1430.21 7.40522 1435.32 12.5097 1441.59 12.5097C1447.86 12.5097 1452.97 7.40522 1452.97 1.13164C1452.97 -5.14198 1447.87 -10.2465 1441.59 -10.2465Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M936.352 48.1422C929.427 48.1422 923.793 42.5077 923.793 35.5828C923.793 28.6571 929.427 23.0234 936.352 23.0234C943.277 23.0234 948.912 28.6571 948.912 35.5828C948.912 42.5086 943.277 48.1422 936.352 48.1422ZM936.352 24.2047C930.079 24.2047 924.974 29.3092 924.974 35.5828C924.974 41.8564 930.079 46.9609 936.352 46.9609C942.626 46.9609 947.73 41.8564 947.73 35.5828C947.73 29.3092 942.627 24.2047 936.352 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M967.93 48.1422C961.006 48.1422 955.371 42.5077 955.371 35.5828C955.371 28.6571 961.006 23.0234 967.93 23.0234C974.855 23.0234 980.49 28.6571 980.49 35.5828C980.49 42.5086 974.855 48.1422 967.93 48.1422ZM967.93 24.2047C961.657 24.2047 956.552 29.3092 956.552 35.5828C956.552 41.8564 961.657 46.9609 967.93 46.9609C974.204 46.9609 979.309 41.8564 979.309 35.5828C979.309 29.3092 974.205 24.2047 967.93 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M999.509 48.1422C992.584 48.1422 986.949 42.5077 986.949 35.5828C986.949 28.6571 992.584 23.0234 999.509 23.0234C1006.43 23.0234 1012.07 28.6571 1012.07 35.5828C1012.07 42.5086 1006.43 48.1422 999.509 48.1422ZM999.509 24.2047C993.235 24.2047 988.131 29.3092 988.131 35.5828C988.131 41.8564 993.235 46.9609 999.509 46.9609C1005.78 46.9609 1010.89 41.8564 1010.89 35.5828C1010.89 29.3092 1005.78 24.2047 999.509 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1031.09 48.1422C1024.16 48.1422 1018.53 42.5077 1018.53 35.5828C1018.53 28.6571 1024.16 23.0234 1031.09 23.0234C1038.01 23.0234 1043.65 28.6571 1043.65 35.5828C1043.65 42.5086 1038.01 48.1422 1031.09 48.1422ZM1031.09 24.2047C1024.81 24.2047 1019.71 29.3092 1019.71 35.5828C1019.71 41.8564 1024.81 46.9609 1031.09 46.9609C1037.36 46.9609 1042.46 41.8564 1042.46 35.5828C1042.46 29.3092 1037.36 24.2047 1031.09 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1062.66 48.1422C1055.74 48.1422 1050.1 42.5077 1050.1 35.5828C1050.1 28.6571 1055.74 23.0234 1062.66 23.0234C1069.59 23.0234 1075.22 28.6571 1075.22 35.5828C1075.22 42.5086 1069.59 48.1422 1062.66 48.1422ZM1062.66 24.2047C1056.39 24.2047 1051.28 29.3092 1051.28 35.5828C1051.28 41.8564 1056.39 46.9609 1062.66 46.9609C1068.93 46.9609 1074.04 41.8564 1074.04 35.5828C1074.04 29.3092 1068.94 24.2047 1062.66 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1094.25 48.1422C1087.32 48.1422 1081.69 42.5077 1081.69 35.5828C1081.69 28.6571 1087.32 23.0234 1094.25 23.0234C1101.17 23.0234 1106.81 28.6571 1106.81 35.5828C1106.81 42.5086 1101.17 48.1422 1094.25 48.1422ZM1094.25 24.2047C1087.97 24.2047 1082.87 29.3092 1082.87 35.5828C1082.87 41.8564 1087.97 46.9609 1094.25 46.9609C1100.52 46.9609 1105.62 41.8564 1105.62 35.5828C1105.62 29.3092 1100.52 24.2047 1094.25 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1125.82 48.1422C1118.9 48.1422 1113.26 42.5077 1113.26 35.5828C1113.26 28.6571 1118.9 23.0234 1125.82 23.0234C1132.75 23.0234 1138.38 28.6571 1138.38 35.5828C1138.38 42.5086 1132.75 48.1422 1125.82 48.1422ZM1125.82 24.2047C1119.55 24.2047 1114.44 29.3092 1114.44 35.5828C1114.44 41.8564 1119.55 46.9609 1125.82 46.9609C1132.09 46.9609 1137.2 41.8564 1137.2 35.5828C1137.2 29.3092 1132.1 24.2047 1125.82 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1157.4 48.1422C1150.47 48.1422 1144.84 42.5077 1144.84 35.5828C1144.84 28.6571 1150.47 23.0234 1157.4 23.0234C1164.32 23.0234 1169.96 28.6571 1169.96 35.5828C1169.96 42.5086 1164.32 48.1422 1157.4 48.1422ZM1157.4 24.2047C1151.13 24.2047 1146.02 29.3092 1146.02 35.5828C1146.02 41.8564 1151.13 46.9609 1157.4 46.9609C1163.67 46.9609 1168.78 41.8564 1168.78 35.5828C1168.78 29.3092 1163.67 24.2047 1157.4 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1188.98 48.1422C1182.05 48.1422 1176.42 42.5077 1176.42 35.5828C1176.42 28.6571 1182.05 23.0234 1188.98 23.0234C1195.9 23.0234 1201.54 28.6571 1201.54 35.5828C1201.54 42.5086 1195.9 48.1422 1188.98 48.1422ZM1188.98 24.2047C1182.7 24.2047 1177.6 29.3092 1177.6 35.5828C1177.6 41.8564 1182.7 46.9609 1188.98 46.9609C1195.25 46.9609 1200.36 41.8564 1200.36 35.5828C1200.36 29.3092 1195.25 24.2047 1188.98 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1220.55 48.1422C1213.63 48.1422 1207.99 42.5077 1207.99 35.5828C1207.99 28.6571 1213.63 23.0234 1220.55 23.0234C1227.48 23.0234 1233.11 28.6571 1233.11 35.5828C1233.11 42.5086 1227.48 48.1422 1220.55 48.1422ZM1220.55 24.2047C1214.28 24.2047 1209.17 29.3092 1209.17 35.5828C1209.17 41.8564 1214.28 46.9609 1220.55 46.9609C1226.83 46.9609 1231.93 41.8564 1231.93 35.5828C1231.93 29.3092 1226.83 24.2047 1220.55 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1252.13 48.1422C1245.21 48.1422 1239.57 42.5077 1239.57 35.5828C1239.57 28.6571 1245.21 23.0234 1252.13 23.0234C1259.06 23.0234 1264.69 28.6571 1264.69 35.5828C1264.69 42.5086 1259.06 48.1422 1252.13 48.1422ZM1252.13 24.2047C1245.86 24.2047 1240.76 29.3092 1240.76 35.5828C1240.76 41.8564 1245.86 46.9609 1252.13 46.9609C1258.41 46.9609 1263.51 41.8564 1263.51 35.5828C1263.51 29.3092 1258.41 24.2047 1252.13 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1283.71 48.1422C1276.78 48.1422 1271.15 42.5077 1271.15 35.5828C1271.15 28.6571 1276.78 23.0234 1283.71 23.0234C1290.63 23.0234 1296.27 28.6571 1296.27 35.5828C1296.27 42.5086 1290.63 48.1422 1283.71 48.1422ZM1283.71 24.2047C1277.43 24.2047 1272.33 29.3092 1272.33 35.5828C1272.33 41.8564 1277.43 46.9609 1283.71 46.9609C1289.98 46.9609 1295.09 41.8564 1295.09 35.5828C1295.09 29.3092 1289.98 24.2047 1283.71 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1315.29 48.1422C1308.36 48.1422 1302.73 42.5077 1302.73 35.5828C1302.73 28.6571 1308.36 23.0234 1315.29 23.0234C1322.21 23.0234 1327.85 28.6571 1327.85 35.5828C1327.85 42.5086 1322.21 48.1422 1315.29 48.1422ZM1315.29 24.2047C1309.01 24.2047 1303.91 29.3092 1303.91 35.5828C1303.91 41.8564 1309.01 46.9609 1315.29 46.9609C1321.56 46.9609 1326.66 41.8564 1326.66 35.5828C1326.66 29.3092 1321.56 24.2047 1315.29 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1346.87 48.1422C1339.94 48.1422 1334.31 42.5077 1334.31 35.5828C1334.31 28.6571 1339.94 23.0234 1346.87 23.0234C1353.79 23.0234 1359.43 28.6571 1359.43 35.5828C1359.43 42.5086 1353.79 48.1422 1346.87 48.1422ZM1346.87 24.2047C1340.59 24.2047 1335.49 29.3092 1335.49 35.5828C1335.49 41.8564 1340.59 46.9609 1346.87 46.9609C1353.14 46.9609 1358.25 41.8564 1358.25 35.5828C1358.25 29.3092 1353.14 24.2047 1346.87 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1378.44 48.1422C1371.51 48.1422 1365.88 42.5077 1365.88 35.5828C1365.88 28.6571 1371.51 23.0234 1378.44 23.0234C1385.36 23.0234 1391 28.6571 1391 35.5828C1391 42.5086 1385.36 48.1422 1378.44 48.1422ZM1378.44 24.2047C1372.16 24.2047 1367.06 29.3092 1367.06 35.5828C1367.06 41.8564 1372.16 46.9609 1378.44 46.9609C1384.71 46.9609 1389.82 41.8564 1389.82 35.5828C1389.82 29.3092 1384.71 24.2047 1378.44 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1410.02 48.1422C1403.09 48.1422 1397.46 42.5077 1397.46 35.5828C1397.46 28.6571 1403.09 23.0234 1410.02 23.0234C1416.94 23.0234 1422.58 28.6571 1422.58 35.5828C1422.58 42.5086 1416.94 48.1422 1410.02 48.1422ZM1410.02 24.2047C1403.74 24.2047 1398.64 29.3092 1398.64 35.5828C1398.64 41.8564 1403.74 46.9609 1410.02 46.9609C1416.29 46.9609 1421.39 41.8564 1421.39 35.5828C1421.39 29.3092 1416.29 24.2047 1410.02 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1441.59 48.1422C1434.67 48.1422 1429.03 42.5077 1429.03 35.5828C1429.03 28.6571 1434.67 23.0234 1441.59 23.0234C1448.52 23.0234 1454.15 28.6571 1454.15 35.5828C1454.15 42.5086 1448.52 48.1422 1441.59 48.1422ZM1441.59 24.2047C1435.32 24.2047 1430.21 29.3092 1430.21 35.5828C1430.21 41.8564 1435.32 46.9609 1441.59 46.9609C1447.86 46.9609 1452.97 41.8564 1452.97 35.5828C1452.97 29.3092 1447.87 24.2047 1441.59 24.2047Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M936.352 82.5914C929.427 82.5914 923.793 76.9569 923.793 70.032C923.793 63.1063 929.427 57.4727 936.352 57.4727C943.277 57.4727 948.912 63.1063 948.912 70.032C948.912 76.9578 943.277 82.5914 936.352 82.5914ZM936.352 58.6539C930.079 58.6539 924.974 63.7584 924.974 70.032C924.974 76.3056 930.079 81.4101 936.352 81.4101C942.626 81.4101 947.73 76.3056 947.73 70.032C947.73 63.7584 942.627 58.6539 936.352 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M967.93 82.5914C961.006 82.5914 955.371 76.9569 955.371 70.032C955.371 63.1063 961.006 57.4727 967.93 57.4727C974.855 57.4727 980.49 63.1063 980.49 70.032C980.49 76.9578 974.855 82.5914 967.93 82.5914ZM967.93 58.6539C961.657 58.6539 956.552 63.7584 956.552 70.032C956.552 76.3056 961.657 81.4101 967.93 81.4101C974.204 81.4101 979.309 76.3056 979.309 70.032C979.309 63.7584 974.205 58.6539 967.93 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M999.509 82.5914C992.584 82.5914 986.949 76.9569 986.949 70.032C986.949 63.1063 992.584 57.4727 999.509 57.4727C1006.43 57.4727 1012.07 63.1063 1012.07 70.032C1012.07 76.9578 1006.43 82.5914 999.509 82.5914ZM999.509 58.6539C993.235 58.6539 988.131 63.7584 988.131 70.032C988.131 76.3056 993.235 81.4101 999.509 81.4101C1005.78 81.4101 1010.89 76.3056 1010.89 70.032C1010.89 63.7584 1005.78 58.6539 999.509 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1031.09 82.5914C1024.16 82.5914 1018.53 76.9569 1018.53 70.032C1018.53 63.1063 1024.16 57.4727 1031.09 57.4727C1038.01 57.4727 1043.65 63.1063 1043.65 70.032C1043.65 76.9578 1038.01 82.5914 1031.09 82.5914ZM1031.09 58.6539C1024.81 58.6539 1019.71 63.7584 1019.71 70.032C1019.71 76.3056 1024.81 81.4101 1031.09 81.4101C1037.36 81.4101 1042.46 76.3056 1042.46 70.032C1042.46 63.7584 1037.36 58.6539 1031.09 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1062.66 82.5914C1055.74 82.5914 1050.1 76.9569 1050.1 70.032C1050.1 63.1063 1055.74 57.4727 1062.66 57.4727C1069.59 57.4727 1075.22 63.1063 1075.22 70.032C1075.22 76.9578 1069.59 82.5914 1062.66 82.5914ZM1062.66 58.6539C1056.39 58.6539 1051.28 63.7584 1051.28 70.032C1051.28 76.3056 1056.39 81.4101 1062.66 81.4101C1068.93 81.4101 1074.04 76.3056 1074.04 70.032C1074.04 63.7584 1068.94 58.6539 1062.66 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1094.25 82.5914C1087.32 82.5914 1081.69 76.9569 1081.69 70.032C1081.69 63.1063 1087.32 57.4727 1094.25 57.4727C1101.17 57.4727 1106.81 63.1063 1106.81 70.032C1106.81 76.9578 1101.17 82.5914 1094.25 82.5914ZM1094.25 58.6539C1087.97 58.6539 1082.87 63.7584 1082.87 70.032C1082.87 76.3056 1087.97 81.4101 1094.25 81.4101C1100.52 81.4101 1105.62 76.3056 1105.62 70.032C1105.62 63.7584 1100.52 58.6539 1094.25 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1125.82 82.5914C1118.9 82.5914 1113.26 76.9569 1113.26 70.032C1113.26 63.1063 1118.9 57.4727 1125.82 57.4727C1132.75 57.4727 1138.38 63.1063 1138.38 70.032C1138.38 76.9578 1132.75 82.5914 1125.82 82.5914ZM1125.82 58.6539C1119.55 58.6539 1114.44 63.7584 1114.44 70.032C1114.44 76.3056 1119.55 81.4101 1125.82 81.4101C1132.09 81.4101 1137.2 76.3056 1137.2 70.032C1137.2 63.7584 1132.1 58.6539 1125.82 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1157.4 82.5914C1150.47 82.5914 1144.84 76.9569 1144.84 70.032C1144.84 63.1063 1150.47 57.4727 1157.4 57.4727C1164.32 57.4727 1169.96 63.1063 1169.96 70.032C1169.96 76.9578 1164.32 82.5914 1157.4 82.5914ZM1157.4 58.6539C1151.13 58.6539 1146.02 63.7584 1146.02 70.032C1146.02 76.3056 1151.13 81.4101 1157.4 81.4101C1163.67 81.4101 1168.78 76.3056 1168.78 70.032C1168.78 63.7584 1163.67 58.6539 1157.4 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1188.98 82.5914C1182.05 82.5914 1176.42 76.9569 1176.42 70.032C1176.42 63.1063 1182.05 57.4727 1188.98 57.4727C1195.9 57.4727 1201.54 63.1063 1201.54 70.032C1201.54 76.9578 1195.9 82.5914 1188.98 82.5914ZM1188.98 58.6539C1182.7 58.6539 1177.6 63.7584 1177.6 70.032C1177.6 76.3056 1182.7 81.4101 1188.98 81.4101C1195.25 81.4101 1200.36 76.3056 1200.36 70.032C1200.36 63.7584 1195.25 58.6539 1188.98 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1220.55 82.5914C1213.63 82.5914 1207.99 76.9569 1207.99 70.032C1207.99 63.1063 1213.63 57.4727 1220.55 57.4727C1227.48 57.4727 1233.11 63.1063 1233.11 70.032C1233.11 76.9578 1227.48 82.5914 1220.55 82.5914ZM1220.55 58.6539C1214.28 58.6539 1209.17 63.7584 1209.17 70.032C1209.17 76.3056 1214.28 81.4101 1220.55 81.4101C1226.83 81.4101 1231.93 76.3056 1231.93 70.032C1231.93 63.7584 1226.83 58.6539 1220.55 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1252.13 82.5914C1245.21 82.5914 1239.57 76.9569 1239.57 70.032C1239.57 63.1063 1245.21 57.4727 1252.13 57.4727C1259.06 57.4727 1264.69 63.1063 1264.69 70.032C1264.69 76.9578 1259.06 82.5914 1252.13 82.5914ZM1252.13 58.6539C1245.86 58.6539 1240.76 63.7584 1240.76 70.032C1240.76 76.3056 1245.86 81.4101 1252.13 81.4101C1258.41 81.4101 1263.51 76.3056 1263.51 70.032C1263.51 63.7584 1258.41 58.6539 1252.13 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1283.71 82.5914C1276.78 82.5914 1271.15 76.9569 1271.15 70.032C1271.15 63.1063 1276.78 57.4727 1283.71 57.4727C1290.63 57.4727 1296.27 63.1063 1296.27 70.032C1296.27 76.9578 1290.63 82.5914 1283.71 82.5914ZM1283.71 58.6539C1277.43 58.6539 1272.33 63.7584 1272.33 70.032C1272.33 76.3056 1277.43 81.4101 1283.71 81.4101C1289.98 81.4101 1295.09 76.3056 1295.09 70.032C1295.09 63.7584 1289.98 58.6539 1283.71 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1315.29 82.5914C1308.36 82.5914 1302.73 76.9569 1302.73 70.032C1302.73 63.1063 1308.36 57.4727 1315.29 57.4727C1322.21 57.4727 1327.85 63.1063 1327.85 70.032C1327.85 76.9578 1322.21 82.5914 1315.29 82.5914ZM1315.29 58.6539C1309.01 58.6539 1303.91 63.7584 1303.91 70.032C1303.91 76.3056 1309.01 81.4101 1315.29 81.4101C1321.56 81.4101 1326.66 76.3056 1326.66 70.032C1326.66 63.7584 1321.56 58.6539 1315.29 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1346.87 82.5914C1339.94 82.5914 1334.31 76.9569 1334.31 70.032C1334.31 63.1063 1339.94 57.4727 1346.87 57.4727C1353.79 57.4727 1359.43 63.1063 1359.43 70.032C1359.43 76.9578 1353.79 82.5914 1346.87 82.5914ZM1346.87 58.6539C1340.59 58.6539 1335.49 63.7584 1335.49 70.032C1335.49 76.3056 1340.59 81.4101 1346.87 81.4101C1353.14 81.4101 1358.25 76.3056 1358.25 70.032C1358.25 63.7584 1353.14 58.6539 1346.87 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1378.44 82.5914C1371.51 82.5914 1365.88 76.9569 1365.88 70.032C1365.88 63.1063 1371.51 57.4727 1378.44 57.4727C1385.36 57.4727 1391 63.1063 1391 70.032C1391 76.9578 1385.36 82.5914 1378.44 82.5914ZM1378.44 58.6539C1372.16 58.6539 1367.06 63.7584 1367.06 70.032C1367.06 76.3056 1372.16 81.4101 1378.44 81.4101C1384.71 81.4101 1389.82 76.3056 1389.82 70.032C1389.82 63.7584 1384.71 58.6539 1378.44 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1410.02 82.5914C1403.09 82.5914 1397.46 76.9569 1397.46 70.032C1397.46 63.1063 1403.09 57.4727 1410.02 57.4727C1416.94 57.4727 1422.58 63.1063 1422.58 70.032C1422.58 76.9578 1416.94 82.5914 1410.02 82.5914ZM1410.02 58.6539C1403.74 58.6539 1398.64 63.7584 1398.64 70.032C1398.64 76.3056 1403.74 81.4101 1410.02 81.4101C1416.29 81.4101 1421.39 76.3056 1421.39 70.032C1421.39 63.7584 1416.29 58.6539 1410.02 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1441.59 82.5914C1434.67 82.5914 1429.03 76.9569 1429.03 70.032C1429.03 63.1063 1434.67 57.4727 1441.59 57.4727C1448.52 57.4727 1454.15 63.1063 1454.15 70.032C1454.15 76.9578 1448.52 82.5914 1441.59 82.5914ZM1441.59 58.6539C1435.32 58.6539 1430.21 63.7584 1430.21 70.032C1430.21 76.3056 1435.32 81.4101 1441.59 81.4101C1447.86 81.4101 1452.97 76.3056 1452.97 70.032C1452.97 63.7584 1447.87 58.6539 1441.59 58.6539Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M936.352 117.035C929.427 117.035 923.793 111.4 923.793 104.475C923.793 97.5496 929.427 91.916 936.352 91.916C943.277 91.916 948.912 97.5496 948.912 104.475C948.912 111.401 943.277 117.035 936.352 117.035ZM936.352 93.0973C930.079 93.0973 924.974 98.2018 924.974 104.475C924.974 110.749 930.079 115.853 936.352 115.853C942.626 115.853 947.73 110.749 947.73 104.475C947.73 98.2018 942.627 93.0973 936.352 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M967.93 117.035C961.006 117.035 955.371 111.4 955.371 104.475C955.371 97.5496 961.006 91.916 967.93 91.916C974.855 91.916 980.49 97.5496 980.49 104.475C980.49 111.401 974.855 117.035 967.93 117.035ZM967.93 93.0973C961.657 93.0973 956.552 98.2018 956.552 104.475C956.552 110.749 961.657 115.853 967.93 115.853C974.204 115.853 979.309 110.749 979.309 104.475C979.309 98.2018 974.205 93.0973 967.93 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M999.509 117.035C992.584 117.035 986.949 111.4 986.949 104.475C986.949 97.5496 992.584 91.916 999.509 91.916C1006.43 91.916 1012.07 97.5496 1012.07 104.475C1012.07 111.401 1006.43 117.035 999.509 117.035ZM999.509 93.0973C993.235 93.0973 988.131 98.2018 988.131 104.475C988.131 110.749 993.235 115.853 999.509 115.853C1005.78 115.853 1010.89 110.749 1010.89 104.475C1010.89 98.2018 1005.78 93.0973 999.509 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1031.09 117.035C1024.16 117.035 1018.53 111.4 1018.53 104.475C1018.53 97.5496 1024.16 91.916 1031.09 91.916C1038.01 91.916 1043.65 97.5496 1043.65 104.475C1043.65 111.401 1038.01 117.035 1031.09 117.035ZM1031.09 93.0973C1024.81 93.0973 1019.71 98.2018 1019.71 104.475C1019.71 110.749 1024.81 115.853 1031.09 115.853C1037.36 115.853 1042.46 110.749 1042.46 104.475C1042.46 98.2018 1037.36 93.0973 1031.09 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1062.66 117.035C1055.74 117.035 1050.1 111.4 1050.1 104.475C1050.1 97.5496 1055.74 91.916 1062.66 91.916C1069.59 91.916 1075.22 97.5496 1075.22 104.475C1075.22 111.401 1069.59 117.035 1062.66 117.035ZM1062.66 93.0973C1056.39 93.0973 1051.28 98.2018 1051.28 104.475C1051.28 110.749 1056.39 115.853 1062.66 115.853C1068.93 115.853 1074.04 110.749 1074.04 104.475C1074.04 98.2018 1068.94 93.0973 1062.66 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1094.25 117.035C1087.32 117.035 1081.69 111.4 1081.69 104.475C1081.69 97.5496 1087.32 91.916 1094.25 91.916C1101.17 91.916 1106.81 97.5496 1106.81 104.475C1106.81 111.401 1101.17 117.035 1094.25 117.035ZM1094.25 93.0973C1087.97 93.0973 1082.87 98.2018 1082.87 104.475C1082.87 110.749 1087.97 115.853 1094.25 115.853C1100.52 115.853 1105.62 110.749 1105.62 104.475C1105.62 98.2018 1100.52 93.0973 1094.25 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1125.82 117.035C1118.9 117.035 1113.26 111.4 1113.26 104.475C1113.26 97.5496 1118.9 91.916 1125.82 91.916C1132.75 91.916 1138.38 97.5496 1138.38 104.475C1138.38 111.401 1132.75 117.035 1125.82 117.035ZM1125.82 93.0973C1119.55 93.0973 1114.44 98.2018 1114.44 104.475C1114.44 110.749 1119.55 115.853 1125.82 115.853C1132.09 115.853 1137.2 110.749 1137.2 104.475C1137.2 98.2018 1132.1 93.0973 1125.82 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1157.4 117.035C1150.47 117.035 1144.84 111.4 1144.84 104.475C1144.84 97.5496 1150.47 91.916 1157.4 91.916C1164.32 91.916 1169.96 97.5496 1169.96 104.475C1169.96 111.401 1164.32 117.035 1157.4 117.035ZM1157.4 93.0973C1151.13 93.0973 1146.02 98.2018 1146.02 104.475C1146.02 110.749 1151.13 115.853 1157.4 115.853C1163.67 115.853 1168.78 110.749 1168.78 104.475C1168.78 98.2018 1163.67 93.0973 1157.4 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1188.98 117.035C1182.05 117.035 1176.42 111.4 1176.42 104.475C1176.42 97.5496 1182.05 91.916 1188.98 91.916C1195.9 91.916 1201.54 97.5496 1201.54 104.475C1201.54 111.401 1195.9 117.035 1188.98 117.035ZM1188.98 93.0973C1182.7 93.0973 1177.6 98.2018 1177.6 104.475C1177.6 110.749 1182.7 115.853 1188.98 115.853C1195.25 115.853 1200.36 110.749 1200.36 104.475C1200.36 98.2018 1195.25 93.0973 1188.98 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1220.55 117.035C1213.63 117.035 1207.99 111.4 1207.99 104.475C1207.99 97.5496 1213.63 91.916 1220.55 91.916C1227.48 91.916 1233.11 97.5496 1233.11 104.475C1233.11 111.401 1227.48 117.035 1220.55 117.035ZM1220.55 93.0973C1214.28 93.0973 1209.17 98.2018 1209.17 104.475C1209.17 110.749 1214.28 115.853 1220.55 115.853C1226.83 115.853 1231.93 110.749 1231.93 104.475C1231.93 98.2018 1226.83 93.0973 1220.55 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1252.13 117.035C1245.21 117.035 1239.57 111.4 1239.57 104.475C1239.57 97.5496 1245.21 91.916 1252.13 91.916C1259.06 91.916 1264.69 97.5496 1264.69 104.475C1264.69 111.401 1259.06 117.035 1252.13 117.035ZM1252.13 93.0973C1245.86 93.0973 1240.76 98.2018 1240.76 104.475C1240.76 110.749 1245.86 115.853 1252.13 115.853C1258.41 115.853 1263.51 110.749 1263.51 104.475C1263.51 98.2018 1258.41 93.0973 1252.13 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1283.71 117.035C1276.78 117.035 1271.15 111.4 1271.15 104.475C1271.15 97.5496 1276.78 91.916 1283.71 91.916C1290.63 91.916 1296.27 97.5496 1296.27 104.475C1296.27 111.401 1290.63 117.035 1283.71 117.035ZM1283.71 93.0973C1277.43 93.0973 1272.33 98.2018 1272.33 104.475C1272.33 110.749 1277.43 115.853 1283.71 115.853C1289.98 115.853 1295.09 110.749 1295.09 104.475C1295.09 98.2018 1289.98 93.0973 1283.71 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1315.29 117.035C1308.36 117.035 1302.73 111.4 1302.73 104.475C1302.73 97.5496 1308.36 91.916 1315.29 91.916C1322.21 91.916 1327.85 97.5496 1327.85 104.475C1327.85 111.401 1322.21 117.035 1315.29 117.035ZM1315.29 93.0973C1309.01 93.0973 1303.91 98.2018 1303.91 104.475C1303.91 110.749 1309.01 115.853 1315.29 115.853C1321.56 115.853 1326.66 110.749 1326.66 104.475C1326.66 98.2018 1321.56 93.0973 1315.29 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1346.87 117.035C1339.94 117.035 1334.31 111.4 1334.31 104.475C1334.31 97.5496 1339.94 91.916 1346.87 91.916C1353.79 91.916 1359.43 97.5496 1359.43 104.475C1359.43 111.401 1353.79 117.035 1346.87 117.035ZM1346.87 93.0973C1340.59 93.0973 1335.49 98.2018 1335.49 104.475C1335.49 110.749 1340.59 115.853 1346.87 115.853C1353.14 115.853 1358.25 110.749 1358.25 104.475C1358.25 98.2018 1353.14 93.0973 1346.87 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1378.44 117.035C1371.51 117.035 1365.88 111.4 1365.88 104.475C1365.88 97.5496 1371.51 91.916 1378.44 91.916C1385.36 91.916 1391 97.5496 1391 104.475C1391 111.401 1385.36 117.035 1378.44 117.035ZM1378.44 93.0973C1372.16 93.0973 1367.06 98.2018 1367.06 104.475C1367.06 110.749 1372.16 115.853 1378.44 115.853C1384.71 115.853 1389.82 110.749 1389.82 104.475C1389.82 98.2018 1384.71 93.0973 1378.44 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1410.02 117.035C1403.09 117.035 1397.46 111.4 1397.46 104.475C1397.46 97.5496 1403.09 91.916 1410.02 91.916C1416.94 91.916 1422.58 97.5496 1422.58 104.475C1422.58 111.401 1416.94 117.035 1410.02 117.035ZM1410.02 93.0973C1403.74 93.0973 1398.64 98.2018 1398.64 104.475C1398.64 110.749 1403.74 115.853 1410.02 115.853C1416.29 115.853 1421.39 110.749 1421.39 104.475C1421.39 98.2018 1416.29 93.0973 1410.02 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1441.59 117.035C1434.67 117.035 1429.03 111.4 1429.03 104.475C1429.03 97.5496 1434.67 91.916 1441.59 91.916C1448.52 91.916 1454.15 97.5496 1454.15 104.475C1454.15 111.401 1448.52 117.035 1441.59 117.035ZM1441.59 93.0973C1435.32 93.0973 1430.21 98.2018 1430.21 104.475C1430.21 110.749 1435.32 115.853 1441.59 115.853C1447.86 115.853 1452.97 110.749 1452.97 104.475C1452.97 98.2018 1447.87 93.0973 1441.59 93.0973Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M936.352 151.486C929.427 151.486 923.793 145.851 923.793 138.927C923.793 132.001 929.427 126.367 936.352 126.367C943.277 126.367 948.912 132.001 948.912 138.927C948.912 145.852 943.277 151.486 936.352 151.486ZM936.352 127.548C930.079 127.548 924.974 132.653 924.974 138.927C924.974 145.2 930.079 150.305 936.352 150.305C942.626 150.305 947.73 145.2 947.73 138.927C947.73 132.653 942.627 127.548 936.352 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M967.93 151.486C961.006 151.486 955.371 145.851 955.371 138.927C955.371 132.001 961.006 126.367 967.93 126.367C974.855 126.367 980.49 132.001 980.49 138.927C980.49 145.852 974.855 151.486 967.93 151.486ZM967.93 127.548C961.657 127.548 956.552 132.653 956.552 138.927C956.552 145.2 961.657 150.305 967.93 150.305C974.204 150.305 979.309 145.2 979.309 138.927C979.309 132.653 974.205 127.548 967.93 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M999.509 151.486C992.584 151.486 986.949 145.851 986.949 138.927C986.949 132.001 992.584 126.367 999.509 126.367C1006.43 126.367 1012.07 132.001 1012.07 138.927C1012.07 145.852 1006.43 151.486 999.509 151.486ZM999.509 127.548C993.235 127.548 988.131 132.653 988.131 138.927C988.131 145.2 993.235 150.305 999.509 150.305C1005.78 150.305 1010.89 145.2 1010.89 138.927C1010.89 132.653 1005.78 127.548 999.509 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1031.09 151.486C1024.16 151.486 1018.53 145.851 1018.53 138.927C1018.53 132.001 1024.16 126.367 1031.09 126.367C1038.01 126.367 1043.65 132.001 1043.65 138.927C1043.65 145.852 1038.01 151.486 1031.09 151.486ZM1031.09 127.548C1024.81 127.548 1019.71 132.653 1019.71 138.927C1019.71 145.2 1024.81 150.305 1031.09 150.305C1037.36 150.305 1042.46 145.2 1042.46 138.927C1042.46 132.653 1037.36 127.548 1031.09 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1062.66 151.486C1055.74 151.486 1050.1 145.851 1050.1 138.927C1050.1 132.001 1055.74 126.367 1062.66 126.367C1069.59 126.367 1075.22 132.001 1075.22 138.927C1075.22 145.852 1069.59 151.486 1062.66 151.486ZM1062.66 127.548C1056.39 127.548 1051.28 132.653 1051.28 138.927C1051.28 145.2 1056.39 150.305 1062.66 150.305C1068.93 150.305 1074.04 145.2 1074.04 138.927C1074.04 132.653 1068.94 127.548 1062.66 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1094.25 151.486C1087.32 151.486 1081.69 145.851 1081.69 138.927C1081.69 132.001 1087.32 126.367 1094.25 126.367C1101.17 126.367 1106.81 132.001 1106.81 138.927C1106.81 145.852 1101.17 151.486 1094.25 151.486ZM1094.25 127.548C1087.97 127.548 1082.87 132.653 1082.87 138.927C1082.87 145.2 1087.97 150.305 1094.25 150.305C1100.52 150.305 1105.62 145.2 1105.62 138.927C1105.62 132.653 1100.52 127.548 1094.25 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1125.82 151.486C1118.9 151.486 1113.26 145.851 1113.26 138.927C1113.26 132.001 1118.9 126.367 1125.82 126.367C1132.75 126.367 1138.38 132.001 1138.38 138.927C1138.38 145.852 1132.75 151.486 1125.82 151.486ZM1125.82 127.548C1119.55 127.548 1114.44 132.653 1114.44 138.927C1114.44 145.2 1119.55 150.305 1125.82 150.305C1132.09 150.305 1137.2 145.2 1137.2 138.927C1137.2 132.653 1132.1 127.548 1125.82 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1157.4 151.486C1150.47 151.486 1144.84 145.851 1144.84 138.927C1144.84 132.001 1150.47 126.367 1157.4 126.367C1164.32 126.367 1169.96 132.001 1169.96 138.927C1169.96 145.852 1164.32 151.486 1157.4 151.486ZM1157.4 127.548C1151.13 127.548 1146.02 132.653 1146.02 138.927C1146.02 145.2 1151.13 150.305 1157.4 150.305C1163.67 150.305 1168.78 145.2 1168.78 138.927C1168.78 132.653 1163.67 127.548 1157.4 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1188.98 151.486C1182.05 151.486 1176.42 145.851 1176.42 138.927C1176.42 132.001 1182.05 126.367 1188.98 126.367C1195.9 126.367 1201.54 132.001 1201.54 138.927C1201.54 145.852 1195.9 151.486 1188.98 151.486ZM1188.98 127.548C1182.7 127.548 1177.6 132.653 1177.6 138.927C1177.6 145.2 1182.7 150.305 1188.98 150.305C1195.25 150.305 1200.36 145.2 1200.36 138.927C1200.36 132.653 1195.25 127.548 1188.98 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1220.55 151.486C1213.63 151.486 1207.99 145.851 1207.99 138.927C1207.99 132.001 1213.63 126.367 1220.55 126.367C1227.48 126.367 1233.11 132.001 1233.11 138.927C1233.11 145.852 1227.48 151.486 1220.55 151.486ZM1220.55 127.548C1214.28 127.548 1209.17 132.653 1209.17 138.927C1209.17 145.2 1214.28 150.305 1220.55 150.305C1226.83 150.305 1231.93 145.2 1231.93 138.927C1231.93 132.653 1226.83 127.548 1220.55 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1252.13 151.486C1245.21 151.486 1239.57 145.851 1239.57 138.927C1239.57 132.001 1245.21 126.367 1252.13 126.367C1259.06 126.367 1264.69 132.001 1264.69 138.927C1264.69 145.852 1259.06 151.486 1252.13 151.486ZM1252.13 127.548C1245.86 127.548 1240.76 132.653 1240.76 138.927C1240.76 145.2 1245.86 150.305 1252.13 150.305C1258.41 150.305 1263.51 145.2 1263.51 138.927C1263.51 132.653 1258.41 127.548 1252.13 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1283.71 151.486C1276.78 151.486 1271.15 145.851 1271.15 138.927C1271.15 132.001 1276.78 126.367 1283.71 126.367C1290.63 126.367 1296.27 132.001 1296.27 138.927C1296.27 145.852 1290.63 151.486 1283.71 151.486ZM1283.71 127.548C1277.43 127.548 1272.33 132.653 1272.33 138.927C1272.33 145.2 1277.43 150.305 1283.71 150.305C1289.98 150.305 1295.09 145.2 1295.09 138.927C1295.09 132.653 1289.98 127.548 1283.71 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1315.29 151.486C1308.36 151.486 1302.73 145.851 1302.73 138.927C1302.73 132.001 1308.36 126.367 1315.29 126.367C1322.21 126.367 1327.85 132.001 1327.85 138.927C1327.85 145.852 1322.21 151.486 1315.29 151.486ZM1315.29 127.548C1309.01 127.548 1303.91 132.653 1303.91 138.927C1303.91 145.2 1309.01 150.305 1315.29 150.305C1321.56 150.305 1326.66 145.2 1326.66 138.927C1326.66 132.653 1321.56 127.548 1315.29 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1346.87 151.486C1339.94 151.486 1334.31 145.851 1334.31 138.927C1334.31 132.001 1339.94 126.367 1346.87 126.367C1353.79 126.367 1359.43 132.001 1359.43 138.927C1359.43 145.852 1353.79 151.486 1346.87 151.486ZM1346.87 127.548C1340.59 127.548 1335.49 132.653 1335.49 138.927C1335.49 145.2 1340.59 150.305 1346.87 150.305C1353.14 150.305 1358.25 145.2 1358.25 138.927C1358.25 132.653 1353.14 127.548 1346.87 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1378.44 151.486C1371.51 151.486 1365.88 145.851 1365.88 138.927C1365.88 132.001 1371.51 126.367 1378.44 126.367C1385.36 126.367 1391 132.001 1391 138.927C1391 145.852 1385.36 151.486 1378.44 151.486ZM1378.44 127.548C1372.16 127.548 1367.06 132.653 1367.06 138.927C1367.06 145.2 1372.16 150.305 1378.44 150.305C1384.71 150.305 1389.82 145.2 1389.82 138.927C1389.82 132.653 1384.71 127.548 1378.44 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1410.02 151.486C1403.09 151.486 1397.46 145.851 1397.46 138.927C1397.46 132.001 1403.09 126.367 1410.02 126.367C1416.94 126.367 1422.58 132.001 1422.58 138.927C1422.58 145.852 1416.94 151.486 1410.02 151.486ZM1410.02 127.548C1403.74 127.548 1398.64 132.653 1398.64 138.927C1398.64 145.2 1403.74 150.305 1410.02 150.305C1416.29 150.305 1421.39 145.2 1421.39 138.927C1421.39 132.653 1416.29 127.548 1410.02 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <path
              d="M1441.59 151.486C1434.67 151.486 1429.03 145.851 1429.03 138.927C1429.03 132.001 1434.67 126.367 1441.59 126.367C1448.52 126.367 1454.15 132.001 1454.15 138.927C1454.15 145.852 1448.52 151.486 1441.59 151.486ZM1441.59 127.548C1435.32 127.548 1430.21 132.653 1430.21 138.927C1430.21 145.2 1435.32 150.305 1441.59 150.305C1447.86 150.305 1452.97 145.2 1452.97 138.927C1452.97 132.653 1447.87 127.548 1441.59 127.548Z"
              fill="#E2E8FE" fill-opacity="0.5" />
            <rect x="6" y="-13" width="944.143" height="175.993" fill="url(#paint0_linear_1630_46709)" />
            <rect x="954" y="-25" width="944.143" height="175.993" fill="url(#paint1_linear_1630_46709)" />
          </g>
          <defs>
            <linearGradient id="paint0_linear_1630_46709" x1="452.191" y1="-13" x2="452.191" y2="152.64"
              gradientUnits="userSpaceOnUse">
              <stop stop-opacity="0" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient id="paint1_linear_1630_46709" x1="1400.19" y1="-25" x2="1400.19" y2="140.64"
              gradientUnits="userSpaceOnUse">
              <stop stop-opacity="0" />
              <stop offset="1" />
            </linearGradient>
            <clipPath id="clip0_1630_46709">
              <rect width="146" height="1454.97" fill="white" transform="translate(0 146) rotate(-90)" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </header>
    <main>
      <div class="left-section">
        <div class="profile-card" style="min-width: 300px; max-width: 100%">
          <div class="profile-image">
            <svg width="110" height="110" viewBox="0 0 171 171" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1626_45697)">
                <circle cx="85.5" cy="85.332" r="82.5" fill="black" />
                <path
                  d="M107.223 134.984C107.618 135.233 108.254 135.651 108.994 136.228C116.582 142.142 115.029 149.132 119.851 154.636C124.314 159.73 127.25 155.569 138.864 162.408C146.344 166.812 146.232 169.192 151.497 170.334C157.364 171.608 163.035 169.852 164.825 172.932C165.004 173.24 165.427 174.081 165.262 175.06C164.654 178.632 156.712 180.568 152.762 181.062C151.693 181.194 150.379 181.246 148.521 181.82C146.296 182.511 145.75 183.021 143.769 183.655C143.233 183.827 140.732 184.6 137.954 184.522C134.751 184.433 134.288 183.314 130.907 182.579C125.525 181.407 123.794 183.636 120.355 182.189C117.345 180.922 115.951 178.067 115.116 176.49C112.476 171.483 107.471 164.101 97.6328 152.51L107.228 134.989L107.223 134.984Z"
                  fill="#5A57DE" />
                <path
                  d="M105.651 146.321C108.083 152.271 111.246 155.63 113.57 157.561C118.184 161.398 120.465 160.417 128.996 165.847C134.767 169.518 134.594 170.522 138.131 171.723C143.045 173.39 148.305 173.12 148.697 175.127C148.782 175.565 148.6 175.941 148.527 176.092C147.726 177.742 144.589 177.501 141.286 177.621C135.266 177.848 135.764 179.018 130.122 179.108C128.767 179.129 125.616 179.113 121.862 178.404C118.531 177.775 112.882 176.25 106.655 172.076C106.116 168.775 105.575 165.473 105.036 162.173L100.277 152.658L105.655 146.318L105.651 146.321Z"
                  fill="#C3CDEF" />
                <path
                  d="M36.7978 138.336C42.8021 122.467 54.4917 113.409 59.6048 113.13C59.7939 113.118 61.8623 113.007 62.1365 113.763C62.51 114.791 59.3991 117.004 59.7395 117.477C59.827 117.6 60.0823 117.493 60.3282 117.727C60.5102 117.9 60.6 118.16 60.626 118.498C60.7064 119.597 60.1816 120.519 59.7986 121.465C57.7775 126.505 56.1204 131.667 54.4302 136.823C52.3996 143.016 49.5345 154.366 49.3407 155.451C49.3171 155.59 49.2131 156.235 48.8443 157.023C48.8372 157.042 48.7403 157.247 48.6788 157.351C48.6575 157.387 48.102 158.276 45.9603 158.294C43.4546 158.316 39.7952 157.141 39.5328 155.117C39.495 154.827 39.5352 154.578 39.5754 154.415C38.4904 153.938 37.0555 153.099 36.0177 151.597C34.5001 149.406 33.9186 145.931 36.7954 138.331L36.7978 138.336Z"
                  fill="#C3CDEF" />
                <path
                  d="M133.518 138.81C127.348 123.005 115.561 114.07 110.448 113.845C110.257 113.838 108.188 113.746 107.924 114.504C107.562 115.54 110.694 117.717 110.358 118.192C110.271 118.315 110.016 118.213 109.775 118.45C109.595 118.625 109.507 118.885 109.484 119.223C109.415 120.322 109.95 121.239 110.342 122.18C112.415 127.199 114.129 132.343 115.871 137.482C117.968 143.654 120.946 154.972 121.159 156.055C121.185 156.194 121.294 156.84 121.672 157.622C121.679 157.641 121.779 157.844 121.84 157.948C121.861 157.984 122.426 158.868 124.57 158.863C127.076 158.859 130.724 157.646 130.967 155.618C131.003 155.327 130.96 155.079 130.92 154.916C132 154.429 133.426 153.575 134.447 152.06C135.929 149.861 136.475 146.382 133.522 138.813L133.518 138.81Z"
                  fill="#C3CDEF" />
                <path
                  d="M64.3502 104.741C63.6197 106.558 62.5583 109.211 61.3007 112.421C61.3007 112.421 49.4103 142.74 45.3301 176.556C45.1718 177.856 44.8385 180.676 46.4814 182.958C48.7933 186.17 53.4005 186.522 57.5941 186.749C60.0928 186.886 73.9051 187.629 77.406 180.894C78.0135 179.724 78.7582 177.469 79.1482 175.112C79.4697 173.164 79.4886 171.5 79.4414 170.318C77.6401 169.788 75.8364 169.256 74.0375 168.727C76.9096 169.006 80.0914 169.178 83.5427 169.152C87.1997 169.126 90.5635 168.881 93.5704 168.526C92.0812 169.131 90.5919 169.736 89.1026 170.341C88.767 175.885 90.1995 179.386 91.0694 181.067C91.6155 182.118 92.0741 182.714 92.2727 182.962C92.7549 183.563 94.7358 186.905 99.9908 187.163C104.712 187.395 118.689 188.657 122.85 181.107C124.297 178.48 124.027 175.762 123.852 174.18C120.027 139.977 108.872 113.953 108.872 113.953C107.822 111.506 106.501 108.506 104.924 105.095C101.835 106.885 94.4947 110.577 84.4718 110.459C75.1958 110.348 68.123 106.949 64.3525 104.738L64.3502 104.741Z"
                  fill="#E1E7FF" />
                <path
                  d="M42.5234 127.221C43.8732 128.653 45.7265 130.358 48.1543 131.968C50.2629 133.362 52.2604 134.322 53.9222 134.986C53.2012 137.53 52.4968 140.14 51.8112 142.813C51.0808 145.667 50.4071 148.463 49.783 151.203C56.662 154.144 67.8385 157.912 82.0314 158.37C99.2714 158.928 112.717 154.342 120.074 151.203C119.54 148.742 118.96 146.224 118.322 143.655C117.648 140.927 116.944 138.277 116.223 135.712C118.308 135.154 120.75 134.336 123.376 133.105C125.591 132.067 127.487 130.949 129.066 129.892C127.797 127.677 125.641 124.365 122.263 121.046C107.886 106.92 87.0713 108.069 79.1096 108.508C71.4199 108.931 62.4252 109.548 53.4707 115.758C47.9793 119.571 44.5304 124.079 42.5258 127.223L42.5234 127.221Z"
                  fill="#28B798" />
                <path
                  d="M45.8294 70.9887C42.9289 68.7312 37.9765 64.1547 35.1965 56.7651C34.1895 54.0797 31.185 46.0991 34.5441 38.6221C35.2533 37.0406 36.6503 34.0527 39.8747 32.5894C40.338 32.379 43.8981 30.8188 46.4511 32.3861C49.3209 34.1449 49.4084 38.8443 49.3374 41.7637C49.1105 51.3234 48.9379 59.9091 52.9637 65.8567L45.8294 70.9887Z"
                  fill="#5A57DE" />
                <path
                  d="M45.7472 77.1829C44.5369 76.6368 42.7427 75.7858 40.6388 74.618C27.791 67.4814 24.4295 60.1864 18.6285 61.2288C15.1535 61.8529 13.2553 65.0253 12.922 65.6139C9.91038 70.9185 13.0213 78.2395 17.2669 81.9461C21.7016 85.8135 28.1668 86.9647 33.8615 86.9789C35.495 86.9836 37.1308 86.8914 38.7572 86.7141C39.2867 86.6551 43.5512 86.3407 43.6505 85.9293C44.3502 83.0123 45.0499 80.0952 45.7496 77.1781L45.7472 77.1829Z"
                  fill="#5A57DE" />
                <path
                  d="M46.9954 83.4712C45.135 83.6532 42.5087 83.7265 39.464 83.1828C31.6679 81.7904 23.8646 76.8451 24.4532 74.9233C24.465 74.8831 24.5029 74.7744 24.6163 74.6491C26.0063 73.1149 31.4031 77.4527 38.4546 79.1665C40.4569 79.6511 43.3952 80.1215 47.1727 79.8544C46.9931 80.1523 46.7307 80.6818 46.648 81.3909C46.5345 82.3743 46.8418 83.1402 46.9978 83.4735L46.9954 83.4712Z"
                  fill="#E1E7FF" />
                <path
                  d="M44.3921 88.416C36.3973 89.676 27.2254 92.3614 21.7506 98.7416C18.3749 102.68 16.7083 109.956 20.4551 114.242C20.6868 114.509 23.0436 117.126 26.1687 116.882C28.665 116.686 30.4025 114.79 31.3008 113.677C37.3902 106.143 38.6005 98.9189 46.3092 95.2619L44.3921 88.416Z"
                  fill="#5A57DE" />
                <path
                  d="M45.9178 90.3613C42.119 91.3447 39.3721 92.8056 37.6015 93.9308C32.2189 97.349 27.0301 103.247 27.9804 104.561C28.0254 104.623 28.0703 104.658 28.101 104.68C29.2782 105.528 32.9943 101.725 36.2919 99.2189C38.4289 97.5925 41.7289 95.4981 46.5158 93.8576C46.3173 92.6898 46.1163 91.5244 45.9178 90.3613Z"
                  fill="#E1E7FF" />
                <path
                  d="M48.3795 69.6707C47.2495 68.484 45.5263 66.4534 44.1103 63.5718C40.4013 56.0191 40.9048 46.9205 42.9898 45.7527C43.0867 45.6983 43.29 45.5872 43.5193 45.6345C44.6611 45.8685 44.5192 49.6106 44.8549 52.4544C45.8028 60.52 51.2635 67.2737 51.2635 67.2737L48.3771 69.6731L48.3795 69.6707Z"
                  fill="#E1E7FF" />
                <path
                  d="M126.481 68.8397C129.561 66.8304 134.873 62.6841 138.258 55.5498C139.487 52.959 143.142 45.2526 140.419 37.525C139.842 35.8915 138.7 32.7948 135.608 31.0715C135.166 30.8233 131.746 28.9724 129.072 30.3222C126.068 31.8398 125.59 36.5109 125.417 39.428C124.85 48.9734 124.306 57.5449 119.801 63.1356L126.486 68.8444L126.481 68.8397Z"
                  fill="#5A57DE" />
                <path
                  d="M126.053 75.0195C127.304 74.5775 129.164 73.8777 131.355 72.8873C144.752 66.8427 148.709 59.855 154.403 61.375C157.815 62.2875 159.439 65.6064 159.725 66.2187C162.287 71.7573 158.576 78.7923 154.035 82.1349C149.295 85.6216 142.757 86.2268 137.078 85.7706C135.45 85.6405 133.828 85.4113 132.223 85.0992C131.701 84.9976 127.474 84.3286 127.41 83.9102C126.956 80.9482 126.502 77.9839 126.051 75.0195H126.053Z"
                  fill="#5A57DE" />
                <path
                  d="M124.281 81.1831C126.12 81.5188 128.73 81.8119 131.81 81.5212C139.694 80.7836 147.885 76.5026 147.455 74.5382C147.448 74.498 147.417 74.3869 147.313 74.2498C146.055 72.6069 140.313 76.4813 133.151 77.5995C131.115 77.9162 128.146 78.1408 124.407 77.5593C124.56 77.8713 124.78 78.4197 124.801 79.136C124.832 80.1288 124.463 80.8664 124.281 81.1831Z"
                  fill="#E1E7FF" />
                <path
                  d="M126.46 86.3223C134.325 88.2441 143.242 91.6812 148.163 98.4964C151.201 102.702 152.253 110.091 148.166 114.051C147.913 114.297 145.345 116.713 142.251 116.204C139.781 115.8 138.206 113.765 137.405 112.583C131.963 104.569 131.356 97.2695 123.98 92.9838C123.98 92.9861 126.46 86.3223 126.46 86.3223Z"
                  fill="#5A57DE" />
                <path
                  d="M124.785 88.1352C128.489 89.4306 131.104 91.1161 132.775 92.3855C137.857 96.241 142.535 102.548 141.479 103.777C141.429 103.836 141.38 103.865 141.349 103.884C140.103 104.631 136.718 100.532 133.64 97.761C131.643 95.9644 128.532 93.5982 123.898 91.5676C124.194 90.4211 124.487 89.2769 124.783 88.1328L124.785 88.1352Z"
                  fill="#E1E7FF" />
                <path
                  d="M124.048 67.3148C125.272 66.2274 127.156 64.3458 128.806 61.5918C133.13 54.3748 133.383 45.2643 131.404 43.9263C131.315 43.8649 131.118 43.7372 130.884 43.7632C129.728 43.9027 129.556 47.6424 128.986 50.4507C127.372 58.41 121.367 64.6862 121.367 64.6862L124.05 67.3172L124.048 67.3148Z"
                  fill="#E1E7FF" />
                <path
                  d="M129.639 83.7299C129.332 74.1489 124.637 67.2983 122.845 64.9368C112.274 51.0086 94.1497 50.1671 87.7885 49.8716C83.9637 49.6919 57.6959 48.4698 45.0655 66.6648C43.6023 68.7734 38.7894 75.9195 39.1392 85.7605C39.4891 95.6463 44.9615 105.766 53.6773 110.56C57.0175 112.397 59.9274 112.953 63.7641 113.683C72.6902 115.387 74.3142 113.191 85.5215 113.595C95.5185 113.955 98.5064 115.853 106.955 114.312C109.863 113.782 111.678 113.451 113.888 112.458C123.98 107.939 130.006 95.1854 129.637 83.7322L129.639 83.7299Z"
                  fill="#E1E7FF" />
                <path
                  d="M129.735 85.6071C129.312 77.1041 126.884 69.8611 122.525 64.0766C115.18 54.3302 101.725 48.1462 86.5299 47.534C71.5309 46.9288 57.8533 51.8859 49.9413 60.7931C45.4121 65.8897 42.5376 72.4898 41.3934 80.4018L37.9492 79.903C39.1926 71.3031 42.3508 64.0955 47.3387 58.4789C55.9362 48.801 70.635 43.4066 86.6694 44.0543C102.893 44.7067 117.334 51.4108 125.305 61.9775C130.094 68.3316 132.754 76.22 133.212 85.4322L129.735 85.6071Z"
                  fill="#242424" />
                <path
                  d="M43.7548 100.396L38.0554 100.758C34.5497 100.983 31.5286 98.3209 31.3041 94.8176L30.1812 77.2396C29.9566 73.7339 32.6184 70.7129 36.1217 70.4883C38.3863 70.6396 40.6462 70.7956 42.9085 70.9469C42.3648 75.5967 42.0527 81.0479 42.3104 87.1278C42.5232 92.0424 43.0669 96.4889 43.7524 100.394L43.7548 100.396Z"
                  fill="#242424" />
                <path
                  d="M127.3 69.1387L133.011 69.3112C136.522 69.4176 139.283 72.3489 139.176 75.8593L138.642 93.4633C138.536 96.9737 135.604 99.7347 132.094 99.6284L126.383 99.4558C127.345 96.2858 128.227 92.4137 128.64 87.9507C129.352 80.2372 128.387 73.727 127.3 69.1387Z"
                  fill="#242424" />
                <path
                  d="M84.6801 96.2708C84.2594 96.2708 83.8386 96.2661 83.4155 96.2543C79.7632 96.1574 76.1157 95.6373 72.5746 94.7154C71.4635 94.4246 70.7969 93.2852 71.0877 92.1742C71.3784 91.0631 72.5178 90.3965 73.6289 90.6873C76.8627 91.5336 80.1935 92.004 83.5289 92.0914C87.4436 92.1955 91.3629 91.7723 95.183 90.8338C96.3011 90.5596 97.4287 91.2428 97.7029 92.3586C97.9771 93.4743 97.294 94.6043 96.1782 94.8785C92.4054 95.8028 88.5451 96.2708 84.6825 96.2708H84.6801Z"
                  fill="#242424" />
                <path
                  d="M90.7849 139.549C89.9102 141.45 89.3169 141.223 88.2248 141.223H87.3359L89.9457 135.809C90.2625 135.15 90.2743 134.386 89.9717 133.72L87.4754 128.207H90.0119L92.4987 133.852C92.7847 134.5 92.7777 135.242 92.4798 135.883L90.7825 139.549H90.7849Z"
                  fill="#E1E7FF" />
                <path
                  d="M88.7601 141.479C88.6892 141.479 88.6135 141.479 88.5355 141.477C88.4362 141.474 88.3322 141.472 88.2258 141.472H86.9375L89.7222 135.699C90.0082 135.104 90.0177 134.423 89.7458 133.822L87.0912 127.955H90.1784L92.7314 133.749C93.0458 134.463 93.0387 135.278 92.7102 135.988L91.0152 139.654C90.2612 141.297 89.6654 141.481 88.7601 141.481V141.479ZM87.7389 140.971H88.2258C88.3369 140.971 88.4457 140.973 88.545 140.975C89.4078 140.994 89.838 141.004 90.5543 139.446L92.2492 135.78C92.5163 135.2 92.521 134.536 92.2657 133.952L89.8451 128.459H87.8641L90.1997 133.614C90.533 134.349 90.5212 135.191 90.1713 135.917L87.7365 140.968L87.7389 140.971Z"
                  fill="#E1E7FF" />
                <path
                  d="M78.3859 139.549C79.2606 141.45 79.8539 141.223 80.946 141.223H81.8349L79.2251 135.809C78.9083 135.15 78.8965 134.386 79.2015 133.72L81.6978 128.207H79.1613L76.6745 133.852C76.3884 134.5 76.3955 135.242 76.6934 135.883L78.3883 139.549H78.3859Z"
                  fill="#E1E7FF" />
                <path
                  d="M80.4129 141.481C79.5028 141.481 78.9118 141.297 78.1577 139.654L76.4628 135.988C76.1342 135.278 76.1271 134.463 76.4415 133.749L78.9946 127.955H82.0818L79.4272 133.822C79.1553 134.423 79.1624 135.108 79.4484 135.699L82.2331 141.472H80.9448C80.836 141.472 80.7344 141.474 80.6351 141.477C80.5595 141.479 80.4862 141.479 80.4129 141.479V141.481ZM79.3231 128.461L76.9025 133.955C76.6472 134.536 76.6519 135.203 76.919 135.782L78.614 139.448C79.3302 141.009 79.7605 140.999 80.6233 140.978C80.7249 140.975 80.8313 140.973 80.9424 140.973H81.4294L78.9922 135.924C78.64 135.196 78.6329 134.357 78.9662 133.621L81.3017 128.466H79.3208L79.3231 128.461Z"
                  fill="#E1E7FF" />
                <path
                  d="M86.3408 142.662L86.7191 141.707H82.4688L82.847 142.662C82.9794 142.995 83.3032 143.213 83.6602 143.213H85.53C85.8846 143.213 86.2085 142.995 86.3408 142.662Z"
                  fill="#E1E7FF" />
                <path
                  d="M85.5253 143.462H83.6602C83.1945 143.462 82.7832 143.184 82.613 142.751L82.0977 141.453H87.0879L86.5725 142.751C86.4023 143.184 85.991 143.462 85.5253 143.462ZM82.8376 141.957L83.081 142.567C83.178 142.805 83.4025 142.961 83.6602 142.961H85.5253C85.783 142.961 86.0099 142.808 86.1045 142.567L86.348 141.957H82.8399H82.8376Z"
                  fill="#E1E7FF" />
                <path
                  d="M59.9009 81.4154C59.8773 84.0465 57.7285 86.1622 55.0975 86.1362C52.4688 86.1125 50.3555 83.9614 50.3791 81.3303C50.4027 78.6993 52.5515 76.5836 55.1826 76.6096C57.8136 76.6333 59.9269 78.7844 59.9009 81.4154Z"
                  fill="white" />
                <path
                  d="M118.795 81.9447C118.772 84.5758 116.623 86.6915 113.992 86.6655C111.363 86.6418 109.252 84.4907 109.274 81.8596C109.297 79.2286 111.446 77.1129 114.077 77.1389C116.706 77.1625 118.817 79.3137 118.795 81.9447Z"
                  fill="white" />
                <path
                  d="M58.6983 81.4062C58.6794 83.373 57.072 84.9544 55.1076 84.9379C53.1432 84.9213 51.5617 83.3115 51.5783 81.3424C51.5972 79.3756 53.2046 77.7941 55.169 77.8107C57.1334 77.8272 58.7173 79.4394 58.6983 81.4062Z"
                  fill="black" />
                <path
                  d="M117.599 81.9355C117.58 83.9022 115.973 85.4837 114.006 85.4672C112.039 85.4482 110.46 83.8408 110.477 81.8717C110.496 79.9049 112.103 78.3234 114.067 78.34C116.039 78.3589 117.616 79.9687 117.599 81.9355Z"
                  fill="black" />
                <path
                  d="M53.7151 80.6119C54.0885 80.6119 54.3912 80.3092 54.3912 79.9358C54.3912 79.5625 54.0885 79.2598 53.7151 79.2598C53.3418 79.2598 53.0391 79.5625 53.0391 79.9358C53.0391 80.3092 53.3418 80.6119 53.7151 80.6119Z"
                  fill="white" />
                <path
                  d="M112.758 81.1451C113.132 81.1451 113.434 80.8424 113.434 80.4691C113.434 80.0957 113.132 79.793 112.758 79.793C112.385 79.793 112.082 80.0957 112.082 80.4691C112.082 80.8424 112.385 81.1451 112.758 81.1451Z"
                  fill="white" />
              </g>
              <rect x="1.87423" y="1.70626" width="167.252" height="167.252" rx="83.6258" stroke="#B9A3FF"
                stroke-width="2.25154" />
              <defs>
                <clipPath id="clip0_1626_45697">
                  <rect x="3" y="2.83203" width="165" height="165" rx="82.5" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="profile-content" style="width: 100%">
            <h1 style="
                font-size: 18px !important;
                width: 100%;
                line-height: 1.4rem;
              ">
              {{ userProfile.fullname ? userProfile.fullname : null }}
            </h1>
            <div style="gap: 15px">
              <div style="display: flex; flex-direction: column">
                <p style="font-size: 8px !important; margin-bottom: 2px">
                  <strong>{{ $t('profile.specialty') }}</strong>
                </p>
                <p style="
                    margin-top: 0;
                    font-size: 11px !important;
                    word-break: break-word;
                    overflow-wrap: break-word;
                  ">
                  {{ userProfile.speciality ? userProfile.speciality : null }}
                </p>
              </div>
              <div style="display: flex; flex-direction: column">
                <p style="font-size: 8px !important; margin-bottom: 2px">
                  <strong>Experiencia</strong>
                </p>
                <p style="font-size: 13px !important; margin-top: 0">
                  {{ userProfile.yearsOfExp ? userProfile.yearsOfExp : null }}
                  Años
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="contact-info !block !space-y-3 mb-6">
          <div class="contact-item" v-if="userProfile.email">
            <svg width="15" height="12" viewbox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.56617 11.5285C1.20174 11.5285 0.897474 11.4065 0.653377 11.1624C0.40928 10.9183 0.287231 10.614 0.287231 10.2496V1.72413C0.287231 1.3597 0.40928 1.05543 0.653377 0.811336C0.897474 0.567239 1.20174 0.44519 1.56617 0.44519H13.2583C13.6227 0.44519 13.927 0.567239 14.1711 0.811336C14.4152 1.05543 14.5372 1.3597 14.5372 1.72413V10.2496C14.5372 10.614 14.4152 10.9183 14.1711 11.1624C13.927 11.4065 13.6227 11.5285 13.2583 11.5285H1.56617ZM7.41223 6.07829L1.0789 1.93709V10.2496C1.0789 10.3917 1.12455 10.5085 1.21586 10.5999C1.30729 10.6912 1.42406 10.7369 1.56617 10.7369H13.2583C13.4004 10.7369 13.5172 10.6912 13.6086 10.5999C13.6999 10.5085 13.7456 10.3917 13.7456 10.2496V1.93709L7.41223 6.07829ZM7.41223 5.19519L13.5019 1.23686H1.32253L7.41223 5.19519ZM1.0789 1.93709V1.23686V10.2496C1.0789 10.3917 1.12455 10.5085 1.21586 10.5999C1.30729 10.6912 1.42406 10.7369 1.56617 10.7369H1.0789V1.93709Z"
                fill="#B9A3FF" />
            </svg>
            <span style="font-size: 8px !important; min-width: 120px">{{ userProfile.email ? userProfile.email : null
              }}</span>
          </div>
          <div class="contact-item" v-if="userProfile.country || userProfile.city">
            <svg width="11" height="16" viewbox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.41223 12.5318C6.85042 11.3951 7.93567 10.3056 8.66796 9.2632C9.40025 8.22084 9.76639 7.20995 9.76639 6.23053C9.76639 5.42012 9.61849 4.72906 9.32267 4.15735C9.02671 3.58563 8.658 3.12297 8.21651 2.76936C7.77502 2.41562 7.30187 2.15852 6.79705 1.99808C6.2921 1.83777 5.83049 1.75761 5.41223 1.75761C4.99396 1.75761 4.53236 1.83777 4.02741 1.99808C3.52259 2.15852 3.04943 2.41562 2.60795 2.76936C2.16646 3.12297 1.79774 3.58563 1.50179 4.15735C1.20597 4.72906 1.05806 5.42012 1.05806 6.23053C1.05806 7.20995 1.42421 8.22084 2.1565 9.2632C2.88879 10.3056 3.97403 11.3951 5.41223 12.5318ZM5.41223 13.5412C3.68376 12.2401 2.394 10.9836 1.54296 9.77165C0.691916 8.55987 0.266396 7.37943 0.266396 6.23033C0.266396 5.3624 0.421958 4.60141 0.733083 3.94736C1.04408 3.29344 1.44486 2.74416 1.93543 2.29951C2.426 1.85498 2.97785 1.52163 3.591 1.29943C4.20428 1.07711 4.81135 0.965942 5.41223 0.965942C6.0131 0.965942 6.62018 1.07711 7.23346 1.29943C7.8466 1.52163 8.39846 1.85498 8.88903 2.29951C9.3796 2.74416 9.78038 3.29344 10.0914 3.94736C10.4025 4.60141 10.5581 5.3624 10.5581 6.23033C10.5581 7.37943 10.1325 8.55987 9.2815 9.77165C8.43046 10.9836 7.1407 12.2401 5.41223 13.5412ZM5.41223 7.29928C5.74618 7.29928 6.02755 7.18481 6.25634 6.95589C6.48527 6.7271 6.59973 6.44573 6.59973 6.11178C6.59973 5.77782 6.48527 5.49645 6.25634 5.26766C6.02755 5.03874 5.74618 4.92428 5.41223 4.92428C5.07828 4.92428 4.79691 5.03874 4.56811 5.26766C4.33919 5.49645 4.22473 5.77782 4.22473 6.11178C4.22473 6.44573 4.33919 6.7271 4.56811 6.95589C4.79691 7.18481 5.07828 7.29928 5.41223 7.29928ZM0.266396 16.0076V15.2159H10.5581V16.0076H0.266396Z"
                fill="#B9A3FF" />
            </svg>
            <span style="
                font-size: 8px !important;
                word-wrap: break-word;
                min-width: 100px;
              ">{{ userProfile.city ? userProfile.city + "," : null }} {{ userProfile.country ? userProfile.country :
                null }}
            </span>
          </div>
          <div class="contact-item" v-if="userProfile.phonenumber">
            <svg width="13" height="13" viewbox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.8991 12.8201C10.5645 12.8201 9.19633 12.486 7.79468 11.8177C6.39303 11.1492 5.09081 10.2111 3.888 9.00329C2.68533 7.7956 1.74978 6.49344 1.08135 5.09681C0.413048 3.70018 0.0788984 2.33455 0.0788984 0.999932C0.0788984 0.758078 0.158065 0.556532 0.316398 0.395296C0.474732 0.23406 0.672648 0.153442 0.910148 0.153442H2.86794C3.08314 0.153442 3.2709 0.221196 3.43121 0.356703C3.59166 0.49221 3.70031 0.666046 3.75718 0.878213L4.14985 2.76594C4.1864 2.98721 4.1798 3.18058 4.13005 3.34604C4.08031 3.51149 3.9925 3.647 3.86663 3.75256L2.12971 5.37231C2.45443 5.96197 2.81345 6.5098 3.20677 7.01581C3.6001 7.52181 4.01955 8.0011 4.46513 8.45367C4.92482 8.9135 5.41961 9.34106 5.9495 9.73637C6.47926 10.1317 7.0608 10.5049 7.69414 10.8562L9.38712 9.13272C9.51603 8.99365 9.6609 8.90208 9.82174 8.85802C9.98258 8.81381 10.1604 8.80491 10.3553 8.8313L12.0208 9.17231C12.236 9.22508 12.4106 9.33348 12.5447 9.49748C12.6786 9.66136 12.7456 9.84938 12.7456 10.0615V11.9889C12.7456 12.2264 12.6649 12.4243 12.5037 12.5826C12.3425 12.7409 12.1409 12.8201 11.8991 12.8201ZM1.75822 4.62933L3.27763 3.23184C3.32829 3.1912 3.36128 3.13539 3.37659 3.0644C3.39176 2.99328 3.38919 2.92731 3.36887 2.86648L3.01717 1.18874C2.99685 1.10747 2.96136 1.04657 2.91069 1.00607C2.85989 0.965429 2.79392 0.945109 2.71277 0.945109H1.08827C1.02732 0.945109 0.976582 0.965429 0.936075 1.00607C0.895437 1.04657 0.875117 1.09731 0.875117 1.15827C0.890291 1.69924 0.975065 2.26403 1.12944 2.85263C1.28368 3.44137 1.49328 4.0336 1.75822 4.62933ZM8.4478 11.2277C8.98257 11.4926 9.55271 11.6885 10.1582 11.8153C10.7636 11.9422 11.2911 12.0102 11.7407 12.0193C11.8017 12.0193 11.8524 11.999 11.8929 11.9584C11.9336 11.9177 11.9539 11.867 11.9539 11.8062V10.2167C11.9539 10.1356 11.9336 10.0696 11.8929 10.0188C11.8524 9.96813 11.7915 9.93264 11.7103 9.91232L10.2457 9.61228C10.1849 9.59196 10.1316 9.58945 10.086 9.60476C10.0402 9.61993 9.99195 9.65292 9.94128 9.70371L8.4478 11.2277Z"
                fill="#B9A3FF" />
            </svg>
            <span style="font-size: 8px !important">{{
              userProfile.phonenumber
            }}</span>
          </div>
        </div>
        <div class="dark-box">
          <p style="
              font-size: 9px !important;
              padding: 5px 15px;
              margin-top: 0.7rem;
              margin-bottom: 0.7rem;
            ">
            {{ userProfile?.about ? userProfile.about : null }}
          </p>
          <p style="font-size: 12px !important; padding-left: 15px">
            <strong>Sueldo deseable bruto</strong>
          </p>
          <h2 style="
              padding-left: 12px;
              padding-bottom: 15px;
              font-weight: bold !important;
            ">
            {{ userProfile.minSalary ? userProfile.minSalary : null }}
          </h2>
        </div>
        <h2 style="
            padding-left: 15px;
            font-size: 18px !important;
            font-weight: bold;
          ">
          {{ $t('profile.languages') }}
        </h2>
        <div class="dark-box" style="padding-left: 20px; padding-right: 20px">
          <div class="languages" style="margin-top: 20px; margin-bottom: 20px !important">
            <div class="language-item" style="margin-left: 0px">
              <p style="
                  font-size: 12px;
                  padding-left: 0px;
                  margin-top: 20px !important;
                  font-weight: bold !important;
                  margin-top: 0rem !important;
                  margin-bottom: 0rem;
                ">
                {{ $t('profile.ingles') }}
              </p>
              <div class="flex">
                <span v-for="star in 5" :key="star" class="mr-1">
                  <svg v-if="star <= this.englishLevel" width="18" height="16" viewBox="0 0 22 20" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.3206 0.369775C10.4702 -0.09088 11.1219 -0.09088 11.2716 0.369775L13.3813 6.8628C13.4483 7.06882 13.6402 7.20829 13.8569 7.20829H20.684C21.1684 7.20829 21.3698 7.8281 20.9779 8.1128L15.4546 12.1257C15.2794 12.253 15.206 12.4787 15.273 12.6847L17.3827 19.1778C17.5324 19.6384 17.0051 20.0215 16.6133 19.7368L11.09 15.7239C10.9147 15.5965 10.6774 15.5965 10.5022 15.7239L4.97889 19.7368C4.58703 20.0215 4.05979 19.6384 4.20947 19.1778L6.31918 12.6847C6.38612 12.4787 6.31279 12.253 6.13755 12.1257L0.614247 8.1128C0.22239 7.8281 0.423778 7.20829 0.90814 7.20829H7.73531C7.95193 7.20829 8.1439 7.06881 8.21084 6.8628L10.3206 0.369775Z"
                      fill="#B9A3FF" />
                  </svg>
                  <svg v-else width="18" height="16" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.5862 0.369775C10.7359 -0.09088 11.3876 -0.09088 11.5372 0.369775L13.6469 6.8628C13.7139 7.06882 13.9059 7.20829 14.1225 7.20829H20.9496C21.434 7.20829 21.6354 7.8281 21.2435 8.1128L15.7202 12.1257C15.545 12.253 15.4717 12.4787 15.5386 12.6847L17.6483 19.1778C17.798 19.6384 17.2708 20.0215 16.8789 19.7368L11.3556 15.7239C11.1804 15.5965 10.9431 15.5965 10.7678 15.7239L5.24452 19.7368C4.85266 20.0215 4.32542 19.6384 4.47509 19.1778L6.58481 12.6847C6.65174 12.4787 6.57841 12.253 6.40317 12.1257L0.879872 8.1128C0.488015 7.8281 0.689403 7.20829 1.17376 7.20829H8.00094C8.21755 7.20829 8.40953 7.06881 8.47647 6.8628L10.5862 0.369775Z"
                      fill="#333333" />
                  </svg>
                </span>
              </div>
              <span class="level">{{
                englishLevelDescription(this.englishLevel)
              }}</span>
            </div>
          </div>
        </div>
        <h2 style="
            padding-left: 15px;
            font-size: 18px !important;
            font-weight: bold;
          ">
          {{ $t('profile.preferences') }}
        </h2>
        <div class="dark-box">
          <p style="
              padding-left: 14px;
              font-size: 10px !important;
              margin-top: 13px !important;
              margin-bottom: 8px;
            ">
            {{ $t('profile.people_in_charge') }}
          </p>
          <div class="pill px-4 py-2">{{ this.personsInCharge ?? 0 }}</div>
          <p v-if="this.jobTypePreference?.length > 0" style="
              padding-left: 14px;
              margin-top: 13px !important;
              margin-bottom: 8px;
              font-size: 10px !important;
            ">
            {{ $t('profile.work_modality') }}
          </p>
          <div class="pill !px-2 !py-1" v-for="(preference, index) in this.jobTypePreference" :key="index">
            {{ preference }}
          </div>
          <div class="pill-section" v-if="industries?.length > 0">
            <p style="
                padding-left: 14px;
                margin-top: 13px !important;
                margin-bottom: 8px;
                font-size: 10px !important;
              ">
              {{ $t('profile.companies_looking') }}
            </p>
            <div v-for="industry in industries" :key="industry" class="pill !px-2 !py-1">
              {{ industry }}
            </div>
          </div>
        </div>
        <section v-if="
          userProfile?.socialNetworks?.linkedin ||
          userProfile?.socialNetworks?.github
        ">
          <h2 style="
              padding-left: 15px;
              font-size: 18px !important;
              font-weight: bold;
            ">
            {{ $t('profile.socialnetworks') }}
          </h2>
          <div class="dark-box">
            <!-- LinkedIn -->
            <div class="link-with-icon" style="margin-top: 15px" v-if="userProfile?.socialNetworks?.linkedin">
              <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20.9886 15.657C22.3369 13.8298 23.9158 12.6589 25.9382 12.2686C29.2379 11.6477 32.3957 11.8783 34.8793 14.4862C36.4937 16.1715 36.955 18.3713 37.1146 20.6066C37.4872 25.7335 37.2033 30.8783 37.292 36.023C37.3098 36.8213 36.9727 37.0519 36.2276 37.0519C34.3294 37.0342 32.4489 37.0164 30.5507 37.0519C29.6814 37.0696 29.4331 36.7326 29.4331 35.8988C29.4508 32.0846 29.4508 28.2704 29.4331 24.4562C29.4331 23.516 29.4331 22.5758 29.2202 21.6355C28.8299 19.9324 27.8542 18.9567 26.3462 18.8148C24.0932 18.6019 22.5675 19.347 21.8757 21.0501C21.5031 21.9903 21.3257 22.9838 21.3257 23.995C21.3257 27.8979 21.3257 31.8007 21.308 35.7036C21.308 37.0519 21.308 37.0519 19.9065 37.0519C18.097 37.0519 16.3052 37.0342 14.4957 37.0519C13.8393 37.0519 13.5377 36.839 13.5554 36.1294C13.5732 28.572 13.5732 20.9969 13.5554 13.4395C13.5554 12.7299 13.8215 12.4992 14.4957 12.4992C16.3584 12.517 18.2211 12.517 20.0839 12.4992C20.7048 12.4992 21.0064 12.7299 20.9709 13.3863C20.9709 14.0959 20.9886 14.77 20.9886 15.657Z"
                  fill="#5956d9" />
                <path
                  d="M1.3468 24.7174C1.3468 21.0451 1.36454 17.3906 1.32906 13.7184C1.31132 12.8491 1.54194 12.4943 2.46444 12.5298C4.36266 12.583 6.24314 12.5652 8.14136 12.5475C8.81549 12.5475 9.09934 12.7781 9.08159 13.4877C9.06385 21.0451 9.06385 28.6203 9.08159 36.1777C9.08159 36.8873 8.78001 37.1002 8.12362 37.1002C6.20766 37.0824 4.27396 37.0647 2.358 37.1002C1.59516 37.1179 1.32906 36.8518 1.32906 36.0712C1.36454 32.2925 1.3468 28.4961 1.3468 24.7174Z"
                  fill="#5956d9" />
                <path
                  d="M5.18408 9.44337C2.68269 9.44337 0.660292 7.38549 0.695772 4.90184C0.731253 2.56011 2.78914 0.50223 5.1486 0.48449C7.61452 0.466749 9.65466 2.50689 9.63692 5.00828C9.63692 7.45645 7.63226 9.44337 5.18408 9.44337Z"
                  fill="#5956d9" />
              </svg>
              <a :href="userProfile?.socialNetworks?.linkedin" target="_blank">
                {{ userProfile?.socialNetworks?.linkedin }}
              </a>
            </div>

            <!-- GitHub -->
            <div class="link-with-icon" v-if="userProfile?.socialNetworks?.github">
              <svg width="38" height="38" viewBox="0 0 41 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20.1366 0.121094C9.31929 0.121094 0.46875 8.97164 0.46875 19.789C0.46875 28.3937 6.12326 35.7691 13.9904 38.4734C14.9738 38.7193 15.2197 37.9817 15.2197 37.49C15.2197 36.9984 15.2197 35.7691 15.2197 34.0482C9.81099 35.2774 8.58175 31.5897 8.58175 31.5897C7.59835 29.3771 6.36911 28.6395 6.36911 28.6395C4.64817 27.4103 6.61496 27.4103 6.61496 27.4103C8.58175 27.6561 9.56514 29.3771 9.56514 29.3771C11.2861 32.5731 14.2363 31.5897 15.2197 31.098C15.4655 29.8687 15.9572 28.8854 16.4489 28.3937C12.0236 27.902 7.59835 26.181 7.59835 18.5597C7.59835 16.3471 8.3359 14.6262 9.56514 13.3969C9.31929 12.9052 8.58175 10.9384 9.81099 8.23409C9.81099 8.23409 11.5319 7.74239 15.2197 10.2009C16.6947 9.70918 18.4157 9.46333 20.1366 9.46333C21.8576 9.46333 23.5785 9.70918 25.0536 10.2009C28.7413 7.74239 30.4623 8.23409 30.4623 8.23409C31.4456 10.9384 30.9539 12.9052 30.7081 13.3969C31.9373 14.872 32.6749 16.5929 32.6749 18.5597C32.6749 26.181 28.0038 27.6561 23.5785 28.1478C24.316 29.1312 25.0536 30.3604 25.0536 32.0814C25.0536 34.7857 25.0536 36.7525 25.0536 37.49C25.0536 37.9817 25.2994 38.7193 26.5287 38.4734C34.3958 35.7691 40.0503 28.3937 40.0503 19.789C39.8045 8.97164 30.954 0.121094 20.1366 0.121094Z"
                  fill="#5956d9" />
              </svg>
              <a :href="userProfile?.socialNetworks?.github" target="_blank">
                {{ userProfile?.socialNetworks?.github }}
              </a>
            </div>

            <!-- Bitbucket -->
            <div class="link-with-icon" v-if="userProfile?.socialNetworks?.bitbucket">
              <svg width="38" height="38" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3.33398 0C1.50065 0 0.0506504 1.45 0.167317 3.26667L5.41732 36.0667C5.61732 38.3 7.51732 40 9.76732 40H31.0007C32.7007 40 34.1673 38.7667 34.3673 37.0667L39.6173 3.26667C39.734 1.45 38.284 0 36.4507 0H3.33398ZM23.9173 26.6667H15.8673L13.6173 13.3333H26.0507L23.9173 26.6667Z"
                  fill="#5956d9" />
              </svg>
              <a :href="userProfile?.socialNetworks?.bitbucket" target="_blank">
                {{ userProfile?.socialNetworks?.bitbucket }}
              </a>
            </div>

            <!-- Twitter/X -->
            <div class="link-with-icon" v-if="userProfile?.socialNetworks?.twitter">
              <svg width="38" height="38" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M23.6867 3.33398H28.1725L18.6867 14.209L29.8867 29.334H21.32L14.3867 20.2507L6.45337 29.334H1.96671L12.0867 17.7507L1.33337 3.33398H10.1325L16.4192 11.6673L23.6867 3.33398ZM22.4534 26.6673H24.92L8.89254 5.87732H6.24671L22.4534 26.6673Z"
                  fill="#5956d9" />
              </svg>
              <a :href="userProfile?.socialNetworks?.twitter" target="_blank">
                {{ userProfile?.socialNetworks?.twitter }}
              </a>
            </div>

            <!-- Behance -->
            <div class="link-with-icon" style="margin-bottom: 15px" v-if="userProfile?.socialNetworks?.behance">
              <svg width="38" height="26" viewBox="0 0 40 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.5895 11.3173C21.5065 11.3173 21.0148 17.4635 21.0148 17.4635C21.0148 24.839 12.9018 25.0848 12.9018 25.0848H0.609375V0.5H12.9018C16.8354 0.5 20.2772 2.22094 20.2772 6.64621C20.2772 11.0715 16.5895 11.3173 16.5895 11.3173ZM6.26389 4.43358V9.84224H12.9018C14.131 9.84224 15.1144 9.35054 15.1144 6.89206C15.1144 4.43358 13.3935 4.43358 13.3935 4.43358H12.4101H6.26389ZM12.9018 20.1679C13.8852 20.1679 15.852 19.922 15.852 17.2177C15.852 13.7758 13.3935 13.7758 13.3935 13.7758H6.26389V20.1679H12.9018ZM31.3404 6.40036C41.1744 6.40036 39.9451 16.9718 39.9451 17.2177H27.4068C27.4068 21.643 31.5863 21.3971 31.5863 21.3971C35.7657 21.3971 35.5198 18.6928 35.5198 18.6928H39.6993C39.6993 25.5765 31.3404 25.0848 31.3404 25.0848C21.2606 25.0848 21.9982 15.7426 21.9982 15.7426C21.9982 15.7426 21.9982 6.40036 31.3404 6.40036ZM35.274 13.7758C35.274 13.7758 35.274 9.84224 31.3404 10.0881C27.6527 10.0881 27.161 13.7758 27.161 13.7758H35.274ZM25.1942 0.5H37.4866V2.95848H25.1942V0.5Z"
                  fill="#5956d9" />
              </svg>
              <a :href="userProfile?.socialNetworks?.behance" target="_blank">
                {{ userProfile?.socialNetworks?.behance }}
              </a>
            </div>
          </div>
        </section>
        <section v-if="certsAndCourses?.length">
          <h2 style="
              padding-left: 15px;
              font-size: 18px !important;
              font-weight: bold;
            ">
            {{ $t('profile.certifications') }}
          </h2>
          <div class="dark-box" style="padding-top: 20px; padding-bottom: 20px !important">
            <div v-for="certs in certsAndCourses" :key="certs.name" class="cert" style="padding-left: 15px">
              <p style="
                  font-size: 12px !important;
                  text-transform: uppercase;
                  margin-top: 0rem !important;
                  margin-bottom: 0rem;
                ">
                <strong>{{ certs.name }}</strong>
              </p>
              <p style="
                  font-size: 12px !important;
                  margin-top: 0rem !important;
                  margin-bottom: 0rem;
                ">
                {{ certs.certifiedBy }}
              </p>
              <p style="
                  font-size: 12px !important;
                  margin-top: 0rem !important;
                  margin-bottom: 0rem;
                ">
                {{ formatedDate(certs.startDate) }}
              </p>
            </div>
          </div>
        </section>
        <section v-if="techAssesments?.length">
          <h2 style="
              padding-left: 15px;
              font-size: 18px !important;
              font-weight: bold;
            ">
            {{ $t('profile.technical_skills') }}
          </h2>
          <div class="dark-box" style="padding: 2rem">
            <div v-for="certs in techAssesments" :key="certs.name" class="singular-test">
              <img src="@/assets/images/tests/squaregorilla.png" alt="" class="img-test" />
              <div>
                <h3 style="font-weight: bold !important">{{ certs.name }}</h3>
                <p class="test-name" style="
                    font-size: 12px !important;
                    margin-top: 0rem !important;
                    margin-bottom: 0rem;
                  ">
                  {{ certs.certifiedBy }}
                </p>
                <p style="
                    font-size: 12px !important;
                    margin-top: 0rem !important;
                    margin-bottom: 0rem;
                  ">
                  {{ $t('profile.issued') }}: <span> {{ formatedDate(certs.startDate) }}</span>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="right-section">
        <section v-if="technologies?.length">
          <h2 style="
              padding-left: 15px;
              font-size: 18px !important;
              font-weight: bold;
            ">
            {{ $t('profile.technologies') }}
          </h2>
          <div class="dark-box" style="padding: 1.5rem 2rem">
            <div class="singular-technology" v-for="technology in technologies" :key="technology.techName">
              <div class="technology-mastery">
                <h3 style="font-size: 16px !important">
                  {{ technology.techName }}
                </h3>
                <span v-if="technology?.level?.length">{{
                  $t(
                    `modal-tecnologias.opcion-nivel-experiencia-${technology.level}`
                  )
                }}</span>
              </div>
              <div class="progress-bar">
                <div class="bar" style="width: 100%"></div>
              </div>
              <p class="years-experience">
                {{ technology.expYears }} {{ $t('profile.years_experience') }}
              </p>
            </div>
          </div>
        </section>
        <section v-if="experience?.length">
          <h2 style="
              padding-left: 15px;
              font-size: 18px !important;
              font-weight: bold;
            ">
            {{ $t('profile.experience') }}
          </h2>
          <div class="dark-box" style="padding: 2rem">
            <div v-for="job in experience" :key="job.company" class="singular-experience relative">
              <div class="border-2 rounded-full h-4 w-4 absolute z-[99]" style="
                  border-color: #5956d9;
                  background-color: black;
                  left: -6.5px;
                "></div>
              <h3 style="
                  font-weight: bold !important;
                  font-size: 14px !important;
                  margin: 0;
                  margin-top: 0.2rem;
                ">
                {{ job.position }}
              </h3>
              <p class="company-name" style="
                  margin: 0;
                  margin-top: 0.2rem !important;
                  font-size: 14px !important;
                ">
                {{ job.company }}
              </p>
              <p class="period" style="
                  margin: 0;
                  margin-top: 0.2rem !important;
                  font-size: 13px !important;
                ">
                {{ job.startDate }}|{{ job.endDate }}({{ job.companyLocation }})
              </p>
              <div class="grid-skills-badge" style="margin-top: 1rem">
                <div v-for="tech in job.technologies" :key="tech" class="badge">
                  {{ tech }}
                </div>
              </div>
              <p class="description" style="
                  margin: 0;
                  margin-top: 0.6rem;
                  font-size: 11px;
                  line-height: 16px;
                ">
                {{ job.whatBuilt }}
              </p>
            </div>
          </div>
        </section>
        <section v-if="formalEducation?.length">
          <h2 style="
              padding-left: 15px;
              font-size: 18px !important;
              font-weight: bold;
            ">
            {{ $t('profile.education') }}
          </h2>
          <div class="dark-box" style="padding: 2rem 2rem 0.5rem">
            <div v-for="education in formalEducation" :key="education.name" class="singular-experience">
              <div class="border-2 rounded-full h-4 w-4 absolute z-[99]" style="
                  border-color: #5956d9;
                  background-color: #e3e9ff;
                  left: -6.5px;
                "></div>
              <h3 style="font-size: 14px !important">{{ education.school }}</h3>
              <span style="font-size: 14px !important">{{
                education.grade
              }}</span><br />
              <span class="spaneriod text-xs text-primary" style="margin: 0; margin-top: 0.2rem !important">
                {{ education.startDate }} | {{ education.endDate }}
              </span>
            </div>
          </div>
        </section>
        <section v-if="takenAssesments?.length">
          <h2 style="
              padding-left: 15px;
              font-size: 18px !important;
              font-weight: bold;
            ">
            {{ $t('profile.skills') }}
          </h2>
          <div class="dark-box" style="padding: 2rem">
            <div class="grid-skills">
              <div v-for="result in takenAssesments" :key="result.id" class="w-full mt-2 flex items-start">
                <div>
                  <img :src="require(`@/assets/images/badges/${result.assesment.photo}`)
                    " alt="Assessment Icon" class="max-w-none" style="width: 50px; height: auto" />
                </div>
                <div class="ml-3">
                  <h3 style="font-size: 16px !important" class="text-lg mb-1 font-semibold">
                    {{ result.assesment.name }}
                  </h3>
                  <p class="text-xs mt-2">
                    {{ $t('profile.assessment_description', {
                      name: result.assesment.name,
                      length: result.assesment.testLength
                    }) }}
                  </p>
                  <div class="flex flex-wrap mt-2 text-xs">
                    <div v-for="subAssesment in result.subAssesmentsResult" :key="subAssesment.id"
                      class="rounded-full uppercase px-2 py-1 mr-2 mt-2 text-black"
                      style="background: #b9a3ff; font-size: 0.55rem">
                      {{ subAssesment.assesment.name }}
                    </div>
                  </div>
                  <h3 class="text-lg font-semibold mt-3">
                    {{ $t('profile.score') }}:
                    <span style="color: rgb(40, 185, 154)">
                      {{ getTotalScore(result) }}/{{
                        getTotalTestLength(result)
                      }}
                    </span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
    <div class="text-center pb-10">
      <span>powered by
        <img src="@/assets/images/logo-white.png" alt="Share" class="inline mx-2 max-h-6" /></span>
    </div>
  </body>
</template>

<script>
import CryptoJS from 'crypto-js'
import ApolloClient from '@/graphql/apolloClient'
import publicProfileQuery from '@/graphql/queries/publicProfile'
import { toastNotification } from '@/services/toastNotification'
import { mapActions } from 'vuex'

export default {
  name: 'Profile',
  data() {
    return {
      user: null,
      userProfile: null,
      modalBienvenidaShow: false,
      assesmentCategories: [],
      // section: 'Education',
      section: 'Skills',
      technologies: [],
      formalEducation: [],
      certsAndCourses: [],
      experience: [],
      personalProjects: [],
      takenAssesments: [],
      englishLevel: null,
      about: null,
      id: null,
      decryptedId: null,
      jobTypePreference: []
    }
  },
  async mounted() {
    await this.setLoadingOn()
    await this.getUserProfile()
  },
  methods: {
    ...mapActions(['setLoadingOn', 'setLoadingOff']),
    getUserProfile: async function () {
      try {
        const userId = this.$route.params.id
        this.decryptedId = this.decryptId(userId)

        const userProfileData = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale
            }
          },
          fetchPolicy: 'network-only',
          query: publicProfileQuery,
          variables: {
            id: this.decryptedId
          }
        })

        this.userProfile = userProfileData?.data?.userPublicProfile
        this.user = userProfileData?.data?.user
        console.log('user', this.user)
        this.technologies = this.userProfile?.technologies?.slice()?.reverse()
        this.formalEducation = this.userProfile?.formalEducation
          ?.slice()
          ?.reverse()
        this.certsAndCourses = this.userProfile?.certsAndCourses
          ?.slice()
          ?.reverse()
        this.techAssesments = this.userProfile?.techAssesments
          ?.slice()
          ?.reverse()
        this.experience = this.userProfile.pastJobs?.slice()?.sort((a, b) => {
          const endDateA = a.endDate ? new Date(a.endDate) : new Date()
          const endDateB = b.endDate ? new Date(b.endDate) : new Date()
          return endDateB - endDateA
        })
        this.personalProjects = this.userProfile?.pastProjects
          ?.slice()
          ?.reverse()
        this.takenAssesments = this.userProfile?.takenAssesments
          ?.slice()
          ?.reverse()
        this.englishLevel = this.userProfile?.englishLevel
        this.about = this.userProfile?.about
        this.id = this.userProfile?.id
        this.yearsOfExp = this.userProfile?.yearsOfExp
        this.minSalary = this.userProfile?.minSalary
        this.personsInCharge = this.userProfile?.personsInCharge
        this.industries = this.userProfile?.industries?.slice()?.reverse()
        this.jobTypePreference = this.userProfile?.jobTypePreference
        this.setLoadingOff()
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    formatedDate(date) {
      if (date) {
        const parsedDate = new Date(date)
        const year = parsedDate.getUTCFullYear()
        return `${year}`
      }
      return ''
    },
    getTotalScore(result) {
      return result.subAssesmentsResult.reduce(
        (total, subAssesment) => total + subAssesment.totalScore,
        0
      )
    },
    getTotalTestLength(result) {
      return result.subAssesmentsResult.reduce(
        (total, subAssesment) => total + subAssesment.assesment.testLength,
        0
      )
    },
    englishLevelDescription(level) {
      const numericLevel = parseInt(level, 10)
      if (!numericLevel) return ''
      switch (numericLevel) {
        case 1:
        case 2:
          return 'Básico'
        case 3:
          return 'Intermedio'
        case 4:
          return 'Avanzado'
        case 5:
          return 'Profesional'
        default:
          return 'Desconocido'
      }
    },
    truncateText(text, length) {
      return text?.length > length ? text.substring(0, length) + '...' : text
    },
    encryptId(id) {
      const secretKey = '123secret'
      const ciphertext = CryptoJS.AES.encrypt(
        id.toString(),
        secretKey
      ).toString()
      const hexCiphertext = CryptoJS.enc.Base64.parse(ciphertext).toString(
        CryptoJS.enc.Hex
      )
      return hexCiphertext
    },
    decryptId(encryptedHexId) {
      return parseInt(encryptedHexId, 16).toString()
    }
  }
}
</script>

<style scoped>
.a4-page {
  width: 210mm;
  background-color: #000;
  background-image: url('@/assets/images/background-forms.svg');
  background-repeat: repeat;
  background-size: 210mm;
  color: #fff;
}
header {
  position: relative;
  z-index: -1;
}

header svg {
  width: 100%;
  height: auto;
}

main {
  display: flex;
  flex-direction: row;
  gap: 45px;
  margin-left: 15px;
  margin-right: 15px;
  flex: 1;
  z-index: 2;
}

.left-section {
  padding: 10px;
  box-sizing: border-box;
  margin-top: 35px;
  width: 40%;
  z-index: 3;
}
.right-section {
  padding: 10px;
  box-sizing: border-box;
  margin-top: 35px;
  width: 60%;
  z-index: 3;
}
.left-section {
  width: 36%;
}
.right-section {
  width: 53%;
}

.contact-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 30px;
  gap: 3px;
  min-width: 300px;
  margin-left: 5px !important;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.contact-item svg {
  width: 16px;
  height: 16px;
}

.profile-card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: left;
  gap: 20px;
  width: 100%;
}

.profile-image img {
  width: 120px;
  height: auto;
  border-radius: 50%;
}

.profile-content {
  max-width: 500px;
}

.profile-content h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.profile-content p {
  margin: 5px 0;
}

.profile-content p strong {
  color: #b9a3ff;
}

.dark-box {
  background-color: #1e1d1d;
  border: 2px solid #5956d9;
  border-radius: 10px;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 30px;
  min-width: 300px;
  max-width: 100%;
}

.right-section .dark-box {
  min-width: 420px;
}

.dark-box p strong {
  color: #b9a3ff;
}

.dark-box p {
  margin-top: 0.7rem !important;
}

.dark-box span strong {
  color: #b9a3ff;
}

.languages {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.language-item {
  display: flex;
  align-items: center;
  gap: 25px;
  margin-left: 15px;
  padding: 0;
}

.stars {
  display: flex;
  gap: 5px;
}

.stars svg {
  width: 18px;
  height: 17px;
}

.level {
  font-size: 12px;
  color: #a5a5a5;
}

.pill {
  border-radius: 9999px;
  padding: 10px 16px;
  margin-right: 8px;
  margin-left: 15px;
  background-color: rgba(185, 163, 255, 0.4);
  color: #fff;
  font-size: 0.7rem;
  margin-bottom: 10px;
  display: inline-block;
  text-transform: uppercase;
}

.pill-section {
  margin-bottom: 5px;
}

.link-with-icon {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.link-with-icon a {
  color: #b9a3ff;
  text-decoration: none;
  font-size: 1rem;
  margin-top: 8px;
}

.link-with-icon a:hover {
  text-decoration: underline;
}

.link-with-icon svg {
  padding-left: 15px;
}
.progress-bar {
  width: 100%;
  height: 5px;
  background-color: #333333;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
}
.progress-bar .bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #b9a3ff;
  border-radius: 50px;
}
.technology-mastery {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.technology-mastery h3 {
  margin-top: 0;
  margin-bottom: 0;
}
.technology-mastery span {
  font-size: 12px;
  letter-spacing: 2px;
  color: #b9a3ff;
}
.singular-technology {
  margin-bottom: 1.5rem;
}
.singular-technology h3 {
  font-weight: normal;
}
.singular-technology .years-experience {
  margin-top: 2px !important;
  margin-bottom: 0;
  font-size: 10px;
  font-weight: normal;
}
.badge {
  display: block;
  width: max-content;
  background-color: #b9a3ff;
  color: #1e1d1d;
  font-size: 11px;
  padding: 5px 1rem;
  border-radius: 50px;
}
.grid-skills-badge {
  width: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
}
.singular-experience {
  font-weight: normal;
  position: relative;
  padding-bottom: 1rem;
  padding-left: 2rem;
}
/* .singular-experience::after {
  position: absolute;
  top: 0;
  left: -8px;
  content: '';
  width: 18px;
  height: 18px;
  background-color: #1e1d1d;
  border: 3px solid #5956d9;
  border-radius: 50px;
  z-index: 2;
} */
.singular-experience::before {
  position: absolute;
  left: 0;
  content: '';
  width: 3px;
  height: 100%;
  background-color: #5956d9;
  z-index: 1;
}
.singular-experience:last-child:before {
  display: none;
}
.singular-experience h3 {
  color: #b9a3ff;
  margin-top: 0;
}
.singular-experience .company-name {
  font-weight: bold;
}
.singular-experience .period {
  color: #b9a3ff;
}
.singular-experience .description {
  line-height: 1;
  margin-bottom: 0;
}
.grid-skills {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}
.singular-skill {
  width: calc(50% - 2rem);
  display: flex;
  gap: 1rem;
  align-items: start;
}
.singular-skill .insignia {
  width: 64px;
  height: 64px;
  object-fit: contain;
}
.singular-skill h3 {
  margin-top: 0;
}
.singular-skill .description {
  font-size: 13px;
  line-height: 1;
}
.singular-skill .score span {
  font-size: 25px;
  color: #28b99a;
}
.singular-test {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}
.singular-test img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}
.singular-test .test-name {
  color: #b9a3ff;
  font-size: 18px;
}
.singular-test h3 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.singular-test p {
  margin-top: 5px;
  margin-bottom: 5px;
}
footer {
  padding: 1rem;
  text-align: center;
}
footer .img-copy {
  width: 100%;
}
</style>
