<template>
  <section class="mb-2">
    <!-- Start Basic Info -->

    <div class="flex flex-wrap lg:flex-nowrap items-center">
      <Form @submit="() => null">
        <div class="flex mb-6 lg:mt-7">
          <div
            class="flex justify-between items-center bg-black uppercase border-2 border-primary rounded-lg w-full px-3 lg:px-6 py-3 lg:py-5">
            <Title class="">
              <template #title>
                <h5 class="mt-0 text-lg">
                  {{ $t('profile-page.sidebar.profile.title') }}
                </h5>
              </template>
            </Title>
            <button type="submit" class="btn btn-primary btn-rounded-lg btn-md" aria-pressed="false"
              @click="updateUserProfile">
              <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M18.8844 7.56222V17.7186C18.8844 18.1452 18.7416 18.5013 18.4559 18.787C18.1702 19.0726 17.8141 19.2155 17.3876 19.2155H5.55642C5.12989 19.2155 4.77379 19.0726 4.4881 18.787C4.20241 18.5013 4.05957 18.1452 4.05957 17.7186V5.88747C4.05957 5.46095 4.20241 5.10484 4.4881 4.81916C4.77379 4.53347 5.12989 4.39062 5.55642 4.39062H15.7128L18.8844 7.56222ZM17.9579 7.95786L15.3172 5.31718H5.55642C5.3901 5.31718 5.25343 5.37061 5.14642 5.47747C5.03956 5.58449 4.98612 5.72116 4.98612 5.88747V17.7186C4.98612 17.885 5.03956 18.0216 5.14642 18.1286C5.25343 18.2355 5.3901 18.2889 5.55642 18.2889H17.3876C17.5539 18.2889 17.6906 18.2355 17.7976 18.1286C17.9044 18.0216 17.9579 17.885 17.9579 17.7186V7.95786ZM11.472 16.0082C11.9828 16.0082 12.4194 15.8271 12.7817 15.4648C13.144 15.1025 13.3251 14.666 13.3251 14.1551C13.3251 13.6443 13.144 13.2077 12.7817 12.8454C12.4194 12.4831 11.9828 12.302 11.472 12.302C10.9612 12.302 10.5246 12.4831 10.1623 12.8454C9.80003 13.2077 9.61889 13.6443 9.61889 14.1551C9.61889 14.666 9.80003 15.1025 10.1623 15.4648C10.5246 15.8271 10.9612 16.0082 11.472 16.0082ZM6.62543 9.73615H13.5032V6.95648H6.62543V9.73615ZM4.98612 7.95786V18.2889V5.31718V7.95786Z"
                  fill="black" />
              </svg>
              {{ $t('form.btn-guardar') }}
            </button>
          </div>
        </div>

        <div class="flex flex-no-wrap flex-col lg:flex-nowrap items-center justify-center">
          <div
            class="relative w-24 h-24 lg:w-48 lg:h-48 flex justify-center items-center border-2 border-primary rounded-full">
            <router-link :to="{ name: 'EditProfileAvatar', params: { locale: 'es' } }" v-if="!isPublic"
              class="bg-secondary flex justify-center items-center w-9 h-9 absolute bottom-0 right-0 transform -translate-y-1/4 rounded-full">
              <img src="@/assets/images/icn-edit.svg" class="w-4" />
            </router-link>
            <img :src="
                require(`@/assets/images/avatars/avatar-${
                  user.avatar ? user.avatar : 1
                }.svg`)
  " class="rounded-full" alt="Cody Avatar" />
          </div>
          <div class="mt-3 mb-9">
            <h4 class="text-white text-lg font-bold">Foto de perfil</h4>
          </div>
        </div>

        <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4 mb-3">
          <div class="mb-3">
            <Field name="name" type="text" class="form--element w-full border-2 border-primary" :rules="validateDefault"
              v-model="name" :placeholder="$t('modal-profile.lbl-nombre')" />
            <ErrorMessage name="name" class="form--error" />
          </div>
          <div class="mb-3">
            <Field name="email" type="email" class="form--element w-full border-2 border-primary" v-model="email"
              :readonly="!!id && !!initialData" placeholder="Email" />
          </div>
          <div class="mb-3">
            <Field name="country" as="select" class="form--element form--select w-full border-2 border-primary"
              :rules="validateDefault" v-model="country">
              <option value="" disabled>
                {{ $t('registrarse-page.lbl-countryItem') }}
              </option>
              <option :value="countryItem" v-for="countryItem in countriesList[
                $i18n.locale ? $i18n.locale : 'es'
              ]" :key="countryItem">
                {{ countryItem }}
              </option>
            </Field>
            <ErrorMessage name="country" class="form--error" />
          </div>
          <div class="mb-3">
            <div class="form--phone">
              <Field name="phoneCode" type="text" :placeholder="$t('general.codigo-internacional')"
                list="phoneCodeNumberList" class="form--element form--select w-24 border-2 border-primary"
                :rules="validateDefaultPhone" v-model.lazy="phoneCodeNumber" />
              <Field name="phone" type="text" class="form--element w-full border-2 border-primary"
                :rules="validatePhone" v-model="phonenumber" placeholder="No. de teléfono" />
              <datalist id="phoneCodeNumberList">
                <option value="" disabled></option>
                <option :value="code.dialCode" v-for="(code, index) in phoneCodes" :key="index">
                  {{ code.emoji }} {{ code.name }}
                </option>
              </datalist>
            </div>
            <ErrorMessage name="phone" class="form--error" />
            <ErrorMessage name="phoneCode" class="form--error" />
            <div class="form--error" v-if="error">
              {{ error }}
            </div>
          </div>
          <div class="mb-3">
            <Field name="city" type="text" class="form--element w-full border-2 border-primary" v-model="city"
              placeholder="Ciudad">
            </Field>
            <ErrorMessage name="city" class="form--error" />
          </div>
        </div>

        <div class="section-title flex mb-5 justify-between py-3 items-center gap-4">
          <h3 class="w-[220px] block text-[#B9A3FF] text-2xl">
            {{ $t('profile-page.title-acerca-de-mi') }}
          </h3>
          <div class="w-full border-b-2 mt-1 border-primary"></div>
        </div>

        <p class="dark:text-white pb-4 text-secondary-text">
          {{ $t('profile-page.description-acerca-de-mi') }}
        </p>

        <div>
          <div class="form--group">
            <Field type="text" name="experienciaProfesionalDescription" :rules="validateDefault" v-slot="{ field }"
              v-model="about">
              <textarea class="form--element w-full border-2 border-primary" v-bind="field" rows="5" maxlength="500"
                :placeholder="$t('profile-page.lbl-cuentanos-tu-historia')"></textarea>
            </Field>
            <div class="text-right font-bold text-secondary-text">
              {{ about?.length }}/500
            </div>
            <ErrorMessage name="experienciaProfesionalDescription" class="form--error" />
          </div>
        </div>

        <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4">
          <div class="mb-2">
            <div class="form--group w-full">
              <label class="form--label w-full" for="experiencia-laboral">{{
                $t('box-experiencia.lbl-experiencia-laboral')
              }}</label>
              <input id="experiencia-laboral" name="experiencia-laboral" type="number" min="0"
                class="form--element form--number w-full border-2 border-primary" v-model="yearsOfExperience" />
            </div>
          </div>
          <div class="mb-2">
            <div class="form--group w-full">
              <label class="form--label w-full" for="nivel-de-ingles">{{ $t('box-experiencia.lbl-nivel-de-ingles') }}
                <div class="tooltip inline">
                  <button type="button" role="button area" aria-label="Info" @mouseenter="tooltipShow = true"
                    @mouseleave="tooltipShow = false">
                    <img src="@/assets/images/icn-info.svg" class="w-4" />
                  </button>
                  <transition name="fade">
                    <div class="tooltip--globe tooltip--lg w-full" v-show="tooltipShow == true">
                      <p class="dark:text-primary-text text-secondary-text text-xs">
                        <b>{{ $t('tooltip-nivel-ingles.title-basico') }}:</b>
                        {{ $t('tooltip-nivel-ingles.description-basico') }}
                      </p>
                      <p class="dark:text-primary-text text-secondary-text text-xs">
                        <b>{{
                          $t('tooltip-nivel-ingles.title-conversacional')
                        }}:</b>
                        {{
                          $t('tooltip-nivel-ingles.description-conversacional')
                        }}
                      </p>
                      <p class="dark:text-primary-text text-secondary-text text-xs">
                        <b>{{
                          $t('tooltip-nivel-ingles.title-profesional')
                        }}:</b>
                        {{ $t('tooltip-nivel-ingles.description-profesional') }}
                      </p>
                      <p class="dark:text-primary-text text-secondary-text text-xs last">
                        <b>{{
                          $t('tooltip-nivel-ingles.title-lengua-materna')
                        }}:</b>
                        {{
                          $t('tooltip-nivel-ingles.description-lengua-materna')
                        }}
                      </p>
                    </div>
                  </transition>
                </div>
              </label>
              <select id="nivel-de-ingles" name="nivel-de-ingles"
                class="form--element form--select w-full border-2 border-primary" v-model="userEnglishLevel">
                <option v-for="item in englishLevels" :key="item" :value="item" :selected="userEnglishLevel == item">
                  {{ $t(`nivel-ingles.opt-${item}`) }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="section-title flex mb-5 justify-between py-3 items-center gap-4">
          <h3 class="w-[200px] block text-[#B9A3FF] text-2xl">Especialidad</h3>
          <div class="w-full border-b-2 mt-1 border-primary"></div>
        </div>

        <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4 mb-3">
          <div class="mb-3">
            <Field name="speciality" as="select" class="form--element form--select w-full border-2 border-primary"
              :rules="validateDefault" v-model="speciality">
              <option value="" disabled>
                {{ $t('modal-profile.lbl-especialidad') }}
              </option>
              <option :value="speciality" v-for="speciality in especialidadLista.sort()" :key="speciality">
                {{ speciality }}
              </option>
            </Field>
            <ErrorMessage name="speciality" class="form--error" />
          </div>
          <!-- If Other Selected -->
          <div class="mb-4" v-if="speciality === 'Other/Otro'">
            <label class="form--label" for="otro">
              {{ $t('contrata-devs-page.option-otro') }}
              {{ $t('modal-profile.lbl-especialidad') }}
            </label>
            <Field name="otro" type="text" class="form--element w-full border-2 border-primary" :rules="validateDefault"
              v-model="otherSpeciality" />
            <ErrorMessage name="otro" class="form--error" />
          </div>
          <!-- End Other Selected -->
        </div>

        <div class="section-title flex mb-5 justify-between py-3 items-center gap-4">
          <h3 class="w-[280px] block text-[#B9A3FF] text-2xl">
            {{ $t('box-estado-de-empleo.title-pago-ideal') }}
          </h3>
          <div class="w-full border-b-2 mt-1 border-primary"></div>
        </div>

        <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4">
          <div class="form--group">
            <label class="form--label" for="pago-ideal">{{
              $t('box-estado-de-empleo.lbl-pago-ideal')
            }}</label>
          </div>
          <div class="form--group">
            <input id="pago-ideal-inicial" name="pago-ideal-inicial" type="number" min="0"
              class="form--element form--number w-full border-2 border-primary" v-model="minSalary"
              placeholder="0 USD" />
            <label class="form--label text-center mt-1" v-if="minSalary">USD ${{ addCommas }}
              {{ $t('box-estado-de-empleo.lbl-mensuales') }}</label>
          </div>
        </div>

        <div class="form--group" style="margin-top: -1rem">
          <Field v-slot="{ field }" name="negotiable" as="label" class="form--checkbox">
            <input type="checkbox" name="negotiable" v-bind="field" v-model="negotiable" />

            {{ $t('box-estado-de-empleo.lbl-negociable') }}
          </Field>
          <ErrorMessage name="negotiable" class="form--error ml-3" />
        </div>

        <div class="section-title flex mb-5 justify-between py-3 items-center gap-4">
          <h3 class="w-[220px] block text-[#B9A3FF] text-2xl">
            {{ $t('box-estado-de-empleo.title-salario-actual') }}
          </h3>
          <div class="w-full border-b-2 mt-1 border-primary"></div>
        </div>

        <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4">
          <div class="form--group">
            <label class="form--label" for="pago-ideal">{{
              $t('box-estado-de-empleo.lbl-salario-actual')
            }}</label>
          </div>
          <div class="form--group">
            <input id="pago-ideal-inicial" name="pago-ideal-inicial" type="number" min="0"
              class="form--element form--number w-full border-2 border-primary" v-model="realSalary"
              placeholder="0 USD" />
            <label class="form--label text-center mt-1" v-if="realSalary">USD ${{ addCommasRealSalary }}
              {{ $t('box-estado-de-empleo.lbl-mensuales') }}</label>
          </div>
        </div>

        <div class="section-title flex mb-5 justify-between py-3 items-center gap-4">
          <h3 class="w-[410px] block text-[#B9A3FF] text-2xl">
            {{ $t('box-estado-de-empleo.title-red-social') }}
          </h3>
          <div class="w-full border-b-2 mt-1 border-primary"></div>
        </div>

        <div class="mb-5 md:w-1/2 md:pr-2">
          <div class="form--icon form--icon--left">
            <span class="icon--wrapper bg-linkedin rounded-l-lg">
              <img src="@/assets/images/social-media/icn-linkedin.svg" alt="LinkedIn" />
            </span>
            <Field name="linkedin" type="url" :rules="validateUrl"
              class="form--element element--sm w-full border-2 border-primary"
              :placeholder="$t('profile-page.social-holder-linkedin')" v-model="social.linkedin" pattern="https?://.*"
              required />
          </div>
          <ErrorMessage name="linkedin" class="text-primary text-sm" />
        </div>
      </Form>
    </div>
    <!-- End Basic Info -->
  </section>
</template>

<script>
import Title from '@/components/Title'
import ROUTES from '@/constants/routes'
import countriesList from '@/components/json/countries.json'
import especialidadLista from '@/constants/specialities.json'
import phoneCodes from '@/components/json/phoneCodes.json'
import { Field, Form, ErrorMessage } from 'vee-validate'
import ApolloClient from '@/graphql/apolloClient'
import savePersonalInfoMutation from '@/graphql/mutations/savePersonalInfo'
import SaveSocialNetworksMutation from '@/graphql/mutations/saveSocialNetworks'
import { mapActions, mapGetters } from 'vuex'
import { toastNotification } from '@/services/toastNotification'
import {
  validateDefault,
  validateDefaultPhone,
  validatePhone
} from '@/services/validationRules'
export default {
  name: 'BasicInformationSection',
  props: {
    isPublic: Boolean,
    id: { type: Number, required: false },
    initialData: Object
  },
  components: {
    Title,
    Field,
    ErrorMessage,
    Form
  },
  data() {
    return {
      ROUTES,
      edit: null,
      countriesList,
      especialidadLista,
      phoneCodes,
      name: null,
      speciality: '',
      otherSpeciality: null,
      country: null,
      city: null,
      phonenumber: null,
      phoneCodeNumber: '',
      error: null,
      editTellYourStory: true,
      yearsOfExperience: null,
      about: '',
      minSalary: null,
      realSalary: null,
      userEnglishLevel: null,
      negotiable: false,
      englishLevels: 5,
      social: {
        linkedin: null
      },
      tooltipShow: false,
      email: null
    }
  },
  async beforeMount() {
    await this.fetchUser()
    await this.fetchProfile()
    this.splitPhoneNumber()
  },
  mounted() {
    if (this.isPublic) {
      this.edit = false
    } else {
      this.edit = true
    }
    this.mountData()
    // this.getAboutField()
    // this.splitPhoneNumber()
  },
  computed: {
    ...mapGetters(['token', 'profile', 'user']),
    phoneNumberComplete() {
      return this.phoneCodeNumber + this.phonenumber
    },
    addCommas() {
      if (this.minSalary) {
        console.log(this.minSalary)
        return this.minSalary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } else {
        return ''
      }
    },
    addCommasRealSalary() {
      if (this.realSalary) {
        console.log(this.realSalary)
        return this.realSalary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } else {
        return ''
      }
    }
  },
  methods: {
    ...mapActions([
      'setLoadingOn',
      'setLoadingOff',
      'fetchUser',
      'fetchProfile'
    ]),
    mountData() {
      console.log('THISSSSS', this.user, this.profile, this.initialData)
      this.name =
        this.initialData?.name ||
        this.initialData?.fullname ||
        this.user.name ||
        this.user.fullname ||
        null
      this.speciality =
        this.initialData?.speciality || this.user.speciality || null
      this.otherSpeciality = null
      this.country = this.initialData?.country || this.user.country || null
      this.city = this.initialData?.city || this.user.city || null
      this.phonenumber =
        this.initialData?.phonenumber || this.user.phonenumber || null
      this.phoneCodeNumber = ''
      this.error = null
      this.editTellYourStory = true
      this.yearsOfExperience =
        this.initialData?.yearsOfExp || this.profile?.yearsOfExp || null
      this.about = this.initialData?.about || this.profile?.about || ''
      this.minSalary = this.initialData?.minSalary || this.profile?.minSalary
      this.realSalary =
        this.initialData?.realSalary || this.profile?.realSalary || null
      this.userEnglishLevel =
        this.initialData?.realSalary || this.profile?.englishLevel
      this.negotiable =
        this.initialData?.openToNegotiate ||
        this.profile?.openToNegotiate ||
        false
      this.englishLevels = 5
      this.social = {
        linkedin: null,
        ...this.initialData?.about,
        ...this.profile?.socialNetworks
      }
      this.email = this.initialData?.email || this.user.email || null
    },
    splitPhoneNumber() {
      const checkNumber = this.phonenumber
      if (checkNumber !== null) {
        this.phoneCodes.forEach((item) => {
          if (checkNumber.includes(item.dialCode)) {
            this.phonenumber = checkNumber.split(item.dialCode)[1]
            this.phoneCodeNumber = item.dialCode
          }
        })
      }
    },
    async updateUserProfile() {
      // Validación de la URL de LinkedIn
      const urlPattern = /^https?:\/\/.+/ // Expresión regular para validar URL
      if (this.social.linkedin && !urlPattern.test(this.social.linkedin)) {
        toastNotification('LinkedIn no es una URL válida.', 'error')
        return // Detener el envío si hay un error
      }

      try {
        this.setLoadingOn()
        const personalInfo = await ApolloClient.mutate({
          mutation: savePersonalInfoMutation,
          context: {
            headers: {
              authorization: `Bearer ${await this.token}`
            }
          },
          variables: {
            id: this.id || this.user.userId,
            fullname: this.name,
            name: this.name,
            phonenumber: this.phoneNumberComplete,
            country: this.country,
            city: this.city,
            about: this.about,
            minSalary: parseInt(this.minSalary),
            realSalary: parseInt(this.realSalary),
            openToNegotiate: this.negotiable,
            yearsOfExp: parseInt(this.yearsOfExperience),
            englishLevel: parseInt(this.userEnglishLevel),
            speciality:
              this.speciality === 'Other/Otro'
                ? this.otherSpeciality
                : this.speciality
          }
        })

        const saveSocialNetworks = await ApolloClient.mutate({
          mutation: SaveSocialNetworksMutation,
          context: {
            headers: {
              authorization: `Bearer ${await this.token}`
            }
          },
          variables: {
            linkedin: this.social.linkedin,
            github: this.social.github,
            twitter: this.social.twitter,
            behance: this.social.behance,
            dribble: this.social.dribble,
            bitbucket: this.social.bitbucket,
            id: this.id
          }
        })

        if (!personalInfo.errors && !saveSocialNetworks.errors) {
          this.setLoadingOff()
          await this.fetchUser()
          await this.fetchProfile()
          toastNotification(this.$t('general.info-guardada'), 'success')
          this.edit = false
        }
      } catch (e) {
        this.error = e
        toastNotification(e, 'error')
      }
    },
    // RULES
    validateDefault,
    validateDefaultPhone,
    validatePhone
  }
}
</script>
