<template>
  <!-- END SIDEBAR -->
  <div class="w-full lg:w-8/12 lg:pr-6">
    <!-- Banner finish test -->
    <div v-if="showModal" class="welcome flex justify-center items-center">
      <div
        class="welcome-container bg-black text-white px-10 py-12 rounded-xl border-2 w-full relative border-[#B9A3FF]"
      >
        <!-- Exit -->
        <div class="welcome-exit absolute top-3 right-3">
          <span class="cursor-pointer" @click="closeModal()">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.39916 18.3069L5.69141 17.5992L11.2914 11.9992L5.69141 6.39916L6.39916 5.69141L11.9992 11.2914L17.5992 5.69141L18.3069 6.39916L12.7069 11.9992L18.3069 17.5992L17.5992 18.3069L11.9992 12.7069L6.39916 18.3069Z"
                fill="#E8EAED"
              />
            </svg>
          </span>
        </div>
        <!-- End Exit -->

        <div class="flex justify-center">
          <Vue3Lottie
            :animationData="AlertAnimation"
            :height="150"
            :width="150"
          />
        </div>

        <h3 class="text-center text-4xl my-4">
          <span class="flex items-center justify-center">
            ¡Mejorar Score!
          </span>
        </h3>
        <p class="text-center text-lg max-w-lg mx-auto break-words">
          ¿Estás segur@ de realizar de nuevo el test?
        </p>
        <div class="flex justify-center space-x-4 mt-4">
          <!-- Botón Aceptar -->
          <router-link
            :to="{ name: 'InstruccionesAssesments', params: { id: 2 } }"
            class="btn btn-lg btn-rounded-lg btn-primary"
          >
            <span class="mr-2">Aceptar</span>
          </router-link>

          <!-- Botón Cancelar -->
          <button
            type="button"
            class="btn btn-lg btn-rounded-lg btn-dark border border-primary"
            @click="closeModal()"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
    <!-- Banner finish test -->
    <!-- Card Page Sign Up -->
    <!-- Card Page Sign Up -->
    <CardPage class="bg-transparentAux">
      <template #default>
        <!-- Body -->
        <div class="card--page--body lg:pl-1 pt-10">
          <section class="mb-2">
            <div class="flex mb-6 lg:mt-7">
              <div
                class="flex justify-between items-center uppercase border-2 bg-black rounded-lg w-full px-3 lg:px-6 py-3 lg:py-5"
                style="border-color: #ff7061"
              >
                <Title class="">
                  <template #title>
                    <h5 class="mt-0 text-lg flex items-center">
                      <svg
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width="74.4px"
                        height="82.3px"
                        viewBox="0 0 74.4 82.3"
                        style="enable-background: new 0 0 74.4 82.3"
                        xml:space="preserve"
                      >
                        <g>
                          <path
                            class="st0"
                            d="M66.4,18.8L41.9,4.7C39,3,35.4,3,32.5,4.7L7.9,18.8C5,20.5,3.2,23.6,3.2,27v28.4c0,3.4,1.8,6.4,4.7,8.1
                           l24.6,14.2c2.9,1.7,6.5,1.7,9.4,0l24.6-14.2c2.9-1.7,4.7-4.8,4.7-8.1V27C71.1,23.6,69.3,20.5,66.4,18.8z"
                            fill="#FF7061"
                          />
                        </g>
                        <g>
                          <path
                            class="st1"
                            d="M37.2,81.8c-1.9,0-3.8-0.5-5.5-1.5L6,65.5c-3.4-2-5.5-5.6-5.5-9.5V26.3c0-3.9,2.1-7.6,5.5-9.5L31.7,2
                           c3.4-2,7.6-2,11,0l25.7,14.8c3.4,2,5.5,5.6,5.5,9.5V56c0,3.9-2.1,7.6-5.5,9.6L42.7,80.3C41,81.3,39.1,81.8,37.2,81.8z M37.2,2.9
                           c-1.5,0-3,0.4-4.3,1.2L7.2,18.9c-2.7,1.5-4.3,4.4-4.3,7.5V56c0,3.1,1.6,5.9,4.3,7.5l25.7,14.8c2.7,1.5,6,1.5,8.6,0l25.7-14.8
                           c2.7-1.5,4.3-4.4,4.3-7.5V26.3c0-3.1-1.6-5.9-4.3-7.5L41.5,4C40.2,3.3,38.7,2.9,37.2,2.9z"
                            fill="#FFFFFF"
                          />
                          <path
                            class="st0"
                            d="M37.2,82.3C37.2,82.3,37.2,82.3,37.2,82.3c-2,0-4-0.5-5.8-1.5L5.8,66C2.2,63.9,0,60.1,0,56V26.3
                           c0-4.1,2.2-7.9,5.8-10L31.4,1.5c3.6-2.1,8-2.1,11.5,0l25.7,14.8c3.6,2,5.8,5.9,5.8,10V56c0,4.1-2.2,8-5.8,10L42.9,80.8
                           C41.2,81.8,39.2,82.3,37.2,82.3z M37.2,81.3L37.2,81.3C37.2,81.3,37.2,81.3,37.2,81.3c1.8,0,3.7-0.5,5.2-1.4l25.7-14.8
                           c3.2-1.9,5.3-5.4,5.2-9.1V26.3c0-3.7-2-7.2-5.2-9.1L42.4,2.4c-3.2-1.9-7.2-1.9-10.5,0L6.3,17.3C3.1,19.1,1,22.6,1,26.3V56
                           c0,3.7,2,7.2,5.2,9.1L32,79.9C33.5,80.8,35.3,81.3,37.2,81.3C37.2,81.3,37.2,81.3,37.2,81.3z M37.2,79.9c-1.6,0-3.2-0.4-4.6-1.2
                           L7,63.9c-2.8-1.6-4.6-4.7-4.6-7.9V26.3c0-3.3,1.8-6.3,4.6-7.9L32.6,3.6c1.4-0.8,3-1.2,4.6-1.2l0,0c1.6,0,3.2,0.4,4.6,1.2l25.7,14.8
                           c2.8,1.6,4.6,4.7,4.6,7.9V56c0,3.3-1.8,6.3-4.6,7.9L41.8,78.7C40.3,79.5,38.8,79.9,37.2,79.9z M37.2,3.4c-1.4,0-2.8,0.4-4.1,1.1
                           L7.5,19.3c-2.5,1.4-4,4.1-4,7V56c0,2.9,1.6,5.6,4.1,7l25.7,14.8c2.5,1.4,5.6,1.4,8.1,0L66.9,63c2.5-1.4,4-4.1,4-7V26.3
                           c0-2.9-1.6-5.6-4.1-7L41.2,4.5C40,3.8,38.6,3.4,37.2,3.4z"
                            fill="#FF7061"
                          />
                        </g>
                        <g>
                          <path
                            class="st1"
                            d="M48.7,20.5h0.8c0.9,0.2,1.6,0.8,1.9,1.7c1.3-0.6,2.8-0.1,3.5,1.1c0,0.1,0.1,0.2,0.1,0.3c0,0,0.1,0,0.1,0
                           c1.3-0.6,2.8,0.1,3.4,1.4c0.2,0.4,0.2,0.8,0.2,1.2c-0.1,3.4,0,6.8,0,10.2c0,0.5-0.1,1-0.4,1.5c-0.5,0.8-1,1.7-1.5,2.6
                           c-0.1,0.2-0.2,0.5-0.2,0.8c0,2.2,0,4.3,0,6.5c0,0.2,0.1,0.4,0.3,0.5c0.8,0.5,1.3,1.4,1.1,2.4c-0.1,0.4-0.2,0.8-0.4,1.2
                           c0.8,0.5,1.2,1.4,1.1,2.4c-0.1,1-0.8,1.8-1.7,2.1c0.3,0.7,0.4,1.4,0.1,2.1c-0.3,0.7-0.8,1.2-1.5,1.4c0.6,1.3,0,2.9-1.3,3.5
                           c-0.4,0.2-0.9,0.3-1.4,0.2c-3.3-0.1-6.7,0-10,0c-0.5,0-1.1-0.1-1.5-0.4c-0.8-0.5-1.7-1-2.6-1.5c-0.2-0.1-0.5-0.2-0.7-0.2
                           c-2.2,0-4.4,0-6.5,0c-0.2,0-0.4,0.1-0.5,0.3c-0.4,0.6-0.9,1-1.6,1.1c-0.7,0.1-1.3,0-1.9-0.4c-0.8,1.2-2.4,1.5-3.5,0.8
                           c-0.5-0.3-0.8-0.8-1-1.3c-0.6,0.3-1.4,0.4-2.1,0.1c-0.7-0.3-1.2-0.8-1.5-1.5h-0.2c-1.3,0.6-2.7,0-3.3-1.2c-0.1-0.2-0.1-0.4-0.2-0.5
                           c0,0,0-0.1-0.1-0.1V47c0.2-0.5,0.5-1,0.7-1.4c0.4-0.7,0.8-1.3,1.2-2c0.1-0.2,0.2-0.5,0.2-0.8c0-2.2,0-4.3,0-6.5
                           c0-0.2-0.1-0.4-0.3-0.5c-0.8-0.5-1.3-1.4-1.1-2.4c0.1-0.4,0.2-0.8,0.4-1.2c-0.6-0.4-1-1-1.1-1.7v-0.8c0.2-0.9,0.9-1.6,1.7-2
                           c-0.3-0.7-0.4-1.4-0.1-2.1c0.3-0.7,0.8-1.2,1.5-1.4c0-0.1-0.1-0.2-0.1-0.2c-0.5-1.3,0.1-2.7,1.4-3.3c0.1,0,0.2-0.1,0.3-0.1l0.3-0.1
                           h11.5c0.5,0.2,1,0.5,1.4,0.7c0.7,0.4,1.4,0.8,2.1,1.2c0.2,0.1,0.5,0.2,0.7,0.2H43c0.2,0,0.4-0.1,0.5-0.3c0.5-0.8,1.4-1.2,2.3-1.1
                           c0.4,0.1,0.9,0.2,1.3,0.4C47.4,21.1,48,20.7,48.7,20.5z M36.5,52c0,0.4,0,0.9-0.1,1.3c-0.4,1.2-1.7,1.9-2.9,1.6
                           c-0.3-0.1-0.6-0.2-0.8-0.4c-0.4-0.3-0.8-0.6-1.2-0.9v6.3h0.5c2,0,4,0,6,0c0.5,0,1.1,0.1,1.5,0.4c0.8,0.5,1.7,1,2.5,1.5
                           C42.4,62,42.7,62,43,62c2.6,0,5.1,0,7.7,0c0.9,0,1.8,0,2.6,0c0.5,0,1-0.4,1.1-0.9c0.1-0.5-0.2-1-0.6-1.1c-0.2-0.1-0.5-0.1-0.7-0.1
                           h-3c-0.4,0-0.7-0.3-0.7-0.6c0,0,0,0,0,0c0-0.4,0.3-0.7,0.7-0.7c0,0,0,0,0,0c0.2,0,0.3,0,0.5,0h4.1c0.6,0,1.2-0.4,1.2-1c0,0,0,0,0,0
                           c0-0.6-0.5-1.1-1.1-1.1c0,0-0.1,0-0.1,0c-0.9,0-1.8,0-2.8,0c-0.7,0-1-0.2-1.1-0.7s0.3-0.7,1.1-0.7c1.4,0,2.8,0,4.2,0
                           c0.6,0,1.1-0.4,1.2-1c0,0,0,0,0-0.1c0-0.6-0.6-1.1-1.2-1.1h-4.3c-0.6,0-0.9-0.3-1-0.7s0.3-0.7,0.9-0.7h1.8c0.6,0,1.2,0,1.8,0
                           c0.6,0,1.1-0.4,1.1-1c0-0.6-0.4-1.1-1-1.1c-0.2,0-0.3,0-0.5,0h-7.4c-0.1,0-0.3,0-0.4,0c-0.4,0-0.6-0.4-0.6-0.8c0-0.1,0-0.2,0.1-0.2
                           c0.1-0.1,0.2-0.3,0.3-0.4c0.6-0.7,1.1-1.4,1.7-2.1c0.2-0.3,0.3-0.7,0.1-1c-0.2-0.3-0.6-0.5-0.9-0.4c-0.2,0.1-0.4,0.2-0.6,0.4
                           c-2.2,2.2-4.4,4.3-6.5,6.5c-0.6,0.7-1.5,1.1-2.4,1C37.7,52,37.1,52,36.5,52z M43,24.1h-0.6c-2,0-3.9,0-5.9,0
                           c-0.6,0-1.2-0.1-1.7-0.5c-0.8-0.5-1.6-1-2.4-1.4c-0.3-0.2-0.6-0.2-0.9-0.2c-3.4,0-6.7,0-10.1,0c-0.1,0-0.3,0-0.4,0
                           c-0.6,0.1-1,0.5-1,1.1c0,0.5,0.5,1,1,1c0.1,0,0.3,0,0.4,0c1,0,1.9,0,2.9,0c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.8
                           c-0.1,0-0.3,0-0.4,0h-3.9c-1,0-1.5,0.4-1.5,1.1s0.5,1.1,1.5,1.1h2.8c0.4,0,0.7,0.4,0.7,0.8c0,0.4-0.3,0.7-0.7,0.7h-4.3
                           c-0.2,0-0.5,0-0.7,0.1c-0.4,0.2-0.7,0.6-0.7,1.1c0.1,0.5,0.6,0.9,1.1,0.9h4.2c0.7,0,1.1,0.2,1.1,0.7s-0.3,0.7-1.1,0.7H19
                           c-0.6,0-1,0.4-1.1,0.9c0,0,0,0,0,0.1c0,0.6,0.4,1.1,0.9,1.2c0.2,0,0.4,0,0.5,0c2.5,0,5.1,0,7.6,0c0.4,0,0.7,0,0.9,0.4
                           c0.1,0.3,0,0.7-0.2,0.9c-0.6,0.7-1.2,1.5-1.8,2.2c-0.3,0.4-0.3,0.9,0.1,1.2c0.3,0.3,0.9,0.3,1.2,0l0.3-0.3c2.2-2.2,4.4-4.4,6.6-6.6
                           c0.5-0.5,1.1-0.8,1.8-0.8c0.6,0,1.2,0,1.8,0H38c0-0.4,0-0.9,0.1-1.3c0.4-1.2,1.7-1.9,2.9-1.5c0.3,0.1,0.5,0.2,0.7,0.4
                           c0.4,0.3,0.8,0.6,1.2,1L43,24.1z M48.8,47.7h6.3v-0.5c0-2,0-4,0-6c0-0.5,0.1-1.1,0.4-1.5c0.5-0.8,1-1.7,1.5-2.5
                           c0.1-0.3,0.2-0.6,0.2-0.9c0-2,0-4,0-6c0-1.5,0-2.9,0-4.3c0-0.5-0.3-1-0.8-1.1c-0.4-0.1-0.9,0.1-1.1,0.4c-0.1,0.2-0.2,0.5-0.2,0.8
                           c0,1,0,2,0,3c0,0.4-0.3,0.7-0.7,0.7c0,0,0,0,0,0c-0.4,0-0.7-0.3-0.7-0.7c0-0.1,0-0.2,0-0.4c0-1.4,0-2.8,0-4.2c0-0.6-0.4-1.1-1-1.2
                           c0,0,0,0-0.1,0c-0.6,0-1,0.5-1,1.1c0,0,0,0,0,0.1c0,0.9,0,1.8,0,2.7c0,0.7-0.2,1.1-0.7,1.1s-0.7-0.3-0.7-1c0-1.4,0-2.7,0-4.1
                           c0-0.3,0-0.5-0.2-0.8c-0.2-0.4-0.7-0.6-1.1-0.5c-0.5,0.1-0.9,0.6-0.9,1.1c0,1.4,0,2.8,0,4.2c0,0.7-0.2,1.1-0.7,1.1
                           s-0.7-0.3-0.7-1.1c0-1.2,0-2.3,0-3.5c0-0.6-0.4-1.1-1-1.1c0,0,0,0,0,0c-0.6,0-1.1,0.4-1.1,1c0,0.2,0,0.3,0,0.5v7.3
                           c0,0.4,0.1,0.9-0.4,1.1s-0.8-0.1-1.1-0.4l-2-1.6c-0.3-0.4-0.9-0.4-1.2-0.1c-0.3,0.2-0.5,0.6-0.3,0.9c0.1,0.2,0.2,0.4,0.4,0.6
                           c2.2,2.2,4.4,4.4,6.6,6.7c0.6,0.5,0.9,1.2,0.8,2c0,0.7,0,1.4,0,2c0.1,0,0.1,0,0.2,0c1-0.2,2,0.2,2.5,1c0.5,0.9,0.4,2-0.3,2.8
                           L48.8,47.7z M27.2,41.2h-0.1c-1.2,0.3-2.5-0.4-2.8-1.6c-0.2-0.8,0-1.7,0.6-2.3l0.8-1h-6.3v6.5c0,0.5-0.1,1.1-0.4,1.5
                           c-0.5,0.8-1,1.7-1.5,2.5c-0.1,0.3-0.2,0.5-0.2,0.8c0,2,0,4.1,0,6.1c0,1.4,0,2.8,0,4.2c0,0.6,0.4,1,0.9,1.2c0.5,0.1,0.9-0.2,1.1-0.6
                           c0.1-0.2,0.1-0.5,0.1-0.7c0-1,0-2,0-3c0-0.4,0.3-0.7,0.7-0.7c0,0,0,0,0,0c0.4,0,0.7,0.3,0.7,0.6c0,0.1,0,0.3,0,0.4v4.2
                           c-0.1,0.6,0.4,1.2,1.1,1.2c0,0,0,0,0,0c0.6,0,1.1-0.6,1-1.2c0,0,0,0,0-0.1c0-1,0-2,0-2.9c0-0.5,0.3-0.8,0.7-0.9s0.7,0.3,0.7,0.8
                           v3.4c0,0.4,0,0.8,0,1.2c0,0.5,0.5,1,1,1c0.6,0,1-0.4,1.1-1c0-0.2,0-0.3,0-0.5c0-1.4,0-2.8,0-4.2c0-0.4,0.3-0.7,0.7-0.8c0,0,0,0,0,0
                           c0.4,0,0.7,0.3,0.7,0.7c0,0,0,0,0,0c0,0.1,0,0.2,0,0.3c0,1.2,0,2.3,0,3.5c0,0.4,0.3,0.8,0.7,1c0.4,0.3,1,0.2,1.3-0.2
                           c0.2-0.3,0.3-0.6,0.3-1c0-1.2,0-2.4,0-3.6c0-1.4,0-2.9,0-4.4c0-0.3,0.2-0.5,0.4-0.7c0.3-0.2,0.6-0.1,0.8,0.1l0.3,0.2
                           c0.7,0.6,1.4,1.1,2,1.7c0.3,0.2,0.7,0.3,1,0.1c0.4-0.2,0.5-0.6,0.4-1c-0.1-0.2-0.2-0.4-0.4-0.6c-2.2-2.2-4.4-4.5-6.7-6.7
                           c-0.5-0.5-0.8-1.2-0.8-1.9C27.2,42.6,27.2,41.9,27.2,41.2z M45.8,42.3c0-0.7,0-1.2,0-1.7c0-0.3-0.1-0.5-0.3-0.7
                           c-2-2.1-4.1-4.2-6.2-6.2c-0.1-0.1-0.3-0.2-0.4-0.2c-1.1,0-2.1,0-3.2,0c-0.2,0-0.5,0.1-0.6,0.3c-2.1,2.1-4.2,4.1-6.2,6.2
                           c-0.1,0.1-0.2,0.3-0.2,0.5c0,1,0,2,0,3.1c0,0.3,0.1,0.5,0.3,0.7c2,2.1,4.1,4.1,6.2,6.2c0.2,0.1,0.3,0.2,0.5,0.2c1,0,1.9,0,2.9,0
                           c0.4,0,0.7-0.1,0.9-0.4c1.7-1.7,3.3-3.4,5.1-5C45.5,44.3,46.2,43.4,45.8,42.3z"
                            fill="#FFFFFF"
                          />
                        </g>
                      </svg>
                      <span class="ml-3">
                        {{ $t('habilidades-page.title-fit-cultural') }}
                      </span>
                    </h5>
                  </template>
                </Title>
                <router-link
                  v-if="fitCultural.length === 0"
                  :to="{ name: 'InstruccionesAssesments', params: { id: 2 } }"
                  class="btn btn-primary btn-rounded-lg btn-md"
                >
                  <span class="mr-2">{{ $t('general.realizar-test') }}</span>
                  <svg
                    width="41"
                    height="19"
                    viewBox="0 0 41 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1072_6965)">
                      <path
                        d="M40.6555 8.09292C38.8929 7.5766 37.2549 6.77541 35.795 5.68935C33.9255 4.30063 32.3588 2.43119 31.3083 0.3125L30.1867 0.864429C31.3439 3.16116 33.0175 5.15523 35.0472 6.66858C35.7416 7.1849 36.4715 7.64781 37.2549 8.05731H0.0976562V9.3036H37.5576C36.7564 9.76651 36.0086 10.2828 35.3143 10.8882C32.608 13.2205 31.3617 16.0158 30.7742 17.9386L31.9671 18.2947C32.5012 16.5143 33.6407 13.9505 36.1155 11.8318C37.4508 10.6923 38.9819 9.81992 40.6733 9.26799L40.6555 8.09292ZM38.5902 8.76947V8.68045C38.6258 8.69826 38.6436 8.69826 38.6793 8.71606C38.6436 8.73387 38.6258 8.75167 38.5902 8.76947Z"
                        fill="#1E1D1D"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1072_6965">
                        <rect
                          width="40.5757"
                          height="18"
                          fill="white"
                          transform="translate(0.0976562 0.3125)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </router-link>
                <button
                  v-if="fitCultural.length > 0"
                  @click="showModal = true"
                  class="btn btn-primary btn-rounded-lg btn-md"
                >
                  <span class="mr-2">Mejorar Score</span>
                  <svg
                    width="41"
                    height="19"
                    viewBox="0 0 41 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1072_6965)">
                      <path
                        d="M40.6555 8.09292C38.8929 7.5766 37.2549 6.77541 35.795 5.68935C33.9255 4.30063 32.3588 2.43119 31.3083 0.3125L30.1867 0.864429C31.3439 3.16116 33.0175 5.15523 35.0472 6.66858C35.7416 7.1849 36.4715 7.64781 37.2549 8.05731H0.0976562V9.3036H37.5576C36.7564 9.76651 36.0086 10.2828 35.3143 10.8882C32.608 13.2205 31.3617 16.0158 30.7742 17.9386L31.9671 18.2947C32.5012 16.5143 33.6407 13.9505 36.1155 11.8318C37.4508 10.6923 38.9819 9.81992 40.6733 9.26799L40.6555 8.09292ZM38.5902 8.76947V8.68045C38.6258 8.69826 38.6436 8.69826 38.6793 8.71606C38.6436 8.73387 38.6258 8.75167 38.5902 8.76947Z"
                        fill="#1E1D1D"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1072_6965">
                        <rect
                          width="40.5757"
                          height="18"
                          fill="white"
                          transform="translate(0.0976562 0.3125)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
            </div>
            <p class="dark:text-white text-secondary-text mb-0">
              Este test mide la pasión y el entusiasmo que tiene una persona en
              lo que hace día a día, así como la habilidad para aprender cosas
              nuevas y mantenerse actualizado. Asimismo, evalúa la creatividad,
              la proactividad y la capacidad para cumplir con los tiempos de
              entrega.
            </p>
          </section>
        </div>
      </template>
    </CardPage>
    <!-- End Card Page Sign Up -->

    <ResultadoAssesments :id="2" />
  </div>
</template>

<script>
import CardPage from '@/components/CardPage'
// import BoxEstadoDeEmpleo from '@/components/profile/BoxEstadoDeEmpleo'
// import BasicInformationSection from '@/components/profile/BasicInformationSection'
import Title from '@/components/Title'

import ApolloClient from '@/graphql/apolloClient'
import assesmentCategoriesQuery from '@/graphql/queries/assesmentCategories'
import userProfileQuery from '@/graphql/queries/userProfile'
import { mapGetters, mapActions } from 'vuex'
import { toastNotification } from '@/services/toastNotification'

import ResultadoAssesments from '@/views/ResultadoAssesments.vue'
import AlertAnimation from '@/animations/Alert.json'

export default {
  name: 'CulturalFitTest',
  data() {
    return {
      AlertAnimation,
      userProfile: null,
      modalBienvenidaShow: false,
      assesmentCategories: [],
      showModal: false
    }
  },
  components: {
    CardPage,
    // BasicInformationSection
    Title,
    ResultadoAssesments
  },
  updated() {
    this.getAssesmentCategories()
  },
  mounted() {
    this.setLoadingOn()
    this.getUserProfile()
    this.getAssesmentCategories()
  },
  watch: {
    modalBienvenidaShow(newName) {
      localStorage.modalBienvenidaShow = newName
    }
  },
  computed: {
    ...mapGetters(['token', 'user']),
    fitCultural() {
      if (
        !this.userProfile ||
        !this.userProfile.takenAssesments ||
        this.userProfile.takenAssesments.length === 0
      ) {
        return []
      }

      return this.userProfile.takenAssesments.filter(
        (assesment) => assesment.assesment.photo === 'icn-fit-cultural.svg'
      )
    }
  },
  methods: {
    ...mapActions(['setLoadingOn', 'setLoadingOff']),
    showTutorialModal: function () {
      // Comprobar si ya se ha mostrado el modal antes y almacenarlo en localStorage
      if (localStorage.getItem('modalBienvenidaShown')) {
        // Si el modal ya se mostró anteriormente, asegurar que no se muestre de nuevo
        this.modalBienvenidaShow = false
      } else {
        // Verificar si el perfil del usuario tiene datos que indicarían que no es su primera visita
        if (
          this.userProfile.englishLevel === null &&
          this.userProfile.idealCompany.length === 0 &&
          this.userProfile.jobTypePreference?.length === 0 &&
          this.userProfile.industries.length === 0 &&
          this.userProfile.jobStatus === null &&
          this.userProfile.minSalary === null &&
          this.userProfile.personsInCharge === null
        ) {
          // Mostrar el modal solo si es evidente que el usuario es nuevo y no ha configurado su perfil
          this.modalBienvenidaShow = true
          // Almacenar en localStorage que el modal ya se ha mostrado para no repetir en futuras sesiones
          localStorage.setItem('modalBienvenidaShown', 'true')
        } else {
          // No mostrar el modal si el perfil del usuario ya está parcialmente o totalmente lleno
          this.modalBienvenidaShow = false
        }
      }
    },

    getAssesmentCategories: async function () {
      try {
        const habilitiesQuery = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale
            }
          },
          fetchPolicy: 'network-only',
          query: assesmentCategoriesQuery
        })
        this.assesmentCategories = habilitiesQuery.data.assesmentCategories
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    getUserProfile: async function () {
      try {
        const userProfileData = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale,
              authorization: `Bearer ${await this.token}`
            }
          },
          fetchPolicy: 'network-only',
          query: userProfileQuery
        })
        this.userProfile = userProfileData.data.userProfile
        this.showTutorialModal()
        this.setLoadingOff()
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    onModalClose() {
      this.modalBienvenidaShow = false
    },
    closeModal() {
      this.showModal = false
    }
  }
}
</script>
