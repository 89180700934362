<template>
  <div class="flex flex-col items-center mt-4">
    <Vue3Lottie :animationData="AlertAnimation" :height="120" :width="120" />
    <h3 class="text-center text-2xl my-4">
      <span class="flex items-center justify-center">{{ $t('seccion_incompleta.titulo') }}</span>
    </h3>
    <p class="text-center text-lg mt-2">
      {{ $t('seccion_incompleta.descripcion') }}
    </p>
  </div>
</template>

<script>
import AlertAnimation from '@/animations/Alert.json'

export default {
  name: 'SeccionIncompleta',
  data: function () {
    return {
      AlertAnimation
    }
  }
}
</script>
