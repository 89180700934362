<template>
  <CardPage class="bg-transparentAux">
    <template #default>
      <!-- Title -->
      <div class="card--page--header">
        <Title>
          <template #title>
            <h5 class="mt-2">
              {{ $t('box-estado-de-empleo.title-estado-de-empleo') }}
            </h5>
          </template>
          <template #options v-if="!isPublic">
            <button
              type="button"
              @click="edit = true"
              role="button"
              aria-label="Edit"
              v-if="edit === false"
              class="w-9 h-9 flex justify-center items-center rounded-md btn-secondary transform -translate-y-2"
            >
              <img src="@/assets/images/icn-edit.svg" />
            </button>
          </template>
        </Title>
      </div>
      <!-- End Title -->
      <!-- Body -->
      <div class="card--page--body">
        <ul class="list--box" v-if="edit === false">
          <li>
            <span class="icon"
              ><img src="@/assets/images/icn-status-laboral.svg" /></span
            ><b>{{ $t('box-estado-de-empleo.text-estado-laboral') }}: </b>
            <span v-if="userJobStatus">{{
              $t(`estado-laboral.opt-${userJobStatus}`)
            }}</span>
          </li>
          <li>
            <span class="icon"
              ><img src="@/assets/images/icn-type-position.svg" /></span
            ><b>{{ $t('box-estado-de-empleo.text-posicion-ideal') }}: </b>
            <span v-if="userJobType">{{ userJobType }}</span>
          </li>
          <li>
            <span class="icon"
              ><img src="@/assets/images/icn-disponibilidad.svg" /></span
            ><b>{{ $t('box-estado-de-empleo.text-disponibilidad') }}: </b>
            <span v-if="userAvailability">{{
              $t(`tiempo-ideal.opt-${userAvailability}`)
            }}</span>
          </li>
          <li>
            <span class="icon"
              ><img src="@/assets/images/icn-money.svg"
            /></span>
            <b>{{ $t('box-estado-de-empleo.text-expectativa-salarial') }}: </b>
            <span v-if="initialIncomeRange"
              >USD ${{ addCommas }} (<span v-if="negotiable === true">{{
                $t('box-estado-de-empleo.lbl-negociable')
              }}</span>
              <span v-else>{{
                $t('box-estado-de-empleo.lbl-no-negociable')
              }}</span
              >)
            </span>
          </li>
        </ul>
        <Form @submit="submitForm" v-else>
          <div class="form--group">
            <label class="form--label" for="status-laboral">{{
              $t('box-estado-de-empleo.lbl-estado-laboral')
            }}</label>
            <select
              id="status-laboral"
              name="status-laboral"
              class="form--element form--select w-full"
              v-model="userJobStatus"
            >
              <option
                v-for="item in jobStatusOptions"
                :key="item"
                :value="item"
              >
                {{ $t(`estado-laboral.opt-${item}`) }}
              </option>
            </select>
          </div>
          <div class="form--group">
            <label class="form--label" for="forma-laboral">{{
              $t('box-estado-de-empleo.lbl-forma-laboral')
            }}</label>
            <select
              id="forma-laboral"
              name="forma-laboral"
              class="form--element form--select w-full"
              v-model="userJobType"
            >
              <option v-for="item in idealJobOptions" :key="item" :value="item">
                {{ item }}
              </option>
            </select>
          </div>
          <div class="form--group">
            <label class="form--label" for="tiempo-ideal">{{
              $t('box-estado-de-empleo.lbl-tiempo-ideal')
            }}</label>
            <select
              id="tiempo-ideal"
              name="tiempo-ideal"
              class="form--element form--select w-full"
              v-model="userAvailability"
            >
              <option
                v-for="item in idealTimeOptions"
                :key="item"
                :value="item"
              >
                {{ $t(`tiempo-ideal.opt-${item}`) }}
              </option>
            </select>
          </div>
          <div class="form--group">
            <label class="form--label" for="pago-ideal">{{
              $t('box-estado-de-empleo.lbl-pago-ideal')
            }}</label>
            <input
              class="form--range w-full"
              type="range"
              min="0"
              v-model="initialIncomeRange"
              max="20000"
              step="100"
              id="pago-ideal-inicial"
            />
            <label class="form--label text-center" v-if="initialIncomeRange"
              >USD ${{ addCommas }}
              {{ $t('box-estado-de-empleo.lbl-mensuales') }}</label
            >
          </div>
          <div class="form--group">
            <Field
              v-slot="{ field }"
              name="negotiable"
              as="label"
              class="ml-3 form--checkbox"
            >
              <input
                type="checkbox"
                name="negotiable"
                v-bind="field"
                v-model="negotiable"
              />

              {{ $t('box-estado-de-empleo.lbl-negociable') }}
            </Field>
            <ErrorMessage name="negotiable" class="form--error ml-3" />
          </div>
          <div class="mt-10 flex justify-center space-x-4">
            <button
              type="button"
              @click="edit = false"
              class="btn btn-md btn-outline btn-outline-primary btn-rounded-lg"
              role="button"
            >
              {{ $t('general.cancelar') }}
            </button>
            <button
              type="button"
              @click="submitForm"
              class="btn btn-md btn-primary btn-rounded-lg mx-auto"
              role="button"
            >
              {{ $t('general.guardar') }}
            </button>
          </div>
        </Form>
      </div>
      <!-- End Body -->
    </template>
  </CardPage>
</template>

<script>
import CardPage from '@/components/CardPage'
import Title from '@/components/Title'
import apolloClient from '@/graphql/apolloClient'
import saveJobStatus from '@/graphql/mutations/saveJobStatus'
import { Field, Form, ErrorMessage } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'
import { toastNotification } from '@/services/toastNotification'

export default {
  name: 'EstadoDeEmpleoBox',
  props: {
    user: Object,
    isPublic: Boolean,
    id: { type: Number, required: false }
  },
  components: {
    CardPage,
    Title,
    Form,
    Field,
    ErrorMessage
  },
  data() {
    return {
      initialIncomeRange: this.user.minSalary,
      userJobStatus: this.user.jobStatus,
      userJobType: this.user.jobTypePreference,
      userAvailability: this.user.availability,
      edit: true,
      jobStatusOptions: ['looking', 'open', 'closed'],
      idealJobOptions: ['Full Time', 'Part-Time', 'Freelance'],
      idealTimeOptions: ['inm', '1to2', '3to4', '5to6', '7more', 'notsure'],
      negotiable:
        this.user.openToNegotiate === null ? false : this.user.openToNegotiate
    }
  },
  mounted() {
    if (
      (this.userAvailability !== null &&
        this.userJobStatus !== null &&
        this.userJobType !== null &&
        this.initialIncomeRange !== null) ||
      this.isPublic
    ) {
      this.edit = false
    } else {
      this.edit = true
    }
  },
  computed: {
    ...mapGetters(['token']),
    addCommas() {
      if (this.initialIncomeRange) {
        return this.initialIncomeRange
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } else {
        return ''
      }
    }
  },
  methods: {
    ...mapActions(['setLoadingOn', 'setLoadingOff', 'fetchUser']),
    async submitForm() {
      try {
        this.setLoadingOn()
        const saveStatus = await apolloClient.mutate({
          mutation: saveJobStatus,
          variables: {
            availability: this.userAvailability,
            jobStatus: this.userJobStatus,
            jobTypePreference: this.useJobType,
            minSalary: parseInt(this.initialIncomeRange),
            openToNegotiate: this.negotiable,
            id: this.id
          },
          context: {
            headers: {
              authorization: `Bearer ${await this.token}`
            }
          }
        })
        if (!saveStatus.errors) {
          await this.fetchUser()
          this.setLoadingOff()
          this.edit = false
          toastNotification(this.$t('general.info-guardada'), 'success')
        }
      } catch (e) {
        toastNotification(e, 'error')
      }
    }
  }
}
</script>
