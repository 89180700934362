<template>
  <ModalRegistroEvento
    v-if="modalEventsShow"
    @cerrarModal="modalEventsShow = false"
    :title="conference.name"
    :date="confDate"
    :time="confTime"
    :place="conference.place"
    :image="conference.photo"
    :language="conference.eventLang"
    :ticket="$t('general.gratis')"
    :liveLink="conference.liveLink"
    :calendarLink="conference.calendarLink"
    :category="conference.category"
    :badges="conference.subCategories"
  />
  <ModalMensaje
    size="xs"
    v-if="modalMensajeShow"
    @cerrarModal="modalMensajeShow = false"
  >
    <template #default>
      <div class="modal--header">
        <Title>
          <template #title>
            <h4>
              {{ user.fullname }}
            </h4>
          </template>
        </Title>
      </div>
      <div class="modal--body">
        <p class="text-primary text-xl text-center">
          <b>{{ error }}</b>
        </p>
        <div class="flex mb-8" v-if="infoEventShow == true">
          <div class="w-2/6">
            <img
              :src="
                conference.photo !== 'null'
                  ? conference.photo
                  : require('@/assets/images/img-about-us-codys.svg')
              "
              class="w-full"
              :alt="conference.name"
            />
          </div>
          <div class="w-4/6 pl-6">
            <div class="cards--comunidad--badges">
              <span
                v-if="conference.category"
                class="badge badge-line badge-xs badge-rounded-full"
                >{{ conference.category }}</span
              >
              <template v-if="conference.subCategories.length > 0">
                <span
                  v-for="badge in conference.subCategories"
                  :key="badge"
                  class="badge badge-line badge-xs badge-rounded-full"
                  >#{{ badge }}</span
                >
              </template>
            </div>
            <ul class="cards--comunidad--date">
              <li>
                <time>{{ confTime }}</time>
              </li>
              <li>|</li>
              <li>{{ confDate }}</li>
            </ul>
            <h3 class="text-white font-bold mb-3">
              {{ conference.name }}
            </h3>
            <ul class="cards--comunidad--meta">
              <li>
                <span><img src="@/assets/images/icn-lugar.svg" /></span
                >{{ conference.place }}
              </li>
              <li>
                <span><img src="@/assets/images/icn-idioma.svg" /></span
                >{{ conference.eventLang }}
              </li>
              <li>
                <span><img src="@/assets/images/icn-ticket.svg" /></span
                >{{ $t('general.gratis') }}
              </li>
            </ul>
          </div>
        </div>
        <div
          v-if="infoEventShow == true"
          class="mb-0 flex flex-wrap justify-center gap-y-4 gap-x-4"
        >
          <a
            class="btn btn-primary btn-lg btn-rounded-lg"
            :href="conference.calendarLink"
            :title="$t('general.agregar-al-calendario')"
            target="_blank"
            >{{ $t('general.agregar-al-calendario') }}</a
          >
          <a
            class="btn btn-secondary btn-lg btn-rounded-lg"
            :href="conference.liveLink"
            :title="$t('general.link-al-evento')"
            target="_blank"
            >{{ $t('general.link-al-evento') }}</a
          >
          <router-link
            class="btn btn-secondary btn-lg btn-rounded-lg"
            :to="{ name: 'ProfileEvents' }"
          >
            <span>
              {{ $t('modal-registro-evento.button-mis-eventos') }}
            </span>
          </router-link>
        </div>
      </div>
      <div class="modal--footer">
        <button
          type="submit"
          @click="modalMensajeShow = false"
          class="btn btn-primary mx-auto btn-rounded-lg btn-lg"
          role="button"
          aria-pressed="false"
        >
          {{ $t('general.cerrar') }}
        </button>
      </div>
    </template>
  </ModalMensaje>
  <main class="main--wrapper" v-if="conference">
    <Hero
      :hero-title="conference.name"
      :hero-description="conference.except"
      section="pageHeroEvent"
      :hero-image="conference.photo"
      class="hero--md mb-8 lg:mb-16"
      :category="conference.category"
      :badges="conference.subCategories"
      :date="confDate"
      :time="confTime"
      :place="conference.place"
      :language="conference.eventLang"
      :isAuthenticated="isAuthenticated"
      @handleIncription="signUpEvento"
      :link="{
        name: 'SignUp',
        params: {
          id: conference.id,
          name: conference.name,
          date: conference.date,
          place: conference.place,
          photo: conference.photo,
          eventLang: conference.eventLang,
          category: conference.category,
          badges: conference.badges,
          liveLink: conference.liveLink,
          calendarLink: conference.calendarLink
        }
      }"
    />
    <div class="content--wrapper">
      <section class="mb-16 lg:mb-24">
        <Title class="mb-8">
          <template #title>
            <h2>{{ $t('comunidad-detail-page.title-acerca-eventos') }}</h2>
          </template>
        </Title>
        <div
          class="flex flex-wrap space-y-6 lg:flex-nowrap lg:space-x-6 lg:space-y-0"
        >
          <div class="w-full lg:w-7/12">
            <div
              class="text-base dark:text-primary-text text-secondary-text leading-loose mb-8"
              v-html="conference.description"
            ></div>
            <div class="w-full lg:w-7/12">
              <h2 class="text-3xl font-bold dark:text-white mb-8">
                {{ $t('comunidad-detail-page.title-whatWillLearn') }}
              </h2>
              <div
                class="text-base dark:text-primary-text text-secondary-text leading-loose"
                v-html="conference.whatWillLearn"
              ></div>
            </div>
          </div>
          <div class="w-full lg:w-5/12">
            <!-- Skeakers -->
            <div
              class="dark:bg-contrast bg-white p-5 shadow-lg rounded-xl mb-10"
            >
              <Title class="mb-6">
                <template #title>
                  <h4>
                    {{ $t('comunidad-detail-page.title-speakers') }}
                  </h4>
                </template>
              </Title>
              <template
                v-for="(speaker, index) in conference.expandedSpeakers"
                :key="index"
              >
                <div class="flex items-center space-x-4 mb-3">
                  <img
                    :src="
                      speaker.photoUrl
                        ? speaker.photoUrl
                        : require('@/assets/images/img-cody-speaker.svg')
                    "
                    class="rounded-full w-16"
                    :alt="speaker.name"
                  />
                  <div class="flex-grow">
                    <h5 class="dark:text-white font-bold">
                      {{ speaker.name }}
                    </h5>
                    <h6 class="text-secondary text-sm uppercase">
                      {{ speaker.position }}
                    </h6>
                  </div>
                </div>
                <p
                  class="dark:text-primary-text text-secondary-text text-sm leading-6"
                >
                  {{ speaker.about }}
                </p>
              </template>
              <!-- Dev Community Leader -->
              <div class="flex items-center space-x-4 mb-3">
                <img
                  src="@/assets/images/img-community-manager-speaker.jpg"
                  class="rounded-full w-16"
                  alt="Dev Community Leader"
                />
                <div class="flex-grow">
                  <h5 class="dark:text-white font-bold">
                    Hector Ulises Gonzalez Medel
                  </h5>
                  <h6 class="text-secondary text-sm uppercase">
                    Dev Community Leader
                  </h6>
                </div>
              </div>
              <p
                class="dark:text-primary-text text-secondary-text text-sm leading-6"
              >
                {{ $t('comunidad-detail-page.text-bio') }}
              </p>
              <!-- End Dev Community Leader -->
            </div>
            <!-- End Skeakers -->
            <div class="flex">
              <button
                v-if="isAuthenticated"
                @click="signUpEvento"
                class="btn btn-xl btn-primary btn-rounded-lg mx-auto"
              >
                {{ $t('general.inscribirse') }}
                <svg
                  class="ml-2 w-5 dark:text-white fill-current"
                  viewBox="0 0 16 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.5 3.375V8.625H12.25V3.375H3.5ZM14.4375 6C14.4375 5.28906 15.0117 4.6875 15.75 4.6875V2.0625C15.75 1.35156 15.1484 0.75 14.4375 0.75H1.3125C0.574219 0.75 0 1.35156 0 2.0625V4.6875C0.710938 4.6875 1.3125 5.28906 1.3125 6C1.3125 6.73828 0.710938 7.3125 0 7.3125V9.9375C0 10.6758 0.574219 11.25 1.3125 11.25H14.4375C15.1484 11.25 15.75 10.6758 15.75 9.9375V7.3125C15.0117 7.3125 14.4375 6.73828 14.4375 6ZM13.125 3.15625V8.84375C13.125 9.22656 12.8242 9.5 12.4688 9.5H3.28125C2.89844 9.5 2.625 9.22656 2.625 8.84375V3.15625C2.625 2.80078 2.89844 2.5 3.28125 2.5H12.4688C12.8242 2.5 13.125 2.80078 13.125 3.15625Z"
                  />
                </svg>
              </button>
              <router-link
                v-else
                :to="{
                  name: 'SignUp',
                  params: {
                    id: conference.id,
                    name: conference.name,
                    date: conference.date,
                    place: conference.place,
                    photo: conference.photo,
                    eventLang: conference.eventLang,
                    category: conference.category,
                    badges: conference.badges,
                    liveLink: conference.liveLink,
                    calendarLink: conference.calendarLink
                  }
                }"
                class="btn btn-xl btn-primary btn-rounded-lg mx-auto"
                title=""
                >{{ $t('general.inscribirse') }}
                <svg
                  class="ml-2 w-5 text-white fill-current"
                  viewBox="0 0 16 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.5 3.375V8.625H12.25V3.375H3.5ZM14.4375 6C14.4375 5.28906 15.0117 4.6875 15.75 4.6875V2.0625C15.75 1.35156 15.1484 0.75 14.4375 0.75H1.3125C0.574219 0.75 0 1.35156 0 2.0625V4.6875C0.710938 4.6875 1.3125 5.28906 1.3125 6C1.3125 6.73828 0.710938 7.3125 0 7.3125V9.9375C0 10.6758 0.574219 11.25 1.3125 11.25H14.4375C15.1484 11.25 15.75 10.6758 15.75 9.9375V7.3125C15.0117 7.3125 14.4375 6.73828 14.4375 6ZM13.125 3.15625V8.84375C13.125 9.22656 12.8242 9.5 12.4688 9.5H3.28125C2.89844 9.5 2.625 9.22656 2.625 8.84375V3.15625C2.625 2.80078 2.89844 2.5 3.28125 2.5H12.4688C12.8242 2.5 13.125 2.80078 13.125 3.15625Z"
                  />
                </svg>
              </router-link>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- <WhatWillLearnVue /> -->
    <!-- Sección Otros Eventos -->
    <div class="content--wrapper">
      <section class="content--comunidad">
        <Title class="mb-6">
          <template #title>
            <h3>{{ $t('general.otros-eventos') }}</h3>
          </template>
          <template #options>
            <router-link
              :to="{ name: 'Eventos' }"
              class="btn btn-sm btn-rounded-lg btn-secondary transform -translate-y-3"
              >{{ $t('home-page.cta-comunidad') }}</router-link
            >
          </template>
        </Title>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <template
            v-for="conference in courses"
            v-bind:key="conference.id"
            v-bind:conferenceData="conference"
          >
            <CardComunidad
              v-if="courses.length > 0"
              :conferenceData="conference"
            />
          </template>
        </div>
      </section>
    </div>
    <!--  End Sección Otros Eventos -->
  </main>
</template>

<script>
import Hero from '@/components/Hero'
import Title from '@/components/Title'
import CardComunidad from '@/components/CardComunidad'
import ModalRegistroEvento from '@/components/ModalRegistroEvento'
import ModalMensaje from '@/components/Modal'
import ApolloClient from '@/graphql/apolloClient'
import EventQuery from '@/graphql/queries/event'
import EventsQuery from '../graphql/queries/events'
import ROUTES from '@/constants/routes'
import { hourFormat, dateFormat } from '@/services/dateFormatting'
import { mapGetters, mapActions } from 'vuex'
import signUpEvent from '@/graphql/mutations/signUpEvent'
import { toastNotification } from '@/services/toastNotification'
// import { Splide, SplideSlide } from '@splidejs/vue-splide'
// import WhatWillLearnVue from '../components/WhatWillLearn.vue'

export default {
  components: {
    Hero,
    Title,
    ModalRegistroEvento,
    ModalMensaje,
    CardComunidad
  },
  data: function () {
    return {
      ROUTES,
      conference: null,
      modalEventsShow: false,
      modalMensajeShow: false,
      error: null,
      infoEventShow: false,
      eventFromParams: false,
      courses: [],
      options: {
        rewind: true,
        perPage: 3,
        pagination: true,
        autoplay: false,
        pauseOnHover: false,
        arrows: true,
        autoWidth: false,
        padding: '0rem',
        lazyLoad: 'nearby',
        breakpoints: {
          640: {
            perPage: 1
          },
          1024: {
            perPage: 3
          },
          1340: {
            perPage: 4
          }
        }
      }
    }
  },
  created: function () {
    this.getEvent()
  },
  methods: {
    ...mapActions(['setLoadingOn', 'setLoadingOff']),
    getEvent: async function () {
      try {
        const event = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale
            }
          },
          variables: {
            eventId: parseInt(this.$route.params.id)
          },
          fetchPolicy: 'network-only',
          query: EventQuery
        })
        this.conference = event.data.event
        this.checkRouteParams()
        this.getEvents()
        this.setLoadingOff()
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    checkRouteParams: function () {
      if (this.$route.params.eventLang && !this.eventFromParams) {
        this.signUpEvento()
        this.eventFromParams = true
      }
    },
    signUpEvento: async function () {
      if (this.conference.liveLink.includes('calendly')) {
        window.open(this.conference.liveLink, '_blank')
      } else {
        try {
          this.setLoadingOn()
          const result = await ApolloClient.mutate({
            mutation: signUpEvent,
            variables: {
              email: this.user.email,
              speciality:
                this.user.speciality === null ? '' : this.user.speciality,
              fullname: this.user.fullname,
              eventId: parseInt(this.$data.conference.id)
            }
          })
          if (result.data.inscribeToEvent) {
            this.setLoadingOff()
            this.modalEventsShow = true
          }
        } catch (e) {
          this.setLoadingOff()
          if (e.message === 'Ya te encuentras inscrito a este evento') {
            this.error = this.$i18n.t('general.ya-estas-inscripto')
            this.infoEventShow = true
          } else {
            this.error = e
            this.infoEventShow = false
            toastNotification(e, 'error')
          }
          this.modalMensajeShow = true
        }
      }
    },
    getEvents: async function () {
      try {
        const eventsQuery = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale
            }
          },
          fetchPolicy: 'network-only',
          query: EventsQuery
        })
        this.moreEvents = eventsQuery.data.events

        this.moreEvents.forEach((element) => {
          // eslint-disable-next-line eqeqeq
          if (element.id != this.$route.params.id) {
            if (this.courses.length <= 2) {
              this.courses.push(element)
            }
          }
        })
        this.setLoadingOff()
      } catch (e) {
        toastNotification(e, 'error')
      }
    }
  },
  computed: {
    ...mapGetters(['user', 'isAuthenticated']),
    confTime: function () {
      return hourFormat(this.conference.date)
    },
    confDate: function () {
      return dateFormat(this.conference.date)
    }
  }
}
</script>
