<template>
  <main class="main--wrapper py-24 lg:py-40" v-if="user">
    <div class="content--wrapper">
      <CardPage class="w-full lg:w-6/12 xl:w-7/12 mx-auto">
        <template #default>
          <button
            type="button"
            class="card--close"
            role="button"
            @click="$router.back()"
          >
            <img
              src="@/assets/images/icn-close.svg"
              :alt="$t('general.cerrar')"
            />
          </button>
          <form @submit.prevent="validar">
            <div class="card--page--header">
              <Title>
                <template #title>
                  <h4>{{ $t('modal-tecnologias.title') }}</h4>
                </template>
              </Title>
            </div>
            <div class="card--page--body">
              <p
                class="dark:text-primary-text text-secondary-text"
                v-for="(description, index) in 2"
                :key="index"
              >
                {{ $t(`modal-tecnologias.description-0${index + 1}`) }}
              </p>
              <h4
                class="dark:text-primary-text text-secondary-text font-bold mb-5"
              >
                {{ $t('modal-tecnologias.title-tecnologias-relevantes') }}
              </h4>
              <table class="table--tecnologias table-fixed mb-5 w-full">
                <thead>
                  <tr>
                    <th class="w-2/7">
                      <h5
                        class="dark:text-primary-text text-secondary-text text-sm font-normal"
                      >
                        {{ $t('modal-tecnologias.lbl-tecnologias') }}
                      </h5>
                    </th>
                    <th class="w-2/7">
                      <h5
                        class="dark:text-primary-text text-secondary-text text-sm font-normal flex"
                      >
                        {{ $t('modal-tecnologias.lbl-nivel') }}
                        <div classs="tooltip">
                          <span
                            class="ml-2"
                            type="button"
                            role="button area"
                            aria-label="Info"
                            @mouseenter="tooltipShow = true"
                            @mouseleave="tooltipShow = false"
                          >
                            <img
                              src="@/assets/images/icn-info.svg"
                              class="w-4"
                            />
                          </span>
                          <transition name="fade">
                            <div
                              class="tooltip--globe tooltip--sm rounded-lg"
                              v-show="tooltipShow == true"
                            >
                              <p
                                class="dark:text-primary-text text-secondary-text"
                                v-for="(level, index) in 4"
                                :key="index + 1"
                                :class="index + 1 === 4 ? 'last' : ''"
                              >
                                <b>
                                  {{
                                    $t(
                                      `tooltip-nivel-experiencia.title-${
                                        index + 1
                                      }`
                                    )
                                  }}:
                                </b>
                                {{
                                  $t(
                                    `tooltip-nivel-experiencia.description-${
                                      index + 1
                                    }`
                                  )
                                }}
                              </p>
                            </div>
                          </transition>
                        </div>
                      </h5>
                    </th>
                    <th class="w-2/7">
                      <h5
                        class="dark:text-primary-text text-secondary-text text-sm font-normal"
                      >
                        {{ $t('modal-tecnologias.lbl-experiencia') }}
                      </h5>
                    </th>
                    <th class="w-1/7"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(lista, index) in tecnologias" :key="index">
                    <td>
                      <input
                        list="technologiesList"
                        name="tecnologias"
                        class="form--element form--select w-full cursor-default"
                        v-model.lazy="tecnologias[index].techName"
                      />
                      <datalist id="technologiesList">
                        <option
                          :value="tecnologia"
                          v-for="(tecnologia, index) in tecnologiasLista"
                          :key="index"
                        />
                      </datalist>
                    </td>
                    <td>
                      <select
                        id="nivel"
                        name="nivel"
                        class="form--element form--select w-full"
                        v-model="tecnologias[index].level"
                      >
                        <option value=""></option>
                        <option
                          :value="`${nivel}`"
                          v-for="(nivel, index) in 4"
                          :key="nivel"
                        >
                          {{
                            $t(
                              `modal-tecnologias.opcion-nivel-experiencia-${
                                index + 1
                              }`
                            )
                          }}
                        </option>
                      </select>
                    </td>
                    <td>
                      <select
                        id="experiencia"
                        name="experiencia"
                        class="form--element form--select w-full"
                        v-model="tecnologias[index].expYears"
                      >
                        <option value=""></option>
                        <option
                          v-for="year in 15"
                          :key="year"
                          :value="year !== 10 ? `${year}` : `${year}+`"
                        >
                          {{ year !== 15 ? year : `${year}+` }}
                        </option>
                      </select>
                    </td>
                    <td>
                      <span
                        class="w-9 h-9 flex justify-center items-center rounded-md btn-secondary"
                        @click="removeRow(index)"
                      >
                        <img src="@/assets/images/icn-remove.svg" />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="flex">
                <span
                  class="btn btn-rounded-lg btn-secondary btn-lg"
                  type="button"
                  role="button"
                  :aria-label="$t('general.agregar')"
                  @click="addRow()"
                >
                  {{ $t('general.agregar') }}
                </span>
              </div>
            </div>
            <div class="card--page--footer flex justify-center space-x-4">
              <span
                type="button"
                class="btn btn-outline btn-outline-primary btn-rounded-lg btn-lg"
                role="button"
                aria-pressed="false"
                @click="$router.back()"
              >
                {{ $t('general.volver') }}
              </span>
              <button
                type="submit"
                class="btn btn-primary btn-rounded-lg btn-lg"
                role="button"
                aria-pressed="false"
              >
                {{ $t('general.guardar') }}
              </button>
            </div>
          </form>
        </template>
      </CardPage>
    </div>
  </main>
</template>

<script>
import CardPage from '@/components/CardPage'
import Title from '@/components/Title'
import tecnologiasLista from '@/components/json/tecnologias.json'
import apolloClient from '@/graphql/apolloClient'
import saveTechnologiesMutation from '@/graphql/mutations/saveTechnologies'
import { mapActions, mapGetters } from 'vuex'
import { toastNotification } from '@/services/toastNotification'

export default {
  name: 'EditTecnologías',
  components: {
    Title,
    CardPage
  },
  computed: {
    ...mapGetters(['token', 'user'])
  },
  data() {
    return {
      tecnologiasLista,
      tecnologias: [{ techName: null, level: null, expYears: null }],
      tooltipShow: false,
      value: null
    }
  },
  mounted() {
    this.initializeData()
  },
  methods: {
    ...mapActions(['fetchUser', 'setLoadingOn', 'setLoadingOff']),
    async validar() {
      try {
        this.setLoadingOn()
        const saveTechnologies = await apolloClient.mutate({
          mutation: saveTechnologiesMutation,
          context: {
            headers: {
              authorization: `Bearer ${await this.token}`
            }
          },
          variables: {
            technologies: this.tecnologias.filter((val) => {
              return (
                JSON.stringify(val) !==
                JSON.stringify({ techName: null, level: null, expYears: null })
              )
            })
          }
        })
        if (!saveTechnologies.errors) {
          await this.fetchUser()
          this.setLoadingOff()
          this.$router.push({ name: 'Profile' })
          toastNotification(this.$t('general.info-guardada'), 'success')
        }
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    addRow() {
      this.tecnologias.push({ techName: null, level: null, expYears: null })
    },
    removeRow(index) {
      this.tecnologias.splice(index, 1)
    },
    initializeData() {
      if (this.user.technologies) {
        this.user.technologies.forEach((val) => {
          this.tecnologias.unshift({
            techName: val.techName,
            level: val.level,
            expYears: val.expYears
          })
        })
      }
    }
  }
}
</script>
