<template>
  <div class="progress mt-3">
    <div class="progress--meta flex justify-between items-center">
      <h4 v-if="title" class="font-semibold" style="line-height: 1.3rem">
        {{ title }}
      </h4>
      <h6 v-if="level?.length" class="ml-auto" style="color: #5956d9">
        {{ $t(`modal-tecnologias.opcion-nivel-experiencia-${level}`) }}
      </h6>
    </div>
    <div class="light:progress--bar relative overflow-hidden h-2 w-full">
      <div class="w-full h-full rounded-full bg-white absolute z-0"></div>
      <div
        class="progress--progress bg-primary"
        :style="`width: ${porcentaje}%`"
      ></div>
    </div>
    <h6
      class="light:text-secondary-text text-dark-text text-xs mt-1"
      v-if="years"
    >
      {{ years }} {{ $t('general.años') }} de experiencia
    </h6>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    porcentaje: Number,
    title: String,
    years: String,
    level: String
  }
}
</script>
