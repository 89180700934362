<template>
  <div class="cards p-2 rounded-lg border-l-8 border-secondary mb-4">
    <div class="flex space-x-4">
      <div class="w-full">
        <div class="flex justify-between">
          <h6 class="dark:text-white font-bold">{{ candidate.name }}</h6>
          <button
            class="bg-secondary rounded-full h-7 w-7 flex justify-center align-center"
          >
            <icnEnvelope class="image w-4 items-center" />
          </button>
        </div>
        <span class="text-secondary-text">{{ candidate.speciality }}</span>
        <div class="flex">
          <span
            class="badge dark:bg-primary-text text-white bg-secondary-text badge-xs badge-rounded-full mb-2"
            v-for="stack in candidate.techStack"
            :key="stack"
          >
            {{ stack }}
          </span>
        </div>
        <div class="flex flex-wrap justify-between">
          <div class="flex justify-between">
            <span
              >Exp:
              <span
                class="bg-secondary rounded-full h-5 w-5 flex items-center justify-center text-white"
              >
                {{ candidate.experience }}</span
              >
            </span>
          </div>
          <div class="flex">
            <span
              >Sal:
              <span
                class="text-white font-bold rounded-lg dark:bg-secondary-text bg-primary-text text-sm p-0.5"
              >
                ${{ candidate.salary.min }} - ${{ candidate.salary.max }}</span
              >
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import icnEnvelope from '@/components/svg/icon/icnEnvelope.vue'

export default {
  name: 'CardCandidate',
  props: {
    candidate: Object
  },
  components: { icnEnvelope },
  data() {
    return {}
  }
}
</script>
