<template>
  <!-- END SIDEBAR -->
  <div class="w-full lg:w-8/12 lg:pr-6">
    <!-- Card Page Sign Up -->
    <CardPage class="bg-transparentAux">
      <template #default>
        <!-- Body -->
        <div class="card--page--body lg:pl-1 pt-10">
          <section class="mb-12 w-full flex flex-wrap md:pt-7 md:pb-10">
            <div class="w-full lg:w-6/12 lg:pr-4 lg:mt-16">
              <h2 class="dark:text-white text-secondary-text mb-4 lg:text-4xl">
                {{ $t('habilidades-page.title_1') }}
                <span class="-mx-2" style="display: inline-block">
                  <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M18.6184 14.8587L10.9865 11.1536L18.6213 7.44774C18.8379 7.32717 19.0148 7.07592 19.0949 6.7662C19.1758 6.45214 19.1433 6.1352 19.0076 5.91789C18.8589 5.65076 18.5434 5.44067 18.1773 5.35836C17.8929 5.29411 17.6308 5.32082 17.4777 5.43056L10.3591 10.2504L11.0262 1.72614C11.0703 1.34061 10.9432 1.05687 10.6494 0.872772C10.2284 0.609975 9.55629 0.622248 9.11806 0.90165C8.91013 1.03449 8.67405 1.28285 8.72819 1.71459L9.39674 10.2497L2.28318 5.43344C1.77203 5.15043 1.03057 5.41684 0.756941 5.83486C0.46671 6.49329 0.720842 7.24053 1.13742 7.44846L8.76863 11.1536L1.14103 14.8566C0.915053 14.9844 0.740337 15.2334 0.660921 15.541C0.58006 15.8551 0.612548 16.172 0.748278 16.3886C0.897004 16.6557 1.2125 16.8658 1.57854 16.9481C1.863 17.0124 2.12507 16.9857 2.27813 16.8759L9.39674 12.0561L8.72964 20.5803C8.67477 21.0236 8.91085 21.272 9.11878 21.4048C9.55629 21.6842 10.2292 21.6965 10.6501 21.4337C10.9439 21.2496 11.071 20.9659 11.0277 20.5897L10.3591 12.0568L17.4727 16.873C17.9846 17.156 18.726 16.8896 18.9989 16.4716C19.2891 15.8139 19.035 15.0667 18.6184 14.8587Z"
                      fill="#DB7E6E" />
                  </svg>
                </span>
                {{ $t('habilidades-page.title_2') }}
              </h2>
              <p class="text-lg font-semibold mb-4" style="color: #b9a3ff">
                {{ $t('habilidades-page.subtitle') }}
              </p>
              <p class="dark:text-white text-secondary-text text-sm mb-0">
                {{ $t('habilidades-page.body_1') }}
              </p>
              <p class="dark:text-white text-secondary-text text-sm">
                {{ $t('habilidades-page.body_2') }}
              </p>
              <div class="w-full px-4 mt-1">
                <router-link :to="{ name: 'SoftSkillsTest' }"
                  class="flex justify-center items-center flex-grow-0 flex-shrink-0 w-full relative overflow-hidden gap-3 bg-primary text-black rounded-lg py-3 cursor-pointer">
                  <span> {{ $t('profile.start_test') }} </span>
                  <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
                </router-link>
              </div>
            </div>
            <div class="w-full lg:w-6/12 lg:pl-4">
              <img src="@/assets/images/test-dinamica.svg" class="mb-6 w-full" alt="Step 1" />
              <ul class="px-4">
                <li class="dark:text-white text-secondary-text flex items-center text-sm">
                  <span class="mr-3"><svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M12.7993 21.5909C7.54912 21.5909 3.29297 17.3347 3.29297 12.0845C3.29297 6.83427 7.54912 2.57812 12.7993 2.57812C18.0496 2.57812 22.3057 6.83427 22.3057 12.0845C22.2938 17.3298 18.0446 21.5789 12.7993 21.5909ZM12.7993 4.20354C8.44915 4.20354 4.92263 7.73007 4.92263 12.0803C4.92263 16.4304 8.44915 19.957 12.7993 19.957C17.1495 19.957 20.6761 16.4304 20.6761 12.0803C20.6701 7.73255 17.147 4.20953 12.7993 4.20354ZM10.6282 15.605C10.7905 15.7702 11.0162 15.8573 11.2474 15.844C11.4645 15.8489 11.6735 15.7622 11.8233 15.605L17.6792 9.67305C17.9969 9.35492 17.9969 8.83955 17.6792 8.52142C17.5279 8.36611 17.3202 8.27852 17.1034 8.27852C16.8865 8.27852 16.6789 8.36611 16.5275 8.52142L11.204 13.8341L9.0311 11.6612C8.71004 11.3621 8.20974 11.3709 7.89943 11.6812C7.58913 11.9915 7.58031 12.4918 7.87947 12.8129L10.6282 15.605Z"
                        fill="white" />
                    </svg>
                  </span>
                  {{ $t('habilidades-page.bullet_1') }}
                </li>
                <li class="dark:text-white text-secondary-text flex items-center text-sm my-2">
                  <span class="mr-3"><svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M12.7993 21.5909C7.54912 21.5909 3.29297 17.3347 3.29297 12.0845C3.29297 6.83427 7.54912 2.57812 12.7993 2.57812C18.0496 2.57812 22.3057 6.83427 22.3057 12.0845C22.2938 17.3298 18.0446 21.5789 12.7993 21.5909ZM12.7993 4.20354C8.44915 4.20354 4.92263 7.73007 4.92263 12.0803C4.92263 16.4304 8.44915 19.957 12.7993 19.957C17.1495 19.957 20.6761 16.4304 20.6761 12.0803C20.6701 7.73255 17.147 4.20953 12.7993 4.20354ZM10.6282 15.605C10.7905 15.7702 11.0162 15.8573 11.2474 15.844C11.4645 15.8489 11.6735 15.7622 11.8233 15.605L17.6792 9.67305C17.9969 9.35492 17.9969 8.83955 17.6792 8.52142C17.5279 8.36611 17.3202 8.27852 17.1034 8.27852C16.8865 8.27852 16.6789 8.36611 16.5275 8.52142L11.204 13.8341L9.0311 11.6612C8.71004 11.3621 8.20974 11.3709 7.89943 11.6812C7.58913 11.9915 7.58031 12.4918 7.87947 12.8129L10.6282 15.605Z"
                        fill="white" />
                    </svg>
                  </span>
                  {{ $t('habilidades-page.bullet_2') }}
                </li>
                <li class="dark:text-white text-secondary-text flex items-center text-sm">
                  <span class="mr-3"><svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M12.7993 21.5909C7.54912 21.5909 3.29297 17.3347 3.29297 12.0845C3.29297 6.83427 7.54912 2.57812 12.7993 2.57812C18.0496 2.57812 22.3057 6.83427 22.3057 12.0845C22.2938 17.3298 18.0446 21.5789 12.7993 21.5909ZM12.7993 4.20354C8.44915 4.20354 4.92263 7.73007 4.92263 12.0803C4.92263 16.4304 8.44915 19.957 12.7993 19.957C17.1495 19.957 20.6761 16.4304 20.6761 12.0803C20.6701 7.73255 17.147 4.20953 12.7993 4.20354ZM10.6282 15.605C10.7905 15.7702 11.0162 15.8573 11.2474 15.844C11.4645 15.8489 11.6735 15.7622 11.8233 15.605L17.6792 9.67305C17.9969 9.35492 17.9969 8.83955 17.6792 8.52142C17.5279 8.36611 17.3202 8.27852 17.1034 8.27852C16.8865 8.27852 16.6789 8.36611 16.5275 8.52142L11.204 13.8341L9.0311 11.6612C8.71004 11.3621 8.20974 11.3709 7.89943 11.6812C7.58913 11.9915 7.58031 12.4918 7.87947 12.8129L10.6282 15.605Z"
                        fill="white" />
                    </svg>
                  </span>
                  {{ $t('habilidades-page.bullet_3') }}
                </li>
              </ul>
            </div>
          </section>
        </div>
        <!-- End Body -->
      </template>
    </CardPage>
    <!-- End Card Page Sign Up -->
  </div>
</template>

<script>
import CardPage from '@/components/CardPage'
// import BoxEstadoDeEmpleo from '@/components/profile/BoxEstadoDeEmpleo'
// import BasicInformationSection from '@/components/profile/BasicInformationSection'

import ApolloClient from '@/graphql/apolloClient'
import assesmentCategoriesQuery from '@/graphql/queries/assesmentCategories'
import userProfileQuery from '@/graphql/queries/userProfile'
import { mapGetters, mapActions } from 'vuex'
import { toastNotification } from '@/services/toastNotification'

export default {
  name: 'Tests',
  data() {
    return {
      userProfile: null,
      modalBienvenidaShow: false,
      assesmentCategories: []
    }
  },
  components: {
    CardPage
    // BasicInformationSection
  },
  updated() {
    this.getAssesmentCategories()
  },
  mounted() {
    this.setLoadingOn()
    this.getUserProfile()
    this.getAssesmentCategories()
  },
  watch: {
    modalBienvenidaShow(newName) {
      localStorage.modalBienvenidaShow = newName
    }
  },
  computed: {
    ...mapGetters(['token', 'user'])
  },
  methods: {
    ...mapActions(['setLoadingOn', 'setLoadingOff']),
    showTutorialModal: function () {
      // Comprobar si ya se ha mostrado el modal antes y almacenarlo en localStorage
      if (localStorage.getItem('modalBienvenidaShown')) {
        // Si el modal ya se mostró anteriormente, asegurar que no se muestre de nuevo
        this.modalBienvenidaShow = false
      } else {
        // Verificar si el perfil del usuario tiene datos que indicarían que no es su primera visita
        if (
          this.userProfile.englishLevel === null &&
          this.userProfile.idealCompany?.length === 0 &&
          this.userProfile.jobTypePreference?.length === 0 &&
          this.userProfile.industries?.length === 0 &&
          this.userProfile.jobStatus === null &&
          this.userProfile.minSalary === null &&
          this.userProfile.personsInCharge === null
        ) {
          // Mostrar el modal solo si es evidente que el usuario es nuevo y no ha configurado su perfil
          this.modalBienvenidaShow = true
          // Almacenar en localStorage que el modal ya se ha mostrado para no repetir en futuras sesiones
          localStorage.setItem('modalBienvenidaShown', 'true')
        } else {
          // No mostrar el modal si el perfil del usuario ya está parcialmente o totalmente lleno
          this.modalBienvenidaShow = false
        }
      }
    },

    getAssesmentCategories: async function () {
      try {
        const habilitiesQuery = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale
            }
          },
          fetchPolicy: 'network-only',
          query: assesmentCategoriesQuery
        })
        this.assesmentCategories = habilitiesQuery.data.assesmentCategories
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    getUserProfile: async function () {
      try {
        const userProfileData = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale,
              authorization: `Bearer ${await this.token}`
            }
          },
          fetchPolicy: 'network-only',
          query: userProfileQuery
        })
        this.userProfile = userProfileData.data.userProfile
        this.showTutorialModal()
        this.setLoadingOff()
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    onModalClose() {
      this.modalBienvenidaShow = false
    }
  }
}
</script>
