<template>
  <main class="main--wrapper">
    <!-- Section Hero -->
    <section class="relative pt-32 pb-14 lg:py-40">
      <div class="absolute bottom-0 lg:top-0 right-0 w-9/12 lg:w-6/12">
        <img
          src="@/assets/images/shape-01.svg"
          class="w-auto lg:h-full ml-auto"
          alt="Utopicode"
        />
      </div>
      <div
        class="content--wrapper flex lg:justify-between flex-wrap relative items-center"
      >
        <div class="w-full lg:w-6/12 mb-12 lg:mb-0">
          <h2
            class="dark:text-white text-contrast font-bold mb-2 lg:mb-3 title--h2"
          >
            {{ $t('comunidad-page.title') }}
          </h2>
          <p class="dark:text-primary-text text-secondary-text text-xl mb-8">
            {{ $t('comunidad-page.description') }}
          </p>
          <div
            class="flex flex-wrap lg:flex-nowrap space-y-6 lg:space-x-6 lg:space-y-0"
          >
            <a
              class="btn btn-xl border-2 btn-outline-secondary btn-outline-secondary-glow dark:rounded-lg rounded-full w-full lg:w-auto"
              href="https://discord.gg/SwctK6By"
              target="_blank"
              :title="$t('comunidad-page.cta')"
              >{{ $t('comunidad-page.cta') }}</a
            >
          </div>
        </div>
        <div class="w-full lg:w-5/12">
          <img
            src="@/assets/images/img-discord.svg"
            class="shadow-svg-xl mx-auto"
            alt="Utopicode"
          />
        </div>
      </div>
    </section>
    <!-- End Section Hero -->
    <section class="relative py-16 lg:py-32 mb-6">
      <div class="absolute bottom-0 lg:top-0 left-0 w-9/12 lg:w-6/12">
        <img
          src="@/assets/images/shape-02.svg"
          class="w-auto mr-auto lg:h-full"
          alt="Utopicode"
        />
      </div>
      <div
        class="content--wrapper flex lg:justify-between flex-wrap relative items-center lg:flex-row-reverse"
      >
        <div class="w-full lg:w-5/12 mb-12 lg:mb-0">
          <h2
            class="dark:text-white font-bold mb-2 lg:mb-3 title--h4"
            v-html="$t('comunidad-page.title-section-02')"
          ></h2>
          <p class="dark:text-primary-text text-secondary-text text-lg mb-8">
            {{ $t('comunidad-page.description-section-02') }}
          </p>
          <div class="flex gap-5 flex-wrap">
            <a
              class="btn btn-lg border-2 btn-outline-secondary btn-outline-secondary-glow btn-rounded-lg w-full lg:w-auto"
              href="https://discord.gg/SwctK6By"
              target="_blank"
              :title="option"
              v-for="(option, index) in options"
              :key="index"
            >
              {{ option }}</a
            >
          </div>
        </div>
        <div class="w-full lg:w-6/12">
          <img
            src="@/assets/images/img-codys-utopicode-discord.svg"
            class="shadow-svg-xl mx-auto"
            alt="Utopicode"
          />
        </div>
      </div>
    </section>
    <!-- Section Pool de Talento -->
    <!-- <section class="relative pt-16 lg:pt-32 mb-6">
      <div class="absolute bottom-0 lg:top-0 right-0 w-9/12 lg:w-6/12">
        <img
          src="@/assets/images/shape-02.svg"
          class="w-auto ml-auto lg:h-full flip-horizontal"
          alt="Utopicode"
        />
      </div>
      <div class="content--wrapper relative">
        <div class="w-full lg:w-7/12 mb-12 mx-auto">
          <h2
            class="text-white font-bold mb-2 lg:mb-3 title--h4 text-center"
            v-html="$t('comunidad-page.title-section-03')"
          ></h2>
          <p class="dark:text-primary-text text-secondary-text text-lg mb-8 text-center">
            {{ $t('comunidad-page.description-section-03') }}
          </p>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-14">
          <CardHallOfFame
            v-for="(item, index) in data"
            :cardData="item"
            :key="index"
          ></CardHallOfFame>
        </div>
        <button
          class="btn btn-xl btn-primary btn-rounded-lg w-full lg:w-auto mx-auto"
          title="#React.js"
        >
          {{ $t('comunidad-page.cta-section-03') }}
        </button>
      </div>
    </section> -->
    <!-- End Section Pool de Talento -->
  </main>
</template>

<script>
// import CardHallOfFame from '@/components/CardHallOfFame'

export default {
  name: 'Comunidad',
  data() {
    return {
      options: [
        '#React.js',
        '#Javascript',
        '#Python',
        '#FrontEnd',
        '#RubyOnRails',
        '#CSS3',
        '#Node.js',
        '#C++',
        '#Swift',
        '#Go',
        '#DataScience',
        '#BackEnd',
        '#Senior',
        '#Mid',
        '#Junior',
        '#CodeHelp'
      ],
      data: [
        {
          image:
            'https://colibriwp.com/blog/wp-content/uploads/2018/07/banner-redimensionat.jpg',
          url: 'www.google.com.ar',
          category: 'Website',
          title: 'Name 01'
        },
        {
          image: 'https://mobirise.com/assets52/images/poster.png',
          url: 'www.google.com.ar',
          category: 'Mobile',
          title: 'Name 02'
        },
        {
          image:
            'https://media.designrush.com/tinymce_images/79179/conversions/website-dimensions-content.jpg',
          url: 'www.google.com.ar',
          category: 'Desktop App',
          title: 'Name 03'
        },
        {
          image:
            'https://colibriwp.com/blog/wp-content/uploads/2018/07/banner-redimensionat.jpg',
          url: 'www.google.com.ar',
          category: 'Website',
          title: 'Name 01'
        },
        {
          image:
            'https://media.istockphoto.com/vectors/smartphone-with-app-icons-vector-id511991248?s=612x612',
          url: 'www.google.com.ar',
          category: 'Mobile',
          title: 'Name 02'
        },
        {
          image:
            'https://media.designrush.com/tinymce_images/79179/conversions/website-dimensions-content.jpg',
          url: 'www.google.com.ar',
          category: 'Desktop App',
          title: 'Name 03'
        }
      ]
    }
  }
}
</script>
