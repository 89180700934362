<template>
  <!-- Table -->
  <div
    class="overflow-x-auto mb-6 border dark:border-line-dark border-line-light whitespace-nowrap"
  >
    <table
      class="table--default table--hover table--nowrap dark:text-primary-text text-secondary-text w-full"
    >
      <thead>
        <tr>
          <th class="">
            <div class="flex items-center justify-center flex-nowrap">
              {{ $t('candidates-pool.lbl-position') }}
              <div class="table-sort">
                <button
                  class="table-sort-up active"
                  type="button"
                  @click="sortByColumn('id', 'asc')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="fill-current"
                    viewBox="0 0 10 6"
                  >
                    <path
                      d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                    />
                  </svg>
                </button>
                <button
                  class="table-sort-down"
                  type="button"
                  @click="sortByColumn('id', 'desc')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="fill-current transform rotate-180"
                    viewBox="0 0 10 6"
                  >
                    <path
                      d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </th>
          <th>
            <div class="flex items-center justify-center flex-nowrap">
              {{ $t('candidates-pool.lbl-pool') }}
              <div class="table-sort">
                <button
                  class="table-sort-up active"
                  type="button"
                  @click="sortByColumn('email', 'asc')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="fill-current"
                    viewBox="0 0 10 6"
                  >
                    <path
                      d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                    />
                  </svg>
                </button>
                <button
                  class="table-sort-down"
                  type="button"
                  @click="sortByColumn('email', 'desc')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="fill-current transform rotate-180"
                    viewBox="0 0 10 6"
                  >
                    <path
                      d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </th>
          <th>
            <div class="flex items-center justify-center flex-nowrap">
              {{ $t('candidates-pool.lbl-candidates') }}
              <div class="table-sort">
                <button
                  class="table-sort-up active"
                  type="button"
                  @click="sortByColumn('speciality', 'asc')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="fill-current"
                    viewBox="0 0 10 6"
                  >
                    <path
                      d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                    />
                  </svg>
                </button>
                <button
                  class="table-sort-down"
                  type="button"
                  @click="sortByColumn('speciality', 'desc')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="fill-current transform rotate-180"
                    viewBox="0 0 10 6"
                  >
                    <path
                      d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </th>
          <th>
            <div class="flex items-center justify-center flex-nowrap">
              {{ $t('candidates-pool.lbl-date') }}
              <div class="table-sort">
                <button
                  class="table-sort-up active"
                  type="button"
                  @click="sortByColumn('status', 'asc')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="fill-current"
                    viewBox="0 0 10 6"
                  >
                    <path
                      d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                    />
                  </svg>
                </button>
                <button
                  class="table-sort-down"
                  type="button"
                  @click="sortByColumn('status', 'desc')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="fill-current transform rotate-180"
                    viewBox="0 0 10 6"
                  >
                    <path
                      d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </th>
          <th>
            <div class="flex items-center justify-center flex-nowrap">
              {{ $t('candidates-pool.lbl-tech-stack') }}
              <div class="table-sort">
                <button
                  class="table-sort-up active"
                  type="button"
                  @click="sortByColumn('status', 'asc')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="fill-current"
                    viewBox="0 0 10 6"
                  >
                    <path
                      d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                    />
                  </svg>
                </button>
                <button
                  class="table-sort-down"
                  type="button"
                  @click="sortByColumn('status', 'desc')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="fill-current transform rotate-180"
                    viewBox="0 0 10 6"
                  >
                    <path
                      d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </th>
          <th>
            <div class="flex items-center justify-center flex-nowrap">
              {{ $t('candidates-pool.lbl-country') }}
              <div class="table-sort">
                <button class="table-sort-up active" type="button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="fill-current"
                    viewBox="0 0 10 6"
                  >
                    <path
                      d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                    />
                  </svg>
                </button>
                <button class="table-sort-down" type="button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="fill-current transform rotate-180"
                    viewBox="0 0 10 6"
                  >
                    <path
                      d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </th>
          <th>
            <div class="flex items-center justify-center flex-nowrap">
              {{ $t('candidates-pool.lbl-city') }}
              <div class="table-sort">
                <button class="table-sort-up active" type="button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="fill-current"
                    viewBox="0 0 10 6"
                  >
                    <path
                      d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                    />
                  </svg>
                </button>
                <button class="table-sort-down" type="button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="fill-current transform rotate-180"
                    viewBox="0 0 10 6"
                  >
                    <path
                      d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </th>
          <th>
            {{ $t('candidates-pool.lbl-salary') }}
          </th>
          <th>
            <div class="flex items-center justify-center flex-nowrap">
              {{ $t('candidates-pool.lbl-years') }}
              <div class="table-sort">
                <button class="table-sort-up active" type="button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="fill-current"
                    viewBox="0 0 10 6"
                  >
                    <path
                      d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                    />
                  </svg>
                </button>
                <button class="table-sort-down" type="button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="fill-current transform rotate-180"
                    viewBox="0 0 10 6"
                  >
                    <path
                      d="M5.378.153a.543.543 0 0 0-.756 0l-4.467 4.4a.558.558 0 0 0 0 .772l.527.522c.206.204.55.204.778 0l3.551-3.494L8.54 5.848c.229.204.572.204.778 0l.527-.522a.558.558 0 0 0 0-.771L5.378.154Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(data, index) in poolData" :key="index">
          <td class="">
            {{ data.role }}
          </td>
          <td class="">{{ data.position }}</td>
          <td>{{ data.candidates }}</td>
          <td>{{ data.date }}</td>
          <td>
            <p class="mb-1 flex space-x-1">
              <span
                class="badge dark:bg-main-dark bg-line-light dark:text-white badge-xs badge-rounded-full"
                v-for="stack in data.techStack"
                :key="stack"
                >{{ stack }}
              </span>
            </p>
          </td>
          <td>{{ data.country }}</td>
          <td>{{ data.city }}</td>
          <td>{{ data.salary.min }}-{{ data.salary.max }}</td>
          <td>{{ data.years }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- End Table -->
</template>
<script>
export default {
  name: 'SearchTable',
  components: {},
  data() {
    return {
      poolData: [
        {
          role: 'React Senior',
          position: 'Remoto',
          english: 'Nativo',
          salary: { min: '3000', max: '6000', currency: 'USD' },
          techStack: ['React', 'CSS'],
          candidates: 8,
          country: 'Mexico',
          city: 'Ciudad de Mexico',
          date: '6d Ago',
          years: '5 años'
        },
        {
          role: 'Frontend Senior',
          position: 'Remoto',
          english: 'Nativo',
          salary: { min: '3000', max: '6000', currency: 'USD' },
          techStack: ['React', 'CSS'],
          candidates: 8,
          country: 'Mexico',
          city: 'Ciudad de Mexico',
          date: '6d Ago',
          years: '5 años'
        },
        {
          role: 'Backend Senior',
          position: 'Remoto',
          english: 'Nativo',
          salary: { min: '3000', max: '6000', currency: 'USD' },
          techStack: ['React', 'CSS'],
          candidates: 11,
          country: 'Colombia',
          city: 'Ciudad de Mexico',
          date: '6d Ago',
          years: '5 años'
        },
        {
          role: 'Frontend Senior',
          position: 'Remoto',
          english: 'Nativo',
          salary: { min: '3000', max: '6000', currency: 'USD' },
          techStack: ['React', 'CSS'],
          candidates: 10,
          country: 'Argentina',
          city: 'Ciudad de Mexico',
          date: '6d Ago',
          years: '5 años'
        },
        {
          role: 'Mobile Senior',
          position: 'Remoto',
          english: 'Nativo',
          salary: { min: '3000', max: '6000', currency: 'USD' },
          techStack: ['React', 'CSS'],
          candidates: 6,
          country: 'Mexico',
          city: 'Ciudad de Mexico',
          date: '6d Ago',
          years: '5 años'
        },
        {
          role: 'Devops Senior',
          position: 'Remoto',
          english: 'Nativo',
          salary: { min: '3000', max: '6000', currency: 'USD' },
          techStack: ['React', 'CSS'],
          candidates: 4,
          country: 'Argentina',
          city: 'Ciudad de Mexico',
          date: '6d Ago',
          years: '5 años'
        },
        {
          role: 'Angular Senior',
          position: 'Remoto',
          english: 'Nativo',
          salary: { min: '3000', max: '6000', currency: 'USD' },
          techStack: ['React', 'CSS'],
          candidates: 5,
          country: 'Mexico',
          city: 'Ciudad de Mexico',
          date: '6d Ago',
          years: '5 años'
        },
        {
          role: 'Android Senior',
          position: 'Remoto',
          english: 'Nativo',
          salary: { min: '3000', max: '6000', currency: 'USD' },
          techStack: ['React', 'CSS'],
          candidates: 3,
          country: 'Mexico',
          city: 'Ciudad de Mexico',
          date: '6d Ago',
          years: '5 años'
        },
        {
          role: 'Node Senior',
          position: 'Remoto',
          english: 'Nativo',
          salary: { min: '3000', max: '6000', currency: 'USD' },
          techStack: ['React', 'CSS'],
          candidates: 7,
          country: 'Mexico',
          city: 'Ciudad de Mexico',
          date: '6d Ago',
          years: '5 años'
        }
      ]
    }
  },
  methods: {
    sortByColumn(column, order) {
      const orderFactor = order === 'asc' ? 1 : -1
      const dataList = [...this.poolData]
      this.poolData = dataList.sort(function compare(firstEl, secondEl) {
        if (firstEl[column] > secondEl[column]) {
          return 1 * orderFactor
        } else {
          return -1 * orderFactor
        }
      })
    }
  }
}
</script>
