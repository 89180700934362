<template>
  <section class="mb-12">
    <Title class="mb-4">
      <template #title>
        <h4 class="mt-1 text-left">
          {{ $t('profile-page.title-acerca-de-mi') }}
        </h4>
      </template>
    </Title>
    <div>
      <p class="dark:text-primary-text text-secondary-text">
        {{ $t('profile-page.description-acerca-de-mi') }}
      </p>
      <Form @submit="updateUserProfile">
        <div class="form--group">
          <Field
            type="text"
            name="experienciaProfesionalDescription"
            :rules="validateDefault"
            v-slot="{ field }"
            v-model="about"
          >
            <textarea
              class="form--element w-full border-2 border-primary"
              v-bind="field"
              rows="5"
              maxlength="500"
              :placeholder="$t('profile-page.lbl-cuentanos-tu-historia')"
            ></textarea>
          </Field>
          <div class="text-right font-bold text-secondary-text">
            {{ about?.length }}/500
          </div>
          <ErrorMessage
            name="experienciaProfesionalDescription"
            class="form--error"
          />
        </div>
      </Form>
      <div class="flex gap-4 justify-center">
        <button
          class="btn btn-md btn-rounded-lg btn-primary"
          type="button"
          role="button"
          :aria-label="$t('general.guardar')"
          @click="updateUserProfile"
        >
          {{ $t('general.guardar') }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import Title from '@/components/Title'
import apolloClient from '../../graphql/apolloClient'
import saveAboutUserMutation from '../../graphql/mutations/saveAboutUser'
import { mapActions, mapGetters } from 'vuex'
import { Field, Form, ErrorMessage } from 'vee-validate'
import { validateDefault } from '@/services/validationRules'
import { toastNotification } from '@/services/toastNotification'

export default {
  name: 'AboutMeSection',
  props: {
    userAbout: String,
    isPublic: Boolean,
    id: { type: Number, required: false }
  },
  components: {
    Title,
    Field,
    Form,
    ErrorMessage
  },
  computed: {
    ...mapGetters(['token'])
  },
  data() {
    return {
      editTellYourStory: true,
      about: this.userAbout ? this.userAbout : ''
    }
  },
  mounted() {
    this.getAboutField()
  },
  methods: {
    ...mapActions(['setLoadingOn', 'setLoadingOff', 'fetchUser']),
    updateUserProfile: async function () {
      try {
        this.setLoadingOn()
        const saveAboutUser = await apolloClient.mutate({
          mutation: saveAboutUserMutation,
          context: {
            headers: {
              authorization: `Bearer ${await this.token}`
            }
          },
          variables: {
            about: this.about,
            id: this.id
          }
        })
        if (!saveAboutUser.errors) {
          this.editTellYourStory = false
          this.fetchUser()
          this.setLoadingOff()
          toastNotification(this.$t('general.info-guardada'), 'success')
        }
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    getAboutField() {
      if (this.about === null || this.about === '') {
        this.editTellYourStory = true
      } else {
        this.editTellYourStory = false
      }
    },
    // RULES
    validateDefault
  }
}
</script>
