import gql from 'graphql-tag'

const saveHighPreferences = gql`
  mutation saveHighPreferences(
    $industries: [String]
    $personsInCharge: String
  ) {
    updateHighPreferences(
      industries: $industries
      personsInCharge: $personsInCharge
    ) {
      industries
      personsInCharge
    }
  }
`

export default saveHighPreferences
